import _groupBy from 'lodash/groupBy';
import * as Sentry from '@sentry/react';
import QueryEvaluator from 'src/app/slicedForm/utility/QueryEvaluator';
import {
  CB_ITEM_NAMES,
  FLAGS,
  pressReleaseList,
  topicsList,
  otherNewsList
} from './newsConstants';
import { CHECKBOX_STATES } from 'src/app/components/filterContainers/NestedCheckboxFilterModal/constants';

/*
 We need to map the checkbox values back into their respective data source: TOPICS/SOURCE/SEC_CATEGORY.

 We also want to get clever, and avoid sending in 99 values when we have 100 total. Instead, send
 the inverse.

 We also want to deal with is_pr, which has a column in the database.
*/


const getChecked = data => data.filter(v => v.state === CHECKBOX_STATES.CHECKED);
const getUnchecked = data => data.filter(v => v.state === CHECKBOX_STATES.UNCHECKED);
const getIds = data => data.map(v => v.name);


/* eslint-disable-next-line no-unused-vars  */
const conditionalLog = (...args) => {
  // console.log(...args);
};


// Only allow articles that have the matching tickers. Move the matching ticker to be the parent record
export const reduceBySymbolFilter = (records, record, tickers = []) => {
  if (!tickers || !tickers.length) return [...records, record];

  const firstMatchingTicker = record.tickers.find(ticker => tickers.includes(ticker));

  if (!firstMatchingTicker) return records;

  const tIdx = record.tickers.findIndex(t => t === firstMatchingTicker);

  if (tIdx === -1) {
    console.warn('tickerIdx null, something went wrong...');
    record.tickers = null;
    return records;
  }

  // Move the matching ticker first, so it appears as the parent record
  record.tickers.unshift(record.tickers.splice(tIdx, 1)[0]);

  return [...records, record];
};



/**
 * Apply our filters directly to records, like we're pretending to be Postgres.
 * The filters will come in as QueryStruct, to match the backend as closely as 
 * possible.
 *
 * Records have multiple tickers. If a single ticker matches, we want to include
 * all the rest. If none match, we want to exclude the record.
 * 
 * Move the first matching ticker to the top of the list
 *
 * ** EXPRESSIONS (6/23/24): **
 *
 *   We also now have expressions. This is a doozy.
 *
 *   E.G: WHERE (expr1) > (expr2)
 *      expr1 = price + 100
 *      expr2 = chg / oopen
 *
 *   Because our backend sends ALL possible columns back through the websocket,
 *   we have all the information we need to compute the expression, technically.
 *
 *   Steps:
 *    1) Replace expression placeholders with column values, within a string.
 *       - If column is missing, we must throw away expression.
 *         Ideally, we eval this clause as PASS. Be generous.
 *    2) Use ArithmaticParser to evaluate string.
 *       - Catch errors, any error means clause is FAIL. (divide by zero, bad syntax, etc)
 *       - Parser returns a number.
 *    3) Run that number through regular QueryEvaluator
 *
 * @param {array} accRecords
 * @param {object} currRecord
 * @param {Object<Object>} filters
 * @param {object[]} columnDefs - INCLUDES EXPRESSION DEFS
 * @returns
 */
export function reduceByRealtimeFilters(
  accRecords,
  currRecord,
  filters = {},
  columnDefs
) {
  const evaluator = new QueryEvaluator(
    columnDefs,
    { defaultWhenMissingData: true, allowExpressions: true }
  );

  if (!filters || !Object.keys(filters).length) return [...accRecords, currRecord];

  // Check if any ticker inside this article passes
  const firstMatchingTicker = currRecord.tickers.find(ticker => {
    const quote = currRecord.quote?.[ticker];
    if (!quote || !Object.keys(quote).length) return false;

    // Apply filters
    const passes = evaluator.apply(filters, quote);
    return passes;
  });

  if (!firstMatchingTicker) {
    // No tickers match, don't add to reducer
    return accRecords;
  }

  // Move the matching ticker to the top
  const tIdx = currRecord.tickers.findIndex(t => t === firstMatchingTicker);

  if (tIdx === -1) {
    console.warn('tickerIdx null, something went wrong...');
    currRecord.tickers = null;
    return accRecords;
  }

  currRecord.tickers.unshift(currRecord.tickers.splice(tIdx, 1)[0]);

  return [...accRecords, currRecord];
}



export const filterByCategories = (record, categories) => {
  const { source, topics, form_category } = record;
  conditionalLog('[NS_STREAM] RECORD:', record, categories);

  if (form_category) {
    // SEC filings
    let { data, flag } = categories[CB_ITEM_NAMES.SEC_CATEGORIES];
    if (flag === FLAGS.NONE) {
      conditionalLog('[NS_STREAM] SEC FILTERED, FLAGS.NONE');
      return false;
    }
    if (flag === FLAGS.IN) {
      if (!data.includes(form_category)) {
        conditionalLog(`[NS_STREAM] SEC FILTERED, FLAGS.IN ${form_category}`);
        return false;
      }
    }
    if (flag === FLAGS.NOT_IN) {
      if (data.includes(form_category)) {
        conditionalLog(`[NS_STREAM] SEC FILTERED, FLAGS.NOT_IN ${form_category}`);
        return false;
      }
    }
    conditionalLog(`[NS_STREAM] SEC PASSED!`);
    return true;
  } else {
    // NEWS TOPICS
    // TODO: topic overlap on same article. See clickup.
    let { data, flag } = categories[CB_ITEM_NAMES.TOPICS];
    if (flag === FLAGS.NONE) {
      conditionalLog('[NS_STREAM] NEWS FILTERED, FLAGS.NONE');
      return false;
    }

    if (flag === FLAGS.IN) {
      // If any of articles topics are included
      if (!topics.some(t => data.includes(t))) {
        conditionalLog('[NS_STREAM] NEWS FILTERED, FLAGS.IN');
        return false;
      }
    }
    if (flag === FLAGS.NOT_IN) {
      // If all of articles topics are excluded
      const inverseTopics = topicsList.filter(t => !data.includes(t.name));
      if (!inverseTopics.some(t => data.includes(t))) {
        conditionalLog('[NS_STREAM] NEWS FILTERED, FLAGS.NOT_IN');
        return false;
      }
    }

    // NEWS SOURCES
    let { data: prData, flag: prFlag } = categories[CB_ITEM_NAMES.PRESS_RELEASE];
    let { data: otherData, flag: otherFlag } = categories[CB_ITEM_NAMES.OTHER_NEWS];
    const allAllowedSources = [];

    if (prFlag === FLAGS.ALL) {
      allAllowedSources.push(...pressReleaseList.map(n => n.name));
    } else if (prFlag === FLAGS.IN) {
      allAllowedSources.push(...prData);
    } else if (prFlag === FLAGS.NOT_IN) {
      allAllowedSources.push(...pressReleaseList.filter(n => !prData.includes(n.name)).map(n => n.name));
    }

    if (otherFlag === FLAGS.ALL) {
      allAllowedSources.push(...otherNewsList.map(n => n.name));
    } else if (otherFlag === FLAGS.IN) {
      allAllowedSources.push(...otherData);
    } else if (otherFlag === FLAGS.NOT_IN) {
      allAllowedSources.push(...otherNewsList.filter(n => !otherData.includes(n.name)).map(n => n.name));
    }

    if (!allAllowedSources.includes(source)) {
      conditionalLog('[NS_STREAM] NEWS FILTERED SOURCE NOT ALLOWED', allAllowedSources);
      return false;
    }

    conditionalLog(`[NS_STREAM] NEWS PASSED!`);
    return true;
  }
};


export const filterByKeywords = (record, searchKeywords = []) => {
  const searchableColumns = ['headline'];
  if (searchKeywords.length && searchableColumns.length) {
    return searchKeywords.some(keyword => {
      return searchableColumns.some(col => checkKeywordAgainstColumn(keyword, record?.[col]));
    });
  }
  return true;
};



const checkKeywordAgainstColumn = (keyword, column) => {
  if (column) return column.toLowerCase().toLocaleString().includes(keyword.toLowerCase());
  return false;
};


// The format we will save to the database, stored under parent categories for space saving. (not_in / in operators)
export const serializeCategoryFilters = (childStates, items) => {
  const itemAndValues = childStates.map(v => ({
    ...v,
    ...items.find(x => x.name === v.name)
  }));


  const balanceArrayState = itemValues => {
    const checked = getChecked(itemValues);
    if (checked.length === 0) {
      return { flag: FLAGS.NONE };
    }
    if (checked.length === itemValues.length) {
      return { flag: FLAGS.ALL };
    }
    if (checked.length <= (itemValues.length / 2)) {
      return { flag: FLAGS.IN, data: getIds(checked) };
    } else {
      return { flag: FLAGS.NOT_IN, data: getIds(getUnchecked(itemValues)) };
    }
  };

  const groupedByParent = _groupBy(itemAndValues, 'parent');

  Object.keys(groupedByParent).forEach(parentKey => {
    groupedByParent[parentKey] = balanceArrayState(groupedByParent[parentKey]);
  });

  return groupedByParent;
};


export const deserializeCategoryFilters = (serializedTree, items) => {
  let newState = [];

  Object.entries(serializedTree).forEach(([parentKey, schema]) => {
    const { flag, data } = schema;
    const childItems = items.filter(x => x.parent === parentKey && x.checkbox);

    if (flag === FLAGS.ALL) {
      newState = [
        ...newState,
        ...childItems.map(x => ({ name: x.name, state: CHECKBOX_STATES.CHECKED }))
      ];
    } else if (flag === FLAGS.NONE) {
      newState = [
        ...newState,
        ...childItems.map(x => ({ name: x.name, state: CHECKBOX_STATES.UNCHECKED }))
      ];
    } else if (flag === FLAGS.IN) {
      newState = [
        ...newState,
        ...childItems.map(x => ({ name: x.name, state: data.includes(x.name) ? CHECKBOX_STATES.CHECKED : CHECKBOX_STATES.UNCHECKED }))
      ];
    } else if (flag === FLAGS.NOT_IN) {
      newState = [
        ...newState,
        ...childItems.map(x => ({ name: x.name, state: data.includes(x.name) ? CHECKBOX_STATES.UNCHECKED : CHECKBOX_STATES.CHECKED }))
      ];
    } else {
      const err = Error(`FLAG enum value invalid! ${flag}, for parent ${parentKey}`);
      Sentry.captureException(err);
      throw err;
    }
  });

  return newState;
};

