import {
  allCountries,
  countryShortCodes as _countryShortCodes,
} from 'country-region-data/dist/data';


/**
 * @typedef {Object} Region
 * @property {string} name
 * @property {string} shortCode
 */

/** 
 * @typedef {Object} Country
 * @property {string} countryName
 * @property {string} countryShortCode
 * @property {Region[]} regions
 */

/**
 * Backwards compatibility. This is the old format of countryData, which we 
 * use on the app. The new format is array based. Faster, but not worth 
 * refactoring.
 * @type {Country[]}
 */

export const countryData = allCountries.map(country => {
  return {
    countryName: country[0],
    countryShortCode: country[1],
    regions: country[2].map(region => {
      return {
        name: region[0],
        shortCode: region[1]
      }
    })
  }
});

export const countryShortCodes = _countryShortCodes;