import { initialFinancialsAlarms } from './profileSettingsConfig';
import { PREDEF_PREFIX } from 'src/redux/profileSettings/profileSettingsConfig';

// rg - normal
// gr - reversed
export const predefinedFullKeyStatsProfile = [
  {
    id: `${PREDEF_PREFIX}large-cap`,
    profileName: 'Large Cap',
    alarms: {
      'marketCap': { less: 500_000_000, greater: 10_000_000_000, reversed: false },
      'sharesoutstanding': { less: 100_000_000, greater: 500_000_000, reversed: false },
      'sharesfloat': { less: 100_000_000, greater: 500_000_000, reversed: false },
      'shortpercentfloat': { less: 10, greater: 30, reversed: false },
      'percentinstitutions': { less: 10, greater: 30, reversed: false },
      'percentinsiders': { less: 20, greater: 50, reversed: true },
      ...initialFinancialsAlarms
    },
    sections: [
      {
        sectionName: 'General Info',
        tables: [
          ['marketCap', 'sharesoutstanding', 'sharesfloat', 'shortpercentfloat', 'percentinstitutions', 'percentinsiders', 'industry'],
          ['SSR', 'rvolCalculated', 'day365_high_combined', 'day365_low_combined', 'changeYTDCombined', 'lastSplitCombined', 'next_earnings']
        ],
      },
      {
        sectionName: 'Ratios',
        tables: [
          ['pe_ratio', 'DebtEquityRatio', 'CurrentRatio'],
          ['BasicEPS', 'ROA', 'ROE']
        ],
      }
    ]
  },
  {
    id: `${PREDEF_PREFIX}small-cap`,
    profileName: 'Small Cap',
    alarms: {
      'marketCap': { less: 150_000_000, greater: 500_000_000, reversed: true },
      'sharesoutstanding': { less: 3_000_000, greater: 10_000_000, reversed: false },
      'sharesfloat': { less: 3_000_000, greater: 10_000_000, reversed: false },
      'shortpercentfloat': { less: 10, greater: 30, reversed: true },
      'percentinstitutions': { less: 10, greater: 30, reversed: true },
      'percentinsiders': { less: 20, greater: 50, reversed: true },
      'floatRotation': { less: 3, greater: 10, reversed: true },
      ...initialFinancialsAlarms
    },
    sections: [
      {
        sectionName: 'General Info',
        tables: [
          ['marketCap', 'sharesoutstanding', 'sharesfloat', 'shortpercentfloat', 'percentinstitutions', 'percentinsiders', 'floatRotation'],
          ['SSR', 'rvolCalculated', 'day365_high_combined', 'day365_low_combined', 'changeYTDCombined', 'lastSplitCombined', 'next_earnings']
        ],
      },
      {
        sectionName: 'Ratios',
        tables: [
          ['CashAndCashEquivalents', 'MonthsOfCash', 'WorkingCapital'],
          ['TotalRevenue', 'CurrentRatio', 'BurnMo']
        ],
      }
    ]
  }
];


export const defaultFullKeyStatsProfile = {
  activeProfile: `${PREDEF_PREFIX}small-cap`,
  financialsPeriod: 'quarterly',
  profiles: [
    ...predefinedFullKeyStatsProfile,
  ]
};


////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////


export const predefinedOverviewHistoryProfile = [
  {
    id: `${PREDEF_PREFIX}large-cap`,
    profileName: 'Large Cap',
    activeColumns: ['day0_date', 'day0_pm_vol', 'day0_gap_pct', 'day0_chg_pct', 'day1_ret_open_pct', 'day0_vol', 'day0_high_time'],
    filters: {
      'day0_gap_pct': { from: 3 },
      'day0_vol': { from: 1_000_000 }
    }
  },
  {
    id: `${PREDEF_PREFIX}small-cap`,
    profileName: 'Small Cap',
    activeColumns: ['day0_date', 'day0_pm_vol', 'day0_gap_pct', 'day0_chg_pct', 'day1_ret_open_pct', 'day0_vol', 'day0_high_time'],
    filters: {
      'day0_gap_pct': { from: 10 },
      'day0_vol': { from: 500_000 }
    }
  },
];


export const defaultOverviewHistoryProfile = {
  activeProfile: `${PREDEF_PREFIX}large-cap`,
  profiles: [
    ...predefinedOverviewHistoryProfile
  ]
};


////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////


export const defaultGapStatsProfile = {
  filters: {
    'day0_gap_pct': { from: 5.0 },
    'day0_vol': { from: 500_000 },
  }
};


////////////////////////////////////////////////////////////////////////
////////////////////////////////////////////////////////////////////////


export const defaultFinancialsProfile = {
  activeProfile: 'default',
  financialsPeriod: 'quarterly',
  profiles: [{
    id: 'default',
    profileName: 'Default',
    alarms: initialFinancialsAlarms,
    sections: [
      {
        sectionName: 'General',
        tables: [['BurnMo', 'WorkingCapital', 'MonthsOfCash', 'IssuanceEquityMo', 'CurrentRatio', 'DebtEquityRatio']]
      },
      {
        sectionName: 'Balance Sheet',
        tables: [['CashAndCashEquivalents', 'TotalAssets', 'TotalLiabilities', 'LongTermDebt', 'AccountsPayable', 'RetainedEarnings']]
      },
      {
        sectionName: 'Income Statement',
        tables: [['TotalRevenue', 'NetIncome', 'EBITDA', 'BasicEPS', 'ROA', 'ROE']]
      },
      {
        sectionName: 'Cash Flow',
        tables: [['ChangesInCash', 'OperatingCashFlow', 'InvestingCashFlow', 'FinancingCashFlow', 'FreeCashFlow', 'NetCommonStockIssuance']]
      }
    ]
  }]
};
