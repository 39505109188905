import { useMemo, useState, useCallback } from 'react';
import useStateFromProp from 'src/hooks/useStateFromProp';

// Cache for different rounding formatters
const compactors = new Map();

/**
 *
 * @param {integer} maximumFractionDigits
 * @return {Intl.NumberFormat} - formatter
 */
const getCompactor = (maximumFractionDigits) => {
  if (compactors.has(maximumFractionDigits)) {
    return compactors.get(maximumFractionDigits);
  } else {
    const compactor = new Intl.NumberFormat('en-US', {
      notation: "compact",
      maximumFractionDigits
    });
    compactors.set(maximumFractionDigits, compactor);
    return compactor;
  }
}



const SHORTHANDS = {
  K: 1.0e+3,
  M: 1.0e+6,
  B: 1.0e+9,
  T: 1.0e+12,
}

/** 
 * Can be either expanded or compacted. 
 * Allows:
 *  - Plain numbers
 *  - Numbers with commas in the right spot before the decimal
 *  - Numbers with compaction (KBMT) at the end
 *  - Leading decimal, or leading zero
 *  - Negatives, with all other rules
 * Does not allow:
 *  - Adornment chars. Those are handled separately.
 *  - Strings without at least a single digit.
 */
// const isValidNumberRegex = /^(-?)([0-9]{1,3}(,[0-9]{3})*([.])?([0-9]+)?)(?<abbrv>[KMBT])?$/i
const isValidNumberRegex = /^(-?)(?=.*[0-9])([0-9]{1,3}(,[0-9]{3})*)?([.])?([0-9]+)?(?<abbrv>[KMBT])?$/i


/**
 * Force a number to abbreviate, potentially losing precision
 * @param {number} n
 * @param {integer} maximumFractionDigits
 * @returns {string} - abbreviated number
 * @example
 */
export const abbreviate = (n, maximumFractionDigits = 6) => {
  // Normal number to shorthand string or number string
  if (n === null) return null;
  return getCompactor(maximumFractionDigits).format(n)
}


/**
 * Convert a shorthand string or number string to a number
 * @param {number|string} s
 * @returns {number}
 * @example
 * 10K => 10000
 * 10000 => 10000
 */
export const unabbreviate = (s) => {
  if (typeof s === 'number') return s
  if (typeof s !== 'string') return NaN

  const match = s.trim().match(isValidNumberRegex)
  if (!match) return NaN

  const { abbrv } = match.groups;

  let result;
  s = s.replace(/,/g, '')
  if (abbrv === undefined) {
    result = parseFloat(s)
  } else {
    const bare = s.replace(abbrv, '')
    const multiplier = SHORTHANDS[abbrv.toUpperCase()]

    result = parseFloat(bare) * multiplier
  }

  return result;
}



const delimitRegex = /\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g

/**
 * Add commas to the number. Number may or may not be abbreviated.
 * @param {number|string} s
 * @returns {string}
 */

export const delimit = (s) => {
  return s.toString().replace(delimitRegex, ',');
}


/**
 * Add the char to the start/end, if applicable
 * @param {string} abbreviated
 * @param {string} start
 * @param {string} end
 * @returns {string}
 */
export const adorn = (abbreviated, start, end) => {
  if (!abbreviated && abbreviated !== 0) return abbreviated;

  if (start) {
    abbreviated = start + abbreviated;
  }
  if (end) {
    abbreviated = abbreviated + end;
  }
  return abbreviated;
}


/**
 * Remove the char from start/end, if applicable
 * @param {string} abbreviated
 * @param {string} start
 * @param {string} end
 * @returns {string}
 */
export const unadorn = (abbreviated, start, end) => {
  if (!abbreviated && abbreviated !== 0) return abbreviated;

  if (start && abbreviated.startsWith(start)) {
    abbreviated = abbreviated.slice(start.length)
  }
  if (end && abbreviated.endsWith(end)) {
    abbreviated = abbreviated.slice(0, -end.length)
  }
  return abbreviated
}


/**
 * Returns partial adorn/unadorn methods with the adornments applied
 * @param {string} start 
 * @param {string} end 
 * @returns {{ adorn: function(string): string, unadorn: function(string): string }}
 */
export const useAdornments = (start, end) => {
  return useMemo(() => {
    return {
      adorn: (abbreviated) => adorn(abbreviated, start, end),
      unadorn: (abbreviated) => unadorn(abbreviated, start, end),
    }
  }, [start, end])
}



/**
 * Applies all abbreviation/adornment operations, and exposes handlers.
 * @param {number} value - The raw numerical input
 * @param {Integer} decimals - The rounding amount
 * @param {string} startAdornmentString - The string to add to the start of the abbreviated number
 * @param {string} endAdornmentString - The string to add to the end of the abbreviated number
 * @param {function} onAccept - The handler for when the input is valid. Called during BLUR event
 * @returns {{
 *  inputVal: string,
 *  onBlur: function,
 *  onChange: function
 * }}
 */
export const useShorthandNumberInput = ({
  value,
  decimals = 6,
  startAdornmentString,
  endAdornmentString,
  onAccept,
  allowNull = false
}) => {
  const { adorn, unadorn } = useAdornments(startAdornmentString, endAdornmentString);
  const [error, setError] = useState(null);
  const [_inputVal, setInputVal] = useStateFromProp(
    adorn(abbreviate(value, decimals))
  );

  /* eslint-disable-next-line no-self-compare */
  const inputVal = ((_inputVal !== _inputVal) || _inputVal === null) ? '' : _inputVal // check for NaN



  const onBlur = useCallback((event) => {
    const realNumber = unabbreviate(unadorn(event.target.value))

    if (!allowNull && isNaN(realNumber)) {
      setInputVal(_inputVal);
      setError(null);
      return;
    }

    onAccept(isNaN(realNumber) ? null : realNumber);

    /*
    We are double-setting the input here. It usually syncs with useStateFromProp, but in some
    cases it does not. This happens when abbreviate rounds decimals past 6. The "value" prop
    may not change, but the abbreviated number should be rounded, and theirfore re-rendered.
    12.123456B is the same 'realNumber' as 12.123456789B

    But, we still need to keep useStateFromProp to ensure single source of truth.
    */
    setInputVal(isNaN(realNumber) ? null : adorn(abbreviate(realNumber, 6)));
    setError(null);
  }, [onAccept])


  const onKeyDown = useCallback((event) => {
    if (event.key === 'Enter') {
      event.target.blur();
    }
  }, [])


  const onChange = useCallback((event) => {
    setInputVal(event.target.value);

    const valid = unadorn(event.target.value.trim()).match(isValidNumberRegex);
    setError(valid ? null : 'Invalid number');
  }, []);

  return {
    inputVal,
    error,
    onBlur,
    onEnter: onKeyDown,
    onChange,
  }
}
