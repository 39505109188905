import React from 'react';
import {
  Typography,
  makeStyles
} from '@material-ui/core'


function PickerRangeLabel(props) {
  return (
    <Typography><strong>{props.text}</strong></Typography>
  )
}


export default PickerRangeLabel;