class PromiseHelper extends Promise {

  /**
   * Wait for the specified ms
   * @param {number} ms
   */
  static sleep = (ms) => new Promise(resolve => setTimeout(resolve, ms));

  /**
   * Sleeps for a random amount of time, specified between min and max
   * @param {number} min - milliseconds
   * @param {number} max - milliseconds
   */
  static sleepBetween = (min, max) => {
    const delay = Math.floor(Math.random() * (max - min + 1)) + min;
    return new Promise(resolve => setTimeout(resolve, delay));
  }

  /**
   * Wait for the specified ms, then return a value
   * @param {number} ms
   * @param {*} returnVal
   */
  static delay = (ms, returnVal) => {
    return new Promise(resolve => {
      setTimeout(resolve.bind(null, returnVal), ms);
    });
  };

  /**
   * Like all settled, except it will only wait for a maximum of MS time before cancelling unresolved requests.
   * Prevents a single long-running request from blocking the rest.
   * @param {Array<Promise>} promises
   * @param {number} ms
   * @param {*} returnVal - returns this value if the promise times out
  */
  static raceAllSettled = (promises, ms, returnVal) => {
    return Promise.allSettled(promises.map(p => {
      return Promise.race([p, PromiseHelper.delay(ms, returnVal)]);
    }));
  };
}

export default PromiseHelper
