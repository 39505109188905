import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';


function SnackbarNotifications() {
  const notification = useSelector(state => state.notification?.notifications);
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    if (notification?.message) {
      enqueueSnackbar(notification.message, {
        ...notification
      });
    }
  }, [notification]);

  return <div></div>;
}


export default SnackbarNotifications;
