import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  AppBar,
  Box,
  Hidden,
  IconButton,
  Toolbar,
  makeStyles,
  SvgIcon
} from '@material-ui/core';
import { Menu as MenuIcon } from 'react-feather';
import { THEMES } from 'src/constants';
import TickerSearch from 'src/app/components/ticker/TickerSearch';
import LogoE from 'src/components/LogoE';

const useStyles = makeStyles((theme) => ({
  root: {
    zIndex: theme.zIndex.drawer + 100,
    ...theme.name === THEMES.LIGHT ? {
      boxShadow: 'none',
      backgroundColor: theme.palette.primary.main
    } : {},
    ...theme.name === THEMES.ONE_DARK ? {
      backgroundColor: theme.palette.background.default
    } : {}
  },
  toolbar: {
    minHeight: theme.mobileTopBarHeight,
  },
  logoBoxDesktop: {
    width: 190
  },
  flexGrow: {
    maxWidth: 250,
    width: '100%'
  },
  tickerSearchCont: {
    paddingLeft: 10,
    paddingRight: 17,
    width: '100%',
    // paddingRight: 130
  },
  menuButton: {
    padding: 8
  },
  edgeESection: {
    width: 32,
    '& img': {
      maxHeight: 29,
    },
    marginRight: 4
  }
}));


function TopBar({ className, onMobileNavOpen, hideTickerSearch = true, ...rest }) {
  const classes = useStyles({});

  return (
    <Hidden smUp>
      <AppBar
        className={clsx(classes.root, className)}
        {...rest}
      >
        <Toolbar className={classes.toolbar}>
          <IconButton
            className={classes.menuButton}
            color="inherit"
            onClick={onMobileNavOpen}
          >
            <SvgIcon fontSize="medium">
              <MenuIcon />
            </SvgIcon>
          </IconButton>
          {!hideTickerSearch ? (
            <Box
              className={classes.tickerSearchCont}
            >
              <TickerSearch className={classes.flexGrow} />
            </Box>
          ) : (<Box style={{ flex: 1, width: '100%' }} />)}
          <Box
            display="flex"
            justifyContent="center"
            className={classes.edgeESection}
          >
            <RouterLink to="/">
              <LogoE />
            </RouterLink>
          </Box>
        </Toolbar>
      </AppBar>
    </Hidden>
  );
}

TopBar.propTypes = {
  className: PropTypes.string,
  onMobileNavOpen: PropTypes.func
};

export default TopBar;
