import { Calendar6Icon } from 'src/theme/EdgeIcons';
import React from 'react';
import {
  InputAdornment,
  IconButton,
  makeStyles,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  button: {
    '& .MuiSvgIcon-root': {
      fontSize: 24,
      color: theme.palette.text.secondary,
    },
    '& MuiIconButton-root': {
      padding: 2
    }
  }
}));




function IconAdornment({ onClick, disabled, children }) {
  const classes = useStyles();

  return (
    <InputAdornment position="end">
      <IconButton
        className={classes.button}
        onClick={onClick}
        disabled={disabled}
       >
        {children}
      </IconButton>
    </InputAdornment>
  )
}


export default IconAdornment;
