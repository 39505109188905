import fuzzysort from 'fuzzysort';

export const scannerV2FuzzySort = (query, columns) => {
  const result = fuzzysort.go(query, columns, {
    keys: ['label', 'synonyms'],
  });
  return result.map((r) => r.obj);
};

export const historyFuzzySort = (query, columns) => {
  const result = fuzzysort.go(query, columns, {
    keys: ['label', 'columnFilterLabel', 'synonyms'],
  });
  // TODO - exact matches need to be first. Maybe ignore casing and spaces.
  let weightedResult = result.map((r) => r.obj);
  weightedResult.sort((a, b) => a.label.localeCompare(b.label));
  weightedResult = moveDayZeroUp(weightedResult);
  return weightedResult;
};

const moveDayZeroUp = (results) => {
  let resultsWithoutZero = [];
  let resultsWithZero = [];
  results.forEach(r => {
    if (r.name.startsWith('day0_')) {
      resultsWithZero.push(r);
    } else {
      resultsWithoutZero.push(r);
    }
  });
  const insertLocation = resultsWithoutZero.findIndex(r => {
    return r.name.startsWith('day');
  });
  resultsWithoutZero.splice(insertLocation, 0, ...resultsWithZero);
  return resultsWithoutZero;
};


export const basicFuzzySort = (query, columns) => {
  const result = fuzzysort.go(query, columns, {
    key: 'label'
  });
  return result.map((r) => r.obj);
};


export const listModalFuzzySort = (query, keys, config) => {
  const objects = keys.map(k => ({
    name: k, label: config[k].label
  }));
  const results = fuzzysort.go(query, objects, {
    key: 'label'
  });
  return results.map((r) => r.obj.name);
}
