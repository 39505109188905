

import React, { useCallback, useEffect, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useInterval } from 'src/hooks/useInterval';
import { SAMPLE_TICKERS, AUDIO, SPEAK_AUDIO_KEY } from './constants';
import { useMultiAudio, useMakeTickerTTS } from 'src/hooks/useAudio';
import SmallFilterWindow from 'src/app/components/filterContainers/SmallFilterWindow';
import VolumeSlider from './VolumeSlider';
import HoverInfoPopper from 'src/app/components/elements/HoverInfoPopper';
import CooldownSlider from './CooldownSlider';
import {
  AlertOffIcon,
  AlertOnIcon,
} from 'src/theme/EdgeIcons';
import {
  HelpOutline
} from '@material-ui/icons'
import {
  darken,
  Typography,
  Select,
  MenuItem,
  useTheme,
  makeStyles,
  Grid,
  Button,
  Box,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {},
  container: {
    minWidth: 200
  },
  scroll: {
    height: '100%',
    maxHeight: 400,
    overflowY: 'auto'
  },
  soundPickButton: {
    display: 'block',
    width: '100%',
    '&.MuiButton-contained': {
      border: '1px solid transparent'
    }
  },
  slider: {
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.paperAlt_darker1,
    border: `1px solid ${theme.palette.background.lightBorder}`,
  },
  popper: {
    backgroundColor: theme.palette.background.panelHeader,
    border: `1px solid black`,
    // border: `1px solid ${theme.palette.border}`,
    maxWidth: 320,
    '& p': {
      fontSize: 14
    }
  },
  popperContainer: {
    display: 'inline'
  },
  popperTrigger: {
    cursor: 'pointer',
    color: theme.palette.warning.main,
    fontSize: 18,
    verticalAlign: 'text-top',
    marginLeft: 4
  },
  dropdown: {
    width: '100%',

    '& .MuiSelect-root': {
      borderRadius: theme.grid.borderRadius,
      backgroundColor: theme.palette.background.paperAlt_darker1,
      border: `1px solid ${theme.palette.background.lightBorder}`,
      transition: 'background-color 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontSize: 14,
      lineHeight: 1,
      paddingBottom: [[0, '!important']],
      '&:focus-visible': {
        ...theme.focus.outline
      }
    },
    '& .MuiSelect-root:hover, .MuiSelect-root:focus': {
      backgroundColor: `${darken(theme.palette.background.paperAlt_darker1, .1)} !important`
    },
    '& .MuiInputAdornment-positionEnd, .MuiAutocomplete-endAdornment': {
      marginLeft: 0,
      '& .MuiIconButton-root': {
        borderRadius: theme.grid.borderRadius,
        padding: '4px 3px'
      }
    },
    '& .MuiInputBase-input': {
      fontSize: 14,
      paddingLeft: 8,
      paddingTop: 8,
      paddingBottom: 8,
      textTransform: 'none',
      fontWeight: 400
    },
    '& .MuiOutlinedInput-notchedOutline': {
      display: 'none'
    },
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: 18,
      paddingLeft: 10,
      display: 'flex',
      alignItems: 'center',
      '& .menu-item-icon-audio': {
        marginRight: 10,
        marginLeft: 3,
        '& svg': {
          fontSize: 18
        }
      }
    },
    '& .MuiSelect-icon': {
      fontSize: 16,
      top: 'calc(50% - 8px)',
    },
  },
  menuPaper: {
    backgroundColor: darken(theme.palette.background.paperAlt_darker1, .2),
    '& .MuiListItem-root': {
      display: 'flex',
      alignItems: 'center',
      '& .menu-item-icon-audio': {
        marginRight: 10,
        marginLeft: 3,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        '& svg': {
          fontSize: 20
        }
      }

    }
  }
}));




function CooldownInstructions() {
  return (
    <>
      <Box pb={1}>
        <Typography variant="h5">
          Add a Cooldown Time for Audio Notifications
        </Typography>
      </Box>
      <Box pt={1}>
        <Typography>
          To avoid hearing multiple notifications for the same ticker in a short period, you can set a cooldown time.
          <br></br><br></br>
          This will prevent audio alerts from playing repeatedly for the same ticker when it enters and leaves the scanner frequently.
        </Typography >
      </Box >
    </>
  )
}




function AudioFormPopover({
  volume,
  lastVolume,
  cooldownTime = 60,
  selectedAudio,
  onOptionsChange,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const [_, triggerPlaying] = useMultiAudio(AUDIO[selectedAudio]?.value, {
    volume: volume,
    blocking: false,
  });
  const sampleTickerIndex = useRef(0);

  const [__, triggerTTS] = useMakeTickerTTS({
    volume,
    tickerLimit: 3,
    bufferLimit: 2
  });

  const triggerPlayingOrSpeak = useCallback(() => {
    if (selectedAudio === SPEAK_AUDIO_KEY) {
      triggerTTS([SAMPLE_TICKERS[sampleTickerIndex.current]]);
      sampleTickerIndex.current = (sampleTickerIndex.current + 1) % SAMPLE_TICKERS.length;
    } else {
      triggerPlaying();
    }
  }, [selectedAudio, triggerTTS, triggerPlaying]);


  const handleSetVolume = useCallback((value) => {
    // If volume !== 0, then we want to save it to lastVolume too.
    onOptionsChange({
      volume: value,
      lastVolume: value || lastVolume
    });
  }, [onOptionsChange, selectedAudio, triggerPlaying]);


  const handleSetAudio = useCallback((key) => {
    onOptionsChange({ selectedAudio: key });
  }, [onOptionsChange])


  const handleSetCooldownTime = useCallback((value) => {
    onOptionsChange({ cooldownTime: value });
  }, [onOptionsChange])

  const menuProps = useMemo(() => {
    return {
      MenuListProps: { disablePadding: true },
      classes: { paper: classes.menuPaper }
    }
  }, [classes.menuPaper])

  return (
    <SmallFilterWindow
      popoverClassName={classes.root}
      iconText="Audio Settings"
      Icon={volume === 0 ? AlertOffIcon : AlertOnIcon}
      shouldHideIconText
      iconColor={volume === 0 ? theme.palette.text.primary : theme.palette.primary.main}
      popoverMinWidth={392}
      popoverTitle="Audio Settings"
    >
      <Box className={classes.container}>
        <div className={clsx(classes.scrollParent, 'modal-horizontal-scrollbar-sm')}>
          <Typography style={{ fontSize: 13, maxWidth: 190 }}>
            Play an audio alert when a ticker is added to the Scanner
          </Typography>

          <Box pt={1.5}>
            <Typography id="continuous-slider" gutterBottom>
              <strong>Volume</strong>
            </Typography>
            <VolumeSlider
              className={classes.slider}
              value={volume}
              lastValue={lastVolume}
              onChange={handleSetVolume}
            />
          </Box>

          <Box pt={2}>
            <Typography id="continuous-slider" gutterBottom>
              <strong>Notification Sound</strong>
            </Typography>
            <Select
              className={clsx(classes.dropdown)}
              varaint="standard"
              label=""
              placeholder="Select..."
              disableUnderline
              aria-label="Pick audio track"
              value={selectedAudio}
              onChange={event => handleSetAudio(event.target.value)}
              MenuProps={menuProps}
            >
              {Object.entries(AUDIO).map(([key, { label, Icon }]) => (
                <MenuItem key={key} value={key}>
                  {Boolean(Icon) && <div className='menu-item-icon-audio'><Icon /></div>}
                  {label}
                </MenuItem>
              ))}
            </Select>
          </Box>

          <Box pt={2}>
            <Typography>
              <strong>Cooldown Time</strong>
              <HoverInfoPopper
                containerClassName={classes.popperContainer}
                className={classes.popper}
                arrow={false}
                content={<CooldownInstructions />}
              >
                <HelpOutline className={classes.popperTrigger} />
              </HoverInfoPopper>
            </Typography>
          </Box>
          <CooldownSlider
            value={cooldownTime}
            onChange={handleSetCooldownTime}
          />

          <Box pt={2}>
            <Button
              className={classes.soundPickButton}
              onClick={triggerPlayingOrSpeak}
              variant="contained"
              color="primary"
              disableRipple
            >
              TEST AUDIO
            </Button>
          </Box>

        </div>
      </Box>
    </SmallFilterWindow>
  );

}


AudioFormPopover.propTypes = {
  volume: PropTypes.number,
  lastVolume: PropTypes.number,
  selectedAudio: PropTypes.string,
  onChange: PropTypes.func,
};

export default AudioFormPopover;
