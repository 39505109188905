
export const THEMES = {
  // LIGHT: 'LIGHT',
  ONE_DARK: 'ONE_DARK',
  // UNICORN: 'UNICORN'
};

export const DOCS_WEBSITE_URL = 'https://docs.edgetotrade.com';

// Local variables found in .env.development.
// All other staged variables found in gitlab-environments/*
export const stagedApis = {
  // edgeApi: process.env.REACT_APP_EDGE_API_BASE_URL,
  edgeUsersApi: `${process.env.REACT_APP_EDGE_API}/users`,
  edgeDataApi: `${process.env.REACT_APP_EDGE_API}/data`,
  edgeProxyApi: `${process.env.REACT_APP_EDGE_API}/prx`,
  edgeCFApi: `${process.env.REACT_APP_CF_URL}cf`
}


export const COGNITO = {
  UserPoolId: process.env.REACT_APP_COGNITO_USER_POOL_ID,
  ClientId: process.env.REACT_APP_COGNITO_APP_CLIENT_ID,
  attributes: {
    givenName: 'given_name',
    familyName: 'family_name',
    betaAccess: 'custom:beta_access'
  }
};

export const WEBSITE_URL = 'https://edgetotrade.com'
