import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getTime } from 'date-fns';
import { selectComponent } from 'src/redux/layout/topListLayoutSelectors';
import { updateComponent } from 'src/redux/layout/topListLayoutActions';
import { nextTradingDay4amUnix } from 'src/utils/datetime/date-fns.tz';


/**
 * Unfortunately, we need to check the exclude list on every render. If expired, immediatly update redux.
 * This sucks because
 *  1) We get a render chain. If the user loads the page and the list is expired, we have to re-render the page instantly.
 *  2) We have to run this constantly. The expiration is a specific moment in time, we need to catch it.
 *
 * Our alternative is to guard every API call and display component with a check. I'd rather not spread the logic around like this.
 *  - On exclude change, update the list
 *  - Otherwise, let the list live but:
 *     1) TickerExcludeList - Hide excluded tickers that are expired
 *     2) fetchScanner      - Don't send in tickers that are expired
 *
 */

/**
 * @param {UnixMilliseconds} expiration
 * @returns {boolean}
 */
export const isExpired = (expiration) => expiration && expiration <= (+new Date());


/**
 * Returns next business day at 4am in EST
 * @return {UnixMilliseconds} next expiration date
 */
const getNextExpiry = () => {
  let nextExpiry = getTime(nextTradingDay4amUnix());
  // if (process.env.REACT_APP_USERS_LAMBDA_STAGE === 'local') {
  //   // nextExpiry = (+new Date()) + 15000
  // }
  return nextExpiry;
}


const defaultExcludedTickers = [];


/**
 * @typedef {Object} MosaicTickerExcludeCallbacks
 * @property {function(): string[]} getExcludedTickers
 *    - Returns the list of excluded tickers. If the list is expired, returns an empty array.
 *    - You can use this function itself as a useEffect dependancy if you want to track when the underlying list has been updated.
 * @property {function(string): void} addExcludedTicker
 *    - Add a ticker to the list. If the list is expired, prev values will be reset.
 * @property {function(string): void} removeExcludedTicker
 *    - Remove ticker from list. If the list is expired, it will be reset.
 */


/**
 * Adds and removes excluded tickers for a Mosaic component. Stored in Redux.
 * Also handles expiration of the excluded tickers at 4am every business day.
 *
 * We also are checking the profile during load to see if the list is expired, so we can update
 * before the initial render. Annoying, but better UX.
 *
 * @param {string} componentId
 * @param {string} layoutId
 * @return {MosaicTickerExcludeCallbacks}
 */
function useMosaicTickerExclude(
  componentId,
  layoutId,
) {
  const dispatch = useDispatch();
  const {
    excludedTickers = defaultExcludedTickers,
    excludedTickersExpiration = null,
  } = useSelector(selectComponent(componentId, layoutId));


  const getExcludedTickers = useCallback(() => {
    return isExpired(excludedTickersExpiration) ? [] : excludedTickers;
  }, [excludedTickers, excludedTickersExpiration]);


  const addExcludedTicker = useCallback((ticker) => {
    let nextExpiry = getNextExpiry()

    let nextList;
    if (isExpired(excludedTickersExpiration)) {
      console.debug(`[tickerExclude] EXPIRED LIST, ADDING TICKER. nextExpiry:${nextExpiry} now:${+new Date()}`);
      nextList = [...defaultExcludedTickers, ticker];
    } else {
      console.debug(`[tickerExclude] ADDING TICKER. nextExpiry:${nextExpiry} now:${+new Date()}`);
      nextList = [...new Set([...excludedTickers, ticker])];
    }

    dispatch(updateComponent(componentId, layoutId, {
      excludedTickers: nextList,
      excludedTickersExpiration: nextExpiry,
    }));
  }, [excludedTickers, excludedTickersExpiration]);


  const removeExcludedTicker = useCallback((ticker) => {
    let nextList;
    
    if (isExpired(excludedTickersExpiration)) {
      console.log(`[tickerExclude] EXPIRED LIST, REMOVING TICKER`);
      nextList = defaultExcludedTickers;
    } else {
      console.debug(`[tickerExclude] REMOVING TICKER`);
      nextList = excludedTickers.filter(t => t !== ticker);
    }

    dispatch(updateComponent(componentId, layoutId, {
      excludedTickers: nextList,
      excludedTickersExpiration: nextList.length ? excludedTickersExpiration : null,
    }));
  }, [excludedTickers, excludedTickersExpiration]);


  return {
    getExcludedTickers,
    addExcludedTicker,
    removeExcludedTicker,
  };
}


export default useMosaicTickerExclude;
