import React, { useState, useCallback, useRef, useEffect } from 'react';
import clsx from 'clsx';
import
MaskedTimeInput, {
  MaskedTimeInputDefaultProps,
  MaskedTimeInputPropTypes
} from 'src/app/components/pickers/components/MaskedTimeInput';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { isValid } from 'date-fns';
import useStateFromProp from 'src/hooks/useStateFromProp';
import {
  ClickAwayListener,
  makeStyles
} from '@material-ui/core';
import ConditionalWrapper from 'src/app/components/utility/ConditionalWrapper';


const useStyles = makeStyles(() => ({
  root: {},
  formattedSeparatorCont: {
    '& p': {
      display: 'inline',
      verticalAlign: 'sub'
    }
  }
}));


/**
 * Wraps two MaskedTimeInputs to create a range.
 * Some logic handles moving the cursor between them.
 *
 * We could make a single large masked input, but its less pretty.
 * @component
 */


function MaskedTimeRangeInputs({
  className,
  inputClassName,
  leftInputClassName,
  rightInputClassName,
  acceptStrategy,
  range,
  onAccept,
  flexWrap,
  endAdornment,
  separator,
  ...rest
}) {
  const classes = useStyles();
  const [tempRange, setTempRange] = useStateFromProp(range);
  const endDateInputRef = useRef(null);


  useEffect(() => setTempRange(range), [range]);

  /**
   * Valid input recieved from children
   */
  const handleInputAccept = (newRange) => {
    // The disabledDates for the keyboard inputs don't need to account for Range <=>, because
    // we will simply swap the values if they are in the wrong order.
    if (isValid(newRange.startDate) && isValid(newRange.endDate)) {
      // Sort them, so they aren't out of order. Better than showing error.
      const sorted = [newRange.startDate, newRange.endDate].sort((a, b) => a.getTime() - b.getTime());
      newRange.startDate = sorted[0];
      newRange.endDate = sorted[1];
    }
    if (acceptStrategy === 'onAccept') onAccept(newRange);
    setTempRange(newRange);
  };


  /**
   * User is unfocusing component. Propagate to parent.
   * TODO: This is better for performance on History. Ideally, we'd refactor history to handle onAccept more frequently.
   */
  const handleClickAway = () => {
    onAccept(tempRange);
  };

  /**
   * Checks the carrot position in the starDate input, so we can refocus to the next input
   * once they've reached the end.
   */
  const handleKeyUpRefocus = (event) => {
    if (
      endDateInputRef.current &&
      event.target.selectionStart === rest.format.length &&
      event.target.value
    ) {
      endDateInputRef.current.focus();
      endDateInputRef.current.setSelectionRange(0, 0);
    }
  };


  const formattedSeparator = React.isValidElement(separator) ? separator : <Typography>{separator}</Typography>;


  return (
    <Box
      className={clsx(className, classes.root)}
      display="flex"
    >
      <ConditionalWrapper
        condition={acceptStrategy === 'onClickAway'}
        wrapper={children => (
          <ClickAwayListener
            onClickAway={handleClickAway}
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
          >
            {children}
          </ClickAwayListener>
        )}
      >
        <Box
          display="flex"
          alignItems="center"
          flexWrap={flexWrap}
        >
          <MaskedTimeInput
            {...rest}
            className={clsx(inputClassName, leftInputClassName)}
            acceptStrategy="onBlur"
            errorPositionAbsolute
            date={tempRange.startDate}
            autoFocus={false}
            onAccept={startDate => handleInputAccept({ ...tempRange, startDate })}
            onKeyUp={handleKeyUpRefocus}
          />

          {Boolean(formattedSeparator) && <div className={classes.formattedSeparatorCont}>{formattedSeparator}</div>}

          <MaskedTimeInput
            {...rest}
            className={clsx(inputClassName, rightInputClassName)}
            date={tempRange.endDate}
            errorPositionAbsolute
            autoFocus={false}
            acceptStrategy="onBlur"
            inputRef={endDateInputRef}
            onAccept={endDate => handleInputAccept({ ...tempRange, endDate })}
          />
        </Box>
      </ConditionalWrapper>
      {Boolean(endAdornment) && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className='range-end-adornment'
        >
          {endAdornment}
        </Box>
      )}
    </Box>
  );
}


const {
  date: unusedDate,
  handleKeyUp: unusedKeyUp,
  autoFocus: unusedAutoFocus,
  ...maskedTimeInputPropTimes
} = MaskedTimeInputPropTypes;


MaskedTimeRangeInputs.propTypes = {
  ...maskedTimeInputPropTimes,
  acceptStrategy: PropTypes.oneOf(['onAccept', 'onClickAway']),
  inputClassName: PropTypes.string,
  range: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date)
  }),
  flexWrap: PropTypes.string,
  separator: PropTypes.any,
};


MaskedTimeRangeInputs.defaultProps = {
  ...MaskedTimeInputDefaultProps,
  acceptStrategy: 'onAccept',
  flexWrap: 'nowrap',
  separator: null,
};


export default React.memo(MaskedTimeRangeInputs);
