import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import TabsBarButton from 'src/layouts/AppLayout/TabsBar/TabsBarButton';
import { CheckIcon } from 'src/theme/EdgeIcons';
import {
  IconButton,
  InputBase,
  ClickAwayListener,
} from '@material-ui/core';
import { TradingViewCloudArrow } from 'src/theme/EdgeIcons';


const STEPS = {
  DISPLAYING: 'displaying',
  EDITING: 'editing',
};


function NewLayoutItem({
  className,
  newFileName,
  onConfirm,
  useStyles,
}) {
  const [instanceTitle, setInstanceTitle] = useState(newFileName);
  const [formStep, setFormStep] = useState(STEPS.DISPLAYING);
  const classes = useStyles({ editing: formStep === STEPS.EDITING });


  const handleClick = () => {
    setInstanceTitle(newFileName);
    setFormStep(STEPS.EDITING);
  };


  const handleEditConfirm = () => {
    onConfirm(instanceTitle);
    setFormStep(STEPS.DISPLAYING);
  };


  const handleClickAway = () => {
    setFormStep(STEPS.DISPLAYING);
    setInstanceTitle('');
  };


  const handleKeyDown = (event) => {
    const VALID_KEYS = ['Enter', 'Escape'];

    if (event.key === 'Enter') {
      handleEditConfirm();
    } else if (event.key === 'Escape') {
      handleClickAway();
    }
    if (VALID_KEYS.includes(event.key)) {
      event.preventDefault();
      event.stopPropagation();
    }
  };
  

  return (
    <ClickAwayListener onClickAway={handleClickAway}>
      <div className={classes.newItemBorder}>
        {(formStep !== STEPS.EDITING)
          ? (
            <TabsBarButton
              className={classes.createLayoutButton}
              Icon={TradingViewCloudArrow}
              text='Save new indicator template...'
              iconSize={'24px'}
              onClick={formStep === STEPS.DISPLAYING ? handleClick : handleClickAway}
            />
          ) : (
            <div className={clsx(className, classes.root)}>
              <div className={classes.listLeft}>
                <InputBase
                  autoFocus
                  value={instanceTitle}
                  onChange={(event) => setInstanceTitle(event.target.value)}
                  onKeyDown={handleKeyDown}
                  className={classes.inputBase}
                />
              </div>
              <div className={classes.listRight}>
                <div className={clsx(classes.listIcon, classes.confirmIcon, classes.iconGreen)}>
                  <IconButton
                    aria-label="Confirm"
                    disableRipple
                    onClick={handleEditConfirm}
                  >
                    <CheckIcon/>
                  </IconButton>
                </div>
              </div>
            </div>
          )
        }
      </div>
    </ClickAwayListener>
  );
}


NewLayoutItem.propTypes = {
  className: PropTypes.string,
  newFileName: PropTypes.string,
  onConfirm: PropTypes.func,
};


export default NewLayoutItem;
