export const metricLabels = {
  fromDate: 'From',
  toDate: 'To',
  day0_gap_pct: 'Gap %',
  day0_open: 'Open',
  day0_vol: 'Volume',
  day0_chg_pct: 'Chg %',
  day0_pm_vol: 'PM Volume',
  day0_high: 'High',
  day0_low: 'Low',
  day0_1min_high: '1m High',
  day0_1min_high_pct: '1m Low',
  day0_1min_vol: '1m Vol'
};

export const comparatorLabels = {
  gte: 'greater or equal',
  gt: 'greater',
  lte: 'less or equal',
  lt: 'less',
  eq: 'equal',
  btw: 'between'
};

export const comparatorSymbols = {
  gte: '>=',
  gt: '>',
  lte: '<=',
  lt: '<',
  eq: '=',
  btw: null
};

export const COMPS = {
  GE: 'ge',
  GT: 'gt',
  LE: 'le',
  LT: 'lt',
  EQ: 'eq',
  BT: 'bt'
};

export const comparatorLabelsV2 = {
  ge: 'Greater or equal',
  gt: 'Greater',
  le: 'Less or equal',
  lt: 'Less',
  eq: 'Equals',
  bt: 'Between'
};

export const comparatorSymbolsV2 = {
  ge: '>=',
  gt: '>',
  le: '<=',
  lt: '<',
  eq: '=',
  bt: 'btw'
};

const defaultComparators = Object.keys(comparatorLabelsV2);


export const metricValueTypes = {
  percentage: [
    'day0_gap_pct',
    'day0_open'
  ],
  number: [
    'day0_vol',
    'day0_pm_vol',
    'day0_high',
    'day0_low'
  ],
  date: [
    'fromDate',
    'toDate'
  ],
  other: []
};


export const getNumberValueType = (metric) => {
  return Object.keys(metricValueTypes).find(type => {
    return metricValueTypes[type].includes(metric);
  });
};


export const formFieldsConfiguration = [
  // date picker fields are forcibly applied in FilterForm.js
  {
    metric: 'day0_gap_pct',
    component: 'compare',
    numberType: 'percentage',
    inputAdornment: '%',
    comparators: defaultComparators,
  }, {
    metric: 'day0_vol',
    component: 'compare',
    numberType: 'number',
    comparators: defaultComparators,
  }, {
    metric: 'day0_open',
    component: 'compare',
    numberType: 'percentage',
    inputAdornment: '%',
    comparators: defaultComparators,
  }, {
    metric: 'day0_pm_vol',
    component: 'compare',
    numberType: 'number',
    comparators: defaultComparators,
  }, {
    metric: 'day1_high',
    component: 'compare',
    numberType: 'number',
    inputAdornment: '$',
    comparators: defaultComparators,
  }, {
    metric: 'day1_low',
    component: 'compare',
    numberType: 'number',
    inputAdornment: '$',
    comparators: defaultComparators,
  },
  // {
  //   metric: fromDate,
  //   component: 'date',
  //   minDate: '2010-01-01'
  // }, {
  //   metric: toDate,
  //   component: date,
  //   minDate: '2010-01-01'
  // },
];


// gap_pct=val~gte~200000
// gap_pct=col~lt~day1_volume
// gap_pct=val~btw~10~-20


export const parseFullFilterString = (string) => {
  if (string) {
    const [metric, valueString] = string.split('=');
    const [dataType, valueAndComparator] = valueString.split(/~(.+)/); // split by first instance of "~"
    var [comparator, value] = valueAndComparator.split(/~(.+)/);
    var secondValue = '';
    if (comparator === 'btw') {
      [value, secondValue] = value.split('~');
    }
    return [metric, comparator, value, secondValue, dataType];
  }
  return ['', '', '', '', ''];
};

export const parseFilterStringValue = (valueString) => {
  if (valueString) {
    const [dataType, valueAndComparator] = valueString.split(/~(.+)/); // split by first instance of "~"
    var [comparator, value] = valueAndComparator.split(/~(.+)/);
    var secondValue = '';
    if (comparator === 'btw') {
      [value, secondValue] = value.split('~');
    }
    return [comparator, value, secondValue, dataType];
  }
  return ['gt', '', '', 'val'];
};


export const cleanEmptyFilters = (filters) => {
  return filters.filter(({ val, selected }) => {
    return (selected || val);
  });
};

export const cleanEmptyFiltersOld = (filters) => {
  const cleanedValues = {};
  Object.keys(filters).map(name => {
    /* eslint-disable-next-line no-unused-vars */
    const { comp, compType, val, val2, selected } = filters[name];
    if (selected || val) {
      cleanedValues[name] = filters[name];
    }
  });
  return cleanedValues;
};


