import {
  makeStyles,
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import FunctionsIcon from '@material-ui/icons/Functions';
import clsx from 'clsx';
import _filter from 'lodash/fp/filter';
import _flow from 'lodash/fp/flow';
import _map from 'lodash/fp/map';
import _partition from 'lodash/fp/partition';
import React, { useCallback, useMemo } from 'react';
import ActionList from 'src/app/components/ActionList';
import { DELETE_COLUMN, selectColumnsAsList, SET_COLUMN_ORDER } from "src/app/slicedForm/ColumnForm/reducers/columnReducer";
import { useColumnDefs } from "src/app/slicedForm/shared/context/ColumnDefsProvider";
import { useFormDispatch, useFormSelector } from "src/app/slicedForm/shared/context/FormProvider";
import { EXPR_PREFIX } from 'src/redux/expressions/globalExpressionReducer';
import { STICKY_OPTIONS } from '../../FilterForm/definitions/inputEnums';
import { useIsEditingDisabled } from '../../shared/context/FormSettingsProvider';
import useCopyOrUpgradeClickblocker from '../../shared/hooks/useCopyOrUpgradeClickblocker';

const useStyles = makeStyles(() => ({
  stickyList: {
    paddingBottom: 0,
    paddingTop: 8,
  },
  draggableList: {
    paddingTop: 0
  },
}))


const reorder = (list, sourceidx, destIdx) => {
  const result = Array.from(list);
  const [removed] = result.splice(sourceidx, 1);
  result.splice(destIdx, 0, removed);
  return result;
};



function SelectedColumns({ listClassName }) {
  const classes = useStyles()
  const dispatch = useFormDispatch();
  const locked = useIsEditingDisabled();
  const selectedColumns = useFormSelector(selectColumnsAsList)
  const colDefs = useColumnDefs();
  const { clickBlockerProps } = useCopyOrUpgradeClickblocker();


  const [sticky, draggable] = useMemo(() => {
    return _flow(
      _map(name => colDefs.find(c => c.name === name)),
      _filter(Boolean),
      _partition('sticky')
    )(selectedColumns)

  }, [selectedColumns, colDefs])


  const handleDelete = useCallback((name) => {
    dispatch({
      type: DELETE_COLUMN,
      payload: {
        column: { column: name },
        colDefs
      }
    })
  }, []);


  const handleReorder = useCallback((result) => {
    // We can't use source->dest, because of sticky columns. They might be in wrong order inside profile.
    // Note: I'm now also sorting sticky columns inside reducer, so this may be unecissary.
    const newDraggable = reorder(draggable, result.source.index, result.destination.index);
    dispatch({
      type: SET_COLUMN_ORDER,
      payload: [...sticky, ...newDraggable]
    })
  }, [draggable]);


  const paddingLeft = 3;

  return (
    <>
      <div {...clickBlockerProps} />
      {/* Show the background opacity only. The Inner section shows the modal. */}
      <ActionList
        className={clsx(listClassName, classes.stickyList)}
        disabled={locked}
      >
        {sticky.map(def => {
          const toggleable = def.sticky === STICKY_OPTIONS.TOGGLEABLE;
          return (
            <ActionList.Item
              pl={paddingLeft}
              key={def.name}
              disabled={!toggleable}
              onSelect={() => toggleable && handleDelete(def.name)}
              title={def.label}
            >
              <ActionList.Leading placeholder />
              {Boolean(def.name.startsWith(EXPR_PREFIX)) && <FunctionsIcon className="expression-icon-list-item" />}
              {def.label}
              {toggleable && (
                <ActionList.Trailing>
                  <ClearIcon color="primary" />
                </ActionList.Trailing>
              )}
            </ActionList.Item>
          )
        })}
      </ActionList>
      <ActionList.Draggable
        className={clsx(listClassName, classes.draggableList)}
        onReorder={handleReorder}
        disabled={locked}
      >
        {draggable.map((def, i) => (
          <ActionList.DraggableItem
            pl={paddingLeft}
            key={def.name}
            draggableId={def.name}
            index={i}
            onSelect={() => handleDelete(def.name)}
            title={def.label}
          >
            {Boolean(def.name.startsWith(EXPR_PREFIX)) && <FunctionsIcon className="expression-icon-list-item" />}
            {def.label}
            <ActionList.Trailing>
              <ClearIcon color="primary" />
            </ActionList.Trailing>
          </ActionList.DraggableItem>
        ))}
      </ActionList.Draggable>
    </>
  )
}

export default SelectedColumns
