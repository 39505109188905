import { alpha, makeStyles } from '@material-ui/core';


export const useDropdownStyles = makeStyles((theme) => ({
  dropdownButton: {
    fontSize: 12,
    height: 32,
    padding: '4px 8px 4px 11px',
    margin: '4px 0',
    width: '100%',
    opacity: 1,
    display: 'flex',
    alignItems: 'center',
    color: theme.palette.text.primary,
    borderRadius: `${theme.grid.borderRadius}px !important`,
    // border: '1px solid rgba(255, 255, 255, 0.12) !important',
    border: 'none !important',
    backgroundColor: alpha(theme.palette.text.primary, .15),
    '&.MuiToggleButton-root.Mui-selected': {
      color: '#fff',
      backgroundColor: alpha(theme.palette.primary.main, .4),
      '&:hover': {
        color: '#fff',
        backgroundColor: alpha(theme.palette.primary.main, .4),
      },
    },
    '&:hover': {
      color: '#fff',
      backgroundColor: alpha(theme.palette.text.primary, .3),
    },
    '& .MuiToggleButton-label': {
      lineHeight: 1,
      whiteSpace: 'nowrap'
    }
  },
  goButton: {
    margin: '4px 0px 4px 8px',
    minWidth: 'unset',
    padding: 9,
    borderWidth: 2,
    '&:hover': {
      borderWidth: 2
    }
  },
}));
