import { parseToMarketTime, formatMarketTime } from 'src/utils/datetime/date-fns.tz';
import edgeDataApi from 'src/apis/edgeDataApi';
import _cloneDeep from 'lodash/cloneDeep';

export const REALTIME_STOCKNEWS_MESSAGE = '@realtime/stocknews-message';
export const REALTIME_SEC_FILINGS_MESSAGE = '@realtime/sec-filings-message';
export const REALTIME_TOPLIST_SCANNER_MESSAGE = '@realtime/tl-scanner-message';
export const REALTIME_STOCKNEWS_MESSAGE_CLICKED_TICKER = '@realtime/tl-scanner-message-clicked-ticker';
export const REALTIME_FETCH_NEWS_HISTORY = '@realtime/fetch-news-history';
export const REALTIME_BATCH_NEWS_HISTRY = '@realtime/batch-news-history';
export const REALTIME_CLEAR_NEWS_HISTORY = '@realtime/clear-news-history';
export const REALTIME_SET_TL_FEED_TYPE = '@realtime/set-tl-feed-type';
export const NEWS_PAGE_FETCH_TICKER_NEWS = '@realtime/fetch_news_page_ticker_news';
export const REALTIME_SET_NEWS_CLICKED_TICKER = '@realtime/set-toplist-clicked-ticker';
export const REALTIME_FETCH_PRICE_DATA = '@realtime/fetch-price-data';
export const REALTIME_SET_SNAPSHOT_TICKER = '@realtime/set-snapshot-ticker';
export const REALTIME_SET_SNAPSHOT_SCAN_ENABLED = '@realtime/set-snapshot-is-scanning';
export const REALTIME_SET_SNAPSHOT_SCAN_DELAY = '@realtime/set-snapshot-scan-delay';
export const REALTIME_SET_SNAPSHOT_NUM_TICKERS_TO_SCAN = '@realtime/set-snapshot-num-tickers-scan';
export const REALTIME_SET_IS_VIEWING_LIVE_DATA = '@realtime/set-is-viewing-live-toplist-data';
export const REQUEST__REALTIME_FETCH_NEWS_HISTORY = '@realtime/request__fetch-news-history';
export const REQUEST__REALTIME_BATCH_NEWS_HISTORY = '@realtime/request__batch-news-history';
export const REQUEST__NEWS_PAGE_FETCH_TICKER_NEWS = '@realtime/request__fetch_news_page_ticker_news';
export const REQUEST__REALTIME_FETCH_TL_SCANNER_HISTORY = '@realtime/request__tl-fetch-scanner-history';
export const REQUEST__REALTIME_FETCH_SCANNER_STATIC = '@realtime/request__fetch-scanner-static';
export const RESOLVE__REALTIME_FETCH_NEWS_HISTORY = '@realtime/resolve__fetch-news-history';


export const SCANNER_HTTP_MESSAGE = '@realtime/scanner-http-message';
export const REQUEST__SCANNER_HTTP_MESSAGE = '@realtime/request__scanner-http-message';



export const topListFormatQueries = (filters, day) => {
  if (!filters) {
    filters = {};
  }
  const query = [];
  Object.keys(filters).forEach(key => {
    const { from, to, selected } = filters[key];

    if (selected) {
      query.push([key, 'eq', 'val', selected].join('~'));
    }
    if (from) {
      query.push([key, 'ge', 'val', from].join('~'));
    }
    if (to) {
      query.push([key, 'le', 'val', to].join('~'));
    }
  });

  if (day) {
    query.push(['day0_date', 'eq', 'val', day].join('~'));
  }
  return query;
};


// TODO: clean up this tree parsing stuff
// export const topListFormatQueriesV2 = (filters, day) => {
//   if (!filters) {
//     filters = { AND: [] };
//   }
//   if (day) {
//     filters = _cloneDeep(filters);
//     try {
//       filters.AND.push({
//         left: { column: 'day0_date' },
//         operator: 'EQ',
//         right: { value: day }
//       });
//     } catch (err) {
//       console.warn('topListFormatQueriesV2() Filters.AND doesn\'t exist in top-level', filters);
//     }
//   }
//
//   return replaceSliceGroupWithCurrentTimeChild(filters);
// };


// HISTORY PAGE. combined with newsActions.fetchHistoryNews()
export const fetchNewsSecSingleTicker = (ticker, startDate, limit, type) => async dispatch => {
  if (type !== 'news' && type !== 'sec') {
    throw new Error('Wrong [type] passed into fetchNewsHistory');
  }
  if (type === 'news') {
    throw Error('/news-sec-history is depricated, and should not be used to fetch News. SEC is still allowed temporarily.')
  }

  dispatch({ type: REQUEST__NEWS_PAGE_FETCH_TICKER_NEWS, payload: type });

  let articles = [];
  try {
    let url = `/news-sec-history/${ticker}?type=${type}`;
    url += startDate ? `&start_date=${startDate}` : '';
    url += limit ? `&max_articles=${limit}` : '';
    const response = await edgeDataApi.get(url);
    if (response.data && Array.isArray(response.data)) {
      articles = (type === 'news')
        ? null // addArticleProperties(response.data, {}, ticker)
        : addSecProperties(response.data, {}, ticker);
    }
  } catch (err) {
    console.log('[realtime fetchNewsSecSingleTicker]: ', err);
  }
  dispatch({ type: NEWS_PAGE_FETCH_TICKER_NEWS, payload: { data: articles, type: type } });
};


// benzinga
export const addHistoricalArticleProperties = (articles, extraProperties = {}) => {
  const fmt = 'EEE, dd MMM yyyy HH:mm:ss XX' // "Fri, 02 Sep 2023 09:31:00 -0400"
  return articles.map(originalArticle => {
    const article = {};
    const time = parseToMarketTime(originalArticle.created, fmt);

    article.dateDisplay = formatMarketTime(time, 'yyyy-MM-dd');
    article.dateCompare = formatMarketTime(time, 'yyyyMMddHHmmss');
    article.time = formatMarketTime(time, 'HH:mm');
    article.dateTimeDisplay = article.dateDisplay + ' - ' + article.time;
    article.uuid = originalArticle.id;
    article.headline = originalArticle.title;
    article.source = originalArticle.author;
    article.storyurl = originalArticle.url;
    article.T = 'N';
    return { ...article, ...extraProperties };
  });
};


export const addSecProperties = (filings, extraProperties = {}, ticker) => {
  const fmt = "yyyy-MM-dd'T'HH:mm:ssXXX";
  return filings.map(file => {
    // 2023-08-08T17:15:40-04:00
    const time = parseToMarketTime(file['dt-newsid'].split('#')[0], fmt, new Date());

    /* eslint-disable-next-line no-unused-vars */
    let [formType, ...headlineParts] = file.description.replace('Form ', '').split(' - ');
    file.headline = headlineParts.join(' - ');

    // if (ticker && file.ticker !== ticker) {
      // SEC-API has the wrong ticker saved for these. Maybe we can use this later once they fix it.
      // file.headline += ` (Prev. Symbol: ${file.ticker})`;
    // }

    file.dateDisplay = file['filing_date'];
    file.dateCompare = formatMarketTime(time, 'yyyyMMddHHmmss');
    file.time = formatMarketTime(time, 'HH:mm');
    file.dateTimeDisplay = file.dateDisplay + ' - ' + file.time;
    file.uuid = file['acc_num'];
    file.source = 'SEC';
    file['feed-category'] = 'sec';
    return { ...file, ...extraProperties };
  });
};
