import React from 'react';
import PropTypes from 'prop-types';
import { FilterIcon } from 'src/theme/EdgeIcons';
import clsx from 'clsx';
import ConditionalWrapper from 'src/app/components/utility/ConditionalWrapper';
import {
  Tooltip,
  useTheme,
  makeStyles,
  IconButton,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    padding: '0px 4px',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
    '&:focus-visible': {
      ...theme.focus.outline
    },
    '& .MuiSvgIcon-root': {
      marginBottom: 0,
      fontSize: 24,
      color: props => props.iconColor
    },
  },
  iconButtonLabel: {
    margin: 0,
    padding: '1px 0 0 6px',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1
  },
  disabled: {
    color: '#E6E8E5 !important',
    opacity: '.6',
    '& .MuiSvgIcon-root': {
      color: '#E6E8E5 !important',
      opacity: '.6 !important'
    }
  },
}));


function PanelIconButton({
  className,
  Icon,
  iconFontSize,
  url,
  iconColor,
  text,
  shouldHideIconText,
  onClick,
  disabled,
  ariaLabel
}) {
  const theme = useTheme();
  const classes = useStyles({ iconFontSize, iconColor, theme });

  const iconStyle = {
    color: iconColor
  };
  if (iconFontSize) {
    iconStyle.fontSize = iconFontSize;
  }

  return (
    <ConditionalWrapper
      condition={url}
      wrapper={children => <a href={url} target="_blank" rel="noreferrer" style={{ textDecoration: 'none' }}>{children}</a>}
    >
      <ConditionalWrapper
        condition={shouldHideIconText}
        wrapper={children => <Tooltip enterDelay={400} enterNextDelay={0} title={text} placement="top">{children}</Tooltip>}
      >
        <span>
          <IconButton
            disableRipple
            onClick={(event) => { if (onClick) onClick(event); }}
            disabled={disabled}
            aria-label={ariaLabel}
            className={clsx(classes.root, 'ett-panel-icon-button', className, disabled && classes.disabled)}
          >
            <Icon style={iconStyle} />
            {text && !shouldHideIconText && <Typography variant="body2" color="textPrimary" className={classes.iconButtonLabel} style={{ color: iconColor }}>{text}</Typography>}
          </IconButton>
        </span>
      </ConditionalWrapper>
    </ConditionalWrapper>
  );
}


PanelIconButton.propTypes = {
  className: PropTypes.string,
  Icon: PropTypes.oneOfType([PropTypes.func, PropTypes.node, PropTypes.element, PropTypes.object]),
  iconFontSize: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  url: PropTypes.string,
  iconColor: PropTypes.string,
  text: PropTypes.string,
  ariaLabel: PropTypes.string,
  shouldHideIconText: PropTypes.bool,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
};


PanelIconButton.defaultProps = {
  Icon: FilterIcon,
  iconColor: '#E6E8E5',
  disabled: false,
  ariaLabel: ''
};

export default PanelIconButton;
