import { useMemo } from 'react';
import { useSelector } from 'react-redux';


/**
 * Memoizes a makeSelector on the given argument.
 * Multiple arguments should be passed as a single object. Memoized if needed.
 * 
 * Must be used with a makeSelector structure, a function that returns a createSelector
 * callback. The makeSelector must have the argument handling already defined.
 * 
 * If this were not to be used, then 
 *  1) createSelector would be reinstantiated on every render.
 *  2) Sharing the selector between components would bust the cache.
 * 
 * @param {function} makeSelector
 * @param {*} argument
 * @example
 * const makeSelector = () => createSelector(
 *  [
 *    state => state.something, 
 *    (_, id) => id,  // Notice the argument is being handled
 *  ],
 *  (something, id) => something[id]
 * ) 
 * 
 * const Component = ({ id }) => {
 *  const value = useParameterizedFormSelector(makeSelector, id);
 *  // value will be memoized on id, and by createSelector 
 *  // dependancies as usual.
 * }
 * 
 */
export default function useParameterizedSelector(makeSelector, argument) {
  const memoizedSelector = useMemo(() => {
    const selector = makeSelector();
    return state => selector(state, argument);
  }, [argument]);

  return useSelector(memoizedSelector);
}


