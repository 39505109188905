import React from 'react';
import {
  Typography
} from '@material-ui/core';



function Heading({
  className,
  variant = "h1",
  children,
}) {
    return (
        <Typography variant={variant} className={className}>
          {children}
        </Typography>
    )
}

Heading.displayName = 'ActionList.Heading';

export default Heading;
