import {
  Box,
  Button,
  FormControl,
  InputAdornment,
  Typography,
  makeStyles,
  TextField,
  useTheme
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import React, { useState, useMemo, useCallback } from 'react';
import { FilterEntityProvider, FormGroupContext, onAndClick, useFormGroupContext } from 'src/app/slicedForm/FilterForm/context/FilterEntityContext';
import { useEntity, useWatch } from 'src/app/slicedForm/FilterForm/context/useFormEntity';
import { AGGREGATE_SHORT_LABELS, AGGREGATE_LONG_LABELS, AGGREGATES, VALUE_TYPES, allowedAggregatesForInputType } from "src/app/slicedForm/FilterForm/definitions/inputEnums";
import EntityDoubleConfirmationIconButton from 'src/app/slicedForm/FilterForm/elements/EntityDoubleConfirmationIconButton';
import { useColumnDef } from "src/app/slicedForm/shared/context/ColumnDefsProvider";
import ColumnIconSelector from 'src/app/slicedForm/shared/elements/ColumnIconSelector';
import useInputStyles, { ButtonStyleProps, InputStyleProps } from 'src/app/slicedForm/shared/styles/useInputStyles';
import { CheckIcon, EditIcon } from 'src/theme/EdgeIcons';
import WithColumnPickerForm from '../FilterForm/elements/WithColumnPickerForm';
import FilterEntity from '../FilterForm/elements/FilterEntity';
import SimpleDropdown from '../FilterForm/elements/SimpleDropdown';
import { useFormDispatch } from '../shared/context/FormProvider';
import { getFilterEntityDefaults } from '../shared/schema/schemaBuilder';
import { useAggregateEntityContext } from './aggregateEntityContext';
import { DELETE_ENTITY } from '../FilterForm/reducers/filterReducer';
import { usePrevious } from 'src/hooks/usePrevious';
import ColoredDropdown from '../FilterForm/elements/ColoredDropdown';



const useStyles = makeStyles(theme => ({
  root: {},
  center: {
    flex: 1,
  },
  right: {
    flex: 1,
    flexGrow: 0,
  },
  left: {
    // flex: 1,
    width: '36%',
    flexBasis: '36%',
    flexShrink: 0
  },
  colDefbutton: {
    fontWeight: 500
  },
  header: {
    flex: 1
  },
  addConditionButton: {
    marginTop: theme.spacing(1),
    marginLeft: 75 + 12, // useInputStyles FIXED_WIDTHS
  },
  conditionCont: {
    borderTop: `1px solid ${theme.palette.background.lightBorder}`,
    position: 'relative',
    marginTop: theme.spacing(1),
  },
  condition: {
    paddingTop: theme.spacing(1),
    // paddingTop: theme.spacing(.5),
    // paddingBottom: theme.spacing(.5),
    flex: 1
  },
  labelText: {
    marginLeft: 12,
    marginTop: 2,
    width: 70,
    textAlign: 'right',
    color: theme.palette.text.secondary,
  }
}));


function AggregateEntityHeader({ className }) {
  const classes = useStyles();
  const theme = useTheme();
  const { id, type, tree, disabled } = useAggregateEntityContext();
  const { useRegister } = useEntity(id);
  const inputClasses = useInputStyles();
  const columnInputProps = useRegister('target.column');
  const aggregateInputProps = useRegister('conditional_agg', { refName: 'innerRef', valueName: 'selected' });
  const labelInputProps = useRegister('label');
  const colDef = useColumnDef(columnInputProps?.value);

  const showTotalRecords = aggregateInputProps?.selected === AGGREGATES.CNT;

  const aggregateOptions = useMemo(() => {
    return allowedAggregatesForInputType(colDef?.input).map(agg => {
      return {
        value: agg,
        label: AGGREGATE_LONG_LABELS[agg],
        color: theme.palette.aggregates?.[agg]
      }
    })
  }, [colDef?.input]);

  const handleUpdateColumn = useCallback((newColDef) => {
    const defaults = getFilterEntityDefaults(newColDef);
    if (!defaults || !newColDef) {
      console.warn(`No defaults/newColDef found for column ${newColDef}`);
      return;
    }

    columnInputProps?.onChange?.(newColDef?.name, { colDef: newColDef });
  }, [columnInputProps?.onChange, getFilterEntityDefaults])

  return (
    <>
      <Box
        className={classes.left}
        display="flex"
        alignItems="center"
      >
        <ColoredDropdown
          className={clsx(
            inputClasses.formControl,
            inputClasses.comparisonSelectControlLarger,
            '--first'
          )}
          inputClassName={clsx('--less-gutter', inputClasses.purpleHighlight)}
          options={aggregateOptions}
          label="Select aggregation function"
          disabled={disabled}
          {...aggregateInputProps}
        />

        <FormControl
          className={clsx(
            inputClasses.formControl,
          )}
        >
          {showTotalRecords
            ? (
              <Button
                {...ButtonStyleProps}
                className={clsx(
                  classes.colDefbutton,
                  inputClasses.button,
                  inputClasses.buttonAsInput,
                  inputClasses.backgroundButton,
                )}
                disabled={true}
              >
                Records
              </Button>
            ) : (
              <WithColumnPickerForm
                anchorPosition="left"
                onSelect={handleUpdateColumn}
              >
                <Button
                  {...ButtonStyleProps}
                  className={clsx(
                    classes.colDefbutton,
                    inputClasses.button,
                    inputClasses.buttonAsInput,
                    inputClasses.backgroundButton
                  )}
                  disabled={disabled}
                  aria-label="Open column selection popup"
                  title={colDef?.label}
                  ref={columnInputProps?.ref}
                >
                  <ColumnIconSelector name={colDef?.name} floating />
                  {colDef?.label || 'Select...'}
                </Button>
              </WithColumnPickerForm>
            )}
        </FormControl>
      </Box>

      <Typography className={classes.labelText}>Label:</Typography>

      {/* TODO: Move to component  */}
      <FormControl
        variant="outlined"
        innerRef={labelInputProps?.ref}
        error={Boolean(labelInputProps?.error)}
        className={clsx(
          inputClasses.formControl,
          inputClasses.formControlValueTypePlusValue,
          (labelInputProps?.error) && inputClasses.formControlError,
          (labelInputProps?.error) && 'sf-form-control-error-flash'
        )}
      >
        <TextField
          InputProps={{
            ...InputStyleProps,

          }}
          className={clsx(
            inputClasses.input,
            inputClasses.lowlight
          )}
          placeholder={'Label'}
          disabled={disabled}
          error={labelInputProps?.value === ''}
          value={labelInputProps.value}
          onChange={(event) => labelInputProps.onChange(event.target.value || '')}
        />
      </FormControl>
    </>
  )
}


function AggregateEntity({ className }) {
  const classes = useStyles();
  const theme = useTheme();
  const inputClasses = useInputStyles();
  const dispatch = useFormDispatch();
  const { id, type, tree, disabled } = useAggregateEntityContext();
  const { groupId } = useFormGroupContext();
  const parentGroupContext = useFormGroupContext();
  const [deleteConfirming, setDeleteConfirming] = useState(false);

  // TODO: disabled / edit locked?

  // Aggs are both entities and groups. This is ugly.

  const handleAddFilter = (colDef) => {
    onAndClick(id, colDef, dispatch)
  }

  return (
    <Box className={className}>

      <Box
        display="flex"
        alignItems="center"
      >
        <AggregateEntityHeader />

        <Box
          display="flex"
          justifyContent="flex-end"
          flex="1"
        >
          {!tree.length && (
            <WithColumnPickerForm
              anchorPosition="left"
              onSelect={handleAddFilter}
            >
              <Button
                {...ButtonStyleProps}
                aria-label="Add another aggregate to this group"
                disabled={disabled}
                className={clsx(
                  // classes.addConditionButton,
                  inputClasses.button,
                  inputClasses.buttonAsInput,
                  inputClasses.backgroundButton
                )}
              >
                + Add Conditions
              </Button>
            </WithColumnPickerForm>
          )}
          <EntityDoubleConfirmationIconButton
            confirming={deleteConfirming}
            BaseIcon={ClearIcon}
            baseLabel="Delete this filter"
            baseColor={theme.palette.primary.main}
            ConfirmIcon={CheckIcon}
            confirmLabel={'Confirm delete?'}
            confirmColor={theme.palette.text.negative}
            disabled={disabled}
            setConfirming={setDeleteConfirming}
            onAccept={() => dispatch({
              type: DELETE_ENTITY,
              payload: {
                id,
                groupId
              }
            })}
          />
        </Box>
      </Box>

      <FormGroupContext.Provider value={{
        ...parentGroupContext,
        groupId: id,
        groupType: type,
      }}>
        {Boolean(tree.length) && (
          <div className={classes.conditionCont}>
            {/* <Typography style={{ fontWeight: 400, fontSize: 13, marginLeft: 75 + 12 }}>Conditions:</Typography> */}
            {tree.map((node, i) => {
              return (
                <FilterEntityProvider id={node.id} type={node.type} key={node.id} disabled={disabled}>
                  <FilterEntity className={classes.condition} />
                </FilterEntityProvider>
              )
            })}
          </div>
        )}
      </FormGroupContext.Provider>

    </Box>
  )
}

export default AggregateEntity;
