import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  CircularProgress,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paperAlt,
    position: 'absolute',
    zIndex: 3,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
    padding: 15
  },
  container: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
}));


function MosiacLoadingOverlay({ className, show }) {
  const classes = useStyles();

  if (!show) {
    return null
  }

  return (
    <div className={clsx(className, classes.root)}>
      <div className={classes.container}>
        <CircularProgress size={18} />
      </div>
    </div>
  )
}


MosiacLoadingOverlay.propTypes = {
  className: PropTypes.string,
  show: PropTypes.bool
}

MosiacLoadingOverlay.defaultProps = {
  show: false
}

export default MosiacLoadingOverlay;


