import ChartToolbarButtonBase from './ChartToolbarButtonBase';


export default class TemplateAndDropdownButton extends ChartToolbarButtonBase {

  constructor(
    widget,
    name,
    createButtonArgs = {},
    onTemplateClick,
    onDropdownClick
  ) {
    super(widget, createButtonArgs);
    this.element = widget.current.createButton(createButtonArgs);
    this.name = name;
    this.onTemplateClick = onTemplateClick;
    this.onDropdownClick = onDropdownClick;
    this.isAnimating = false;
    this.render();
    this.setupAnimation();
  }

  setName(newName) {
    this.name = newName;
    if (!this.element) {
      this.render();
    } else {
      this.element.querySelector('.js-button-text').innerText = this.name;
    }
  }


  onClick(handler) {
    super.onClick(() => {
      this.animate();
      handler();
    });
  }

  animate() {
    if (this.isAnimating) return;

    this.isAnimating = true;
    const spinning = this.element.querySelector('.spinningCloud-NezC5dyJ');
    const arrowGap = this.element.querySelector('.arrowGap-NezC5dyJ');
    const arrow = this.element.querySelector('.arrow-NezC5dyJ');
    const check = this.element.querySelector('.check-NezC5dyJ');

    const spinningCloudLen = spinning.getTotalLength();
    spinning.setAttribute('stroke-dasharray', '49 13');

    spinning.style.display = 'inline';
    check.style.display = 'none';
    arrow.style.display = 'none';
    arrowGap.style.display = 'none';

    let currLen = 0;
    const interval = setInterval(() => {
      spinning.setAttribute('stroke-dashoffset', currLen);
      currLen += 1;
      if (currLen >= spinningCloudLen) {
        currLen = 0;
      }
    }, 20);

    setTimeout(() => {
      clearInterval(interval);
      spinning.setAttribute('stroke-dasharray', '0');
      check.style.display = 'inline';

      setTimeout(() => {
        this.setupAnimation();
      }, 2000);
    }, 2000);
  }


  setupAnimation() {
    const cloudIcon = this.element.querySelector('#cloud-icon');
    const paneIcon = this.element.querySelector('#pane-icon');

    paneIcon.style.display = 'block';
    cloudIcon.style.display = 'none';

    const spinning = this.element.querySelector('.spinningCloud-NezC5dyJ');
    const dotted = this.element.querySelector('.dottedCloud-NezC5dyJ');
    const check = this.element.querySelector('#check');
    const arrowGap = this.element.querySelector('.arrowGap-NezC5dyJ');
    const arrow = this.element.querySelector('.arrow-NezC5dyJ');

    spinning.style.display = 'inline';
    spinning.setAttribute('stroke-dasharray', '0');

    dotted.style.display = 'none';

    check.style.display = 'none';
    check.querySelector('path').setAttribute('stroke-dasharray', 0);

    arrow.style.display = 'inline';
    arrow.style.opacity = 1;
    arrow.style.transform = 'translateY(0)';

    arrowGap.style.opacity = 1;
    arrowGap.style.display = 'inline';
    arrowGap.style.fill = '#131722';
    this.isAnimating = false;
  }


  bind() {
    this.element.querySelector('#layout-btn').addEventListener('click', () => {
      this.onTemplateClick();

      const cloudIcon = this.element.querySelector('#cloud-icon');
      const paneIcon = this.element.querySelector('#pane-icon');

      paneIcon.style.display = 'none';
      cloudIcon.style.display = 'block';
      this.animate();
    });
    this.element.querySelector('#dropdown-btn').addEventListener('click', () => {
      this.onDropdownClick();
    });
  }



  render() {
    this.element.innerHTML =
      `<div class="wwrap" style="height:100%;display:flex;">
        <div class="button-khcLBZEz apply-common-tooltip" data-role="button" id="layout-btn" title="Save current indicator template">
          <div 
              data-role="button" 
              class="button-OhqNVIYA button-ptpAHg8E withText-ptpAHg8E button-GwQQdU8S apply-common-tooltip isInteractive-GwQQdU8S accessible-GwQQdU8S ${this.name === 'Template' ? 'disabled' : ''}"
              style="padding-right:6px;padding-left:5px;margin-left:-5px;display:flex;align-items:center;justify-content: center;height:100%;"
          >
            <span class="icon-9pA37sIi">
              <svg xmlns="http://www.w3.org/2000/svg" class="container-NezC5dyJ icon-9pA37sIi unsaved-NezC5dyJ" version="1.1" width="28" height="28" viewBox="0 0 28 28" id="cloud-icon">
                <g fill="none">
                  <path class="dottedCloud-NezC5dyJ" stroke="currentColor" stroke-dasharray="3.5,2.5" d="M21.5 21.5h-14a5 5 0 1 1 .42-9.983 7.5 7.5 0 0 1 14.57 2.106 4.002 4.002 0 0 1-.99 7.877z"></path>
                  <path class="spinningCloud-NezC5dyJ" stroke="currentColor" stroke-dasharray="0" stroke-dashoffset="0" d="M21.5 21.5h-14a5 5 0 1 1 .42-9.983 7.5 7.5 0 0 1 14.57 2.106 4.002 4.002 0 0 1-.99 7.877z"></path>
                  <path class="arrowGap-NezC5dyJ" d="M11 20h6v5h-6z"></path>
                  <g class="arrow-NezC5dyJ" stroke="currentColor">
                    <path stroke-linecap="square" d="M14.5 14.5v10"></path>
                    <path d="M11 17l3.5-3.5L18 17"></path>
                  </g>
                  <g class="check-NezC5dyJ" stroke="currentColor">
                    <path stroke-dasharray="0" d="M10 15l2.5 2.5L18 12"></path>
                  </g>
                </g>
                </svg>
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 28 28" width="28" height="28" id="pane-icon">
                    <path fill="currentColor" fill-rule="evenodd"
                          d="M8 7h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1H8a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1zM6 8c0-1.1.9-2 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H8a2 2 0 0 1-2-2V8zm11-1h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1V8a1 1 0 0 1 1-1zm-2 1c0-1.1.9-2 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2V8zm-4 8H8a1 1 0 0 0-1 1v3a1 1 0 0 0 1 1h3a1 1 0 0 0 1-1v-3a1 1 0 0 0-1-1zm-3-1a2 2 0 0 0-2 2v3c0 1.1.9 2 2 2h3a2 2 0 0 0 2-2v-3a2 2 0 0 0-2-2H8zm9 1h3a1 1 0 0 1 1 1v3a1 1 0 0 1-1 1h-3a1 1 0 0 1-1-1v-3a1 1 0 0 1 1-1zm-2 1c0-1.1.9-2 2-2h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2h-3a2 2 0 0 1-2-2v-3z" id="panes"></path>
                     <g class="check-NezC5dyJ" stroke="currentColor" id="check">
                      <path stroke-dasharray="0" d="M10 15l2.5 2.5L18 12"></path>
                    </g>
                </svg>
            </span>
            <div class="js-button-text text-9pA37sIi">${this.name}</div>
          </div>
        </div>
        <div class="js-save-load-menu-open-button button-GwQQdU8S isInteractive-GwQQdU8S apply-common-tooltip ett-modify-arrow-bg" title="Edit indicator templates" data-role="button" id="dropdown-btn">
            <div class="arrow-reABrhVR">
                <div class="arrowWrap-reABrhVR"><span class="icon-XIHzWm6B"><svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 8" width="11" height="8"><path fill="currentColor" d="M0 1.475l7.396 6.04.596.485.593-.49L16 1.39 14.807 0 7.393 6.122 8.58 6.12 1.186.08z"></path></svg></span>
                </div>
            </div>
        </div>
      </div>`;

    this.bind();
  }
}
