import {STRUCTURAL_TYPES} from "src/app/slicedForm/mapping/mappingDirections/index";
import { useParameterizedFormSelector } from "src/app/slicedForm/shared/context/FormProvider";
import { makeSelectSliceChildEntities } from 'src/app/slicedForm/FilterForm/reducers/filterReducer';
import { useMemo } from 'react';
import { getSliceRange } from 'src/app/slicedForm/utility/filterTimeSliceFunctions';
import { useDeepCompareMemo } from "src/hooks/useDeepCompare";

/**
 * @typedef {Object} SliceMeta
 * @property {number} index
 * @property {string} start - 'HH:mm'
 * @property {string} end - 'HH:mm'
 * @property {Slice[]} slices
 *
 */


/**
 * Returns 'null' if not a slice child, or SliceMeta object if it is.
 * @param {string} id
 * @param {string} groupId
 * @param {keyof STRUCTURAL_TYPES} groupType
 * @return {SliceMeta|null}
 */
function useEntitySlices(id, groupId, groupType) {
  const sliceEntities = useParameterizedFormSelector(makeSelectSliceChildEntities, groupId);

  // TODO: This causes a double-render, and I can't figure out why. Clearly the slices are building twice,
  // but why doesn't deepCompare fix it?
  return useDeepCompareMemo(() => {
    if (groupType !== STRUCTURAL_TYPES.SLICE_GROUP || sliceEntities === undefined) {
      return undefined;
    }

    const index = sliceEntities.findIndex(slice => slice.id === id);
    const [start, end] = getSliceRange(id, sliceEntities);
    return { index, start, end, slices: sliceEntities }
  }, [sliceEntities]);
}


export default useEntitySlices;
