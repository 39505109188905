import _set from 'lodash/set';


/*
 * Various methos for converting nested object/arrays into a flat object of path strings.
 *
 * { a: { b: [c, d] }} => { 'a.b[0]': c, 'a.b[1]': d }
 *
 * We store them like this inside SlicedForms reducers.
 */

const isObject = (val) => val && typeof val === 'object';

const isFinalArray = (val) => {
  return (
    Array.isArray(val)
    && val.every((item) => !isObject(item) && !Array.isArray(item))
  )
}

const isFinalValue = (val) => !isObject(val) || isFinalArray(val);


/**
 * @param {Object} object 
 * @param {string} [initialPathPrefix] - accumulator, can start with a prefix
 * @returns {Object<string, any>}
 */
export function flattenObjectToPaths(object, initialPathPrefix = null) {
  if (isFinalValue(object)) {
    return [{ [initialPathPrefix]: object }]
  }

  const prefix = initialPathPrefix
    ? Array.isArray(object)
      ? initialPathPrefix
      : `${initialPathPrefix}.`
    : ''

  return Object.keys(object)
    .flatMap((key) =>
      flattenObjectToPaths(
        object[key],
        Array.isArray(object) ? `${prefix}[${key}]` : `${prefix}${key}`,
      ),
    )
    .reduce((acc, path) => ({ ...acc, ...path }))
}


/**
 * @param {Object<string, any>} object 
 * @param {string} [initialPathPrefix=null]
 * @returns {Object}
 */
export function unflattenPathsToObject(data, initialPrefix = null) {

  if (Object(data) !== data || Array.isArray(data)) return data;
  const obj = Object.keys(data).reduce(function (acc, key) {
    return _set(acc, key, data[key]);
  }, {});

  if (initialPrefix && obj?.[initialPrefix]) {
    return obj[initialPrefix];
  }

  return obj;
}



