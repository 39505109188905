import {
  getNodeTypeCategory,
  STRUCTURAL_CATEGORIES,
  STRUCTURAL_TYPES,
  STRUCTURAL_TYPE_RULES,
} from 'src/app/slicedForm/mapping/mappingDirections/index';
import { decideProfileNodeType, FILTER_FORM_TYPES } from 'src/app/slicedForm/mapping/mappingDirections/index';
import {
  createEntityAndLeaf,
  createEntityValues
} from 'src/app/slicedForm/mapping/formTreeManipulation';

/**  @typedef {import('./index.js').ProfileStruct} ProfileStruct */




/**
 * @param {object} state
 * @param {keyof FILTER_FORM_TYPES} formType
 **/
export default function ProfileToForm(state, formType) {
  const entities = {};
  const profiles = [];

  if (!formType) {
    throw new Error('formType is required, in order to deserialize profile after saving');
  }

  const out = () => {
    state.profiles.forEach(mapProfile);
    const mapped = {
      ...state, // allow extra properties. This might bite me later...
      entities,
      profiles,
      activeProfile: state.activeProfile,
      expressions: state.expressions,
      formType
    };

    if (process.env.REACT_APP_USERS_LAMBDA_STAGE !== 'prod') {
      console.log('PROFILE_TO_FORM INIT: ', mapped);
    }

    return mapped;
  };



  /**  @param {ProfileStruct} profile */
  const mapProfile = (profile) => {
    const { [formType]: _, ...rest } = profile;

    if (!(formType in profile)) {
      throw new Error(`profileToForm - Profile does not have required attribute for formType, ${formType}, ${profile?.name}`);
    }

    let objects;
    if (formType === FILTER_FORM_TYPES.AGGREGATE) {
      // Aggregates have an array as the root node. That does not work with any rendering logic.
      // Add a fake wrapper node to make life worth living.
      objects = { [STRUCTURAL_TYPES.ROOT]: profile[formType] };
    } else {
      objects = profile[formType];
    }


    const recurse = (node) => {
      const type = decideProfileNodeType(node);
      const category = getNodeTypeCategory(type);
      const treeKey = STRUCTURAL_TYPE_RULES[type]?.treeKey;

      switch (category) {
        case STRUCTURAL_CATEGORIES.GROUP_WITHOUT_ENTITY: {
          const { [treeKey]: children } = node;
          const { leaf } = createEntityAndLeaf({
            type,
            tree: children.map(recurse),
          });

          return leaf;
        }
        case STRUCTURAL_CATEGORIES.GROUP_WITH_ENTITY: {
          const { [treeKey]: children, ...values } = node;
          const { id, entity, leaf } = createEntityAndLeaf({
            type,
            tree: children.map(recurse),
            values: createEntityValues(values)
          });

          entities[id] = entity;
          return leaf;
        }
        case STRUCTURAL_CATEGORIES.ENTITY: {
          const { id, entity, leaf } = createEntityAndLeaf({
            type,
            values: createEntityValues(node)
          });

          entities[id] = entity;

          return leaf;
        }
        default: {
          throw new Error(`Unknown node category "${category}<${type}>", node ${JSON.stringify(node)}}`);
        }
      }
    };


    profiles.push({
      ...rest,
      root: recurse(objects),
    });
  };



  return out();
}
