class RewardfulClient {
  isReady = false;
  campaignsWithCookiesAllowed = [
    '1aad9901-89ea-4b99-b198-75fdf4c23c14', // Development Affiliates
    '897a8747-7f56-489c-af50-24f8e2450e36', // Cookie Affiliates
    '41c3b399-6671-4e1f-a39e-6bfdfd9f4b07'  // Lifetime Cookie Affiliates
  ];

  getReferralCode() {
    return window.Rewardful.referral;
  }


  getCampaign() {
    return window.Rewardful.campaign;
  }

  getCoupon() {
    return window.Rewardful.coupon;
  }

  getCouponCode() {
    const coupon = this.getCoupon();
    return coupon?.id || null;
  }

  setSource(newSource) {
    window.rewardful('ready', function () {
      window.rewardful('source', newSource);
    });
  }

  affiliateCookieAllowed() {
    const campaign = this.getCampaign();
    return (campaign && this.campaignsWithCookiesAllowed.includes(campaign.id));
  }

  async getReferralIDFromPromoCode(newSource) {
    const originalValue = this.getReferralCode();
    window.rewardful('source', newSource);

    const r = await this.waitUntil(() => this.getReferralCode() !== originalValue);
    const newValue = this.getReferralCode();
    console.log(`rw_source (${originalValue})->(${newValue}) : ${r}`);
    return newValue;
  }

  async waitUntil(condition) {
    return await new Promise(resolve => {
      let i = 0;
      const interval = setInterval(() => {
        if (i >= 40) {
          clearInterval(interval);
          resolve('Max limit reached');
        }
        if (condition()) {
          clearInterval(interval);
          resolve('Change detected!');
        }

        i++;
      }, 150);
    });
  }
}


const rewardfulService = new RewardfulClient();
export default rewardfulService;
