import React, { createContext, useState, useContext, useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _noop from 'lodash/noop';
import ContainedModal from 'src/app/components/utility/ContainedModal';
import { makeStyles, alpha } from '@material-ui/core';
import { useFormSelector } from './FormProvider';
import { PREDEF_PREFIX } from 'src/redux/profileSettings/profileSettingsConfig';
import { selectActiveProfile } from '../reducers/profileReducer';
import ModalContentCopyPredefined from '../elements/ModalContentCopyPredefined';
import MarketingModalUpgradeFallback from 'src/app/components/MarketingModal/MarketingModalUpgradeFallback';
import MarketingModalExpressionsUpgrade from 'src/app/components/MarketingModal/MarketingModalExpressionsUpgrade';


const ModalContext = createContext({
  openOverlayModal: _noop,
  closeOverlayModal: _noop,
  open: false,
  registeredModals: [],
});

const emptyObj = {};


export function ModalConfig({ name, children, ...props }) {
  // The parent FormOverlayModalProvider will register these and render them conditionally.
  return null;
}

ModalConfig.propTypes = {
  name: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
}


const DEFAULT_MODALS = {
  copy_default: {
    content: <ModalContentCopyPredefined showCopyButton />,
    modalProps: {
      className: 'modal--copy-profile',
      disablePortal: true
    }
  },
  upgrade_customization: {
    content: <MarketingModalUpgradeFallback />,
    modalProps: {
      className: 'modal--upgrade-cus',
      width: 450,
      sharpBorder: true,
    }
  },
  upgrade_expression: {
    content: <MarketingModalExpressionsUpgrade />,
    modalProps: {
      className: 'modal--upgrade-exp',
      width: 450,
      sharpBorder: true,
    }
  }
}

/**
 * A modal that sits at the top of the form. Its content is dynamic,
 * the trigger callback can choose which modal to open, and the client
 * can specify different components to use per modal.
 *
 * Modals are defined Declaratively, via <ModalConfig /> elements nested
 * as direct children of the FormOverlayModalProvider.
 *
 * Must sit below FormProvider
 *
 * Use cases:
 *  - "Copy profile?" modal
 *  - "Upgrade Account?" modal
 **/
function FormOverlayModalProvider({
  children,
}) {
  const [currentModal, setCurrentModal] = useState(null);
  const [currentModalProps, setCurrentModalProps] = useState(null);
  const [currentModalContentProps, setCurrentModalContentProps] = useState(null);
  const modals = {};
  const rest = [];

  // Register child modals
  React.Children.forEach(children, child => {
    if (child.type === ModalConfig && child?.props?.name) {
      const { name, children, ...modalProps } = child.props;
      modals[name] = { content: children, modalProps }
    } else {
      rest.push(child);
    }
  });

  // Fill defaults if missing
  Object.keys(DEFAULT_MODALS).forEach(name => {
    if (!(name in modals)) {
      modals[name] = DEFAULT_MODALS[name];
    }

  })

  const childCacheKey = Object.keys(modals);

  const openModal = useCallback((name, providedProps = emptyObj) => {
    if (!(name in modals)) {
      console.warn(`Modal ${name} not found. Register a modal with <ModalConfig name="${name}">. Allowed modals:`, childCacheKey);
      return;
    }
    setCurrentModal(name);
    setCurrentModalProps(modals[name]?.modalProps || emptyObj);
    setCurrentModalContentProps(providedProps);
    // setCurrentModal({ name, providedProps });
  }, [...Object.keys(modals)])


  const closeModal = useCallback(() => {
    setCurrentModal(null);
  }, []);

  const value = useMemo(() => {
    return {
      open: currentModal || false,
      openOverlayModal: openModal,
      closeOverlayModal: closeModal,
      registeredModals: Object.keys(modals),
    }
  }, [...Object.keys(modals), openModal, closeModal]);


  const _open = Boolean(currentModal && modals[currentModal]?.content);

  return (
    <ModalContext.Provider value={value}>
      {rest}
      <ContainedModal
        blur
        disablePortal={false}
        {...currentModalProps}
        open={_open}
        onClose={closeModal}
      >
        {Boolean(_open) && React.cloneElement(modals[currentModal].content, {
          onClose: closeModal,
          ...currentModalContentProps
        })}
      </ContainedModal>
    </ModalContext.Provider>
  );
}


FormOverlayModalProvider.propTypes = {
  disabled: PropTypes.bool,
  children: PropTypes.node.isRequired,
};


export default FormOverlayModalProvider;


export function useFormOverlayModal() {
  return useContext(ModalContext);
}


