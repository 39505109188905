import PromiseHelper from 'src/utils/PromiseHelper'

/**
 * @typedef {Object} BatchRequst
 * @property {string} id - The id of the data source. Usually just 'watchlist' or 'news', so they can be treated separately
 * @property {Promise} promise - The Promise object currently fetching data
 * @property {function(object[])} onResolve - Called with the resolved Promise data
 */

/**
 * Tries to ensure each Top List instance renders its updates at the exact same time.
 * If a request comes in, the batcher will wait 120ms for other requests.
 * After 120ms, it resolves all in the batch using Promise.raceAllSettled, ensuring
 * each of them returns at the same time.
 *
 * @type {Object} DataSourceResponseBatcher
 * @property {number} batchingTime - milliseconds
 * @property {Map<string, boolean>} dataSources - keeps track of the different types of data connected
 * @property {BatchRequst[]} pendingRequests - The current processing queue
*/
const DataSourceResponseBatcher = {
  batchingTime: 150,
  dataSources: new Map(),
  pendingRequests: [],

  /**
   * @param {BatchRequst} request - The request to register
   * @param {boolean} [skipBatching=false] - Don't attempt to batch this request, resolve it immediatly
  */
  registerRequest: async function(request, skipBatching = false) {
    if (skipBatching || this.dataSources.size <= 1) {
      return request.onResolve(await request.promise);
    }

    this.pendingRequests.push(request);

    if (this.pendingRequests.length !== 1) {
      return;
    }

    await PromiseHelper.sleep(this.batchingTime);

    const currentBatch = this.pendingRequests;
    this.pendingRequests = [];

    const response = await PromiseHelper.raceAllSettled(currentBatch.map(b => b.promise), 4000, false);

    response.forEach((promResult, idx) => {
      const records = (promResult.status === 'rejected') ? false : promResult.value;
      currentBatch[idx].onResolve(records);
    });
  },

  registerDataFeed: function(id) {
    this.dataSources.set(id, true);
  },

  unregisterDataFeed: function(id) {
    this.dataSources.delete(id);
  }
};


export default DataSourceResponseBatcher;
