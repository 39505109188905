import axios from 'axios';
import { stagedApis } from 'src/constants';


export default axios.create({
  baseURL: stagedApis.edgeProxyApi
});

export const unauthorizedEdgeProxyApi = axios.create({
  baseURL: stagedApis.edgeProxyApi
});

/** 
 * @typedef {Object} TickerSearchResponse 
 * @property {string} symbol
 * @property {string} name
 */

/** 
 * Single search-ticker endpoint to be used accross the app.
 * Currently unauthorized for performance.
 * @async
 * @param {string} query 
 * @returns {Promise<{symbol: string, name: string}[]>}
 */
export async function searchTicker(query) {
  const response = await unauthorizedEdgeProxyApi.get(`/search-ticker/${query.toUpperCase()}`);
  return response?.data || [];
}