import { useCallback } from 'react';
import { COLUMN_RESIZERS, applyResize } from 'src/utils/agGridFunctions';

function useResizeContextMenu({ handleSetResizeKey }) {

  return useCallback((contextMenuParams) => {
    const gridRef = { current: contextMenuParams };

    const apply = (key) => {
      applyResize(gridRef, key);

      if (handleSetResizeKey) {
        handleSetResizeKey(key);
      } else {
        console.error(new Error("'handleSetResizeKey' property not passed to resizeContextMenu. You must pass this to save resize settings."));
      }
    }

    return [
      {
        name: 'Fit to data',
        action: () => apply(COLUMN_RESIZERS.FIT_TO_DATA)
      },
      {
        name: 'Fit to headers',
        action: () => apply(COLUMN_RESIZERS.FIT_TO_HEADERS)
      },
      {
        name: 'Fit to panel',
        action: () => apply(COLUMN_RESIZERS.FIT_TO_PANEL)
      }
    ]
  }, []);
}

export default useResizeContextMenu;
