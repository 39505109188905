
export const DEFUALT_MENU_ITEMS = {
  separator: 'separator',
  export: 'export',
  csvExport: 'csvExport',
  excelExport: 'excelExport',
  copy: 'copy',
  copyWithHeaders: 'copyWithHeaders',
  copyWithGroupHeaders: 'copyWithGroupHeaders',
  autosize: 'autoSizeAll',
  expand: 'expandAll',
  contract: 'contractAll'
}


export const MENU_ITEM_CLASSES = ['ag-ett-ctx-menu-item']
