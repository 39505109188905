import React, { useContext, useMemo, useCallback } from 'react';
import PropTypes from "prop-types";
import ActionList from 'src/app/components/ActionList';
import ColumnSelectionInner from "src/app/slicedForm/ColumnForm/elements/ColumnSelectionInner";
import FormModal from 'src/app/components/FormModal';
import HoverFieldDefinition from 'src/app/slicedForm/ColumnForm/elements/HoverFieldDefinition';
import { ColumnDefsContext } from 'src/app/slicedForm/shared/context/ColumnDefsProvider';
import { ColumnSimpleRow } from 'src/app/slicedForm/ColumnForm/elements/ColumnListItems';
import { ExpressionSimpleRow } from 'src/app/slicedForm/ColumnForm/elements/ExpressionListItems';
import { makeStyles, useMediaQuery } from '@material-ui/core';


export function SimpleRow({ index, style, data }) {
  const colDef = data.filteredColumns[index];
  const selected = data.selectedColumns.includes(colDef.name)

  return (
    <ActionList.Item
      style={style}
      selected={selected}
      pl={13}
      pr={13}
      selectionVariant='single'
      role="menuitem"
      onSelect={() => data.onSelect(colDef, selected)}
      title={colDef.label}
      {...data.getOptionProps(index, { selected })}
    >
      {colDef.label}
      <ActionList.Trailing>
        <HoverFieldDefinition
          colDef={colDef}
          anchorPosition="right"
        />
      </ActionList.Trailing>
    </ActionList.Item>
  )
}

/** We have many instances of columnPicker modal. Don't want to rewrite a media query every time */
export const useColumnPickerFormWidth = (breakpoint = 'md', smaller = 400, larger = 500) => {
  const isSmall = useMediaQuery(theme => theme.breakpoints.down(breakpoint), { noSsr: true });
  return isSmall ? smaller : larger
}


const useStyles = makeStyles(theme => ({
  root: {},
  panelHeaderColor: {
    backgroundColor: theme.palette.background.panelHeader
  },
  relative: {
    position: 'relative',
  }
}));

/**
 * A minimal version of the ColumnForm, intended to be used within a FilterForm as a popover.
 * You can override ColumnContext, in order to give the window a different set of columns to choose from.
 * 
 * If no columnDefs provided, then it uses the full parent context's defs, EXCEPT it also filters
 * out any columns that have directlySelectable set to false. These columns are only valid within
 * Comparison dropdowns, not as a target themselves.
 */
function ColumnPickerForm({
  onSelect,
  columnDefs,
}) {
  const classes = useStyles();
  const parentContext = useContext(ColumnDefsContext);

  const childContext = useMemo(() => {
    if (!columnDefs) {
      return {
        ...parentContext,
        columnDefs: parentContext.columnDefs.filter(col => col?.directlySelectable !== false)
      }
    }

    return {
      ...parentContext,
      columnDefs
    }
  }, [parentContext, columnDefs])

  const handleSelect = useCallback((colDef) => {
    onSelect && onSelect(colDef);
  }, [onSelect]);

  return (
    <ColumnDefsContext.Provider value={childContext}>
      <FormModal.Body className={classes.root}>
        <ColumnSelectionInner
          leftColumnClassName={classes.panelHeaderColor}
          rightColumnClassName={classes.panelHeaderColor}
          leftColumnIsDrawer={false}
          autoFocusSearch
          showProfileControls={false}
          onSelect={handleSelect}
          leftColumnWidth={160}
          ColumnRowComponent={ColumnSimpleRow}
          ExpressionRowComponent={ExpressionSimpleRow}
        />
      </FormModal.Body>
    </ColumnDefsContext.Provider>
  )
}

ColumnPickerForm.propTypes = {
  onSelect: PropTypes.func,
  columnDefs: PropTypes.arrayOf(PropTypes.object),
}


ColumnPickerForm.defaultProps = {
  columnDefs: null,
  useControlledQuery: false,
}

export default ColumnPickerForm;
