import { TagManagerService } from 'src/services/tagManagerService';


export const pushBillingSuccessGTM = (props) => {
  const { user, subscriptionStatus, plan, subscriptionId, intentId, partnerCode, promotionCode } = props;

  if (!plan || !user) {
    console.error('pushBillingSuccessGTM: Missing required data', plan, user);
    return;
  }

  const unitAmount = plan?.unit_amount || 0;

  TagManagerService.push({
    event: 'begin_subscription',
    transaction_id: subscriptionId,
    subscription_status: subscriptionStatus,
    currency: 'USD',
    coupon: promotionCode || partnerCode,
    subscription_term: plan.recurring.interval,
    value: unitAmount / 100,
    // partner_code: partnerCode,
    items: [{
      item_id: plan.id,
      item_variant: plan.nickname,
      item_name: plan.metadata?.plan_level,
      item_category: 'Subscription Plan',
      affiliation: 'Online Subscription',
      price: unitAmount / 100,
      quantity: 1,
      // tax_behavior: plan.tax_behavior,
      // promotion_name: promotionCode,
    }]
  });
}

export const pushEventSignupGTM = (props) => {
  TagManagerService.push({ event: 'sign_up', step: 'email_sent', ...props, });
}

export const pushAddToCartGTM = (props) => {
  const { user, cart, plan, invoice } = props;
  const { intentType, subscriptionId } = cart;
  // let subscriptionStatus = intentType === 'setup_intent' ? 'trialing' : 'active';

  const unitAmount = plan?.unit_amount || 0;

  TagManagerService.push({
    event: 'add_to_cart',
    currency: 'USD',
    items: [{
      item_id: plan.id,
      item_variant: plan.nickname,
      item_name: plan.metadata?.plan_level,
      item_category: 'Subscription Plan',
      affiliation: 'Online Subscription',
      price: unitAmount / 100,
      quantity: 1,
    }]
  });
}
