import React, { createContext, useContext, useState, useEffect } from "react";
import PropTypes from 'prop-types';
import { isWeekend, parse } from 'date-fns';
import { getCurrentTradingDay, isDateHoliday, minutesOfDay } from 'src/utils/datetime/date-fns.tz';
import { useInterval } from 'src/hooks/useInterval';

const TimerContext = createContext();

/***
This timer will update any connected components at the exact same time.
This helps you make updates appear visually in sync.

@param {number} interval - The interval in milliseconds
@param {callback} conditionCheck - If the function returns false, the timer will not update.
@param {callback[]} globalFunction - Callback to call when the timer updates. This is useful if you want to update global state instead of component state
@param {number} children - React children to recieve the timer context
*/
function SyncronizedTimerProvider({ interval, conditionCheck, globalFunction, children }) {
  const [timer, setTimer] = useState(0);

  useInterval(() => {
    if (!conditionCheck()) return;

    setTimer(timer + 1);

    if (globalFunction) {
      globalFunction();
    }
    // }
  }, interval);



  return (
    <TimerContext.Provider
      value={{
        timer
      }}
    >
      {children}
    </TimerContext.Provider>
  );
}


SyncronizedTimerProvider.propTypes = {
  interval: PropTypes.number,
  conditionCheck: PropTypes.func,
  globalFunction: PropTypes.func,
  children: PropTypes.node,
}


SyncronizedTimerProvider.defaultProps = {
  interval: 5_000,
  conditionCheck: () => true,
}

export default SyncronizedTimerProvider;

/**
 * Used to connect each component to the timer.
 * @param {callback} callback - The function to call when the timer updates
 * @param {int} divisor - Optionally skip some ticks. If the timer interval is 5 seconds, and your divisor is 1, your component will update every 5 seconds.
 * If your divisor is 3, your component will update every 15 seconds.
*/
export function useSyncronizedTimer(callback, divisor = 1) {
  const { timer } = useContext(TimerContext);

  useEffect(callback, [Math.floor(timer / divisor)])
}


const startTimerTimeMinutes = minutesOfDay(parse('03:30', 'HH:mm', new Date()));
const endTimerTimeMinutes = minutesOfDay(parse('20:30', 'HH:mm', new Date()));


export const shouldUpdateTimerDuringMarketHours = () => {
  const currentDay = getCurrentTradingDay();

  if (isWeekend(currentDay) || isDateHoliday(currentDay)) {
    return false;
  }

  const currentMinutes = minutesOfDay(currentDay);

  return (currentMinutes >= startTimerTimeMinutes && currentMinutes <= endTimerTimeMinutes)
}
