
import _isEqual from 'lodash/isEqual';
import PropTypes from 'prop-types';
import React, { useContext, useMemo } from 'react';
import FormModal from 'src/app/components/FormModal';
import Form from 'src/app/slicedForm/ExpressionForm/elements/Form';
import expressionDetailsReducer from 'src/app/slicedForm/ExpressionForm/reducers/expressionDetailsReducer';
import { ColumnDefsContext } from 'src/app/slicedForm/shared/context/ColumnDefsProvider';
import FormProvider, { useFormDispatch } from 'src/app/slicedForm/shared/context/FormProvider';
import { PUT_EXPRESSION } from 'src/app/slicedForm/shared/reducers/expressionReducer';
import { COLUMN_TYPES } from 'src/app/slicedForm/shared/schema/schemaBuilder';
import GlobalExpressionProvider from 'src/redux/expressions/globalExpressionContext';
import { emptyExpression, generateExpressionName } from 'src/redux/expressions/globalExpressionReducer';
import getArithmaticParser from '../../ExpressionForm/arithmatic/ArithmeticParser';
import useComparableColDefs from '../../shared/hooks/useComparableColDefs';
import { useProfilePermissions } from 'src/hooks/useUserPlanPermissions';


/*
 *
 * NOTE: mapping
 *
 * - Feels like tying the whole app up in a knot
 * - How to display the problem?
 * - Column names not matching is very confusing
 * - Every component needs ALL columns from ALL other components
 *
 * TODO: EXPRESSIONS
 *
 * BUGS:
 *  Changed toplist useDeepCompareEffect to useEffect. Could cause bugs.
 * 
 * - Set up mapping for columnProf->Query
 *    - ORDERBY! Not working at all...
 *    - What we have is horribly messy
 *    - profileToQuery is bad name. maybe singleProfileToQuery.
 *    - singleProfileToPreview
 *
 * - Display scanner err?
 *    - Needs to be added to watchlist and news backend...
 *
 * - Big problem: Deleted expressions, missing columns
 *    - No form should fail
 *    - No query should fail
 *    - Expression should be either removed, or made obviously invalid
 *
 * - NOT DOING NOW: Hover to see expr? Like on input
 *
 * - profile to preview, huge pain
 *
 * - Need Arrow key blocker on expression. Down arrow is navigating the child modal's list
 *
 *  Things I hate:
 *
 * - Hate the [...columns, ...expressions], they should be selected at the base. Big change.
 *
 * - Hate the QueryEvaluator logic being instantiated upon every websocket record
 *
 * - Hate the ArithmaticParser eval vs validate. It works, but man its ugly
 *
 * - Hate QueryEvaluator .apply not working for bare expressions
 *
 * - useValidateExpression makes no sense with singleton
 *
 * BACKEND:
 *  - permissions
 *  - max saved exprs serverside
 *  - Allow empty expressions, just throw away I think
 *
 * NEXT STEPS:
 *  - Map columns
 *  - Column groups like koyfin
 *  - Key Stats to RDS
 *  - historyComponent to celerdata
 *
 * 
 **/





const reducers = [expressionDetailsReducer];


/**
 * Edit a single expression inside its own reducer.
 * Pass an empty object to create a new expression.
 *
 * Automatically filters pickable columns based
 * on Expression.comparesTo.
 *
 * @param {object} props
 * @param {string} [props.className]
 * @param {boolean} props.open
 * @param {function} props.handleClose
 * @param {object} props.expression
 **/
function ExpressionDetailsForm({
  className,
  open,
  handleClose,
  expression,
}) {
  const parentColumnContext = useContext(ColumnDefsContext);
  const arithmaticParser = useMemo(() => getArithmaticParser(), []);
  const dispatch = useFormDispatch();
  /*
    TODO:
    Permissions are useless here, this just returns defaults. No permissions even make sense on this form.
    Makes me feel like: 
      1) FormActions are not correct here. Maybe just build another Actions component for expressions, its not hard.
      2) FormSettings should not be part of FormProvider. That should be opt-in.
      3) Permissions have to scope alongside each Reducer. profileReducer? "customizationDisabled"
  */

  const initialState = useMemo(() => {
    let currentExpression;
    let errMsg = null;

    if (expression && expression?.name) {
      try {
        arithmaticParser.validate(expression?.expression || '');
      } catch (err) {
        errMsg = err.message;
      }

      currentExpression = { ...expression };

    } else {
      // don't validate on init for an empty expression
      currentExpression = { ...emptyExpression, name: generateExpressionName() };
    }

    return {
      currentExpression,
      errors: {
        currentExpression: errMsg,
        label: null,
      }
    };
  }, [expression])

  const expressionableColDefs = useComparableColDefs(initialState.currentExpression).filter(col => col.columnType !== COLUMN_TYPES.expression.name);

  const childColumnContext = useMemo(() => {
    return {
      ...parentColumnContext,
      columnDefs: expressionableColDefs,
    }
  }, [parentColumnContext, expressionableColDefs]);

  const formActions = useMemo(() => ({
    onCancel: () => {
      handleClose();
    },
    onSubmit: ({ state }) => {
      if (!_isEqual(state.currentExpression, expression)) {
        dispatch({ type: PUT_EXPRESSION, payload: { ...state.currentExpression } });
      }
      handleClose();
    }
  }), [handleClose]);

  return (
    <FormModal
      className={className}
      open={open}
      width={500}
      height={600}
      onClose={handleClose}
      stopPropagation
    >
      <GlobalExpressionProvider contextKey={null}>
        <FormProvider
          providerName="ExpressionDetailsProvider"
          initialState={initialState}
          reducers={reducers}
          actions={formActions}
        >
          <ColumnDefsContext.Provider value={childColumnContext}>
            <Form />
          </ColumnDefsContext.Provider>
        </FormProvider>
      </GlobalExpressionProvider>
    </FormModal>
  )
}


ExpressionDetailsForm.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  expression: PropTypes.object,
}


ExpressionDetailsForm.defaultProps = {
  open: false,
}


export default ExpressionDetailsForm;
