import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { ItemContext } from './context';
import ConditionalTooltip from 'src/app/components/utility/ConditionalTooltip';
import {
  IconButton as MuiIconButton,
  makeStyles,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {},
}));


function IconButton({
  className,
  Icon,
  label,
  disabled,
  edge,
  ariaLabel,
  placeholder,
  onClick,
}) {
  const { disabled: _disabled } = useContext(ItemContext);
  const classes = useStyles();

  return placeholder ? (
    <div
      className={clsx(
        className,
        classes.root,
        'MuiButtonBase-root',
        'MuiIconButton-root',
        '--placeholder'
      )}
      tabIndex={undefined}
      aria-hidden="true"
      style={{ visibility: 'hidden' }}
    >
      <div className="MuiIconButton-label">
        <div className='MuiSvgIcon-root' />
      </div>
    </div>
  ) : (
    <ConditionalTooltip label={label}>
      <span>
        <MuiIconButton
          className={clsx(className, classes.root)}
          disableRipple
          edge={edge}
          aria-label={ariaLabel}
          disabled={disabled || _disabled}
          onClick={(e) => {
            e.preventDefault();
            e.stopPropagation();
            onClick(e);
          }}
        >
          <Icon />
        </MuiIconButton>
      </span>
    </ConditionalTooltip >
  )
}


IconButton.displayName = 'ActionList.IconButton';


IconButton.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  Icon: PropTypes.elementType,
  edge: PropTypes.oneOf(['start', 'end']),
  ariaLabel: PropTypes.string,
  placeholder: PropTypes.bool,
  onClick: PropTypes.func
};


IconButton.defaultProps = {};


export default IconButton;
