import React from 'react';
import PropTypes from 'prop-types';
import { isObjEmpty } from 'src/utils/isObjEmpty';
import clsx from 'clsx';
import ConditionalWrapper from 'src/app/components/utility/ConditionalWrapper';
import DragCarousel from 'src/app/components/elements/DragCarousel';
import {
  Typography,
  makeStyles
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root_normal: {
    display: 'flex',
    marginRight: 0,
    overflow: 'auto'
  },
  root_carousel: {
    display: 'block',
    marginRight: 0,
    overflow: 'hidden'
  },
  tickerTitleItemLabel: {
    color: theme.palette.text.label,
    fontSize: 12,
    fontWeight: 500,
    whiteSpace: 'nowrap'
  },
  tickerTitleItemContent: {
    color: theme.palette.text.primary,
    fontSize: 12,
    fontWeight: 500,
    whiteSpace: 'nowrap',
  },
  white: {
    color: theme.palette.text.secondary
  },
  red: {
    color: theme.palette.text.negative
  },
  green: {
    color: theme.palette.text.positive
  },
}));


function TickerQuote({
  className,
  carousel,
  quoteData,
  isFetching
}) {
  const classes = useStyles({ carousel });


  const renderQuote = (data, key) => {
    if (!data || isObjEmpty(data)) return null;

    let label = `${data.session}`;
    if (data.datetime) {
      label += ` (${data.datetime})`;
    }
    let change = (Math.round((data.change + Number.EPSILON) * 100) / 100).toFixed(2);
    const latestPrice = (Math.round((data.price + Number.EPSILON) * 100) / 100).toFixed(2);
    const changePercent = (Math.round(((data.change_p) + Number.EPSILON) * 100) / 100).toFixed(2);

    let colorClass = classes.white;
    if (changePercent < 0) {
      colorClass = classes.red;
    } else if (changePercent > 0) {
      colorClass = classes.green;
      change = `+${change}`;
    }

    return (
      <div className="ticker-title-item" key={key}>
        <Typography className={classes.tickerTitleItemLabel}>{label}</Typography>
        {(data.price === 0 || data.price === null || data.price === undefined)
          ? <Typography className={classes.tickerTitleItemContent}>-</Typography>
          : <Typography className={classes.tickerTitleItemContent}>{latestPrice}<span className={colorClass}>{` ${change} (${changePercent}%)`}</span></Typography>
        }
      </div>
    );
  };


  if (isFetching) {
    return null;
  }


  return (
    <div className={clsx(className, carousel ? classes.root_carousel : classes.root_normal)}>
      <ConditionalWrapper
        condition={carousel}
        wrapper={children => <DragCarousel spaceBetween={20} showControls={false}>{children}</DragCarousel>}
      >
        {[renderQuote(quoteData.regular, 'regular'), renderQuote(quoteData.extended, 'extended')].filter(jsx => Boolean(jsx))}
      </ConditionalWrapper>
    </div>
  );
}


TickerQuote.propTypes = {
  className: PropTypes.string,
  carousel: PropTypes.bool,
  quoteData: PropTypes.object,
  isFetching: PropTypes.bool,
};


TickerQuote.defaultProps = {
  carousel: false,
  isFetching: false
};


export default TickerQuote;



