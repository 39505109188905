import * as Sentry from '@sentry/react';

/**
 * Utility class to log layout queue events to Sentry.
 * A little messy but I don't got the time.
 * 
 * 1) With each new Queue request, add via addRequest()
 * 2) Modify request via addRequestStep(), before and after the database call for example
 * 3) Call flush() once the queue completes
 */
class LayoutQueueLoggingContext {
  constructor() {
    this.requests = [];
  }

  addRequest(timestamp, step, reason = '', params = {}) {
    try {
      this.requests.push({
        timestamp,
        reason,
        steps: [step],
        params: params
      });
    } catch (err) {
      Sentry.captureException(err, scope => scope.setTransactionName('LayoutQueueLoggingContext Err'));
      console.error(err);
    }
  }

  addRequestStep(timestamp, step) {
    try {
      const req = this.requests.find(e => e.timestamp === timestamp);
      req.steps?.push(step);
    } catch (err) {
      Sentry.captureException(err, scope => scope.setTransactionName('LayoutQueueLoggingContext Err'));
      console.error(err);
    }
  }

  queueError(processIndex, batchIndex, batchId, successes = [], error) {
    // We shouldn't clear the requests on error, they're still in the queue.
    try {
      const payload = {
        queueProcessIndex: processIndex,
        queueBatchIndex: batchIndex,
        queueBatchId: batchId,
        layoutQueueRequests: this.requests,
        layoutQueueResult: {
          action: 'onQueuFailure',
          successes: successes.length,
          ...(error && {
            errMessage: error?.message,
            errStack: error?.stack,
          })
        }
      }
      Sentry.withScope(scope => {
        scope.setTransactionName('LayoutQueueLoggingContext');
        scope.setLevel('info');
        scope.setExtras(payload);
        scope.setTags({ 
          'queue.type': 'onQueueFailure',
          'queue.batchId': batchId,
          'queue.batchIndex': batchIndex,
        })
        Sentry.captureMessage('LayoutQueueLoggingContext');
      })
    } catch (err) {
      Sentry.captureException(err, scope => scope.setTransactionName('LayoutQueueLoggingContext Err'));
      console.error(err);
    }
  }

  queueComplete(processIndex, batchIndex, batchId, successes = [], error = null) {
    // Clear the queue
    try {
      const payload = {
        queueProcessIndex: processIndex,
        queueBatchIndex: batchIndex,
        queueBatchId: batchId,
        layoutQueueRequests: this.requests,
        layoutQueueResult: {
          action: 'onQueueComplete',
          successes: successes.length,
          ...(error && {
            errMessage: error?.message,
            errStack: error?.stack,
          })
        }
      }
      Sentry.withScope(scope => {
        scope.setTransactionName('LayoutQueueLoggingContext');
        scope.setLevel('info');
        scope.setExtras(payload);
        scope.setTags({ 
          'queue.type': 'onQueueComplete',
          'queue.batchId': batchId,
          'queue.batchIndex': batchIndex,
        })
        Sentry.captureMessage('LayoutQueueLoggingContext');
      })
    } catch (err) {
      Sentry.captureException(err, scope => scope.setTransactionName('LayoutQueueLoggingContext Err'));
      console.error(err);
    } finally {
      this.requests = [];
    }
  }
}


export default LayoutQueueLoggingContext;