
import { useCallback } from 'react';
import { useFormDispatch, useFormSelector } from '../../shared/context/FormProvider';
import { UPDATE_ENTITY_VALUE } from '../reducers/filterReducer';
import { getEntityVal } from '../../mapping/formTreeManipulation';





export const VALIDATORS = {
  required: (value) => {
    return value !== null && value !== undefined && value !== '';
  }
}


const defaultOptions = {
  mode: 'onChange',
  valueName: 'value',
  validator: VALIDATORS.required
}






/**
 * Refs automatically added to memory. Check if ref exists for validation.
 * 
 * This is a little wonky.
 * 1) We unmount the "namespace". Does that even make sense? Refs will
 *    unmount themselves
 * 2) Its possible our reducer makes an invalid modification, without registering. 
 *    How do we deal with that?
 * 3) If a user makes an invalid change, then switches profiles, the ref will clear.
 *    How do we manage that? Prevent profile switching? Ignore the ref? Whats the point of refs then?
 */


function useRegister(id, path, options = {}) {
  options = Object.assign({}, defaultOptions, options);

  const dispatch = useFormDispatch();
  const { val, err } = _useWatchInternal(id, path);

  const handleChange = useCallback((newValue, extra = {}) => {
    if (newValue === val) {
      console.debug(`Values same for ${id}.${path}, old:${val}, new:${newValue}. Ignoring.`);
      return;
    }
    dispatch({
      type: UPDATE_ENTITY_VALUE,
      payload: {
        id,
        path,
        value: newValue,
        ...extra,
      }
    })
  }, [val]);


  return {
    [options.mode]: handleChange,
    [options.valueName]: val === undefined ? null : val,
    error: err
  }
}


export function useWatch(id, path) {
  return getEntityVal(useFormSelector(state => state.entities?.[id]?.[path]));
}

const emptyObj = {};

export function _useWatchInternal(id, path) {
  return useFormSelector(state => state.entities?.[id]?.[path]) || emptyObj;
}


export function useEntity(id) {
  // const dispatch = useFormDispatch();

  // I know this is crazy, but it works. We aren't breaking the rules technically
  // because hook order is maintained.

  const register = useCallback((path, options) => {
    return useRegister(id, path, options);
  }, [id]);

  const watch = useCallback((path, options = {}) => {
    return useWatch(id, path);
  }, [id]);


  return {
    useWatch: watch,
    useRegister: register
  }
}

