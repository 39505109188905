import { addMinutes, format, parse, subMinutes } from 'date-fns';
import { formatMarketTime, parseAssumeMarketTime } from 'src/utils/datetime/date-fns.tz';

const marketStart = parseAssumeMarketTime('04:00:00', 'HH:mm:ss');
const marketEnd = parseAssumeMarketTime('20:00:00', 'HH:mm:s');

export const getMarketStartString = () => formatMarketTime(marketStart, 'HH:mm');
export const getMarketEndString = () => formatMarketTime(marketEnd, 'HH:mm');

/**
 *
 * @typedef {Object} Slice
 * @property {string} id - entityId
 * @property {string|null} startTime - 'HH:mm'
 */


/**
 * Find the next valid/existing time, after the given index
 * @param {Slice[]} slices
 * @param {number} currIdx
 * @return {string}
 */
const getNextValidSliceTime = (slices, currIdx) => {
  const nextIdx = currIdx + 1;
  if (!(nextIdx in slices)) {
    return marketEnd;
  }
  if (slices[nextIdx]?.startTime) {
    return parseAssumeMarketTime(slices[nextIdx].startTime, 'HH:mm');
  }

  return getNextValidSliceTime(slices, nextIdx);
};


/**
 * Find the previous valid/existing time, before the given index
 * @param {Slice[]} slices
 * @param {number} currIdx
 * @return {Date}
 */
const getPrevValidSliceTime = (slices, currIdx) => {
  const prevIdx = currIdx - 1;
  if (!(prevIdx in slices)) {
    return marketStart;
  }
  if (slices[prevIdx]?.startTime) {
    return parseAssumeMarketTime(slices[prevIdx].startTime, 'HH:mm');
  }

  return getPrevValidSliceTime(slices, prevIdx);
};


/**
 * Each slice only has a single start time.
 * We want to display a range to the user, so search for the endTime.
 * @param {string} sliceId
 * @param {Slice[]} slices
 * @return {(*|string)[]|*[]}
 */
export function getSliceRange(sliceId, slices) {
  const sliceIndex = slices.findIndex(slice => slice.id === sliceId);

  if (sliceIndex === -1) {
    throw new Error(`Slice does not exist ${sliceId}`);
  }

  const startTime = slices[sliceIndex].startTime;

  if (!startTime && sliceIndex === 0) {
    throw new Error('First slice must have a start time');
  }

  if (!startTime) return [null, null];

  const endTime = format(getNextValidSliceTime(slices, sliceIndex), 'HH:mm');

  return [startTime, endTime];
}


/**
 * Each slice can't pick a time earlier than the previous valid slice
 * Each slice can't pick a time later than the next valid slice
 *
 * @param {string} sliceId
 * @param {Slice[]} slices
 * @returns {{min: Date, max: Date}}
 */
export const getSliceBounds = (sliceId, slices) => {
  const bounds = { min: marketStart, max: marketEnd };

  const sliceIndex = slices.findIndex(slice => slice.id === sliceId);

  if (sliceIndex === -1) {
    throw new Error(`Slice does not exist ${sliceId}`);
  }

  bounds.min = addMinutes(getPrevValidSliceTime(slices, sliceIndex), 1);
  bounds.max = subMinutes(getNextValidSliceTime(slices, sliceIndex), 1);

  return bounds;
}
