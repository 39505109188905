import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';

export const SAMPLE_TICKERS = [
  'AAPL',
  'MSFT',
  'GOOG',
  'ROG.SW',
  'INTC',
  'PRX.AS',
]


export const DEFAULT_VOLUME = 60;

export const SPEAK_AUDIO_KEY = 'speak';

const alertPath = (name) => `/static/sound/alerts/${name}`;

const filenames = [
  'ambient.wav',
  'blib.mp3',
  'double.wav',
  'drop.wav',
  'event.mp3',
  'plasma.mp3',
  'pling.wav',
  'plong.mp3',
  'radar.mp3',
  'swusch.mp3',
  'zap.mp3',
]


const makeItem = (filename) => {
  const [name] = filename.split('.');
  const label = name.split(' ').map(w => w.charAt(0).toUpperCase() + w.slice(1)).join(' ');
  return {
    name,
    label,
    value: alertPath(filename),
  }
}


export const AUDIO = {
  speak: {
    label: 'Text-to-Speech',
    Icon: RecordVoiceOverIcon,
    value: null // special case, need to call a different function
  },
  ...filenames.reduce((acc, filename) => {
    const item = makeItem(filename);
    return {
      ...acc,
      [item?.name]: item
    }
  }, {})
}



export const DEFAULT_AUDIO = Object.keys(AUDIO)[0];
