import { isObject } from "lodash";
import requestIdleCallback from 'ric-shim';


/**
 * @typedef {Object} SliceDefinition
 * @property {string} sliceName - Name in redux state
 * @property {string} version - versioning
 * @property {function(object): boolean} matcher - Whether the result of the action should be persisted
 * @property {string[]} propertiesToRemove - Remove these before persisting to localstorage
 **/

/** 
 * @typedef {Object} Slice
 * @property {string} sliceName
 * @property {string} version
 * @property {function(object): boolean} matcher
 * @property {string[]} propertiesToRemove
 * @property {function(string): string} getStorageKey - The key in localstorage, takes namespace => 'userSub:version:namespace'
 * @property {function(string): string} databaseKey - The timestamp key in dynamo. Not namespaced, because multiple users can't exist in user's db record **/


/**
 * 2nd attempt at this. We need userSub inside the localstorage key, so we don't share user data.
 *
 * Because of this, we cannot hydrate automatically, since userSub isn't known upon hydration
 *
 * In order to save:
 *    1) Matcher() must match
 *    2) timestamp must exist on action
 *    3) namespace must exist on action
 *
 * In order to hydrate:
 *   1) Call a function manually
 *
 * Database timestamp key IS NOT NAMESPACED. Theres no point, and it makes pruning easier.
 **/
class UserNamespacedLocalStorageMiddleware {
  constructor() {
    this.slices = new Map();
    this.namespace = null;
  }

  /**
   * Should be called whenever the user's userSub is loaded
   * @param {string} namespace 
   **/
  setNamespace(namespace) {
    this.namespace = namespace;
  }

  /**
   * @param {string} sliceName
   * @returns {boolean}
   **/
  hasSlice = (sliceName) => {
    return this.slices.has(sliceName)
  }

  /**
   * @param {string} sliceName
   * @returns {Slice}
   **/
  getSlice = (sliceName) => {
    return this.slices.get(sliceName);
  }

  /** @returns {boolean} **/
  namespaceIsValid = () => {
    const result = typeof this.namespace === 'string' && this.namespace;
    if (!result) {
      console.warn('Namespace being invoked, but it is not set!')
    }
    return result;
  }

  /**
   * @param {object} action
   * @returns {boolean}
   **/
  isValidAction = (action) => {
    return action?.writeLocalStorage
      && typeof action.writeLocalStorage === 'number'
      && !isNaN(action.writeLocalStorage)
      && this.namespaceIsValid();
  }

  /** 
   * @param {SliceDefinition[]} definitions
   * @returns {void}
   **/
  addSlices = (definitions) => {
    definitions.forEach(({ sliceName, ...rest }) => {
      const def = {
        ...rest,
        getStorageKey: () => this.namespaceIsValid() && `elrdx#${this.namespace}#${rest.version}#${sliceName}`,
        databaseKey: `ts#${sliceName}#${rest.version}`
      }

      if (!def.matcher || !def.version || !sliceName) {
        throw new Error(`Invalid slice definition ${sliceName}`);
      }

      this.slices.set(sliceName, def);

    })
  }

  /**
   * @param {string} sliceName
   * @param {number|undefined} dbTimestamp
   **/
  getWriteLocalStorageTime = (action) => {
    if (!this.isValidAction(action)) {
      return;
    }
    return action.writeLocalStorage;
  }


  middleware = ({ getState }) => next => action => {
    const result = next(action);
    const state = getState();

    this.slices.forEach((slice, sliceName) => {
      const { matcher, getStorageKey, propertiesToRemove } = slice;
      try {
        const timestamp = this.getWriteLocalStorageTime(action);
        const storageKey = getStorageKey();
        if (matcher && matcher(action) && timestamp && storageKey) {
          requestIdleCallback(() => {
            let data = state?.[sliceName] || null;
            if (propertiesToRemove && propertiesToRemove.length && isObject(data)) {
              data = { ...data };
              propertiesToRemove.forEach(prop => delete data[prop]);
            }
            localStorage.setItem(storageKey, JSON.stringify({ data, timestamp }));
            console.debug('LOCALSTORAGE_MIDDLEWARE:SET', sliceName, timestamp);
          })
        }
      } catch (err) {
        console.log(err);
      }
    });

    return result;
  }


  getDbTimestamp = (sliceName, userData = {}) => {
    if (!this.hasSlice(sliceName)) {
      return null;
    }

    const slice = this.getSlice(sliceName);

    if (!slice) return null;

    if (!(slice?.databaseKey in userData)) {
      return null;
    }

    return parseInt(userData?.[slice?.databaseKey]) || null;
  }


  /**
   * @param {string} sliceName
   * @param {number} dbTimestamp
   * @returns {[boolean, object]} - [shouldHydrate, data]
   **/
  getHydrateLocalData = (sliceName, dbTimestamp = 0) => {

    const result = [false, null];

    try {
      if (!this.hasSlice(sliceName)) {
        return result;
      }

      if (!this.namespaceIsValid()) {
        return result;
      }

      const slice = this.getSlice(sliceName);

      const storageKey = slice.getStorageKey();

      const item = localStorage.getItem(storageKey);

      if (!item) {
        return result;
      }

      const parsed = JSON.parse(item) || {};
      const { data, timestamp: storageTimestamp = 0 } = parsed;

      if (!data || !storageTimestamp) {
        return result;
      }
      if (storageTimestamp < dbTimestamp) {
        return result
      }

      return [true, data];

    } catch (err) {
      console.error(err);
      return result;
    }
  }


}


export const userNamespacedLocalStorageMiddleware = new UserNamespacedLocalStorageMiddleware();
