
export const SCANNER_FUSE_OPTIONS = {
  // ignoreLocation: true,
  threshold: 0.4,
  keys: [
    "label",
    {
      name: "synonyms",
      weight: 0.8
    },
    {
      name: "alternateLabel",
      weight: 0.6
    }
  ]
}


export const BASE_SYNONYMS = {
  'chg': ['change'],
  '%': ['percent'],
  'vol': ['volume'],
  'rvol': ['relative volume'],
  'o': ['open'],
  'h': ['high'],
  'l': ['low'],
  'c': ['close'],
  'own': ['ownership'],
  'inst.': ['institutional'],
  'ins': ['insider'],
  'pr': ['press'],
  'min': ['minute'],
  'ah': ['afterhours'],
  'pm': ['premarket'],
  'ssr': ['short sal restriction'],
  'vw': ['vwap'],
  'equiv': ['equivalent'],
  'qoq': ['quarter'],
  'eps': ['earnings per share'],
  'roa': ['return on assets'],
  'roe': ['return on equity'],
  'ret': ['return'],
  'close': ['prev close'],
  'd[-1]': ['day -1', 'prev'],
  'd[-2]': ['day -2'],
  'd[-3]': ['day -3'],
  'd[-4]': ['day -4'],
  'd[-5]': ['day -5'],
  'mo': ['month'],
  'ytd': ['year to date']
}
