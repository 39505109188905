import edgeDataApi from 'src/apis/edgeDataApi';
import { fullFormatStatCellNumber } from 'src/utils/formatStatCellNumber';

export const UPDATE_KEYSTATS_QUOTE = '@keystats/update-keystats-quote';
export const FETCH_KEYSTATS_COMBINED = '@keystats/fetch-keystats-combined';
export const FETCH_KEYSTATS = '@keystats/fetch-keystats';
export const UPDATE_KEYSTATS_RVOL = '@keystats/update-keystats-rvol';
export const REQUEST__FETCH_KEYSTATS = '@keystats/request__fetch-keystats';


let prevKeyStatsTicker = null;
export const fetchKeyStatsFinancialsV2 = (ticker) => async dispatch => {
  if (!ticker || ticker === prevKeyStatsTicker) {
    return false;
  }

  prevKeyStatsTicker = ticker;
  dispatch({ type: REQUEST__FETCH_KEYSTATS });

  const keystats = await fetchKeystats(ticker);

  dispatch({ type: FETCH_KEYSTATS_COMBINED, payload: keystats });
};


export const fetchKeystats = async (ticker) => {
  let keystats = null;
  try {
    const res = await edgeDataApi.get(`/keystats-financials/v2/${ticker}`);
    keystats = res.data;
  } catch (err) {
    console.log('fetchKeyStatsv2:', err);
  }

  if (keystats) {
    if (keystats?.data?.ipo_date && keystats.data.ipo_date.toLowerCase() === 'none') {
      keystats.data.ipo_date = null;
    }

    keystats.data = processKeyStatsDataV2(keystats.data);
  }
  return keystats;
};


const processKeyStatsDataV2 = (data) => {

  data['lastSplitCombined'] = `${data['lastsplitfactor'] || '-'} / ${data['lastsplitdate'] || '-'}`;
  data['52 Week'] = `$${formatNumberHelper(data['weekhigh52'])} - $${formatNumberHelper(data['weeklow52'])}`;
  data['day365_high_combined'] = `${fullFormatStatCellNumber(data['weekhigh52'], { pre: '$' })} / ${data['day365_high_date'] || '-'}`;
  data['day365_low_combined'] = `${fullFormatStatCellNumber(data['weeklow52'], { pre: '$' })} / ${data['day365_low_date'] || '-'}`;
  data['changeYTDCombined'] = formatDoubleNumber(data['changeYTD'], data['changeYTDPercent'], '$', '%');

  data['SSR'] = data['ssr'] ? 'Yes' : 'No';

  // 9/24 - home_country_code now exists on endpoint
  // try {
  //   const countryFound = detect(data['home_country']);
  //   data['countryCode'] = countryFound[0].iso3166;
  // } catch (err) {
  //   data['countryCode'] = null;
  //   console.warn('Country ISO3166 not identifiable');
  // }
  //
  return data;
};


export const fetchQuote = (ticker) => async dispatch => {
  try {
    const res = await edgeDataApi.get(`/quote/${ticker}`);
    dispatch({ type: UPDATE_KEYSTATS_QUOTE, payload: res.data });
  } catch (err) {
    console.log('fetchQuote', err);
  }
};



const formatNumberHelper = (value) => {
  return fullFormatStatCellNumber(value, {});
};


const formatDoubleNumber = (valueOne, valueTwo, pre, post) => {
  valueOne = fullFormatStatCellNumber(valueOne, {});
  valueTwo = fullFormatStatCellNumber(valueTwo, {});
  let s = '';
  if (valueOne !== '-' && pre) {
    s += pre;
  }
  s += valueOne;
  s += ' / ';
  s += valueTwo;
  if (valueTwo !== '-' && post) {
    s += post;
  }
  return s;
};


