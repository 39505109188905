import React from 'react';
import clsx from 'clsx';
import { PREDEF_PREFIX } from 'src/redux/profileSettings/profileSettingsConfig';
import ClearIcon from '@material-ui/icons/Clear';
import LockIcon from '@material-ui/icons/Lock';
import { CopyIcon } from 'src/theme/EdgeIcons';
import {
  makeStyles,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    overflowY: 'auto',
    '& .top-news-filter-list-item': {
      '&.is-copyable-profile': {
        paddingRight: 58,
      },
      '&.Mui-selected': {
        backgroundColor: 'transparent',
        color: theme.palette.primary.main,
      },
    },
    '& .saved-prof-secondary-action': {
      right: 11,
      zIndex: 0,
      '& .saved-prof-icon-btn': {
        padding: 0,
        borderRadius: 4
      },
      '& .saved-prof-copy-btn': {
        padding: 2,
        borderRadius: 4,
        fontSize: 22
      },
      '& .saved-prof-remove-btn': {
        padding: 0,
        borderRadius: 4,
        '& .MuiSvgIcon-root': {
          fontSize: 20
        }
      }
    }
  },
  lockIcon: {
    minWidth: 22,
    '& .MuiSvgIcon-root': {
      fontSize: 15,
      color: theme.palette.text.primary,
      opacity: .89
    }
  },
  scrollContainer: {
    height: '100%'
  },
}));

/**
 * TODO: Warning! profileName and name both are valid! 
 * Some components have .name and some older ones .profileName. Maybe a problem, huh?
 **/
function SavedProfiles({
  className,
  columnProfileSettings,
  onProfileSelect,
  onProfileRemove,
  onCopy,
  copyDisabled,
  ACTIVE_PROFILE_KEY = 'activeProfile'
}) {
  const classes = useStyles();
  const { profiles, [ACTIVE_PROFILE_KEY]: activeProfile } = columnProfileSettings;

  const userProfs = profiles.filter(p => !p.id.startsWith(PREDEF_PREFIX));
  const predefProfs = profiles.filter(p => p.id.startsWith(PREDEF_PREFIX));

  return (
    <div className={clsx(className, classes.root, 'modal-horizontal-scrollbar-sm')}>
      <List>
        {[...predefProfs, ...userProfs].map(profile => {
          const { id, name, profileName } = profile;
          const _name = name || profileName;

          const selected = id === activeProfile;
          const isPredefined = id.includes(PREDEF_PREFIX);
          return (
            <ListItem
              title={_name}
              className={clsx('top-news-filter-list-item', isPredefined && 'is-predefined-profile', !!onCopy && 'is-copyable-profile')}
              key={id}
              disableRipple
              selected={selected}
              button
              onClick={() => onProfileSelect(id)}
            >
              {isPredefined && (
                <ListItemIcon className={classes.lockIcon}>
                  <LockIcon />
                </ListItemIcon>
              )}
              <ListItemText id={_name + '-profile'}>{_name}</ListItemText>

              <ListItemSecondaryAction className="saved-prof-secondary-action">
                {!selected && !isPredefined && (
                  <IconButton
                    onClick={() => onProfileRemove(id)}
                    disabled={id === activeProfile}
                    className="saved-prof-icon-btn"
                  >
                    <ClearIcon color="primary" className="saved-prof-remove-btn" />
                  </IconButton>
                )}
                {!!onCopy && (
                  <IconButton
                    onClick={() => onCopy(_name, id)}
                    className="saved-prof-icon-btn"
                    disabled={copyDisabled}
                  >
                    <CopyIcon color="textPrimary" className="saved-prof-copy-btn" />
                  </IconButton>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          );
        })}
      </List>
    </div>
  );
}


export default SavedProfiles;
