import produce from 'immer';

import {
  REQUEST__LIST_TEMPLATES,
  LIST_TEMPLATES,
  SAVE_TEMPLATE,
  REQUEST__SAVE_TEMPLATE,
  DELETE_TEMPLATE,
  RENAME_LAYOUT,
  COPY_LAYOUT,
  DELETE_LAYOUT,
  CL_SET_INTRADAY_DATE,
  CL_SET_HISTORY_INTRADAY_DATE
} from 'src/redux/tradingViewChart/tradingViewChartActions';


const initialState = {
  layouts: {
    global_v1: [],
    overview: [],
  },
  templates: {
    global_v1: [],
    overview: []
  },
  chartingLibrary: {
    history: {
      fromDate: '',
      ticker: null,
    },
    dashboard: {
      ticker: null
    }
  },
  isFetching: {
    global_v1: {
      layouts: false,
      templates: false,
    },
    overview: {
      layouts: false,
      templates: false
    }
  }
};

const tradingViewChartReducer = (state = initialState, action) => {

  switch (action.type) {

    case RENAME_LAYOUT: {
      const { clientId, data } = action.payload;
      const { id, name } = data;
      return produce(state, draft => {
        const idx = draft.layouts[clientId].findIndex(f => f.id === id);
        if (idx === -1) return draft;
        draft.layouts[clientId][idx].name = name;
        draft.layouts[clientId][idx].isFetching = false;
      });
    }

    case COPY_LAYOUT: {
      const { clientId, data } = action.payload;
      const { name, id } = data;
      return produce(state, draft => {
        const idx = draft.layouts[clientId].findIndex(f => f.id === id);
        if (idx === -1) {
          draft.layouts[clientId].push({
            id,
            name,
            isFetching: false
          });
        } else {
          draft.layouts[clientId][idx].name = name;
          draft.layouts[clientId][idx].isFetching = false;
        }
      });
    }

    case DELETE_LAYOUT: {
      const { clientId, id } = action.payload;
      return produce(state, draft => {
        const idx = draft.layouts[clientId].findIndex(f => f.id === id);
        if (idx === -1) return draft;
        draft.layouts[clientId].splice(idx, 1);
      });
    }


    case REQUEST__LIST_TEMPLATES: {
      const clientId = action.payload;
      return produce(state, draft => {
        draft.isFetching[clientId].templates = true;
      });
    }

    case LIST_TEMPLATES: {
      const { clientId, data } = action.payload;
      return produce(state, draft => {
        draft.templates[clientId] = data.map(el => ({ ...el, isFetching: false }));
        draft.isFetching[clientId].templates = false;
      });
    }

    case REQUEST__SAVE_TEMPLATE: {
      const { clientId, data } = action.payload;
      const { name, id } = data;
      return produce(state, draft => {
        draft.templates[clientId].push({
          id,
          name,
          isFetching: true
        });
      });
    }

    case SAVE_TEMPLATE: {
      const { clientId, data } = action.payload;
      const { name, id } = data;
      return produce(state, draft => {
        const idx = draft.templates[clientId].findIndex(f => f.id === id);
        if (idx === -1) {
          draft.templates[clientId].push({
            id,
            name,
            isFetching: false
          });
        } else {
          draft.templates[clientId][idx].name = name;
          draft.templates[clientId][idx].isFetching = false;
        }
      });
    }


    case DELETE_TEMPLATE: {
      const { clientId, id } = action.payload;
      return produce(state, draft => {
        const idx = draft.templates[clientId].findIndex(f => f.id === id);
        if (idx === -1) return draft;
        draft.templates[clientId].splice(idx, 1);
      });
    }

    case CL_SET_INTRADAY_DATE: {
      return produce(state, draft => {
        draft.chartingLibrary.dashboard.fromDate = action.payload;
      });
    }

    case CL_SET_HISTORY_INTRADAY_DATE: {
      return produce(state, draft => {
        draft.chartingLibrary.history.fromDate = action.payload.date;
        draft.chartingLibrary.history.ticker = action.payload.ticker;
      });
    }


    default: {
      return state;
    }
  }
};


export default tradingViewChartReducer;
