import { useState, useEffect, useCallback } from 'react';

export function useSessionStorage(key) {

  // pull the initial value from local storage if it is already set
  const [state, setState] = useState(() => {

    if (!key) {
      return null;
    }
    let exValue = null;
    try {
      exValue = sessionStorage.getItem(key);
    } catch (err) {
      console.log('Failed getItem localstorage', err);
    }
    if (exValue) {
      return JSON.parse(exValue);
    }
    return null;
  });


  useEffect(() => {
    try {
      sessionStorage.setItem(key, JSON.stringify(state));
    } catch (err) {
      console.log('Failed setItem sessionStorage: ', err);
    }
  }, [state]);


  const storageWatcher = useCallback((e) => {
    if (e.newValue) {
      setState((currState) => {
        if (!key) {
          return null;
        }
        const newDat = typeof e.newValue === 'string' ? e.newValue : JSON.parse(e.newValue || 'null');
        return newDat == state ? newDat : currState;
      });
    }
  }, [state]);


  useEffect(() => {
    window.addEventListener('storage', storageWatcher);
    return () => {
      window.removeEventListener('storage', storageWatcher);
    };
  }, [state]);


  return [state, setState];
}
