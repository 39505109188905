import React from 'react';
import { Provider } from 'react-redux';
import { configureStore } from 'src/redux/store/store';
import { SettingsProvider } from 'src/context/SettingsContext';


export default function Root({ children, initialState = {}, settings = {}}) {
  const store = configureStore(initialState);

  return (
    <Provider store={store}>
      <SettingsProvider settings={settings}>
        {children}
      </SettingsProvider>
    </Provider>
  )
}
