import React from 'react';
import clsx from 'clsx';
import slugify from 'slugify';
import {
  makeStyles,
  List,
  ListItem,
  ListItemText,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    '& .top-news-filter-list-item.Mui-selected': {
      backgroundColor: 'transparent',
      color: theme.palette.primary.main,
    },
    '& .saved-prof-secondary-action': {
      right: 13,
      fontSize: 0,
      zIndex: 0,
      '& .saved-prof-icon-btn': {
        padding: 0,
        borderRadius: 4
      },
      '& .saved-prof-remove-btn': {
        padding: 0,
        borderRadius: 4,
        '& .MuiSvgIcon-root': {
          fontSize: 20
        }
      }
    }
  },
  scrollParent: {
    height: '100%',
    overflowY: 'auto'
  },
}));


function SavedProfiles({
  className,
  sections,
  activeSection,
  onSectionSelect,
}) {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.root)}>
      <div className={classes.scrollParent}>
        <List>
          {sections.map(sectionName => {
            return (
              <ListItem
                title={sectionName}
                className="top-news-filter-list-item"
                key={sectionName}
                role={undefined}
                disableRipple
                selected={sectionName === activeSection}
                button
                onClick={() => onSectionSelect(sectionName)}
              >
                <ListItemText id={slugify(sectionName) + '-profile'}>{sectionName}</ListItemText>
              </ListItem>
            );
          })}
        </List>
      </div>
    </div>
  );
}


export default SavedProfiles;
