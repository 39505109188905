import edgeDataApi from 'src/apis/edgeDataApi';

export const CL_SET_INTRADAY_DATE = '@tradingviewchart/cl-set-intraday-date';
export const CL_SET_HISTORY_INTRADAY_DATE = '@tradingviewchart/cl-set-history-intraday-date';
export const RENAME_LAYOUT = '@tradingviewchart/rename-layout';
export const COPY_LAYOUT = '@tradingviewchart/copy-layout';
export const DELETE_LAYOUT = '@tradingviewchart/delete-layout';
export const REQUEST__LIST_TEMPLATES = '@tradingviewchart/request__list-templates';
export const REQUEST__SAVE_TEMPLATE = '@tradingviewchart/request__save-template';
export const LIST_TEMPLATES = '@tradingviewchart/list-templates';
export const SAVE_TEMPLATE = '@tradingviewchart/save-template';
export const DELETE_TEMPLATE = '@tradingviewchart/delete-template';


//---------------------------------------------------------------------
//---------------------------------------------------------------------
//---------------------------------------------------------------------


export const fetchListTemplates = (clientId) => async (dispatch, getState) => {
  try {
    const templates = getState().tvchart.templates?.[clientId];
    const isFetching = getState().tvchart.isFetching?.[clientId]?.templates;
    if (isFetching || (templates && templates.length)) {
      return;
    }
  } catch (err) {
    console.error(err);
  }

  dispatch({ type: REQUEST__LIST_TEMPLATES, payload: clientId });
  let records = [];
  try {
    const { data } = await edgeDataApi.get(`/user/study-templates/${clientId}`);
    records = data;
  } catch (err) {
    console.error(err);
  }
  const payload = { clientId, data: records };
  dispatch({ type: LIST_TEMPLATES, payload });
};


export const saveTemplate = (clientId, name, content) => async dispatch => {
  const id = (+new Date()).toString();
  try {
    dispatch({ type: REQUEST__SAVE_TEMPLATE, payload: { clientId, data: { name, id } } });
    await edgeDataApi.post(`/user/study-templates/${clientId}/${id}`, { name, content });
    const payload = { clientId, data: { name, id } };
    dispatch({ type: SAVE_TEMPLATE, payload });
  } catch (err) {
    console.error(err);
    return false;
  }
};


export const deleteTemplate = (clientId, id) => async dispatch => {
  try {
    dispatch({ type: DELETE_TEMPLATE, payload: { clientId, id } });
    await edgeDataApi.delete(`/user/study-templates/${clientId}/${id}`);
  } catch (err) {
    console.error(err);
    return false;
  }
};


export const setIntradayDate = (date) => {
  return { type: CL_SET_INTRADAY_DATE, payload: date };
};

export const setHistoryIntradayDate = (date, ticker) => {
  return { type: CL_SET_HISTORY_INTRADAY_DATE, payload: { date, ticker } };
};
