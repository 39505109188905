import edgeDataApi from 'src/apis/edgeDataApi';
import PromiseHelper from 'src/utils/PromiseHelper';
import * as Sentry from '@sentry/react';
import { formatMarketTime, parseAssumeMarketTime } from 'src/utils/datetime/date-fns.tz';

export const FETCH_FILINGS_TICKER = '@sec-filings/fetch-filings-ticker';
export const SET_VIEWED_FILING = '@sec-filings/set-viewed-filing';
export const FETCH_EARNINGS_TRANSCRIPT_HISTORY = '@sec-filings/fetch-earnings-transcript-history';
export const REQUEST__FETCH_FILINGS_TICKER = '@sec-filings/request__fetch-filings-ticker';
export const REQUEST__FETCH_EARNINGS_TRANSCRIPT_HISTORY = '@sec-filings/request__fetch-earnings-transcript-history';
export const REQUEST__FETCH_EARNINGS_TRANSCRIPT = '@sec-filings/request__fetch-earnings-transcript';


export const SEC_PAGE_SORT = {
  group: 'group',
  date: 'date'
};


export const fetchFilingsAndTranscripts = (ticker, sort = SEC_PAGE_SORT.group) => async dispatch => {
  dispatch({ type: REQUEST__FETCH_FILINGS_TICKER });
  let data = [];

  try {
    const settledPromises = await PromiseHelper.raceAllSettled(
      [
        fetchFilingsSingleTicker(ticker, sort),
        fetchEarningsTranscriptHistory(ticker)
      ],
      5000,
      { status: 'rejected', reason: 'raceAllSettled timeout reached' }
    );

    data = settledPromises.reduce((acc, prom) => {
      if (prom.status === 'fulfilled') {
        return [...acc, ...prom.value];
      } else {
        console.error(Error(`allSettled failure, ${prom.reason}`));
        return acc;
      }
    }, []);

  } catch (err) {
    Sentry.captureException(err);
  }

  const payload = {
    sort,
    data: data.sort((a, b) => b.datetime > a.datetime ? 1 : -1),
  };

  // debugger;

  dispatch({ type: FETCH_FILINGS_TICKER, payload });
};


export const fetchFilingsSingleTicker = async (ticker, sort = SEC_PAGE_SORT.group) => {
  const { data } = await edgeDataApi.get(`/sec/v2/${ticker}?sort=${sort}&limit=${150}`);
  const fmt = 'yyyy-MM-dd HH:mm:ss';

  return data.data.map(article => {
    const date = parseAssumeMarketTime(article.datetime, fmt);
    return {
      ...article,
      dateDisplay: formatMarketTime(date, 'd LLLL yy'),
      dateStamp: formatMarketTime(date, 'yyyy-MM-dd'),
      source: 'SEC Filings'
    };
  });
};


export const fetchEarningsTranscriptHistory = async (ticker) => {
  const { data } = await edgeDataApi(`/sec/transcripts/${ticker}`);
  const fmt = 'yyyy-MM-dd HH:mm:ss';

  return data.map((slice, i) => {
    const date = parseAssumeMarketTime(slice.datetime, fmt);
    const datestamp = formatMarketTime(date, 'yyyy-MM-dd');
    return {
      ...slice,
      form_category: 'transcripts',
      link_to_first_document: '',
      link_to_filing_details: '',
      form_type: `${slice.year}, Q${slice.quarter}`,
      dateDisplay: formatMarketTime(date, 'd LLLL yy'),
      datestamp,
      // TODO: we get duplicates sometimes? adding index, but investage.
      accession_no: `${datestamp}-transcript-${i}`,
      source: 'SEC Filings'
    };
  });
};


export const setViewedFiling = (article) => {
  return { type: SET_VIEWED_FILING, payload: article };
};
