import {
  Box,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Typography,
  useTheme
} from '@material-ui/core';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import React from 'react';
import MarketingModal from 'src/app/components/MarketingModal/elements/MarketingModal';
import { permissions, PLAN_LEVEL_LABELS } from 'src/hooks/useUserPlanPermissions';
import { CheckIcon } from 'src/theme/EdgeIcons';


const useStyles = makeStyles(theme => ({
  root: {},
}));


/**
 * This should never be shown. But just in case, if we end up showing an
 * "Upgrade" modal in an unexpected place, we need a fallback.
 **/
function MarketingModalUpgradeFallback({
  className,
  suggestedPlanLevel,
  onClose
}) {
  const theme = useTheme();
  const classes = useStyles();
  const planLevelLabel = PLAN_LEVEL_LABELS[suggestedPlanLevel] || planLevelLabel;

  const title = suggestedPlanLevel
    ? (
      <Typography variant="h2">
        <span style={{ marginRight: 10 }}>🚀</span>
        Upgrade to <span style={{ color: theme.palette.text.planTag }}>{planLevelLabel}</span>
      </Typography>
    ) : (
      <Typography variant="h2">
        <span style={{ marginRight: 10 }}>🚀</span>
        Upgrade to Access
      </Typography>
    )

  return (
    <MarketingModal
      className={clsx(classes.root, className)}
      suggestedPlanLevel={planLevelLabel}
      titleContent={title}
      onClose={onClose}
    >
      <Box pt={2}>
        <Typography variant="body1" color="textSecondary">
          {planLevelLabel} Features:
        </Typography>
      </Box>

      <Box pt={2} pb={1}>
        <List>
          <ListItem>
            <ListItemIcon className="success"><CheckIcon /></ListItemIcon>
            <ListItemText primary={`${permissions?.[suggestedPlanLevel]?.history_max_years} Years of Historical Data`} />
          </ListItem>
          <ListItem>
            <ListItemIcon className="success"><CheckIcon /></ListItemIcon>
            <ListItemText primary="Unlimited Scanners, Charts, and Watchlists" />
          </ListItem>
          <ListItem>
            <ListItemIcon className="success"><CheckIcon /></ListItemIcon>
            <ListItemText primary="Customizable Ticker Stats Profiles" />
          </ListItem>
          <ListItem>
            <ListItemIcon className="success"><CheckIcon /></ListItemIcon>
            <ListItemText primary={`Up to ${permissions?.[suggestedPlanLevel]?.max_expressions} custom Expressions`} />
          </ListItem>
        </List>
      </Box>

    </MarketingModal>
  )
}


MarketingModalUpgradeFallback.propTypes = {
  className: PropTypes.string,
  suggestedPlanLevel: PropTypes.string,
  onClose: PropTypes.func,
}


export default MarketingModalUpgradeFallback;
