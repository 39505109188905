import React from 'react';
import { DOCS_WEBSITE_URL } from 'src/constants';
import {
  Typography,
  makeStyles,
  ButtonBase
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    width: '100%',
    height: '100vh',
    minHeight: 700,
    '& a': {
      textDecoration: 'none'
    }
  },
  logo: {
    padding: '15px 20px',
    height: 85,
    '& img': {
      display: 'block',
      maxWidth: '100%',
      maxHeight: '100%'
    }
  },
  content: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: 'calc(100vh - 85px)',
    minHeight: 200,
    position: 'relative',
    backgroundImage: 'url(/static/images/error-background-red.svg)',
    backgroundPosition: 'top',
    backgroundSize: '100%',
    backgroundRepeat: 'no-repeat',
  },
  backgroundStretchFit: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    zIndex: 0,
    '& img': {
      width: '100%',
      height: '100%'
    }
  },
  center: {
    zIndex: 1,
    textAlign: 'center',
    paddingBottom: 100
  },
  title: {
    fontSize: 160,
    textShadow: '1px 1px 10px rgba(0, 0, 0, 0.95)',
    '@media(max-width: 700px)': {
      fontSize: 100,
    },
    lineHeight: 1,
    marginBottom: 10,
  },
  subtitle: {
    fontSize: 40,
    textShadow: '1px 1px 10px rgba(0, 0, 0, 0.95)',
    '@media(max-width: 700px)': {
      fontSize: 28,
    },
  },
  buttons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: 15
  },
  errorButton: {
    boxSizing: 'border-box',
    fontFamily: theme.typography.fontFamily,
    backgroundColor: theme.palette.background.dark,
    color: theme.palette.text.primary,
    margin: 25,
    fontWeight: 800,
    padding: '14px 32px',
    lineHeight: 1.75,
    borderRadius: 15,
    fontSize: 30,
    borderWidth: 2,
    borderStyle: 'solid',
    borderColor: theme.palette.background.negative,
    boxShadow: '1px 1px 10px rgba(0, 0, 0, 0.95)',
    '@media(max-width: 700px)': {
      fontSize: 20,
      padding: '7px 15px'
    },
  },
  icon: {
    maxWidth: '100%',
    maxHeight: '100%',
    marginRight: 15
  }
}));


function ErrorPage({ title = '404', subtitle = 'Something went wrong' }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <div className={classes.logo}>
        <img
          alt="LogoE"
          src="/static/EdgeToTradeWhiteText-e.png"
        />
      </div>
      <div className={classes.content}>
        <div className={classes.center}>
          <Typography variant="h1" color="textPrimary" className={classes.title}>{title}</Typography>
          <Typography variant="h3" color="textPrimary" className={classes.subtitle}>{subtitle}</Typography>
          <div className={classes.buttons}>
            <a href={'/'}>
              <ButtonBase
                disableRipple
                className={classes.errorButton}
              >
                <img src="/static/images/left-arrow.png" alt="" className={classes.icon}/>
                Home
              </ButtonBase>
            </a>
            <a href={DOCS_WEBSITE_URL} target="_blank" rel="noreferrer">
              <ButtonBase
                disableRipple
                className={classes.errorButton}
              >
                <img src="/static/images/mail-icon.png" className={classes.icon} alt=""/>
                Support
              </ButtonBase>
            </a>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ErrorPage;
