import { comparatorSymbolsV2, COMPS } from '../labels';
import clsx from 'clsx';
import { Typography } from '@material-ui/core';
import React from 'react';
import { startOfDay, sub } from 'date-fns'
import { formatMarketTime, getCurrentTradingDay } from 'src/utils/datetime/date-fns.tz';
import { fullFormatStatCellNumber } from 'src/utils/formatStatCellNumber';
import { HISTORY_COLUMNS } from './historyColumns';

export const HISTORY_SEARCH_SYNONYMS = {
  'chg': ['change'],
  'avg': ['average'],
  'vol': ['volume'],
  'vw': ['vwap'],
  'hod': ['high of day'],
  'lod': ['low of day'],
  'ema': ['exponential moving average'],
  'min': ['minute'],
  'ret': ['return'],
  'pmh': ['premarket high', 'pre-market high'],
  'pml': ['premarket low', 'pre-market low'],
  'pm': ['premarket', 'pre-market'],
  '%': ['percent'],
  'adt': ['average daily trading volume', 'trading volume'],
  'atr': ['average true range', 'true range', 'range'],
};

export const mapSynonymsToHistory = (columns, synonymMap) => {
  return columns.map(col => {
    let out = { ...col };
    for (const [shortKey, longSynonyms] of Object.entries(synonymMap)) {
      if (col.label.toLowerCase().includes(shortKey)) {
        const s = out.synonyms || [];
        out.synonyms = [...s, ...longSynonyms];
      }
    }
    if (out.synonyms) {
      out.synonyms = out.synonyms.join(' ');
    }
    return out;
  });
};


/**
 * Subtract a number of days from the current trading day, and return start/end as strings
 * @param {number} num - Number of {type} to subtract
 * @param {string} [type='days'] - years, months, weeks, days, hours, minutes, seconds
 * @param {string} [returnFmt='yyyy-MM-dd']
 * @return {[string,string]} - [from, to]
 */
export const getRollingDates = (num, type = 'days', returnFmt = 'yyyy-MM-dd') => {
  const today = getCurrentTradingDay();
  const past = sub(today, { [type]: num });
  return [
    formatMarketTime(past, returnFmt),
    formatMarketTime(today, returnFmt),
  ]
}


export const INITIAL_FILTER_VALUES = {
  compare: { comp: COMPS.GE, compType: 'val' },
  dateRange: { comp: COMPS.BT, compType: 'val', selected: '30_day' },
  dateCompare: { comp: COMPS.LT, compType: 'val' },
  time: { comp: COMPS.GE, compType: 'val' },
  singleSelect: { comp: COMPS.EQ, compType: 'val' },
  ticker: { comp: COMPS.EQ, compType: 'val' },
};

export const ROLLING_DATE_OPTIONS = {
  '7_day': { label: '7 days', getDates: () => getRollingDates(7) },
  '30_day': { label: '30 days', getDates: () => getRollingDates(30) },
  '60_day': { label: '60 days', getDates: () => getRollingDates(60) },
  '90_day': { label: '90 days', getDates: () => getRollingDates(90) },
  '1_year': { label: '1 Year', years: 1, getDates: () => getRollingDates(1, 'years') },
  '2_year': { label: '2 Year', years: 2, getDates: () => getRollingDates(2, 'years') },
  '3_year': { label: '3 Year', years: 3, getDates: () => getRollingDates(3, 'years') },
  '5_year': { label: '5 Year', years: 5, getDates: () => getRollingDates(5, 'years') },
  '14_year': { label: '14 Year', years: 14, getDates: () => getRollingDates(14, 'years') },
};


export const renderTickerFilterItem = (col) => {
  if (col && col.val) {
    return (
      <div className={clsx('history-filter-item-display', 'history-filter-item-ticker-display')}>
        <Typography variant="h5" color="primary">{col.val}</Typography>
      </div>
    );
  }
};

export const renderFilterItem = ({ name, val, val2, comp, compType, selected }, allColumns = HISTORY_COLUMNS) => {
  const cfg = allColumns.find(col => col.name === name);
  if (!cfg) return null;
  if (!selected && !val) return null;

  const nVal = val ? parseFloat(val) : val;
  const nVal2 = val2 ? parseFloat(val2) : val2;

  let labelString;
  let valueString;
  const { label: originalLabel, hardFormat, input, numberType, filterItemLabel } = cfg;
  const label = filterItemLabel ? filterItemLabel : originalLabel;

  const isTime = numberType === 'time' || input === 'dateRange' || input === 'dateCompare';

  if (input === 'dateRange' && selected) {
    labelString = `${label}: `;
    valueString = ROLLING_DATE_OPTIONS[selected].label;
  } else if (comp === COMPS.BT) {
    let labelStringOne = `${label} ${comparatorSymbolsV2[COMPS.GE]} `;
    let valueStringOne = isTime ? val : fullFormatStatCellNumber(nVal, { stripTrailingZeros: true, hardFormat: true });
    let labelStringTwo = ` AND ${comparatorSymbolsV2[COMPS.LT]} `;
    let valueStringTwo = isTime ? val2 : fullFormatStatCellNumber(nVal2, { stripTrailingZeros: true, hardFormat });
    return (
      <div className="history-filter-item-display" key={name}>
        <Typography variant="h5" color="textPrimary">{labelStringOne}<span>{valueStringOne}</span>{labelStringTwo}<span>{valueStringTwo}</span></Typography>
      </div>
    );
  } else if (selected) {
    labelString = `${label} ${comparatorSymbolsV2[COMPS.EQ]} `;
    if (name.endsWith('_ssr')) {
      if (selected === "0") {
        valueString = "No"
      } else if (selected === "1") {
        valueString = "Yes"
      }
    } else {
      valueString = selected;
    }
  } else {
    if (compType === 'col') {
      valueString = label;
      const cfg2 = allColumns.find(col => col.name === val);
      if (!cfg2) {
        valueString = 'NULL';
      } else {
        valueString = cfg2.label;
      }
    } else {
      valueString = isTime ? val : fullFormatStatCellNumber(nVal, { stripTrailingZeros: true, allowZero: true });
    }
    labelString = `${label} ${comparatorSymbolsV2[comp]} `;
  }
  return (
    <div className="history-filter-item-display" key={name}>
      <Typography variant="h5" color="textPrimary">{labelString} <span>{valueString}</span></Typography>
    </div>
  );
};
