import React, { useRef, useMemo, useState, useCallback } from 'react';
import clsx from 'clsx';
import useDefaultContextMenu from 'src/app/components/grid/contextMenu/useDefaultContextMenu';
import { AgGridReact } from '@ag-grid-community/react';
import { RSCORE_OVERLAY_GRID_COLUMNS } from 'src/app/components/grid/topListNews/columns/columnDefs';
import { handleKeyboardRowNavigation } from 'src/utils/agGridFunctions';
import NewsHeadlineCellRenderer from 'src/app/components/grid/cellRenderers/NewsHeadlineCellRenderer';
import DarkTooltip from 'src/app/components/utility/DarkTooltip';
import {
  ArrowBack as ArrowBackIcon,
  HelpOutline as HelpOutlineIcon
} from '@material-ui/icons';
import {
  Typography,
  makeStyles,
  useTheme
} from '@material-ui/core';
import PanelIconButton from 'src/app/components/panels/PanelIconButton';



const gridComponents = {
  'NewsHeadlineCellRenderer': NewsHeadlineCellRenderer,
};


const useStyles = makeStyles((theme) => ({
  root: {
    position: 'absolute',
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
    zIndex: props => props.zIndex,
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column'
  },
  gridContainer: {
    flex: 1,
    '& .ag-root-wrapper': {
      borderRadius: '0 !important'
    }
  },
  overlayHeader: {
    backgroundColor: theme.palette.background.darkBlue,
    height: 27,
    display: 'flex',
    alignItems: 'center',
    '& > span': {
      height: '100%'
    }
  },
  overlayHeaderTitle: {
    flex: 1,
    paddingLeft: 10,
    display: 'flex',
    alignItems: 'center',
    height: '100%',
    '& p': {
      fontWeight: 400,
      fontSize: 14
    }
  },
  tabButton: {
    minWidth: 98,
    height: '100%',
    paddingLeft: 10,
    paddingRight: 10,
    borderRadius: 0,
    borderLeft: `2px solid ${theme.palette.background.panelHeader}`,
    '& .MuiSvgIcon-root': {
      margin: 0,
      fontSize: 14
    },
  }
}));


function OverlayGrid({
  className,
  parentRowNode,
  parentDataSource,
  setIsFetching,
  onClose,
  zIndex = 2
}) {
  const classes = useStyles({ zIndex });
  const theme = useTheme()

  const makeWatchlistMenu = useDefaultContextMenu();
  const [_parentRowNode] = useState(parentRowNode);

  const [records, setRecords] = useState([]);
  const gridRef = useRef();

  useMemo(() => {
    let mounted = true;
    (async () => {
      const r = await parentDataSource.getChildRscoreRecords(_parentRowNode);
      if (mounted) {
        // For the parent record, show ticker instead of similarity
        setRecords([
          {
            ..._parentRowNode,
            similarity: _parentRowNode?.ticker
          },
          ...r
        ]);
        setIsFetching(false);
      }
    })();

    return () => mounted = false;
  }, []);


  const getRowId = useCallback(({ data }) => {
    return data.news_id;
  }, []);


  const getRowHeight = useCallback((params) => {
    return params.node.level === 0 ? 50 : 24;
  }, []);


  const popupParent = useMemo(() => {
    return document.querySelector('body');
  }, []);


  return (
    <div className={clsx(className, classes.root)}>
      <div className={classes.overlayHeader}>
        <div className={classes.overlayHeaderTitle}>
          <Typography>Compare similar articles</Typography>
          <DarkTooltip
            enterDelay={0}
            variant="primary"
            placement="bottom"
            title={
              <Typography variant="body2">
                This table compares the selected article's headline to previous articles about the same ticker.
                <br /><br />
                A score of 1.0 is very similar.
                <br />
                A score of .30 has little similarity.
              </Typography>
            }
          >
            <HelpOutlineIcon style={{ fontSize: 15, marginLeft: 6, color: theme.palette.text.secondary }} />
          </DarkTooltip>
        </div>
        <PanelIconButton
          Icon={ArrowBackIcon}
          className={classes.tabButton}
          onClick={onClose}
          text="Back"
        />
      </div>
      <AgGridReact
        context={{
          isInnerGrid: true
        }}
        ref={gridRef}
        rowData={records}
        columnDefs={RSCORE_OVERLAY_GRID_COLUMNS}
        components={gridComponents}
        rowSelection={'single'}
        navigateToNextCell={handleKeyboardRowNavigation}
        suppressMultiSort={true}
        animateRows={true}
        headerHeight={25}
        popupParent={popupParent}
        getContextMenuItems={makeWatchlistMenu}
        getRowId={getRowId}
        getRowHeight={getRowHeight}
        rowModelType="clientSide"
        getRowClass={params => {
          if (params.node.rowIndex === 0) {
            return 'ag-grid-rscore-top-row';
          }
        }}
      // onGridReady={onGridReady}
      // loadingCellRendererSelector={loadingCellRendererSelector}
      />
    </div>
  );
}


export default OverlayGrid;
