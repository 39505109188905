

// Backend provides the categories 

export const NEWS_SEC_FORM_TYPES = {
  earnings: { label: 'Financials' },
  news: { label: 'News' },
  dilution: { label: 'Dilution' },
  proxy: { label: 'Proxy' },
  ownership: { label: 'Ownership' },
  other: { label: 'Other' },
  transcripts: { label: 'Transcripts' }
};
