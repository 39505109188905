
/**
 * When profiles or expressions are deleted, we need to remove all references to them.
 *
 * Theres no simple way to do this, its like pulling teeth.
 *
 * Keep in mind, any state structure changes must be reflected here, as well as slicedForms,
 * as well as profileToQuery, as well as fucking everywhere else.
 *
 * TODO: We need to get a handle on tree state transformations
 *
 * 1) Define an interface for mappings. Then each mapper (toQuery, toProfile, toPreview) should
 *    extend this
 * 2) Define an interface for modifications. Like filterReducer formTreeManipulation. Then modifying
 *    nodes and leaves becomes easier to manage. When changes are made, its obvious how to do so. 
 *
 **/

import { isObject } from "lodash";



/**
 * Get the IDs of any profile that has been deleted in this update
 * @param {object[]} nextProfiles
 * @param {object[]} oldProfiles
 * @returns {string[]} - The deleted IDs.
 **/
export const getRemovedProfileIds = (nextProfiles, oldProfiles) => {
  const newProfileIds = nextProfiles.map(p => p.id);
  const oldProfileIds = oldProfiles.map(p => p.id);
  return oldProfileIds.filter(x => !newProfileIds.includes(x));
}



/**
 * Get the names (ID's) of any expression that has been deleted in this update
 * @param {boolean} hasExpressionsChanged
 * @param {string} contextKey - Expression's context key
 * @param {object[]} nextExpressions
 * @param {object[]} oldExpressions
 * @returns {string[]} - The deleted IDs.
 **/
export const getRemovedExpressions = (
  hasExpressionsChanged,
  contextKey,
  nextExpressions,
  oldExpressions
) => {
  if (!hasExpressionsChanged) return [];

  const newExpressionIds = nextExpressions.map(e => e.name);
  const oldExpressionIds = oldExpressions.filter(e => e.contextKey === contextKey).map(e => e.name);
  return oldExpressionIds.filter(x => !newExpressionIds.includes(x));
}



/**
 * IF this component is referencing a deleted profile ID, remove it and set to default.
 * @param {object} draftComponent
 * @param {string[]} removedProfileIds
 * @param {object} profileType
 * @returns {boolean} - True if a change was made
 **/
export const removeDeletedProfileIdReferences = (
  draftComponent,
  removedProfileIds = [],
  profileType,
) => {
  const { idKey, listKey, defaultProfileId } = profileType;

  if (!(idKey in draftComponent)) return false;

  if (!removedProfileIds.includes(draftComponent[idKey])) return false;

  draftComponent[idKey] = defaultProfileId;

  return true;
}



/**
 * If this component is being ordered by a deleted expression, remove the reference.
 * @param {object} draftComponent
 * @param {string[]} removedExpressionNames
 * @returns {boolean} - True if a change was made
 **/
export const removeDeletedExpressionOrderbyReferences = (
  draftComponent,
  removedExpressionNames = [],
) => {
  if (!removedExpressionNames.length) return false;

  if (!draftComponent?.orderby) return false;

  if (!removedExpressionNames.includes(draftComponent.orderby)) return false;

  draftComponent.orderby = null;

  return true;
}



/**
 * Iterate through all components in all layouts, calling the callback on each.
 * @param {object} draft
 * @param {({layoutId: string, layout: object, componentId: string, component: object}) => boolean} callback
 **/
export const forEachComponent = (draft, callback) => {
  Object.entries(draft.layouts).forEach(([layoutId, layout]) => {
    Object.entries(layout.components).forEach(([componentId, component]) => {
      callback({ layoutId, layout, componentId, component });
    });
  });
};


/**
 * Iterate through all profiles, calling the callback on each.
 * @param {object} draft
 * @param {object} profile_config PROFILE_CONFIG constant
 * @param {(ProfileConfigItem) => boolean} filterBy filter the list of profiles before iterating
 * @param {(object, object) => undefined} callback Called on each filter profile, for mutable updates
 * @param {string} type - The profile type to iterate over. Leave empty for all profile types.
 **/
export const forEachProfile = (
  draft,
  profile_config,
  filterBy,
  callback
) => {
  Object.values(profile_config).forEach(cfg => {
    if (!filterBy(cfg)) return;
    draft[cfg.listKey].forEach((prof) => {
      callback(prof, cfg);
    })
  })
}



/**
 * Iterate through all profiles, calling the callback on each.
 * If the callback returns a value, then we replace the original profile with the return value.
 * If the callback returns undefined, then we don't modify that profile.
 *
 * This is an attmept to limit rerenders when we cannot update mutably.
 *
 * @param {object} draft
 * @param {object} profile_config PROFILE_CONFIG constant
 * @param {(ProfileConfigItem) => boolean} filterBy filter the list of profiles before iterating
 * @param {(object, object) => object|undefined} callback Called on each filter profile,  
 * @param {string} type - The profile type to iterate over. Leave empty for all profile types.
 **/
export const conditionalMapProfile = (
  draft,
  profile_config,
  filterBy,
  callback
) => {
  Object.values(profile_config).forEach(cfg => {
    if (!filterBy(cfg)) return;

    draft[cfg.listKey].forEach((prof, idx) => {
      const updated = callback(prof, cfg);
      if (isObject(updated)) {
        draft[cfg.listKey][idx] = updated;
      }
    })
  })
}



