import React, { useState, useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { format, parse } from 'date-fns';
import {
  Typography,
  Button,
  TextField,
  FormControl,
  makeStyles,
  ClickAwayListener,
} from '@material-ui/core';
import useInputStyles, {
  ButtonStyleProps,
  InputStyleProps
} from 'src/app/slicedForm/shared/styles/useInputStyles';
import PopoverKeyboardTimePicker from 'src/app/components/pickers/PopoverKeyboardTimePicker';
import { parseAssumeMarketTime } from 'src/utils/datetime/date-fns.tz';
import { makeSliceDisabledTimes } from 'src/app/components/pickers/definitions/disabledTimes';

const useStyles = makeStyles(() => ({
  root: {},
  disabled: {
    // opacity: .7,
    pointerEvents: 'none'
  },
  button: {
    whiteSpace: 'nowrap'
  }
}));


const TIME_FMT = 'HH:mm';


function TimeSliceInput({
  className,
  id,
  start,
  end,
  slices,
  onChange,
  innerRef,
  error,
  disabled,
}) {
  const classes = useStyles();
  const inputClasses = useInputStyles();
  const [showPicker, setShowPicker] = useState(false);
  const startDate = useMemo(() => start ? parseAssumeMarketTime(start, TIME_FMT) : null, [start]);
  const disabledTime = useMemo(() => makeSliceDisabledTimes(id, slices), [id, slices])

  const handleAccept = useCallback((newStartDate) => {
    const stamp = format(newStartDate, TIME_FMT);
    onChange(stamp);
  }, [onChange]);

  const handleKeyUp = useCallback((event) => {
    if (event.key === 'Enter') {
      setShowPicker(false);
    }
  }, []);

  const render = () => {
    if (showPicker || !startDate) {
      return (
        <ClickAwayListener onClickAway={() => setShowPicker(false)}>
          <div>
            <PopoverKeyboardTimePicker
              inputClassName={clsx(inputClasses.input)}
              disableUnderline
              showErrorMessage
              errorPositionAbsolute
              showNowButton
              backgroundOpacity={0}
              date={startDate}
              format={TIME_FMT}
              placeholder={'hh:mm'}
              disabledTime={disabledTime}
              onKeyUp={handleKeyUp}
              marketTime
              onAccept={handleAccept}
            />
          </div>
        </ClickAwayListener>
      );
    } else {
      return (
        <Button
          {...ButtonStyleProps}
          className={clsx(
            inputClasses.button,
            inputClasses.backgroundButton,
            classes.button
          )}
          onClick={() => setShowPicker(true)}
          disabled={disabled}
        >
          {(start && end)
            ? `${start} - ${end}`
            : 'Select...'
          }
        </Button>
      );
    }
  };


  return (
    <FormControl
      className={clsx(
        className,
        classes.root,
        error && inputClasses.formControlError,
        error && 'sf-form-control-error-flash'
      )}
      variant="outlined"
      fullWidth={true}
      ref={innerRef}
      error={Boolean(error)}
    >
      {render()}
    </FormControl>
  );
}

export default React.memo(TimeSliceInput);
