import {
  STRUCTURAL_TYPES,
  STRUCTURAL_CATEGORIES,
  STRUCTURAL_TYPE_RULES,
  getNodeTypeCategory
} from 'src/app/slicedForm/mapping/mappingDirections/index';
import _mapValues from 'lodash/mapValues';
import { unflattenPathsToObject } from '../flatpath.js';

/**  @typedef {import('./index.js').FormStruct} FormStruct */


export default function FormToProfile(state) {
  const entities = state.entities;
  const profiles = [];
  const formType = state.formType;

  if (!formType) {
    throw new Error('formType is required, set it inside profileToForm');
  }

  const out = () => {
    state.profiles.forEach(mapProfile);
    return {
      profiles,
      activeProfile: state.activeProfile,
      expressions: state.expressions,
    };
  };


  const decideNodeType = (node) => {
    if (!node || !(typeof node === 'object')) return null;
    return node?.type;
  };



  /** @param {FormStruct} profile */
  const mapProfile = (profile) => {
    const { root, ...rest } = profile;

    const recurse = (node) => {
      const type = decideNodeType(node);
      const category = getNodeTypeCategory(type);
      const treeKey = STRUCTURAL_TYPE_RULES[type]?.treeKey;

      switch (category) {
        case STRUCTURAL_CATEGORIES.GROUP_WITHOUT_ENTITY: {
          return {
            [treeKey]: node.tree.map(recurse)
          };
        }
        case STRUCTURAL_CATEGORIES.GROUP_WITH_ENTITY: {
          return {
            ...unflattenPathsToObject(_mapValues(entities[node.id], e => e?.val)),
            [treeKey]: node.tree.map(recurse)
          };
        }
        case STRUCTURAL_CATEGORIES.ENTITY: {
          return unflattenPathsToObject(_mapValues(entities[node.id], e => e?.val));
        }
        default: {
          throw new Error(`Unknown node category "${category}<${type}>", node ${JSON.stringify(node)}}`);
        }
      }
    };

    const out = recurse(root);

    // Get rid of the useless ROOT node. Its purely to allow arrays as a base level item.
    profiles.push({
      [formType]: (STRUCTURAL_TYPES.ROOT in out) ? out[STRUCTURAL_TYPES.ROOT] : out,
      ...rest
    });
  };


  return out();
}
