import { useRef, useEffect } from 'react';
import { useSelector } from 'react-redux';


function useSelectorRef(selector) {
  const selectedState = useSelector(selector);
  const selectedStateRef = useRef(selectedState);

  useEffect(() => {
    selectedStateRef.current = selectedState;
  }, [selectedState])

  return [selectedState, selectedStateRef];
}

export default useSelectorRef;
