import React from 'react';
import ActionList from 'src/app/components/ActionList';
import HoverFieldDefinition from 'src/app/slicedForm/ColumnForm/elements/HoverFieldDefinition';

export function ColumnCheckboxRow(props) {
  return <ColumnRow {...props} checkbox />
}

export function ColumnSimpleRow(props) {
  return <ColumnRow {...props} />
}

function ColumnRow({ index, style, data, checkbox }) {
  const colDef = data.filteredColumns[index];
  const selected = data.selectedColumns.includes(colDef.name)

  return (
    <ActionList.Item
      pl={6}
      style={style}
      selected={selected}
      checkbox={checkbox}
      selectionVariant={checkbox ? 'multiple' : 'single'}
      role={checkbox ? 'menuitemcheckbox' : 'menuitem'}
      onSelect={() => data.onSelect(colDef, selected)}
      title={colDef.label}
      {...data.getOptionProps(index, { selected })}
    >
      {colDef.label}
      <ActionList.Trailing>
        <HoverFieldDefinition
          colDef={colDef}
          anchorPosition="right"
        />
      </ActionList.Trailing>
    </ActionList.Item>
  )
}
