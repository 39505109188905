import React from 'react';
import { makeStyles } from '@material-ui/core';
import {
  CircularProgress
} from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 20,
    height: '100%',
    display: 'flex',
    alignItems: 'center',
  }
}));


function WatchlistLoadingCellFullWidth() {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <CircularProgress size={15}/>
    </div>
  );
}


export default WatchlistLoadingCellFullWidth;
