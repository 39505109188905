
export const TOPICS = {
  ACCHTHIN: { label: 'Accident & Health Insurance', description: 'Insurance and Government Health Programs News' },
  ADAGENCY: { label: 'Advertising Agencies - Sector', description: 'News about the Advertising Agencies Sector' },
  ADVMKTPR: { label: 'Advertising, Marketing and PR', description: 'Public Relations, Investor Relations, Marketing and Advertising News' },
  AERODEFS: { label: 'Aerospace and Defense', description: 'Aerospace, Defense, Aviation News' },
  AERODFMD: { label: 'Aerospace/Defense - Major Diversified', description: 'Major Diversified Aerospace and Defense Company News' },
  AERODFPS: { label: 'Aerospace/Defense Products & Services', description: 'Aerospace and Defense Product News' },
  AGR10101: { label: 'Agriculture - Sector', description: 'News about the Agriculture Sector' },
  ALTENERG: { label: 'Alternate Energy', description: 'Wind, Solar and Natural Gas, Renewable Energy Resources News' },
  AMEX0001: { label: 'NYSE American Exchange', description: 'News related to NYSE American listed stocks.' },
  ANTITRST: { label: 'Antitrust', description: 'Antitrust, Monopoly and Unfair Business Practice News' },
  APPLIANC: { label: 'Appliances', description: 'Major and Household Appliance News' },
  ASIAPACI: { label: 'Asia Pacific', description: 'News about Asia Pacific Region' },
  ASSMNGMT: { label: 'Asset Management', description: 'Financial Resource and Asset Management News' },
  AUTOMTVE: { label: 'Automotive', description: 'Auto Makers, After Market and Auto Suppliers News' },
  BANKFINA: { label: 'Banking and Finance', description: 'Banking and Financial Institutions News' },
  BANKING9: { label: 'Banking', description: 'Consumer Banking News' },
  BASICMAT: { label: 'Basic Materials - Sector', description: 'News relating to the Basic Materials Sector' },
  BEVBREWS: { label: 'Beverages - Brewers', description: 'Brewery and Brewing News' },
  BEVSOFTD: { label: 'Beverages - Soft Drinks', description: 'Carbonated Beverage News' },
  BEVWINDS: { label: 'Beverages - Wineries & Distillers', description: 'Wine and Spirits News' },
  BIGDATA: { label: 'Big Data', description: 'Custom category' },
  BIOTCHNO: { label: 'Biotechnology', description: 'Biotechnology Companies and Research News' },
  BODSH: { label: 'BoD/SH', description: 'Board of Directors or Shareholders discussions' },
  BONDS001: { label: 'Bonds', description: 'Bond Market News' },
  BREAKING: { label: 'Breaking News and Urgent Updates', description: 'timely news, natural disasters and other leading stories' },
  BROADCRD: { label: 'Broadcasting - Radio', description: 'Over the Air and Satellite Radio News' },
  BROADCTV: { label: 'Broadcasting - TV', description: 'Over the Air, Satellite and Cable Subscription Television News' },
  BUSINESS: { label: 'Economy, Business, and Finance', description: 'Economic, Business and Financial News' },
  BUYBACK1: { label: 'Stock Buyback', description: 'Corporate Stock Shares Buyback News' },
  CAPITALG: { label: 'Capital Gains', description: 'Corporate Capital Gains News' },
  CASINENT: { label: 'Casinos - Entertainment', description: 'News about Casinos, Gambling and Gaming' },
  CASINTRA: { label: 'Casinos - Travel', description: 'News about Casino, Gambling, Gaming and Casino Resort Hotels' },
  CELEBRTY: { label: 'Celebrity News and Gossip', description: 'Gossip and News about today\'s celebrities and wannabes' },
  CHEMICLS: { label: 'Chemicals', description: 'Chemical Companies and Research News' },
  CHICMERC: { label: 'Chicago Mercantile Exchange', description: 'Chicago Mercantile Exchange News' },
  CIGARETT: { label: 'Cigarettes', description: 'Cigarette Companies News' },
  COAL0001: { label: 'Coal News', description: 'News about Coal and electricity generation' },
  COM308: { label: 'Communication Services - Sector', description: 'News about the Communication Services Sector' },
  COMMODIT: { label: 'Commodities', description: 'Commodities and Futures News' },
  COMPHARD: { label: 'Computer Hardware', description: 'Computer Hardware Products and Technology News' },
  COMPSWSR: { label: 'Computer Software', description: 'Computer Software Products and Technology News' },
  COMPUTER: { label: 'Computer', description: 'Computer Companies, Products and Technologies News' },
  CON102: { label: 'Consumer Cyclical - Sector', description: 'News relating to the Consumer Cyclical Sector' },
  CON205: { label: 'Consumer Defensive - Sector', description: 'News relating to the Consumer Defensive Sector' },
  CONFEREN: { label: 'Conference Calls, Web Events', description: 'Corporate Conference Calls, Web Events and Annual Meeting News' },
  CONSCRED: { label: 'Consumer Credit', description: 'Consumer Credit' },
  CONSGOOD: { label: 'Consumer Goods', description: 'Consumer and Household Goods News' },
  CONSUMER: { label: 'Consumer Affairs', description: 'Consumer Services and Product Recall News and Advisories' },
  CPI00001: { label: 'Consumer Price Index', description: 'News about the Consumer Price Index' },
  CRUDEOIL: { label: 'Crude Oil News', description: 'News about Crude Oil' },
  CRYPTOC: { label: 'Cryptocurrency', description: 'Cyrptocurrency News' },
  DATAWARE: { label: 'Data Warehousing', description: 'Data Warehousing Products and Technologies News' },
  DIVIDEND: { label: 'Cash Or Stock Dividend', description: 'Corporate Dividend News' },
  DRUGS999: { label: 'Pharmaceutical', description: 'Pharmaceutical Companies and Research News' },
  EARNINGS: { label: 'Earnings', description: 'Corporate Earnings News' },
  ECONOMY1: { label: 'Economy', description: 'Economic and Economy News' },
  EDUCATIN: { label: 'Education', description: 'All Levels of Education and Instruction News' },
  ELE31165130: { label: 'Electronic Gaming and   Multimedia Sector', description: 'Electronic Gaming and Multimedia News' },
  ELECTION: { label: 'Election', description: 'Stories about Elections and Candidates' },
  ELECTRON: { label: 'Electronics', description: 'Electronics Companies, Products and Technologies News' },
  ENERGY01: { label: 'Energy', description: 'Energy Companies, Products and Technologies News' },
  ENTRTAIN: { label: 'Entertainment', description: 'Entertainment Companies, Products and People News' },
  EUROPE01: { label: 'Europe', description: 'News about European Region' },
  EUROUNIO: { label: 'European Union', description: 'News about the European Union' },
  FASHION01: { label: 'Fashion', description: 'Fashion News' },
  FDA00001: { label: 'FDA', description: 'News about the FDA, the Food Drug Administration' },
  FEDERAL: { label: 'Federal', description: 'Stories about or from Federal Agencies, Policy and Policymakers' },
  FEDRESRV: { label: 'Federal Reserve', description: 'News about the Federal Reserve' },
  FINANCE1: { label: 'Finance', description: 'Finance Industry News' },
  FINANCIL: { label: 'Financial', description: 'Financial Tools, Methods and Products News' },
  FINASERV: { label: 'Financial and Business Services', description: 'Financial and Accounting Companies, Products and Methods' },
  FINSERVI: { label: 'Financial and Business Services', description: 'News relating to the Financial Services Sector' },
  FOODSBEV: { label: 'Food and Beverage', description: 'Food and Beverage Companies and Research' },
  FORESTPR: { label: 'Forest Products', description: 'News about Forest Products and Timber' },
  FORNEXCH: { label: 'Foreign Exchange (FOREX)', description: 'Foreign Exchange and Currency News' },
  FUTURES1: { label: 'Futures Trading', description: 'Futures and Commodities News' },
  GAMEMULT: { label: 'Games and Multimedia', description: 'Games, Multimedia, Playstation, Dreamcast, Nintend…Media, Expert Gamer, and Computer Gaming \n   News' },
  GAMENEWS: { label: 'Game News', description: 'Playstation, Dreamcast, Nintendo, Game Boy, Neo   …ames, Game Media, \n Expert Gamer, Computer Gaming' },
  GAMING00: { label: 'Video and Online Gaming', description: 'PC and Console Gaming Companies, Products and Technologies News and Reviews' },
  HEA31165131: { label: 'Health Information Services', description: 'News about the Health Information Services Sector' },
  HISTORY0: { label: 'History', description: 'News about Historic People, Places and Significant Events' },
  HLTHCARE: { label: 'Healthcare', description: 'Healthcare Providers and Specialty News' },
  HLTHSERV: { label: 'Health Services', description: 'Healthcare Companies, Products and Research News' },
  HOMEFUFX: { label: 'Furnishings, Fixtures &    Appliances - Sector', description: 'News about the Furnishings, Fixtures and Appliances    Sector' },
  HOSPITAL: { label: 'Hospitals', description: 'Healthcare Facilities and Administration News' },
  HUMANRES: { label: 'Human Resources/Labor', description: 'Human Resources, Employment and Labor News' },
  IMF00001: { label: 'IMF', description: 'News about the IMF, the International Monetary Fund' },
  IND310: { label: 'Industrials - Sector', description: 'News about the Industrials Sector' },
  INDUSGDS: { label: 'Industrial Goods', description: 'Industrial Products, Goods and Services News' },
  INSIDRTR: { label: 'Insider Trading', description: 'Insider Trading History, Stock Purchases and Activity News' },
  INSUBROK: { label: 'Insurance Brokers - Sector', description: 'News about the Insurance Brokers Sector' },
  INTHPINK: { label: 'OTC Markets Exchange', description: 'News relating to OTC Markets listed stocks.' },
  INTLTRAD: { label: 'International Trade', description: 'Trade and Commerce between US and other Countries News' },
  INTRNET9: { label: 'Internet', description: 'Internet Companies, Products and Technologies News' },
  INTRSTRA: { label: 'Interest Rates', description: 'News about US Interest Rates and Impact on Economy' },
  INVESTOP: { label: 'Investment Opinion', description: 'Investor Opinions, Stock Ratings and Performance Analysis News' },
  IPONEWS1: { label: 'IPO\'s', description: 'Initial Public Offering News and Events' },
  LEGAL000: { label: 'Legal News', description: 'Legal and Law Issues and News' },
  LEISURE9: { label: 'Leisure', description: 'Leisure, Recreation, Travel and Culture News' },
  LSE: { label: 'London Stock Exchange', description: 'News related to LSE listed stocks.' },
  MANUFACT: { label: 'Manufacturing', description: 'Manufacturing and Semiconductor Industry and    Manufacturer News' },
  MANUFCTU: { label: 'Manufacturing and Engineering', description: 'Industrial Manufacturing, Engineering and Technologies News' },
  MARKCOMM: { label: 'Market News', description: 'company communication and market moving news' },
  MARKECON: { label: 'Market and Economy', description: 'News that may affect stock markets and global economies' },
  MATERCON: { label: 'Materials and Construction', description: 'Building Materials and Construction Design and Equipment News' },
  MEDIA999: { label: 'Media', description: 'Books, Film, Music and Performing Arts News and Events' },
  MEDICDEV: { label: 'Medical Devices', description: 'Medical Devices, Cardiac Pacemaker, Prosthesis and    Prosthetic News' },
  MEDMARIJ: { label: 'Medical Marijuana', description: 'Medical Marijuana minus illegal and illicit terms' },
  MEETINGS: { label: 'Meetings', description: 'Meetings' },
  MERGERAC: { label: 'Mergers & Acquisitions', description: 'Corporate Mergers and Acquisitions News and Events' },
  METALMIN: { label: 'Metal and Mineral', description: 'Mining, Metals and Geological Exploration News' },
  MINING02: { label: 'Mining 2', description: 'Mining, Mineral and Minerals News' },
  MORTGAGE: { label: 'Mortgage', description: 'Residential and Commercial Property Financing News' },
  MUTUALFD: { label: 'Mutual Funds', description: 'Funds Management News' },
  NASDAQ01: { label: 'Nasdaq', description: 'News related to Nasdaq listed stocks.' },
  NATGAS02: { label: 'Natural Gas', description: 'Natural Gas Companies, Products and Technologies News' },
  NATIONAL: { label: 'National News', description: 'US Government News and Events' },
  NATURALG: { label: 'Natural Gas', description: 'Natural Gas News and Research' },
  NATURALR: { label: 'Natural Resources', description: 'Environmental and Agricultural Products News and Research' },
  NEWYORK1: { label: 'New York', description: 'News about New York City' },
  NONCOMPANY: { label: 'Non-Company', description: 'To filter out Non-Company News Releases' },
  NOUS: { label: 'No US Distribution', description: 'Stories that contain \'Not for Distribution in the US\'' },
  NYSE0001: { label: 'NYSE', description: 'News related to NYSE listed stocks.' },
  OILENERG: { label: 'Oil & Energy', description: 'Oil and Gas Companies, Products and Technologies News' },
  OPEC0001: { label: 'OPEC News', description: 'News about the Organization of the Petroleum Exporting    Countries, OPEC and Oil Ministers' },
  PHA20533080: { label: 'Pharmaceutical Retailers', description: 'News about the Pharmaceutical Retailers Sector' },
  PHARMACE: { label: 'Pharmaceutical', description: 'News about Pharmaceutical, Pharmacy and Blue Cross' },
  POLITICS: { label: 'Politics', description: 'Presidential Candidates and Political Parties News' },
  PRHYPE: { label: 'Press Release Hype', description: 'stock hype, self serving press releases and other content' },
  PRODPRIC: { label: 'Producer Price Index', description: 'News about the Producer Price Index' },
  PUB10215: { label: 'Publishing - Sector', description: 'News about the Publishing Sector' },
  RAILROAD: { label: 'Railroad', description: 'Railroad, Railway and Amtrak News' },
  RANT0000: { label: 'Rantings', description: 'News on a Variety of Topics with Passionate or Irreverent Language' },
  RATINGS1: { label: 'Ratings', description: 'Ratings on Stocks, Markets and Indexes' },
  REALESTT: { label: 'Real Estate', description: 'Residential and Commercial Real Estate News' },
  REI10428: { label: 'REITs', description: 'News about REITs' },
  REMOVE: { label: 'Removed Stories', description: 'Stories sent by providers that should not be processed' },
  RETAIL99: { label: 'Wholesale and Retail', description: 'Wholesale and Resale Companies, Products and Technologies News' },
  SCIENCE0: { label: 'Science', description: 'Scientific News and Research' },
  SOCIALIS: { label: 'Social Issues', description: 'News about Social Issues such as Race, Religion, Sex/Gender' },
  SOFTWARE: { label: 'Software', description: 'Software Industry Companies, Products and Technologies News' },
  SOLARPOW: { label: 'Solar Power', description: 'News about Solar Power and Solar Energy' },
  SPAM: { label: 'SPAM', description: 'Stories with no editorial value, collection of links or other useless info' },
  STATEPOL: { label: 'State Politics', description: 'State Politics feed' },
  STE10107: { label: 'Steel - Sector', description: 'News about the Steel Sector' },
  STKSPLIT: { label: 'Stock Splits', description: 'Corporate Stock and Shares Split News' },
  TECHNOLO: { label: 'Computing and Information Technology - Sector', description: 'Technology Companies and Products News' },
  TELECOMU: { label: 'Telecommunications', description: 'Telecommunications Companies, Products and Technologies News' },
  TOBACOO9: { label: 'Tobacco', description: 'Tobacco Companies, Products and Legislation News' },
  TRANSPRT: { label: 'Transportation', description: 'Transportation and Logistics Companies, Products and Technologies News' },
  TSXTSX01: { label: 'TSX and TSXV Exchanges', description: 'News related to TSX and TSXV listi' },
  URANIUM1: { label: 'Uranium News', description: 'News and Research about Uranium' },
  UTILITIE: { label: 'Utilities', description: 'Utilities Companies, Products and Technologies News' },
  VENTUREC: { label: 'Venture Capital', description: 'Venture Capital and Angel Investing Financing News' },
  WORLDNEW: { label: 'World News', description: 'News about International Trade, Foreign Policy and Global Even ts' }
};

