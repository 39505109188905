import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { fullFormatStatCellNumber } from 'src/utils/formatStatCellNumber';
import {
  Typography,
  Box,
  makeStyles,
  useTheme
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {},
  title: {
    padding: '0 .5em .6em',
    fontSize: props => props.fontSize,
    display: 'inline-block'
  },
  titleSupplement: {
    display: 'inline-block'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fontSize.data,
    padding: props => props.dense ? '.4em 0px .3em .6em' : '.5em 0px .5em .6em',
    borderBottom: '1px solid rgba(81, 81, 81, 1)',
    '&:last-child': {
      borderBottom: 'none !important'
    }
  },
  cellLeft: {
    flex: 1,
    paddingRight: 5,
    '& p': {
      color: theme.palette.text.secondary,
    }
  },
  cellRight: {
    paddingRight: 21,
    '& p': {
      color: theme.palette.text.primary,
      fontWeight: 500
    }
  },
  hasAlarm: {
    position: 'relative'
  },
  alarmCircle: {
    borderRadius: 3,
    width: 11,
    height: 11,
    position: 'absolute',
    right: 4,
    top: 'calc(50% - 1px)',
    transform: 'translateY(-50%)'
  },
  flagIcon: {
    width: 16,
    position: 'absolute',
    top: 'calc(50%)',
    right: 0,
    transform: 'translateY(-50%)'
  }
}));


function KeyStatsStatTable({
  className,
  rows,
  dense,
  align
}) {
  const classes = useStyles({ dense });
  const theme = useTheme();


  const renderAlarm = ({ value, alarmsEnabled, less, greater, reversed, flag }) => {
    if (!alarmsEnabled || !less || !greater || !value) {
      return null;
    }
    let backgroundColor;
    if (value < less) {
      backgroundColor = reversed ? theme.palette.alarms.greater : theme.palette.alarms.less;
    } else if (value < greater) {
      backgroundColor = theme.palette.alarms.between;
    } else {
      backgroundColor = reversed ? theme.palette.alarms.less : theme.palette.alarms.greater;
    }
    return (
      <div style={{ backgroundColor }} className={classes.alarmCircle} />
    );
  };


  return (
    <Box className={clsx(className, classes.root)}>
      <Box>
        {rows.map((row, i) => {
          return (
            <div className={clsx(classes.row, 'two-col-div-table-row')} key={i}>
              <div className={clsx(classes.cell, classes.cellLeft)}>
                <Typography variant="body2" color="textPrimary">{row.label}</Typography>
              </div>
              <div className={clsx(classes.cell, classes.cellRight, Boolean(row.alarmsEnabled || row.flag) && classes.hasAlarm)} style={{ textAlign: align }}>
                <Typography variant="body2" color="textPrimary">
                  {fullFormatStatCellNumber(row.value, row)}
                </Typography>
                {renderAlarm(row)}
                {row.flag && <img alt="Flag" className={classes.flagIcon} src={row.flag} />}
              </div>
            </div>
          );
        })}
      </Box>
    </Box>
  );
}


KeyStatsStatTable.propTypes = {
  className: PropTypes.string,
  rows: PropTypes.array,
  dense: PropTypes.bool,
  align: PropTypes.string
}


KeyStatsStatTable.defaultProps = {
  align: 'right',
  dense: false
}

export default KeyStatsStatTable;

