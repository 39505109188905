
export const FINANCIALS_COLUMNS = {
  'General': [
    { name: 'BurnMo', label: 'Burn/mo', alarmsEnabled: true, nullFormat: 'N/A' },
    { name: 'WorkingCapital', label: 'Working Capital', cash: true, alarmsEnabled: true },
    { name: 'MonthsOfCash', label: 'Months of Cash', alarmsEnabled: true, nullFormat: 'N/A' },
    { name: 'EstimatedCash', label: 'Estimated Cash', pre: '$', cash: true, alarmsEnabled: true },
    { name: 'IssuanceEquityMo', label: 'Issuance Equity/mo', alarmsEnabled: true },
    { name: 'CurrentRatio', label: 'Current Ratio', alarmsEnabled: true },
    { name: 'DebtEquityRatio', label: 'Debt/Equity Ratio', alarmsEnabled: true },
    { name: 'pe_ratio', label: 'P/E Ratio', alarmsEnabled: true}
  ],
  'Balance Sheet': [
    { name: 'CashAndCashEquivalents', label: 'Cash & Equiv', cash: true, alarmsEnabled: true },
    { name: 'TotalAssets', label: 'Total Assets', cash: true, alarmsEnabled: true },
    { name: 'TotalLiabilities', label: 'Total Liabilities', cash: true, alarmsEnabled: true },
    { name: 'LongTermDebt', label: 'Long Term Debt', cash: true, alarmsEnabled: true },
    { name: 'AccountsPayable', label: 'Accounts Payable', cash: true, alarmsEnabled: true },
    { name: 'RetainedEarnings', label: 'Retained Earnings', cash: true, alarmsEnabled: true },
    { name: 'AccumulatedDepreciation', label: 'Accumulated Depreciation', cash: true, alarmsEnabled: true },
    { name: 'CashEquivalents', label: 'Cash Equiv', cash: true, alarmsEnabled: true },
    { name: 'Payables', label: 'Payables', cash: true, alarmsEnabled: true },
    { name: 'CurrentDebtAndCapitalLeaseObligation', label: 'Current Debt & Capital Lease Obligation', cash: true, alarmsEnabled: true },
    { name: 'MachineryFurnitureEquipment', label: 'Machinery Furniture Equipment', cash: true, alarmsEnabled: true },
    { name: 'Cash', label: 'Cash', cash: true, alarmsEnabled: true },
    { name: 'GrossPPE', label: 'Gross PPE', cash: true, alarmsEnabled: true },
    { name: 'CommonStock', label: 'Common Stock', cash: true, alarmsEnabled: true },
    { name: 'TotalCapitalization', label: 'Total Capitalization', cash: true, alarmsEnabled: true },
    { name: 'TotalEquityGrossMinority', label: 'Total Equity Gross Minority', cash: true, alarmsEnabled: true },
    { name: 'Leases', label: 'Leases', cash: true, alarmsEnabled: true },
    { name: 'TotalNonCurrentLiabilities', label: 'Total Non Current Liabilities', cash: true, alarmsEnabled: true },
    { name: 'ShortTermInvestments', label: 'Short Term Investments', cash: true, alarmsEnabled: true },
    { name: 'InvestmentsAndAdvances', label: 'Investments & Advances', cash: true, alarmsEnabled: true },
    { name: 'OtherNonCurrentLiabilities', label: 'Other Non Current Liabilities', cash: true, alarmsEnabled: true },
    { name: 'OtherCurrentBorrowings', label: 'Other Current Borrowings', cash: true, alarmsEnabled: true },
    { name: 'OtherCurrentAssets', label: 'Other Current Assets', cash: true, alarmsEnabled: true },
    { name: 'CapitalStock', label: 'Capital Stock', cash: true, alarmsEnabled: true },
    { name: 'Receivables', label: 'Receivables', cash: true, alarmsEnabled: true },
    { name: 'CommercialPaper', label: 'Commercial Paper', cash: true, alarmsEnabled: true },
    { name: 'CashCashEquivalentsAndShortTermInvestments', label: 'Cash & Mark Sec.', cash: true, alarmsEnabled: true },
    { name: 'TotalNonCurrentAssets', label: 'Total Non Current Assets', cash: true, alarmsEnabled: true },
    { name: 'PayablesAndAccruedExpenses', label: 'Payables & Accrued Expenses', cash: true, alarmsEnabled: true },
    { name: 'StockholdersEquity', label: 'Stockholders Equity', cash: true, alarmsEnabled: true },
    { name: 'OtherReceivables', label: 'Other Receivables', cash: true, alarmsEnabled: true },
    { name: 'OtherCurrentLiabilities', label: 'Other Current Liabilities', cash: true, alarmsEnabled: true },
    { name: 'CurrentAssets', label: 'Current Assets', cash: true, alarmsEnabled: true },
    { name: 'GainsLossesNotAffectingRetainedEarnings', label: 'Gains Losses Not Affecting Retained Earnings', cash: true, alarmsEnabled: true },
    { name: 'CurrentDebt', label: 'Current Debt', cash: true, alarmsEnabled: true },
    { name: 'CurrentLiabilities', label: 'Current Liabilities', cash: true, alarmsEnabled: true },
    { name: 'LongTermDebtAndCapitalLeaseObligation', label: 'Long Term Debt & Capital Lease Obligation', cash: true, alarmsEnabled: true },
    { name: 'AccountsReceivable', label: 'Accounts Receivable', cash: true, alarmsEnabled: true },
    { name: 'NetPPE', label: 'Net PPE', cash: true, alarmsEnabled: true },
    { name: 'CurrentDeferredLiabilities', label: 'Current Deferred Liabilities', cash: true, alarmsEnabled: true },
    { name: 'OtherNonCurrentAssets', label: 'Other Non Current Assets', cash: true, alarmsEnabled: true },
    { name: 'LandAndImprovements', label: 'Land & Improvements', cash: true, alarmsEnabled: true },
    { name: 'CurrentDeferredRevenue', label: 'Current Deferred Revenue', cash: true, alarmsEnabled: true },
    { name: 'Inventory', label: 'Inventory', cash: true, alarmsEnabled: true },
    { name: 'NonCurrentDeferredTaxesLiabilities', label: 'Non Current Deferred Taxes Liabilities', cash: true, alarmsEnabled: true },
    { name: 'NonCurrentDeferredRevenue', label: 'Non Current Deferred Revenue', cash: true, alarmsEnabled: true },
    { name: 'NonCurrentDeferredLiabilities', label: 'Non Current Deferred Liabilities', cash: true, alarmsEnabled: true },
    { name: 'FinishedGoods', label: 'Finished Goods', cash: true, alarmsEnabled: true },
    { name: 'RawMaterials', label: 'Raw Materials', cash: true, alarmsEnabled: true },
    { name: 'CurrentAccruedExpenses', label: 'Current Accrued Expenses', cash: true, alarmsEnabled: true },
    { name: 'GoodwillAndOtherIntangibleAssets', label: 'Goodwill & Other Intangible Assets', cash: true, alarmsEnabled: true },
    { name: 'OtherIntangibleAssets', label: 'Other Intangible Assets', cash: true, alarmsEnabled: true },
    { name: 'Goodwill', label: 'Goodwill', cash: true, alarmsEnabled: true },
  ],
  'Income Statement': [
    { name: 'TotalRevenue', label: 'Revenue', cash: true, alarmsEnabled: true, allowZero: true },
    { name: 'NetIncome', label: 'Net Income', cash: true, alarmsEnabled: true },
    { name: 'EBITDA', label: 'EBITDA', cash: true, alarmsEnabled: true },
    { name: 'BasicEPS', label: 'EPS', cash: true, alarmsEnabled: true },
    { name: 'ROA', label: 'ROA', alarmsEnabled: true },
    { name: 'ROE', label: 'ROE', alarmsEnabled: true },
    { name: 'OperatingRevenue', label: 'Operating Revenue', cash: true, alarmsEnabled: true },
    { name: 'TotalExpenses', label: 'Total Expenses', cash: true, alarmsEnabled: true },
    { name: 'DilutedContinuousOperations', label: 'Diluted Continuous Operations', cash: true, alarmsEnabled: true },
    { name: 'InterestIncomeNonOperating', label: 'Interest Income Non Operating', cash: true, alarmsEnabled: true },
    { name: 'InterestExpenseNonOperating', label: 'Interest Expense Non Operating', cash: true, alarmsEnabled: true },
    { name: 'InterestIncome', label: 'Interest Income', cash: true, alarmsEnabled: true },
    { name: 'NetIncomeFromContinuingAndDiscontinuedOperation', label: 'Net Income From Continuing & Discontinued Operation', cash: true, alarmsEnabled: true },
    { name: 'NetIncomeContinuousOperations', label: 'Net Income Continuous Operations', cash: true, alarmsEnabled: true },
    { name: 'OperatingIncome', label: 'Operating Income', cash: true, alarmsEnabled: true },
    { name: 'DilutedAverageShares', label: 'Diluted Average Shares', cash: true, alarmsEnabled: true },
    { name: 'ContinuingAndDiscontinuedBasicEPS', label: 'Continuing & Discontinued Basic EPS', cash: true, alarmsEnabled: true },
    { name: 'NormalizedIncome', label: 'Normalized Income', cash: true, alarmsEnabled: true },
    { name: 'TotalOperatingIncomeAsReported', label: 'Total Operating Income As Reported', cash: true, alarmsEnabled: true },
    { name: 'NetNonOperatingInterestIncomeExpense', label: 'Net Non Operating Interest Income Expense', cash: true, alarmsEnabled: true },
    { name: 'GrossProfit', label: 'Gross Profit', cash: true, alarmsEnabled: true },
    { name: 'EBIT', label: 'Earning Before Interest & Taxes (EBIT)', cash: true, alarmsEnabled: true },
    { name: 'CostOfRevenue', label: 'Cost of Revenue', cash: true, alarmsEnabled: true },
    { name: 'ResearchAndDevelopment', label: 'Research & Development', cash: true, alarmsEnabled: true },
    { name: 'OtherIncomeExpense', label: 'Other Income Expense', cash: true, alarmsEnabled: true },
    { name: 'NetInterestIncome', label: 'Net Interest Income', cash: true, alarmsEnabled: true },
    { name: 'DividendPerShare', label: 'Dividend Per Share', cash: true, alarmsEnabled: true },
    { name: 'OperatingExpense', label: 'Operating Expense', cash: true, alarmsEnabled: true },
    { name: 'NetIncomeCommonStockholders', label: 'Net Income Common Stockholders', cash: true, alarmsEnabled: true },
    { name: 'ContinuingAndDiscontinuedDilutedEPS', label: 'Continuing & Discontinued Diluted EPS', cash: true, alarmsEnabled: true },
    { name: 'PretaxIncome', label: 'Earnings before Tax (EBT)', cash: true, alarmsEnabled: true },
    { name: 'BasicContinuousOperations', label: 'Basic Continuous Operations', cash: true, alarmsEnabled: true },
    { name: 'SellingGeneralAndAdministration', label: 'Selling, General & Administration', cash: true, alarmsEnabled: true },
    { name: 'DilutedEPS', label: 'Earnings Per Share (Diluted)', cash: true, alarmsEnabled: true },
    { name: 'InterestExpense', label: 'Interest Expense', cash: true, alarmsEnabled: true },
    { name: 'BasicAverageShares', label: 'Basic Average Shares', cash: true, alarmsEnabled: true },
    { name: 'TaxProvision', label: 'Tax Provision', cash: true, alarmsEnabled: true },
  ],
  'Cash Flow': [
    { name: 'ChangesInCash', label: 'Net Cash Flow', cash: true, alarmsEnabled: true },
    { name: 'OperatingCashFlow', label: 'Operating CF', cash: true, alarmsEnabled: true },
    { name: 'InvestingCashFlow', label: 'Investing CF', cash: true, alarmsEnabled: true },
    { name: 'FinancingCashFlow', label: 'Financing CF', cash: true, alarmsEnabled: true },
    { name: 'NetCommonStockIssuance', label: 'Stock Issuance', cash: true, alarmsEnabled: true },
    { name: 'PurchaseOfBusiness', label: 'Business Acquisitions & Disposals', cash: true, alarmsEnabled: true },
    { name: 'DepreciationAndAmortization', label: 'Depreciation & Amortization', cash: true, alarmsEnabled: true },
    { name: 'NetIssuancePaymentsOfDebt', label: 'Net Issuance Payments of Debt', cash: true, alarmsEnabled: true },
    { name: 'CashDividendsPaid', label: 'Payment of Dividends & Other Cash Distributions', cash: true, alarmsEnabled: true },
    { name: 'FreeCashFlow', label: 'Free Cash Flow', cash: true, alarmsEnabled: true },
    { name: 'ChangeInWorkingCapital', label: 'Change in Working Capital', cash: true, alarmsEnabled: true },
    { name: 'DeferredTax', label: 'Deferred Tax', cash: true, alarmsEnabled: true },
    { name: 'TaxExpense', label: 'Tax Expense', cash: true, alarmsEnabled: true },
    { name: 'LongTermDebtPayments', label: 'Long Term Debt Payments', cash: true, alarmsEnabled: true },
    { name: 'ChangeInPayablesAndAccruedExpense', label: 'Change in Payables & Accrued Expense', cash: true, alarmsEnabled: true },
    { name: 'CashFlowFromContinuingFinancingActivities', label: 'Net Cash Flow From Financing', cash: true, alarmsEnabled: true },
    { name: 'CommonStockIssuance', label: 'Common Stock Issuance', cash: true, alarmsEnabled: true },
    { name: 'IssuanceOfCapitalStock', label: 'Issuance of Capital Stock', cash: true, alarmsEnabled: true },
    { name: 'LongTermDebtIssuance', label: 'Long Term Debt Issuance', cash: true, alarmsEnabled: true },
    { name: 'ChangeInOtherWorkingCapital', label: 'Change in Other Working Capital', alarmsEnabled: true },
    { name: 'NetInvestmentPurchaseAndSale', label: 'Net Investment Purchase & Sale', cash: true, alarmsEnabled: true },
    { name: 'OtherNonCashItems', label: 'Other Non Cash Items', cash: true, alarmsEnabled: true },
    { name: 'PurchaseOfPPE', label: 'Investment Acquisitions & Disposals', cash: true, alarmsEnabled: true },
    { name: 'NetIncomeFromContinuingOperations', label: 'Net Income From Continuing Operations', cash: true, alarmsEnabled: true },
    { name: 'StockBasedCompensation', label: 'Share Based Compensation', cash: true, alarmsEnabled: true },
    { name: 'CashFlowFromContinuingOperatingActivities', label: 'Net Cash Flow From Operations', cash: true, alarmsEnabled: true },
    { name: 'EndCashPosition', label: 'End Cash Position', cash: true, alarmsEnabled: true },
    { name: 'ChangeInOtherCurrentAssets', label: 'Change in Other Current Assets', cash: true, alarmsEnabled: true },
    { name: 'CommonStockDividendPaid', label: 'Common Stock Dividend Paid', cash: true, alarmsEnabled: true },
    { name: 'DepreciationAmortizationDepletion', label: 'Depreciation, Amortization & Accretion', cash: true, alarmsEnabled: true },
    { name: 'ChangeInReceivables', label: 'Change in Receivables', cash: true, alarmsEnabled: true },
    { name: 'CommonStockPayments', label: 'Common Stock Payments', cash: true, alarmsEnabled: true },
    { name: 'ChangesInAccountReceivables', label: 'Changes in Account Receivables', cash: true, alarmsEnabled: true },
    { name: 'ChangeInPayable', label: 'Change in Payable', cash: true, alarmsEnabled: true },
    { name: 'InterestPaidSupplementalData', label: 'Interest Paid Supplemental Data', cash: true, alarmsEnabled: true },
    { name: 'IncomeTaxPaidSupplementalData', label: 'Income Tax Paid Supplemental Data', cash: true, alarmsEnabled: true },
    { name: 'PurchaseOfInvestment', label: 'Purchase of Investment', cash: true, alarmsEnabled: true },
    { name: 'CashFlowFromContinuingInvestingActivities', label: 'Net Cash Flow From Investing', cash: true, alarmsEnabled: true },
    { name: 'CapitalExpenditure', label: 'Capital Expenditure', cash: true, alarmsEnabled: true },
    { name: 'NetOtherFinancingCharges', label: 'Net Other Financing Charges', cash: true, alarmsEnabled: true },
    { name: 'ChangeInOtherCurrentLiabilities', label: 'Change in Other Current Liabilities', cash: true, alarmsEnabled: true },
    { name: 'NetBusinessPurchaseAndSale', label: 'Net Business Purchase & Sale', cash: true, alarmsEnabled: true },
    { name: 'RepaymentOfDebt', label: 'Issuance (Repayment) of Debt', cash: true, alarmsEnabled: true },
    { name: 'DeferredIncomeTax', label: 'Deferred Income Tax', cash: true, alarmsEnabled: true },
    { name: 'NetOtherInvestingChanges', label: 'Net Other Investing Changes', cash: true, alarmsEnabled: true },
    { name: 'ChangeInAccountPayable', label: 'Change in Account Payable', cash: true, alarmsEnabled: true },
    { name: 'BeginningCashPosition', label: 'Beginning Cash Position', cash: true, alarmsEnabled: true },
    { name: 'NetShortTermDebtIssuance', label: 'Net Short Term Debt Issuance', cash: true, alarmsEnabled: true },
    { name: 'NetPPEPurchaseAndSale', label: 'Net PPE Purchase & Sale', cash: true, alarmsEnabled: true },
    { name: 'NetLongTermDebtIssuance', label: 'Net Long Term Debt Issuance', cash: true, alarmsEnabled: true },
    { name: 'IssuanceOfDebt', label: 'Issuance of Debt', cash: true, alarmsEnabled: true },
    { name: 'ChangeInInventory', label: 'Change in Inventory', cash: true, alarmsEnabled: true },
    { name: 'SaleOfInvestment', label: 'Sale of Investment', cash: true, alarmsEnabled: true },
    { name: 'ShortTermDebtIssuance', label: 'Short Term Debt Issuance', cash: true, alarmsEnabled: true },
    { name: 'NetIntangiblesPurchaseAndSale', label: 'Net Intangibles Purchase & Sale', cash: true, alarmsEnabled: true },
    { name: 'PurchaseOfIntangibles', label: 'Purchase of Intangibles', cash: true, alarmsEnabled: true },
  ],
};

