import _cloneDeep from 'lodash/cloneDeep';


/** Safely clone a profile. Removes predefined key. */
export const cloneProfile = (profile) => {
  const newProfile = _cloneDeep(profile);
  const { predefined, ...rest } = newProfile;
  return rest;
} 

/**
 * All predefined (uneditable) profiles ID's will be prefixed with this. Use it to change how the profile should be handled.
 * @type {string}
 * @const
 */
export const PREDEF_PREFIX = 'predef__';
/**
 * For historical filter form, we have valid intermediate states where we need a copy of a certain profile in an "unsaved" state.
 * TODO: This is really messy, I hope to remove this soon.
 * @type {string}
 * @const
 */
export const UNSAVED_PREFIX = 'unsaved__';

/**
 * For all pages except TopList, defines the profile types, and the pages they live in.
 * Used to structure Redux state, and Dynamo storage
 * @example
 * state[PROFS.page.HISTORY][PROFS.type.FILTERS].profiles = [...]
 * @readonly
 * @type {{string: {string: string}}}
 */
export const PROFS = {
  page: {
    TOP_LIST: 'toplist',
    SCANNER: 'scanner',
    GLOBAL: 'global',
    NEWS: 'news',
    HISTORY: 'history',
    OVERVIEW: 'overview',
  },
  type: {
    SCANNER: 'scanner',
    KEYSTATS: 'keyStats',
    NEWS: 'news',
    FINANCIALS: 'financials',
    TITLE_BAR: 'titleBar',
    HISTORY: 'history',
    SEC: 'sec',
    FILTERS: 'filters',
    FILTER_PROFILES: 'filterProfiles',
    GAP_STATS: 'gapStats',
    TOP_BAR: 'topBar',
    RECORDS: 'records',
    ALL_METRICS: 'allMetrics',
    CHART: 'chart'
  }
};

// rg - normal
// gr - reversed
export const initialFinancialsAlarms = {
  'MonthsOfCash': { less: 9, greater: 24, reversed: false },
  'WorkingCapital': { less: 5_000_000, greater: 25_000_000, reversed: false },
  'CurrentRatio': { less: 2, greater: 10, reversed: false },
  'DebtEquityRatio': { less: 1, greater: 2, reversed: true },
};


export const initialKeyStatsAlarms = {
'marketCap': { less: 150_000_000, greater: 500_000_000, reversed: true },
'sharesoutstanding': { less: 3_000_000, greater: 10_000_000, reversed: false },
'sharesfloat': { less: 3_000_000, greater: 10_000_000, reversed: false },
'shortpercentfloat': { less: 10, greater: 30, reversed: true },
'percentinstitutions': { less: 10, greater: 30, reversed: true },
'percentinsiders': { less: 20, greater: 50, reversed: true },
'floatRotation': { less: 3, greater: 10, reversed: true },
...initialFinancialsAlarms
};

