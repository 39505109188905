import Fuse from 'fuse.js';


/**
 * Must be recreated every time the dataset changes
 * https://www.fusejs.io/api/options.html
 */
export const makeScannerFuseSearch = (columns) => {
  const fuseOptions = {
    // ignoreLocation: true,
    threshold: 0.4,
    keys: [
      "label",
      {
        name: "synonyms",
        weight: 0.8
      },
      {
        name: "alternateLabel",
        weight: 0.6
      }
    ]
  }
  
  const fuse = new Fuse(columns, fuseOptions);

  return (searchTerm) => {
    return !searchTerm 
      ? columns
      : fuse.search(searchTerm).map(i => i.item);
  }
}



export const makeFuseSearch = (columns, options) => {
  if (!options) {
    throw new Error('makeFuseSearch must be provided an options argument');
  }

  const fuse = new Fuse(columns, options);

  return searchTerm => !searchTerm 
      ? columns
      : fuse.search(searchTerm).map(i => i.item)
}