import { useCallback } from 'react';
import useRouterLinksContextMenu from './sections/useRouterLinksContextMenu';
import joinNestedArrays from 'src/utils/joinNestedArrays';
import { DEFUALT_MENU_ITEMS } from './constants';
import useResizeContextMenu from './sections/useResizeContextMenu';
import usePreventCellChangeFlashContextMenu from './sections/usePreventCellChangeFlashContextMenu';
import useWatchlistContextMenu from './sections/useWatchlistContextMenu';

/**
 * @param {string} forceTicker - If no ticker exists for a given cell, use this one
 * @param {function} handleSetResizeKey - Set the resize key for a given
 * @param {function} [setCellFlashDisabled] - Toggle component state to stop cell flashing
 */
function useDefaultContextMenu({
  handleSetResizeKey,
  setCellFlashDisabled,
  forceTicker = null
} = {}) {
  const makeRouterLinksContextMenu = useRouterLinksContextMenu();
  const makeWatchlistContextMenu = useWatchlistContextMenu({ forceTicker });
  const makePreventCellChangeFlashContextMenu = usePreventCellChangeFlashContextMenu({ setCellFlashDisabled });
  const makeResizeContextMenu = useResizeContextMenu({ handleSetResizeKey });

  return useCallback((contextMenuParams) => {
    return joinNestedArrays([
      makeWatchlistContextMenu(contextMenuParams),
      makeRouterLinksContextMenu(contextMenuParams),
      makePreventCellChangeFlashContextMenu(contextMenuParams),
      makeResizeContextMenu(contextMenuParams),
    ], DEFUALT_MENU_ITEMS.separator);
  }, [makeRouterLinksContextMenu, makePreventCellChangeFlashContextMenu, makeWatchlistContextMenu])
}


export default useDefaultContextMenu;
