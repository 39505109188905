import React from 'react';
import PropTypes from "prop-types";



export function Switch({ expr, children, log }) {


  const found = children.find(child => {
    return child.props.when === expr;
  });

  if (found) {
    return found;
  }

  const defaultCase = children.find(child => {
    return child.props?.default;
  })

  if (defaultCase) {
    return defaultCase;
  }
}

Switch.propTypes = {
  expr: PropTypes.any,
  children: PropTypes.any.isRequired
}

export function Case(props) {
  if (props?.else) {
    throw new Error("Case does not have prop 'else'. Did you mean to use 'default' instead?");
  }
  return props.children;
}

Case.propTypes = {
  when: PropTypes.any,
  default: PropTypes.any,
  children: PropTypes.any.isRequired
}


