import { useCallback } from 'react';
import { useTheme } from '@material-ui/core';


export default function usePreventCellChangeFlashContextMenu({ setCellFlashDisabled }) {
  const theme = useTheme();

  return useCallback((contextMenuParams) => {
    const flashDisabled = contextMenuParams?.context?.cellFlashDisabled;

    if (!setCellFlashDisabled) return [];

    if (flashDisabled) {
      return [{
        name: 'Cell flashing disabled',
        suppressCloseOnSelect: true, // Not available in our grid version
        action: () => setCellFlashDisabled(false)
      }]
    } else {
      return [{
        name: 'Cell flashing enabled',
        checked: true,
        suppressCloseOnSelect: true, // Not available in our grid version
        action: () => setCellFlashDisabled(true)
      }]
    }
  }, [setCellFlashDisabled]);
}
