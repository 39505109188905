import edgeDataApi from 'src/apis/edgeDataApi';

// export const FETCH_HISTORY = '@stattables/fetch-history';
// export const FETCH_EARNINGS = '@stattables/fetch-earnings';
// export const FETCH_SEC_FILINGS = '@stattables/fetch-sec-filings';
// export const FETCH_GAP_STATS = '@stattables/fetch-gap-stats';
export const FETCH_FUNDAMENTALS = '@stattables/fetch-fundamentals';
export const FETCH_INSIDERS = '@stattables/fetch-insiders';

// export const REQUEST__FETCH_HISTORY = '@stattables/request__fetch-history';
// export const REQUEST__FETCH_EARNINGS = '@stattables/request__fetch-earnings';
// export const REQUEST__FETCH_SEC_FILINGS = '@stattables/request__fetch-sec-filings';
// export const REQUEST__FETCH_GAP_STATS = '@stattables/request__fetch-gap-stats';
export const REQUEST__FETCH_FUNDAMENTALS = '@stattables/request__fetch-fundamentals';
export const REQUEST__FETCH_INSIDERS = '@stattables/request__fetch-insiders';


export const fetchInsiders = ticker => async dispatch => {
  let res = null;
  dispatch({ type: REQUEST__FETCH_INSIDERS });
  try {
    const response = await edgeDataApi.get(`/stock-fundamentals/${ticker}`);
    res = response.data ? response.data : null;

    if (res['ticker']) {
      delete res['ticker'];
    }

    if (res['transactions']) {
      res.transactions.forEach(row => {
        if (row.transactionType) {
          row.transactionType = row.transactionType.split('-')[1]
        }
      });
    }

  } catch (err) {
    console.log('ERROR: fetchFundamentals', err);
  }
  dispatch({ type: FETCH_INSIDERS, payload: res });
};

