import React, { useState, useCallback, memo } from 'react';
import PropTypes from 'prop-types';
import { FilterIcon } from 'src/theme/EdgeIcons';
import ClearIcon from '@material-ui/icons/Clear';
import PanelIconButton from 'src/app/components/panels/PanelIconButton';
import {
  Box,
  makeStyles,
  Dialog,
  DialogContent,
  DialogActions,
  IconButton,
  Typography
} from '@material-ui/core';
import clsx from 'clsx';


const useStyles = makeStyles((theme) => ({
  root: {},
  closeButton: {
    padding: 0,
    borderRadius: 4,
    '& .MuiSvgIcon-root': {
      fontSize: 28
    }
  },
  dialogRoot: {
    '& .MuiDialog-paperScrollPaper': {
      width: props => props.width,
      height: props => props.height,
      maxWidth: 'calc(100% - 64px)',
      maxHeight: 'calc(100% - 64px)',
      overflow: 'hidden'
    }
  },
  dialogActions: {
    backgroundColor: theme.palette.background.panelHeader,
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  dialogContentRoot: {
    padding: '0 !important',
    backgroundColor: theme.palette.background.panelHeader,
    height: '100%',
    display: 'flex',
    overflow: 'hidden',
    flexDirection: 'column'
  },
  modalTitle: {
    fontSize: 20
  }
}));


function FilterModal({
  className,
  Icon = FilterIcon,
  shouldHideIconText,
  button,
  buttonDisabled,
  iconText,
  modalSize,
  iconColor,
  CenterActionComponent,
  modalTitle,
  onClose,
  width,
  height,
  keepMounted,
  shouldRenderProps,
  children
}) {
  const classes = useStyles({ width, height });
  const [open, setOpen] = useState(false);


  const handleClickOpen = useCallback(() => {
    setOpen(true);
  }, []);

  const handleClose = useCallback(() => {
    if (onClose) onClose();
    setOpen(false);
  }, [onClose]);


  return (
    <>
      <Box className={clsx(classes.root, className)}>
        {button
          ? <button.Component color={button.color} onClick={handleClickOpen} className={button.className} disabled={buttonDisabled}>{button.text}</button.Component>
          : <PanelIconButton Icon={Icon} iconColor={iconColor} onClick={handleClickOpen} text={iconText} shouldHideIconText={shouldHideIconText} disabled={buttonDisabled}/>
        }
      </Box>

      <Dialog
        maxWidth={modalSize}
        fullWidth
        keepMounted={keepMounted}
        open={open}
        onClose={handleClose}
        className={classes.dialogRoot}
      >
        <DialogActions className={classes.dialogActions}>
          {modalTitle && <Typography variant="h2" className={classes.modalTitle}>{modalTitle}</Typography>}
          {CenterActionComponent && CenterActionComponent}
          <IconButton onClick={handleClose} color="primary" disableRipple className={classes.closeButton}>
            <ClearIcon color="primary"/>
          </IconButton>
        </DialogActions>
        <DialogContent className={classes.dialogContentRoot}>
          {shouldRenderProps ? children(handleClose) : children}
        </DialogContent>
      </Dialog>
    </>
  );
}


FilterModal.propTypes = {
  className: PropTypes.string,
  Icon: PropTypes.func,
  shouldHideIconText: PropTypes.bool,
  buttonDisabled: PropTypes.bool,
  button: PropTypes.object,
  iconText: PropTypes.string,
  modalSize: PropTypes.string,
  iconColor: PropTypes.string,
  CenterActionComponent: PropTypes.any,
  modalTitle: PropTypes.string,
  onClose: PropTypes.func,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  shouldRenderProps: PropTypes.bool,
  keepMounted: PropTypes.bool,
  children: PropTypes.any
};


FilterModal.defaultProps = {
  Icon: FilterIcon,
  shouldHideIconText: false,
  buttonDisabled: false,
  iconText: 'Filter',
  modalSize: 'md',
  shouldRenderProps: false,
  keepMounted: false,
  width: 900,
  height: 590,
};


export default memo(FilterModal);
