import React, { useMemo } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { SearchIcon } from 'src/theme/EdgeIcons';
import {
  InputBase,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {},
  filterInput: {
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: 6,
    display: 'flex',
    '& .MuiInputAdornment-root': {
      height: 'unset'
    },
    '& .MuiSvgIcon-root': {
      marginLeft: 9,
      marginRight: 9,
      marginBottom: 1
    },
    '& .MuiInputBase-input': {
      paddingTop: 5,
      paddingBottom: 5,
      color: theme.palette.text.secondary
    }
  }
}));


function MetricSearchInput({
  className,
  query,
  onChange,
  placeholder,
  ariaLabel,
  autoFocus,
  disabled,
  ...rest
}) {
  const classes = useStyles();

  const inputProps = useMemo(() => {
    return {
      'aria-label': ariaLabel,
      ...(autoFocus && { className: 'tiptap-expression-input-skip-blur' })
    }
  }, [autoFocus])

  return (
    <div className={clsx(classes.root, className)}>
      <InputBase
        className={classes.filterInput}
        placeholder={placeholder}
        value={query}
        onChange={onChange}
        inputProps={inputProps}
        autoFocus={autoFocus}
        disabled={disabled}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon />
          </InputAdornment>
        }
        {...rest}
      />
    </div>
  );
}


MetricSearchInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  placeholder: PropTypes.string,
  ariaLabel: PropTypes.string,
  autoFocus: PropTypes.bool,
  disabled: PropTypes.bool,
}

MetricSearchInput.defaultProps = {
  placeholder: 'Search',
  ariaLabel: 'Search'
}

export default MetricSearchInput;
