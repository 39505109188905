import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CopyIcon } from 'src/theme/EdgeIcons';
import {
  Typography,
  Button,
  Box,
  Dialog,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent !important',
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent !important'
    },
    '& .MuiPaper-root': {
      borderRadius: 6,
      maxWidth: 450
    },
    '& .MuiDialog-container.MuiDialog-scrollPaper': {
      backgroundColor: 'rgba(0, 0, 0, 0.5) !important'
    }
  },
  popupButton: {}
}));


function CopyDefaultProfileModal({
  className,
  description,
  profileName,
  open,
  onClose,
  onCopy,
}) {
  const classes = useStyles();


  return (
    <Dialog
      disableAutoFocus
      disableEnforceFocus
      disableScrollLock
      disablePortal
      onClose={onClose}
      open={open}
      fullWidth
      transitionDuration={0}
      style={{ position: 'absolute' }}
      className={clsx(className, classes.root)}
    >
      <Box className={classes.modalBody} textAlign="center" p={1}>
        <Typography variant="body1" gutterBottom>
          {description}
        </Typography>
        <Box display="flex" alignItems="space-between" justifyContent="center" mt={3}>
          <Box mr={1}>
            <Button
              className={classes.popupButton}
              onClick={onCopy}
              color="primary"
              variant="contained"
              size="small"
            >
              <CopyIcon/> Copy {profileName}
            </Button>
          </Box>
          <Box ml={1}>
            <Button
              className={classes.popupButton}
              onClick={onClose}
              color="primary"
              variant="outlined"
              size="small"
            >
              Close
            </Button>
          </Box>
        </Box>
      </Box>
    </Dialog>
  );
}


CopyDefaultProfileModal.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  profileName: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onCopy: PropTypes.func,
};


CopyDefaultProfileModal.defaultProps = {
  description: 'You cannot edit a predefined profile. Make an editable copy?',
  open: false,
  onClose: () => { },
  onCopy: () => { }
};


export default CopyDefaultProfileModal;
