export const SOURCES = {
  abnw: { label:  'ABN Newswire' },
  basic: { label:  'Basic News Sources' },
  bayaw: { label:  'ACCESSWIRE' },
  bayusmc: { label:  'Baystreet' },
  bayawia: { label:  'ACCESSWIRE Investor Awareness News' },
  prn: { label:  'PR Newswire' },
  bwi: { label:  'Business Wire' },
  acn: { label:  'ACN Newswire' },
  TheNewsWire: { label:  'The News Wire' },
  upi: { label:  'United Press International' },
  nfil: { label:  'Newsfile' },
  cnw: { label:  'Canada Newswire' },
  pzo: { label:  'GlobeNewswire' },
  nwd: { label:  'News Direct' },
  equi: { label:  'Equities.com' },
  stnn: { label:  'Stock News Now' },
  fool: { label:  'Motley Fool' },
  glpr: { label:  'GlobeNewswire Marketing' },
  nwmw: { label:  'NewMediaWire' },
  frc: { label:  'Fundamental Research Corp' },
  nnw: { label:  'NetworkNewsWire' },
  seek: { label: 'Seeking Alpha' },
  psn: { label:  'Pot Stock News' },
  btvrss: { label:  'BTV Bulletin' },
  fnp: { label:  'MarketNewsUpdates' },
  foolcan: { label:  'Fool Canada' },
  msc: { label:  'MoneyShow.com' },
  std: { label:  'Stock Traders Daily' },
  canw: { label:  'CannabisNewsWire' },
  ssn: { label:  'StreetSignals' },
  cryp: { label:  'CryptoCurrencyNews' },
  mic: { label:  'MicroSmallCap' },
  ibn: { label:  'InvestorBrandNetwork' },
  fnm: { label:  'Financial News Media' },
  smm: { label:  'Stock Market Manager' },
  fbz: { label:  'FinancialBuzz.com' },
  mnh: { label:  'Markets & Headlines' },
  inn: { label:  'Investing News Network' },
  stmk: { label:  'Stockmarket.com' },
  ssnw: { label:  'StockStreetNews' },
  pen: { label:  'PennyStocks.com' },
  wsprrss: { label:  'WallStreet PR' },
  chk: { label:  'ChannelChek' },
  mjs: { label:  'MarijuanaStocks.com' },
  iam: { label:  'IAM Newswire' },
  grn: { label:  'Green Market Report' },
  inid: { label:  'InvestorIdeas.com' },
  stnc: { label:  'StockNews.com' },
  gld: { label:  'Goldstocks.com' },
  irw: { label:  'IRW Press' },
  // qmr: { label: 'QuoteMedia Research SEC Filings' },
  inp: { label:  'Investorplace' },
  actw: { label:  'Actusnews Wire' },
  mlnrss: { label:  'Midas Letter News' },
  buzz: { label:  'Market Buzz' },
  invz: { label:  'Invezz' },
}
