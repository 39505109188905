import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useStateFromProp from 'src/hooks/useStateFromProp';
import { EditIcon2, CheckIcon, DenyIcon2, CopyIcon } from 'src/theme/EdgeIcons';
import {
  CircularProgress,
  Checkbox,
  IconButton,
  Typography,
  InputBase,
  ClickAwayListener, Tooltip,
} from '@material-ui/core';
import LockIcon from '@material-ui/icons/Lock';


export const STEPS = {
  DISPLAYING: 'displaying',
  EDITING: 'editing',
  DELETING: 'deleting'
};


function ManageLayoutsListItem({
  className,
  useStyles,
  textWidth,
  id,
  title,
  selected,
  disabled,
  checkboxDisabled,
  showLoading,
  checkbox,
  predefined,
  formStep,
  onFormStep,
  onClick,
  onEdit,
  onDelete,
  onCopy,
}) {
  const [instanceTitle, setInstanceTitle] = useStateFromProp(title);
  const classes = useStyles({
    editing: formStep === STEPS.EDITING,
    textWidth
  });
  const cbId = `cb-layout-label-${id}`;


  const handleSelect = () => {
    if (formStep === STEPS.DISPLAYING) {
      onClick(id);
    }
  };


  const handleEdit = () => {
    onFormStep(STEPS.EDITING);
  };


  const handleEditConfirm = () => {
    onEdit(id, instanceTitle);
    onFormStep(STEPS.DISPLAYING);
  };


  const handleDelete = () => {
    onFormStep(STEPS.DELETING);
  };


  const handleDeleteConfirm = () => {
    onDelete(id);
    onFormStep(STEPS.DISPLAYING);
  };


  const handleClickAway = () => {
    onFormStep(STEPS.DISPLAYING);
    setInstanceTitle(title);
  };


  const handleKeyDown = (event) => {
    const VALID_KEYS = ['Enter', 'Escape'];

    if (event.key === 'Enter') {
      handleEditConfirm();
    } else if (event.key === 'Escape') {
      handleClickAway();
    }
    if (VALID_KEYS.includes(event.key)) {
      event.preventDefault();
      event.stopPropagation();
    }
  };


  const renderTextOrInput = () => {
    let el;
    if (formStep !== STEPS.EDITING) {
      el = <Typography id={cbId} className={classes.title}>{instanceTitle}</Typography>;
    } else {
      el = (
        <InputBase
          autoFocus
          value={instanceTitle}
          onChange={(event) => setInstanceTitle(event.target.value)}
          className={classes.inputBase}
          onKeyDown={handleKeyDown}
        />
      );
    }
    return el;
  };


  const renderSecondaryActions = () => {
    let el;
    if (formStep !== STEPS.EDITING) {
      if (formStep !== STEPS.DELETING) {
        el = onDelete ? (
          <div className={clsx(classes.listIcon, classes.denyIcon)}>
            <Tooltip enterDelay={400} enterNextDelay={0} title="Delete" placement="top">
              <IconButton
                aria-label="Delete"
                disableRipple
                onClick={handleDelete}
              >
                <DenyIcon2 />
              </IconButton>
            </Tooltip>
          </div>
        ) : null;
      } else {
        el = (
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className={clsx(classes.listIcon, classes.confirmIcon, classes.iconRed)}>
              <Tooltip enterDelay={400} enterNextDelay={0} title="Confirm Delete?" placement="top">
                <IconButton
                  aria-label="Confirm Remove?"
                  disableRipple
                  onClick={handleDeleteConfirm}
                >
                  <CheckIcon />
                </IconButton>
              </Tooltip>
            </div>
          </ClickAwayListener>
        );
      }
    } else {
      el = (
        <div className={clsx(classes.listIcon, classes.confirmIcon, classes.iconGreen)}>
          <Tooltip enterDelay={400} enterNextDelay={0} title="Confirm Edit?" placement="top">
            <IconButton
              aria-label="Confirm Edit?"
              disableRipple
              onClick={handleEditConfirm}
            >
              <CheckIcon />
            </IconButton>
          </Tooltip>
        </div>
      );
    }

    return el;
  };


  return (
    <ClickAwayListener
      onClickAway={(e) => formStep !== STEPS.DISPLAYING && handleClickAway(e)}
    >
      <div
        className={clsx(
          className,
          classes.root,
          selected && !checkbox && classes.selected,
          disabled && classes.disabled,
        )}
      >
        <div
          className={classes.listLeft}
          role="button"
          onClick={handleSelect}
          title={title}
        >
          {(formStep !== STEPS.EDITING && checkbox && !predefined) && (
            <div className={clsx(classes.listIcon, classes.checkboxIcon)}>
              <Checkbox
                className={clsx(classes.checkbox, checkboxDisabled && classes.disabledCheckbox)}
                checked={selected}
                tabIndex={-1}
                disableRipple
                inputProps={{ 'aria-labelledby': cbId }}
              />
            </div>
          )}
          {(formStep !== STEPS.EDITING && predefined) && (
            <div className={clsx(classes.listIcon, classes.checkboxIcon)}>
              <LockIcon style={{ fontSize: 15, marginRight: 5 }} />
            </div>
          )}
          {renderTextOrInput()}
        </div>
        <div className={classes.listRight}>
          {(showLoading)
            ? (
              <>
                <div className={clsx(classes.listIcon, classes.loadingIcon)}>
                  <CircularProgress size={20} />
                </div>
              </>
            ) : (
              <>
                {(formStep === STEPS.DISPLAYING && onCopy) && (
                  <div className={clsx(classes.listIcon, classes.copyIcon)}>
                    <Tooltip enterDelay={400} enterNextDelay={0} title="Copy" placement="top">
                      <IconButton
                        aria-label="Copy"
                        disableRipple
                        onClick={() => onCopy(id, title)}
                      >
                        <CopyIcon />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                {(formStep !== STEPS.EDITING && formStep !== STEPS.DELETING && !predefined && onEdit) && (
                  <div className={clsx(classes.listIcon, classes.editIcon)}>
                    <Tooltip enterDelay={400} enterNextDelay={0} title="Edit Name" placement="top">
                      <IconButton
                        aria-label="Edit"
                        disableRipple
                        onClick={handleEdit}
                      >
                        <EditIcon2 />
                      </IconButton>
                    </Tooltip>
                  </div>
                )}
                {!predefined && renderSecondaryActions()}
              </>
            )
          }
        </div>
      </div>
    </ClickAwayListener>
  );
}


ManageLayoutsListItem.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  title: PropTypes.string,
  selected: PropTypes.bool,
  disabled: PropTypes.bool,
  textWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  predefined: PropTypes.bool,
  showLoading: PropTypes.bool,
  checkbox: PropTypes.bool,
  formStep: PropTypes.string,
  generateFilename: PropTypes.func,
  onFormStep: PropTypes.func,
  onClick: PropTypes.func,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  onCopy: PropTypes.func,
};


ManageLayoutsListItem.defaultProps = {
  selected: false,
  disabled: false,
  predefined: false,
  showLoading: false,
  textWidth: 126,
  checkbox: false,
  isNewItemButon: false,
  formStep: STEPS.DISPLAYING
};


export default ManageLayoutsListItem;
