import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _groupBy from 'lodash/groupBy';
import SingleMetricCheckboxAccordion from '../filterElements/SingleMetricCheckboxAccordion';
import FilterWindowSearch from 'src/app/components/filterElements/FilterWindowSearch';
import FilterToggleButtons from '../filterElements/FilterToggleButtons';
import {
  Box, Checkbox,
  List, ListItem, ListItemIcon, ListItemText,
  makeStyles, Typography,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column'
  },
  list: {},
  extraCheckboxes: {
    paddingTop: 2,
    paddingBottom: 2,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
    '& .top-news-filter-list-item': {
      flex: 1,
      display: 'flex',
      justifyContent: 'center',
    }
  },
  leftSideActions: {
    borderBottom: `1px solid ${theme.palette.background.panelBorder}`,
    '& .nlntf-form-control': {
      padding: '12px 16px 12px 15px'
    },
    '& .form-control-additional': {
      paddingRight: 16,
      paddingLeft: 15
    }
  },
  toggleFormControl: {
    paddingLeft: 15,
    paddingRight: 16,
    paddingTop: 11,
  },
  scrollParent: {
    height: '100%',
    overflowY: 'auto'
  },
  group: {
    marginBottom: 16
  },
  groupTitle: {
    margin: '0px 14px 6px',
    fontSize: 16,
    color: theme.palette.text.secondary,
    // borderBottom: `2px solid ${theme.palette.background.panelBorder}`,
    borderBottom: `1px solid ${theme.palette.text.secondary}`,
    paddingBottom: 2
  }
}));


const defaultElementKeyCb = (i) => i.name;


function AllMetrics({
  className,
  allItems,
  selectedItems,
  alarms,
  maximumMetricsReached,
  onAddItem,
  searchQuery,
  onSearchChange,
  onAlarmChange,
  onSwapAlarms,
  searchInputPlaceholder,
  unique,
  elementKeyCb,
  editLocked,
  displayAllMetricsGroup,
  extraCheckboxes,
  extraCheckboxValues,
  onExtraCheckboxToggle,
}) {
  const classes = useStyles();


  const renderWithGroups = () => {
    const groups = _groupBy(allItems, 'allMetricsGroup');
    return Object.keys(groups).map(key => {
      return (
        <div
          className={classes.group}
          key={key}
        >
          {(key && key !== 'undefined') && <Typography variant="h5" className={classes.groupTitle}>{key}</Typography>}
          {render(groups[key])}
        </div>
      );
    });
  };


  const render = (items) => {
    return items.map(row => {
      const selected = !!(selectedItems.includes(row.name));
      const metricAlarm = alarms && alarms[row.name] || {};
      return (
        <SingleMetricCheckboxAccordion
          disabled={editLocked || row.required || (maximumMetricsReached && !selected)}
          selected={row.required || selected}
          row={row}
          onAddItem={onAddItem}
          alarms={metricAlarm}
          key={elementKeyCb(row)}
          onAlarmChange={onAlarmChange}
          onSwapAlarms={onSwapAlarms}
          unique={unique}
        />
      );
    });
  };


  return (
    <div className={clsx(className, classes.root)}>
      <Box className={classes.leftSideActions}>
        {extraCheckboxes && (
          <div className={classes.extraCheckboxes}>
            {extraCheckboxes.map(cb => {
              const cbValue = extraCheckboxValues?.[cb.name] || false;
              return (
                <ListItem
                  title={cb.label}
                  className="top-news-filter-list-item"
                  key={name}
                  role="button"
                  disableRipple
                  button
                  disabled={editLocked}
                  onClick={() => onExtraCheckboxToggle(cb.name, !cbValue)}
                >
                  <ListItemIcon>
                    <Checkbox
                      edge="start"
                      checked={cbValue}
                      tabIndex={-1}
                      disableRipple
                    />
                  </ListItemIcon>
                  <ListItemText id={cb.label + '-checkbox'}>{cb.label}</ListItemText>
                </ListItem>
              );
            })}
          </div>
        )}
        <div className="nlntf-form-control" style={{ paddingTop: extraCheckboxes ? 0 : 16 }}>
          <FilterWindowSearch
            value={searchQuery}
            handleChange={onSearchChange}
            placeholder={searchInputPlaceholder}
          />
        </div>
      </Box>
      <div className={clsx(classes.scrollParent, 'modal-horizontal-scrollbar-sm')}>
        <List className={classes.list}>
          {displayAllMetricsGroup ? renderWithGroups(allItems) : render(allItems)}
        </List>
      </div>
    </div>
  );
}


AllMetrics.propTypes = {
  className: PropTypes.string,
  searchInputPlaceholder: PropTypes.string,
  allItems: PropTypes.array,
  selectedItems: PropTypes.array,
  alarms: PropTypes.object,
  onAddItem: PropTypes.func,
  searchQuery: PropTypes.string,
  onSearchChange: PropTypes.func,
  onAlarmChange: PropTypes.func,
  onSwapAlarms: PropTypes.func,
  elementKeyCb: PropTypes.func,
  maximumMetricsReached: PropTypes.bool,
  unique: PropTypes.bool,
  editLocked: PropTypes.bool,
  displayAllMetricsGroup: PropTypes.bool,
  extraCheckboxes: PropTypes.array,
  extraCheckboxValues: PropTypes.object,
  onExtraCheckboxToggle: PropTypes.func,
};


AllMetrics.defaultProps = {
  unique: true,
  elementKeyCb: defaultElementKeyCb,
  editLocked: false,
  displayAllMetricsGroup: false,
  extraCheckboxes: undefined
};


export default AllMetrics;
