import React from 'react';
import clsx from 'clsx';
import FormHeader from 'src/app/slicedForm/shared/elements/FormHeader';
import FormModal from 'src/app/components/FormModal';
import { FormActions, FormCloseButton } from "src/app/slicedForm/shared/elements/FormActions";
import NotificationSnackbar from '../../shared/elements/NotificationSnackbar';
import ExpressionPanel from './ExpressionPanel';
import {
  makeStyles,
} from '@material-ui/core'

const useStyles = makeStyles(theme => ({
  root: {},
  panelHeaderColor: {
    backgroundColor: theme.palette.background.panelHeader
  },
  relative: {
    position: 'relative'
  },
  horizontalScroll: {
    overflowY: 'auto'
  },
  snackbarContainerPosition: {
    position: 'absolute',
    left: 50,
    bottom: 50,
  }
}));


const selectIsAnyInvalid = state => {
  const errs = Object.values(state?.errors || []).some(Boolean);
  if (errs) return true;

  // When a new expression is added, it is invalid by default. But,
  // we don't want to set an error message, as that would be confusing
  // to the user.
  const empty = !state?.currentExpression?.expression;
  return empty;
}


/**
  * Simple expression popup, used with just a single expression
  **/
function Form() {
  const classes = useStyles();

  return (
    <>
      <FormModal.Actions
        className={classes.panelHeaderColor}
        borderBottom
      >
        <FormHeader
          className={classes.title}
          title="Custom Expressions"
          titleWidth={260}
        />
        <FormCloseButton />
      </FormModal.Actions>

      <FormModal.Body>
        <FormModal.Column
          expand={true}
          className={clsx(classes.horizontalScroll, 'modal-horizontal-scrollbar-sm')}
          borderLeft={false}
        >
          <FormModal.Section
            className={clsx(
              classes.relative,
            )}
            expand
          >
            <ExpressionPanel />
          </FormModal.Section>
        </FormModal.Column>

        <NotificationSnackbar />

      </FormModal.Body>
      <FormModal.Actions
        className={classes.panelHeaderColor}
        borderTop
      >
        <FormActions selectIsAnyInvalid={selectIsAnyInvalid} />
      </FormModal.Actions>
    </>
  );
}

export default Form;
