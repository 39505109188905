import { makeStyles } from '@material-ui/core';


export default makeStyles((theme) => ({
  root: {},
  side: {
    '& .checkbox-list-item.checkbox-list-disabled': {
      opacity: '.5 !important',
      pointerEvent: 'none !important',
    },
    '& .checkbox-list-item.checkbox-border-bottom': {
      borderBottom: `1px solid ${theme.palette.background.panelBorder}`
    },
    '& .checkbox-list-item:not(.no-checkbox):hover': {
      backgroundColor: theme.palette.action.hover
    },
    '& .checkbox-list-selectable:hover .checkbox-selectable-chevron': {
      opacity: .6
    },
    '& .checkbox-list-selected': {
      // borderColor: theme.palette.primary.main
    },
    '& .checkbox-list-root': {
      '&.indent-level-1:not(.checkbox-list-root-TOPICS) .checkbox-list-item': {
        paddingLeft: 20
      },
      '&.indent-level-1 .checkbox-list-item.no-checkbox': {
        paddingLeft: '48px !important'
      },
      '&.indent-level-2 .checkbox-list-item': {
        paddingLeft: 30
      }
    }
  },
  'side-0': {
    maxWidth: 200,
    '& .checkbox-list-root .checkbox-list-selected': {
      backgroundColor: theme.palette.background.paperAlt
    }
  },
  'side-1': {
    flex: 1,
    backgroundColor: theme.palette.background.paperAlt
  },
  'side-2': {

  },
}));
