import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { SearchIcon } from 'src/theme/EdgeIcons';
import {
  InputBase,
  InputAdornment,
  makeStyles,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {},
  filterInput: {
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: 6,
    display: 'flex',
    '& .MuiInputAdornment-root': {
      height: 'unset'
    },
    '& .MuiSvgIcon-root': {
      marginLeft: 9,
      marginRight: 9,
      marginBottom: 1
    },
    '& .MuiInputBase-input': {
      paddingTop: 5,
      paddingBottom: 5,
      color: theme.palette.text.secondary
    }
  }
}));


function FilterWindowSearch({ 
  className, 
  value, 
  handleChange, 
  handleKeyPress, 
  handleBlur, 
  placeholder
}) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <InputBase
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        className={classes.filterInput}
        inputProps={{ 'aria-label': 'Search' }}
        onKeyDown={handleKeyPress}
        onBlur={handleBlur}
        startAdornment={
          <InputAdornment position="start">
            <SearchIcon/>
          </InputAdornment>
        }
      />
    </div>
  );
}


FilterWindowSearch.propTypes = {
  className: PropTypes.string,
  value: PropTypes.string,
  handleChange: PropTypes.func,
  handleKeyPress: PropTypes.func,
  handleBlur: PropTypes.func,
  placeholder: PropTypes.string, 
}

FilterWindowSearch.defaultProps = {
  placeholder: 'Search'
}

export default FilterWindowSearch;
