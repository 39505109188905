/* eslint-disable no-param-reassign */
import produce from 'immer';
import _extend from 'lodash/extend';
import _filter from 'lodash/filter';
import _uniqBy from 'lodash/uniqBy';
import {
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGIN_FAILURE,
  LOGOUT,
  SILENT_LOGIN,
  UPDATE_PROFILE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
  REGISTER_FAILURE,
  RESET_PASSWORD_SEND_CODE,
  RESET_PASSWORD_FORM_SUBMIT,
  CANCEL_SUBSCRIPTION,
  RENEW_SUBSCRIPTION,
  UPDATE_CART,
  FETCH_SUBSCRIPTION_PLANS,
  REQUEST__FETCH_SUBSCRIPTION_PLANS,
  FETCH_UNPAID_INTENT,
  REQUEST__FETCH_UNPAID_INTENT,
  REQUEST__FETCH_UPCOMING_INVOICE,
  FETCH_UPCOMING_INVOICE,
  REQUEST__FETCH_NEW_PLAN_INVOICE,
  RESOLVE__FETCH_NEW_PLAN_INVOICE,
  FETCH_NEW_PLAN_INVOICE,

  FETCH_NON_PROFESSIONAL_FORM_RESPONSE,
  REQUEST__FETCH_NON_PROFESSIONAL_FORM_RESPONSE,

  START__REFRESH_USER_ON_TIMEOUT,
  END__REFRESH_USER_ON_TIMEOUT, UPDATE_SUBSCRIPTION
} from 'src/redux/account/accountActions';
import { SET_TICKER_HISTORY, ADD_TICKER_HISTORY, REMOVE_TICKER_HISTORY } from 'src/redux/ticker/tickerActions';

export const initialState = {
  user: {
    givenName: '',
    familyName: '',
    userSub: '',
    ihmac: null,
    requiresPayment: true,
    betaAccess: false,
    tickerHistory: [],
    selectedTicker: 'AAPL'
  },
  nonProfessionalPreviousResponse: {},
  cart: {
    period: 'month',
    promotionCode: '',
    partnerCode: ''
  },
  plans: [],
  upcomingInvoice: null,
  newPlanInvoice: null,
  unpaidPayment: null,
  loginMessage: null,
  passwordResetSent: null,
  isFetching: {
    plans: false,
    refreshUser: false,
    upcomingInvoice: false,
    newPlanInvoice: false,
    unpaidPayment: false,
    nonProfessionalForm: false,
  }
};

const accountReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_REQUEST: {
      return produce(state, (draft) => {
        // Ensure we clear current session
        draft.user = null;
        draft.loginMessage = null;
      });
    }

    case LOGIN_SUCCESS: {
      return produce(state, (draft) => {
        draft.user = action.payload;
      });
    }

    case LOGIN_FAILURE: {
      return produce(state, () => {
        // Maybe store error
      });
    }

    case LOGOUT: {
      return produce(state, (draft) => {
        draft.user = initialState.user;
      });
    }

    case SILENT_LOGIN: {
      const { user } = action.payload;
      return produce(state, (draft) => {
        draft.user = user || initialState.user;
      });
    }

    case UPDATE_PROFILE: {
      return produce(state, (draft) => {
        draft.user = _extend({}, draft.user, action.payload.data);
      });
    }

    case UPDATE_SUBSCRIPTION: {
      return produce(state, (draft) => {
        const { data, id } = action.payload;
        const index = draft.user.subscriptions.findIndex(s => s.subscriptionId === id);
        if (index >= 0) {
          draft.user.subscriptions[index] = {
            ...draft.user.subscriptions[index],
            ...data
          };
        } else {
          console.log('ALERT: had to create subscription in memory. It should already have been fetched from the db.');
          draft.user.subscriptions.push({
            subscriptionId: id,
            ...action.payload
          });
        }
      });
    }

    case FETCH_UPCOMING_INVOICE: {
      return produce(state, draft => {
        draft.upcomingInvoice = action.payload;
        draft.isFetching.upcomingInvoice = false;
      });
    }

    case FETCH_NEW_PLAN_INVOICE: {
      return produce(state, draft => {
        draft.newPlanInvoice = action.payload;
        draft.isFetching.newPlanInvoice = false;
      });
    }

    case FETCH_UNPAID_INTENT: {
      return produce(state, draft => {
        draft.unpaidPayment = action.payload;
        draft.isFetching.unpaidPayment = false;
      });
    }

    case CANCEL_SUBSCRIPTION: {
      return produce(state, (draft) => {
        draft.user = action.payload;
      });
    }

    case RENEW_SUBSCRIPTION: {
      return produce(state, (draft) => {
        draft.user = action.payload;
      });
    }

    case UPDATE_CART: {
      return produce(state, (draft) => {
        if (action.payload === null) {
          draft.cart = {
            period: draft.cart.period,
            promotionCode: '',
            partnerCode: ''
          };
        } else {
          draft.cart = {
            ...draft.cart,
            ...action.payload
          };
        }
      });
    }

    case FETCH_SUBSCRIPTION_PLANS: {
      return produce(state, (draft) => {
        draft.isFetching.plans = false;
        draft.plans = action.payload;
      });
    }

    case REGISTER_REQUEST: {
      return produce(state, (draft) => {
        draft.loginMessage = null;
      });
    }

    case REGISTER_SUCCESS: {
      return produce(state, (draft) => {
        draft.loginMessage = action.payload;
      });
    }

    case REGISTER_FAILURE: {
      return produce(state, (draft) => {
        draft.loginMessage = action.payload;
      });
    }

    case RESET_PASSWORD_SEND_CODE: {
      return produce(state, (draft) => {
        draft.passwordResetSent = action.payload;
      });
    }

    case RESET_PASSWORD_FORM_SUBMIT: {
      return produce(state, (draft) => {
        if (action.payload.message) {
          draft.loginMessage = action.payload;
        }
      });
    }

    case FETCH_NON_PROFESSIONAL_FORM_RESPONSE: {
      return produce(state, (draft) => {
        draft.isFetching.nonProfessionalForm = false;
        if (action.payload && Object.keys(action.payload)) {
          draft.nonProfessionalPreviousResponse = action.payload;
        }
      })
    }

    case SET_TICKER_HISTORY: {
      return produce(state, (draft) => {
        if (action.payload?.history) {
          draft.user.tickerHistory = action.payload.history;
        }
        if (action.payload?.selectedTicker) {
          draft.user.selectedTicker = action.payload.selectedTicker;
        }
      });
    }

    case ADD_TICKER_HISTORY: {
      return produce(state, (draft) => {
        if (action.payload) {
          const list = draft.user.tickerHistory;
          if (list.length > 12) {
            list.pop();
          }
          list.unshift(action.payload);
          draft.user.tickerHistory = _uniqBy(list, 'symbol');
        }
      });
    }

    case REMOVE_TICKER_HISTORY: {
      return produce(state, (draft) => {
        var list = state.user.tickerHistory;
        draft.user.tickerHistory = _filter(list, (o) => {
          return o.symbol !== action.payload.symbol;
        });
      });
    }

    case REQUEST__FETCH_SUBSCRIPTION_PLANS: {
      return produce(state, (draft) => {
        draft.isFetching.plans = true;
      });
    }


    case REQUEST__FETCH_UNPAID_INTENT: {
      return produce(state, (draft) => {
        draft.isFetching.unpaidPayment = true;
      });
    }

    case REQUEST__FETCH_UPCOMING_INVOICE: {
      return produce(state, (draft) => {
        draft.isFetching.upcomingInvoice = true;
      });
    }

    case REQUEST__FETCH_NEW_PLAN_INVOICE: {
      return produce(state, (draft) => {
        draft.isFetching.newPlanInvoice = true;
      });
    }

    case RESOLVE__FETCH_NEW_PLAN_INVOICE: {
      return produce(state, (draft) => {
        draft.isFetching.newPlanInvoice = false;
      })
    }

    case REQUEST__FETCH_NON_PROFESSIONAL_FORM_RESPONSE: {
      return produce(state, (draft) => {
        draft.isFetching.nonProfessionalForm = true;
      })
    }

    case START__REFRESH_USER_ON_TIMEOUT: {
      return produce(state, (draft) => {
        draft.isFetching.refreshUser = true;
      });
    }

    case END__REFRESH_USER_ON_TIMEOUT: {
      return produce(state, (draft) => {
        draft.isFetching.refreshUser = false;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
