import { useReducer } from 'react';
import { unauthorizedEdgeProxyApi } from 'src/apis/edgeProxyApi';
import * as Sentry from '@sentry/react';
import useIsMountedRef from 'src/hooks/useIsMountedRef';


const logErrorToSentry = (error, metric) => {
  Sentry.captureException(error, {
    extra: { metric },
    transactionName: `Field definition "${metric}" missing or errored`
  });
};

/**
 * @typedef {Object} FieldDefinition
 * @property {string} field_definition
 * @property {string} example
 */

/** @type {Map<string, FieldDefinition>} */
const cache = new Map();



/**
 * @param {string} metric 
 * @returns {Promise<FieldDefinition>}
 * @throws Error if no definition is found
 */
const fetchData = async (metric) => {
  let { data } = await unauthorizedEdgeProxyApi(`/field-definitions/${metric}`);
  if (!data || !Object.keys(data).length) {
    throw new Error(`No description found for ${metric}.`)
  }
  return data;
};


const initialState = {
  data: null,
  isFetching: false,
  error: null
};

function reducer(state, action) {
  switch (action.type) {
    case 'FETCH_START':
      return { ...state, isFetching: true };
    case 'FETCH_SUCCESS':
    case 'CACHE_HIT':
      return { data: action.payload, isFetching: false, error: null };
    case 'FETCH_FAILURE':
      return { data: null, isFetching: false, error: action.payload };
    default:
      return state;
  }
}


const initialize = (metric) => {
  const cachedData = cache.get(metric);
  if (cachedData) {
    return { ...initialState, data: cachedData };
  } else {
    return { ...initialState };
  }
}


/**
 * Fetch long field definitions from the server using 'fetchDefinition'.
 *
 * Caches previous responses globally, outside of hook. This means 
 * there is no namespacing on the column names, they must represent the same
 * object everywhere this is used.
 *
 * Ensures cached values will be returned on the first render cycle.
 *
 * @TODO implement namespacing
 * @param {string} metric
 * @returns {{
 *   state: { data: FieldDefinition, isFetching: boolean, error: string|bool },
 *   fetchDefinition: () => void
 * }}
 *
 * @example
 * // MyComponent() ...
 * const { state, fetchDefinition } = useFetchFieldDefinition(metric_name);
 *
 * // Trigger on an action...
 * const handleClick = () => fetchDefinition();
 *
 * // Or, trigger on mount...
 * useEffect(() => fetchDefinition(), [metric_name])
 *
 * if (state.isFetching) { ... }
 * if (state.error) { ... }
 * return (
 *  <div>state?.data?.field_definition</div>
 * )
 */
function useFetchFieldDefinition(metric) {
  const [state, dispatch] = useReducer(reducer, metric, initialize);
  const isMounted = useIsMountedRef();

  const safeDispatch = (action) => isMounted.current && dispatch(action);

  const fetchCacheFirst = async () => {
    // No metric, error
    if (!metric) {
      safeDispatch({ type: 'FETCH_FAILURE', payload: null });
      return;
    }

    // Already fetching or fetched, return
    if (state?.isFetching || state?.data || state?.error) {
      return;
    }

    // Already exists in global cache, but not reducer. Add to reducer.
    if (cache.has(metric)) {
      safeDispatch({ type: 'CACHE_HIT', payload: cache.get(metric) });
      return;
    }

    // Fetch the data
    safeDispatch({ type: 'FETCH_START' });
    try {
      const data = await fetchData(metric);
      // Cache it
      cache.set(metric, data);
      safeDispatch({ type: 'FETCH_SUCCESS', payload: data });
    } catch (err) {
      // Don't cache errors. Let the user try again later.
      logErrorToSentry(err, metric);
      safeDispatch({ type: 'FETCH_FAILURE', payload: `No definition found.` });
    }
  }

  return {
    state,
    fetchDefinition: fetchCacheFirst
  }
}


export default useFetchFieldDefinition;
