import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import SmallFilterWindow from 'src/app/components/filterContainers/SmallFilterWindow';
import MosaicPanelTickerSearch from 'src/app/TopListsMosaic/layout/MosaicPanelHeader/MosaicPanelTickerSearch';
import { BlockIcon } from 'src/theme/EdgeIcons';
import ActionList from 'src/app/components/ActionList';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Typography,
  useTheme,
  makeStyles,
  Button,
  Box,
  Checkbox
} from '@material-ui/core';
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
  root: {
    ' & > .MuiPopover-paper': {
      // backgroundColor: theme.palette.background.paperAlt_darker1,
    }
  },
  listBox: {
    margin: 0,
    padding: [[0, 0]],
    overflowY: 'auto',
    listStyle: 'none',
    maxHeight: '34vh',
    minWidth: 200
  },
  item: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    '&[data-focus=true]': {
      backgroundColor: 'rgba(255,255,255, .1)',
    }
  },
  infoText: {
    marginBottom: 15,
    fontSize: 13
  },
  tickerSearch: {
    '& > .ticker-search-bg': {
      backgroundColor: theme.palette.background.paperAlt_highlight,
      borderRadius: 4,
    },
    '& .ticker-search-input-wrap > .MuiInputBase-root': {
      width: '100%',
    },
    '& input.MuiInputBase-input': {
      paddingTop: 10,
      paddingBottom: 8,
      width: '100%'
    }
  },
  tickerSearchMenu: {
    border: `1px solid ${theme.palette.background.tab}} !important`
  },
  scroll: {
    height: '100%',
    maxHeight: 400,
    overflowY: 'auto'
  }
}));


/**
 * This componment allows a user to add exlcuded tickers to WatchLists.
 * These tickers are added on the component level, not to filter profiles.
 *
 * The list will only be valid for the current day. It gets wiped at premarket start the next day.
 */
function TickerExcludePopover({
  onSelect,
  onRemove,
  getExcludedTickers,
}) {
  const classes = useStyles();
  const theme = useTheme();

  const tickers = getExcludedTickers();

  return (
    <SmallFilterWindow
      popoverClassName={classes.root}
      iconText="Exclude Tickers"
      Icon={BlockIcon}
      shouldHideIconText
      iconColor={tickers.length ? theme.palette.primary.main : theme.palette.text.primary}
      popoverMinWidth={392}
      popoverTitle="Excluded Tickers"

    >
      <Box>
        <Typography className={classes.infoText}>Choose tickers to be excluded from this component<br />for the rest of the trading day. They will be reset tomorrow.</Typography>
        <MosaicPanelTickerSearch
          className={classes.tickerSearch}
          menuClassName={classes.tickerSearchMenu}
          inputWidth={'100%'}
          menuWidth={null}
          onSubmit={onSelect}
          ticker={null}
          showClearButton={false}
          doNotShowLastTicker
          nullPlaceholder="Search..."
        />
      </Box>
      <div className={clsx(classes.scrollParent, 'modal-horizontal-scrollbar-sm')}>
        <ActionList
          className={classes.listBox}
          role="listbox"
          component="ul"
        >
          {Boolean(tickers.length) && (
            tickers.map(ticker => {
              return (
                <ActionList.Item
                  pr={0}
                  pl={0}
                  className={classes.item}
                  role={'button'}
                  key={ticker}
                  selectionVariant="single"
                  onSelect={() => onRemove(ticker)}
                >
                  <ActionList.Leading>
                    <ClearIcon color="primary" />
                  </ActionList.Leading>
                  {ticker}
                </ActionList.Item>
              );
            })
          )}
        </ActionList>
      </div>
    </SmallFilterWindow>
  );

}


TickerExcludePopover.propTypes = {
  getExcludedTickers: PropTypes.func.isRequired,
  onSelect: PropTypes.func,
  onRemove: PropTypes.func
};

export default TickerExcludePopover;
