import { CognitoUser, CognitoUserPool, CognitoUserAttribute, AuthenticationDetails } from 'amazon-cognito-identity-js';
import { COGNITO as COGNITO_CFG } from 'src/constants';
import axiosAuthService from 'src/services/AxiosAuthService';

class AuthService {

  userPool = new CognitoUserPool({
    UserPoolId: COGNITO_CFG.UserPoolId,
    ClientId: COGNITO_CFG.ClientId
  });


  register = (formData) => new Promise((resolve, reject) => {
    const attributeList = ['givenName', 'familyName', 'betaAccess'].map(field => {
      return new CognitoUserAttribute({
        Name: COGNITO_CFG.attributes[field],
        Value: formData[field]
      });
    });
    this.userPool.signUp(formData.email, formData.password, attributeList, null, (err, result) => {
      if (err) {
        console.log(err);
        reject(err);
      } else if (result.user) {
        resolve(result.user);
      }
      reject('Error: unspecified authService register failure');
    });
  });


  resendVerificationCode = (email) => new Promise((resolve, reject) => {
    const cognitoUser = this.getCognitoUser(email);
    cognitoUser.resendConfirmationCode((err, result) => {
      if (err) {
        console.log(err);
        reject(err);
      } else {
        resolve(result);
      }
    });
  });


  loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {
    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password
    });

    const cognitoUser = this.getCognitoUser(email);

    cognitoUser.authenticateUser(authenticationDetails, {
      onSuccess: (session) => {
        axiosAuthService.setAuthorizationHeaders(session);
        return resolve({ session, user: this.getCurrentUser() });
      },
      onFailure: (err) => {
        return reject(err);
      }
    });
  });


  updateUser = (formData) => new Promise((resolve, reject) => {
    this.loginFromSession().then(({ user }) => {
      const attributeList = ['givenName', 'familyName'].map(field => {
        return new CognitoUserAttribute({
          Name: COGNITO_CFG.attributes[field],
          Value: formData[field]
        });
      });
      user.updateAttributes(attributeList, (err, result) => {
        if (err) {
          return reject(err);
        }
        return resolve(result);
      });
    }).catch(err => {
      reject(err);
    });
  });


  deleteAttributes = (attributes = []) => new Promise((resolve, reject) => {
    if (!attributes || !attributes.length) {
      return resolve(true);
    }
    this.loginFromSession().then(({ user }) => {
      user.deleteAttributes(attributes, (err, result) => {
        if (err) {
          return reject(err);
        }
        return resolve(result);
      });
    }).catch(err => {
      reject(err);
    });
  });


  sendResetPasswordCode = (email) => new Promise((resolve, reject) => {
    const cognitoUser = this.getCognitoUser(email);
    cognitoUser.forgotPassword({
      onSuccess: (result) => {
        return resolve(result);
      },
      onFailure: (err) => {
        return reject(err);
      }
    });
  });


  submitPasswordReset = ({ email, verificationCode, newPassword }) => new Promise((resolve, reject) => {
    const cognitoUser = this.getCognitoUser(email);
    cognitoUser.confirmPassword(verificationCode, newPassword, {
      onFailure(err) {
        return reject(err);
      },
      onSuccess(result) {
        return resolve(result);
      },
    });
  });


  getSession = async () => await new Promise((resolve, reject) => {
    const user = this.userPool.getCurrentUser();
    if (!user) {
      console.log('No Authenticated User');
      return reject('No Authenticated User');
    }
    user.getSession((err, session) => {
      if (err) return reject(err);
      if (session) {
        resolve({ session, user });
      } else {
        reject('no session');
      }
    });
  });


  refreshToken = async ({ user, session }) => await new Promise((resolve, reject) => {
    if (!user || !session) {
      return reject('No user / session');
    }
    const self = this;
    const refreshToken = session.getRefreshToken();
    if (refreshToken) {
      user.refreshSession(refreshToken, (err, session) => {
        if (err) return reject(err);
        if (session && session.isValid()) {
          self.setExpiryLocalStorage(session.accessToken.payload.exp);
          axiosAuthService.setAuthorizationHeaders(session);
          return resolve(session);
        } else {
          // logout?
          return reject('refresh null');
        }
      });
    }
  });

  /* eslint-disable-next-line no-unused-vars */
  loginFromSession = async () => await new Promise((resolve, reject) => {
    const self = this;
    this.getSession().then(({ session, user }) => {
      if (session.isValid()) {
        self.setExpiryLocalStorage(session.accessToken.payload.exp);
        axiosAuthService.setAuthorizationHeaders(session);
        return resolve({ user, session });
      }

      // session invalid. Attempt refresh;
      self.refreshToken({ user, session }).then(refreshedSession => {
        return resolve({ user, session: refreshedSession });
      }).catch(err => {
        console.log('attempted to refresh on login err', err);
      });

    }).catch(err => {
      return resolve(err);
    });
  });


  getCognitoUser(email) {
    return new CognitoUser({
      Username: email,
      Pool: this.userPool
    });
  }


  setExpiryLocalStorage = (expiry) => {
    localStorage.setItem('_edge_exp', JSON.stringify(expiry));
  };


  getExpiryLocalStorage = () => {
    return JSON.parse(localStorage.getItem('_edge_exp')) || 0;
  };


  getCurrentUser = () => {
    return this.userPool.getCurrentUser();
  };


  logout = () => new Promise((resolve, reject) => {
    this.getSession().then(({ session, user }) => {
      if (session.isValid()) {
        axiosAuthService.setAuthorizationHeaders(session);
        user.signOut()
        resolve(user)
      } else {
        const refreshToken = session.getRefreshToken();
        if (refreshToken) {
          user.refreshSession(refreshToken, (err, session) => {
            if (err) return reject('refresh errored', err);
            if (session && session.isValid()) {
              axiosAuthService.setAuthorizationHeaders(session);
              user.signOut();
              resolve(user)
            } else {
              return reject('Failed refresh');
            }
          });
        }
      }
    }).catch(err => {
      return reject(err);
    });

  });


}

const authService = new AuthService();

export default authService;
