import _cloneDeep from 'lodash/cloneDeep';
import {
  CHECKBOX_STATES,
  CHILD_TYPES
} from 'src/app/components/filterContainers/NestedCheckboxFilterModal/constants';


export const itemIsCheckboxAndHasNoChildren = (item, items) => {
  if (!item.checkbox) return false;

  const children = items.filter(x => x.parent === item.name);
  return !children.length;
};


const getMostDistantChildren = (parentItem, items) => {
  return items.filter(x => x.parent === parentItem.name).reduce((list, childItem) => {
    if (itemIsCheckboxAndHasNoChildren(childItem, items)) {
      return [...list, childItem];
    } else {
      return [...list, ...getMostDistantChildren(childItem, items)];
    }
  }, []);
};


/*
CHECKBOX STATE

Recursivly handles checking, unckecking, and partial checking through parents and children
Also allows traversal of items without checkboxes. Will simply ignore that node, and check its parents/children.

(this is basically a Tree implementation, without actual nodes. We could build up a tree within the reducer, the
code would look a little nicer. But its a waste of resources since we can't store a Tree in state.)
*/


export const deriveItemState = (state, items, item) => {
  if (itemIsCheckboxAndHasNoChildren(item, items)) {
    // base item, state is tracked in react
    return state.find(x => x.name === item.name).state;
  } else if (item.checkbox) {

    // parent item, state is derived from most distant children
    const childStates = getMostDistantChildren(item, items).map(x => state.find(s => s.name === x.name));
    if (childStates.length === childStates.filter(s => s.state === CHECKBOX_STATES.CHECKED).length) {
      return CHECKBOX_STATES.CHECKED;
    } else if (childStates.length === childStates.filter(s => s.state === CHECKBOX_STATES.UNCHECKED).length) {
      return CHECKBOX_STATES.UNCHECKED;
    } else {
      return CHECKBOX_STATES.INDETERMINATE;
    }
  }
};


export const updateCheckboxStates = (oldState, items, clickedName) => {
  const newState = oldState.map(i => ({ ...i }));
  const item = items.find(x => x.name === clickedName);
  if (itemIsCheckboxAndHasNoChildren(item, items)) {
    // base item
    const stateIdx = newState.findIndex(s => s.name === item.name);
    if (newState[stateIdx].state === CHECKBOX_STATES.CHECKED) {
      newState[stateIdx].state = CHECKBOX_STATES.UNCHECKED;
    } else {
      newState[stateIdx].state = CHECKBOX_STATES.CHECKED;
    }
  } else if (item.checkbox) {
    // parent item
    const parentState = deriveItemState(newState, items, item);
    const childItems = getMostDistantChildren(item, items);
    childItems
      .map(x => newState.findIndex(v => v.name === x.name))
      .map(idx => {
        if (parentState === CHECKBOX_STATES.CHECKED) {
          newState[idx].state = CHECKBOX_STATES.UNCHECKED;
        } else {
          newState[idx].state = CHECKBOX_STATES.CHECKED;
        }
      });
  }
  return newState;
};


/*
UI STATE

Seperate data structure that handles the nested columns UI layout.
Creates and updates an object tree that shows or hides columns as needed.
*/

// Only needs to be built once, on item schema. Generates tree.
export const buildUiStateSchema = (items, parentName = null, path = {}) => {
  let children;

  if (!parentName) {
    children = items.filter(i => !i.parent);
  } else {
    children = items.filter(i => i.parent === parentName);
  }

  children.forEach(child => {
    if (child.childType === CHILD_TYPES.NESTED) {
      if (!path?.selected) {
        path.selected = child.name;
      }
      path[child.name] = path[child.name] || {};
      buildUiStateSchema(items, child.name, path[child.name]);
    } else {
      buildUiStateSchema(items, child.name, path);
    }
  });
  return path;
};


// Generates array of keys signifying the current path in the UI tree. AKA, which children are
// visible in which nested column. [1:sources, 2:pr, 3:nested_thing]
export const getCurrentUiPath = (level) => {
  let ids = [];
  if (level?.selected) {
    ids = [...ids, level.selected];
    if (level?.[level.selected]) {
      ids.push(...getCurrentUiPath(level[level.selected]));
    }
  }
  return ids;
};


// Given an index in the UI path and a new value, update the underlying tree.
export const updateUiState = (uiState, uiPath, uiPathIdx, newName) => {
  let newState = _cloneDeep(uiState);
  let v = newState;
  for (let i = 0; i < uiPathIdx + 1; i++) {
    v = uiPath[i] === 'root' ? v : v[uiPath[i]];
  }
  v.selected = newName;
  return newState;
};
