import { useCallback } from 'react';
import useRouterLinksContextMenu from './sections/useRouterLinksContextMenu';
import joinNestedArrays from 'src/utils/joinNestedArrays';
import { DEFUALT_MENU_ITEMS } from './constants';
import useResizeContextMenu from 'src/app/components/grid/contextMenu/sections/useResizeContextMenu';
import useTickerExcludeContextMenu from './sections/useTickerExcludeContextMenu';
import useWatchlistContextMenu from './sections/useWatchlistContextMenu';
import usePreventCellChangeFlashContextMenu from './sections/usePreventCellChangeFlashContextMenu';




/**
 * @param {function} addExcludedTicker - Add a ticker to the excluded tickers list
 * @param {function} handleSetResizeKey - Set the resize key for a given
 * @param {function} [setCellFlashDisabled] - Toggle component state to stop cell flashing
 * @param {string} forceTicker - If no ticker exists for a given cell, use this one
 */
function useScannerContextMenu({
  addExcludedTicker,
  handleSetResizeKey,
  setCellFlashDisabled,
  forceTicker = null
} = {}) {
  const makeRouterLinksContextMenu = useRouterLinksContextMenu();
  const makeTickerExcludeContextMenu = useTickerExcludeContextMenu({ addExcludedTicker, forceTicker });
  const makeWatchlistContextMenu = useWatchlistContextMenu({ forceTicker });
  const makePreventCellChangeFlashContextMenu = usePreventCellChangeFlashContextMenu({ setCellFlashDisabled });
  const makeResizeContextMenu = useResizeContextMenu({ handleSetResizeKey });

  return useCallback((contextMenuParams) => {
    return joinNestedArrays([
      makeTickerExcludeContextMenu(contextMenuParams),
      makeWatchlistContextMenu(contextMenuParams),
      makeRouterLinksContextMenu(contextMenuParams),
      makePreventCellChangeFlashContextMenu(contextMenuParams),
      makeResizeContextMenu(contextMenuParams),
    ], DEFUALT_MENU_ITEMS.separator);
  }, [makeRouterLinksContextMenu, makeWatchlistContextMenu, makeTickerExcludeContextMenu, makePreventCellChangeFlashContextMenu, makeResizeContextMenu])
}


export default useScannerContextMenu;
