import edgeProxyApi from 'src/apis/edgeProxyApi';
import { addHistoricalArticleProperties } from 'src/redux/realtime/realtimeActions';

export const FETCH_NEWS = '@newsActions/fetch-news';
export const FETCH_RSCORE_NEWS = '@newsActions/fetch-rscore-news';
export const REQUEST__FETCH_NEWS = '@newsActions/request__fetch-news';
export const CLEAR_NEWS = '@newsActions/clear-news';



export const clearNews = () => {
  return { type: CLEAR_NEWS };
};


// HISTORY: benzinga
export const fetchHistoryNews = (date, ticker) => async dispatch => {
  dispatch({ type: REQUEST__FETCH_NEWS });

  let articles = [];
  try {
    let url = `/history-news/${ticker}?dateTo=${date}`;
    const response = await edgeProxyApi.get(url);
    if (response.data && Array.isArray(response.data)) {
      articles = addHistoricalArticleProperties(response.data);
    }
  } catch (err) {
    console.log('[realtime fetchNewsBySingleTicker]: ', err);
  }
  dispatch({ type: FETCH_NEWS, payload: { data: articles, ticker } });
};
