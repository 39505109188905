import React from 'react';


export const formatStatCellNumber = (n, negativeParenthesis = false, isInteger = false) => {
  if (n === 'undefined') {
    return null;
  }

  if (!n) {
    return n;
  }

  // handle numbers with a percent sign at the end
  let numPct = n.toString();
  let pct = numPct.substring(numPct.length - 1, numPct.length);
  if (pct === '%') {
    n = parseFloat(numPct.substring(0, numPct.length - 1));
  } else {
    pct = '';
  }

  // handle numbers with a dollar sign at the beginning
  let numInitialString = n.toString();
  let start = numInitialString.substring(0, 1);
  if (start === '$' || start === '-' && !numInitialString.substring(1, n.length).includes(' ')) {
    n = parseFloat(numInitialString.substring(1, n.length));
  } else {
    start = '';
  }

  if (isNaN(n)) {
    return n;
  }

  // format and round
  let num = roundBases(n);

  // handle trailing character thats not a digit
  let numS = num.toString();
  let finalChar = numS.substring(numS.length - 1, numS.length);
  let full = 0;
  if (!isCharDigit(finalChar)) {
    let numberRounded = roundToDecimalPlaces(parseFloat(numS.substring(0, numS.length - 1)));
    full = numberRounded.toString() + finalChar;
  } else {
    if (isInteger && num < 1000) {
      full = num.toString();
    } else {
      full = roundToDecimalPlaces(num);
    }
  }

  // format negatives with parenthesis if flag is set to true
  if (negativeParenthesis && start === '-') {
    start = '';
    full = `(${full})`;
  }

  const final = start + full + pct;

  return (final === 'undefined') ? '' : final;
};


const isCharDigit = n => n < 10;


const roundToDecimalPlaces = (num, decimalPlaces = 2) => {
  return Number(Math.round(parseFloat(num + 'e' + decimalPlaces)) + 'e-' + decimalPlaces).toFixed(decimalPlaces);
};


export const numericInputBaseFormatter = (n) => {
  // const nb = roundToDecimalPlaces(roundBases(n)).toString();
  return roundBases(n).toString();
};


export const fullFormatStatCellNumber = (value, { pre, post, negativeParethesis, allowZero = false, nullFormat = '-', hardFormat = false, stripTrailingZeros = false } = {}) => {

  if (allowZero && value === 0) {
    return addPrePost(value, pre, post);
  }

  if (!value || value === '0000-00-00' || value === '0' || value === '') {
    return nullFormat;
  }

  if (hardFormat || React.isValidElement(value)) {
    return value;
  }

  let n = formatStatCellNumber(value, negativeParethesis);
  if (parseFloat(n) === 0) {
    n = '0';
  }
  if (stripTrailingZeros && !negativeParethesis) {
    n = removeTrailingZeros(n);
  }

  return addPrePost(n, pre, post);
};


export const addPrePost = (value, pre, post) => {
  let s = '';
  if (pre) s += pre;
  s += value;
  if (post) s += post;
  return s;
};

const removeTrailingZeros = (n) => {
  let modiff = '';
  if (isNaN(n.slice(-1))) {
    modiff = n.slice(-1);
    n = n.slice(0, -1);
  }
  n = parseFloat(n);
  return n.toString() + modiff;
};


const roundBases = (n) => {
  return Math.abs(Number(n)) >= 1.0e+12
    ? Math.abs(Number(n)) / 1.0e+12 + 'T'
    : Math.abs(Number(n)) >= 1.0e+9
      ? Math.abs(Number(n)) / 1.0e+9 + 'B'
      : Math.abs(Number(n)) >= 1.0e+6
        ? Math.abs(Number(n)) / 1.0e+6 + 'M'
        : Math.abs(Number(n)) >= 1.0e+3
          ? Math.abs(Number(n)) / 1.0e+3 + 'K'
          : Math.abs(Number(n));
};

