import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _noop from 'lodash/noop';
import TimePicker from 'src/app/components/pickers/components/TimePicker';
import useStateFromProp from 'src/hooks/useStateFromProp';
import MaskedTimeRangeInputs from 'src/app/components/pickers/components/MaskedTimeRangeInputs';
import MaskedTimeInput from 'src/app/components/pickers/components/MaskedTimeInput';
// import DefinedStaticDays from 'src/app/components/pickers/components/DefinedStaticDays';
import _uniqueId from 'lodash/uniqueId';
import IconAdornment from 'src/app/components/pickers/components/IconAdornment';
import { MarketOpenIcon } from 'src/theme/EdgeIcons';
import {
  Box,
  makeStyles,
  Popover,
  Typography
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  keyboardInput: {},
  popover: {
    '& > div:first-of-type': {
      backgroundColor: ({ backgroundOpacity }) => backgroundOpacity ? `rgba(0, 0, 0, ${backgroundOpacity}) !important` : 'transparent'
    }
  },
  pickerType: {},
  inactivePicker: {
    backgroundColor: 'rgba(0, 0, 0, .2)',
    // opacity: .5,
  },
  pickerTypeLabel: {
    paddingLeft: 3,
    backgroundColor: theme.palette.background.dark,
  }
}));

const PICKER_TYPES = {
  startDate: 'startDate',
  endDate: 'endDate'
}

const PICKER_TYPE_LABELS = {
  [PICKER_TYPES.startDate]: 'Start',
  [PICKER_TYPES.endDate]: 'End'
}

if (Object.keys(PICKER_TYPES).length !== 2) {
  throw Error('Invalid PICKER_TYPES. Must have exactly 2 keys representing startDate and endDate');
}


/** @typedef {import('./definitions/disabledTimes').DisabledTime} DisabledTime */


/**
 * Extends user's disabledTime configuration with start/end bounds logic.
 * @param {DisabledTime} userDisabledTimes
 * @param {Date | undefined} otherTime - undefined on load
 * @param {keyof PICKER_TYPES} type
 * @returns {DisabledTime}
 */
export function rangeAddDisabledTimeBounds(userDisabledTimes, otherTime, type) {
  if (!(type in PICKER_TYPES)) {
    throw Error(`Invalid type. Must be either "${PICKER_TYPES.startDate}" or "${PICKER_TYPES.endDate}"`);
  }

  if (!otherTime) return userDisabledTimes;

  const otherHours = otherTime.getHours();
  const otherMinutes = otherTime.getMinutes();
  const otherSeconds = otherTime.getSeconds();


  const hoursCallback = (value) => {
    const userDisabled = userDisabledTimes.hours?.(value);
    if (userDisabled) return userDisabled;

    if (
      (type === PICKER_TYPES.startDate && value > otherHours) ||
      (type === PICKER_TYPES.endDate && value < otherHours)
    ) {
      return `Hour must be ${type === PICKER_TYPES.startDate ? 'before' : 'after'} the other selected time`;
    }

    return false;
  }

  const minutesCallback = (value, selectedHour) => {
    const userDisabled = userDisabledTimes.minutes?.(value, selectedHour);
    if (userDisabled) return userDisabled;

    if (
      (type === PICKER_TYPES.startDate && (selectedHour > otherHours || (selectedHour === otherHours && value >= otherMinutes))) ||
      (type === PICKER_TYPES.endDate && (selectedHour < otherHours || (selectedHour === otherHours && value <= otherMinutes)))
    ) {
      return `Minute must be ${type === PICKER_TYPES.startDate ? 'before' : 'after'} the other selected time`;
    }

    return false;
  }

  const secondsCallback = (value, selectedHour, selectedMinute) => {
    const userDisabled = userDisabledTimes.seconds?.(value, selectedHour, selectedMinute);
    if (userDisabled) return userDisabled;

    if (
      (type === PICKER_TYPES.startDate &&
        (selectedHour > otherHours ||
          (selectedHour === otherHours && (selectedMinute > otherMinutes || (selectedMinute === otherMinutes && value >= otherSeconds))))) ||
      (type === PICKER_TYPES.endDate &&
        (selectedHour < otherHours ||
          (selectedHour === otherHours && (selectedMinute < otherMinutes || (selectedMinute === otherMinutes && value <= otherSeconds)))))
    ) {
      return `Second must be ${type === PICKER_TYPES.startDate ? 'before' : 'after'} the other selected time`;
    }

    return false;
  }

  return {
    hours: hoursCallback,
    minutes: minutesCallback,
    seconds: secondsCallback,
  };
}



/**
 * Must memoize props.range for performance reasons!
 *
 * @component
 */
function PopoverKeyboardTimeRangePicker({
  rangeClassName,
  inputClassName,
  leftInputClassName,
  rightInputClassName,
  popoverClassName,
  range,
  allowNull,
  onAccept,
  marketTime,
  placeholder,
  showErrorMessage,
  errorPositionAbsolute,
  showNowButton,
  disableUnderline,
  inputVariant,
  disabled,
  backgroundOpacity,
  onKeyUp,
  disabledTime,
  acceptStrategy,
  separator,
  ...sharedProps
}) {
  const classes = useStyles({ backgroundOpacity });
  const [id] = useState(() => _uniqueId('popover_'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [activePicker, setActivePicker] = useState(Object.keys(PICKER_TYPES)[0]);
  const [inputRange, setInputRange] = useStateFromProp(range);

  const onTimePickerChange = (newDate, newType) => {
    setInputRange(prevRange => ({
      ...prevRange,
      [newType]: newDate
    }));
  }

  const open = Boolean(anchorEl);
  const _id = open ? id : undefined;

  const handleOpen = useCallback(event => setAnchorEl(event.currentTarget), []);

  const handleClose = () => {
    onAccept(inputRange);
    setAnchorEl(null)
  }


  return (
    <>
      <MaskedTimeRangeInputs
        {...sharedProps}
        allowNull={allowNull}
        className={rangeClassName}
        inputClassName={inputClassName}
        leftInputClassName={leftInputClassName}
        rightInputClassName={rightInputClassName}
        range={inputRange}
        marketTime={marketTime}
        placeholder={placeholder}
        disabledTime={disabledTime}
        separator={separator}
        onAccept={(nr) => {
          onAccept(nr)
          setInputRange(nr)
        }}
        showErrorMessage={showErrorMessage}
        disableUnderline={disableUnderline}
        inputVariant={inputVariant}
        disabled={disabled}
        acceptStrategy={acceptStrategy}
        endAdornment={<IconAdornment onClick={handleOpen} disabled={disabled}><MarketOpenIcon/></IconAdornment>}
      />
      <Popover
        id={_id}
        open={open}
        anchorEl={anchorEl}
        className={classes.popover}
        disabled={disabled}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          className={clsx(popoverClassName)}
          display="inline-flex"
          flexWrap="nowrap"
        >
          {Object.keys(PICKER_TYPES).map(type => {
            const isActive = activePicker === type;
            const otherType = Object.keys(PICKER_TYPES).find(key => key !== type);
            const _disabledTime = rangeAddDisabledTimeBounds(disabledTime, inputRange?.[otherType], type);
            return (
              <Box
                display="flex"
                flex={1}
                flexDirection="column"
                key={type}
                className={clsx(
                  classes.pickerType,
                  `--picker-type--${type}`,
                  { [classes.inactivePicker]: !isActive }
                )}
                onClick={() => setActivePicker(type)}
              >
                <Typography
                  className={classes.pickerTypeLabel}
                >
                  {PICKER_TYPE_LABELS[type]}
                </Typography>
                <TimePicker
                  key={type}
                  date={inputRange?.[type]}
                  marketTime
                  onChange={date => onTimePickerChange(date, type)}
                  showNowButton={showNowButton}
                  disabledTime={_disabledTime}
                  {...sharedProps}
                />
              </Box>
            );
          })}
        </Box>
      </Popover>
    </>
  );
}

export const RangeShape = PropTypes.shape({
  [PICKER_TYPES.startDate]: PropTypes.instanceOf(Date),
  [PICKER_TYPES.endDate]: PropTypes.instanceOf(Date),
});


PopoverKeyboardTimeRangePicker.propTypes = {
  inputClassName: PropTypes.string,
  popoverClassName: PropTypes.string,
  /** date-fns Unicode date formatting */
  format: PropTypes.string.isRequired,
  /** Shown when input is empty */
  placeholder: PropTypes.string.isRequired,
  /** @see MaskedDateInput */
  inputVariant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  /** Show a shadow behind popover */
  backgroundOpacity: PropTypes.number,
  /** Partial control */
  range: PropTypes.shape({
    startDate: PropTypes.instanceOf(Date),
    endDate: PropTypes.instanceOf(Date),
  }),
  marketTime: PropTypes.bool.isRequired,
  disabledTime: PropTypes.shape({
    hours: PropTypes.func,   // () => []
    minutes: PropTypes.func, // (selectedHour) => []
    seconds: PropTypes.func  // (selectedHour, selectedMinute) => []
  }),
  /** How many numbers to jump by */
  steps: PropTypes.shape({
    hours: PropTypes.number,
    minutes: PropTypes.number,
    seconds: PropTypes.number
  }),
  /**
   * Convert the Date coming out of the picker to Market Time.
   * Assumes your min/max/disable dates are already converted to market time.
   */
  disabled: PropTypes.bool,
  /** @see MaskedTimeInput */
  showErrorMessage: PropTypes.bool,
  /** @see MaskedTimeInput */
  errorPositionAbsolute: PropTypes.bool,
  /** @see MaskedTimeInput */
  disableUnderline: PropTypes.bool,
  /** Allow exclusion of any date */
  disabledDay: PropTypes.func,
  /** Valid input has been accepted */
  onAccept: PropTypes.func.isRequired,
  acceptStrategy: PropTypes.oneOf(['onAccept', 'onClickAway']),
};


PopoverKeyboardTimeRangePicker.defaultProps = {
  inputVariant: 'standard',
  backgroundOpacity: .3,
  marketTime: false,
  disabled: false,
  showErrorMessage: true,
  errorPositionAbsolute: false,
  disableUnderline: false,
  showNowButton: false,
  disabledTime: {},
  acceptStrategy: 'onAccept',
  steps: {
    hours: 1,
    minutes: 1,
    seconds: 1
  }
};



export default React.memo(PopoverKeyboardTimeRangePicker);
