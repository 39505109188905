import React, { useState, useMemo } from 'react';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
import clsx from 'clsx';
import { ListContext } from './context';
import useSlots from 'src/hooks/useSlots';
import { useListStyles } from './styles';
import { DragDropContext, Droppable } from 'react-beautiful-dnd';
import Heading from './Heading';
import {
  List as MUIList,
} from '@material-ui/core';


const getListStyle = isDraggingOver => {
  return isDraggingOver ? { pointerEvents: 'none' } : {};
};


function DraggableList(props) {
  const {
    className,
    role,
    component,
    disabled,
    onReorder,
    dense
  } = props;

  const listClasses = useListStyles();
  const [droppableId] = useState(() => _uniqueId('actionlist-droppable-'))
  const [slots, childrenWithoutSlots] = useSlots(props.children, {
    heading: Heading
  });


  const context = useMemo(() => ({
      virtual: false,
      draggable: true,
      droppableId,
      component,
      disabled,
      role
  }), [droppableId, component, disabled, role])


  const onDragEnd = (result) => {
    if (!result.destination) return;
    onReorder(result)
  }


  return (
    <ListContext.Provider value={context}>
      {slots.heading}
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId={droppableId}>
          {(provided, snapshot) => (
            <MUIList
              className={clsx(className, listClasses.root)}
              {...provided.droppableProps}
              style={getListStyle(snapshot.isDraggingOver)}
              innerRef={provided.innerRef}
              role={role}
              dense={dense}
            >
              {childrenWithoutSlots}
              {provided.placeholder}
            </MUIList>
          )}
        </Droppable>
      </DragDropContext>
    </ListContext.Provider>
  );
}


DraggableList.displayName = 'ActionList.Draggable';


DraggableList.propTypes = {
  className: PropTypes.string,
  /** Aria-Role. Usually listbox or menu */
  role: PropTypes.string,
  component: PropTypes.elementType,
  /** Must be an array of <ActionList.Item /> instances */
  dense: PropTypes.bool,
  onReorder: PropTypes.func.isRequired,
  children: PropTypes.any
};


DraggableList.defaultProps = {
  role: 'listbox',
  component: 'ul',
  dense: true
};


export default DraggableList;
