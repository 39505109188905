import React, {
  createContext,
  useContext,
  useMemo
} from "react";
import PropTypes from "prop-types";
import { useFormSelector_Unprotected } from './FormProvider';


const defaultValue = {
  columnDefs: [],
  columnGroups: {},
  fuseOptions: {},
  defaultDateConfig: {},
  propagateKeyFromFormProvider: 'expressions',
}

export const ColumnDefsContext = createContext(defaultValue);


const emptyArray = [];


export default function ColumnDefsProvider({
  columnDefs,
  columnGroups,
  fuseOptions,
  defaultDateConfig,
  propagateKeyFromFormProvider,
  children
}) {
  // See props below for explination. Not necissary to use within FormProvider, selecting undefined is okay.
  const expressions = useFormSelector_Unprotected(state => state?.[propagateKeyFromFormProvider] || emptyArray);

  const value = useMemo(() => {
    return {
      columnDefs: [...expressions, ...columnDefs],
      columnGroups,
      defaultDateConfig,
      fuseOptions,
    }
  }, [columnDefs, columnGroups, fuseOptions, expressions, defaultDateConfig]);

  return (
    <ColumnDefsContext.Provider value={value}>
      {children}
    </ColumnDefsContext.Provider>
  )
}

export const ColumnDefsProviderPropTypes = {
  columnDefs: PropTypes.arrayOf(PropTypes.object).isRequired,
  columnGroups: PropTypes.object,
  fuseOptions: PropTypes.object,
  defaultDateConfig: PropTypes.object,
  /** 
   * When we have editable columns (like Expressions), we want to dispatch them to 
   * the Form (they're regular form fields), but consume them like Columns.
   *
   * This key simply copies a piece of Form array state into the total Columns.
   **/
  propagateKeyFromFormProvider: PropTypes.string,
  children: PropTypes.any,
}

ColumnDefsProvider.propTypes = {
  ...ColumnDefsProviderPropTypes
}

ColumnDefsProvider.defaultProps = {
  columnGroups: {},
  fuseOptions: {},
  defaultDateConfig: {},
  propagateKeyFromFormProvider: 'expressions',
}

/**
 * @returns {Object[]}
 **/
export function useColumnDefs() {
  const context = useContext(ColumnDefsContext);
  if (context === defaultValue) {
    throw new Error('useColumnDefs must be used within a ColumnDefsProvider');
  }
  return context?.columnDefs || emptyArray;
}

/** @returns {Object} **/
export function useColumnGroups() {
  const context = useContext(ColumnDefsContext);
  if (context === defaultValue) {
    throw new Error('useColumnGroups must be used within a ColumnDefsProvider');
  }
  return context.columnGroups;
}

/** @returns {Object} */
export function useColumnDef(name) {
  const context = useContext(ColumnDefsContext);
  if (context === defaultValue) {
    throw new Error('useColumnDef must be used within a ColumnDefsProvider');
  }
  const columnDefs = context.columnDefs;
  return columnDefs.find(c => c.name === name);
}


/** @returns {Object} */
export function useDefaultDateConfig() {
  const context = useContext(ColumnDefsContext);
  if (context === defaultValue) {
    throw new Error('useDefaultDateConfig must be used within a ColumnDefsProvider');
  }
  return context.defaultDateConfig;
}

/** @returns {Object} */
export function useFuseOptions() {
  const context = useContext(ColumnDefsContext);
  if (context === defaultValue) {
    throw new Error('useFuseOptions must be used within a ColumnDefsProvider');
  }
  return context.fuseOptions;
}
