
function isSessionStorageAvailable() {
  const test = 'isAvailable';
  try {
    sessionStorage.setItem(test, test);
    sessionStorage.removeItem(test);
    return true;
  } catch (e) {
    return false;
  }
}

export default  isSessionStorageAvailable;
