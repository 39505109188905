import { useMemo } from 'react'

import { resolveSliceGroup } from 'src/app/slicedForm/mapping/profileToQuery';
import { EXPR_PREFIX } from 'src/redux/expressions/globalExpressionReducer';
import { decideProfileNodeType, STRUCTURAL_TYPE_RULES, STRUCTURAL_TYPES } from '../../mapping/mappingDirections';
import { getCurrentTradingDay } from 'src/utils/datetime/date-fns.tz';
import { useDeepCompareMemo } from 'src/hooks/useDeepCompare';


const resolveArg = (arg = {}) => {
  if (arg?.column?.startsWith?.(EXPR_PREFIX)) {
    return arg?.column;
  }
}


function findExpressionFilters(
  filters = {},
  now = getCurrentTradingDay()
) {
  const expressionNames = [];

  if (!filters || !Object.keys(filters).length) return expressionNames;

  const recurse = (node) => {
    const type = decideProfileNodeType(node);
    const treeKey = STRUCTURAL_TYPE_RULES[type]?.treeKey;


    switch (type) {
      case STRUCTURAL_TYPES.AND:
      case STRUCTURAL_TYPES.OR:
      case STRUCTURAL_TYPES.ROOT:
      case STRUCTURAL_TYPES.VISUAL_GROUP: {
        return node[treeKey].map(recurse);
      }
      case STRUCTURAL_TYPES.SLICE_GROUP: {
        const resolvedNode = resolveSliceGroup(node, now)
        return recurse(resolvedNode)
      }
      case STRUCTURAL_TYPES.CONDITIONAL_AGG: {
        const arg = resolveArg(node?.target);
        if (arg) {
          expressionNames.push(arg);
        }
        return node[treeKey].map(recurse);
      }
      case STRUCTURAL_TYPES.FILTER: {
        let args = [
          node?.left,
          ...(Array.isArray(node?.right) ? node?.right : [node?.right])
        ];

        const expressionArgs = args.map(resolveArg).filter(Boolean);
        expressionNames.push(...expressionArgs);
        break;
      }
      default: {
        break;
      }
    }
  }

  recurse(filters);

  return [...new Set(expressionNames)];
}


function finedExpressionsColumns(
  columns = []
) {
  return [...new Set(columns.map(c => resolveArg(c)).filter(Boolean))];
}


function findExpressionOrderby(
  orderby = ''
) {
  return orderby?.startsWith?.(EXPR_PREFIX) ? orderby : null;
}



/**
 * Filter down the list of expressions into only ones that are relevant to the
 * current SlicedForms query. Used to prevent components from updating when
 * unrelated expressions change.
 *
 * Reference is stable, you can use this in a useMemo and it will not change
 * without reason.
 * 
 * @param {object[]} expressions
 * @param {object} param0
 * @param {object} [param0.columnProfile]
 * @param {object} [param0.filterProfile]
 * @param {object} [param0.aggregateProfile]
 * @param {string} [param0.orderby]
 * @returns {object[]}
 **/
export default function useFilterRelevantExpressions(
  expressions,
  {
    columnProfile,
    filterProfile,
    aggregateProfile,
    orderby,
  }
) {

  const relevantExpressions = useDeepCompareMemo(() => {
    let expIds = [
      ...findExpressionFilters(filterProfile?.filters),
      ...findExpressionFilters(aggregateProfile?.aggregates ? { [STRUCTURAL_TYPES.ROOT]: aggregateProfile.aggregates } : undefined),
      ...finedExpressionsColumns(columnProfile?.columns),
      findExpressionOrderby(orderby)
    ].filter(Boolean);

    expIds = [...new Set(expIds)];

    const final = [...expressions].sort().filter(exp => expIds.includes(exp.name));
    return final;

  }, [expressions, columnProfile?.columns, filterProfile?.filters, aggregateProfile?.aggregates, orderby]);

  return useDeepCompareMemo(() => relevantExpressions, [relevantExpressions]);
}
