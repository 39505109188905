import React from 'react';
import clsx from 'clsx';
import DoubleConfirmationButton, {
  useDoubleConfirmationState as originalUseDoubleConfirmationState,
  DoubleConfirmationButtonDefaultProps,
  DoubleConfirmationButtonPropTypes
} from 'src/app/components/elements/DoubleConfirmationButton';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    '& .MuiIconButton-root': {
      padding: 6,
      borderRadius: theme.grid.borderRadius,
      color: theme.palette.text.primary,
      '&:focus-visible': {
        ...theme.focus.outline
      }
    }
  }
}));


function EntityDoubleConfirmationIconButton({
  className,
  ...rest
}) {
  const classes = useStyles();

  return (
    <DoubleConfirmationButton
      className={clsx(className, classes.root)}
      {...rest}
    />
  );
}


EntityDoubleConfirmationIconButton.propTypes = {
  ...DoubleConfirmationButtonPropTypes
};



EntityDoubleConfirmationIconButton.defaultProps = {
  ...DoubleConfirmationButtonDefaultProps,
  baseLabel: undefined,
  baseIcon: undefined,
};


export const useDoubleConfirmationState = originalUseDoubleConfirmationState;


export default EntityDoubleConfirmationIconButton;
