import {
  DATA_TYPES,
  ENTITY_RIGHT_DEFAULTS,
  INPUTS,
  ALLOW_NULL,
  AGGREGATES,
  defaultAggregateEntity,
  AGGREGATE_SHORT_LABELS,
  allowedAggregatesForInputType,
} from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import {
  formatLargePrice,
  formatPrice,
  formatLargeInteger,
  formatPercentage
} from 'src/utils/formatters'

/** @typedef {import('src/app/slicedForm/mapping/mappingDirections/index').ProfileFilterNode} ProfileFilterNode */


// Helps build the column definitions for the grid

const validate = (builtParams, groups) => {
  const { name, label, group, dtype } = builtParams;
  if (!name) {
    throw new Error('"name" field is required');
  }
  if (!label && label !== '') {
    throw new Error('"label" field is required. Can be empty string');
  }
  if (!group) {
    throw new Error('"group" field is required');
  }
  // if (!dtype) {
  //   throw new Error('"dtype" field is required');
  // }
  // if (!(dtype in DATA_TYPES)) {
  //   throw new Error('Invalid dtype. Must be one of: ' + Object.keys(DATA_TYPES).join(', '));
  // }

  if (Array.isArray(group)) {
    group.forEach(g => {
      if (!(g in groups)) {
        throw new Error(`Invalid array elemet group ${JSON.stringify(g)}. Must be one of: ${Object.keys(groups).join(', ')}`);
      }
    })
  } else if (!(group in groups)) {
    throw new Error(`Invalid group ${JSON.stringify(group)}. Must be one of: ${Object.keys(groups).join(', ')}`);
  }

  return builtParams;
};

const validateSelect = (builtParams) => {
  const { filter, dtype, name } = builtParams;
  if (!dtype) {
    throw new Error(`"dtype" field must explictly be declaired for "${name}" Select/MultiSelect column`);
  }
  if (!filter.options) {
    throw new Error('"filter.options" field is required must be a non-empty array');
  }
  if (!Array.isArray(filter.options)) {
    throw new Error('"filter.options" field is required must be a non-empty array');
  }
  if (!filter.options.length) {
    throw new Error('"filter.options" field is required must be a non-empty array');
  }
  filter.options.forEach(opt => {
    if (!opt.name || opt.label) {
      console.warn('Failing filter.options:', filter.options);
      throw new Error('"filter.options" must be an array of objects with "name" and "label" fields. See logs.');
    }
  });
  return builtParams;
};



/**
 * 
 * @param {ColumnDef[]} builtColDefs 
 * @returns {ColumnDef[]}
 * @throws {Error}
 */
export const validateColDefs = (builtColDefs, groups) => {
  builtColDefs.forEach(def => {
    validate(def, groups);
    if (def?.filter && def.filter.input in [INPUTS.MULTI_SELECT, INPUTS.SELECT]) {
      validateSelect(def);
    }
  });
  return builtColDefs;
};


/*
 SYMBOLS: None, $, %, x
 
 FORMATTING: Compact, Price, None
*/


/**
 * Defines:
 *  1) How the grid formats the value
 *  2) Which columns are valid comparisons to this column. (ah_vol > pm_vol)
 * 
 * This is entangling two systems (comparisons vs formats), but right now they map 1 to 1.
 */

export const COLUMN_TYPES = {
  expression: {
    name: 'expression',
    comparesTo: [
      'columnType.expression',
      'columnType.secret',
      'columnType.largePrice',
      'columnType.price',
      'columnType.largeInteger',
      'columnType.percentage',
      'columnType.volume'
    ]
  },
  secret: {
    name: 'secret',
    comparesTo: [
      'columnType.expression',
      'columnType.secret',
      'columnType.largePrice',
      'columnType.price',
      'columnType.largeInteger',
      'columnType.percentage',
      'columnType.volume'
    ]
  },
  largePrice: {
    name: 'largePrice',
    formatter: args => formatLargePrice(args.value),
    comparesTo: [
      'columnType.largePrice',
      'columnType.expression',
      'columnType.secret',
    ]
  },
  price: {
    name: 'price',
    formatter: args => formatPrice(args.value),
    comparesTo: [
      'columnType.price',
      'columnType.expression',
      'columnType.secret',
    ]
  },
  largeInteger: {
    name: 'largeInteger',
    formatter: args => formatLargeInteger(args.value),
    comparesTo: [
      'columnType.largeInteger',
      'columnType.expression',
      'columnType.secret',
    ]
  },
  percentage: {
    name: 'percentage',
    formatter: args => formatPercentage(args.value),
    comparesTo: [
      'columnType.percentage',
      'columnType.expression',
      'columnType.secret',
    ]
  },
  volume: {
    name: 'volume',
    formatter: args => formatLargeInteger(args.value),
    comparesTo: [
      'columnType.volume',
      'columnType.expression',
      'columnType.secret',
    ]
  },
  date: {
    name: 'date',
    formatter: null,
    comparesTo: ['columnType.date']
  },
  time: {
    name: 'time',
    formatter: null,
    comparesTo: ['columnType.time']
  },
}


/**
 * @typedef {Object} SchemaProps
 * @property {string} name - The name of the schema.
 * @property {string} label - The label of the schema.
 * @property {string} group - The group of the schema.
 * @property {string} [secret] - If the columns should be visually hidden from the user during Form interactions. The secret word reveals them in search.
 * @property {string} [formLabel] - The form label of the schema.
 * @property {string} [columnType] - The column type of the schema, used for comparisons.
 * @property {object[]} [options] - The Multi Select options (if applicable).
 * @property {Object|false} [grid] - Arbitrary grid properties.
 * @property {Object|false} [filter] - Arbitrary filter properties.
 * @property {Object|false} [column] - Arbitrary column properties.
 */


/**
 * @typedef {Object} ColumnColumnDef - The Column Form settings
 * @property {string} [label] - override
 * @property {keyof STICKY_OPTIONS} [sticky] - Don't allow drag/drop resorting
 * @property {number} [stickyIndex] - The position inside the SelectColumns panel
 */

/**
 * @typedef {Object} FilterColumnDef - The Filter form settings
 * @property {string} [label] - override
 * @property {boolean} [timeSlice] - Allow time slice selection
 * @property {keyof ALLOW_NULL} allowNull - Allow null selection
 * @property {boolean} [allowNullDefault] - If USER_CONTROLLED, then what is the default value?
 * @property {string[]} [comparesTo] - Rules for how to match column -> column comparisons. If not provided, no comparison is allowed.
 * @property {boolean} [directlySelectable=true] - IF EXPLICITLY FALSE, then this column is only available for comparison. It cannot be the left-hand column. 
 * @property {keyof INPUTS} input - The INPUT type
 */

/**
 * @typedef {Object} GridColumnDef 
 * Incomplete list of Grid column def properties. Some are different than ag-grid api.
 * @property {string} [label] - override
 * @property {number|string} [width]
 * @property {number|string} [minWidth]
 * @property {number|string} [maxWidth]
 * @property {'left'|'right'} [pinned]
 * @property {string} [type] - The build-in ag-grid types. Probably don't use.
 * @property {string} [zeroValueFormat]
 * @property {boolean} [sortable] - Allow sorting
 * @property {boolean} [resizable]
 * @property {boolean} [suppressSizeToFit]
 * @property {boolean} [suppressAutoSize]   
 * @property {boolean} [enableCellChangeFlash]   
 * @property {boolean} [suppressMovable]   
 * @property {boolean} [suppressMenu]   
 * @property {boolean} [highlightOnRowHover]
 * @property {boolean} [hasActiveIndicator]
 * @property {string|function} [className]
 * @property {function} [valueGetter]
 * @property {function} [valueFormatter]
 * @property {function} [cellRenderer]
 * @property {Object} [cellRendererParams]
 */


/**
 * @summary The base list of columns, containing options for each context.
 * @description The base list of columns, containing options for each context.
 * Columns will be mapped to thier individual components.
 *
 * if a key is "false" it should not be rendered for that component.
 * Global options will be overriden by child options
 *
 * A mapping function will be provided for:
 *  - gridColumns (AG-Grid defs)
 *  - filterForm (slicedForm)
 *  - columnForm (slicedForm)
 *
 * @typedef {Object} ColumnDef
 * @property {string} name
 * @property {string} label
 * @property {string} formLabel - Overrides the "label" for all children except Grid. This is just for convienience, it doesn't follow the inheritence pattern.
 * @property {keyof DATA_TYPES} dtype - CURRENTLY UNUSED! The data type of the column (currency, float, volume, etc)
 * @property {string} [columnType] - A way to match columns together for column->column comparisons
 * @property {object[]} [options] - The Multi Select options (if applicable).
 * @property {keyof GROUPS | keyof GROUPS[]} group - The column grouping (fundamentals, price, other, etc). Can be array.
 * @property {GridColumnDef|false} [grid] - The AG-Grid column definition
 * @property {FilterColumnDef|false} [filter] - The filter form definition
 * @property {ColumnColumnDef|false} [column] - The column form definition
 *
 */



/**
 * Helper class to generate SlicedForm schema on initialization.
 * Right now, just controls a single default, and the returned 
 * Objects contain the real logic.
 *
 * Intended to be used like HistorySchemaGenerator, RealtimeSchemaGenerator, etc.
 **/
export class SchemaBuilder {
  constructor({
    noTimeSlice = false,
    defaultNumberCellRenderer = 'compactNumberCellRenderer',
  } = {}) {
    this.noTimeSlice = noTimeSlice;
    this.defaultNumberCellRenderer = defaultNumberCellRenderer;
    this.builtColDefs = [];
  }

  _createSchema({
    name,
    label,
    formLabel,
    group,
    dtype,
    columnType,
    secret,
    grid = {},
    filter = {},
    column = {},
    defaultGrid = {},
    defaultFilter = {},
    defaultColumn = {},
  }) {
    const base = {
      name,
      label,
      formLabel,
      group,
      dtype,
      columnType,
    }

    if (secret && typeof secret === 'string') {
      base.secret = secret;
    }

    if (grid !== false) {
      base.grid = {
        ...defaultGrid,
        ...grid,
      }
    }
    if (filter !== false) {
      base.filter = {
        ...defaultFilter,
        ...filter
      }
    }
    if (column !== false) {
      base.column = {
        ...defaultColumn,
        ...column
      }
    }

    return base;
  }

  /**
   * Base for Volume fields. Large, compacted integers.
   * @param {SchemaProps} props
   * @returns {ColumnDef}
   **/
  volume({ ...props }) {
    return this._createSchema({
      dtype: DATA_TYPES.VOLUME,
      columnType: COLUMN_TYPES.volume.name,
      ...props,
      defaultGrid: {
        cellRenderer: this.defaultNumberCellRenderer,
        sortable: true,
        valueFormatter: COLUMN_TYPES.volume.formatter,
      },
      defaultFilter: {
        input: INPUTS.COMPARE,
        ...(this.noTimeSlice ? {} : { timeSlice: true }),
        comparesTo: COLUMN_TYPES.volume.comparesTo,
      },
    });
  }

  /**
   * Base for percentages.
   * @param {SchemaProps & {change?: boolean}} props
   * @returns {ColumnDef}
   **/
  percentage({ change = false, ...props }) {
    return this._createSchema({
      dtype: DATA_TYPES.PERCENTAGE,
      columnType: COLUMN_TYPES.percentage.name,
      ...props,
      defaultGrid: {
        cellRenderer: this.defaultNumberCellRenderer,
        sortable: true,
        numberHighlighting: change,
        valueFormatter: COLUMN_TYPES.percentage.formatter,
      },
      defaultFilter: {
        input: INPUTS.COMPARE,
        ...(this.noTimeSlice ? {} : { timeSlice: true }),
        comparesTo: COLUMN_TYPES.percentage.comparesTo,
      },
    });
  }

  /**
   * Base for small-ish price fields. High, Low, etc.
   * @param {SchemaProps & {change?: boolean}} props
   * @returns {ColumnDef}
   **/
  price({ change = false, ...props }) {
    return this._createSchema({
      dtype: DATA_TYPES.PRICE,
      columnType: COLUMN_TYPES.price.name,
      ...props,
      defaultGrid: {
        cellRenderer: this.defaultNumberCellRenderer,
        sortable: true,
        numberHighlighting: change,
        valueFormatter: COLUMN_TYPES.price.formatter,
      },
      defaultFilter: {
        input: INPUTS.COMPARE,
        ...(this.noTimeSlice ? {} : { timeSlice: true }),
        comparesTo: COLUMN_TYPES.price.comparesTo,
      },
    });
  }


  /**
   * Base for very large Price fields. Marketcap, etc.
   * @param {SchemaProps} props
   * @returns {ColumnDef}
   **/
  largePrice(props) {
    return this._createSchema({
      dtype: DATA_TYPES.LARGE_PRICE,
      columnType: COLUMN_TYPES.largePrice.name,
      ...props,
      defaultGrid: {
        cellRenderer: this.defaultNumberCellRenderer,
        sortable: true,
        valueFormatter: COLUMN_TYPES.largePrice.formatter,
      },
      defaultFilter: {
        input: INPUTS.COMPARE,
        comparesTo: COLUMN_TYPES.largePrice.comparesTo,
        allowNull: ALLOW_NULL.USER_CONTROLLED,
        allowNullDefault: true,
      },
    });
  }

  /**
   * Base for very large integers. Sharesoutstanding, etc.
   * @param {SchemaProps} props
   * @returns {ColumnDef}
   **/
  largeInteger(props) {
    return this._createSchema({
      dtype: DATA_TYPES.INT,
      columnType: COLUMN_TYPES.largeInteger.name,
      ...props,
      defaultGrid: {
        cellRenderer: this.defaultNumberCellRenderer,
        sortable: true,
        valueFormatter: COLUMN_TYPES.largeInteger.formatter,
      },
      defaultFilter: {
        input: INPUTS.COMPARE,
        comparesTo: COLUMN_TYPES.largeInteger.comparesTo,
        allowNull: ALLOW_NULL.USER_CONTROLLED,
        allowNullDefault: true,
      },
    });
  }

  /**
   * Base for dropdown Multi Selects. Usually Strings, but can be anything.
   * @param {SchemaProps & {options: object[]}} props
   * @returns {ColumnDef}
   **/
  multiSelect({ options, ...props }) {
    return this._createSchema({
      dtype: DATA_TYPES.STRING,
      ...props,
      defaultGrid: {
        sortable: true,
        className: 'ag-right-aligned-cell ag-right-aligned-cell-padding',
      },
      defaultFilter: {
        input: INPUTS.MULTI_SELECT,
        options,
      },
    });
  }

  /**
   * @param {SchemaProps & {dateConfig: object}} props
   * @returns {ColumnDef}
   **/
  date({ dateConfig, ...props }) {
    return this._createSchema({
      dtype: DATA_TYPES.DATE,
      columnType: COLUMN_TYPES.date.name,
      ...props,
      defaultGrid: {
        sortable: true,
        className: 'ag-right-aligned-cell ag-right-aligned-cell-padding',
      },
      defaultFilter: {
        input: INPUTS.DATE,
        columnType: COLUMN_TYPES.date.comparesTo,
        dateConfig,
      },
    });
  }

  /**
   * @param {SchemaProps & {dateConfig: object}} props
   * @returns {ColumnDef}
   **/
  time({ dateConfig, ...props }) {
    return this._createSchema({
      dtype: DATA_TYPES.TIME,
      columnType: COLUMN_TYPES.time.name,
      ...props,
      defaultGrid: {
        sortable: true,
        className: 'ag-right-aligned-cell ag-right-aligned-cell-padding',
      },
      defaultFilter: {
        input: INPUTS.TIME,
        columnType: COLUMN_TYPES.time.comparesTo,
        dateConfig,
      },
    });
  }

  /**
   * No formatters or column comparisons. Floats or Integers allowed.
   * @param {SchemaProps} props
   * @returns {ColumnDef}
   **/
  arbitraryNumber(props) {
    return this._createSchema({
      ...props,
      defaultGrid: {
        type: 'numericColumn',
        sortable: true,
      },
      defaultFilter: {
        input: INPUTS.COMPARE,
      },
    });
  }

  /**
   * No formatter. Unknown development columns. Should be comparable to all.
   **/
  secretArbitraryNumber(props) {
    return this._createSchema({
      ...props,
      secret: 'test_columns',
      columnType: COLUMN_TYPES.secret.name, // compares to all
      dtype: DATA_TYPES.FLOAT,
      defaultGrid: {
        type: 'numericColumn',
        sortable: true,
      },
      defaultFilter: {
        input: INPUTS.COMPARE,
        comparesTo: COLUMN_TYPES.secret.comparesTo,
      },
    })
  }
}



/**
 * Builds the default Form Entity value for a given metric, based on filter schema properties.
 * Right now this is thin, but it has the potential to:
 *  - Intialize default values per metric
 *  - Initialize extra property values like allowNull or timeSlice
 *  - Initialize with correct data structure like arrays, strings, etc.
 * @param {FilterColumnDef} filterColumnDef
 * @returns {ProfileFilterNode}
 */
export const getFilterEntityDefaults = (filterColumnDef) => {
  if (!filterColumnDef) throw new Error('filterColumnDef is required');

  const defaults = ENTITY_RIGHT_DEFAULTS[filterColumnDef.input];

  const schema = {
    left: {
      column: filterColumnDef.name,
      type: 'column'
    },
    ...defaults
  }

  if (filterColumnDef.allowNull === ALLOW_NULL.TRUE) {
    schema.allowNull = true;
  } else if (filterColumnDef.allowNull === ALLOW_NULL.FALSE) {
    //
  } else if (filterColumnDef.allowNull === ALLOW_NULL.USER_CONTROLLED) {
    if ('allowNullDefault' in filterColumnDef) {
      schema.allowNull = filterColumnDef.allowNullDefault;
    } else {
      schema.allowNull = true
    }
  }

  return schema;
}



/**
 * Much simpler than Filter, just return the structure
 **/
export const getAggregateEntityDefaults = (columnDef) => {
  if (!columnDef) throw new Error('columnDef is required');

  const allowedAggs = allowedAggregatesForInputType(columnDef?.input);

  if (!allowedAggs.length) {
    console.warn('No allowed aggregates for column:', columnDef);
    return null;
  }

  const aggValue = allowedAggs[0];

  return {
    ...defaultAggregateEntity,
    conditional_agg: aggValue,
    label: columnDef?.label || columnDef?.name,
    target: {
      ...defaultAggregateEntity?.target,
      column: columnDef.name,
      type: 'column'
    },
  }

}


