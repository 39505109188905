import { format as formatDate } from 'date-fns';

/**
 * Takes a date-fns formatting string and returns a mask string.
 * Masks should replace valid Unicode date format tokens with specified patternChar
 * @param {string} fmt - Like 'yyyy-MM-dd'
 * @param {string} patternChar - The character to show in place of numbers, like '#'
 */

export const generateMask = (fmt, patternChar = '#') => {
  return fmt.split('').reduce((str, curr) => {
    return str + (/[a-zA-Z]/.test(curr) ? patternChar : curr);
  }, '');
};


/**
 *  Given yyyy-MM-dd, returns:
 *  [
 *    '####-##-##',
 *    [ 'y', 'y', 'y', 'y', M', 'M', d', 'd' ]
 *  ]
 *  @returns {Array<string, string[]>}
 */
export const generateMaskWithPlaceholders = (fmt, patternChar = "#") => {
  let format = '';
  const maskChars = [];

  fmt.split('').forEach((char) => {
    if (/[a-zA-Z]/.test(char)) {
      format += patternChar;
      maskChars.push(char.toLowerCase());
    } else {
      format += char;
    }
  });

  return [format, maskChars];
}


/**
 * Turns a single input mask into a combined range mask. 
 * ####-##-## => ####-##-## - ####-##-##
 * @param {string} format - Like '####-##-##'
 * @param {string | string[]} mask - Either a single char '_', or an array of string placeholders [y,y,m,m,...]
 * @returns {Array<string, string | string[]>}
 */
export const maskToRange = (format, mask, rangeSep = '-') => {
  return [
    `${format} ${rangeSep} ${format}`,
    Array.isArray(mask) ? mask.concat(mask) : mask
  ]
}


/**
 * Helper to prevent useState()/setState() instatiating every render
 * @param {Date|null} date
 * @param {string} format
 * @returns {string|null}
 */
export const makeInputDateString = (date, format) => () => date ? formatDate(date, format) : '';


export const makeDateRangeInputString = (range, format) => () => {
  if (!range || !range.startDate || !range.endDate) return '';
  return `${formatDate(range.startDate, format)} - ${formatDate(range.endDate, format)}`;
}

export const validateTimeFormat = (fmt) => {
  const validPatterns = [
    'HH:mm',    // 24 hour
    'hh:mm',    // 12 hour
    'HH:mm:ss', // 24 hour
    'hh:mm:ss'  // 12 hour
  ]

  if (!validPatterns.includes(fmt)) {
    throw new Error(`Invalid time format: ${fmt}, must match one of the following: ${validPatterns.join(', ')}`)
  }
  return true;
}
