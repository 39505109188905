/* eslint-disable no-param-reassign */
import produce from 'immer';

import {
  FETCH_HISTORY,
  FETCH_EARNINGS,
  FETCH_SEC_FILINGS,
  FETCH_GAP_STATS,
  FETCH_FUNDAMENTALS,
  FETCH_INSIDERS,
  REQUEST__FETCH_HISTORY,
  REQUEST__FETCH_EARNINGS,
  REQUEST__FETCH_SEC_FILINGS,
  REQUEST__FETCH_GAP_STATS,
  REQUEST__FETCH_FUNDAMENTALS,
  REQUEST__FETCH_INSIDERS
} from 'src/redux/statTables/statTablesActions';


const initialState = {
  // history: [],
  // earnings: [],
  // secFilings: {
  //   finacials: [],
  //   news: [],
  //   dilution: [],
  //   proxies: [],
  //   ownership: [],
  //   other: []
  // },
  insiders: {
    'summary': {},
    'transactions': [],
    'dividends': [],
    'Institutions': [],
    'Funds': [],
  },
  // gapStats: {
  //   'statsGap10': {},
  //   'statsGap20': {}
  // },
  fundamentals: {
    'data': {},
    'date': null
  },
  isFetching: {
    // history: true,
    earnings: true,
    // secFilings: true,
    // gapStats: true,
    // fundamentals: true,
    insiders: true
  }
};


const statTablesReducer = (state = initialState, action) => {

  switch (action.type) {

    // case FETCH_HISTORY: {
    //   return produce(state, (draft) => {
    //     draft.isFetching.history = false;
    //     draft.history = action.payload || [];
    //   });
    // }

    // case FETCH_EARNINGS: {
    //   return produce(state, (draft) => {
    //     draft.isFetching.earnings = false;
    //     draft.earnings = action.payload || [];
    //   });
    // }
    //
    // case FETCH_SEC_FILINGS: {
    //   return produce(state, (draft) => {
    //     draft.isFetching.secFilings = false;
    //     draft.secFilings = action.payload || draft.secFilings;
    //   });
    // }
    //
    // case FETCH_GAP_STATS: {
    //   return produce(state, ({ gapStats, isFetching }) => {
    //     isFetching.gapStats = false;
    //     if (!action.payload) {
    //       gapStats.statsGap10 = {};
    //       gapStats.statsGap20 = {};
    //     } else {
    //       gapStats.statsGap10 = action.payload.stats_gap_10;
    //       gapStats.statsGap20 = action.payload.stats_gap_20;
    //     }
    //   });
    // }

    case FETCH_FUNDAMENTALS: {
      return produce(state, ({ fundamentals, isFetching }) => {
        isFetching.fundamentals = false;
        if (!action.payload) {
          fundamentals.data = {};
          fundamentals.date = null;
        } else {
          fundamentals.data = action.payload.snapshot;
          fundamentals.date = Object.keys(action.payload.balanceSheet)[0];
        }
      });
    }

    case FETCH_INSIDERS: {
      return produce(state, (draft) => {
        draft.isFetching.insiders = false;
        if (action.payload) {
          draft.insiders['summary'] = action.payload['summary'] || {};
          draft.insiders['transactions'] = action.payload['transactions'] || [];
          draft.insiders['dividends'] = action.payload['dividends'] || [];
          draft.insiders['Institutions'] = action.payload['Institutions'] || [];
          draft.insiders['Funds'] = action.payload['Funds'] || [];
        } else {
          draft.insiders = initialState.insiders;
        }
      });
    }

    // case REQUEST__FETCH_HISTORY: {
    //   return produce(state, ({ isFetching }) => { isFetching.history = true; });
    // }
    // case REQUEST__FETCH_EARNINGS: {
    //   return produce(state, ({ isFetching }) => { isFetching.earnings = true; });
    // }
    // case REQUEST__FETCH_SEC_FILINGS: {
    //   return produce(state, ({ isFetching }) => { isFetching.secFilings = true; });
    // }
    // case REQUEST__FETCH_GAP_STATS: {
    //   return produce(state, ({ isFetching }) => { isFetching.gapStats = true; });
    // }
    case REQUEST__FETCH_FUNDAMENTALS: {
      return produce(state, ({ isFetching }) => { isFetching.fundamentals = true; });
    }
    case REQUEST__FETCH_INSIDERS: {
      return produce(state, ({ isFetching }) => { isFetching.insiders = true; });
    }

    default: {
      return state;
    }
  }
};

export default statTablesReducer;
