
import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _uniqueId from 'lodash/uniqueId';
import DateRangePicker, { RangeShape } from 'src/app/components/pickers/components/DateRangePicker';
import MaskedDateRangeInputs from 'src/app/components/pickers/components/MaskedDateRangeInputs';
import IconAdornment from 'src/app/components/pickers/components/IconAdornment';
import Popover from '@material-ui/core/Popover';
import {
  Box,
  lighten,
  makeStyles,
} from '@material-ui/core';
import { Calendar6Icon } from 'src/theme/EdgeIcons';
import useStateFromProp from 'src/hooks/useStateFromProp';


const useStyles = makeStyles(theme => ({
  keyboardInput: {},
  popover: {
    '& > div:first-of-type': {
      backgroundColor: ({ backgroundOpacity }) => backgroundOpacity ? `rgba(0, 0, 0, ${backgroundOpacity}) !important` : 'transparent'
    }
  },
  defaultStyling: {
    width: '100%',
    backgroundColor: theme.palette.background.paperAlt_highlight,
    transition: 'background-color 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    borderRadius: theme.grid.borderRadius,
    '&:hover': {
      backgroundColor: `${lighten(theme.palette.background.paperAlt_highlight, .05)} !important`,
    },
    '& .MuiOutlinedInput-notchedOutline': {
      display: 'none'
    },
    '& .MuiInputBase-root': {
      fontSize: 14,
      lineHeight: 1,
      paddingBottom: [[0, '!important']],
      '&:focus-visible': {
        ...theme.focus.outline
      }
    },
    '& .MuiInput-formControl': {
      backgroundColor: 'transparent !important'
    },
    '& .--range-input': {
      width: 86,
      '& .MuiInputBase-input': {
        fontSize: 14,
        paddingLeft: 8,
        paddingTop: 10,
        paddingBottom: 8,
        textTransform: 'none',
        fontWeight: 400
      },
      '& input::placeholder': {
        transform: 'scale(.9, 1)',
        transformOrigin: 'left',
        paddingLeft: 0,
        marginLeft: 0
      }
    },
    '& .--range-left': {

    },
    '& .--range-right': {

    },
    '& .range-end-adornment .MuiIconButton-root, &.range-end-adornment .MuiIconButton-root': {
      borderRadius: theme.grid.borderRadius,
      padding: '5.2px 5px'
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: '0 !important'
    }
  }
}));



/**
 * 
 * props.range MUST be memoized for performance reasons!
 * 
 * Displays:
 *  - Two keyboard inputs together
 *  - Calendar icon, which opens:
 *    - Date picker calendar
 *    - (Optionally) Static day selectors
 *
 *  All work together in concert. Valid dates are sent to parent onChange.
 *
 * @component
 */
function PopoverKeyboardDateRangePicker({
  applyDefaultStyling,
  rangeClassName,
  inputClassName,
  leftInputClassName,
  rightInputClassName,
  popoverClassName,
  range,
  onAccept,
  marketTime,
  placeholder,
  definedDays,
  showErrorMessage,
  makeInputRanges,
  disableUnderline,
  inputVariant,
  disabled,
  separator,
  allowNull,
  backgroundOpacity,
  staticRangeHeaderContent,
  makeStaticRanges,
  acceptStrategy,
  leftTopLabel,
  rightTopLabel,
  ...sharedProps
}) {
  const classes = useStyles({ backgroundOpacity });
  const [id] = useState(() => _uniqueId('popover_'));
  const [anchorEl, setAnchorEl] = useState(null);
  const [inputRange, setInputRange] = useStateFromProp(range);

  const open = Boolean(anchorEl);
  const _id = open ? id : undefined;

  if (sharedProps.maxDate && sharedProps.disableFuture) throw Error('Cannot have both maxDate and disableFuture');

  const handleOpen = useCallback(event => setAnchorEl(event.currentTarget), []);

  const handleClose = () => {
    onAccept(inputRange);
    setAnchorEl(null)
  }

  return (
    <>
      <MaskedDateRangeInputs
        {...sharedProps}
        leftInputClassName={clsx(leftInputClassName, '--range-left')}
        rightInputClassName={clsx(rightInputClassName, '--range-right')}
        leftTopLabel={leftTopLabel}
        rightTopLabel={rightTopLabel}
        className={clsx(
          rangeClassName,
          '--range',
          applyDefaultStyling && classes.defaultStyling
        )}
        inputClassName={clsx(inputClassName, '--range-input')}
        range={inputRange}
        allowNull={allowNull}
        marketTime={marketTime}
        placeholder={placeholder}
        onAccept={(nr) => {
          onAccept(nr)
          setInputRange(nr)
        }}
        separator={separator}
        showErrorMessage={showErrorMessage}
        disableUnderline={disableUnderline}
        inputVariant={inputVariant}
        disabled={disabled}
        acceptStrategy={acceptStrategy}
        endAdornment={<IconAdornment onClick={handleOpen} disabled={disabled}><Calendar6Icon /></IconAdornment>}
      />
      <Popover
        id={_id}
        open={open}
        anchorEl={anchorEl}
        className={classes.popover}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          className={clsx(popoverClassName)}
          display="inline-flex"
          flexWrap="nowrap"
        >
          <DateRangePicker
            {...sharedProps}
            range={inputRange}
            marketTime
            retainEndDateOnFirstSelection={false}
            moveToDateOnFirstSelection={false}
            onAccept={(nr) => {
              setInputRange(nr)
            }}
            makeStaticRanges={makeStaticRanges}
            makeInputRanges={makeInputRanges}
            staticRangeHeaderContent={staticRangeHeaderContent}
          />

        </Box>
      </Popover>
    </>
  );
}


PopoverKeyboardDateRangePicker.propTypes = {
  rangeClassName: PropTypes.string,
  popoverClassName: PropTypes.string,
  range: RangeShape.isRequired,
  /** date-fns Unicode date formatting */
  format: PropTypes.string.isRequired,
  /** Shown when input is empty */
  placeholder: PropTypes.string.isRequired,
  /** @see MaskedDateInput */
  inputVariant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  /** Individual day selectors on the left of the calendar. See staticDayDefinitions.js */
  /** How does the parent component update? */
  backgroundOpacity: PropTypes.number,
  /** Valid input has been accepted */
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  shownDate: PropTypes.instanceOf(Date),
  /**
   * Convert the Date coming out of the picker to Market Time.
   * Assumes your min/max/disable dates are already converted to market time.
   */
  marketTime: PropTypes.bool.isRequired,
  disableHoliday: PropTypes.bool,
  disableWeekend: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disabled: PropTypes.bool,
  /** @see MaskedDateInput */
  showErrorMessage: PropTypes.bool,
  /** @see MaskedDateInput */
  disableUnderline: PropTypes.bool,
  /** Allow exclusion of any date */
  disabledDay: PropTypes.func,
  /** Show a shadow behind popover */
  onAccept: PropTypes.func.isRequired,
  acceptStrategy: PropTypes.oneOf(['onAccept', 'onClickAway']),
  makeStaticRanges: PropTypes.func,
  staticRangeLabel: PropTypes.any,
  /** Inputs on left of calendar, like "X days from today" */
  makeInputRanges: PropTypes.func
};


PopoverKeyboardDateRangePicker.defaultProps = {
  inputVariant: 'standard',
  backgroundOpacity: .3,
  marketTime: false,
  disabled: false,
  disableHoliday: false,
  disableWeekend: false,
  disableFuture: false,
  showErrorMessage: true,
  disableUnderline: false,
  acceptStrategy: 'onAccept'
};


export default React.memo(PopoverKeyboardDateRangePicker);

