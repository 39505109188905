import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import _uniqueId from 'lodash/uniqueId';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  transparent: {
    backgroundColor: 'transparent !important',
    boxShadow: 'none !important'
  },
  paper: {
    padding: theme.spacing(1),
    backgroundColor: theme.palette.background.tab,
    borderRadius: theme.grid.borderRadius,
    boxShadow: theme.shadows[1],
    marginTop: 8,
  },
  withArrow: {
    position: 'relative',
    '&::before': {
      content: "''",
      position: 'absolute',
      top: -8,
      left: '50%',
      transform: 'translateX(-50%)',
      borderStyle: 'solid',
      borderWidth: '0 8px 8px 8px',
      borderColor: `transparent transparent ${theme.palette.background.tab} transparent`,
    }
  },
  content: {
    '& p': {
      fontSize: 12
    }
  }
}));

export default function HoverInfoPopper({
  containerClassName,
  className,
  arrow = true,
  content,
  children
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [id] = useState(() => _uniqueId('hover-info-popper-'));

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  return (
    <div className={containerClassName}>
      <span
        aria-owns={open ? id : undefined}
        aria-haspopup="true"
        onMouseEnter={handlePopoverOpen}
        onMouseLeave={handlePopoverClose}
      >
        {children}
      </span>
      <Popover
        id={id}
        className={classes.popover}
        classes={{
          paper: classes.transparent,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div className={clsx(className, classes.paper, arrow && classes.withArrow)}>
          {(typeof content === 'string') ? <Typography variant="body2">{content}</Typography> : content}
        </div>
      </Popover>
    </div>
  );
}


HoverInfoPopper.propTypes = {
  className: PropTypes.string,
  id: PropTypes.string,
  arrow: PropTypes.bool,
  content: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.element]),
  children: PropTypes.any
};
