import React from 'react';
import clsx from 'clsx';
import noop from 'lodash/noop';
import PropTypes from 'prop-types';
import {
  CHECKBOX_STATES,
  CHILD_TYPES
} from 'src/app/components/filterContainers/NestedCheckboxFilterModal/constants';
import { ChevronRight as ChevronRightIcon } from '@material-ui/icons';
import {
  Typography,
  Checkbox,
  makeStyles,
} from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {},
  checkboxListItem: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    position: 'relative',
    borderRadius: 0,
    '&.checkbox-list-selected': {
      '& $chevron': {
        opacity: 1
      }
    }
  },
  checkboxItemLabel: {
    cursor: 'pointer'
  },
  chevron: {
    position: 'absolute',
    right: 4,
    top: '50%',
    transform: 'translateY(-50%)',
    opacity: .25
  }
}));


/* Recursive */
function CheckboxList({
  className,
  items,
  disabledItemKeys, // Which parents should be disabled. Add to this list to disable children
  getStateForId,
  idsToRender,
  indentLevel,
  onCheck,
  onSelect,
  selectedColumnItem
}) {
  const classes = useStyles();

  if (!idsToRender.length) {
    idsToRender = items.filter((i) => !i.parent && i.childType === CHILD_TYPES.INDENTED).map((i) => i.name);
  }

  const getChildNodes = (parent, disabled) => {
    const nodeItems = items.filter(i => i.parent === parent.name);
    if (!nodeItems.length) return null;
    if (parent.childType !== CHILD_TYPES.INDENTED) return null;

    const newDisabledItems = disabled ? [...disabledItemKeys, ...nodeItems.map(i => i.name)] : disabledItemKeys;

    return (
      <CheckboxList
        className={`checkbox-list-root-${parent.name}`}
        items={items}
        disabledItemKeys={newDisabledItems}
        idsToRender={nodeItems.map(i => i.name)}
        indentLevel={indentLevel + 1}
        onCheck={onCheck}
        onSelect={onSelect}
        getStateForId={getStateForId}
        selectedColumnItem={selectedColumnItem}
      />
    );
  };

  return (
    <div
      className={clsx(
        classes.root,
        className,
        'checkbox-list-root',
        `indent-level-${indentLevel}`
      )}
    >
      {idsToRender.map((name) => {
        const item = items.find((i) => i.name === name);
        const checkboxState = getStateForId(name);
        const checkable = item.checkbox;
        const selectable = item?.childType === CHILD_TYPES.NESTED;
        const selected = selectable && selectedColumnItem === item.name;

        const disabled = disabledItemKeys.includes(item.name);

        const handleOuterClick = () => {
          if (disabled) return;
          if (checkable && selectable) {
            onSelect(item.name);
          } else if (checkable) {
            onCheck(item.name);
          } else if (selectable) {
            onSelect(item.name);
          }
        };

        const handleCheckboxClick = () => {
          if (checkable && selectable && !disabled) {
            onCheck(item.name);
          }
        };

        return (
          <React.Fragment key={item.name}>
            <div
              role="button"
              onClick={handleOuterClick}
              aria-disabled={disabled}
              className={clsx(
                classes.checkboxListItem,
                'checkbox-list-item',
                !checkable && 'no-checkbox',
                selectable && 'checkbox-list-selectable',
                selected && 'checkbox-list-selected',
                item.classes,
                disabled && 'checkbox-list-disabled'
              )}>
              {checkable && (
                <div
                  role="checkbox"
                  onClick={handleCheckboxClick}
                >
                  <Checkbox
                    checked={checkboxState === CHECKBOX_STATES.CHECKED}
                    indeterminate={checkboxState === CHECKBOX_STATES.INDETERMINATE}
                    disableRipple
                    disabled={disabled}
                  />
                </div>
              )}
              <div className={clsx(classes.checkboxItemLabel, 'checkbox-list-item-label')}>
                <Typography variant="body1">{item.label}</Typography>
              </div>
              {(selectable && !disabled) && (
                <ChevronRightIcon className={clsx(
                  classes.chevron,
                  'checkbox-selectable-chevron',
                )}
                />
              )}
            </div>
            {getChildNodes(item, disabled)}
          </React.Fragment>
        );
      })}
    </div>
  );
}


CheckboxList.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  disabledItemKeys: PropTypes.arrayOf(PropTypes.string),
  disabled: PropTypes.bool,
  getStateForId: PropTypes.func,
  idsToRender: PropTypes.arrayOf(PropTypes.string),
  indentLevel: PropTypes.number,
  onCheck: PropTypes.func,
  onSelect: PropTypes.func,
  selectedColumnItem: PropTypes.string
};


CheckboxList.defaultProps = {
  idsToRender: [],
  disabledItemKeys: [],
  indentLevel: 0,
  onCheck: noop,
  onSelect: noop,
  selectedColumnItem: undefined
};


export default CheckboxList;
