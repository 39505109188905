import { initialKeyStatsAlarms } from './profileSettingsConfig';
import { PREDEF_PREFIX } from 'src/redux/profileSettings/profileSettingsConfig';


export const defaultToplistScannerProfile = {
  activeProfile: 'default',
  audioEnabled: false,
  profiles: [{
    id: 'default',
    profileName: 'Default',
    activeColumns: ['index', 'ticker', 'number_news', 'session_lp', 'session_v', 'session_chg_p', 'marketcap', 'sharesoutstanding', 'sharesfloat', 'shortpercentfloat', 'home_country']
  }]
};

export const defaultToplistKeyStatsProfile = {
  activeProfile: 'default',
  financialsPeriod: 'quarterly',
  profiles: [{
    id: 'default',
    profileName: 'Default',
    alarms: initialKeyStatsAlarms,
    sections: [
      {
        sectionName: 'General Info',
        tables: [
          ['marketCap', 'sharesoutstanding', 'sharesfloat', 'shortpercentfloat', 'percentinsiders', 'percentinstitutions', 'floatRotation', 'SSR'],
        ],
      },
      {
        sectionName: 'Ratios',
        tables: [
          ['BurnMo', 'WorkingCapital', 'ROA', 'ROE'],
        ],
      }
    ]
  }]
};


export const predefinedTopListFilterProfiles = [
  {
    id: `${PREDEF_PREFIX}none`,
    profileName: 'None',
    filters: {},
  },
  {
    id: `${PREDEF_PREFIX}small-cap`,
    profileName: 'Small Cap',
    filters: {
      'session_lp': { from: 1, to: 15 },
      'session_v': { from: 300_000 },
      'session_chg_p': { from: 5 },
      'share_type': { selected: 'CS' }
    }
  },
  {
    id: `${PREDEF_PREFIX}large-cap`,
    profileName: 'Large Cap',
    filters: {
      'session_lp': { from: 15, to: 100 },
      'session_v': { from: 100_000 },
      'session_chg_p': { from: 1 },
      'share_type': { selected: 'CS' }
    }
  },
  {
    id: `${PREDEF_PREFIX}gap-scanner`,
    profileName: 'Gap Scanner',
    filters: {
      'session_lp': { from: 1, to: 15 },
      'pm_chg_p': { from: 5 },
      'pm_v': { from: 50_000 },
      'share_type': { selected: 'CS' }
    }
  },
  {
    id: `${PREDEF_PREFIX}afterhours-scanner`,
    profileName: 'Afterhours Scanner',
    filters: {
      'session_lp': { from: 1, to: 15 },
      'ah_chg_p': { from: 3 },
      'ah_v': { from: 10_000 },
      'share_type': { selected: 'CS' }
    }
  }
];


export const defaultTopListFilterProfile = {
  activeProfile: `${PREDEF_PREFIX}none`,
  profiles: [
    ...predefinedTopListFilterProfiles
  ]
};
