import ChartToolbarButtonBase from './ChartToolbarButtonBase';


export default class LayoutButton extends ChartToolbarButtonBase {

  constructor(widget, name, createButtonArgs = {}) {
    super(widget, createButtonArgs);
    this.element = widget.current.createButton(createButtonArgs);
    this.name = name;
    this.render();
    this.setupAnimation();
    this.isAnimating = false;
  }

  setName(newName) {
    this.name = newName;
    if (!this.element) {
      this.render();
    } else {
      this.element.querySelector('.js-button-text').innerText = this.name;
    }
  }

  onClick(handler) {
    super.onClick(() => {
      this.animate();
      handler()
    });
  }

  animate() {
    if (this.isAnimating) return;

    this.isAnimating = true;
    const spinning = this.element.querySelector('.spinningCloud-NezC5dyJ');
    const arrowGap = this.element.querySelector('.arrowGap-NezC5dyJ');
    const arrow = this.element.querySelector('.arrow-NezC5dyJ');
    const check = this.element.querySelector('.check-NezC5dyJ');

    const spinningCloudLen = spinning.getTotalLength();
    spinning.setAttribute('stroke-dasharray', '49 13');

    spinning.style.display = 'inline';
    check.style.display = 'none';
    arrow.style.display = 'none';
    arrowGap.style.display = 'none';

    let currLen = 0;
    const interval = setInterval(() => {
      spinning.setAttribute('stroke-dashoffset', currLen);
      currLen += 1;
      if (currLen >= spinningCloudLen) {
        currLen = 0;
      }
    }, 20);

    setTimeout(() => {
      clearInterval(interval);
      spinning.setAttribute('stroke-dasharray', '0');
      check.style.display = 'inline';

      setTimeout(() => {
        this.setupAnimation();
      }, 2000);
    }, 2000);
  }


  setupAnimation() {
    const spinning = this.element.querySelector('.spinningCloud-NezC5dyJ');
    const dotted = this.element.querySelector('.dottedCloud-NezC5dyJ');
    const check = this.element.querySelector('.check-NezC5dyJ');
    const arrowGap = this.element.querySelector('.arrowGap-NezC5dyJ');
    const arrow = this.element.querySelector('.arrow-NezC5dyJ');

    spinning.style.display = 'inline';
    spinning.setAttribute('stroke-dasharray', '0');

    dotted.style.display = 'none';

    check.style.display = 'none';
    check.querySelector('path').setAttribute('stroke-dasharray', 0);

    arrow.style.display = 'inline';
    arrow.style.opacity = 1;
    arrow.style.transform = 'translateY(0)';

    arrowGap.style.opacity = 1;
    arrowGap.style.display = 'inline';
    arrowGap.style.fill = '#131722';
    this.isAnimating = false;
  }


  render() {
    this.element.innerHTML =
      `<div 
          id="header-toolbar-save-load" 
          title="Save general chart settings" 
          style="padding-left:7px;padding-right:7px;margin-left:-7px;margin-right:-7px;" 
          data-role="button" 
          class="button-OhqNVIYA button-ptpAHg8E withText-ptpAHg8E button-GwQQdU8S apply-common-tooltip isInteractive-GwQQdU8S accessible-GwQQdU8S "
        >
        <svg xmlns="http://www.w3.org/2000/svg" class="container-NezC5dyJ icon-9pA37sIi unsaved-NezC5dyJ" version="1.1" width="28" height="28" viewBox="0 0 28 28">
          <g fill="none">
            <path class="dottedCloud-NezC5dyJ" stroke="currentColor" stroke-dasharray="3.5,2.5" d="M21.5 21.5h-14a5 5 0 1 1 .42-9.983 7.5 7.5 0 0 1 14.57 2.106 4.002 4.002 0 0 1-.99 7.877z"></path>
            <path class="spinningCloud-NezC5dyJ" stroke="currentColor" stroke-dasharray="0" stroke-dashoffset="0" d="M21.5 21.5h-14a5 5 0 1 1 .42-9.983 7.5 7.5 0 0 1 14.57 2.106 4.002 4.002 0 0 1-.99 7.877z"></path>
            <path class="arrowGap-NezC5dyJ" d="M11 20h6v5h-6z"></path>
            <g class="arrow-NezC5dyJ" stroke="currentColor">
              <path stroke-linecap="square" d="M14.5 14.5v10"></path>
              <path d="M11 17l3.5-3.5L18 17"></path>
            </g>
            <g class="check-NezC5dyJ" stroke="currentColor">
              <path stroke-dasharray="0" d="M10 15l2.5 2.5L18 12"></path>
            </g>
          </g>
        </svg>
        <div class="js-button-text text-9pA37sIi text-mEQw2hrh" style="padding-left: 5px;">${this.name}</div>
      </div>`;
  }
}
