import _isEqual from 'lodash/isEqual';
import _omit from 'lodash/omit';
import _differenceBy from 'lodash/differenceBy';
import _uniqBy from 'lodash/uniqBy';
import { format } from 'date-fns-tz';
import edgeDataApi from 'src/apis/edgeDataApi';
import profileToQuery from 'src/app/slicedForm/mapping/profileToQuery';
import { decideDataSourceSort } from 'src/app/components/grid/gridColumnFunctions';
import DataSourceResponseBatcher from './DataSourceResponseBatcher';
import {
  getCurrentMarketTimeString,
  getMostRecentTradingDay
} from 'src/utils/datetime/date-fns.tz';


export const TRACKER_ACTIONS = {
  added: 'added',
  removed: 'removed'
};



class TopListDataSourceV2 {
  /**
   * @constructor
   * @param {string} id - component id registering the dataSource
   * @property {object} previousResponse - The previous response from the server. Used to silently ignore failed requests.
   * @property {object} previousQuery - Same purpose as previousResponse
   * @property {boolean} initialRequestComplete - Allows the calling component to handle some edge cases
   **/
  constructor(id) {
    this.id = id;
    this.previousResponse = null;
    this.previousQuery = null;
    this.initialRequestComplete = false;
  }

  async getRows(params) {
    if (!params?.api || !params?.context?.isMountedRef?.current) return;

    const topListParams = this.buildQuery(params);
    void DataSourceResponseBatcher.registerRequest(
      {
        id: this.id,
        promise: this.fetchTopListScanner(topListParams),
        onResolve: (records) => this.onScannerResolve(records, topListParams, params),
      },
      topListParams.historical
    );

    DataSourceResponseBatcher.registerDataFeed(this.id);
  }

  buildQuery(params) {
    const { columnProfile, filterProfile, day, order, orderby, handlePersistSort, getExcludedTickers, expressions } = params.context;

    const mostRecentTradingDay = getMostRecentTradingDay();
    const _day = day === format(mostRecentTradingDay, 'yyyy-MM-dd') ? false : day;

    const blacklist_columns = ['index'];
    const columnParam = columnProfile.columns.filter(c => !blacklist_columns.includes(c.column));

    // If an expression is deleted, it won't be in the grid in the first place
    const sortArgs = decideDataSourceSort(params, { order, orderby }, ['session_chg_p', 'session_chg', 'session_v']);
    handlePersistSort(sortArgs);

    const excludedTickers = getExcludedTickers();

    const requestParams = profileToQuery(
      {
        columns: columnParam,
        filters: filterProfile.filters,
        sortArgs: sortArgs
      },
      _day,
      excludedTickers,
      expressions
    )

    return {
      ...requestParams,
      is_historical: Boolean(_day),
    };
  }

  async fetchTopListScanner(requestParams) {
    let records = [];
    try {
      const response = await edgeDataApi.post('/scanner/v2', requestParams);
      if (Array.isArray(response.data)) {
        records = response.data;
      }
    } catch (err) {
      records = false;
    } finally {
      this.initialRequestComplete = true
    }

    return records;
  }

  onScannerResolve(records, topListParams, params) {
    const { success, fail, api, columnApi, context } = params;

    if (!api || !context?.isMountedRef?.current) return;

    const isNewQuery = !_isEqual(this.previousQuery, topListParams);
    const isNewQueryIgnoringColumns = !_isEqual(
      _omit(this.previousQuery, 'columns'),
      _omit(topListParams, 'columns')
    );

    if (!Array.isArray(records)) {
      if (isNewQuery) {
        this.previousResponse = records;
        this.previousQuery = topListParams;
        api?.hideOverlay();
        context?.setIsFetching(false);
        fail();
        context?.handleSetTrackerData([]);
        return;
      } else {
        records = this.previousResponse;
      }
    }

    // if (process.env.REACT_APP_USERS_LAMBDA_STAGE === 'local') {
    //   records.forEach(r => {
    //     r.session_lp = r.session_lp * (Math.random() + .5);
    //   });
    // }

    success({ rowData: records, rowCount: records.length });

    if (isNewQueryIgnoringColumns) {
      context?.handleSetTrackerData?.([]);
    } else {
      this.determineTrackerHistory(records, context, this.previousResponse);
    }

    this.previousResponse = records;
    this.previousQuery = topListParams;


    if (columnApi?.columnModel?.gridColumnsMap && 'index' in columnApi.columnModel.gridColumnsMap) {
      setTimeout(() => {
        api?.refreshCells?.({
          columns: ['index'],
          suppressFlash: true
        });
      }, 0);
    }

    if (!records.length) {
      api?.showNoRowsOverlay();
    } else {
      api?.hideOverlay();
    }

    context?.setIsFetching(false);
  }


  determineTrackerHistory(records, context, previousResponse) {
    const { trackerData, handleSetTrackerData } = context;
    const timestamp = getCurrentMarketTimeString('HH:mm:ss');
    const removedTickers = _differenceBy(previousResponse, records, 'ticker');
    const addedTickers = _differenceBy(records, previousResponse, 'ticker');
    // For audio tracking. Do this here for performance reasons.
    const batchTime = Math.floor(new Date().getTime() / 1000);

    // if (process.env.REACT_APP_USERS_LAMBDA_STAGE === 'local') {
    //
    //   const getRandomItems = (arr, numItems) => {
    //     const result = [];
    //     const usedIndices = new Set();
    //
    //     while (result.length < numItems && result.length < arr.length) {
    //       const randomIndex = Math.floor(Math.random() * arr.length);
    //
    //       if (!usedIndices.has(randomIndex)) {
    //         result.push(arr[randomIndex]);
    //         usedIndices.add(randomIndex);
    //       }
    //     }
    //
    //     return result;
    //   }
    //
    //   const list = ['AAPL', 'GOOGL', 'AMZN', 'TSLA', 'MSFT', 'MBOT'];
    //   const tickers = getRandomItems(list, 4);
    //
    //   handleSetTrackerData([
    //     ...tickers.map(ticker => ({ ticker, timestamp, batchTime, action: TRACKER_ACTIONS.added })),
    //     ...trackerData.map(item => item.stale ? item : { ...item, stale: true }) // Mark previous items
    //   ]);
    //   return;
    // }

    if (removedTickers.length || addedTickers.length) {

      const data = [
        ...addedTickers.map(({ ticker }) => ({ ticker, timestamp, batchTime, action: TRACKER_ACTIONS.added })),
        ...removedTickers.map(({ ticker }) => ({ ticker, timestamp, batchTime, action: TRACKER_ACTIONS.removed })),
        ...trackerData.map(item => item.stale ? item : { ...item, stale: true }) // Mark previous items
      ]

      // Somehow we occasionally get dups in here. I don't know why, it might just be Devtools reloading
      handleSetTrackerData(_uniqBy(data, row => `${row.ticker}#${row.timestamp}`));
    }
  }


  destroy() {
    DataSourceResponseBatcher.unregisterDataFeed(this.id);
  }
}


export default TopListDataSourceV2;



