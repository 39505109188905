import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  makeStyles
} from '@material-ui/core';

export const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.panelHeader,
    borderTopRightRadius: 4,
    borderTopLeftRadius: 4,
    padding: '0px 13px',
    fontWeight: 'bold',
    textAlign: 'center',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    '& .panelTitle': {
      fontSize: theme.fontSize.tab,
      margin: 0,
      padding: 0,
      lineHeight: 1,
      paddingTop: 7,
      paddingBottom: 6,
    },
    '& .panelTitleSuppliment': {
      margin: 0,
      padding: 0,
      lineHeight: 1,
      paddingTop: '.5em',
      paddingBottom: '.5em',
      fontSize: theme.fontSize.data,
      marginLeft: '1.1em'
    }
  },
  panelTitleCont: {
    display: 'flex',
    alignItems: 'center',
    flex: 1
  },
  noPaddingRight: {
    paddingRight: 0
  },
  draggable: {
    cursor: 'move'
  }
}));

function SinglePanelHeader({
  className,
  children,
  title,
  titleSuppliment,
  titleSupplimentColor,
  align,
  noPaddingRight,
}) {
  const classes = useStyles();

  const renderTitle = () => {
    return (
      <div className={classes.panelTitleCont}>
        <Typography variant="h3" color="textPrimary" className="panelTitle" align={align}>{title}</Typography>
        {!!(titleSuppliment) && React.isValidElement(titleSuppliment) ? (
          titleSuppliment
        ) : (
          <Typography variant="h4" className="panelTitleSuppliment" color="textSecondary" style={{ color: titleSupplimentColor }}>{titleSuppliment}</Typography>
        )}
      </div>
    );
  };

  return (
    <Box className={clsx(classes.root, className, noPaddingRight && classes.noPaddingRight)}>
      {renderTitle()}
      {children}
    </Box>
  );
}


SinglePanelHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.element,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  titleSuppliment: PropTypes.string,
  titleSupplimentColor: PropTypes.string,
  align: PropTypes.string,
  noPaddingRight: PropTypes.bool
};


SinglePanelHeader.defaultProps = {
  titleSupplimentColor: '#fff',
  align: 'left',
  noPaddingRight: false
};


export default SinglePanelHeader;

