import {
  Box,
  Button,
  Typography
} from '@material-ui/core';
import React from 'react';
import { useFormDispatch, useFormSelector } from 'src/app/slicedForm/shared/context/FormProvider';
import {
  COPY_PROFILE,
  generateId,
  selectActiveProfile
} from 'src/app/slicedForm/shared/reducers/profileReducer';


function ModalContentCopyPredefined({
  className,
  description = 'You cannot edit a predefined profile. Make an editable copy?',
  showCopyButton,
  onClose
}) {
  const { name, id } = useFormSelector(selectActiveProfile) || {};
  const dispatch = useFormDispatch();

  const handleCopy = () => {
    dispatch({
      type: COPY_PROFILE,
      payload: {
        id,
        name,
        newId: generateId()
      }
    });
    onClose();
  }

  return (
    <Box
      className={className}
      textAlign="center"
      p={2}
    >
      <Typography variant="body1" gutterBottom>
        {description}
      </Typography>
      <Box
        display="flex"
        alignItems="space-between"
        justifyContent="center"
        mt={3}
      >
        {Boolean(showCopyButton) && (
          <Box ml={1} mr={1}>
            <Button
              onClick={handleCopy}
              color="primary"
              variant="contained"
              size="small"
            >
              Copy {name}
            </Button>
          </Box>
        )}
        <Box ml={1} mr={1}>
          <Button
            onClick={onClose}
            color="primary"
            variant="outlined"
            size="small"
          >
            Close
          </Button>
        </Box>
      </Box>
    </Box>
  );
}


export default ModalContentCopyPredefined;
