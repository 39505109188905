/* eslint-disable react/no-unknown-property */
import React from 'react';
import clsx from 'clsx'
import { SvgIcon } from '@material-ui/core';
import { makeStyles } from '@material-ui/styles';

const useStyles = makeStyles({
  imageIcon: {
    height: 'auto',
    display: 'block'
  },
  iconRoot: {
    marginBottom: 5,
  },
  edgeIconRoot: {
    textAlign: 'center',
    display: 'block',
    margin: 'auto',
  },
  noMargin: {
    marginBottom: 0,
  }
});



export function ChatQuestionMarkIcon({ className, width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: clsx(classes.edgeIconRoot, className) }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M4.856 5.591c-.591.581-.966 1.443-.966 2.561v5.338c0 1.122.37 1.983.956 2.562.587.58 1.468.955 2.634.955a.93.93 0 0 1 .929.881l.096 1.816 3.889-2.545a.931.931 0 0 1 .51-.152h3.615c1.15 0 2.033-.373 2.625-.956.59-.581.965-1.443.965-2.561V8.152c0-1.118-.375-1.98-.965-2.56-.592-.584-1.476-.957-2.625-.957H7.48c-1.148 0-2.031.373-2.623.956Zm2.977 14.484.005-.001h-.005Zm1.46 1.33-.012.005.012-.005Zm.2-.123-.046.037c-.057.044-.129.09-.193.13a4.35 4.35 0 0 1-.565.294c-.185.079-.538.213-.91.185a1.165 1.165 0 0 1-.768-.358 1.19 1.19 0 0 1-.314-.8l-.103-1.959c-1.206-.158-2.258-.646-3.056-1.435-.99-.977-1.509-2.34-1.509-3.886V8.152c0-1.55.53-2.912 1.521-3.887.99-.974 2.367-1.49 3.93-1.49h9.04c1.562 0 2.94.516 3.929 1.49.991.975 1.52 2.337 1.52 3.887v5.338c0 1.55-.529 2.912-1.52 3.888-.99.974-2.367 1.49-3.93 1.49h-3.338l-3.687 2.414Z" />
      <path d="M11.118 12.482v-.123c.003-.42.04-.756.112-1.006.075-.25.183-.452.325-.605.141-.154.312-.294.511-.419a3.41 3.41 0 0 0 .4-.299c.119-.103.212-.218.282-.343a.872.872 0 0 0 .105-.425.797.797 0 0 0-.12-.436.792.792 0 0 0-.32-.289.956.956 0 0 0-.44-.1.978.978 0 0 0-.444.104.847.847 0 0 0-.342.303.929.929 0 0 0-.144.494H9.57c.012-.481.128-.878.346-1.19.22-.315.509-.55.87-.703a2.97 2.97 0 0 1 1.193-.235c.478 0 .902.08 1.27.238.367.156.655.384.865.682.209.298.313.657.313 1.078 0 .281-.046.531-.14.75-.092.216-.22.409-.386.577a2.99 2.99 0 0 1-.588.45 2.42 2.42 0 0 0-.469.354 1.2 1.2 0 0 0-.27.425c-.058.161-.088.36-.09.595v.123h-1.367Zm.714 2.308a.847.847 0 0 1-.616-.253.83.83 0 0 1-.25-.613.814.814 0 0 1 .25-.605.846.846 0 0 1 .616-.253c.228 0 .43.084.602.252a.828.828 0 0 1 .137 1.043.931.931 0 0 1-.313.313.82.82 0 0 1-.426.116Z" />
    </SvgIcon>
  )
}

export function QuestionMark({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path d="M10.175 15.067v-.253c.005-.871.082-1.565.23-2.083.155-.517.379-.935.673-1.254a5.142 5.142 0 0 1 1.06-.865c.308-.2.584-.406.828-.62.244-.214.438-.45.582-.709.144-.264.216-.557.216-.88 0-.344-.082-.645-.246-.904a1.64 1.64 0 0 0-.664-.597 1.978 1.978 0 0 0-.91-.209c-.324 0-.63.072-.919.217-.288.14-.525.348-.709.627-.184.273-.283.614-.298 1.022H6.973c.025-.995.263-1.816.716-2.463a4.104 4.104 0 0 1 1.799-1.455c.746-.324 1.57-.485 2.47-.485.99 0 1.866.164 2.627.492.762.324 1.359.794 1.792 1.411.433.617.649 1.36.649 2.232 0 .582-.097 1.1-.291 1.552-.19.448-.455.846-.799 1.194a6.183 6.183 0 0 1-1.216.933 4.94 4.94 0 0 0-.97.731 2.487 2.487 0 0 0-.56.881c-.12.333-.182.744-.187 1.232v.253h-2.829Zm1.477 4.777c-.497 0-.923-.174-1.276-.522a1.717 1.717 0 0 1-.515-1.27 1.684 1.684 0 0 1 .515-1.253 1.752 1.752 0 0 1 1.276-.523c.473 0 .888.175 1.247.523.358.348.54.766.545 1.254-.006.328-.093.63-.262.903a1.93 1.93 0 0 1-.65.65 1.701 1.701 0 0 1-.88.238Z" />
    </SvgIcon>
  )
}

export function HelpOutlineIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path d="M11.116 13.486v-.123c.002-.421.04-.758.112-1.008a1.52 1.52 0 0 1 .325-.608c.143-.154.314-.294.514-.42a3.27 3.27 0 0 0 .401-.3c.119-.103.213-.218.283-.343a.874.874 0 0 0 .104-.427.799.799 0 0 0-.119-.438.794.794 0 0 0-.322-.289.958.958 0 0 0-.441-.101.981.981 0 0 0-.445.105.849.849 0 0 0-.343.303.932.932 0 0 0-.145.496H9.564c.013-.482.128-.88.348-1.194.219-.315.51-.55.871-.705a2.99 2.99 0 0 1 1.197-.235c.48 0 .904.08 1.273.239.37.157.658.384.868.683.21.3.315.66.315 1.082 0 .282-.047.533-.141.752-.092.217-.22.41-.387.579a2.998 2.998 0 0 1-.59.452 2.39 2.39 0 0 0-.47.354c-.12.123-.21.265-.271.427a1.82 1.82 0 0 0-.09.596v.123h-1.371Zm.716 2.315a.849.849 0 0 1-.619-.253.832.832 0 0 1-.249-.615.816.816 0 0 1 .25-.608.849.849 0 0 1 .618-.253c.229 0 .43.085.604.253a.83.83 0 0 1 .137 1.046.936.936 0 0 1-.314.314.823.823 0 0 1-.427.116Z" />
      <path fillRule="evenodd" d="M12 6a6 6 0 1 0 0 12 6 6 0 0 0 0-12Zm-8 6a8 8 0 1 1 16 0 8 8 0 0 1-16 0Z" />
    </SvgIcon>
  )
}

export function AddIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M8 12a1 1 0 011-1h6a1 1 0 110 2H9a1 1 0 01-1-1z" />
      <path fillRule="evenodd" d="M12 8a1 1 0 011 1v6a1 1 0 11-2 0V9a1 1 0 011-1z" />
      <path fillRule="evenodd" d="M12 6a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1116 0 8 8 0 01-16 0z" />
    </SvgIcon>
  );
}


export function AddIcon2({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M8 12C8 11.4477 8.44772 11 9 11L15 11C15.5523 11 16 11.4477 16 12C16 12.5523 15.5523 13 15 13L9 13C8.44772 13 8 12.5523 8 12Z" />
      <path fillRule="evenodd" clipRule="evenodd" d="M12 8C12.5523 8 13 8.44772 13 9L13 15C13 15.5523 12.5523 16 12 16C11.4477 16 11 15.5523 11 15L11 9C11 8.44772 11.4477 8 12 8Z" />
    </SvgIcon>
  );
}


export function BlockIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M12 18a6 6 0 1 0 0-12 6 6 0 0 0 0 12Zm0 2a8 8 0 1 0 0-16 8 8 0 0 0 0 16Z" />
      <path d="m6.344 16.209 9.9-9.9 1.413 1.414-9.899 9.9-1.414-1.414Z" />
    </SvgIcon>
  );
}


export function LayoutIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path d="M6 6H11.0769V11.0769H6V6Z" />
      <path d="M6 12.9231H11.0769V18H6V12.9231Z" />
      <path d="M12.9229 6H17.9998V11.0769H12.9229V6Z" />
      <path d="M12.9229 12.9231H17.9998V18H12.9229V12.9231Z" />
    </SvgIcon>
  );
}


export function LayoutIcon2({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path d="M6 6H11V18H6V6Z" />
      <path d="M12.9229 6H17.9998V11.0769H12.9229V6Z" />
      <path d="M12.9229 12.9231H17.9998V18H12.9229V12.9231Z" />
    </SvgIcon>

  );
}


export function AlertOnIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path d="M9 4C9.55228 4 10 4.44772 10 5C10 5.55228 9.55228 6 9 6C8.54597 6 7.79461 6.18021 7.16896 6.74329C6.57534 7.27755 6 8.24198 6 10C6 10.5523 5.55228 11 5 11C4.44772 11 4 10.5523 4 10C4 7.75802 4.75799 6.22245 5.83104 5.25671C6.87206 4.31979 8.1207 4 9 4Z" />
      <path fillRule="evenodd" clipRule="evenodd"
        d="M13 5C13 4.44772 12.5523 4 12 4C11.4477 4 11 4.44772 11 5V6.1221C10.2974 6.28811 9.51902 6.63773 8.83127 7.25671C7.7785 8.2042 7.029 9.70019 7.00105 11.8737L6.21946 15H6C5.44772 15 5 15.4477 5 16C5 16.5523 5.44772 17 6 17H6.97932C6.99381 17.0003 7.00826 17.0003 7.02267 17H16.9778C16.9922 17.0003 17.0067 17.0003 17.0212 17H18C18.5523 17 19 16.5523 19 16C19 15.4477 18.5523 15 18 15H17.781L16.9994 11.8737C16.9715 9.70019 16.222 8.2042 15.1692 7.25671C14.4813 6.6376 13.7027 6.28795 13 6.12199V5ZM15.7195 15L15.0301 12.2425C15.0103 12.1632 15.0002 12.0818 15.0002 12C15.0002 10.242 14.4249 9.27755 13.8313 8.74329C13.2056 8.18021 12.4543 8 12.0002 8C11.5462 8 10.7948 8.18021 10.1692 8.74329C9.57558 9.27755 9.00024 10.242 9.00024 12C9.00024 12.0818 8.99021 12.1632 8.97038 12.2425L8.28101 15H15.7195Z" />
      <path d="M11 18C10.4477 18 10 18.4477 10 19C10 19.5523 10.4477 20 11 20H13C13.5523 20 14 19.5523 14 19C14 18.4477 13.5523 18 13 18H11Z" />
      <path d="M14 5C14 4.44772 14.4477 4 15 4C15.8793 4 17.1279 4.31979 18.169 5.25671C19.242 6.22245 20 7.75802 20 10C20 10.5523 19.5523 11 19 11C18.4477 11 18 10.5523 18 10C18 8.24198 17.4247 7.27755 16.831 6.74329C16.2054 6.18021 15.454 6 15 6C14.4477 6 14 5.55228 14 5Z" />
    </SvgIcon>
  );
}


export function AlertOffIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M5.70703 4.29288C5.31641 3.90237 4.68359 3.90237 4.29297 4.29288C3.90234 4.6835 3.90234 5.31656 4.29297 5.70718L7.58008 8.99417C7.22852 9.78006 7.01562 10.7337 7.00098 11.8737L6.21875 15H6C5.44727 15 5 15.4478 5 16C5 16.5523 5.44727 17 6 17H15.5859L18.293 19.7072C18.6836 20.0977 19.3164 20.0977 19.707 19.7072C20.0977 19.3166 20.0977 18.6835 19.707 18.2929L5.70703 4.29288ZM9 12C9 11.4479 9.05664 10.9742 9.15332 10.5673L13.5859 15H8.28125L8.96973 12.2426C8.99023 12.1632 9 12.0818 9 12Z" />
      <path
        d="M13 5.00003C13 4.44778 12.5527 4.00003 12 4.00003C11.4473 4.00003 11 4.44778 11 5.00003V6.12198C10.6533 6.20377 10.2891 6.33023 9.92578 6.51163L11.4785 8.064C11.6738 8.01907 11.8525 8.00003 12 8.00003C12.4541 8.00003 13.2051 8.18021 13.8311 8.74332C14.375 9.2327 14.9033 10.0832 14.9883 11.574L17.5693 14.1554L16.999 11.8737C16.9717 9.70023 16.2217 8.20425 15.1689 7.25674C14.4814 6.63773 13.7021 6.28812 13 6.1221V5.00003Z" />
      <path d="M11 18C10.4473 18 10 18.4478 10 19C10 19.5523 10.4473 20 11 20H13C13.5527 20 14 19.5523 14 19C14 18.4478 13.5527 18 13 18H11Z" />
    </SvgIcon>
  );
}


export function AlertSettingsIconSmall({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 16 14">
      <path fillRule="evenodd" d="M4.707 10.707a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 1.414L2.414 7l2.293 2.293a1 1 0 010 1.414zM11.293 10.707a1 1 0 001.414 0l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L13.586 7l-2.293 2.293a1 1 0 000 1.414zM9.164.014a1 1 0 01.822 1.15l-2 12a1 1 0 01-1.972-.328l2-12a1 1 0 011.15-.822z" />
    </SvgIcon>
  );
}


export function BullhornIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 16 16">
      <path fillRule="evenodd"
        d="M3.426.77C2.74.257 1.888.254 1.236.547.578.844 0 1.506 0 2.414v11.172c0 1.85 2.15 2.572 3.398 1.522C4.444 14.227 5.695 13.41 7 13.116V14a1 1 0 00.293.707l1 1A1 1 0 009 16h3a1 1 0 001-1v-2.156a3.91 3.91 0 001.568-.923C15.423 11.101 16 9.821 16 8c0-1.822-.577-3.1-1.432-3.921-.83-.797-1.837-1.08-2.568-1.08H8c-.282 0-.906-.152-1.79-.559A15.985 15.985 0 013.425.77zM2 2.413v-.002a.149.149 0 01.057-.04.232.232 0 01.11-.02c.03.001.05.01.067.023a17.98 17.98 0 003.14 1.883C6.317 4.69 7.268 5 8 5h1v6H8c-2.4 0-4.501 1.407-5.89 2.578a.085.085 0 01-.047.022.103.103 0 01-.063-.013V2.414zM11 11V5h1c.27 0 .762.118 1.182.521.395.38.818 1.1.818 2.479 0 1.378-.423 2.1-.818 2.479-.42.403-.913.52-1.182.52h-1zm-2 2.586V13h2v1H9.414L9 13.586z" />
    </SvgIcon>
  );
}


export function CodeIconSmall({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} viewBox="0 0 14 16" style={width && { fontSize: width }}>
      <path fillRule="evenodd"
        d="M8 1a1 1 0 00-2 0v1.122a4.95 4.95 0 00-2.169 1.135c-1.053.947-1.802 2.443-1.83 4.617L1.219 11H1a1 1 0 100 2H13a1 1 0 100-2h-.22L12 7.874c-.029-2.174-.778-3.67-1.831-4.617A4.95 4.95 0 008 2.122V1zm2.72 10l-.69-2.757A.997.997 0 0110 8c0-1.758-.575-2.722-1.169-3.257C8.205 4.18 7.454 4 7 4c-.454 0-1.205.18-1.831.743C4.575 5.278 4 6.242 4 8a1 1 0 01-.03.243L3.28 11h7.44z" />
      <path d="M6 14a1 1 0 100 2h2a1 1 0 100-2H6z" />
    </SvgIcon>
  );
}

export function AlertsIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} viewBox="0 0 24 24" style={width && { fontSize: width }}>
      <path fillRule="evenodd"
        d="M13 5a1 1 0 10-2 0v1.122a4.95 4.95 0 00-2.169 1.135c-1.053.947-1.802 2.443-1.83 4.617L6.219 15H6a1 1 0 100 2H18a1 1 0 100-2h-.22L17 11.874c-.029-2.174-.778-3.67-1.831-4.617A4.95 4.95 0 0013 6.122V5zm2.72 10l-.69-2.758A.996.996 0 0115 12c0-1.758-.575-2.722-1.169-3.257C13.205 8.18 12.454 8 12 8c-.454 0-1.205.18-1.831.743C9.575 9.278 9 10.242 9 12a1 1 0 01-.03.242L8.28 15h7.44z" />
      <path d="M11 18a1 1 0 100 2h2a1 1 0 100-2h-2z" />
    </SvgIcon>
  );
}

export function CodesIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} viewBox="0 0 24 24" style={width && { fontSize: width }}>
      <path fillRule="evenodd" d="M8.707 15.707a1 1 0 01-1.414 0l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 1.414L6.414 12l2.293 2.293a1 1 0 010 1.414zM15.293 15.707a1 1 0 001.414 0l3-3a1 1 0 000-1.414l-3-3a1 1 0 10-1.414 1.414L17.586 12l-2.293 2.293a1 1 0 000 1.414zM13.164 5.014a1 1 0 01.822 1.15l-2 12a1 1 0 01-1.972-.328l2-12a1 1 0 011.15-.822z" />
    </SvgIcon>
  );
}

export function NewsSourceIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} viewBox="0 0 24 24" style={width && { fontSize: width }}>
      <path fillRule="evenodd"
        d="M7.426 4.77c-.687-.512-1.538-.515-2.19-.222C4.578 4.844 4 5.506 4 6.414v11.172c0 1.85 2.15 2.572 3.398 1.522 1.046-.881 2.297-1.698 3.602-1.992V18a1 1 0 00.293.707l1 1A1 1 0 0013 20h3a1 1 0 001-1v-2.156a3.91 3.91 0 001.567-.923C19.424 15.101 20 13.821 20 12c0-1.821-.577-3.1-1.433-3.921C17.738 7.282 16.732 7 16 7h-4c-.282 0-.906-.153-1.79-.56a15.983 15.983 0 01-2.784-1.67zM6 6.413v-.002a.149.149 0 01.057-.04.232.232 0 01.11-.02c.03.002.05.01.067.023a17.98 17.98 0 003.14 1.883C10.317 8.69 11.268 9 12 9h1v6h-1c-2.4 0-4.501 1.407-5.89 2.578a.086.086 0 01-.047.022.102.102 0 01-.063-.013V6.414zM15 15V9h1c.27 0 .762.118 1.183.521.394.38.817 1.1.817 2.479s-.423 2.1-.817 2.479c-.42.403-.914.521-1.183.521h-1zm-2 2.586V17h2v1h-1.586L13 17.586z" />
    </SvgIcon>
  );
}

export function NewsSourceIconSmall({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} viewBox="0 0 16 16" style={width && { fontSize: width }}>
      <path fillRule="evenodd"
        d="M3.426.77C2.74.257 1.888.254 1.236.547.578.844 0 1.506 0 2.414v11.172c0 1.85 2.15 2.572 3.398 1.522C4.444 14.227 5.695 13.41 7 13.116V14a1 1 0 00.293.707l1 1A1 1 0 009 16h3a1 1 0 001-1v-2.156a3.91 3.91 0 001.568-.923C15.423 11.101 16 9.821 16 8c0-1.822-.577-3.1-1.432-3.921-.83-.797-1.837-1.08-2.568-1.08H8c-.282 0-.906-.152-1.79-.559A15.985 15.985 0 013.425.77zM2 2.413v-.002a.149.149 0 01.057-.04.232.232 0 01.11-.02c.03.001.05.01.067.023a17.98 17.98 0 003.14 1.883C6.317 4.69 7.268 5 8 5h1v6H8c-2.4 0-4.501 1.407-5.89 2.578a.085.085 0 01-.047.022.103.103 0 01-.063-.013V2.414zM11 11V5h1c.27 0 .762.118 1.182.521.395.38.818 1.1.818 2.479 0 1.378-.423 2.1-.818 2.479-.42.403-.913.52-1.182.52h-1zm-2 2.586V13h2v1H9.414L9 13.586z" />
    </SvgIcon>

  );
}

export function BookIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd"
        d="M4 5a1 1 0 011-1h5v1l.001-1h.044l.069.004a3.534 3.534 0 01.838.18c.312.108.672.278 1.048.54a4.251 4.251 0 011.048-.54c.253-.087.47-.133.634-.157a2.642 2.642 0 01.273-.026h.026L13.99 4H14L14 5V4h5a1 1 0 011 1v10.37a1 1 0 01-1 1h-3.5c-1.306 0-2.435 1.104-2.5 2.393.003.09.003.182 0 .273a1 1 0 01-2 0 4.292 4.292 0 010-.273c-.065-1.289-1.194-2.393-2.5-2.393H5a1 1 0 01-1-1V5zm9 10.152a4.425 4.425 0 012.5-.782h2.499L18 6h-3.98a1.538 1.538 0 00-.318.075c-.181.062-.43.179-.702.399v8.678zm-2-8.678a2.338 2.338 0 00-.702-.4A1.538 1.538 0 009.981 6H6v8.37h2.5c.919 0 1.781.29 2.5.782V6.474z" />
    </SvgIcon>
  );
}


export function Bookmark2Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M8 6a1 1 0 00-1 1v8h8.5a1 1 0 001-1V7a1 1 0 00-1-1v6a1 1 0 01-1.707.707L12.5 11.414l-1.293 1.293A1 1 0 019.5 12V6H8zm3.5 0v3.586l.293-.293a1 1 0 011.414 0l.293.293V6h-2zM7 17h8.5a3 3 0 003-3V7a3 3 0 00-3-3H8a3 3 0 00-3 3v10a3 3 0 003 3h6.5a1 1 0 100-2H8a1 1 0 01-1-1z" />
    </SvgIcon>
  );
}


export function BookmarkIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M5 8a1 1 0 011-1h9a1 1 0 011 1v11a1 1 0 01-1.7.715l-3.8-3.716-3.8 3.716A1 1 0 015 19V8zm2 1v7.624l2.8-2.739a1 1 0 011.4 0l2.8 2.739V9H7z" />
      <path fillRule="evenodd" d="M8 5a1 1 0 011-1h9a1 1 0 011 1v11a1 1 0 01-1.707.707l-3-3a1 1 0 011.414-1.414L17 13.586V6h-7v2a1 1 0 01-2 0V5z" />
    </SvgIcon>
  );
}


export function CPUIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M6 7a1 1 0 011-1h10a1 1 0 011 1v10a1 1 0 01-1 1H7a1 1 0 01-1-1V7zm2 1v8h8V8H8z" />
      <path fillRule="evenodd" d="M9 10a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 01-1 1h-4a1 1 0 01-1-1v-4zm2 1v2h2v-2h-2zM19 11h-2V9h2a1 1 0 110 2zM15 5v2h-2V5a1 1 0 112 0zM9 19v-2h2v2a1 1 0 11-2 0zM11 5v2H9V5a1 1 0 012 0zM13 19v-2h2v2a1 1 0 11-2 0zM5 9h2v2H5a1 1 0 110-2zM5 13h2v2H5a1 1 0 110-2zM19 15h-2v-2h2a1 1 0 110 2z" />
    </SvgIcon>
  );
}


export function Calendar2Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M18 8H6v10h12V8zM6 6a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2H6z" />
      <path fillRule="evenodd" d="M8 4a1 1 0 011 1v1a1 1 0 01-2 0V5a1 1 0 011-1zM16 4a1 1 0 011 1v1a1 1 0 11-2 0V5a1 1 0 011-1z" />
      <path d="M7 10h2v2H7v-2zM15 14h2v2h-2v-2zM11 14h2v2h-2v-2z" />
      <path d="M11 14h2v2h-2v-2zM7 14h2v2H7v-2zM15 10h2v2h-2v-2zM11 10h2v2h-2v-2z" />
    </SvgIcon>
  );
}


export function Calendar3Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M18 8H6v10h12V8zM6 6a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2H6z" />
      <path d="M18 11H6v7h12v-7z" />
      <path fillRule="evenodd" d="M16 4a1 1 0 011 1v4a1 1 0 11-2 0V5a1 1 0 011-1zM12 4a1 1 0 011 1v4a1 1 0 11-2 0V5a1 1 0 011-1zM8 4a1 1 0 011 1v4a1 1 0 01-2 0V5a1 1 0 011-1z" />
    </SvgIcon>
  );
}


export function Calendar4Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M18 8H6v10h12V8zM6 6a2 2 0 00-2 2v10a2 2 0 002 2h12a2 2 0 002-2V8a2 2 0 00-2-2H6z" />
      <path fillRule="evenodd" d="M16 4a1 1 0 011 1v4a1 1 0 11-2 0V5a1 1 0 011-1zM12 4a1 1 0 011 1v4a1 1 0 11-2 0V5a1 1 0 011-1zM8 4a1 1 0 011 1v4a1 1 0 01-2 0V5a1 1 0 011-1zM6 11h12v7h-4v-2h-2v2h-1v-2H9v2H8v-2H6v-1h2v-2H6v-2zm8 2h-2v2h2v-2zm1 0h2v2h-2v-2zm-6 0h2v2H9v-2z" />
    </SvgIcon>
  );
}


export function Calendar5Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M18 13H6v4a1 1 0 001 1h10a1 1 0 001-1v-4zM4 11v6a3 3 0 003 3h10a3 3 0 003-3v-6H4zM7 8a1 1 0 00-1 1v2H4V9a3 3 0 013-3h10a3 3 0 013 3v2h-2V9a1 1 0 00-1-1H7z" />
      <path fillRule="evenodd" d="M8 4a1 1 0 011 1v4a1 1 0 11-2 0V5a1 1 0 011-1zM12 4a1 1 0 011 1v4a1 1 0 11-2 0V5a1 1 0 011-1zM16 4a1 1 0 011 1v4a1 1 0 11-2 0V5a1 1 0 011-1z" />
      <path d="M8 14.5a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zM8 16.5a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zM11 14.5a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zM11 16.5a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zM14 14.5a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5zM14 16.5a.5.5 0 01.5-.5h1a.5.5 0 010 1h-1a.5.5 0 01-.5-.5z" />
    </SvgIcon>
  );
}


export function Calendar6Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M17 8H7a1 1 0 00-1 1v8a1 1 0 001 1h10a1 1 0 001-1V9a1 1 0 00-1-1zM7 6a3 3 0 00-3 3v8a3 3 0 003 3h10a3 3 0 003-3V9a3 3 0 00-3-3H7zM16 4a1 1 0 011 1v1h-2V5a1 1 0 011-1zM8 4a1 1 0 011 1v1H7V5a1 1 0 011-1z" />
      <path fillRule="evenodd" d="M18 12H6v-2h12v2z" />
      <path d="M13 14a1 1 0 011-1h2a1 1 0 011 1v2a1 1 0 01-1 1h-2a1 1 0 01-1-1v-2z" />
    </SvgIcon>
  );
}


export function CalendarIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M4 7a1 1 0 011-1h14a1 1 0 011 1v12a1 1 0 01-1 1H5a1 1 0 01-1-1V7zm2 1v10h12V8H6z" />
      <path fillRule="evenodd" d="M16 4a1 1 0 011 1v2a1 1 0 11-2 0V5a1 1 0 011-1z" />
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8 7V5" />
      <path fillRule="evenodd" d="M4 7a1 1 0 011-1h14a1 1 0 011 1v5a1 1 0 01-1 1H5a1 1 0 01-1-1V7zm2 1v3h12V8H6z" />
    </SvgIcon>
  );
}


export function ChangeViewIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v14a1 1 0 01-1 1H5a1 1 0 01-1-1V5zm2 1v12h12V6H6z" />
      <path fillRule="evenodd" d="M8 9a1 1 0 011-1h6a1 1 0 110 2H9a1 1 0 01-1-1zM8 12a1 1 0 011-1h6a1 1 0 110 2H9a1 1 0 01-1-1zM8 15a1 1 0 011-1h6a1 1 0 110 2H9a1 1 0 01-1-1z" />
    </SvgIcon>
  );
}


export function ChatIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M10.5 5a4.5 4.5 0 00-3.899 6.75 1 1 0 01.1.758l-.288 1.079 1.079-.289a1 1 0 01.759.1A4.5 4.5 0 1010.5 5zM4 9.5a6.5 6.5 0 113.627 5.832l-2.369.634a1 1 0 01-1.224-1.224l.634-2.37A6.477 6.477 0 014 9.5z" />
      <path fillRule="evenodd" d="M15.946 8.722A4.496 4.496 0 0118 12.5c0 .821-.219 1.588-.601 2.25a1 1 0 00-.1.758l.288 1.079-1.078-.289a1 1 0 00-.76.1A4.476 4.476 0 0113.5 17a4.496 4.496 0 01-3.778-2.055 5.464 5.464 0 01-1.972-.68l-.47.125a6.503 6.503 0 009.093 3.942l2.369.634a1 1 0 001.224-1.225l-.634-2.368a6.502 6.502 0 00-4.476-9.23 5.473 5.473 0 011.09 2.579z" />
      <path fillRule="evenodd" d="M8 11a1 1 0 011-1h3a1 1 0 110 2H9a1 1 0 01-1-1zM8 8a1 1 0 011-1h3a1 1 0 110 2H9a1 1 0 01-1-1z" />
    </SvgIcon>
  );
}



export function ComputerIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M18 4a2 2 0 012 2v12a2 2 0 01-2 2h-3a2 2 0 01-2-2V6a2 2 0 012-2h3zm0 14V6h-3v12h3zM4 8a2 2 0 012-2h5a1 1 0 110 2H6v7h5a1 1 0 110 2H6a2 2 0 01-2-2V8z" />
      <path fillRule="evenodd" d="M9 16a1 1 0 011 1v1h1a1 1 0 110 2H7a1 1 0 110-2h1v-1a1 1 0 011-1z" />
      <path d="M15 12h3v2h-3v-2zM15 8h3v2h-3V8z" />
    </SvgIcon>
  );
}


export function ConfirmIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M16.707 9.293a1 1 0 010 1.414l-5 5a1 1 0 01-1.414 0l-2-2a1 1 0 111.414-1.414L11 13.586l4.293-4.293a1 1 0 011.414 0z" />
    </SvgIcon>
  );
}


export function CopyIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M5 4a1 1 0 00-1 1v10a1 1 0 001 1h4v3a1 1 0 001 1h9a1 1 0 001-1V9a1 1 0 00-1-1h-4V5a1 1 0 00-1-1H5zm8 2H6v8h7V6zm-2 10h3a1 1 0 001-1v-5h3v8h-7v-2z" />
    </SvgIcon>
  );
}


export function CurrencyIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd"
        d="M10.997 17.018A.056.056 0 0011 17c0-.002 0-.007-.003-.018a.336.336 0 00-.04-.078 1.315 1.315 0 00-.384-.361C10.13 16.248 9.403 16 8.5 16s-1.63.248-2.073.543a1.314 1.314 0 00-.385.36.337.337 0 00-.039.08C6 16.992 6 16.997 6 17c0 .002 0 .007.003.018a.337.337 0 00.04.078c.052.084.167.216.384.361.443.295 1.17.543 2.073.543s1.63-.248 2.073-.543c.217-.145.332-.277.385-.36a.336.336 0 00.039-.08zM8.5 20c2.485 0 4.5-1.343 4.5-3 0-.59-.255-1.14-.696-1.604-.798-.839-2.204.104-3.804.104-1.62 0-3.04-.93-3.832-.073C4.244 15.884 4 16.423 4 17c0 1.657 2.015 3 4.5 3z" />
      <path fillRule="evenodd"
        d="M10.997 14.018C11 14.007 11 14.002 11 14c0-.002 0-.007-.003-.018a.336.336 0 00-.04-.078 1.316 1.316 0 00-.384-.361C10.13 13.248 9.403 13 8.5 13s-1.63.248-2.073.543a1.315 1.315 0 00-.385.36.337.337 0 00-.039.08C6 13.992 6 13.997 6 14c0 .002 0 .007.003.018a.337.337 0 00.04.078c.052.084.167.216.384.361.443.295 1.17.543 2.073.543s1.63-.248 2.073-.543c.217-.145.332-.277.385-.36a.336.336 0 00.039-.08zM8.5 17c2.485 0 4.5-1.343 4.5-3 0-.626-.287-1.207-.779-1.688-.81-.792-2.174.188-3.721.188-1.477 0-2.787-1.026-3.608-.293-.56.5-.892 1.12-.892 1.793 0 1.657 2.015 3 4.5 3z" />
      <path fillRule="evenodd"
        d="M10.997 11.018C11 11.007 11 11.002 11 11c0-.002 0-.007-.003-.018a.336.336 0 00-.04-.078 1.315 1.315 0 00-.384-.361C10.13 10.248 9.403 10 8.5 10s-1.63.248-2.073.543a1.314 1.314 0 00-.385.36.337.337 0 00-.039.08C6 10.992 6 10.997 6 11c0 .002 0 .007.003.018a.337.337 0 00.04.078c.052.084.167.216.384.361.443.295 1.17.543 2.073.543s1.63-.248 2.073-.543c.217-.145.332-.277.385-.36a.336.336 0 00.039-.08zM8.5 14c2.485 0 4.5-1.343 4.5-3s-2.015-3-4.5-3S4 9.343 4 11s2.015 3 4.5 3z" />
      <path fillRule="evenodd"
        d="M17.997 13.018C18 13.007 18 13.002 18 13c0-.002 0-.007-.003-.018a.336.336 0 00-.04-.078 1.315 1.315 0 00-.384-.361C17.13 12.248 16.403 12 15.5 12s-1.63.248-2.073.543a1.315 1.315 0 00-.385.36.336.336 0 00-.039.08C13 12.992 13 12.997 13 13c0 .002 0 .007.003.018a.336.336 0 00.04.078c.052.084.167.216.384.361.443.295 1.17.543 2.073.543s1.63-.248 2.073-.543c.217-.145.332-.277.385-.36a.336.336 0 00.039-.08zM15.5 16c2.485 0 4.5-1.343 4.5-3 0-.59-.255-1.14-.696-1.604-.798-.839-2.203.104-3.804.104-1.62 0-3.04-.93-3.832-.073-.424.457-.668.996-.668 1.573 0 1.657 2.015 3 4.5 3z" />
      <path fillRule="evenodd"
        d="M17.997 10.018C18 10.007 18 10.002 18 10c0-.002 0-.007-.003-.018a.336.336 0 00-.04-.078 1.316 1.316 0 00-.384-.361C17.13 9.248 16.403 9 15.5 9s-1.63.248-2.073.543a1.316 1.316 0 00-.385.36.336.336 0 00-.039.08C13 9.992 13 9.997 13 10c0 .002 0 .007.003.018a.336.336 0 00.04.078c.052.084.167.216.384.361.443.295 1.17.543 2.073.543s1.63-.248 2.073-.543c.217-.145.332-.277.385-.36a.336.336 0 00.039-.08zM15.5 13c2.485 0 4.5-1.343 4.5-3 0-.626-.287-1.207-.779-1.688-.81-.792-2.174.188-3.721.188-1.477 0-2.787-1.026-3.608-.293-.56.5-.892 1.12-.892 1.793 0 1.657 2.015 3 4.5 3z" />
      <path fillRule="evenodd"
        d="M17.997 7.018C18 7.007 18 7.002 18 7c0-.002 0-.007-.003-.018a.336.336 0 00-.04-.078 1.316 1.316 0 00-.384-.361C17.13 6.248 16.403 6 15.5 6s-1.63.248-2.073.543a1.316 1.316 0 00-.385.36.336.336 0 00-.039.08C13 6.992 13 6.997 13 7c0 .002 0 .007.003.018a.336.336 0 00.04.078c.052.084.167.216.384.361.443.295 1.17.543 2.073.543s1.63-.248 2.073-.543c.217-.145.332-.277.385-.36a.336.336 0 00.039-.08zM15.5 10c2.485 0 4.5-1.343 4.5-3s-2.015-3-4.5-3S11 5.343 11 7s2.015 3 4.5 3z" />
    </SvgIcon>
  );
}


export function DarkIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd"
        d="M9.77 7.914a1 1 0 00-1.33-.857A6.663 6.663 0 0010.662 20a6.663 6.663 0 006.282-4.44 1 1 0 00-.857-1.33h-.005l-.026-.003a12.939 12.939 0 01-.526-.065 15.111 15.111 0 01-1.376-.265c-1.12-.272-2.213-.694-2.785-1.265-.571-.572-.993-1.665-1.265-2.785a15.116 15.116 0 01-.33-1.902l-.002-.025v-.005m4.709 8.098a16.514 16.514 0 01-.799-.172c-1.158-.281-2.735-.803-3.727-1.795-.992-.992-1.514-2.569-1.795-3.727-.068-.281-.125-.55-.172-.799a4.661 4.661 0 106.493 6.492zM13 4a1 1 0 011 1v4a1 1 0 11-2 0V5a1 1 0 011-1zM17 8a1 1 0 011 1v4a1 1 0 11-2 0V9a1 1 0 011-1z" />
      <path fillRule="evenodd" d="M10 7a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1zM14 11a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1z" />
    </SvgIcon>
  );
}


export function DenyIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M15.707 8.293a1 1 0 010 1.414l-6 6a1 1 0 01-1.414-1.414l6-6a1 1 0 011.414 0z" />
      <path fillRule="evenodd" d="M8.293 8.293a1 1 0 011.414 0l6 6a1 1 0 01-1.414 1.414l-6-6a1 1 0 010-1.414z" />
    </SvgIcon>
  );
}


export function DenyIcon2({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M16.5563 17.6569C16.9469 18.0474 17.58 18.0474 17.9706 17.6569C18.3611 17.2663 18.3611 16.6332 17.9706 16.2426L13.7279 12L17.9706 7.75736C18.3611 7.36683 18.3611 6.73367 17.9706 6.34315C17.58 5.95262 16.9469 5.95262 16.5563 6.34315L12.3137 10.5858L8.07107 6.34315C7.68054 5.95262 7.04738 5.95262 6.65685 6.34315C6.26633 6.73367 6.26633 7.36684 6.65685 7.75736L10.8995 12L6.65685 16.2426C6.26633 16.6332 6.26633 17.2663 6.65685 17.6569C7.04738 18.0474 7.68054 18.0474 8.07107 17.6569L12.3137 13.4142L16.5563 17.6569Z" />
    </SvgIcon>
  );
}


export function DenyLargeIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="" clipRule="evenodd" d="M17.5607 6.43934C18.1464 7.02513 18.1464 7.97487 17.5607 8.56066L8.56066 17.5607C7.97487 18.1464 7.02513 18.1464 6.43934 17.5607C5.85355 16.9749 5.85355 16.0251 6.43934 15.4393L15.4393 6.43934C16.0251 5.85355 16.9749 5.85355 17.5607 6.43934Z M6.43934 6.43934C7.02513 5.85355 7.97487 5.85355 8.56066 6.43934L17.5607 15.4393C18.1464 16.0251 18.1464 16.9749 17.5607 17.5607C16.9749 18.1464 16.0251 18.1464 15.4393 17.5607L6.43934 8.56066C5.85355 7.97487 5.85355 7.02513 6.43934 6.43934Z" />
    </SvgIcon>
  );
}


export function LinkingIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path d="M7.7334 5.17946C7.7334 4.52806 8.26146 4 8.91285 4H15.0873C15.7387 4 16.2667 4.52806 16.2667 5.17946C16.2667 5.83085 15.7387 6.35891 15.0873 6.35891H8.91286C8.26146 6.35891 7.7334 5.83085 7.7334 5.17946Z" />
      <path d="M14.6334 10.3671C14.3573 10.3671 14.1334 10.1433 14.1334 9.86713V5.15285C14.1334 4.87671 14.3573 4.65285 14.6334 4.65285H15.7667C16.0429 4.65285 16.2667 4.87671 16.2667 5.15285V9.86713C16.2667 10.1433 16.0429 10.3671 15.7667 10.3671H14.6334Z" />
      <path d="M8.2334 10.3671C7.95726 10.3671 7.7334 10.1433 7.7334 9.86713V5.15285C7.7334 4.87671 7.95726 4.65285 8.2334 4.65285H9.36673C9.64287 4.65285 9.86673 4.87671 9.86673 5.15285V9.86713C9.86673 10.1433 9.64287 10.3671 9.36673 10.3671H8.2334Z" />
      <path d="M11.4334 14.9384C11.1573 14.9384 10.9334 14.7146 10.9334 14.4384V9.72415C10.9334 9.44801 11.1573 9.22415 11.4334 9.22415H12.5667C12.8429 9.22415 13.0667 9.44801 13.0667 9.72415V14.4384C13.0667 14.7146 12.8429 14.9384 12.5667 14.9384H11.4334Z" />
      <path d="M7.7334 18.8206C7.7334 18.1693 8.26146 17.6412 8.91285 17.6412H15.0873C15.7387 17.6412 16.2667 18.1693 16.2667 18.8206C16.2667 19.472 15.7387 20.0001 15.0873 20.0001H8.91286C8.26146 20.0001 7.7334 19.472 7.7334 18.8206Z" />
      <path d="M14.6334 19.51C14.3573 19.51 14.1334 19.2861 14.1334 19.01V14.2957C14.1334 14.0195 14.3573 13.7957 14.6334 13.7957H15.7667C16.0429 13.7957 16.2667 14.0195 16.2667 14.2957V19.01C16.2667 19.2861 16.0429 19.51 15.7667 19.51H14.6334Z" />
      <path d="M8.2334 19.5102C7.95726 19.5102 7.7334 19.2863 7.7334 19.0102V14.2959C7.7334 14.0198 7.95726 13.7959 8.2334 13.7959H9.36673C9.64287 13.7959 9.86673 14.0198 9.86673 14.2959V19.0102C9.86673 19.2863 9.64287 19.5102 9.36673 19.5102H8.2334Z" />
    </SvgIcon>
  );
}


export function NoLinkingIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path d="M7.734 5.727c0-.651.528-1.18 1.18-1.18h6.174a1.18 1.18 0 1 1 0 2.36H8.914a1.18 1.18 0 0 1-1.18-1.18Z
        M14.634 10.915a.5.5 0 0 1-.5-.5V5.701a.5.5 0 0 1 .5-.5h1.133a.5.5 0 0 1 .5.5v4.714a.5.5 0 0 1-.5.5h-1.133Zm-6.4 0a.5.5 0 0 1-.5-.5V5.701a.5.5 0 0 1 .5-.5h1.133a.5.5 0 0 1 .5.5v4.714a.5.5 0 0 1-.5.5H8.235Zm3.2 4.571a.5.5 0 0 1-.5-.5v-4.714a.5.5 0 0 1 .5-.5h1.134a.5.5 0 0 1 .5.5v4.714a.5.5 0 0 1-.5.5h-1.134Zm-3.7 3.883c0-.652.528-1.18 1.18-1.18h6.174a1.18 1.18 0 0 1 0 2.359H8.914a1.18 1.18 0 0 1-1.18-1.18Z
        M14.634 20.058a.5.5 0 0 1-.5-.5v-4.714a.5.5 0 0 1 .5-.5h1.133a.5.5 0 0 1 .5.5v4.714a.5.5 0 0 1-.5.5h-1.133Zm-6.4 0a.5.5 0 0 1-.5-.5v-4.714a.5.5 0 0 1 .5-.5h1.133a.5.5 0 0 1 .5.5v4.714a.5.5 0 0 1-.5.5H8.235Z
        M6.146 3.548a.665.665 0 1 1 1.14-.685l10.569 17.589a.665.665 0 0 1-1.14.685L6.144 3.547Z"/>
    </SvgIcon>
  );
}

export function DesktopIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M18 6H6v9h12V6zM6 4a2 2 0 00-2 2v9a2 2 0 002 2h12a2 2 0 002-2V6a2 2 0 00-2-2H6z" />
      <path fillRule="evenodd" d="M8 16V6h2v10H8z" />
      <path fillRule="evenodd" d="M9 11h10v2H9v-2zM12 16a1 1 0 011 1v1h3a1 1 0 110 2H8a1 1 0 110-2h3v-1a1 1 0 011-1z" />
    </SvgIcon>
  );
}


export function Download2Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path d="M17.057 12.106a2 2 0 01-1.156-2.48 2 2 0 00-3.396-1.954 2 2 0 01-2.359.474 1.5 1.5 0 00-2.108 1.697 2 2 0 01-1.141 2.283A1.5 1.5 0 007.5 15H9v2H7.5a3.5 3.5 0 01-1.41-6.705 3.5 3.5 0 014.92-3.953 4 4 0 016.79 3.908A3.501 3.501 0 0116.5 17H15v-2h1.5a1.5 1.5 0 00.557-2.894z" />
      <path fillRule="evenodd" d="M12 11a1 1 0 011 1v5h1a1 1 0 01.707 1.707l-2 2a1 1 0 01-1.414 0l-2-2A1 1 0 0110 17h1v-5a1 1 0 011-1z" />
    </SvgIcon>
  );
}


export function DownloadIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M15 12a1 1 0 011 1v3.586l.293-.293a1 1 0 011.414 1.414l-2 2a1 1 0 01-1.414 0l-2-2a1 1 0 011.414-1.414l.293.293V13a1 1 0 011-1z" />
      <path fillRule="evenodd" d="M14.5 6C12.02 6 10 8.023 10 10.529c0 1.079.374 2.067.999 2.845a1 1 0 11-1.56 1.252A6.523 6.523 0 018 10.53C8 6.928 10.905 4 14.5 4S21 6.928 21 10.529a6.523 6.523 0 01-1.021 3.514 1 1 0 01-1.687-1.075c.448-.703.708-1.539.708-2.44C19 8.024 16.98 6 14.5 6z" />
      <path fillRule="evenodd" d="M9 12a3 3 0 100 6h1a1 1 0 110 2H9a5 5 0 010-10 1 1 0 110 2z" />
    </SvgIcon>
  );
}


export function EmailIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M4 7a2 2 0 012-2h12a2 2 0 012 2v10a2 2 0 01-2 2H6a2 2 0 01-2-2V7zm14 0H6v10h12V7z" />
      <path fillRule="evenodd" d="M4.293 6.293a1 1 0 011.414 0L12 12.586l6.293-6.293a1 1 0 111.414 1.414l-7 7a1 1 0 01-1.414 0l-7-7a1 1 0 010-1.414z" />
    </SvgIcon>
  );
}


export function ExpandIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M11.293 15.707a1 1 0 010-1.414L12.586 13H5a1 1 0 110-2h7.586l-1.293-1.293a1 1 0 011.414-1.414l3 3a1 1 0 010 1.414l-3 3a1 1 0 01-1.414 0zM19 20a1 1 0 01-1-1V5a1 1 0 112 0v14a1 1 0 01-1 1z" />
    </SvgIcon>
  );
}


export function EyeIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd"
        d="M5.276 12l.11.128c.381.442.933 1.03 1.61 1.615C8.385 14.941 10.154 16 12 16c1.847 0 3.616-1.06 5.003-2.257A15.832 15.832 0 0018.723 12l-.108-.128a15.832 15.832 0 00-1.612-1.615C15.616 9.059 13.847 8 12 8s-3.616 1.06-5.003 2.257A15.839 15.839 0 005.277 12zM20 12l.813-.583-.002-.002-.003-.005-.011-.014-.037-.05a15.764 15.764 0 00-.632-.78 17.838 17.838 0 00-1.818-1.823C16.802 7.441 14.571 6 12 6 9.429 6 7.198 7.44 5.69 8.743a17.844 17.844 0 00-2.316 2.427 10.322 10.322 0 00-.171.226l-.01.014-.004.005-.001.002L4 12l-.813-.582a1 1 0 000 1.164L4 12l-.813.582.002.003.003.005.01.014a7.163 7.163 0 00.172.226 17.847 17.847 0 002.316 2.427C7.198 16.559 9.429 18 12 18c2.571 0 4.802-1.44 6.31-2.743a17.841 17.841 0 002.316-2.427l.134-.176.037-.05.01-.014.004-.005.001-.002L20 12zm0 0l.813.582a1 1 0 000-1.165L20 12z" />
      <path fillRule="evenodd" d="M12 11a1 1 0 100 2 1 1 0 000-2zm-3 1a3 3 0 116 0 3 3 0 01-6 0z" />
    </SvgIcon>
  );
}


export function HelpIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M11.211 10.334c-.15.21-.211.493-.211.666a1 1 0 11-2 0c0-.493.14-1.211.588-1.834C10.074 8.492 10.874 8 12 8s1.926.492 2.412 1.166c.448.623.588 1.34.588 1.834 0 .493-.14 1.211-.588 1.834C13.925 13.51 13.126 14 12 14a1 1 0 110-2c.474 0 .674-.175.789-.334.15-.21.211-.493.211-.666 0-.173-.06-.456-.211-.666-.115-.159-.315-.334-.789-.334-.474 0-.674.175-.789.334z" />
      <path d="M13 16a1 1 0 11-2 0 1 1 0 012 0z" />
      <path fillRule="evenodd" d="M12 6a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1116 0 8 8 0 01-16 0z" />
    </SvgIcon>
  );
}


export function Favorite2Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd"
        d="M12 4a1 1 0 01.951.691l1.347 4.146h4.36a1 1 0 01.587 1.809l-3.526 2.562 1.347 4.146a1 1 0 01-1.54 1.118L12 15.91l-3.526 2.562a1 1 0 01-1.54-1.118l1.348-4.146-3.527-2.562a1 1 0 01.588-1.81h4.36l1.346-4.145A1 1 0 0112 4zm0 4.236l-.62 1.91a1 1 0 01-.951.69H8.42l1.624 1.181a1 1 0 01.363 1.118l-.62 1.91 1.624-1.18a1 1 0 011.176 0l1.625 1.18-.62-1.91a1 1 0 01.362-1.118l1.625-1.18h-2.008a1 1 0 01-.951-.691L12 8.236z" />
    </SvgIcon>
  );
}


export function FavoriteIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M12.016 9.204L10.602 7.79A2.696 2.696 0 006.789 11.6l5.227 5.227 5.227-5.226A2.696 2.696 0 1013.43 7.79l-1.414 1.414zm0 10.453l-6.64-6.64a4.696 4.696 0 016.64-6.642h.001a4.696 4.696 0 016.64 6.641l-6.64 6.64z" />
    </SvgIcon>
  );
}


export const filingsIconContent = `
<svg viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" d="M4 7a3 3 0 013-3h2.469a3 3 0 011.488.395L13.766 6H17a3 3 0 013 3v8a3 3 0 01-3 3H7a3 3 0 01-3-2.999V7zm2 1h3.469a1 1 0 00.496-.132L11.485 7l-1.52-.868A1 1 0 009.469 6H7a1 1 0 00-1 1v1zm7.766 0l-2.809 1.605A3 3 0 019.47 10H6v7.001c0 .552.447.999 1 .999h10a1 1 0 001-1V9a1 1 0 00-1-1h-3.234z"/>
</svg>
`;

export function FilingsIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M4 7a3 3 0 013-3h2.469a3 3 0 011.488.395L13.766 6H17a3 3 0 013 3v8a3 3 0 01-3 3H7a3 3 0 01-3-2.999V7zm2 1h3.469a1 1 0 00.496-.132L11.485 7l-1.52-.868A1 1 0 009.469 6H7a1 1 0 00-1 1v1zm7.766 0l-2.809 1.605A3 3 0 019.47 10H6v7.001c0 .552.447.999 1 .999h10a1 1 0 001-1V9a1 1 0 00-1-1h-3.234z" />
    </SvgIcon>
  );
}

export function ColumnsIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M17.514 5.004c.85 0 1.4.448 1.4 1V19c0 .552-.55 1-1.4 1-.85 0-1.514-.448-1.514-1V6.004c0-.552.664-1 1.514-1ZM6.4 5C7.25 5 8 5.448 8 6v12.996c0 .552-.75 1.002-1.6 1.002-.851 0-1.4-.45-1.4-1.002V6c0-.552.55-1 1.4-1Zm5.597.002c.85 0 1.503.45 1.503 1.002V19c0 .552-.652.998-1.503.998-.85 0-1.497-.446-1.497-.998V6.004c0-.552.647-1.002 1.497-1.002Z" />
      {/* newer-er */}
      {/* <path fillRule="evenodd" clipRule="evenodd" d="M17.3497 5.02588C18.2611 5.02588 19 5.44374 19 5.95919V18.0923C19 18.6077 18.2611 19.0256 17.3497 19.0256C16.4382 19.0256 15.6993 18.6077 15.6993 18.0923V5.95919C15.6993 5.44374 16.4382 5.02588 17.3497 5.02588Z" /> */}
      {/* <path fillRule="evenodd" clipRule="evenodd" d="M6.65035 5.02588C7.56181 5.02588 8.30069 5.44374 8.30069 5.9592V18.0923C8.30069 18.6077 7.56181 19.0256 6.65035 19.0256C5.73888 19.0256 5 18.6077 5 18.0923V5.9592C5 5.44374 5.73888 5.02588 6.65035 5.02588Z" /> */}
      {/* <path fillRule="evenodd" clipRule="evenodd" d="M12 5C12.9115 5 13.6503 5.41786 13.6503 5.93331V18.0664C13.6503 18.5819 12.9115 18.9997 12 18.9997C11.0885 18.9997 10.3497 18.5819 10.3497 18.0664V5.93331C10.3497 5.41786 11.0885 5 12 5Z" /> */}
      {/* newer */}
      {/* <path fillRule="evenodd" clipRule="evenodd" d="M9 5.86667C9 5.38802 8.55228 5 8 5C7.44772 5 7 5.38802 7 5.86667V17.1333C7 17.612 7.44772 18 8 18C8.55228 18 9 17.612 9 17.1333V5.86667ZM13 5.86667C13 5.38802 12.5523 5 12 5C11.4477 5 11 5.38802 11 5.86667V17.1333C11 17.612 11.4477 18 12 18C12.5523 18 13 17.612 13 17.1333V5.86667ZM17 5.86667C17 5.38802 16.5523 5 16 5C15.4477 5 15 5.38802 15 5.86667V17.1333C15 17.612 15.4477 18 16 18C16.5523 18 17 17.612 17 17.1333V5.86667Z" /> */}
      {/* old */}
      {/* <path fillRule="evenodd" d="M17 18V6h-2v12h2zm2-12a2 2 0 00-2-2h-2a2 2 0 00-2 2v12a2 2 0 002 2h2a2 2 0 002-2V6zM9 18V6H7v12h2zm2-12a2 2 0 00-2-2H7a2 2 0 00-2 2v12a2 2 0 002 2h2a2 2 0 002-2V6z"/> */}
    </SvgIcon>
  );
}


export function FilterIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M4.645 7.908C3.65 6.751 4.522 5.021 6.1 5.021h11.8c1.579 0 2.451 1.73 1.456 2.887l-4.853 5.645a.114.114 0 0 0-.028.074v4.83c0 1.328-1.492 2.183-2.74 1.57l-1.2-.59c-.62-.306-1.01-.91-1.01-1.571v-4.239a.114.114 0 0 0-.028-.074L4.645 7.908Zm1.329-1.102a.106.106 0 0 0 .03.06l4.852 5.645c.271.316.42.71.42 1.116v4.239c0 .044.025.084.067.105l1.2.59c.083.04.182-.016.182-.105v-4.829c0-.406.148-.8.42-1.116l4.852-5.644a.106.106 0 0 0 .03-.06.12.12 0 0 0-.014-.066.127.127 0 0 0-.045-.05.12.12 0 0 0-.068-.017H6.1a.12.12 0 0 0-.068.016.128.128 0 0 0-.045.051.12.12 0 0 0-.013.065Z" />
      {/* <path fill-rule="evenodd" d="M4.645 7.182C3.65 5.956 4.522 4.125 6.1 4.125h11.8c1.579 0 2.451 1.831 1.456 3.057l-4.853 5.977a.125.125 0 0 0-.028.08v5.113a1.875 1.875 0 0 1-2.74 1.663l-1.2-.625a1.875 1.875 0 0 1-1.01-1.663v-4.489a.125.125 0 0 0-.028-.079L4.645 7.182Zm1.329-1.167a.115.115 0 0 0 .03.064l4.852 5.977c.271.335.42.752.42 1.182v4.489c0 .046.025.089.067.11l1.2.625a.125.125 0 0 0 .182-.11v-5.114c0-.43.148-.848.42-1.182l4.852-5.977a.115.115 0 0 0 .03-.064.133.133 0 0 0-.014-.069.133.133 0 0 0-.045-.054.115.115 0 0 0-.068-.017H6.1a.115.115 0 0 0-.068.017.133.133 0 0 0-.045.054.133.133 0 0 0-.013.07Z" /> */}
      {/* newer-er-er */}
      {/* <path d="M18.677 6.63A1 1 0 0 0 17.9 5H6.1a1 1 0 0 0-.776 1.63l4.852 5.978a1 1 0 0 1 .224.63v4.489a1 1 0 0 0 .538.887l1.2.625a1 1 0 0 0 1.462-.887v-5.114a1 1 0 0 1 .224-.63l4.853-5.978Z" /> */}
      {/* newer-er */}
      {/* <path d="M18.6765 6.63028C19.2071 5.9767 18.742 5 17.9001 5H6.09989C5.25804 5 4.79292 5.9767 5.32353 6.63028L10.1764 12.6079C10.321 12.7861 10.4 13.0086 10.4 13.2382V17.7267C10.4 18.0995 10.6074 18.4414 10.9381 18.6136L12.1381 19.2386C12.8038 19.5853 13.6 19.1023 13.6 18.3517V13.2381C13.6 13.0086 13.679 12.7861 13.8236 12.6079L18.6765 6.63028Z" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" /> */}
      {/* newer */}
      {/* <path d="M18.2624 7.71228C18.8995 7.08447 18.4549 6 17.5605 6H6.43952C5.54507 6 5.10052 7.08447 5.73762 7.71228L10.1019 12.0129C10.2926 12.2008 10.4 12.4574 10.4 12.7252V16C10.4 16.4037 10.6427 16.7678 11.0154 16.9231L12.2154 17.4231C12.8741 17.6975 13.6 17.2136 13.6 16.5V12.7252C13.6 12.4574 13.7074 12.2008 13.8981 12.0129L18.2624 7.71228Z" strokeWidth="1.75" strokeLinecap="round" strokeLinejoin="round" /> */}
      {/* old */}
      {/* <path d="M4 18a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1zM4 12a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1zM4 6a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1z" /> */}
      {/* <path d="M18 6a2 2 0 11-4 0 2 2 0 014 0zM14 12a2 2 0 11-4 0 2 2 0 014 0zM18 18a2 2 0 11-4 0 2 2 0 014 0z" /> */}
    </SvgIcon>
  );
}


export function Financials2Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M4 7a1 1 0 011-1h14a1 1 0 011 1v10a1 1 0 01-1 1H5a1 1 0 01-1-1V7zm2 1v8h12V8H6z" />
      <path fillRule="evenodd" d="M12 4a1 1 0 011 1v2h-2V5a1 1 0 011-1zM9 11a1 1 0 011 1v2a1 1 0 11-2 0v-2a1 1 0 011-1zM12 10a1 1 0 011 1v3a1 1 0 11-2 0v-3a1 1 0 011-1zM15 9a1 1 0 011 1v4a1 1 0 11-2 0v-4a1 1 0 011-1zM13.293 16.293a1 1 0 011.414 0l2 2a1 1 0 01-1.414 1.414l-2-2a1 1 0 010-1.414zM10.707 16.293a1 1 0 00-1.414 0l-2 2a1 1 0 101.414 1.414l2-2a1 1 0 000-1.414z" />
    </SvgIcon>
  );
}

export const financialsIconContent = `
<svg viewBox="0 0 24 24" fill="none">
  <path d="M4 19a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1zM5 15a1 1 0 011-1h12a1 1 0 110 2H6a1 1 0 01-1-1zM5 9a1 1 0 011-1h12a1 1 0 110 2H6a1 1 0 01-1-1zM13 10v4h-2v-4h2zM9 10v4H7v-4h2zM17 10v4h-2v-4h2z"/>
  <path fill-rule="evenodd" d="M11.445 4.168a1 1 0 011.11 0l6 4a1 1 0 01-1.11 1.664L12 6.202l-5.445 3.63a1 1 0 01-1.11-1.664l6-4z"/>
</svg>
`;

export function FinancialsIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path d="M4 19a1 1 0 011-1h14a1 1 0 110 2H5a1 1 0 01-1-1zM5 15a1 1 0 011-1h12a1 1 0 110 2H6a1 1 0 01-1-1zM5 9a1 1 0 011-1h12a1 1 0 110 2H6a1 1 0 01-1-1zM13 10v4h-2v-4h2zM9 10v4H7v-4h2zM17 10v4h-2v-4h2z" />
      <path fillRule="evenodd" d="M11.445 4.168a1 1 0 011.11 0l6 4a1 1 0 01-1.11 1.664L12 6.202l-5.445 3.63a1 1 0 01-1.11-1.664l6-4z" />
    </SvgIcon>
  );
}


export function FolderIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M4 7a3 3 0 013-3h2.469a3 3 0 011.488.395l2.578 1.473a1 1 0 00.496.132H17a3 3 0 013 3v8a3 3 0 01-3 3H7a3 3 0 01-3-2.999v-8L5 9h1v8.001A1 1 0 007 18h10a1 1 0 001-1V9a1 1 0 00-1-1h-2.969a3 3 0 01-1.488-.395L9.965 6.132A1 1 0 009.469 6H7a1 1 0 00-1 1v2H4V7z" />
    </SvgIcon>
  );
}


export function GraphbarsIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M5 5a1 1 0 011 1v13h13a1 1 0 110 2H5a1 1 0 01-1-1V6a1 1 0 011-1z" />
      <path d="M7 17a1 1 0 112 0v2H7v-2zM10 14a1 1 0 112 0v5h-2v-5zM13 10a1 1 0 112 0v9h-2v-9zM16 6a1 1 0 112 0v13h-2V6z" />
    </SvgIcon>
  );
}


export function Graphcolumns2Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M5 4a1 1 0 011 1v13h13a1 1 0 110 2H5a1 1 0 01-1-1V5a1 1 0 011-1z" />
      <path fillRule="evenodd" d="M9 12a1 1 0 011 1v5a1 1 0 11-2 0v-5a1 1 0 011-1zM13 8a1 1 0 011 1v9a1 1 0 11-2 0V9a1 1 0 011-1zM17 4a1 1 0 011 1v13a1 1 0 11-2 0V5a1 1 0 011-1z" />
    </SvgIcon>
  );
}


export function GraphsidewaysIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M5 4a1 1 0 011 1v12a1 1 0 001 1h12a1 1 0 110 2H7a3 3 0 01-3-3V5a1 1 0 011-1z" />
      <path fillRule="evenodd" d="M8.793 8.793a1 1 0 011.414 0l4.293 4.293 3.793-3.793a1 1 0 111.414 1.414l-4.5 4.5a1 1 0 01-1.414 0L9.5 10.914l-3.793 3.793a1 1 0 01-1.414-1.414l4.5-4.5z" />
    </SvgIcon>
  );
}


export function GraphupIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M5 4a1 1 0 011 1v12a1 1 0 001 1h12a1 1 0 110 2H7a3 3 0 01-3-3V5a1 1 0 011-1z" />
      <path fillRule="evenodd" d="M16 6a1 1 0 110-2h3a1 1 0 011 1v3a1 1 0 11-2 0v-.586l-5.293 5.293a1 1 0 01-1.414 0L9.5 10.914l-3.793 3.793a1 1 0 01-1.414-1.414l4.5-4.5a1 1 0 011.414 0L12 10.586 16.586 6H16z" />
    </SvgIcon>
  );
}


export function HistoryIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M12 9.5a1 1 0 011 1v2.738l1.768 2.122a1 1 0 11-1.536 1.28l-2-2.4A1 1 0 0111 13.6v-3.1a1 1 0 011-1z" />
      <path fillRule="evenodd" d="M4.5 7a1 1 0 011-1h7A7.5 7.5 0 115 13.5a1 1 0 112 0A5.5 5.5 0 1012.5 8h-7a1 1 0 01-1-1z" />
      <path fillRule="evenodd" d="M7.707 4.293a1 1 0 010 1.414L6.414 7l1.293 1.293a1 1 0 01-1.414 1.414l-2-2a1 1 0 010-1.414l2-2a1 1 0 011.414 0z" />
    </SvgIcon>
  );
}


export const insidersIconContent = `
<svg viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" d="M18 9H6v9h12V9zM6 7a2 2 0 00-2 2v9a2 2 0 002 2h12a2 2 0 002-2V9a2 2 0 00-2-2H6z"/>
    <path d="M8 5a1 1 0 011-1h1v3H8V5zM14 4v2h-4V4h4zM13 11a1 1 0 110 2h-2a1 1 0 110-2h2zM16 7h-2V4h1a1 1 0 011 1v2z"/>
    <path fill-rule="evenodd"
            d="M18.038 11.805A.453.453 0 0018 12h2c0 .43-.195.778-.363 1.007-.178.244-.41.465-.652.662-.49.394-1.141.778-1.865 1.114C15.678 15.45 13.783 16 12 16s-3.678-.55-5.12-1.217c-.724-.336-1.376-.72-1.865-1.114a3.648 3.648 0 01-.652-.662C4.195 12.778 4 12.43 4 12h2a.455.455 0 00-.038-.195 1.721 1.721 0 00.31.308c.324.261.822.565 1.448.854C8.978 13.55 10.583 14 12 14c1.417 0 3.022-.45 4.28-1.033.626-.29 1.124-.593 1.448-.854a1.72 1.72 0 00.31-.308zm.004-.008l-.004.008a.046.046 0 01.004-.008zm-12.084 0l.004.008a.044.044 0 01-.004-.008z"/>
</svg>
`;


export function InsidersIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M18 9H6v9h12V9zM6 7a2 2 0 00-2 2v9a2 2 0 002 2h12a2 2 0 002-2V9a2 2 0 00-2-2H6z" />
      <path d="M8 5a1 1 0 011-1h1v3H8V5zM14 4v2h-4V4h4zM13 11a1 1 0 110 2h-2a1 1 0 110-2h2zM16 7h-2V4h1a1 1 0 011 1v2z" />
      <path fillRule="evenodd"
        d="M18.038 11.805A.453.453 0 0018 12h2c0 .43-.195.778-.363 1.007-.178.244-.41.465-.652.662-.49.394-1.141.778-1.865 1.114C15.678 15.45 13.783 16 12 16s-3.678-.55-5.12-1.217c-.724-.336-1.376-.72-1.865-1.114a3.648 3.648 0 01-.652-.662C4.195 12.778 4 12.43 4 12h2a.455.455 0 00-.038-.195 1.721 1.721 0 00.31.308c.324.261.822.565 1.448.854C8.978 13.55 10.583 14 12 14c1.417 0 3.022-.45 4.28-1.033.626-.29 1.124-.593 1.448-.854a1.72 1.72 0 00.31-.308zm.004-.008l-.004.008a.046.046 0 01.004-.008zm-12.084 0l.004.008a.044.044 0 01-.004-.008z" />
    </SvgIcon>
  );
}


export function KeyIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd"
        d="M15.267 6a2.733 2.733 0 00-2.648 3.417 1 1 0 01-.267.963L6 16.62V18h.333v-.4a1 1 0 011-1H8.2v-.867a1 1 0 011-1h.867v-.984a1 1 0 011-1h1.452l1.107-1.107a1 1 0 01.957-.261A2.733 2.733 0 1015.267 6zM10.2 16.733h.867a1 1 0 001-1v-.984h.866a1 1 0 00.707-.293l1.028-1.027a4.733 4.733 0 10-4.097-4.103l-6.272 6.16A1 1 0 004 16.2V19a1 1 0 001 1h2.333a1 1 0 001-1v-.4H9.2a1 1 0 001-1v-.867z" />
      <circle cx="16" cy="8" r="1" />
    </SvgIcon>
  );
}


export function LightIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd"
        d="M12 14a2 2 0 100-4 2 2 0 000 4zm0 2a4 4 0 100-8 4 4 0 000 8zM12 4a1 1 0 011 1v1a1 1 0 11-2 0V5a1 1 0 011-1zM12 17a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM17.707 6.293a1 1 0 010 1.414l-1 1a1 1 0 11-1.414-1.414l1-1a1 1 0 011.414 0zM8.707 15.293a1 1 0 010 1.414l-1 1a1 1 0 11-1.414-1.414l1-1a1 1 0 011.414 0zM20 12a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM17.707 17.707a1 1 0 01-1.414 0l-1-1a1 1 0 011.414-1.414l1 1a1 1 0 010 1.414zM8.707 8.707a1 1 0 01-1.414 0l-1-1a1 1 0 011.414-1.414l1 1a1 1 0 010 1.414zM7 12a1 1 0 01-1 1H5a1 1 0 110-2h1a1 1 0 011 1z" />
    </SvgIcon>
  );
}


export function LinkIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M10.826 10.69a2.311 2.311 0 00-1.405.617l-2.357 2.357c-.796.796-.826 2.473.472 3.771s2.975 1.268 3.771.472a1 1 0 011.414 1.414c-1.807 1.807-4.773 1.355-6.6-.472-1.826-1.826-2.278-4.792-.47-6.6l2.356-2.356a4.306 4.306 0 012.673-1.198c1.238-.09 2.615.357 3.927 1.669a1 1 0 01-1.414 1.414c-.95-.95-1.774-1.132-2.367-1.088z" />
      <path fillRule="evenodd" d="M14.145 14.281a2.311 2.311 0 001.404-.617l2.357-2.357c.796-.796.827-2.474-.471-3.771-1.298-1.298-2.975-1.268-3.771-.472a1 1 0 11-1.415-1.414c1.808-1.808 4.774-1.355 6.6.471 1.827 1.827 2.28 4.793.472 6.6l-2.358 2.357a4.306 4.306 0 01-2.673 1.198c-1.238.09-2.614-.357-3.926-1.67a1 1 0 111.414-1.413c.95.95 1.774 1.131 2.367 1.088z" />
    </SvgIcon>
  );
}


export function RssFeedIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M5 4a1 1 0 000 2c7.18 0 13 5.82 13 13a1 1 0 102 0c0-8.284-6.716-15-15-15zm0 4a1 1 0 000 2 9 9 0 019 9 1 1 0 102 0c0-6.075-4.925-11-11-11zm-1 5a1 1 0 011-1 7 7 0 017 7 1 1 0 11-2 0 5 5 0 00-5-5 1 1 0 01-1-1zm2 7a2 2 0 100-4 2 2 0 000 4z" />
    </SvgIcon>
  );
}


export function LockIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd"
        d="M17 11H7a1 1 0 00-1 1v5a1 1 0 001 1h10a1 1 0 001-1v-5a1 1 0 00-1-1zM7 9a3 3 0 00-3 3v5a3 3 0 003 3h10a3 3 0 003-3v-5a3 3 0 00-3-3H7zM9.358 7.014C9.02 7.577 9 8.296 9 9H7v-.056c0-.647-.002-1.885.643-2.959C8.373 4.77 9.757 4 12 4c2.244 0 3.628.769 4.358 1.986.644 1.073.643 2.311.642 2.958V9h-2c0-.704-.02-1.424-.357-1.986C14.373 6.564 13.757 6 12 6c-1.756 0-2.372.565-2.642 1.014z" />
      <path d="M16 14.5c0 .828-1.79 1.5-4 1.5s-4-.672-4-1.5S9.79 13 12 13s4 .672 4 1.5z" />
    </SvgIcon>
  );
}


export function LogOutIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M4 7a3 3 0 013-3h5a1 1 0 110 2H7a1 1 0 00-1 1v10a1 1 0 001 1h5a1 1 0 110 2H7a3 3 0 01-3-3V7z" />
      <path fillRule="evenodd" d="M14.293 7.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414-1.414L16.586 13H9a1 1 0 110-2h7.586l-2.293-2.293a1 1 0 010-1.414z" />
    </SvgIcon>
  );
}


export function MarketOpenIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M12 18a6 6 0 100-12 6 6 0 000 12zm0 2a8 8 0 100-16 8 8 0 000 16z" />
      <path fillRule="evenodd" d="M12 7a1 1 0 011 1v4a1 1 0 11-2 0V8a1 1 0 011-1z" />
      <path fillRule="evenodd" d="M14.293 15.707l-3-3 1.414-1.414 3 3a1 1 0 01-1.414 1.414z" />
    </SvgIcon>
  );
}


export function MarkingIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M15.293 3.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414L19 10.414 13.586 5l1.707-1.707z" />
      <path fillRule="evenodd" d="M14.293 4.293a1 1 0 011.414 0l4 4a1 1 0 010 1.414l-6.5 6.5a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414l6.5-6.5zM9.914 11.5l2.586 2.586L17.586 9 15 6.414 9.914 11.5z" />
      <path d="M10.5 14.5l-1-1-2 2 1 1 2-2z" />
      <path fillRule="evenodd" d="M8.793 12.793a1 1 0 011.414 0l1 1a1 1 0 010 1.414l-2 2a1 1 0 01-1.414 0l-1-1a1 1 0 010-1.414l2-2zM3 19a1 1 0 011-1h16a1 1 0 110 2H4a1 1 0 01-1-1z" />
    </SvgIcon>
  );
}


export function MinimizeIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M12.707 8.293a1 1 0 010 1.414L11.414 11H19a1 1 0 110 2h-7.586l1.293 1.293a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414l3-3a1 1 0 011.414 0zM5 4a1 1 0 011 1v14a1 1 0 11-2 0V5a1 1 0 011-1z" />
    </SvgIcon>
  );
}


export function MoreIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path d="M14 12a2 2 0 11-4 0 2 2 0 014 0zM20 12a2 2 0 11-4 0 2 2 0 014 0zM8 12a2 2 0 11-4 0 2 2 0 014 0z" />
    </SvgIcon>
  );
}

export const moveIconContent = `
<svg viewBox="0 0 24 24" fill="none">
   <path d="M11 18a2 2 0 11-4 0 2 2 0 014 0zM11 12a2 2 0 11-4 0 2 2 0 014 0zM11 6a2 2 0 11-4 0 2 2 0 014 0zM17 6a2 2 0 11-4 0 2 2 0 014 0zM17 12a2 2 0 11-4 0 2 2 0 014 0zM17 18a2 2 0 11-4 0 2 2 0 014 0z"/>
</svg>`;

export function MoveIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path d="M11 18a2 2 0 11-4 0 2 2 0 014 0zM11 12a2 2 0 11-4 0 2 2 0 014 0zM11 6a2 2 0 11-4 0 2 2 0 014 0zM17 6a2 2 0 11-4 0 2 2 0 014 0zM17 12a2 2 0 11-4 0 2 2 0 014 0zM17 18a2 2 0 11-4 0 2 2 0 014 0z" />
    </SvgIcon>
  );
}


export function News2Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M18 6h-8v11c0 .35-.06.687-.17 1H18V6zM7 18a1 1 0 001-1V6a2 2 0 012-2h8a2 2 0 012 2v12a2 2 0 01-2 2H6a2 2 0 01-2-2v-7a1 1 0 112 0v6a1 1 0 001 1z" />
      <rect width="6" height="2" x="11" y="8" rx="1" />
      <path d="M11 13a1 1 0 011-1h4a1 1 0 110 2h-4a1 1 0 01-1-1z" />
    </SvgIcon>
  );
}


export function NewsIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M4 5a1 1 0 011-1h10a1 1 0 011 1v14a1 1 0 01-1 1H5a1 1 0 01-1-1V5zm2 1v12h8V6H6z" />
      <path fillRule="evenodd" d="M14 9a1 1 0 011-1h4a1 1 0 011 1v10a1 1 0 01-1 1h-4a1 1 0 01-1-1V9zm2 1v8h2v-8h-2zM7 9a1 1 0 011-1h4a1 1 0 110 2H8a1 1 0 01-1-1zM7 12a1 1 0 011-1h4a1 1 0 110 2H8a1 1 0 01-1-1zM7 15a1 1 0 011-1h2a1 1 0 110 2H8a1 1 0 01-1-1z" />
    </SvgIcon>
  );
}


export function Notice2Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path d="M13 16a1 1 0 11-2 0 1 1 0 012 0z" />
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 13v-2" />
      <path fillRule="evenodd" d="M12 4a1 1 0 01.894.553l7 14A1 1 0 0119 20H5a1 1 0 01-.894-1.447l7-14A1 1 0 0112 4zM6.618 18h10.764L12 7.236 6.618 18z" />
    </SvgIcon>
  );
}


export function NoticeIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M12 18a6 6 0 100-12 6 6 0 000 12zm0 2a8 8 0 100-16 8 8 0 000 16z" />
      <path d="M13 16a1 1 0 11-2 0 1 1 0 012 0z" />
      <path fillRule="evenodd" d="M12 8a1 1 0 011 1v4a1 1 0 11-2 0V9a1 1 0 011-1z" />
    </SvgIcon>
  );
}


export function NotificationIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd"
        d="M10.412 6.74c-.904-.607-1.436-.872-2.149-.677-.474.13-1.091.52-1.656.962-.24.187-.45.367-.607.508v5.792c.538-.282 1.179-.569 1.783-.703 1.549-.344 2.727.216 3.646.678l.118.06c.896.452 1.546.78 2.406.74.858-.04 1.988-.37 2.968-.727.42-.153.793-.304 1.079-.425V6.813l-.272.137c-1.005.493-2.408 1.072-3.67 1.146-1.515.09-2.654-.684-3.467-1.235a35.702 35.702 0 00-.179-.122zm8.045-2.482a1 1 0 011.543.84v8.5a1 1 0 01-.572.904L19 13.598l.428.904-.003.002-.009.003-.028.014a13.43 13.43 0 01-.488.217c-.322.139-.774.325-1.294.514-1.01.369-2.38.79-3.56.845-1.403.065-2.495-.49-3.324-.913l-.192-.097c-.889-.447-1.504-.692-2.313-.513-.482.107-1.122.416-1.698.748-.195.112-.372.22-.519.313v3.463a1 1 0 01-2 0v-12a1 1 0 01.295-.71l.705.71-.705-.71.003-.002.006-.006.017-.017a7.968 7.968 0 01.286-.267c.188-.17.454-.402.768-.647.595-.465 1.478-1.074 2.362-1.316 1.67-.455 2.905.353 3.789.945l.05.034c.938.63 1.545 1.036 2.365.988.838-.05 1.936-.47 2.906-.946a18.551 18.551 0 001.585-.88l.02-.013.005-.003z" />
    </SvgIcon>
  );
}


export function OpenFolderIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M4 7a3 3 0 013-3h1.68a3 3 0 011.664.504l1.993 1.328A1 1 0 0012.89 6H15a3 3 0 013 3v2a1 1 0 11-2 0V9a1 1 0 00-1-1h-2.109a3 3 0 01-1.664-.504L9.235 6.168A1 1 0 008.68 6H7a1 1 0 00-1 1v2H4V7zm2 12a1 1 0 11-2 0V9h2v10z" />
      <path fillRule="evenodd" d="M6.03 10.758A1 1 0 017 10h12a1 1 0 01.97 1.242l-2 8A1 1 0 0117 20H5a1 1 0 01-.97-1.242l2-8zM7.78 12l-1.5 6h9.94l1.5-6H7.78z" />
    </SvgIcon>
  );
}


export function OpenOutsideIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M14 5a1 1 0 011-1h4a1 1 0 011 1v4a1 1 0 11-2 0V7.414l-5.293 5.293a1 1 0 01-1.414-1.414L16.586 6H15a1 1 0 01-1-1z" />
      <path fillRule="evenodd" d="M7 6a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1v-4a1 1 0 112 0v4a3 3 0 01-3 3H7a3 3 0 01-3-3V7a3 3 0 013-3h4a1 1 0 110 2H7z" />
    </SvgIcon>
  );
}


export const overviewIconContent = `
<svg viewBox="0 0 24 24" fill="none">
    <path fill-rule="evenodd" d="M12.675 13.736a1.5 1.5 0 10-.777-2.898 1.5 1.5 0 00.777 2.898zm.518 1.931a3.5 3.5 0 10-1.812-6.761 3.5 3.5 0 001.812 6.761z"/>
    <path fill-rule="evenodd" d="M13.87 13.2a1 1 0 011.367-.365l3.061 1.767a1 1 0 11-1 1.733l-3.061-1.768a1 1 0 01-.367-1.366z"/>
    <path fill-rule="evenodd" d="M5 8a3 3 0 013-3h8a3 3 0 013 3v2a1 1 0 11-2 0V8a1 1 0 00-1-1H8a1 1 0 00-1 1v9a1 1 0 001 1h7a1 1 0 110 2H8a3 3 0 01-3-3V8z"/>
    <path fill-rule="evenodd" d="M9.5 4a1.5 1.5 0 100 3h4a1.5 1.5 0 000-3h-4z"/>
</svg>
`;

export function OverviewIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M12.675 13.736a1.5 1.5 0 10-.777-2.898 1.5 1.5 0 00.777 2.898zm.518 1.931a3.5 3.5 0 10-1.812-6.761 3.5 3.5 0 001.812 6.761z" />
      <path fillRule="evenodd" d="M13.87 13.2a1 1 0 011.367-.365l3.061 1.767a1 1 0 11-1 1.733l-3.061-1.768a1 1 0 01-.367-1.366z" />
      <path fillRule="evenodd" d="M5 8a3 3 0 013-3h8a3 3 0 013 3v2a1 1 0 11-2 0V8a1 1 0 00-1-1H8a1 1 0 00-1 1v9a1 1 0 001 1h7a1 1 0 110 2H8a3 3 0 01-3-3V8z" />
      <path fillRule="evenodd" d="M9.5 4a1.5 1.5 0 100 3h4a1.5 1.5 0 000-3h-4z" />
    </SvgIcon>
  );
}


export function OverviewWindowsIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M16 17a1 1 0 100-2 1 1 0 000 2zm0 2a3 3 0 100-6 3 3 0 000 6z" />
      <path fillRule="evenodd" d="M17.293 17.293a1 1 0 011.414 0l1 1a1 1 0 01-1.414 1.414l-1-1a1 1 0 010-1.414zM9 6H6v3h3V6zM6 4a2 2 0 00-2 2v3a2 2 0 002 2h3a2 2 0 002-2V6a2 2 0 00-2-2H6zM9 15H6v3h3v-3zm-3-2a2 2 0 00-2 2v3a2 2 0 002 2h3a2 2 0 002-2v-3a2 2 0 00-2-2H6zM18 6h-3v3h3V6zm-3-2a2 2 0 00-2 2v3a2 2 0 002 2h3a2 2 0 002-2V6a2 2 0 00-2-2h-3z" />
    </SvgIcon>
  );
}


export function OverviewCircleIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M6.438 16.366A1 1 0 015.072 16 8 8 0 0112 4a1 1 0 011 1v7a1 1 0 01-.5.866l-6.062 3.5zM11 11.423V6.084a6 6 0 00-4.623 8.008L11 11.422z" />
      <path fillRule="evenodd" d="M12 18a6 6 0 100-12 6 6 0 000 12zm8-6a8 8 0 11-16 0 8 8 0 0116 0z" />
      <path fillRule="evenodd" d="M5.68 16.466A1 1 0 015.071 16 8 8 0 0118.93 8a1 1 0 01-.366 1.366l-12.125 7a1 1 0 01-.759.1zm.697-2.374l10.247-5.916a6 6 0 00-10.247 5.916z" />
    </SvgIcon>
  );
}


export function PowerIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M9.249 5.484a1 1 0 01-.34 1.373 6 6 0 106.183 0 1 1 0 111.032-1.713 8 8 0 11-8.247 0 1 1 0 011.372.34z" />
      <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 11V5" />
    </SvgIcon>
  );
}


export function RefreshIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd"
        d="M4.002 11.471C4.278 7.05 7.862 4 11.986 4c1.43 0 2.804.347 4 1.008V5a1 1 0 112 0v2a1 1 0 01-1 1H15a1 1 0 01-.791-1.612A6.407 6.407 0 0011.986 6C8.86 6 6.205 8.285 5.998 11.596a1 1 0 11-1.996-.125zm14.908-.936a1 1 0 011.06.936c.294 4.71-3.568 8.529-7.984 8.529a8.426 8.426 0 01-4-1.04V19a1 1 0 11-2 0v-2a1 1 0 011-1h2a1 1 0 01.806 1.592 6.303 6.303 0 002.194.408c3.302 0 6.208-2.885 5.989-6.404a1 1 0 01.935-1.06z" />
    </SvgIcon>
  );
}


export function RemoveIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M12 6a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1116 0 8 8 0 01-16 0z" />
      <path fillRule="evenodd" d="M8 12a1 1 0 011-1h6a1 1 0 110 2H9a1 1 0 01-1-1z" />
    </SvgIcon>
  );
}


export function EditIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M14.293 4.293A1 1 0 0115 4c.413 0 1.05.06 1.706.233.64.17 1.42.48 2.001 1.06.58.58.89 1.36 1.06 2.001C19.94 7.951 20 8.587 20 9a1 1 0 01-.293.707l-10 10A1 1 0 019 20H5a1 1 0 01-1-1v-4a1 1 0 01.293-.707l10-10zm1.099 1.73L6 15.413V18h2.586l9.392-9.392a5.258 5.258 0 00-.145-.802c-.13-.493-.32-.88-.54-1.099-.22-.22-.606-.41-1.099-.54a5.266 5.266 0 00-.802-.145z" />
      <path fillRule="evenodd" d="M12.707 6.293l4 4-1.414 1.414-4-4 1.414-1.414z" />
    </SvgIcon>
  );
}


export function EditIcon2({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M14.0148 8.21687L15.0622 7.1695C15.2574 6.97424 15.574 6.97424 15.7693 7.1695L16.8299 8.23016C17.0252 8.42543 17.0252 8.74201 16.8299 8.93727L15.7826 9.98464L14.0153 8.21738L14.0148 8.21687ZM11.8936 8.21779L11.8931 8.21729L12.9537 7.15663L14.0015 6.10884C14.7826 5.3278 16.0489 5.3278 16.8299 6.10884L17.8906 7.1695C18.6716 7.95055 18.6716 9.21688 17.8906 9.99793L16.8432 11.0453L16.8437 11.0458L15.7831 12.1065L10.5372 17.3523C10.1661 17.7234 9.66408 17.9338 9.13932 17.9381L8.06993 17.9468C6.95262 17.9559 6.04454 17.0478 6.05367 15.9305L6.0624 14.8611C6.06668 14.3364 6.27704 13.8343 6.64812 13.4632L11.8936 8.21779ZM12.9542 9.27845L11.894 10.3387L7.70878 14.5239C7.61601 14.6167 7.56342 14.7422 7.56235 14.8734L7.55362 15.9428C7.55133 16.2221 7.77835 16.4491 8.05768 16.4468L9.12707 16.4381C9.25826 16.437 9.38378 16.3844 9.47654 16.2917L13.6618 12.1065L14.722 11.0462L14.7215 11.0457L12.9542 9.27845Z" />
    </SvgIcon>
  );
}


export function RowsIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M18 7H6v2h12V7zM6 5a2 2 0 00-2 2v2a2 2 0 002 2h12a2 2 0 002-2V7a2 2 0 00-2-2H6zM18 15H6v2h12v-2zM6 13a2 2 0 00-2 2v2a2 2 0 002 2h12a2 2 0 002-2v-2a2 2 0 00-2-2H6z" />
    </SvgIcon>
  );
}


export function SSR2Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd"
        d="M2 9a1 1 0 011-1h4a1 1 0 010 2H4v1h3a1 1 0 011 1v3a1 1 0 01-1 1H3a1 1 0 110-2h3v-1H3a1 1 0 01-1-1V9zM9 9a1 1 0 011-1h4a1 1 0 110 2h-3v1h3a1 1 0 011 1v3a1 1 0 01-1 1h-4a1 1 0 110-2h3v-1h-3a1 1 0 01-1-1V9zM16 9a1 1 0 011-1h3c.435 0 .954.184 1.359.62.418.452.641 1.092.641 1.88 0 .788-.223 1.428-.641 1.88a2.035 2.035 0 01-.134.132l.724 2.172a1 1 0 01-1.898.632L19.28 13H18v2a1 1 0 11-2 0V9zm2 2h1.906a.402.402 0 00.039-.085c.026-.075.055-.207.055-.415s-.029-.34-.055-.415a.402.402 0 00-.039-.085H18v1zm1.888-1.024l.001.001zm0 1.048l.001-.001z" />
    </SvgIcon>
  );
}


export function SSRSIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 6 8" fill="none">
      <path fillRule="evenodd" clipRule="evenodd" d="M0 1C0 0.447715 0.447715 0 1 0H5C5.55228 0 6 0.447715 6 1C6 1.55228 5.55228 2 5 2H2V3H5C5.55228 3 6 3.44772 6 4V7C6 7.55228 5.55228 8 5 8H1C0.447715 8 0 7.55228 0 7C0 6.44772 0.447715 6 1 6H4V5H1C0.447715 5 0 4.55228 0 4V1Z" />
    </SvgIcon>
  );
}


export function SSRIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M12 6a6 6 0 100 12 6 6 0 000-12zm-8 6a8 8 0 1116 0 8 8 0 01-16 0z" />
      <path fillRule="evenodd" d="M9 9a1 1 0 011-1h4a1 1 0 110 2h-3v1h3a1 1 0 011 1v3a1 1 0 01-1 1h-4a1 1 0 110-2h3v-1h-3a1 1 0 01-1-1V9z" />
    </SvgIcon>
  );
}


export function Scanner2Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M11.878 10.319a1.25 1.25 0 100 2.5 1.25 1.25 0 000-2.5zm-3.25 1.25a3.25 3.25 0 116.5 0 3.25 3.25 0 01-6.5 0z" />
      <path fillRule="evenodd" d="M9.006 7.282a1 1 0 01-.001 1.414 4.058 4.058 0 000 5.745 1 1 0 11-1.413 1.415 6.059 6.059 0 010-8.575 1 1 0 011.414.001zM14.75 7.282a1 1 0 011.413 0 6.058 6.058 0 010 8.574 1 1 0 11-1.412-1.415 4.058 4.058 0 000-5.745 1 1 0 01-.002-1.414z" />
      <path fillRule="evenodd" d="M7.017 5.293a1 1 0 01-.001 1.415 6.868 6.868 0 000 9.721 1 1 0 11-1.413 1.416 8.868 8.868 0 010-12.553 1 1 0 011.414.002zM16.739 5.293a1 1 0 011.414 0 8.868 8.868 0 010 12.552 1 1 0 11-1.413-1.416 6.869 6.869 0 000-9.721 1 1 0 01-.001-1.415z" />
    </SvgIcon>
  );
}


export function Scanner3Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path d="M11.878 13.819a2.25 2.25 0 100-4.5 2.25 2.25 0 000 4.5z" />
      <path fillRule="evenodd" d="M9.006 7.282a1 1 0 01-.001 1.414 4.058 4.058 0 000 5.745 1 1 0 11-1.413 1.415 6.059 6.059 0 010-8.575 1 1 0 011.414.001zM14.75 7.282a1 1 0 011.413 0 6.058 6.058 0 010 8.574 1 1 0 11-1.412-1.415 4.058 4.058 0 000-5.745 1 1 0 01-.002-1.414z" />
      <path fillRule="evenodd" d="M6.017 5.293a1 1 0 01-.001 1.415 6.868 6.868 0 000 9.721 1 1 0 11-1.413 1.416 8.868 8.868 0 010-12.553 1 1 0 011.414.002zM17.602 5.293a1 1 0 011.414 0 8.868 8.868 0 010 12.552 1 1 0 11-1.413-1.416 6.869 6.869 0 000-9.721 1 1 0 01-.001-1.415z" />
    </SvgIcon>
  );
}


export function ScannerIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <g clip-path="url(#clip0)">
        <path d="M14 12a2 2 0 11-4 0 2 2 0 014 0z" />
        <path fillRule="evenodd" d="M12 13a1 1 0 100-2 1 1 0 000 2zm0 1a2 2 0 100-4 2 2 0 000 4zM14.829 7.707a1 1 0 011.414 0 6 6 0 010 8.486 1 1 0 01-1.414-1.415 4 4 0 000-5.657 1 1 0 010-1.414z" />
        <path fillRule="evenodd" d="M17.657 6.293a1 1 0 011.414 0 8 8 0 010 11.314 1 1 0 11-1.414-1.415 6 6 0 000-8.485 1 1 0 010-1.414zM9.485 16.192a1 1 0 01-1.414 0 6 6 0 010-8.485 1 1 0 111.414 1.414 4 4 0 000 5.657 1 1 0 010 1.414z" />
        <path fillRule="evenodd" d="M6.657 17.607a1 1 0 01-1.414 0 8 8 0 010-11.314 1 1 0 111.414 1.414 6 6 0 000 8.485 1 1 0 010 1.415z" />
      </g>
      <defs>
        <clipPath id="clip0">
          <path d="M0 0h24v24H0z" />
        </clipPath>
      </defs>
    </SvgIcon>
  );
}


export function SearchIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M11 17a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 100-14 7 7 0 000 14z" />
      <path fillRule="evenodd" d="M15.293 16.293a1 1 0 011.414 0l3 3a1 1 0 01-1.414 1.414l-3-3a1 1 0 010-1.414z" />
    </SvgIcon>
  );
}


export function CheckIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M19.153 7.423a1.012 1.012 0 0 1 0 1.528l-7.673 6.965c-.51.356-1.198.375-1.684 0l-3.772-3.423a1.012 1.012 0 0 1 0-1.528 1.276 1.276 0 0 1 1.684 0l2.93 2.66 6.832-6.202a1.278 1.278 0 0 1 1.683 0Z" />
    </SvgIcon>
  );
}


export function ServerIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M4 7a3 3 0 013-3h10a3 3 0 013 3v10a3 3 0 01-3 3H7a3 3 0 01-3-3V7zm3-1a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1V7a1 1 0 00-1-1H7z" />
      <path fillRule="evenodd" d="M18 13H6v-2h12v2z" />
      <path d="M10 8.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0zM10 15.5a1.5 1.5 0 11-3 0 1.5 1.5 0 013 0z" />
    </SvgIcon>
  );
}


export function SettingsIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd"
        d="M15.614 5.289a1 1 0 00-.898-1.278 5.483 5.483 0 00-5.63 6.936l-4.05 3.51a3 3 0 00-.157 4.388l.133.134a3 3 0 004.388-.157l3.51-4.05a5.483 5.483 0 006.936-5.63 1 1 0 00-1.277-.898l-3.115.908-.748-.749.908-3.114zm-4.556 8.566a5.54 5.54 0 01-1.056-1.056l-3.657 3.17a1 1 0 00-.052 1.462l.133.133a1 1 0 001.463-.052l3.17-3.657zm.85-6.834a3.47 3.47 0 011.363-.843l-.651 2.234a1 1 0 00.253.987l1.585 1.585a1 1 0 00.987.253l2.235-.651a3.484 3.484 0 11-5.77-3.565z" />
    </SvgIcon>
  );
}


export function SortByIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M4 5a1 1 0 011-1h11a1 1 0 110 2H5a1 1 0 01-1-1zM4 11a1 1 0 011-1h7a1 1 0 110 2H5a1 1 0 01-1-1zM4 17a1 1 0 011-1h4a1 1 0 110 2H5a1 1 0 01-1-1zM16 10a1 1 0 011 1v5.586l1.293-1.293a1 1 0 011.414 1.414l-3 3a1 1 0 01-1.414 0l-3-3a1 1 0 011.414-1.414L15 16.586V11a1 1 0 011-1z" />
    </SvgIcon>
  );
}


export function StudyIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M11.626 4.073a1 1 0 01.748 0l8 3.23a1 1 0 01.052 1.832l-8 3.77a1 1 0 01-.852 0l-8-3.77a1 1 0 01.052-1.831l8-3.231zM6.496 8.3L12 10.895 17.504 8.3 12 6.078 6.496 8.301z" />
      <path fillRule="evenodd" d="M5 13.454V9h2v4.395a.82.82 0 00.115.16c.146.169.406.39.816.61.814.438 2.145.835 4.069.835s3.255-.397 4.07-.835c.409-.22.67-.441.815-.61a.823.823 0 00.115-.16V9h2v4.454c0 .555-.287 1.046-.603 1.41-.336.39-.804.752-1.38 1.062C15.859 16.55 14.19 17 12 17c-2.19 0-3.86-.451-5.017-1.074-.576-.31-1.044-.673-1.38-1.061C5.287 14.5 5 14.009 5 13.455z" />
      <path fillRule="evenodd" d="M11.106 8.053a1 1 0 011.341-.447l3 1.5A1 1 0 0116 10v9a1 1 0 11-2 0v-8.382l-2.447-1.224a1 1 0 01-.447-1.341z" />
    </SvgIcon>
  );
}


export function TimeframeIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M12 18a6 6 0 100-12 6 6 0 000 12zm0 2a8 8 0 100-16 8 8 0 000 16z" />
      <path fillRule="evenodd"
        d="M12 4a1 1 0 011 1v2a1 1 0 11-2 0V5a1 1 0 011-1zM12 16a1 1 0 011 1v1a1 1 0 11-2 0v-1a1 1 0 011-1zM16.944 17.015a1 1 0 01-1.414 0l-.808-.808a1 1 0 011.414-1.414l.808.808a1 1 0 010 1.414zM8.944 9.015a1 1 0 01-1.414 0l-.808-.808a1 1 0 011.414-1.414l.808.808a1 1 0 010 1.414zM16.943 6.793a1 1 0 010 1.414l-.808.808a1 1 0 11-1.414-1.414l.808-.808a1 1 0 011.414 0zM8.943 14.793a1 1 0 010 1.414l-.808.808a1 1 0 01-1.414-1.414l.808-.808a1 1 0 011.414 0zM19 12a1 1 0 01-1 1h-1a1 1 0 110-2h1a1 1 0 011 1zM8 12a1 1 0 01-1 1H6a1 1 0 110-2h1a1 1 0 011 1zM11.293 12.707A1 1 0 0111 12v-1a1 1 0 112 0v.586l1.207 1.207a1 1 0 01-1.414 1.414l-1.5-1.5z" />
    </SvgIcon>
  );
}


export function PlusIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M10.9062 17.6562C10.9062 18.1222 11.284 18.5 11.75 18.5C12.216 18.5 12.5938 18.1222 12.5938 17.6562V12.5938H17.6562C18.1222 12.5938 18.5 12.216 18.5 11.75C18.5 11.284 18.1222 10.9062 17.6562 10.9062H12.5938V5.84375C12.5938 5.37776 12.216 5 11.75 5C11.284 5 10.9062 5.37776 10.9062 5.84375V10.9062H5.84375C5.37776 10.9062 5 11.284 5 11.75C5 12.216 5.37776 12.5938 5.84375 12.5938H10.9062V17.6562Z" />
    </SvgIcon>
  );
}


export function PlusIcon2({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M13.25 7.875c0-.76-.56-1.375-1.25-1.375s-1.25.616-1.25 1.375v2.875H7.875c-.76 0-1.375.56-1.375 1.25s.616 1.25 1.375 1.25h2.875v2.875c0 .76.56 1.375 1.25 1.375s1.25-.616 1.25-1.375V13.25h2.875c.76 0 1.375-.56 1.375-1.25s-.616-1.25-1.375-1.25H13.25V7.875Z" />
    </SvgIcon>
  );
}


export function TopLists2Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd"
        d="M12.173 4.385A1 1 0 0112.962 4c1.494 0 2.556.567 3.144 1.598.524.917.57 2.04.535 2.94-.018.462-.06.918-.098 1.318l-.002.022c-.04.421-.07.763-.076 1.046v.038c.335-.055.628-.166.854-.3.298-.176.383-.327.4-.357.004-.007.005-.008.004-.003a1 1 0 011.946-.062c.787 2.926.115 5.382-1.44 7.11-1.525 1.693-3.812 2.594-6.127 2.648-4.13.097-7.824-2.808-8.088-7.062-.064-1.026.096-2.083.323-2.951.218-.833.535-1.637.877-2.089a1 1 0 011.79.73c-.137 1.083.235 1.551.544 1.765l.02.014c-.116-.76-.071-1.532.034-2.2.183-1.171.596-2.292.997-2.888a1 1 0 011.83.558c0 .308.084.772.296 1.122.18.298.433.503.912.503.198 0 .252-.055.276-.08.05-.051.127-.175.179-.43.106-.526.028-1.237-.1-1.745a1 1 0 01.18-.86zm1.967 1.926c.007.356-.017.723-.088 1.074-.095.474-.294 1.006-.704 1.428-.436.45-1.026.687-1.711.687-.902 0-1.59-.329-2.086-.799-.12.92-.034 1.744.291 2.28a1 1 0 01-.464 1.44c-.854.362-2.046.25-2.966-.384-.13-.09-.255-.19-.37-.299-.04.363-.053.727-.032 1.074.189 3.04 2.843 5.262 6.045 5.187 1.859-.044 3.59-.768 4.688-1.987.77-.856 1.273-2.003 1.257-3.45a4.703 4.703 0 01-1.997.438c-.284 0-.585-.073-.853-.264a1.5 1.5 0 01-.508-.649c-.167-.398-.183-.853-.176-1.198.006-.37.045-.787.082-1.182l.001-.015c.04-.415.077-.825.093-1.23.033-.85-.047-1.477-.272-1.872a.996.996 0 00-.23-.279z" />
    </SvgIcon>
  );
}


export function TopListsIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd"
        d="M12.79 10.386L12 11l-.79-.614a1 1 0 011.58 0zM12 12.688c-.206.292-.435.624-.663.973a16.864 16.864 0 00-.972 1.66c-.28.566-.365.899-.365 1.012 0 .813.781 1.667 2 1.667 1.219 0 2-.854 2-1.667 0-.113-.086-.445-.365-1.011a16.864 16.864 0 00-.972-1.661 32.342 32.342 0 00-.663-.973zM12 11l-.79-.614-.003.004-.007.01-.027.035-.1.13c-.084.113-.205.274-.35.471-.287.394-.673.938-1.06 1.532-.386.59-.784 1.248-1.09 1.868C8.288 15.008 8 15.71 8 16.333 8 18.466 9.905 20 12 20c2.095 0 4-1.534 4-3.667 0-.622-.29-1.325-.572-1.897a18.788 18.788 0 00-1.09-1.868 35.946 35.946 0 00-1.511-2.133L12.8 10.4l-.007-.01-.003-.004L12 11z" />
      <path fillRule="evenodd"
        d="M12.71 4.297L12 5l-.71-.703a1 1 0 011.42 0zM12 6.466l-.036.04a31.272 31.272 0 00-1.665 2.02c-.606.804-1.194 1.682-1.626 2.533-.443.87-.673 1.614-.673 2.176C8 16.005 9.92 18 12 18s4-1.995 4-4.765c0-.562-.23-1.306-.672-2.176-.433-.85-1.021-1.73-1.627-2.534a31.272 31.272 0 00-1.665-2.019L12 6.466zM12 5l-.71-.703-.006.005-.013.013-.045.046c-.04.04-.096.1-.167.175a33.247 33.247 0 00-2.358 2.787c-.644.855-1.306 1.837-1.811 2.829-.495.973-.89 2.054-.89 3.083C6 16.833 8.557 20 12 20s6-3.167 6-6.765c0-1.03-.395-2.11-.89-3.083-.505-.992-1.167-1.974-1.811-2.83a33.25 33.25 0 00-2.525-2.96l-.045-.047-.013-.013-.003-.004-.002-.001L12 5z" />
    </SvgIcon>
  );
}


export function TrashIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M5 8a1 1 0 011-1h13a1 1 0 110 2h-.5v9a2 2 0 01-2 2h-8a2 2 0 01-2-2V9H6a1 1 0 01-1-1zm3.5 1v9h8V9h-8z" />
      <path fillRule="evenodd" d="M8.5 6a2 2 0 012-2h4a2 2 0 012 2v2h-2V6h-4v2h-2V6zM14.5 10a1 1 0 011 1v5a1 1 0 11-2 0v-5a1 1 0 011-1zM10.5 10a1 1 0 011 1v5a1 1 0 11-2 0v-5a1 1 0 011-1z" />
    </SvgIcon>
  );
}


export function User2Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M6.125 6A.81.81 0 006 6.462v11.076c0 .23.07.386.125.462h11.75a.81.81 0 00.125-.462V6.462A.81.81 0 0017.875 6H6.125zM6 4C4.895 4 4 5.102 4 6.462v11.076C4 18.898 4.895 20 6 20h12c1.105 0 2-1.102 2-2.462V6.462C20 5.102 19.105 4 18 4H6z" />
      <path fillRule="evenodd" d="M10.412 14.71c-.302.418-.412.95-.412 1.29H8c0-.66.19-1.628.788-2.46C9.424 12.659 10.474 12 12 12c1.526 0 2.576.658 3.211 1.54.6.832.789 1.8.789 2.46h-2c0-.34-.11-.872-.412-1.29-.264-.368-.714-.71-1.588-.71-.874 0-1.324.342-1.588.71z" />
      <path fillRule="evenodd" d="M12 10a1 1 0 100 2 1 1 0 000-2zm-3 1a3 3 0 116 0 3 3 0 01-6 0z" />
    </SvgIcon>
  );
}


export function User3Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd"
        d="M8.379 18.57c-.35.186-.854.43-1.379.43v-2c-.005 0-.004 0 .005-.003.03-.009.157-.045.432-.192.204-.109.407-.23.644-.372.151-.09.316-.19.504-.298C9.453 15.632 10.658 15 12 15c1.343 0 2.548.632 3.416 1.135.188.109.353.208.504.298.237.142.44.263.644.372A2.229 2.229 0 0017 17v2c-.524 0-1.028-.244-1.378-.43a20.383 20.383 0 01-.794-.46c-.143-.085-.284-.169-.416-.245C13.556 17.368 12.761 17 12 17c-.76 0-1.555.368-2.412.865-.13.076-.272.16-.415.246-.272.162-.553.33-.794.459z" />
      <path fillRule="evenodd" d="M12 10a2 2 0 100 4 2 2 0 000-4zm-4 2a4 4 0 118 0 4 4 0 01-8 0z" />
      <path fillRule="evenodd" d="M4 13a8 8 0 0110.285-7.669 1 1 0 11-.57 1.917 6 6 0 103.708 3.18 1 1 0 111.807-.857A8 8 0 114 13z" />
      <path d="M16.58 5.773a.478.478 0 01.84 0 .478.478 0 00.433.25c.379-.01.618.404.42.727a.478.478 0 000 .5.478.478 0 01-.42.727.478.478 0 00-.433.25.478.478 0 01-.84 0 .478.478 0 00-.432-.25.478.478 0 01-.42-.727.478.478 0 000-.5.478.478 0 01.42-.727.478.478 0 00.432-.25z" />
    </SvgIcon>
  );
}


export function User4Icon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M12 18a6 6 0 100-12 6 6 0 000 12zm0 2a8 8 0 100-16 8 8 0 000 16z" />
      <path fillRule="evenodd"
        d="M8.379 17.57c-.35.186-.854.43-1.379.43v-2c-.005 0-.004 0 .005-.003.03-.009.157-.045.432-.192.204-.109.407-.23.644-.372.151-.09.316-.19.504-.298C9.453 14.632 10.658 14 12 14c1.343 0 2.548.632 3.416 1.135.188.109.353.208.504.298.237.142.44.263.644.372A2.229 2.229 0 0017 16v2c-.524 0-1.028-.244-1.378-.43a20.383 20.383 0 01-.794-.46c-.143-.085-.284-.169-.416-.245C13.556 16.368 12.761 16 12 16c-.76 0-1.555.368-2.412.865-.13.076-.272.16-.415.246-.272.162-.553.33-.794.459z" />
      <path fillRule="evenodd" d="M12 9a2 2 0 100 4 2 2 0 000-4zm-4 2a4 4 0 118 0 4 4 0 01-8 0z" />
    </SvgIcon>
  );
}


export function UserIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M10.412 14.71c-.302.418-.412.95-.412 1.29H8c0-.66.19-1.628.788-2.46C9.424 12.659 10.474 12 12 12c1.526 0 2.576.658 3.211 1.54.6.832.789 1.8.789 2.46h-2c0-.34-.11-.872-.412-1.29-.264-.368-.714-.71-1.588-.71-.874 0-1.324.342-1.588.71z" />
      <path fillRule="evenodd" d="M12 10a1 1 0 100 2 1 1 0 000-2zm-3 1a3 3 0 116 0 3 3 0 01-6 0z" />
      <path fillRule="evenodd" d="M12 18a6 6 0 100-12 6 6 0 000 12zm0 2a8 8 0 100-16 8 8 0 000 16z" />
    </SvgIcon>
  );
}


export function WindowIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M17 6H7a1 1 0 00-1 1v10a1 1 0 001 1h10a1 1 0 001-1V7a1 1 0 00-1-1zM7 4a3 3 0 00-3 3v10a3 3 0 003 3h10a3 3 0 003-3V7a3 3 0 00-3-3H7z" />
      <path fillRule="evenodd" d="M18 10H6V8h12v2z" />
    </SvgIcon>
  );
}


export function ZoominIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M11 17a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 100-14 7 7 0 000 14z" />
      <path fillRule="evenodd" d="M14.793 15.793a1 1 0 011.414 0l3.5 3.5a1 1 0 01-1.414 1.414l-3.5-3.5a1 1 0 010-1.414zM8 12a1 1 0 011-1h4a1 1 0 110 2H9a1 1 0 01-1-1z" />
      <path fillRule="evenodd" d="M11 9a1 1 0 011 1v4a1 1 0 11-2 0v-4a1 1 0 011-1z" />
    </SvgIcon>
  );
}


export function ZoomoutIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" d="M11 17a5 5 0 100-10 5 5 0 000 10zm0 2a7 7 0 100-14 7 7 0 000 14z" />
      <path fillRule="evenodd" d="M14.793 15.793a1 1 0 011.414 0l3.5 3.5a1 1 0 01-1.414 1.414l-3.5-3.5a1 1 0 010-1.414zM8 12a1 1 0 011-1h4a1 1 0 110 2H9a1 1 0 01-1-1z" />
    </SvgIcon>
  );
}


export function TradingViewCloudArrow({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      {/*<svg xmlns="http://www.w3.org/2000/svg" width="28" height="28">*/}
      <g fill="none">
        <path stroke="currentColor" d="M11 20.5H7.5a5 5 0 1 1 .42-9.98 7.5 7.5 0 0 1 14.57 2.1 4 4 0 0 1-1 7.877H18" />
        <path stroke="currentColor" d="M14.5 24V12.5M11 16l3.5-3.5L18 16" />
      </g>
      {/*</svg>*/}
    </SvgIcon>
  );
}


export function WatchlistIcon({ width }) {
  const classes = useStyles();
  return (
    <SvgIcon classes={{ root: classes.edgeIconRoot }} style={width && { fontSize: width }} viewBox="0 0 24 24" fill="none">
      <path fillRule="evenodd" clipRule="evenodd"
        d="M4.9834 6.40676C4.9834 4.9748 6.29233 3.81396 7.90698 3.81396H16.093C17.7077 3.81396 19.0166 4.9748 19.0166 6.40676V18.9392C19.0166 19.9827 17.6555 20.5629 16.7366 19.911L12 16.5504L7.26337 19.911C6.34452 20.5629 4.9834 19.9827 4.9834 18.9392V6.40676ZM7.90698 5.8882C7.58405 5.8882 7.32227 6.12037 7.32227 6.40676V17.2129L12 13.8941L16.6777 17.2129V6.40676C16.6777 6.12037 16.4159 5.8882 16.093 5.8882H7.90698Z" />
      {/* SMALLER VERSION: <path fillRule="evenodd" clipRule="evenodd" d="M6 7.21714C6 5.99265 7.11929 5 8.5 5H15.5C16.8807 5 18 5.99265 18 7.21714V17.9338C18 18.8261 16.8361 19.3223 16.0504 18.7648L12 15.8911L7.94963 18.7648C7.16392 19.3223 6 18.8261 6 17.9338V7.21714ZM8.5 6.77371C8.22386 6.77371 8 6.97224 8 7.21714V16.4576L12 13.6197L16 16.4576V7.21714C16 6.97224 15.7761 6.77371 15.5 6.77371H8.5Z" />*/}
    </SvgIcon>
  );
}

