import React, { Component } from 'react';
import * as Sentry from '@sentry/react';
import MarketingModalUpgradeFallback from './MarketingModalUpgradeFallback';


/**
 * Automatically displays a backup Modal if something goes wrong
 * with the intended MarketingModal.
 **/
class MarketingModalErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state to show fallback on the next render
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can log the error to an error reporting service here
    try {
      console.error('Error caught in MarketingModalErrorBoundary:', error, errorInfo);
      Sentry.captureException(error, errorInfo)
      Sentry.captureException(error);
    } catch (err) {
      console.error('Error logging error to Sentry:', err, error, errorInfo);
    }
  }

  render() {
    const {
      children,
      suggestedPlanLevel,
      onClose,
      ...rest
    } = this.props;

    // If an error has been caught, render the fallback modal
    if (this.state.hasError) {
      return <MarketingModalUpgradeFallback suggestedPlanLevel={suggestedPlanLevel} onClose={onClose} />;
    }

    // If no error, render the children normally
    return children;
  }
}

export default MarketingModalErrorBoundary;
