import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Typography, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  root: {
    fontSize: 12,
    paddingLeft: 3,
    paddingRight: 3,
    borderRadius: 4,
    fontWeight: 900,
    textAlign: 'center',
    display: 'inline-block',
    paddingTop: 2,
    paddingBottom: 1,
    lineHeight: 1.1,
    textTransform: 'uppercase',
  },
  inverted: {
    color: theme.palette.background.paperAlt_darker1,
    backgroundColor: theme.palette.text.planTag,
  },
  filled: {
    color: theme.palette.text.planTag,
    backgroundColor: 'transparent',
    border: `2px solid currentColor`,
  },
  pill: {
    backgroundColor: theme.palette.text.planTag,
    color: theme.palette.background.paperAlt_darker1,
    borderRadius: 10,
    paddingLeft: 7,
    paddingRight: 7,
  },
  '&.--absolute': {
    position: 'absolute',
    top: 0,
    right: 0,
    transform: 'translate(-50%, -50%)',
  }
}));


function PlanTag({
  className,
  absolute,
  variant,
  ...rest
}) {
  const classes = useStyles();

  return (
    <Typography
      variant="caption"
      {...rest}
      className={clsx(
        classes.root,
        classes[variant],
        absolute && '--absolute',
        className
      )}
    >
      Pro
    </Typography>
  );
}


PlanTag.propTypes = {
  className: PropTypes.string,
  absolute: PropTypes.bool,
  variant: PropTypes.oneOf(["outlined", "filled", "pill"]),
}


PlanTag.defaultProps = {
  variant: "pill",
}

export default PlanTag;
