import _uniqueId from 'lodash/uniqueId';

/**
 * Displays global notifications via snackbar.
 *
 * The reducer will catch any payload with the 'notification' key, and pass the props to a wrapper component.
 * 'notification' key should be an object with notistack snackbar props.
 */
export const NOTIFICATION_KEY = 'notification';


export const NOTIF = {
  DEFAULT: 'default',
  SUCCESS: 'success',
  ERROR: 'error',
  INFO: 'info',
  WARNING: 'warning',
};

export const createErrorNotification = (message, props = {}) => {
  if (!message) throw new Error('Message is required for error notification');
  const key = props.key || _uniqueId('notif-');
  return {
    [NOTIFICATION_KEY]: {
      key,
      message,
      variant: NOTIF.ERROR,
      persist: false,
      autoHideDuration: 5000,
      ...props
    }
  };
};


export const createSuccessNotification = (message, props = {}) => {
  if (!message) throw new Error('Message is required for error notification');
  const key = props.key || _uniqueId('notif-');
  return {
    [NOTIFICATION_KEY]: {
      key,
      message,
      variant: NOTIF.SUCCESS,
      persist: false,
      autoHideDuration: 5000,
      ...props
    }
  };
};
