import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useInputStyles, { InputStyleProps } from 'src/app/slicedForm/shared/styles/useInputStyles';
import {
  makeStyles,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core'

const useStyles = makeStyles((theme) => ({
  root: {},
  select: {
    '& > .MuiSelect-root': {
      padding: '0 !important',
      '& $item': {
        // I don't know why the heights aren't matching the other inputs.
        // Also this is hard coded. Should be in useInputStyles.
        paddingTop: 10,
        paddingBottom: 8,
        lineHeight: 1.2,
        paddingLeft: 4,
        paddingRight: 16,
        maxHeight: 34.63,
      }
    }
  },
  itemContainer: {
    padding: '0 !important',
  },
  item: {
    borderLeft: `4px inset transparent`,
    padding: '6px 16px',
  }
}));



function ColoredDropdown({
  className,
  inputClassName,
  label,
  options,
  selected,
  disabled,
  onChange,
  error,
  innerRef,
}) {
  const selectedOpt = useMemo(() => options.find(o => o.value === selected), [selected, options])
  const classes = useStyles({ color: selectedOpt?.color });
  const inputClasses = useInputStyles();

  return (
    <FormControl
      className={clsx(
        className,
        classes.root,
        error && classes.formControlError
      )}
      ref={innerRef}
      variant="outlined"
      error={Boolean(error)}
    >
      <Select
        {...InputStyleProps}
        aria-label={label}
        className={clsx(
          classes.select,
          inputClasses.input,
          inputClassName
        )}
        value={selected}
        disabled={disabled}
        onChange={event => onChange(event.target.value)}
        MenuProps={{
          MenuListProps: { disablePadding: true },
        }}
        label={label}
      >
        {
          options.map(({ label, value, color = 'transparent' }) => (
            <MenuItem
              key={value}
              value={value}
              classes={{ root: classes.itemContainer }}
            >
              <div className={classes.item} style={{ borderColor: color }}>{label}</div>
            </MenuItem>
          ))
        }
      </Select>
    </FormControl>
  )
}


ColoredDropdown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  /** { name: label } */
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string.isRequired,
    label: PropTypes.string.isRequired,
    color: PropTypes.string,
  })).isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func
}


export default React.memo(ColoredDropdown);
