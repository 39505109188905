import React from 'react';
import clsx from 'clsx';
import {
  IconButton,
  makeStyles
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: 6,
    borderRadius: theme.grid.borderRadius,
    color: theme.palette.text.primary,
    '&:focus-visible': {
      ...theme.focus.outline
    },
    '& > .MuiIconButton-label': {
      minWidth: 22.28,
      minHeight: 22.28,
    },
    '&.--text': {
      paddingTop: 7,
      paddingBottom: 5,
      fontSize: 13,
      fontWeight: 'bold',
      fontFamily: theme.typography.fontFamily,
      lineHeight: '22.2857px',
      '& > span': {
        minWidth: 22.2857,
        textAlign: 'center'
      }
    }
  }
}));


function EntityIconButton({
  className,
  onClick,
  disabled,
  children,
  text,
  ...props
}) {
  const classes = useStyles();

  return (
    <IconButton
      className={clsx(
        className,
        classes.root,
        text && '--text',
        'entity-icon-button'
      )}
      aria-label="Add time slice"
      disableRipple
      onClick={onClick}
      disabled={disabled}
      {...props}
    >
      {children}
    </IconButton>
  );
}


export default EntityIconButton;
