// Defines all the possible values, positions, and attributes for the table.
// Needs to be kept seperate from the reducer values, and structured by table to inform the filter modal how to present itself.
// Look in the reducer to see what values are currently active. Merge with reducer values during render.
export const KEY_STATS_COLUMNS = {
  'General Info': [
    { name: 'marketCap', label: 'Market Cap', pre: '$', alarmsEnabled: true },
    { name: 'sharesoutstanding', label: 'Shares Out', alarmsEnabled: true, integer: true },
    { name: 'sharesfloat', label: 'Float', alarmsEnabled: true, integer: true },
    { name: 'shortpercentfloat', label: 'Short Float', post: '%', alarmsEnabled: true },
    { name: 'percentinsiders', label: 'Insider Own', post: '%', alarmsEnabled: true },
    { name: 'percentinstitutions', label: 'Inst Own', post: '%', alarmsEnabled: true },
    { name: 'floatRotation', label: 'Float Rotation', post: 'x', alarmsEnabled: true },
    { name: 'SSR', label: 'SSR', hardFormat: true },
    { name: 'rvolCalculated', label: 'Relative Volume' },
    { name: 'day365_high_combined', label: '52 Wk H', hardFormat: true, nullFormat: '- / -' },
    { name: 'day365_low_combined', label: '52 Wk L', hardFormat: true, nullFormat: '- / -' },
    { name: 'changeYTDCombined', label: 'YTD Changed', hardFormat: true, nullFormat: '- / -' },
    { name: 'lastSplitCombined', label: 'Last Split', hardFormat: true, nullFormat: '- / -' },
    { name: 'next_earnings', label: 'Next Earnings', hardFormat: true },
    { name: 'share_type', label: 'Share Type', hardFormat: true },
    { name: 'exchange', label: 'Exchange', hardFormat: true },
    { name: 'home_country', label: 'Based In', hardFormat: true, renderFlag: "home_country_iso" },
    { name: 'sector', label: 'Sector', hardFormat: true },
    { name: 'industry', label: 'Industry', hardFormat: true },
    { name: 'numberofemployees', label: 'Employees', hardFormat: true },
    { name: 'ipo_date', label: 'IPO Date', hardFormat: true },
  ],
  'Ratios': [
    { name: 'BurnMo', label: 'Burn/mo', alarmsEnabled: true },
    { name: 'WorkingCapital', label: 'Working Capital', cash: true, alarmsEnabled: true },
    { name: 'MonthsOfCash', label: 'Months of Cash', alarmsEnabled: true },
    { name: 'EstimatedCash', label: 'Estimated Cash', pre: '$', cash: true, alarmsEnabled: true },
    { name: 'IssuanceEquityMo', label: 'Issuance Equity/mo', cash: true, alarmsEnabled: true },
    { name: 'CurrentRatio', label: 'Current Ratio', alarmsEnabled: true },
    { name: 'DebtEquityRatio', label: 'Debt/Equity Ratio', alarmsEnabled: true },
    { name: 'TotalRevenue', label: 'Revenue', pre: '$', cash: true, alarmsEnabled: true, allowZero: true },
    { name: 'NetIncome', label: 'Net Income', cash: true, alarmsEnabled: true },
    { name: 'BasicEPS', label: 'EPS', cash: true, alarmsEnabled: true },
    { name: 'ROA', label: 'ROA', alarmsEnabled: true },
    { name: 'ROE', label: 'ROE', alarmsEnabled: true },
    { name: 'EBITDA', label: 'EBITDA', cash: true, alarmsEnabled: true },
    { name: 'CashCashEquivalentsAndShortTermInvestments', label: 'Cash & Mark Sec.', cash: true, alarmsEnabled: true },
    { name: 'CashAndCashEquivalents', label: 'Cash & Equiv', pre: '$', cash: true, alarmsEnabled: true },
    { name: 'pe_ratio', label: 'P/E Ratio', alarmsEnabled: true },
  ]
};
