import { useState, useContext, useCallback, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { usePrevious } from 'src/hooks/usePrevious';
import { useSelector } from 'react-redux';
import { selectComponent, selectLinkData } from 'src/redux/layout/topListLayoutSelectors';
import { updateLinkedData, updateComponent } from 'src/redux/layout/topListLayoutActions';
import { LINK_COLORS } from 'src/redux/layout/topListLayoutSchema';
import LayoutContext from '../app/TopListsMosaic/layout/LayoutContext';


/**
 * Change the behavior of inputs and outputs
 **/
export const LINK_DISPATCH_RULES = {
  /** Don't change the current component's state, just update others. **/
  IGNORE_SELF: 'IGNORE_SELF',
}


/**
 * Change whether the component recieves it's linked data on initialization.
 **/
export const LINK_INITIALIZATION_RULES = {
  /** Normal, default **/
  ALWAYS: 'ALWAYS',
  /** Don't initialize with linked data, start empty. **/
  SKIP: 'SKIP',
  /** Compare link.updatedAt with component.linkedStateClearedAt. If link is newer, use that. **/
  TIMESTAMP: 'TIMESTAMP',
}


/**
 * Based on props+linkedData, modify the incomming payload in some cases,
 * before we localize to state.
 * @param {object} incomingLinkedData - State from redux
 * @param {keyof LINK_COLORS} link
 * @param {string} componentId
 * @returns {[boolean, object]} \[shouldUpdate, linkedData\]
 **/
const applyDispatchRules = (incomingLinkedData, link, componentId) => {
  if (!incomingLinkedData?.dispatchRule) return [true, incomingLinkedData];
  switch (incomingLinkedData.dispatchRule) {
    case LINK_DISPATCH_RULES.IGNORE_SELF:
      if (incomingLinkedData.sourceComponentId === componentId) return [false, {}];
      return [true, incomingLinkedData];
    default:
      console.warn('Unknwon dispatch rule', incomingLinkedData.dispatchRule);
      return [true, incomingLinkedData];
  }
}

/**
 * Before we dispatch an update (to linkedData usually, but could be component state),
 * modify the outgoing payload in some cases.
 * @param {object} outgoingLinkedData - State from user actions
 * @param {keyof LINK_COLORS} link
 * @param {string} componentId
 * @returns {object} linkedData
 **/
const setDispatchRules = (outgoingLinkedData, link, sourceComponentId, dispatchRule) => {
  // strip previous values
  const { dispatchRule: _, sourceComponentId: __, ...rest } = outgoingLinkedData;
  return {
    ...rest,
    sourceComponentId,
    dispatchRule,
    updatedAt: new Date().getTime()
  }
}


const emptyObj = {};


/**
 * @param {Object} props
 * @param {keyof LINK_INITIALIZATION_RULES} props.initializationRule - Change the mount behavior
 **/
export default function useToplistLinkedValues({
  initializationRule = LINK_INITIALIZATION_RULES.ALWAYS
} = emptyObj) {
  const { componentId, layoutId } = useContext(LayoutContext);
  const {
    link,
    linkedStateClearedAt = 0,
    noLinkData = emptyObj
  } = useSelector(selectComponent(componentId, layoutId));
  const dispatch = useDispatch();
  const linkedData = useSelector(selectLinkData(link));
  const prevLinkColor = usePrevious(link);
  const [currLinkedData, setCurrLinkedData] = useState(() => {
    const _linkData = link === LINK_COLORS.none.name ? noLinkData : linkedData;
    const updatedAt = _linkData?.updatedAt || 0;

    if (initializationRule === LINK_INITIALIZATION_RULES.SKIP) {
      return {};
    }
    if (initializationRule === LINK_INITIALIZATION_RULES.TIMESTAMP) {
      if (linkedStateClearedAt > updatedAt) return {};
    }
    // Ignore shouldUpdate, because we are initializing.
    const [_, finalData] = applyDispatchRules(_linkData, link, componentId);
    return finalData;
  });

  /**
   * Allows some components (like News) to temporarily clear their ticker state,
   * without changing the underlying linked data.
   *
   * set 'linkedStateClearedAt' to be able to check we should use linked data
   * or not during component initialization.
   **/
  const clearLinkedDataForComponent = useCallback(() => {
    dispatch(updateComponent(componentId, layoutId, {
      linkedStateClearedAt: new Date().getTime()
    }));

    setCurrLinkedData({});
  }, [link, componentId, layoutId]);


  /**
   * Update linked data for current color. 
   * If "No Link":
   *    - update Component state instead of Link
   *    - dispatch to White, to allow outgoing (but not incomming) data
   *
   * @param {Object} data - Arbitrary data to send
   * @param {LINK_DISPATCH_RULES} dispatchRule - Modifiers like IGNORE_SELF
   **/
  const dispatchUpdateLinkedData = useCallback((data, dispatchRule) => {
    const payload = setDispatchRules(data, link, componentId, dispatchRule);

    if (link === LINK_COLORS.none.name) {
      dispatch(updateComponent(componentId, layoutId, {
        noLinkData: { ...payload }
      }));
    } else {
      dispatch(updateLinkedData(
        link,
        { ...payload },
        componentId,
        dispatchRule
      ));
    }
  }, [link, componentId, layoutId]);


  /**
   * Sync the component's state with the linked data, but only when the data itself changes,
   * not when the color of the link changes.
   **/
  useEffect(() => {
    if (link !== prevLinkColor) return;

    const _linkedData = link === LINK_COLORS.none.name ? noLinkData : linkedData;
    const [shouldUpdate, finalData] = applyDispatchRules(_linkedData, link, componentId);

    if (shouldUpdate) {
      setCurrLinkedData({ ...finalData });
    }
  }, [linkedData, noLinkData]);


  return useMemo(() => {
    return {
      link,
      linkedData: currLinkedData,
      dispatchUpdateLinkedData,
      clearLinkedDataForComponent,
    };
  }, [link, currLinkedData, dispatchUpdateLinkedData, clearLinkedDataForComponent])
}


