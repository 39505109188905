import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Dialog,
  makeStyles,
  Button,
} from '@material-ui/core';
import clsx from "clsx";


const useStyles = makeStyles((theme) => ({
  root: {
    '& .MuiDialog-paperScrollPaper': {
      maxWidth: 'calc(100% - 64px)',
      maxHeight: 'calc(100% - 64px)',
      width: props => props.width,
      height: props => props.height,
      overflow: 'hidden',
      backgroundColor: theme.palette.background.paper,
    },
    '&.-showBorders .MuiDialog-paper': {
      '& > .MuiDialog-container > .MuiDialog-paper > *:not(:last-child)': {
        borderBottom: '1px solid lightgray'
      }
    }
  },
  trigger: {
    paddingTop: 5,
    paddingBottom: 5,
  }
}));


function Modal({
  className,
  width,
  height,
  open,
  showBorders,
  onClose,
  stopPropagation,
  children
}) {
  const classes = useStyles({ width, height });

  return (
    <Dialog
      className={clsx(
        className,
        classes.root,
        'FormModal-Modal',
        showBorders && '-showBorders'
      )}
      maxWidth="lg"
      fullWidth
      open={open}
      onClose={onClose}
      onClick={(e) => stopPropagation && e.stopPropagation()}
      onKeyDown={(e) => {
        if (e.key === 'Escape') {
          e.stopPropagation();
          onClose();
        }
        if (stopPropagation) {
          e.stopPropagation();
        }
      }}
    >
      {children}
    </Dialog>
  );
}


Modal.propTypes = {
  className: PropTypes.string,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  height: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  open: PropTypes.bool,
  showBorders: PropTypes.bool,
  onClose: PropTypes.func,
  children: PropTypes.any,
};


Modal.defaultProps = {
  open: false,
  showBorders: true,
  width: 900,
  height: 500,
};

export default Modal;



