import React, { useContext } from 'react';
import clsx from 'clsx';
import { useSelector } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import useUserPlanPermissions from 'src/hooks/useUserPlanPermissions';
import { MosaicWindowContext } from 'react-mosaic-component';
import { selectActiveLayoutComponentTypeCount } from 'src/redux/layout/topListLayoutSelectors';
import DarkTooltip from 'src/app/components/utility/DarkTooltip';
import {
  Typography,
  Link,
  makeStyles
} from '@material-ui/core';
import ConditionalWrapper from './ConditionalWrapper';

const useStyles = makeStyles((theme) => ({
  root: {},
  button: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    textDecoration: 'none',
    backgroundColor: theme.palette.background.fixedTableHeader,
    color: theme.palette.text.primary,
    padding: '10px 5px',
    borderRadius: theme.grid.borderRadius,
    transition: 'background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.background.paperAlt,
    },
    '& .MuiSvgIcon-root': {
      margin: 0,
    },
    '& p': {
      marginLeft: theme.spacing(.5),
    }
  },
  disabled: {
    '& $button': {
      opacity: 0.5,
      cursor: 'not-allowed',
      '&:hover': {
        backgroundColor: theme.palette.background.fixedTableHeader,
      },
    }
  },
  tooltipLink: {
    fontSize: '13px !important',
    color: theme.palette.text.primary
  },
}));


function UpgradeAccountTooltip({ text, children }) {
  const classes = useStyles();
  return (
    <DarkTooltip
      interactive
      enterDelay={0}
      leaveDelay={300}
      variant="primary"
      title={(
        <Link
          to="/account/subscription/update"
          component={RouterLink}
          className={classes.tooltipLink}
          underline="none"
        >
          {text}<br />
          <u>Upgrade your account</u> to increase.
        </Link>
      )}
    >
      {children}
    </DarkTooltip>
  );
}


export function DefaultMaxReachedTooltip({ text, children }) {
  const classes = useStyles();
  return (
    <DarkTooltip
      enterDelay={0}
      leaveDelay={0}
      variant="primary"
      title={(
        <Typography variant="body1" className={classes.tooltipLink}>
          {text}<br />
        </Typography>
      )}
    >
      {children}
    </DarkTooltip>
  );
}


/**
 * Generalized component to be wrapped around a Button, intended to 
 * display a limit reached. Will redirect user to Upgrade page
 * if applicable.
 **/
function AccountLimitTooltip({
  children,
  limitReached,
  upgradeIncreasesLimit,
  text
}) {
  return (
    <ConditionalWrapper
      condition={limitReached}
      wrapper={(innerChildren) => {
        if (upgradeIncreasesLimit) {
          return <UpgradeAccountTooltip text={text}>{innerChildren}</UpgradeAccountTooltip>
        }
        else if (limitReached) {
          return <DefaultMaxReachedTooltip text={text}>{children}</DefaultMaxReachedTooltip>
        }
      }}
    >
      {children}
    </ConditionalWrapper>
  );

}

export default AccountLimitTooltip;
