import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useIntercom } from 'react-use-intercom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { logout } from 'src/redux/account/accountActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';
import {
  Button,
  Collapse,
  ListItem,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  item: {
    display: 'block',
    paddingTop: 0,
    paddingBottom: 0,
  },
  itemLeaf: {
    display: 'flex',
    paddingTop: 0,
    paddingBottom: 0,
    '& .MuiButton-root.Mui-disabled': {
      color: '#595d6c !important'
    }
  },
  button: {
    color: theme.palette.text.secondary,
    padding: '10px 8px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%'
  },
  buttonLeaf: {
    color: theme.palette.text.secondary,
    padding: '10px 4px 6px',
    justifyContent: 'flex-start',
    textTransform: 'none',
    letterSpacing: 0,
    width: '100%',
    fontWeight: theme.typography.fontWeightRegular,
    '&.depth-0': {
      '& $title': {
        fontWeight: theme.typography.fontWeightMedium
      }
    }
  },
  icon: {
    display: 'flex',
    alignItems: 'center',
    marginRight: theme.spacing(1)
  },
  title: {
    marginRight: 'auto',
    fontSize: 12,
  },
  active: {
    color: theme.palette.primary.main,
    '& $title': {
      fontWeight: theme.typography.fontWeightMedium
    }
  }
}));



function NavItem({
  title,
  href,
  action,
  isLogout,
  propKey,
  depth,
  children,
  icon: Icon,
  className,
  open: openProp,
  info: Info,
  newTab,
  disabled,
  ...rest
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(openProp);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const renderButton = () => {
    if (action) {
      return (
        <Button
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          disabled={disabled}
          onClick={action}
        >
          {Icon && (
            <Icon className={classes.icon} />
          )}
          <span className={classes.title}>
            {title}
          </span>
          {Info && <Info />}
        </Button>
      )
    }
    if (href && href.includes('http')) {
      return (
        <Button
          className={clsx(classes.buttonLeaf, `depth-${depth}`)}
          disabled={disabled}
          href={href}
          target={newTab ? "_blank" : "_self"}
        >
          {Icon && (
            <Icon className={classes.icon} />
          )}
          <span className={classes.title}>
            {title}
          </span>
          {Info && <Info />}
        </Button>
      )
    }
    return (
      <Button
        activeClassName={classes.active}
        className={clsx(classes.buttonLeaf, `depth-${depth}`)}
        component={NavLink}
        disabled={disabled}
        exact
        to={href}
      >
        {Icon && (
          <Icon
            className={classes.icon}
            size="20"
          />
        )}
        <span className={classes.title}>
          {title}
        </span>
        {Info && <Info />}
      </Button>
    )
  }



  if (children) {
    return (
      <ListItem
        className={clsx(classes.item, className)}
        disableGutters
        key={title}
        {...rest}
      >
        <Button
          className={classes.button}
          onClick={handleToggle}
          disabled={disabled}
        // style={style}
        // disableRipple
        >
          {Icon && (
            <Icon className={classes.icon} />
          )}
          <span className={classes.title}>
            {title}
          </span>
          {open ? (
            <ExpandLessIcon
              size="small"
              color="inherit"
            />
          ) : (
            <ExpandMoreIcon
              size="small"
              color="inherit"
            />
          )}
        </Button>
        <Collapse in={open}>
          {children}
        </Collapse>
      </ListItem>
    );
  }



  return (
    <ListItem
      className={clsx(classes.itemLeaf, className)}
      disableGutters
      key={propKey}
      {...rest}
    >
      {renderButton()}
    </ListItem>
  );

}


NavItem.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  depth: PropTypes.number.isRequired,
  href: PropTypes.string,
  action: PropTypes.func,
  icon: PropTypes.any,
  info: PropTypes.any,
  open: PropTypes.bool,
  isLogout: PropTypes.bool,
  title: PropTypes.string.isRequired
};

NavItem.defaultProps = {
  open: false
};

export default NavItem;
