import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  Typography,
  Box,
  makeStyles,
  darken,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  title: {
    padding: '0 .5em .6em',
    fontSize: theme.fontSize.title,
    display: 'inline-block'
  },
  titleSupplement: {
    display: 'inline-block'
  },
  row: {
    display: 'flex',
    alignItems: 'center',
    fontSize: theme.fontSize.data,
    padding: props => props.dense ? '.4em 0px .3em .6em' : '.5em 0px .5em .6em',
    borderBottom: `1px solid rgba(81, 81, 81, 1)`,
    '&:last-child': {
      borderBottom: 'none !important'
    }
  },
  cell: {
    flex: 1,
    paddingRight: 5,
    '&.ht-cell-l p': {
      color: theme.palette.text.secondary,
    },
    '&.ht-cell-r p': {
      color: theme.palette.text.primary,
      fontWeight: 500
    }
  },
  aggregateTag: {
    fontSize: 12,
    paddingLeft: 1,
    paddingRight: 1,
    marginRight: 6,
    fontWeight: 'bold',
    borderRadius: 2,
    backgroundColor: darken(theme.palette.text.secondary, .1),
    color: theme.palette.background.paperAlt_darker1,
    width: 31,
    textAlign: 'center',
    display: 'inline-block',
    paddingTop: 2,
    lineHeight: 1.1
    // border: `1px solid ${theme.palette.text.secondary}`,
  },
  secondaryValue: {
    fontWeight: 400,
    color: theme.palette.text.secondary
  }
}));


function TwoColDivTable({
  className,
  data,
  title,
  titleSupplement,
  dense,
  align
}) {
  const classes = useStyles({ dense });


  return (
    <Box className={className}>
      {title ? <Typography variant="h3" color="textPrimary" className={classes.title}>{title}</Typography> : null}
      {titleSupplement ? <Typography variant="h3" color="textSecondary" className={classes.title}> - {titleSupplement}</Typography> : null}
      <Box>
        {data.map((row, i) => {
          return (
            <div className={clsx(classes.row, 'two-col-div-table-row')} key={i}>
              <div className={clsx(classes.cell, 'ht-cell-l', 'ht-cell')}>

                <Typography variant="body2" color="textPrimary">
                  {Boolean(row.tag) && <span className={classes.aggregateTag} style={{ backgroundColor: row?.tagColor }}>{row.tag}</span>}
                  {row.label}
                </Typography>
              </div>
              <div className={clsx(classes.cell, 'ht-cell-r', 'ht-cell')} style={{ textAlign: align }}>
                <Typography variant="body2" color="textPrimary">
                  {row.component ? row.component : row.value}
                  {Boolean(row.secondary) && <span className={classes.secondaryValue}> ({row.secondary})</span>}
                </Typography>
              </div>
            </div>
          );
        })}
      </Box>
    </Box>
  );
}


TwoColDivTable.propTypes = {
  className: PropTypes.string,
  data: PropTypes.array,
  title: PropTypes.string,
  titleSupplement: PropTypes.string,
  dense: PropTypes.bool,
  align: PropTypes.string,
}


TwoColDivTable.defaultProps = {
  dense: false,
  align: 'center',
}

export default TwoColDivTable;

