import React, { useState, useEffect, useRef } from 'react';
import clsx from 'clsx';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import {
  useNotificationValue
} from '../context/NotificationProvider';
import {
  Fade,
  Grow,
  makeStyles,
} from '@material-ui/core';
import {
  Alert,
  AlertTitle
} from '@material-ui/lab'



const useStyles = makeStyles((theme) => ({
  root: {
    // fontSize: 14,
    paddingTop: 4,
    paddingBottom: 4,
    position: 'absolute',
    zIndex: 9999,
    bottom: 15,
    left: 15,
    '& .MuiAlert-message': {
      display: 'flex',
      alignItems: 'center',
      fontSize: 13,
      paddingTop: 10,
      padingBottom: 6
    }
  },
  variantError: {},
  vairantWarning: {},
  variantInfo: {},
}));


/**
 * Positioned absolutely, not a portal.
 */
function NotificationSnackbar({
  className,
}) {
  const classes = useStyles();
  const { notification } = useNotificationValue();

  return (
    <Fade in={notification?.visible}>
      <div>
        <Alert
          elevation={6}
          className={clsx(className, classes.root)}
          severity={notification?.severity}
        >
          {Boolean(notification?.title) && (
            <AlertTitle>{notification?.title}</AlertTitle>
          )}
          {notification?.message}
        </Alert>
      </div>
    </Fade>
  )
}


export default NotificationSnackbar;