import {
  lighten,
  alpha,
  makeStyles
} from '@material-ui/core';


const FIXED_WIDTHS = {
  formControlMin: 25,
  formControlMarginLeft: 12,
  separatorMarginLeft: 5,
  comparisonSelect: 70,
  comparisonSelectLarger: 75,
  timeSlice: 95,
  valueInput: 120,
  calendarButton: 38,
};

const autocompleteTarget = '.MuiAutocomplete-inputRoot[class*="MuiInput-root"] .MuiAutocomplete-input:first-child';

const purpleHighlight = '#343853';
// const lowlight = '#65686E';
const lowlight = lighten('#2E333F', .03);

/**
 * Classes for types of inputs.
 * Extracted here to hopefully make different form stylings easier.
 * Just pass the useStyles down through Context if we need multiple.
 */
export default makeStyles((theme) => ({
  input: {
    ['&:not($highlight):not($purpleHighlight):not($fakeSingleRangeInput):not($lowlight) .MuiInputBase-root:hover, ' +
      '&:not($highlight):not($purpleHighlight):not($fakeSingleRangeInput):not($lowlight) .MuiInputBase-root:focus,' +
      '&:not($highlight):not($purpleHighlight):not($fakeSingleRangeInput):not($lowlight) .MuiSelect-root:hover,' +
      '&:not($highlight):not($purpleHighlight):not($fakeSingleRangeInput):not($lowlight) .MuiSelect-root:focus'
    ]: {
      backgroundColor: `${lighten(theme.palette.background.paper, .05)} !important`
    },
    '& .MuiInputBase-root, & .MuiSelect-root': {
      '&:focus-visible': {
        ...theme.focus.outline
      }
    },
    '& .MuiInputAdornment-positionEnd, .MuiAutocomplete-endAdornment': {
      marginLeft: 0,
      '& .MuiIconButton-root': {
        borderRadius: theme.grid.borderRadius,
        padding: '4px 3px'
      }
    },
    '& .MuiInputBase-root, .MuiSelect-root': {
      borderRadius: theme.grid.borderRadius,
      backgroundColor: theme.palette.background.paper,
      transition: 'background-color 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
      fontSize: 14,
      lineHeight: 1,
      paddingBottom: [[0, '!important']]
      // height: 'unset'
    },
    [`&.MuiButton-root, .MuiInputBase-input, ${autocompleteTarget}`]: {
      fontSize: 14,
      paddingLeft: 8,
      paddingTop: 10,
      paddingBottom: 8,
      textTransform: 'none',
      fontWeight: 400
    },
    '& .MuiOutlinedInput-notchedOutline': {
      display: 'none'
    },
    '&.--less-gutter .MuiSelect-select.MuiSelect-select': {
      paddingRight: 16,
      paddingLeft: 4,
    },
    '& .MuiSelect-select.MuiSelect-select': {
      paddingRight: 18,
      paddingLeft: 6,
    },
    '& .MuiSelect-icon': {
      fontSize: 16,
      top: 'calc(50% - 8px)',
    }
  },
  filterEntityWithLeftPlaceholder: {
    paddingLeft: FIXED_WIDTHS.comparisonSelectLarger + FIXED_WIDTHS.formControlMarginLeft
  },
  adornedEnd: {},
  buttonTextPlaceholder: {
    '&$buttonAsInput': {
      '& .MuiButton-label': {
        color: alpha(theme.palette.text.primary, .5),
      }
    }
  },
  buttonAsInput: {
    textOverflow: 'ellipsis',
    overflowX: 'hidden',
    '& .MuiButton-label': {
      justifyContent: 'flex-start'
    }
  },
  button: {
    '& .MuiButton-label': {
      lineHeight: 'normal'
    },
    '&:not($backgroundButton)': {
      backgroundColor: theme.palette.background.paper,
    },
    '&:focus-visible': {
      ...theme.focus.outline
    },
    borderRadius: theme.grid.borderRadius,
    fontSize: 14,
    padding: [[10, 8, 8, 8]],
    transition: 'background-color 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    textTransform: 'none',
    textWrap: 'nowrap',
    fontWeight: 400,
    height: '100%',
    '&$chipButton': {
      justifyContent: 'space-between',
      padding: [[2, 0, 2, 2]],
      '& > .MuiButton-label': {
        height: 32,
      },
      '& .MuiChip-root': {
        maxWidth: 145,
        marginRight: 4,
        backgroundColor: 'rgba(0,0,0,.2)'
      }
    }
  },
  chipButton: {},
  backgroundButton: {
    fontSize: 13,
    '& > .MuiButton-label': {
      lineHeight: [['16.43px', '!important']]
    }
  },
  purpleHighlight: {
    '&$singleRangeInputControl': {
      backgroundColor: purpleHighlight,
      '&:hover': {
        backgroundColor: `${lighten(purpleHighlight, .05)} !important`,
      }
    },
    // backgroundColor: '#343853',
    '&.MuiButton-root, .MuiSelect-root': {
      backgroundColor: purpleHighlight,
    },
    '&.MuiButton-root:hover, .MuiSelect-root:hover': {
      backgroundColor: [[lighten(purpleHighlight, .05), '!important']]
    }
  },
  highlight: {
    '&$singleRangeInputControl': {
      backgroundColor: theme.palette.background.paperAlt_highlight,
      '&:hover': {
        backgroundColor: `${lighten(theme.palette.background.paperAlt_highlight, .05)} !important`,
      }
    },
    // backgroundColor: theme.palette.background.paperAlt_lighter,
    '&.MuiButton-root, .MuiInputBase-root, .MuiSelect-root': {
      backgroundColor: theme.palette.background.paperAlt_highlight,
    },
    '&.MuiButton-root:hover, .MuiInputBase-root:hover, .MuiSelect-root:hover': {
      backgroundColor: [[lighten(theme.palette.background.paperAlt_highlight, .05), '!important']]
    }
  },
  lowlight: {
    '&$singleRangeInputControl': {
      backgroundColor: lowlight,
      '&:hover': {
        backgroundColor: `${lighten(lowlight, .05)} !important`,
      }
    },
    // backgroundColor: theme.palette.background.paperAlt_lighter,
    '&.MuiButton-root, .MuiInputBase-root, .MuiSelect-root': {
      backgroundColor: lowlight,
      // background: 'var(--Text-Input, rgba(59, 65, 80, 0.38))',
      // boxShadow: '-1px -1px 2px 0px rgba(88, 88, 88, 0.18), 1px 1px 2px 0px rgba(0, 0, 0, 0.35)',
    },
    '&.MuiButton-root:hover, .MuiInputBase-root:hover, .MuiSelect-root:hover': {
      backgroundColor: [[lighten(lowlight, .05), '!important']]
    }
  },
  calendarAdornment: {
    '& .range-end-adornment, &.range-end-adornment': {
      marginTop: 10,
    },
    '& .range-end-adornment .MuiIconButton-root, &.range-end-adornment .MuiIconButton-root': {
      borderRadius: theme.grid.borderRadius,
      padding: '5.2px 5px'
    }
  },
  calendarAdornmentFakeRange: {
    '& .range-end-adornment, &.range-end-adornment': {
      marginTop: '0 !important',
    },
    '& .MuiInputAdornment-positionEnd': {
      marginLeft: '0 !important'
    }
  },
  separator: {
    marginLeft: FIXED_WIDTHS.separatorMarginLeft,
    marginRight: -(FIXED_WIDTHS.formControlMarginLeft - FIXED_WIDTHS.separatorMarginLeft),
    '& p': {
      padding: 0,
      margin: 0,
      color: theme.palette.text.secondary,
      fontSize: 12,
      lineHeight: .9
    }
  },
  formControl: {
    marginLeft: FIXED_WIDTHS.formControlMarginLeft,
    minWidth: FIXED_WIDTHS.formControlMin,
    width: '100%',
    '&.--first': {
      marginLeft: 0
    }
  },
  formControlError: {
    '&$singleRangeInputControl': {
      boxShadow: `0 0 1px 1px ${alpha(theme.palette.error.main, .7)}`
    },
    ['&:not($singleRangeInputControl) .MuiInputBase-root, ' +
      '&:not($singleRangeInputControl) .MuiSelect-root, ' +
      '&:not($singleRangeInputControl) .MuiButton-root'
    ]: {
      boxShadow: `0 0 1px 1px ${alpha(theme.palette.error.main, .7)}`
    },
    '&.flash-error': {
      animation: '$flash 3s ease-out'
    }
  },
  '@keyframes flash': {
    '0%': {
      boxShadow: `0 0 5px 3px ${alpha(theme.palette.error.main, 1)}`
    },
    '100%': {
      boxShadow: `0 0 1px 1px ${alpha(theme.palette.error.main, .7)}`
    }
  },
  comparisonSelectControl: {
    maxWidth: FIXED_WIDTHS.comparisonSelect,
    '& .MuiInputBase-root': {
      textAlign: 'center',
    }
  },
  comparisonSelectControlLarger: {
    maxWidth: FIXED_WIDTHS.comparisonSelectLarger,
    '& .MuiInputBase-root': {
      textAlign: 'center',
    }
  },
  valueTypeSelectControl: {
    maxWidth: FIXED_WIDTHS.comparisonSelect,
    '& .MuiInputBase-root': {
      textAlign: 'center',
    }
  },
  timeSliceControl: {
    maxWidth: FIXED_WIDTHS.timeSlice,
    width: '100%',
    '& .MuiInputAdornment-positionEnd .MuiSvgIcon-root': {
      fontSize: 23
    }
  },
  valueInputControl: {
    maxWidth: FIXED_WIDTHS.valueInput,
  },
  // We pretend two inputs are one by manually screwing with the widths and colors
  fakeSingleRangeInput: {
    '& input::placeholder': {
      transform: 'scale(.9, 1)',
      transformOrigin: 'left',
      paddingLeft: 0,
      marginLeft: 0
    }
  },
  fakeSingleRangeTimeInput: {
    width: 53
  },
  fakeSingleRangeDateInput: {
    width: 86
  },
  singleRangeInputControl: {
    width: 'unset !important',
    transition: 'background-color 100ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    // backgroundColor: theme.palette.background.paper,
    borderRadius: theme.grid.borderRadius,
    maxWidth: (FIXED_WIDTHS.valueInput * 2) + FIXED_WIDTHS.formControlMarginLeft,
    '& .MuiInput-formControl': {
      backgroundColor: 'transparent !important'
    }
  },
  formControlValueTypePlusValue: {
    maxWidth: (
      FIXED_WIDTHS.comparisonSelect + FIXED_WIDTHS.formControlMarginLeft + FIXED_WIDTHS.valueInput
    )
  },
  rangeInputControl: {
    // maxWidth: FIXED_WIDTHS.valueInput - (FIXED_WIDTHS.calendarButton / 2)
    maxWidth: FIXED_WIDTHS.valueInput
  },
  formControlTripleWide: {
    maxWidth: (
      ((FIXED_WIDTHS.comparisonSelect + FIXED_WIDTHS.formControlMarginLeft) * 2) + FIXED_WIDTHS.valueInput
    )
  },
  multiSelectInputControl: {
    '& .Mui-disabled .MuiChip-root': {
      opacity: .6
    },
    maxWidth: (
      FIXED_WIDTHS.comparisonSelect +
      FIXED_WIDTHS.formControlMarginLeft +
      FIXED_WIDTHS.valueInput
    ),
  }
}));


/** Styling props we should apply to all inputs */
export const InputStyleProps = {
  variant: 'standard',
  label: '',
  disableUnderline: true,
};


export const ButtonStyleProps = {
  disableRipple: true
};


export const flashError = (
  target = '.sf-form-control-error-flash',
  classname = 'flash-error'
) => {
  try {
    document.querySelectorAll(target).forEach(el => {
      el.classList.remove(classname);
      void el.offsetWidth;
      el.classList.add(classname);
      el.addEventListener('animationend', () => {
        el.classList.remove(classname);
      }, { once: true });
    })
  } catch (err) {
    console.warn(err)
  }
};
