import TableCellHoverMenu from '../tables/TableCellHoverMenu';
import TickerHoverMenu from '../tables/TickerHoverMenu';
import SSRIconCell from '../elements/SSRIconCell';
import React from 'react';
import { formatStatCellNumber } from 'src/utils/formatStatCellNumber';
import clsx from 'clsx';

/***
 * Takes active columns, and returns the ag-grid version of those column's schema. Also filters out any columns that are not in the schema.
 * @param {string[]} activeColumns - List of columns the user has selected
 * @param {object[]} schema - Our full column definition list
 * @returns {object[]} - The column definitions that match the active columns, formatted for ag-grid
 */
export const reduceColumnSchema = (activeColumns, schema) => {
  return activeColumns.reduce((acc, c) => {
    const col = schema.find(lc => lc.name === c);
    if (col) {
      acc.push({ ...col });
    }
    return acc;
  }, []);
};


/**
 * Decide which column to sort by when the user clicks on a column header, using previous state and ag-grids sort params.
 * We're setting sort on the columnDefinitions to initialize the grid. After that, we're letting the grid track state.
 * We decide the state from this function, then persist it to redux in order for the next page load to keep sort.
 *
 * NOTE: The sort icon will not work for these defaults. We can't set the sort icon without causing the grid to double-fetch
 *
 * @param dataSourceParams - The ag-grid request object
 * @param previousSort {{order: string, orderby: string}} - The last tracked sort state
 * @param backupSortColumns {string[]} - If the requested sort is invalid, use these instead
 * @returns {{orderby: string, order: string}} - The new sort state
 */
export const decideDataSourceSort = (dataSourceParams, previousSort, backupSortColumns = []) => {

  const { order, orderby } = previousSort;
  const { columnApi, request } = dataSourceParams;
  try {
    const { sort, colId } = request.sortModel[0];
    return { order: sort, orderby: colId };
  } catch (err) {
    // empty
  }

  // If the previous sort column still exists, we want to sort it by absolute value
  const colDefs = columnApi.getAllGridColumns();

  if (colDefs.find(col => col.colId === orderby)) {
    return { order: 'abs', orderby };
  }

  // If not, then use defaults
  const defaultSortColumn = colDefs.find(colDef => backupSortColumns.find(name => colDef.colId === name && colDef.colDef?.sortable));

  if (defaultSortColumn) {
    return { order: 'desc', orderby: defaultSortColumn.colId }
  }

  // If no defaults, then pick first sortable value
  const firstSortableCol = colDefs.find(colDef => colDef.colDef?.sortable);
  if (firstSortableCol) {
    return { order: 'desc', orderby: firstSortableCol.colId }
  }

  // If not, then whatever... Just let the grid do its thing, the user can click another column to get an expected result.
  return { order, orderby };
};


export const renderTickerCell = ({ data, value, rowIndex }) => {
  return (
    <TableCellHoverMenu
      id={`hvm-${rowIndex}`}
      Menu={<TickerHoverMenu tickerId={value} handleSelect={() => { }} />}
    >
      <SSRIconCell ssr={!!data.ssr}>{value}</SSRIconCell>
    </TableCellHoverMenu>
  );
};


export const renderTickerSSRCell = ({ data, value, context }) => {
  let classes = ['ag-grid-ticker-ssr-cell'];
  if (context?.activeWatchlistTickerSet && context.activeWatchlistTickerSet.includes(value)) {
    classes.push('ag-grid-corner-notification')
  }
  return (
    <SSRIconCell
      ssr={!!data.ssr}
      className={clsx(classes)}
    >
      {value}
    </SSRIconCell>
  )
}


export const formatCellValue = (args, { pre, post, integer, hardFormat, zeroValueFormat }) => {
  if (args.value === 0 && zeroValueFormat) return zeroValueFormat;
  if (!args.value && args.value !== 0 && args.value !== '0') return null;
  if (hardFormat || React.isValidElement(args.value)) return args.value;
  let s = '';
  if (pre) s += pre;
  s += formatStatCellNumber(args.value, false, integer);
  if (post) s += post;

  return s;
};


export const integerToBooleanFormatter = (args) => {
  if (args.value === 1 || args.value === '1' || args.value === true) return 'Yes';
  if (args.value === 0 || args.value === '0' || args.value === false) return 'No';
  return ' ';
};


export const renderHighlightClass = ({ value }) => {
  if (value < 0) {
    return 'ett-cell-negative';
  } else if (value > 0) {
    return 'ett-cell-positive';
  }
};
