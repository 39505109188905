import { makeStyles } from '@material-ui/core';

const LIST_ITEM_HEIGHT = 32;

export default makeStyles((theme) => ({
  root: {
    position: 'relative',
    height: LIST_ITEM_HEIGHT,
  },
  disabled: {
    pointerEvents: 'none',
    // opacity: .6
  },
  listLeft: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    transition: 'none !important',
    paddingRight: 4,
    paddingLeft: 4,
    paddingTop: 2,
    paddingBottom: 2,
    height: '100%',
    backgroundColor: props => props.editing ? 'rgba(255, 255, 255, 0.08)' : 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  listRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  selected: {
    '& $title': {
      color: theme.palette.primary.main
    }
  },
  title: {
    fontSize: 13,
    color: theme.palette.text.primary,
    fontWeight: 400,
    paddingLeft: 3,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: props => props.textWidth || 126,
    textOverflow: 'clip'
  },
  inputBase: {
    fontSize: 13,
    paddingLeft: 8,
    color: theme.palette.text.primary,
    fontWeight: 400,
    '& input': {
      paddingTop: 7,
      paddingBottom: 6
    }
  },
  checkbox: {
    '&:hover': {
      backgroundColor: 'transparent !important'
    }
  },
  disabledCheckbox: {
    cursor: 'not-allowed',
    '&.Mui-checked': {
      color: [theme.palette.text.disabled, '!important']
    }
  },
  createLayoutButton: {
    width: '100%',
    paddingTop: '4px !important',
    paddingBottom: '4px !important',
    justifyContent: 'center',
    borderRadius: '0 !important',
    '& .MuiTypography-root': {
      fontSize: 13,
      paddingLeft: 0
    },
    '& .MuiSvgIcon-root': {
      margin: 0,
      paddingRight: 0,
    },
  },
  newItemBorder: {
    borderBottom: `2px solid ${theme.palette.background.panelHeader}`,
  },
  listIcon: {
    '& .MuiIconButton-root': {
      padding: 4,
      borderRadius: 4,
    },
    '& .MuiIconButton-root:not(.MuiCheckbox-root)': {
      color: theme.palette.text.primary,
    }
  },
  iconRed: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.negative
    }
  },
  iconGreen: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.positive
    }
  },
  confirmIcon: {
    '& .MuiSvgIcon-root': {

    }
  },
  denyIcon: {
    '& .MuiSvgIcon-root': {

    }
  },
  checkboxIcon: {
    '& svg': {
      fontSize: 20
    }
  },
  editIcon: {
    '& .MuiSvgIcon-root': {

    }
  }
}));
