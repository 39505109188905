import React from 'react';
import { FilterIcon } from 'src/theme/EdgeIcons';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import ConditionalWrapper from 'src/app/components/utility/ConditionalWrapper';
import {
  Tooltip,
  makeStyles,
  IconButton,
  Typography,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '0px 8px',
    borderRadius: 4,
    display: 'flex',
    alignItems: 'center',
    marginBottom: 0,
    height: '100%',
    '& .MuiSvgIcon-root': {
      marginBottom: 0,
      fontSize: 24,
      color: theme.palette.text.secondary,
    },
  },
  iconButtonLabel: {
    margin: 0,
    padding: '1px 0 0 6px',
    fontSize: 12,
    fontWeight: 600,
    lineHeight: 1,
    color: theme.palette.text.secondary
  },
  disabled: {
    opacity: '.6',
    '& .MuiSvgIcon-root': {
      opacity: '.6 !important'
    }
  },
}));


function TabsBarButton({
  className,
  Icon,
  text,
  shouldHideIconText,
  onClick,
  disabled,
  ariaLabel = ''
}) {
  const classes = useStyles();

  return (
    <ConditionalWrapper
      condition={shouldHideIconText}
      wrapper={children => <Tooltip enterDelay={0} enterNextDelay={0} title={text} placement="top">{children}</Tooltip>}
    >
      <IconButton
        disableRipple
        onClick={(event) => { if (onClick) onClick(event); }}
        disabled={disabled}
        aria-label={ariaLabel}
        className={clsx(classes.root, 'ett-panel-icon-button', className, disabled && classes.disabled)}
      >
        <Icon />
        {text && !shouldHideIconText && <Typography variant="body2" color="textPrimary" className={classes.iconButtonLabel}>{text}</Typography>}
      </IconButton>
    </ConditionalWrapper>
  );
}


TabsBarButton.propTypes = {
  className: PropTypes.string,
  Icon: PropTypes.func,
  text: PropTypes.string,
  shouldHideIconText: PropTypes.bool,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  ariaLabel: PropTypes.string
};


TabsBarButton.defaultProps = {
  Icon: FilterIcon,
  shouldHideIconText: false,
  disabled: false
};



export default TabsBarButton;
