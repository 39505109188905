import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { addWatchlistTicker, removeWatchlistTicker } from 'src/redux/layout/topListLayoutActions';
import { selectListActiveWatchlists } from 'src/redux/layout/topListLayoutSelectors';


/*
Show Export / Copy and Paste / Links

Show Watchlist:
if this menu is a watchlist, show remove ticker option
If this item has a ticker, show "Add to watchlist" option
*/


function useWatchlistContextMenu({ 
  forceTicker = null 
}) {
  const dispatch = useDispatch();


  const [activeWatchlists, otherWatchlists] = useSelector(selectListActiveWatchlists);


  return useCallback((contextMenuParams) => {
    const ticker = contextMenuParams?.node?.data?.ticker || forceTicker;
    const watchlistProfileId = contextMenuParams?.context?.watchlistProfileId;
    const watchlistItemId = contextMenuParams?.node?.data?.item_id;

    if (!ticker) return [];

    const watchlistSection = [];

    if (watchlistProfileId && watchlistItemId) {
      watchlistSection.push({
        name: `Remove <strong>${ticker}</strong> from this watchlist`,
        action: () => dispatch(removeWatchlistTicker(watchlistProfileId, watchlistItemId))
      });
    }

    if (activeWatchlists.length) {
      watchlistSection.push(...activeWatchlists.filter(wl => wl.id !== watchlistProfileId).map(wl => ({
        name: `Add <strong>${ticker}</strong> to <strong>${wl.profileName}</strong>`,
        action: () => dispatch(addWatchlistTicker(wl.id, ticker))
      })));
    }

    if (otherWatchlists.length) {
      watchlistSection.push({
        name: `Add to watchlist...`,
        subMenu: otherWatchlists.filter(wl => wl.id !== watchlistProfileId).map(wl => ({
          name: wl.profileName,
          action: () => dispatch(addWatchlistTicker(wl.id, ticker))
        }))
      });
    }

    return watchlistSection;
  }, [activeWatchlists, otherWatchlists]);
}


export default useWatchlistContextMenu;