import React, { useState } from 'react';
import clsx from 'clsx';
import _find from 'lodash/find';
import slugify from 'slugify';
import { MoveIcon } from 'src/theme/EdgeIcons';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import ClearIcon from '@material-ui/icons/Clear';
import {
  List,
  ListItem,
  ListItemText,
  Typography,
  makeStyles
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    '& .list-item-draggable-secondary-action': {
      right: 13,
      fontSize: 0,
      pointerEvents: 'none',
      zIndex: 0,
      position: 'absolute'
    },
    '& .draggable-item': {
      paddingLeft: '6px !important',
    },
    '& .ks-column-title-0': {
      paddingTop: 8
    },
    '& .list-item-draggable-handle': {
      '&:hover svg': {
        color: theme.palette.text.primary,
      },
      '& svg': {
        color: '#777A82',
        paddingRight: 8,
        fontSize: 27
      }
    },
    '& .list-item-remove-button': {
      padding: 0,
      borderRadius: 4,
      '& .MuiSvgIcon-root': {
        fontSize: 20
      },
    }
  },
  title: {
    padding: '7px 10px',
    backgroundColor: theme.palette.background.fixedTableHeader,
    fontWeight: 'bold'
  },
  scrollContainer: {
    height: '100%'
  },
  scrollContainerNative: {
    height: '100%',
    overflowY: 'auto',
    paddingTop: 10
  },
  scrollParent: {
    height: '100%',
    overflowY: 'auto',
    flex: 1
  },
  columnWrap: {}
}));


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getListStyle = (isDraggingOver) => {
  return isDraggingOver ? { pointerEvents: 'none' } : {};
};


const moveBetweenLists = (source, destination, droppableSource, droppableDestination, maxPerTable) => {
  const sourceCanvasId = +droppableSource.droppableId;
  const destCanvasId = +droppableDestination.droppableId;
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  // flow element when need to swap
  if (destination.length >= maxPerTable) {
    if (destCanvasId < sourceCanvasId) {
      const [flow] = destClone.splice(destClone.length - 1, 1);
      sourceClone.splice(0, 0, flow);
    } else if (destCanvasId > sourceCanvasId) {
      const [flow] = destClone.splice(0, 1);
      sourceClone.splice(sourceClone.length, 0, flow);
      if (droppableDestination.index !== 0) {
        droppableDestination.index--;
      }
    }
  }

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[sourceCanvasId] = sourceClone;
  result[destCanvasId] = destClone;

  return result;
};


function SelectedMetrics({
  className,
  title = 'Selected Columns',
  allItems,
  selectedTables,
  onRemoveItem,
  onReorder,
  maxPerTable,
  editLocked = false
}) {
  const classes = useStyles();
  const [disabled, setDisabled] = useState(() => selectedTables.map(() => false));


  const onDragEnd = (result) => {
    const { source, destination } = result;
    if (disabled.some(f => f === true)) {
      setDisabled(selectedTables.map(() => false));
    }

    if (!destination) {
      return;
    }
    const sourceIdx = +source.droppableId;
    const destIdx = +destination.droppableId;
    const newTables = [...selectedTables];
    if (sourceIdx === destIdx) {
      newTables[sourceIdx] = reorder(selectedTables[sourceIdx], source.index, destination.index);
    } else {
      const result = moveBetweenLists(selectedTables[sourceIdx], selectedTables[destIdx], source, destination, maxPerTable);
      newTables[sourceIdx] = result[sourceIdx];
      newTables[destIdx] = result[destIdx];
    }
    onReorder(newTables);
  };


  const getItemStyle = (isDragging, draggableStyle) => {
    const style = {
      userSelect: 'none',
      position: 'relative',
      ...draggableStyle,
    };
    if (isDragging) {
      style.opacity = .6;
    }
    return style;
  };


  return (
    <div className={clsx(className, classes.root)}>
      <Typography className={classes.title}>{title}</Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        {/*<div className="styled-native-scroll-hover-target">*/}
        <div className={clsx(classes.scrollParent, 'modal-horizontal-scrollbar-sm')}>
          {selectedTables.map((table, tableIdx) => (
            <div className={classes.columnWrap} key={tableIdx}>
              {selectedTables.length > 1 && <Typography className={`ks-column-title ks-column-title-${tableIdx}`}>{`Column ${tableIdx + 1}`}</Typography>}
              <Droppable droppableId={`${tableIdx}`} isDropDisabled={disabled[tableIdx]}>
                {(provided, snapshot) => (
                  <List
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    style={getListStyle(snapshot.isDraggingOver)}
                  >
                    {table.map((name, index) => {
                      const { label } = _find(allItems, { name });
                      return (
                        <Draggable key={name} draggableId={name} index={index} isDragDisabled={editLocked}>
                          {(provided, snapshot) => {
                            // console.log(provided, snapshot);
                            const style = getItemStyle(snapshot.isDragging, provided.draggableProps.style, parseInt(snapshot.draggingOver), tableIdx);
                            // disableOtherListDroppable(snapshot.isDragging, parseInt(snapshot.draggingOver), tableIdx);
                            return (
                              <ListItem
                                title={label}
                                className="top-news-filter-list-item draggable-item"
                                key={name}
                                role={undefined}
                                disableRipple
                                disabled={editLocked}
                                button
                                onClick={() => onRemoveItem(name)}
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                style={style}
                              >
                                <div className="list-item-draggable-handle" {...provided.dragHandleProps} onClick={(e) => e.stopPropagation()}>
                                  <MoveIcon/>
                                </div>
                                <ListItemText id={slugify(name) + '-ks-label'}>{label}</ListItemText>
                                <div className="list-item-draggable-secondary-action">
                                  <ClearIcon color="primary" className="list-item-remove-button"/>
                                </div>
                              </ListItem>
                            );
                          }}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </List>
                )}
              </Droppable>
            </div>
          ))}
        </div>
        {/*</div>*/}
      </DragDropContext>
    </div>
  );
}


export default SelectedMetrics;
