import { makeStyles } from '@material-ui/core';

const textColor = '#b2b5be';
const bgColor = '#1e222d';
const borderColor = '#434651';

export const useDialogStyles = makeStyles(() => ({
  root: {
    backgroundColor: 'transparent !important',
    '& .MuiBackdrop-root': {
      backgroundColor: 'transparent !important'
    },
    '& .MuiPaper-root': {
      borderRadius: 6,
      backgroundColor: bgColor,
      maxWidth: 450
    }
  },
  modalHeader: {
    borderBottom: `1px solid ${borderColor}`,
    padding: '15px 15px'
  },
  modalTitle: {
    margin: 0,
    color: textColor,
    fontSize: 20
  },
  modalBody: {
    overflowY: 'auto'
  },
  disabled: {
    opacity: .7,
    pointerEvents: 'none !important'
  },
}));



export const useListItemStyles = makeStyles((theme) => ({
  root: {
    position: 'relative',
  },
  selected: {
    backgroundColor: 'rgba(255, 255, 255, 0.08)',
  },
  listLeft: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    cursor: 'pointer',
    transition: 'none !important',
    paddingRight: 10,
    paddingLeft: 10,
    paddingTop: 3,
    paddingBottom: 3,
    backgroundColor: props => props.editing ? 'rgba(255, 255, 255, 0.08)' : 'transparent',
    '&:hover': {
      backgroundColor: 'rgba(255, 255, 255, 0.08)',
    },
  },
  listRight: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    position: 'absolute',
    right: 0,
    top: '50%',
    transform: 'translateY(-50%)',
  },
  title: {
    fontSize: 16,
    color: textColor,
    fontWeight: 400,
    paddingLeft: 3,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    width: 300,
    textOverflow: 'clip',
    height: 'unset',
    lineHeight: 1.2,
    paddingTop: 7,
    paddingBottom: 6
  },
  inputBase: {
    fontSize: 16,
    paddingLeft: 8,
    color: textColor,
    fontWeight: 400,
    '& input': {
      paddingTop: 7,
      paddingBottom: 6,
      height: 'unset',
      lineHeight: 1.2
    }
  },
  createLayoutButton: {
    width: '100%',
    paddingTop: `7px !important`,
    paddingBottom: `7px !important`,
    justifyContent: 'center',
    borderRadius: 0,
    '& .MuiTypography-root': {
      fontSize: 16,
      paddingLeft: 6,
      fontWeight: 400,
    },
    '& .MuiSvgIcon-root': {
      margin: 0,
      paddingRight: 0,
    },
  },
  newItemBorder: {
    borderBottom: `1px solid ${borderColor}`,
  },
  listIcon: {
    '& .MuiIconButton-root': {
      padding: 4,
      borderRadius: 4,
    },
    '& .MuiIconButton-root:not(.MuiCheckbox-root)': {
      color: textColor,
      fontSize: 26,
    }
  },
  loadingIcon: {
    width: 30.28,
    height: 30.28,
    marginRight: 6,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  iconRed: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.negative
    }
  },
  iconGreen: {
    '& .MuiSvgIcon-root': {
      color: theme.palette.text.positive
    }
  },
}));
