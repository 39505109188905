import React, { useContext } from 'react';


export const ListContext = React.createContext({
  role: 'listbox',
});

export const ItemContext = React.createContext({
  disabled: false
});

// const defaultDoubleConfirmationContext = {
//   confirming: {},
//   isAnyConfirming: false,
//   setConfirming: () => { },
//   clearConfirming: () => { },
// }

// const DoubleConfirmationContext = React.createContext(defaultDoubleConfirmationContext);


