import { useEffect, useMemo, useRef } from 'react';
import _isEqual from 'lodash/isEqual';

function deepCompareEquals(a, b) {
  return _isEqual(a, b);
}

function checkDeps(deps, name) {
  if (!deps || deps.length === 0) {
    throw new Error(
      `${name} should not be used with no dependencies. Use deafault React implementation instead.`
    );
  }
}

function useDeepCompareMemoize(value) {
  const ref = useRef()
  // it can be done by using useMemo as well
  // but useRef is rather cleaner and easier

  if (!deepCompareEquals(value, ref.current)) {
    ref.current = value
  }

  return ref.current
}

export function useDeepCompareEffect(effect, dependencies) {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies, 'useDeepCompareEffect');
  }

  useEffect(
    effect,
    dependencies.map(useDeepCompareMemoize)
  );
}


export function useDeepCompareMemo(factory, dependencies) {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies, 'useDeepCompareMemo');
  }

  return useMemo(
    factory,
    dependencies.map(useDeepCompareMemoize)
  )
}


export function useDeepCompareCallback(callback, dependencies) {
  if (process.env.NODE_ENV !== 'production') {
    checkDeps(dependencies, 'useDeepCompareCallback');
  }

  return useMemo(
    callback,
    dependencies.map(useDeepCompareMemoize)
  )
}
