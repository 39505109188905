import produce from 'immer';
import { isObjEmpty } from 'src/utils/isObjEmpty';
import {
  FETCH_FILINGS_TICKER,
  SET_VIEWED_FILING,
  FETCH_EARNINGS_TRANSCRIPT_HISTORY,
  REQUEST__FETCH_FILINGS_TICKER,
  REQUEST__FETCH_EARNINGS_TRANSCRIPT,
  REQUEST__FETCH_EARNINGS_TRANSCRIPT_HISTORY
} from 'src/redux/secFilings/secFilingsActions';



const initialState = {
  filings: {
    sort: 'group',
    data: [],
    isFetching: true
  },
  earningsTranscripts: [],
  viewedFiling: null,
  viewedTranscript: {},
  isFetchingTranscript: false,
  isFetchingTranscriptHistory: true,
};


const accountReducer = (state = initialState, action) => {
  switch (action.type) {

    case FETCH_FILINGS_TICKER: {
      return produce(state, (draft) => {
        if (action.payload && !isObjEmpty(action.payload)) {
          draft.filings.data.push(...action.payload.data);
          draft.filings.sort = action.payload.sort;
          draft.filings.isFetching = false;
        } else {
          draft.filings = {
            data: [],
            sort: state.filings.sort,
            isFetching: false
          }
        }
      });
    }

    case SET_VIEWED_FILING: {
      return produce(state, (draft) => {
        draft.viewedFiling = action.payload;
      });
    }

    case FETCH_EARNINGS_TRANSCRIPT_HISTORY: {
      return produce(state, (draft) => {
        draft.isFetchingTranscriptHistory = false;
        draft.filings.data.push(...action.payload);
      })
    }

    case REQUEST__FETCH_FILINGS_TICKER: {
      return produce(state, (draft) => {
        draft.filings = {
          ...initialState.filings,
          sort: state.filings.sort
        }
      });
    }

    case REQUEST__FETCH_EARNINGS_TRANSCRIPT_HISTORY: {
      return produce(state, (draft) => { draft.isFetchingTranscriptHistory = true })
    }

    case REQUEST__FETCH_EARNINGS_TRANSCRIPT: {
      return produce(state, (draft) => { draft.isFetchingTranscript = true })
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
