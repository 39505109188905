import React from 'react';

function LogoE({ size = 30 }) {
  return (
    <img
      alt="LogoE"
      src="/static/EdgeToTradeWhiteText-e.png"
      style={{maxWidth:size, height:'auto', width: '100%'}}
    />
  );
}

export default LogoE;
