import { addBusinessDays, addDays, isAfter, isBefore, isSameDay, startOfDay, subBusinessDays, subDays } from 'date-fns';
import { getMostRecentTradingDay } from 'src/utils/datetime/date-fns.tz';


// https://github.com/hypeserver/react-date-range/blob/master/src/defaultRanges.js


export const makeStaticDaysTopList = ({
  minDate,
  maxDate,
  disableFuture,
  marketTime,
  disableHoliday,
  disabledDay,
}) => {
  let resolvedMaxDate = disableFuture ? new Date() : maxDate;
  resolvedMaxDate = resolvedMaxDate ? addDays(resolvedMaxDate, 1) : null;
  const resolvedMinDate = minDate ? subDays(minDate, 1) : null;

  return [
    {
      name: 'today',
      label: 'Today',
      get: (selectedDate) => {
        const mostRecentDay = startOfDay(getMostRecentTradingDay());
        return {
          date: mostRecentDay,
          selected: isSameDay(mostRecentDay, selectedDate)
        };
      }
    },
    {
      name: 'next',
      label: 'Next Day',
      get: (selectedDate) => {
        const nextDay = startOfDay(addBusinessDays(selectedDate, 1));
        return {
          date: nextDay,
          disabled: resolvedMaxDate && isSameDay(nextDay, resolvedMaxDate) || isAfter(nextDay, resolvedMaxDate)
        };
      }
    },
    {
      name: 'prev',
      label: 'Prev. Day',
      get: (selectedDate) => {
        const prevDay = startOfDay(subBusinessDays(selectedDate, 1));
        return {
          date: prevDay,
          disabled: minDate && isSameDay(prevDay, resolvedMinDate) || isBefore(prevDay, resolvedMinDate)
        }
      }
    }
  ];
};



