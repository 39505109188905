import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { SSRSIcon } from 'src/theme/EdgeIcons';
import {
  Typography,
  makeStyles
} from '@material-ui/core';



const useStyles = makeStyles((theme) => ({
  root: {
    fontSize: 17,
    [theme.breakpoints.down('md')]: {
      fontSize: 16
    }
  },
  comptitle: {
    fontWeight: 300,
    marginRight: 10
  },
  exchange: {
    fontSize: 11,
    paddingRight: 11
  },
  ssr: {
    color: theme.palette.background.ssr,
    display: 'inline-block',
  },
  group: {
    display: 'inline-block'
  }
}));



function TickerTitle({
  className,
  tickerId,
  statsData,
  isFetching,
  hideSymbol
}) {
  const classes = useStyles();

  return (
    <Typography variant="h3" className={clsx(classes.root, className)} color="textPrimary">
      {(!hideSymbol) && <strong>{tickerId || '\u00A0'}</strong>}
      {!isFetching &&
      <>
        <span className={classes.comptitle}>{statsData?.['name'] && `${hideSymbol ? '' : ' - '}${statsData['name']}`}</span>
        <span className={classes.group}>
          {statsData?.['exchange'] && <span className={classes.exchange}>{statsData['exchange']}</span>}
          {!!(statsData?.['ssr'] && statsData?.['ssr'] === 1) && <span className={classes.ssr}><SSRSIcon width={8}/></span>}
        </span>
      </>
      }
    </Typography>
  );
}



TickerTitle.propTypes = {
  className: PropTypes.string,
  tickerId: PropTypes.string,
  statsData: PropTypes.object,
  isFetching: PropTypes.bool,
  hideSymbol: PropTypes.bool
}


export default TickerTitle;



