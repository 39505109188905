import { isObject } from 'lodash';
import _cloneDeep from 'lodash/cloneDeep';
import { makeExpressionValueFormatter } from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import { EXPR_PREFIX } from 'src/redux/expressions/globalExpressionReducer';


export const rowClassRules = {
  'halted-row': (params) => !!(params?.data?.halt)
};


export const renderHighlightClass = ({ value }) => {
  if (value < 0) {
    return 'ett-cell-negative';
  } else if (value > 0) {
    return 'ett-cell-positive';
  }
};



/**
 * Splits the ALL_COLUMNS object into its specific parts, with nested props overriding the general ones.
 * @param {'grid'|'filter'|'column'} type
 * @param {Array.<ColumnDef>} defs
 * @returns {Object} - specific column defs
 */
export const getColumnDefsFor = (type, defs) => {
  const cols = _cloneDeep(defs).reduce((acc, curr) => {
    // remove column if specified
    if (!(type in curr) || curr[type] === false) {
      return acc;
    }

    /* eslint-disable-next-line no-unused-vars */
    const { grid, filter, column, ...rest } = curr;

    // set filter/column "label" to "formLabel"
    let extraProps = {};
    if ((type === 'filter' || type === 'column') && rest?.formLabel) {
      extraProps.alternateLabel = rest.label; // for search, so we don't lose the original name
      extraProps.label = rest.formLabel;
    }

    const newCol = {
      ...rest,
      ...curr[type],
      ...extraProps
    };

    return [
      ...acc,
      newCol,
    ];
  }, []);

  return cols;
};


/**
 * Mutable. Adds column.synonyms = [...] propery
 */
export const attachSynonymsToColumns = (columns, synonyms) => {
  if (!synonyms || !Object.keys(synonyms).length) return;

  Object.entries(synonyms).forEach(([pattern, syns]) => {
    const _pattern = pattern.toLowerCase();
    columns.forEach(column => {
      const label_parts = column.label.toLowerCase().split(' ');
      if (label_parts.includes(_pattern)) {
        if (!column.synonyms) {
          column.synonyms = [];
        }
        column.synonyms = [...column.synonyms, ...syns];
      }
    });
  });

  return columns;
};



const reduceColumnSchema = (activeColumns, schema) => {
  return activeColumns.reduce((acc, c) => {
    const col = schema.find(lc => lc.name === c);
    if (col) {
      acc.push({ ...col });
    }
    return acc;
  }, []);
};


/**
 * Filters GRID_COLUMN defs with currently selected columns, and exports for AG-Grid
 * @param {string[]|object[]} activeColumns - List of currently selected cols
 * @param {Object[]} allColumns - All available colDefs
 * @return {Object[]} - Formatted for AG-Grid
 */
export const buildGridColumns = (activeColumns, allColumns, expressions = [], expressionOverrides = {}) => {
  // TODO: COLUMN_KEY Now that columns are [{ column: key }] instead of [key], we need to map. We should generalize this logic.

  // const columns = activeColumns.
  const columns = activeColumns.map(c => c.column);


  return reduceColumnSchema(columns, [...expressions, ...allColumns]).map(col => {

    const guard = (formatter) => {
      return (args) => {
        if (args.value === null || args.value === undefined) {
          return col.nullFormat ? col.nullFormat : '';
        }
        if (args.value === 0 && col.zeroValueFormat) {
          return col.zeroValueFormat;
        }
        return formatter ? formatter(args) : args.value;
      };
    };

    if (col.name.startsWith(EXPR_PREFIX)) {
      return {
        field: col.name,
        headerName: col.label,
        resizable: true,
        sortable: true,
        suppressMovable: true,
        suppressMenu: true,
        width: 100,
        minWidth: 25,
        valueFormatter: guard(makeExpressionValueFormatter(col)),
        cellRenderer: 'compactNumberCellRenderer',
        headerClass: 'ett-header-cell',
        ...expressionOverrides
      }
    }

    return {
      type: col.type,
      field: col.name,
      pinned: col.pinned,
      headerName: col.label,
      sort: col.sort,
      hide: col?.hide,
      editable: false,
      sortable: col.sortable || false,
      resizable: col.resizable !== false,
      suppressMovable: true,
      suppressAutoSize: col.suppressAutoSize || false,
      suppressSizeToFit: col.suppressSizeToFit || false,
      suppressMenu: true,
      width: col.width || 100,
      minWidth: col.minWidth || 25,
      maxWidth: col.maxWidth,
      flex: col?.flex,
      wrapText: col?.wrapText,
      autoHeight: col?.autoHeight,
      valueGetter: col.valueGetter,
      valueFormatter: guard(col.valueFormatter),
      cellRenderer: col.cellRenderer,
      cellRendererParams: col?.cellRendererParams,
      enableCellChangeFlash: col?.enableCellChangeFlash,
      headerClass: 'ett-header-cell',
      cellClass: col.className || (col.numberHighlighting && renderHighlightClass),
    };
  });
};
