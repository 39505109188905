import {
  Box,
  Button,
  FormControl,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { Case, Switch } from 'src/app/components/utility/SwitchCase';
import { useFilterEntityContext } from 'src/app/slicedForm/FilterForm/context/FilterEntityContext';
import {
  BOOLEAN_OP_LABELS,
  BOOLEAN_OPS,
  getAdornmentChars,
  VALUE_TYPE_LABELS,
  VALUE_TYPES
} from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import { useColumnDef } from 'src/app/slicedForm/shared/context/ColumnDefsProvider';
import useComparableColDefs from 'src/app/slicedForm/shared/hooks/useComparableColDefs';
import { getFilterEntityDefaults } from 'src/app/slicedForm/shared/schema/schemaBuilder';
import useInputStyles, { ButtonStyleProps } from 'src/app/slicedForm/shared/styles/useInputStyles';
import ColumnIconSelector from 'src/app/slicedForm/shared/elements/ColumnIconSelector';
import { useEntity } from '../context/useFormEntity';
import ShorthandNumberInput from '../elements/ShorthandNumberInput';
import SimpleDropdown from '../elements/SimpleDropdown';
import WithColumnPickerForm from '../elements/WithColumnPickerForm';


const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    '& .MuiInputAdornment-root': {
      '& p': {
        fontSize: 14,
      }
    }
  },
  flexNegativeMargin: {
    marginTop: -10
  },
  flexMargin: {
    marginTop: 10
  }
}));


/**

Thoughts on validation


Strategies:
  1) All inside reducer. 
    + We have access to all values
    + Components remain simple
    + We need an onSubmit validator anyways, or something that communicates to turn off the submit button.
    - How do we define divergent behavior. This couples data to validation way too heavily.
    - How do we communicate errors? We'd need error state
    - Validation is a UI decision, not a data decision
    - We already use component-based for Date validations
  
  2) All inside components
    - Components have to hold temporary state, and dispatch onyl when valid
    - Combinatory validations are hard or impossible without raising state to a parent
    - Global validations are not possible. "Hide submit button if any errors"
      - Unless we have a simple "isAnyError" state in redux. That would work. Turning the flag off aint easy though.
    + Validation is a UI decision, can make different behaviors via new components

  3) Some kind of mix?
    - Maybe a ValidationReducer?
      + Does not care about filterForm state structure
        simply holds [key, value] of errors
        Components can register a 'validation_type' with the context,
        so components can control their own validation
      + Can be used in conjunction with other reducers
      - Combinatory validtions are still hard or impossible.
        How do we associate two fields together?
      - Reducer still needs to push error messages to component, which is slow and tightly-coupled

  ------ 

  4) react-hook-form style
    I can't figure out how to make this work with our setup. We need
      a) Direct ref() to the input element
      b) Capture all values as they update, unobtrusively 
    
    I don't want to pass these props down to individual compoennts. but I can't think of a way to "intercept"
    it. Maybe:
      - {ref} on containing element, stored in a flat map (no reducer). Stored with unique name "e_id.right.0.value"
      - interceptor on callbacks. Not on base element, but before dispatch
      - global hook to namespace the flat maps


-- 01/11/2024 --

I can't come up with a solution. Really, we'd need to totally rework the form so that each input value is a node.
Maybe tree points to entitity points to value. Man that would suck though.

For now, this:
1) If a column is missing, the backend will filter it out. No problem.
2) If a COMPARE is missing, we should wipe it just before save
  - Doesn't that mean parsing the tree and fixing GROUP relations? I don't know if I can deal with that. 
  - I mean think about Slices for example. What if the missing slice is the active one?


-- 01/12/2024 --

OKAY I think I have it. The secret is to flatten the paths like we though, but we should ONLY 
do so for entities. 

In that way, the conversion between Object entities and Path entities should be easy. Wherever we 
initialize an entity, just pathify it. Then convert back at the end.


Its a bit annoying that so many redux operations require Object.keys() to find the ID,
but lets try it truly flat first. If that doesn't work, we'll nest under valeus[id] = {..paths}
*/

const emptyObj = {}

function Compare({
  className,
  hideComparison,
  hideValueType
}) {
  const classes = useStyles();
  const inputClasses = useInputStyles();
  const { id, disabled } = useFilterEntityContext();

  const { useRegister, useWatch } = useEntity(id);

  const leftColumn = useWatch('left.column');
  const rightColumnFirst = useWatch('right[0].column');
  const rightColumnSecond = useWatch('right[1].column');

  const operatorInputProps = useRegister('operator', { refName: 'innerRef', valueName: 'selected' });
  const valueTypeInputProps = useRegister('right[0].type', { refName: 'innerRef' }); // both right0 and right1 will assume this 'type'

  const rightFirstValueProps = useRegister('right[0].value', { refName: 'innerRef', mode: 'onAccept' });
  const rightFirstColumnProps = useRegister('right[0].column');
  const rightSecondValueProps = useRegister('right[1].value', { refName: 'innerRef', mode: 'onAccept' });
  const rightSecondColumnProps = useRegister('right[1].column');

  // Only needed for start/end adornments. This is the actual filter column.
  const leftColDef = useColumnDef(leftColumn);
  const { startAdornment, endAdornment } = getAdornmentChars(leftColDef);

  // These might be null if we're comparing a value instead of a column. Thats okay.
  const rightColDefFirst = useColumnDef(rightColumnFirst);
  const rightColDefSecond = useColumnDef(rightColumnSecond);

  const comparableColDefs = useComparableColDefs(leftColDef);

  const canCompareColumns = comparableColDefs.length > 0;


  const handleSelectColumnAsValue = (inputProps, colDef, position = 0) => {
    // TODO: This is hard-coding. We probably want this dynamic. Maybe context?
    const defaults = getFilterEntityDefaults(colDef);
    if (!defaults || !colDef) {
      console.warn(`No defaults/colDef found for column ${colDef}}`);
      return;
    }

    inputProps.onChange(colDef?.name, { colDef });
  };



  const renderValueInput = (valueProps, columnProps, colDef, index) => {
    const placeholderText = index === 0 ? 'Smaller Metric' : 'Larger Metric';
    const shouldShowPlaceholderText = !colDef?.label && valueTypeInputProps.value === VALUE_TYPES.column && operatorInputProps.selected === BOOLEAN_OPS.BTW;

    return (
      <Switch expr={valueTypeInputProps.value}>
        <Case when={VALUE_TYPES.value}>
          <ShorthandNumberInput
            className={clsx(
              inputClasses.formControl,
              inputClasses.valueInputControl,
              classes.flexMargin
            )}
            startAdornmentString={startAdornment}
            endAdornmentString={endAdornment}
            disabled={disabled}
            {...valueProps}
          />
        </Case>
        <Case when={VALUE_TYPES.column}>
          <>
            <FormControl
              className={clsx(
                inputClasses.formControl,
                inputClasses.valueInputControl,
                classes.flexMargin,
                columnProps.error && inputClasses.formControlError,
                columnProps.error && 'sf-form-control-error-flash'
              )}
              error={Boolean(columnProps.error)}
            >
              <WithColumnPickerForm
                anchorPosition="right"
                columnDefs={comparableColDefs}
                onSelect={(newColDef) => handleSelectColumnAsValue(columnProps, newColDef, index)}
              >
                <Button
                  aria-label="Open column selection popup"
                  {...ButtonStyleProps}
                  disabled={disabled}
                  className={clsx(
                    inputClasses.button,
                    inputClasses.highlight,
                    inputClasses.buttonAsInput,
                    shouldShowPlaceholderText && inputClasses.buttonTextPlaceholder
                  )}
                  ref={columnProps.ref}
                >
                  <ColumnIconSelector name={colDef?.name} floating small />
                  {shouldShowPlaceholderText ? placeholderText : colDef?.label}
                </Button>
              </WithColumnPickerForm>
            </FormControl>
          </>
        </Case>
      </Switch>
    );
  };


  return (
    <Box className={clsx(className, classes.root)}>

      <Switch expr={hideComparison}>
        <Case when={true}>
          <FormControl className={clsx(
            inputClasses.formControl,
            inputClasses.comparisonSelectControl
          )} />
        </Case>
        <Case default>
          <SimpleDropdown
            className={clsx(
              inputClasses.formControl,
              inputClasses.comparisonSelectControl
            )}
            options={BOOLEAN_OP_LABELS}
            label="Select comparison operator"
            disabled={disabled}
            {...operatorInputProps}
          />
        </Case>
      </Switch>

      <Switch expr={hideValueType || !canCompareColumns}>
        <Case when={true}>
          <FormControl
            className={clsx(
              inputClasses.formControl,
              inputClasses.valueTypeSelectControl
            )}
          />
        </Case>
        <Case default>
          <SimpleDropdown
            className={clsx(
              inputClasses.formControl,
              inputClasses.valueTypeSelectControl
            )}
            options={VALUE_TYPE_LABELS}
            label="Select value type"
            disabled={disabled}
            selected={valueTypeInputProps.value}
            {...valueTypeInputProps}
          />
        </Case>
      </Switch>

      <Box
        display="flex"
        flexWrap="wrap"
        flex={1}
        className={classes.flexNegativeMargin}
      >
        {renderValueInput(rightFirstValueProps, rightFirstColumnProps, rightColDefFirst, 0)}

        {Boolean(operatorInputProps.selected === BOOLEAN_OPS.BTW) &&
          renderValueInput(rightSecondValueProps, rightSecondColumnProps, rightColDefSecond, 1)
        }
      </Box>
    </Box>
  );
}

export default Compare;
