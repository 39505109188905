/* eslint-disable no-async-promise-executor */
import authService from 'src/services/authService';
import AxiosAuthInstance from 'src/services/AxiosAuthService/AxiosAuthInstance';
import edgeUsersApi from 'src/apis/edgeUsersApi';
import edgeDataApi from 'src/apis/edgeDataApi';
import edgeProxyApi from 'src/apis/edgeProxyApi';

/*
 Import any created axios APIs and add them to the instances.
 This will automatically handle adding and refreshing authTokens for that api.
*/

class AxiosAuthService {

  instances = [
    new AxiosAuthInstance(edgeUsersApi, 'accessToken', 'edgeUsersApi'),
    new AxiosAuthInstance(edgeDataApi, 'idToken', 'edgeDataApi'),
    new AxiosAuthInstance(edgeProxyApi, 'idToken', 'edgeProxyApi')
  ]


  setInterceptors = () => {
    this.instances.forEach(instance => {
      instance.axios.interceptors.response.use(
        response => response,
        error => this.errorInterceptor(error, instance)
      );
    });
  };


  /*
  Will be called after any API error response
  For 401 erros, we should initiate a refreshToken call
    - While refreshing, add any other incoming 401 requests to a processing queue.
    - Store the new token in all instances once done, and set the axios defaults.
    - Retry the first request that kicked off the refresh
    - Process the queue with the new token
   */
  errorInterceptor = (error, instance) => {
    const originalRequest = error.config;

    if (!instance.shouldIntercept(error)) {
      return Promise.reject(error);
    }

    if (originalRequest._retry || originalRequest._queued) {
      return Promise.reject(error);
    }

    // refresh is already happening, so add to queue.
    if (this.isAnyAxiosInstanceRefreshing()) {
      return new Promise((resolve, reject) => {
        instance.failedRequestQueue.push({ resolve, reject });
      }).then(token => {
        originalRequest._queued = true;
        originalRequest.headers.Authorization = token;
        return instance.axios(originalRequest);
      }).catch(err => {
        // Return original failure, not refresh queue failure
        console.error('Failed to attach token', err);
        return Promise.reject(error);
      });
    }

    originalRequest._retry = true;
    instance.isRefreshingToken = true;

    // We haven't started to refresh, so we must start the token process.
    // eslint no-async-promise-executor: Not sure how to refactor this code to conform to this rule. Ignoring.
    return new Promise(async (resolve, reject) => {
      try {
        const { session, user } = await authService.getSession();

        if (originalRequest.headers.Authorization !== instance.getAuthorizationHeader()) {
          originalRequest.headers.Authorization = instance.setTokenData(session);
          resolve(instance.axios(originalRequest));
        } else {
          const refreshedSession = await authService.refreshToken({ session, user });
          originalRequest.headers.Authorization = instance.setTokenData(refreshedSession);
          this.processAllPendingRequests(null, refreshedSession);
          resolve(instance.axios(originalRequest));
        }
      } catch (err) {
        // Our token refresh logic broke somewhere
        this.processAllPendingRequests(err, null);
        reject(err);
      } finally {
        instance.isRefreshingToken = false;
      }
    });
  };


  processAllPendingRequests = (error, session) => {
    this.instances.forEach(instance => {
      instance.processPendingRequestQueue(error, session);
    });
  };


  setAuthorizationHeaders = (session) => {
    this.instances.forEach(instance => {
      instance.setAuthorizationHeader(session);
    })
  }


  isAnyAxiosInstanceRefreshing = () => {
    return this.instances.some((instance) => instance.isRefreshingToken);
  };


  setBadAuthorizationHeaderForTesting = () => {
    this.instances.forEach(instance => {
      instance.axios.defaults.headers.common = {
        Authorization: 'BAD FAKE TOKEN',
      };
    });
  };
}

const axiosAuthService = new AxiosAuthService();
export default axiosAuthService;
