import { createStaticRanges } from 'react-date-range';
import { getCurrentTradingDay } from 'src/utils/datetime/date-fns.tz';
import { endOfDay, startOfDay, sub, format } from 'date-fns';



export const TOP_LIST_ROLLING_DATES = [
  { key: 'rolling_7_days', label: '7 Days' },
  { key: 'rolling_30_days', label: '30 Days' },
  { key: 'rolling_60_days', label: '60 Day' },
  { key: 'rolling_90_days', label: '90 Day' },
  { key: 'rolling_1_years', label: '1 year' },
  { key: 'rolling_2_years', label: '2 year' },
  { key: 'rolling_3_years', label: '3 year' },
  { key: 'rolling_5_years', label: '5 year' },
  { key: 'rolling_14_years', label: '14 year' },
]


export const filterRollingDatesByMinDate = (rollingDates, minDate) => {
  return rollingDates;
}


export const isRollingKey = (key) => {
  return typeof key === 'string' && key.startsWith('rolling_');
}


export const isRangeKey = (key) => {
  return typeof key === 'string' && key.startsWith('range_') || isRollingKey(key);
}


export const getRangeDates = (key) => {
  if (!isRangeKey(key)) throw Error('Static range is invalid!');
  const parts = key.split('_');
  const num = parts[1];
  const resolution = parts[2];
  return getRollingDateObjects(num, resolution);
}


export const getRangeDateStrings = (key, fmt = 'yyyy-MM-dd') => {
  const { startDate, endDate } = getRangeDates(key);
  return {
    startDate: format(startDate, fmt),
    endDate: format(endDate, fmt)
  }
}

//

const getRollingDateObjects = (num, type = 'days') => {
  const today = getCurrentTradingDay();
  const past = sub(today, { [type]: num });
  return { startDate: startOfDay(past), endDate: endOfDay(today) };
}


export const getRollingDateFromKey = (key, now = getCurrentTradingDay()) => {
  if (!isRangeKey(key)) throw Error('Static range is invalid!');
  const parts = key.split('_');
  const num = parts[1];
  const resolution = parts[2];

  const past = sub(now, { [resolution]: num });
  startOfDay(past)
}


/**
 * Range buttons on the DateRangePicker component.
 *
 * Dates are static, calculated when makeHistoricalStaticRanges() is
 * called. If you want to be safe, re-calculate the dates at midnight.
 * Or, you could make sure the component mounts frequently and useMemo, like in a Modal.
 */
export const makeHistoricalStaticRanges = () => {
  const definitions = [
    { key: 'range_7_days', label: '7 Days', ...getRollingDateObjects(7) },
    { key: 'range_30_days', label: '30 Days', ...getRollingDateObjects(30) },
    { key: 'range_60_days', label: '60 Day', ...getRollingDateObjects(60) },
    { key: 'range_90_days', label: '90 Day', ...getRollingDateObjects(90) },
    { key: 'range_1_years', label: '1 year', ...getRollingDateObjects(1, 'years') },
    { key: 'range_2_years', label: '2 year', ...getRollingDateObjects(2, 'years') },
    { key: 'range_3_years', label: '3 year', ...getRollingDateObjects(3, 'years') },
    { key: 'range_5_years', label: '5 year', ...getRollingDateObjects(5, 'years') },
    { key: 'range_14_years', label: '14 year', ...getRollingDateObjects(14, 'years') },
  ]

  return createStaticRanges(definitions.map(({ label, ...range }) => ({
    label,
    range: () => range
  })));
}



export const makeTopListStaticRanges = (minDate) => {
  const definitions = [
    { key: 'rolling_7_days', label: '7 Days' },
    { key: 'rolling_30_days', label: '30 Days' },
    { key: 'rolling_60_days', label: '60 Day' },
    { key: 'rolling_90_days', label: '90 Day' },
    { key: 'rolling_1_years', label: '1 year' },
    { key: 'rolling_2_years', label: '2 year' },
    { key: 'rolling_3_years', label: '3 year' },
    { key: 'rolling_5_years', label: '5 year' },
    { key: 'rolling_14_years', label: '14 year' },
  ]

  const withRanges = definitions.map(range => {
    return {
      ...range,
      ...getRangeDates(range?.key)
    }
  });

  return createStaticRanges(withRanges.map(({ label, ...rest }) => ({
    label,
    range: () => rest
  })));
}