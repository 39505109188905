


export const UPDATE_EXPRESSION_DETAILS_LABEL = '@form-expression/UPDATE_EXPRESSION_LABEL';
export const UPDATE_EXPRESSION_DETAILS_FORMAT = '@form-expression/UPDATE_EXPRESSION_FORMAT';
export const UPDATE_EXPRESSION_DETAILS_SYMBOL = '@form-expression/UPDATE_EXPRESSION_SYMBOL';
export const UPDATE_EXPRESSION_DETAILS_EXPRESSION = '@form-expression/UPDATE_EXPRESSION_EXPRESSION';
export const SET_EXPRESSION_ERROR = '@form-expression/CLEAR_ERROR';



/**
 * Intermediate reducer for modifying a SINGLE expression.
 * NOT to be used within the root level SlicedForm Provider. It should live on its own
 *
 * When the inner form is saved, propagate these values to the SlicedForm provider.
 *
 * {
 *  currentExpression: { ... }
 *  [expressions]: [] // Just for listing, not intended to be modified. 
 * }
 * **/
export default function expressionDetailsReducer(draft, action) {

  switch (action.type) {
    case UPDATE_EXPRESSION_DETAILS_LABEL: {
      const { label, error } = action.payload;

      draft.currentExpression.label = label;
      draft.errors.label = error;
      break;
    }

    case UPDATE_EXPRESSION_DETAILS_FORMAT: {
      const { format } = action.payload;

      draft.currentExpression.format = format;
      break;
    }

    case UPDATE_EXPRESSION_DETAILS_SYMBOL: {
      const { symbol } = action.payload;

      draft.currentExpression.symbol = symbol;
      break;
    }

    case UPDATE_EXPRESSION_DETAILS_EXPRESSION: {
      const { expression, args } = action.payload;

      draft.currentExpression.expression = expression;
      draft.currentExpression.args = args;
      break;
    }

    case SET_EXPRESSION_ERROR: {
      const errors = action.payload;

      if (errors?.currentExpression) {
        // TODO: BAD FIX. We need 2 grammers to avoid NULL.
        errors.currentExpression = errors.currentExpression.replace('"NULL",', "")
      }
      draft.errors = {
        ...draft.errors,
        ...errors
      }
      break;
    }

    default: {
      break;
    }
  }
}
