import { formatLargeInteger, formatPrice, fourDecimal, compactInteger } from "src/utils/formatters"

export const DATA_TYPES = {
  STRING: 'STRING',
  INT: 'INT',
  FLOAT: 'FLOAT',
  PERCENTAGE: 'PERCENTAGE',
  PRICE: 'PRICE',
  LARGE_PRICE: 'LARGE_PRICE',
  VOLUME: 'VOLUME',
  DATE: 'DATE',
  TIME: 'TIME',
  DATETIME: 'DATETIME',
  BOOL: 'BOOL',
}


export const EXPRESSION_SYMBOLS = {
  NONE: 'NONE',
  CURRENCY: 'CURRENCY',
  PERCENTAGE: 'PERCENTAGE',
  MULTIPLE: 'MULTIPLE',
}


export const EXPRESSION_SYMBOL_LABELS = {
  NONE: {
    label: 'None',
    example: null
  },
  CURRENCY: {
    label: 'Currency ($)',
    example: '$100'
  },
  PERCENTAGE: {
    label: 'Percent (%)',
    example: '100%'
  },
  MULTIPLE: {
    label: 'Multiple (x)',
    example: '100x'
  },
}


export const EXPRESSION_FORMATS = {
  NONE: 'NONE',
  COMPACT: 'COMPACT',
  FIXED: 'FIXED',
}


export const EXPRESSION_FORMAT_LABELS = {
  NONE: {
    label: 'None',
    example: null
  },
  COMPACT: {
    label: 'Compact',
    example: '1.23M, 3.00K'
  },
  FIXED: {
    label: 'Fixed',
    example: '1.10, 4000.01'
  }
}


export const makeExpressionValueFormatter = ({ format, symbol }) => {
  let formatter;
  if (!format || format === EXPRESSION_FORMATS.NONE) formatter = fourDecimal;
  else if (format === EXPRESSION_FORMATS.COMPACT) {
    if ([EXPRESSION_SYMBOLS.PERCENTAGE, EXPRESSION_SYMBOLS.MULTIPLE].includes(symbol)) {
      // Don't add extra blank space if another symbol exists after
      formatter = compactInteger;
    } else {
      formatter = formatLargeInteger;
    }
  }
  else if (format === EXPRESSION_FORMATS.FIXED) {
    formatter = (value) => formatPrice(value).replace(/\$/g, '');
  }

  let symboler;
  if (!symbol || symbol === EXPRESSION_SYMBOLS.NONE) symboler = (value) => value;
  else if (symbol === EXPRESSION_SYMBOLS.CURRENCY) symboler = (value) => `$${value}`;
  else if (symbol === EXPRESSION_SYMBOLS.PERCENTAGE) symboler = (value) => `${value}%`;
  else if (symbol === EXPRESSION_SYMBOLS.MULTIPLE) symboler = (value) => `${value}x`;

  return (args) => symboler(formatter(args.value));
}


/**
 * @param {keyof DATA_TYPES} dtype
 * @returns {{ startAdornment: string, endAdornment: string }}
 */
export const getAdornmentChars = (colDef = {}) => {
  switch (colDef.dtype) {
    case DATA_TYPES.PRICE:
    case DATA_TYPES.LARGE_PRICE:
      return { startAdornment: '$', endAdornment: null }
    case DATA_TYPES.PERCENTAGE:
      return { startAdornment: null, endAdornment: '%' }
    default:
      return { startAdornment: null, endAdornment: null }
  }
}


export const VALUE_TYPES = {
  value: 'value',
  column: 'column',
  // expression: 'expression' NOT A UNIQUE VALUE TYPE. Instead: { column: 'expr_id', expression: true };
}


export const VALUE_TYPE_LABELS = {
  value: 'Value',
  column: 'Metric',
  // expression: 'Expr.'
}


export const BOOLEAN_OPS = {
  GE: 'GE',
  LE: 'LE',
  GT: 'GT',
  LT: 'LT',
  EQ: 'EQ',
  BTW: 'BT'
}


export const IDENTITY_OPS = {
  IS: 'IS',
  IS_NOT: 'IS_NOT'
}

export const BOOLEAN_OP_LABELS = {
  GE: '>=',
  LE: '<=',
  GT: '>',
  LT: '<',
  EQ: '=',
  BT: 'BTW'
}


export const ROLLING_DATE_OPS = {
  GE: 'GE',
  LT: 'LT'
}


export const ROLLING_DATE_OP_LABELS = {
  GE: 'IN',
  LT: 'NOT IN'
}


export const ARRAY_OPS = {
  IN: 'IN',
  NIN: 'NIN'
}


export const ARRAY_OP_LABELS = {
  IN: 'IN',
  NIN: 'NOT IN'
}


export const DATE_TYPES = {
  DATE: 'DATE',
  ROLLING: 'ROLLING',
}


export const DATE_TYPE_LABELS = {
  DATE: 'Date',
  ROLLING: 'Rolling'
}


export const AGGREGATES = {
  AVG: 'AVG',
  CNT: 'CNT',
  MAX: 'MAX',
  MIN: 'MIN',
  SUM: 'SUM',
  MED: 'MED',
  SD_POP: 'SD_POP'
}

export const AGGREGATE_SHORT_LABELS = {
  ...AGGREGATES,
  SD_POP: 'SD'
}

export const AGGREGATE_LONG_LABELS = {
  ...AGGREGATES,
  CNT: 'COUNT',
  SD_POP: 'STDDEV',
  MED: 'MEDIAN'
}

const fullAggSet = Object.values(AGGREGATES);

const timeAggregateSet = [
  AGGREGATES.AVG,
  AGGREGATES.CNT,
  AGGREGATES.MAX,
  AGGREGATES.MIN,
  AGGREGATES.MED,
]

const stringAggregateSet = [
  AGGREGATES.CNT,
]


/**
 * @param {keyof INPUTS} inputType
 * @returns {string[]}
 **/
export const allowedAggregatesForInputType = (inputType) => {
  if (inputType === INPUTS.COMPARE) return fullAggSet;
  if ([INPUTS.DATE, INPUTS.TIME, INPUTS.DATETIME].includes(inputType)) return timeAggregateSet;
  return stringAggregateSet;
}



/** Will the column query as (COL gt VALUE or NULL) ? */
export const ALLOW_NULL = {
  USER_CONTROLLED: 'USER_CONTROLLED',
  TRUE: 'TRUE',
  FALSE: 'FALSE'
}


export const INPUTS = {
  COMPARE: 'COMPARE',
  TICKER: 'TICKER',
  DATE: 'DATE',
  TIME: 'TIME',
  DATETIME: 'DATETIME',
  SELECT: 'SELECT',
  MULTI_SELECT: 'MULTI_SELECT',
}


const defaultRight = { value: null, column: null, type: 'value' }


/** Use this if we have no left column to create a new entity */
export const defaultFilterEntity = {
  left: { column: null, type: 'column' },
  operator: BOOLEAN_OPS.GE,
  right: [{ ...defaultRight }]
}


export const defaultAggregateEntity = {
  conditional_agg: AGGREGATES.AVG,
  label: `${AGGREGATE_SHORT_LABELS.AVG}:`,
  target: { column: null, type: 'column' },
  tree: []
}


/** Use this if we know the left column at the time of creation */
export const ENTITY_RIGHT_DEFAULTS = {
  [INPUTS.COMPARE]: {
    operator: BOOLEAN_OPS.GE,
    right: [{ ...defaultRight }]
  },
  [INPUTS.TICKER]: {
    operator: ARRAY_OPS.IN,
    right: [{
      ...defaultRight,
      value: []
    }]
  },
  [INPUTS.DATE]: {
    operator: BOOLEAN_OPS.EQ,
    dateType: DATE_TYPES.DATE,
    right: [{
      value: null,
      column: null,
      type: 'value'
    }]
  },
  [INPUTS.TIME]: {
    operator: BOOLEAN_OPS.GE,
    right: [{ ...defaultRight }]
  },
  [INPUTS.DATE_RANGE]: {
    operator: BOOLEAN_OPS.BTW,
    right: [{ ...defaultRight }, { ...defaultRight }]
  },
  [INPUTS.SELECT]: {
    operator: BOOLEAN_OPS.EQ,
    right: [{ ...defaultRight }]
  },
  [INPUTS.MULTI_SELECT]: {
    operator: ARRAY_OPS.IN,
    right: [{
      ...defaultRight,
      value: []
    }]
  }
}


/**
 * Pin the filter to the top of the form. Optionally, make it toggleable.
 * @type {{TOGGLEABLE: string, STICKY: string}}
 */
export const STICKY_OPTIONS = {
  /** 
   * The metric is pinned to the top of the form (columns right now). Can be removed.
   * The metric exists on AllColumns, and can be selected
   */
  TOGGLEABLE: 'TOGGLEABLE',
  /**
   * The metric is pinned to the top of SelectedColumns. Cannot be modified.
   * Does not exist in AllColumns.
   * 
   */
  STICKY: 'SITCKY',
}
