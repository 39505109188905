import produce from 'immer';
import { TOPICS as TOPLIST_NEWS_TOPICS } from 'src/app/TopListsMosaic/TopListNews/categories';
import { SOURCES as TOPLIST_NEWS_SOURCES } from 'src/app/TopListsMosaic/TopListNews/sources';
import { NEWS_SEC_FORM_TYPES } from 'src/redux/secFilings/secTopics';
import {
  ADD_TOPIC_TOPLIST_NEWS,
  REMOVE_TOPIC_TOPLIST_NEWS,
  ADD_METRIC_GLOBAL_TITLE_BAR,
  REMOVE_METRIC_GLOBAL_TITLE_BAR,
  REORDER_METRIC_GLOBAL_TITLE_BAR,
  UPDATE_FEED_TOPLIST_NEWS,
  UPDATE_FILTER_TOPLIST_NEWS,
  UPDATE_FULL_PROFILE,
  UPDATE_SINGLE_PROFILE,
  SET_AUDIO_ENABLED_TOPLIST_NEWS,
  ADD_NEWS_PAGE_TOPIC_FILTERS,
  REMOVE_NEWS_PAGE_TOPIC_FILTERS,
  ADD_NEWS_PAGE_SEC_TOPICS,
  REMOVE_NEWS_PAGE_SEC_TOPICS,
  UPDATE_FEED_NEWS_PAGE,
  UPDATE_FILTER_NEWS_PAGE,
} from 'src/redux/profileSettings/profileSettingsActions';
import { defaultHistoryAllMetricsProfile, defaultHistoryChartProfile, defaultHistoryRecordsProfile, defaultHistoryTopBarProfile } from './historyProfileSettingsConfig';
import { defaultFinancialsProfile, defaultFullKeyStatsProfile, defaultGapStatsProfile, defaultOverviewHistoryProfile } from './overviewProfileSettingsConfig';

const TOPLIST_NEWS_TOPIC_KEYS = Object.keys(TOPLIST_NEWS_TOPICS);
const TOPLIST_NEWS_SOURCES_KEYS = Object.keys(TOPLIST_NEWS_SOURCES);
const NEWS_SEC_TOPICS_KEYS = Object.keys(NEWS_SEC_FORM_TYPES).filter(s => s !== 'transcripts');

const initialState = {
  overview: {
    keyStats: defaultFullKeyStatsProfile,
    financials: defaultFinancialsProfile,
    history: defaultOverviewHistoryProfile,
    gapStats: defaultGapStatsProfile,
  },
  toplist: {
    news: {
      feed: 'all',
      topics: TOPLIST_NEWS_TOPIC_KEYS,
      sources: TOPLIST_NEWS_SOURCES_KEYS,
      filter: 'all',
      metricFilters: {},
      exchanges: ['major'],
      audioEnabled: false
    },
  },
  news: {
    news: {
      feed: 'all',
      topics: TOPLIST_NEWS_TOPIC_KEYS,
      sources: TOPLIST_NEWS_SOURCES_KEYS,
      filters: {}
    },
    sec: {
      topics: NEWS_SEC_TOPICS_KEYS,
      filters: {}
    }
  },
  history: {
    topBar: defaultHistoryTopBarProfile,
    records: defaultHistoryRecordsProfile,
    allMetrics: defaultHistoryAllMetricsProfile,
    chart: defaultHistoryChartProfile
  },
  global: {
    titleBar: {
      metrics: ['volume', 'industry', 'sector', 'home_country', 'rvolCalculated', 'changeYTDCombined', 'marketCap', 'sharesoutstanding']
    }
  },
  isFetching: {
    overview: true,
    toplist: true,
    scanner: true,
    global: true,
    news: true,
    history: true,
  }
};


const profileSettingsReducer = (state = initialState, action) => {
  switch (action.type) {

    case UPDATE_FULL_PROFILE: {
      return produce(state, (draft) => {
        const { data, page } = action.payload;
        Object.keys(data).map(pageKey => {
          Object.keys(data[pageKey]).map(typeKey => {
            if (draft[pageKey] && draft[pageKey][typeKey]) {
              draft[pageKey][typeKey] = {
                ...draft[pageKey][typeKey],
                ...data[pageKey][typeKey]
              };
            }
          });
        });
        draft.isFetching[page] = false;
      });
    }

    case UPDATE_SINGLE_PROFILE: {
      const { profile, page, type } = action.payload;
      if (profile && page && type) {
        return produce(state, (draft) => {
          draft[page][type] = {
            ...draft[page][type],
            ...profile
          };
        });
      }
      return state;
    }

    case ADD_TOPIC_TOPLIST_NEWS: {
      const { data, key } = action.payload;
      const KEYS = key === 'topics' ? TOPLIST_NEWS_TOPIC_KEYS : TOPLIST_NEWS_SOURCES_KEYS;
      if (data && data.length) {
        return produce(state, ({ toplist }) => {
          if (data.length === KEYS.length) {
            toplist.news[key] = KEYS;
          } else {
            data.forEach(topic => {
              if (!toplist.news[key].includes(topic)) {
                toplist.news[key].push(topic);
              }
            });
          }
        });
      }
      return state;
    }

    case REMOVE_TOPIC_TOPLIST_NEWS: {
      const { data, key } = action.payload;
      const KEYS = key === 'topics' ? TOPLIST_NEWS_TOPIC_KEYS : TOPLIST_NEWS_SOURCES_KEYS;
      if (data && data.length) {
        return produce(state, ({ toplist }) => {
          if (data.length === KEYS.length) {
            toplist.news[key] = [];
          } else {
            toplist.news[key] = toplist.news[key].filter(topic => {
              return !data.includes(topic);
            });
          }
        });
      }
      return state;
    }

    case ADD_NEWS_PAGE_TOPIC_FILTERS: {
      const { data, key } = action.payload;
      const KEYS = key === 'topics' ? TOPLIST_NEWS_TOPIC_KEYS : TOPLIST_NEWS_SOURCES_KEYS;
      if (data && data.length) {
        return produce(state, ({ news }) => {
          if (data.length === KEYS.length) {
            news.news[key] = KEYS;
          } else {
            data.forEach(topic => {
              if (!news.news[key].includes(topic)) {
                news.news[key].push(topic);
              }
            });
          }
        });
      }
      return state;
    }

    case REMOVE_NEWS_PAGE_TOPIC_FILTERS: {
      const { data, key } = action.payload;
      const KEYS = key === 'topics' ? TOPLIST_NEWS_TOPIC_KEYS : TOPLIST_NEWS_SOURCES_KEYS;
      if (data && data.length) {
        return produce(state, ({ news }) => {
          if (data.length === KEYS.length) {
            news.news[key] = [];
          } else {
            news.news[key] = news.news[key].filter(topic => {
              return !data.includes(topic);
            });
          }
        });
      }
      return state;
    }


    case ADD_NEWS_PAGE_SEC_TOPICS: {
      const data = action.payload;
      if (data && data.length) {
        return produce(state, ({ news }) => {
          data.forEach(topic => {
            if (!news.sec.topics.includes(topic)) {
              news.sec.topics.push(topic);
            }
          });
        });
      }
      return state;
    }


    case REMOVE_NEWS_PAGE_SEC_TOPICS: {
      const data = action.payload;
      if (data && data.length) {
        return produce(state, ({ news }) => {
          news.sec.topics = news.sec.topics.filter(topic => {
            return !data.includes(topic);
          });
        });
      }
      return state;
    }

    case ADD_METRIC_GLOBAL_TITLE_BAR: {
      if (action.payload && action.payload.length) {
        return produce(state, ({ global }) => {
          action.payload.forEach(topic => {
            if (!global.titleBar.metrics.includes(topic)) {
              global.titleBar.metrics.push(topic);
            }
          });
        });
      }
      return state;
    }

    case REMOVE_METRIC_GLOBAL_TITLE_BAR: {
      if (action.payload && action.payload.length) {
        return produce(state, ({ global }) => {
          global.titleBar.metrics = global.titleBar.metrics.filter(topic => {
            return !action.payload.includes(topic);
          });
        });
      }
      return state;
    }

    case REORDER_METRIC_GLOBAL_TITLE_BAR: {
      if (action.payload && action.payload.length) {
        return produce(state, ({ global }) => {
          global.titleBar.metrics = action.payload;
        });
      }
      return state;
    }

    case UPDATE_FEED_TOPLIST_NEWS: {
      if (action.payload && typeof action.payload === 'string' || action.payload instanceof String) {
        return produce(state, ({ toplist }) => {
          toplist.news.feed = action.payload;
        });
      }
      return state;
    }

    case UPDATE_FILTER_TOPLIST_NEWS: {
      if (action.payload && typeof action.payload === 'string' || action.payload instanceof String) {
        return produce(state, ({ toplist }) => {
          toplist.news.filter = action.payload;
        });
      }
      return state;
    }

    case UPDATE_FEED_NEWS_PAGE: {
      if (action.payload && typeof action.payload === 'string' || action.payload instanceof String) {
        return produce(state, ({ news }) => {
          news.news.feed = action.payload;
        });
      }
      return state;
    }

    case UPDATE_FILTER_NEWS_PAGE: {
      if (action.payload && typeof action.payload === 'string' || action.payload instanceof String) {
        return produce(state, ({ news }) => {
          news.news.filter = action.payload;
        });
      }
      return state;
    }

    case SET_AUDIO_ENABLED_TOPLIST_NEWS: {
      return produce(state, ({ toplist }) => {
        toplist.news.audioEnabled = action.payload;
      });
    }


    // case START_IS_FETCHING_PAGE: {
    //   return produce(state, ({ isFetching }) => {
    //     isFetching[action.payload] = true;
    //   });
    // }


    default: {
      return state;
    }
  }
};

export default profileSettingsReducer;
