import React, { useLayoutEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import clsx from 'clsx';
import { waitElementReady, scrollTo, isElementVisible } from 'src/utils/scrollDom';
import { Typography } from '@material-ui/core';


/**
 * Individual picker column. Seperated to make mounting/click scrollTo animations easier.
 * @component
 */
function TimePickerColumn({
  className,
  name,
  value,
  options,
  checkDisabled,
  onClick,
}) {
  const scrollContRef = useRef(null);
  const optionRefs = useRef(new Map());
  /** holds cancelAnimationFrame for cleanup */
  const cancelScrollAnimationRef = useRef(null);


  // On-Click scroller
  useLayoutEffect(() => {
    const optionEl = optionRefs.current.get(value);
    if (!optionEl) return;

    scrollTo(scrollContRef.current, optionEl.offsetTop, 150);
  }, [value])


  // On-Mount scroller.
  useLayoutEffect(() => {
    const optionEl = optionRefs.current.get(value);
    if (!optionEl) return;

    cancelScrollAnimationRef.current = waitElementReady(optionEl, () => {
      scrollTo(scrollContRef.current, optionEl.offsetTop, 0);
    });

    return () => cancelScrollAnimationRef.current?.();
  }, []);



  return (
    <ul
      className={clsx(
        className,
        `column-${name}`
      )}
      key={name}
      ref={scrollContRef}
    >
      {options.map(opt => {
        const selected = value === opt;
        const disabled = checkDisabled(opt);

        return (
          <li
            className={clsx(
              'time-cell',
              { 'disabled': disabled, 'selected': selected }
            )}
            role="button"
            onClick={() => !disabled && onClick(name, opt)}
            ref={el => optionRefs.current.set(opt, el)}
            key={opt}
          >
            <Typography className='time-cell-inner'>
              {opt.toString().padStart(2, '0')}
            </Typography>
          </li>
        );
      })}
    </ul>
  )
}


TimePickerColumn.propTypes = {
  className: PropTypes.string,
  name: PropTypes.oneOf(['hours', 'minutes', 'seconds', 'ampm']),
  value: PropTypes.number,
  options: PropTypes.arrayOf(PropTypes.number),
  disabledOptions: PropTypes.arrayOf(PropTypes.number),
  checkDisabled: PropTypes.func,
  onClick: PropTypes.func
};


TimePickerColumn.defaultProps = {
  options: [],
  disabledOptions: [],
  checkDisabled: _noop
};

export default React.memo(TimePickerColumn);
