import React, { useEffect, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { useIntercom } from 'react-use-intercom';
import PanelIconButton from 'src/app/components/panels/PanelIconButton';
import {
  makeStyles,
  Button,
} from '@material-ui/core';
import { QuestionMark, HelpOutlineIcon } from 'src/theme/EdgeIcons';
import {
  HelpOutline as HelpOutlineIconMUI,
  Help as HelpIcon
} from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {}
}));


const INTERCOM_SLUGS_CONFIG = {
  prod: {
    toplist: {
      components: {
        scanner: '9734004-top-list-scanner-introduction',
        watchlist: '9726518-top-list-watchlist',
        news: '9726414-top-list-news',
        historical: '9731071-top-list-historical-stats',
        chart: '9728665-top-list-charts',
        keystats: '9726502-top-list-key-stats',
      }
    }
  },
  test: {
    toplist: {
      components: {
        scanner: '9731223-test-getting-started-top-lists',
        watchlist: '9757263-test-watchlist',
        news: '9757266-test-news',
        historical: '9757262-test-history',
        chart: '9757260-test-chart',
        keystats: '9757261-test-key-stats',
      }
    }
  },

}


const INTERCOM_ARTICLES_CONFIG = {
  prod: {
    toplist: {
      components: {
        scanner: 9734004,
        watchlist: 9726518,
        news: 9726414,
        historical: 9731071,
        chart: 9728665,
        keystats: 9726502,
      }
    }
  },
  test: {
    toplist: {
      components: {
        scanner: 9731176,
        watchlist: 9757263,
        news: 9757266,
        historical: 9757262,
        chart: 9757260,
        keystats: 9757261,
      }
    }
  },
};



const stage = process.env.REACT_APP_INTERCOM_WORKSPACE_ENV || 'prod';


export const INTERCOM_ARTICLES = INTERCOM_ARTICLES_CONFIG[stage];
export const INTERCOM_SLUGS = INTERCOM_SLUGS_CONFIG[stage];

const BASE_URL = stage === 'test'
  ? 'https://intercom.help/edge-to-trade-test'
  : 'https://intercom.help/edge-to-trade';


const makeLink = (articlePath) => `${BASE_URL}/en/articles/${articlePath}`;



function IntercomArticleButton({
  className,
  articleId,
  articleSlug,
  disabled,
  text
}) {
  const classes = useStyles();

  const {
    showArticle,
    showSpace,
  } = useIntercom();

  const handleOpenArticleWithinWidget = useCallback(() => {
    // Intercom is busted, and the widget breaks if this is used in conjunction
    // with show("home") later.
    showArticle(articleId);
  }, [showArticle, articleId]);


  if (!articleId && !articleSlug) return null;


  if (articleId) {
    return (
      <PanelIconButton
        className={clsx(classes.root, className)}
        Icon={HelpOutlineIcon}
        text={text}
        shouldHideIconText
        disabled={disabled}
        ariaLabel={text}
        onClick={handleOpenArticleWithinWidget}
      />
    );
  }

  return (
    <PanelIconButton
      className={clsx(classes.root, className)}
      Icon={HelpOutlineIcon}
      text={text}
      shouldHideIconText
      disabled={disabled}
      ariaLabel={text}
      url={makeLink(articleSlug)}
    />
  )
}


IntercomArticleButton.propTypes = {
  className: PropTypes.string,
  articleID: PropTypes.number,
  text: PropTypes.string,
  disabled: PropTypes.bool,
}


IntercomArticleButton.defaultProps = {
  disabled: false,
  text: 'help'
}


export default IntercomArticleButton;
