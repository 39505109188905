import React from 'react';
import PropTypes from 'prop-types'
import clsx from 'clsx';
import {
  alpha,
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    height: '100%',
    flexDirection: 'column',
    backgroundColor: theme.palette.background.paper,
    borderRadius: theme.grid.borderRadius,
    position: 'relative',
    border: `1px solid var(--mosaic-window-border-color)`,
    overflow: 'hidden'
  },
  yellow: {
    // border: '1px solid var(--mosaic-variant-yellow)'
    border: `1px solid ${alpha(theme.palette.historical.border, .9)}`
  },
}));

function MosaicPanel({
  className,
  variant,
  children
}) {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.root, variant && classes?.[variant])}>
      {children}
    </div>
  );
}

MosaicPanel.propTypes = {
  clasName: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.any
}


MosaicPanel.defaultProps = {
  loading: false
}


export default MosaicPanel;

