import React, { useCallback } from 'react';
import clsx from 'clsx';
import {
  useFormSelector,
  useFormDispatch,
} from 'src/app/slicedForm/shared/context/FormProvider';
import HoverInfoPopper from 'src/app/components/elements/HoverInfoPopper';
import ExpressionInput from './ExpressionInput';
import ExpressionLabelInput from './ExpressionLabelInput';
import ExpressionInstructions from './ExpressionInstructions';
import {
  UPDATE_EXPRESSION_DETAILS_FORMAT,
  UPDATE_EXPRESSION_DETAILS_LABEL,
  UPDATE_EXPRESSION_DETAILS_SYMBOL,
} from 'src/app/slicedForm/ExpressionForm/reducers/expressionDetailsReducer'
import {
  EXPRESSION_FORMATS,
  EXPRESSION_FORMAT_LABELS,
  EXPRESSION_SYMBOLS,
  EXPRESSION_SYMBOL_LABELS
} from '../../FilterForm/definitions/inputEnums';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import {
  Box,
  Typography,
  FormHelperText,
  makeStyles,
  alpha,
  Button,
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    padding: theme.spacing(1.5),
    '&  h5': {
      fontSize: 16
    }
  },
  profileNameField: {
    '& .MuiInputBase-root': {
      backgroundColor: theme.palette.background.dark,
    }
  },
  inputErrorPre: {
    fontFamily: 'monospace',
    fontSize: 13,
    fontWeight: 500,
    color: theme.palette.error.main,
    borderRadius: 4,
    backgroundColor: theme.palette.background.paperAlt,
    padding: 5,
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
  },
  formatButton: {
    '&:focus-visible': {
      ...theme.focus.outline
    },
    margin: '0px 5px',
    padding: '5px 10px',
    fontSize: 14,
    flex: 1,
    border: `1px solid ${theme.palette.background.lightBorder}`,
    backgroundColor: theme.palette.background.paperAlt_darker1,
    borderRadius: 4,
    position: 'relative',
    textTransform: 'none'
  },
  formatButtonSelected: {
    borderColor: alpha(theme.palette.primary.main, .4),
    backgroundColor: alpha(theme.palette.primary.main, .1)
  },
  buttonExample: {
    fontSize: 12,
    opacity: .6,
  },
  errorText: {
    minHeight: 22,
    '& p': {
      fontSize: 13,
    }
  },
  popper: {
    backgroundColor: theme.palette.background.panelHeader,
    // border: `1px solid ${theme.palette.border}`,
    border: `1px solid black`,
  },
  popperContainer: {
    display: 'inline'
  },
  popperTrigger: {
    cursor: 'pointer',
    color: theme.palette.warning.main,
    fontSize: 18,
    verticalAlign: 'text-top',
    marginLeft: 4
  }
}));


function FormatButton({
  label,
  example,
  onClick,
  selected,
}) {
  const classes = useStyles();

  return (
    <Button
      onClick={onClick}
      disableRipple
      className={clsx(classes.formatButton, selected && classes.formatButtonSelected)}
    >
      <div>
        {label}
        {example && <div className={classes.buttonExample}>{example}</div>}
      </div>
    </Button>
  )
}

const emptyObj = {};

function ExpressionPanel({ className }) {
  const classes = useStyles();
  const dispatch = useFormDispatch();
  const expression = useFormSelector(state => state.currentExpression);
  const errors = useFormSelector(state => state?.errors || emptyObj);

  const { symbol, format } = expression;

  const handleLabelBlur = useCallback((label) => {
    dispatch({
      type: UPDATE_EXPRESSION_DETAILS_LABEL,
      payload: { label }
    });
  }, [])

  const handleFormatClick = useCallback((format) => {
    dispatch({
      type: UPDATE_EXPRESSION_DETAILS_FORMAT,
      payload: { format }
    })
  }, [])

  const handleSymbolClick = useCallback((symbol) => {
    dispatch({
      type: UPDATE_EXPRESSION_DETAILS_SYMBOL,
      payload: { symbol }
    })
  }, []);


  return (
    <div className={clsx(classes.root, className)}>
      <Box pb={3}>
        <Typography variant="h5">Expression Name:</Typography>
        <Box pt={1}>
          <ExpressionLabelInput
            className={classes.profileNameField}
            label={expression?.label || ''}
            onBlur={handleLabelBlur}
          />
        </Box>
      </Box>
      <Box pb={1}>
        <Typography variant="h5">
          Expression:
          <HoverInfoPopper
            containerClassName={classes.popperContainer}
            className={classes.popper}
            arrow={false}
            content={<ExpressionInstructions />}
          >
            <HelpOutlineIcon className={classes.popperTrigger} />
          </HoverInfoPopper>
        </Typography>
        <Box pt={1}>
          <ExpressionInput />
          <Box className={classes.errorText}>
            <FormHelperText error>
              {errors.currentExpression || ''}
            </FormHelperText>
          </Box>
        </Box>
      </Box>
      <Box pb={3}>
        <Typography variant="h5">Display Format:</Typography>
        <Box display="flex" pt={1}>
          {Object.keys(EXPRESSION_FORMATS).map(key => {
            return (
              <FormatButton
                key={key}
                {...EXPRESSION_FORMAT_LABELS[key]}
                onClick={() => handleFormatClick(key)}
                selected={format === key}
              />
            )
          })}
        </Box>
      </Box>
      <Box>
        <Typography variant="h5">Display Symbol:</Typography>
        <Box display="flex" pt={1}>
          {Object.keys(EXPRESSION_SYMBOLS).map(key => {
            return (
              <FormatButton
                key={key}
                {...EXPRESSION_SYMBOL_LABELS[key]}
                onClick={() => handleSymbolClick(key)}
                selected={symbol === key}
              />
            )
          })}
        </Box>
      </Box>
    </div>
  )
}


export default ExpressionPanel;


