
import { countryShortCodes } from 'src/utils/countryRegionData';

export const countryIsoOptions = countryShortCodes.map(code => ({ name: code, label: code }));

export const integerToBooleanOptions = [
  { name: 1, label: 'Yes' },
  { name: 0, label: 'No' }
];

export const shareTypeOptions = [
  { name: 'CS', label: 'Common Stock' },
  { name: 'WT', label: 'Warrant' },
  { name: 'ETF', label: 'ETF' }
];

export const shareGroupOptions = [
  { name: 'CS', label: 'Common Stock' },
  { name: 'ET', label: 'Exchange Traded' },
  { name: 'PS', label: 'Preferred Stock' },
  { name: 'WT', label: 'Warrant' }
]

export const exchangeOptions = [
  { name: 'ARCA', label: 'ARCA' },
  { name: 'BATS', label: 'BATS' },
  { name: 'NASDAQ', label: 'NASDAQ' },
  { name: 'NYSE', label: 'NYSE' },
  { name: 'NYSEMKT', label: 'NYSEMKT' },
  { name: 'OTCBB', label: 'OTCBB', depricated: true } // Means users can't select this option, but it still exists
]

export const industryOptions = [
  { name: "Advertising Agencies", label: "Advertising Agencies" },
  { name: "Aerospace & Defense", label: "Aerospace & Defense" },
  { name: "Agricultural Inputs", label: "Agricultural Inputs" },
  { name: "Airlines", label: "Airlines" },
  { name: "Airports & Air Services", label: "Airports & Air Services" },
  { name: "Aluminum", label: "Aluminum" },
  { name: "Apparel Manufacturing", label: "Apparel Manufacturing" },
  { name: "Apparel Retail", label: "Apparel Retail" },
  { name: "Asset Management", label: "Asset Management" },
  { name: "Auto Manufacturers", label: "Auto Manufacturers" },
  { name: "Auto Parts", label: "Auto Parts" },
  { name: "Auto & Truck Dealerships", label: "Auto & Truck Dealerships" },
  { name: "Banks - Diversified", label: "Banks - Diversified" },
  { name: "Banks - Regional", label: "Banks - Regional" },
  { name: "Beverages - Brewers", label: "Beverages - Brewers" },
  { name: "Beverages - Non-Alcoholic", label: "Beverages - Non-Alcoholic" },
  { name: "Beverages - Wineries & Distilleries", label: "Beverages - Wineries & Distilleries" },
  { name: "Biotechnology", label: "Biotechnology" },
  { name: "Broadcasting", label: "Broadcasting" },
  { name: "Building Materials", label: "Building Materials" },
  { name: "Building Products & Equipment", label: "Building Products & Equipment" },
  { name: "Business Equipment & Supplies", label: "Business Equipment & Supplies" },
  { name: "Capital Markets", label: "Capital Markets" },
  { name: "Chemicals", label: "Chemicals" },
  { name: "Coking Coal", label: "Coking Coal" },
  { name: "Communication Equipment", label: "Communication Equipment" },
  { name: "Computer Hardware", label: "Computer Hardware" },
  { name: "Computer Systems", label: "Computer Systems" },
  { name: "Confectioners", label: "Confectioners" },
  { name: "Conglomerates", label: "Conglomerates" },
  { name: "Consulting Services", label: "Consulting Services" },
  { name: "Consumer Electronics", label: "Consumer Electronics" },
  { name: "Copper", label: "Copper" },
  { name: "Credit Services", label: "Credit Services" },
  { name: "Department Stores", label: "Department Stores" },
  { name: "Diagnostics & Research", label: "Diagnostics & Research" },
  { name: "Discount Stores", label: "Discount Stores" },
  { name: "Diversified Industrials", label: "Diversified Industrials" },
  { name: "Drug Manufacturers - General", label: "Drug Manufacturers - General" },
  { name: "Drug Manufacturers - Major", label: "Drug Manufacturers - Major" },
  { name: "Drug Manufacturers - Specialty & Generic", label: "Drug Manufacturers - Specialty & Generic" },
  { name: "Education & Training Services", label: "Education & Training Services" },
  { name: "Electrical Equipment & Parts", label: "Electrical Equipment & Parts" },
  { name: "Electronic Components", label: "Electronic Components" },
  { name: "Electronic Gaming & Multimedia", label: "Electronic Gaming & Multimedia" },
  { name: "Electronics & Computer Distribution", label: "Electronics & Computer Distribution" },
  { name: "Engineering & Construction", label: "Engineering & Construction" },
  { name: "Entertainment", label: "Entertainment" },
  { name: "Farm & Heavy Construction Machinery", label: "Farm & Heavy Construction Machinery" },
  { name: "Farm Products", label: "Farm Products" },
  { name: "Financial Conglomerates", label: "Financial Conglomerates" },
  { name: "Financial Data & Stock Exchanges", label: "Financial Data & Stock Exchanges" },
  { name: "Food Distribution", label: "Food Distribution" },
  { name: "Footwear & Accessories", label: "Footwear & Accessories" },
  { name: "Furnishings", label: "Furnishings" },
  { name: "Furnishings Fixtures & Appliances", label: "Furnishings Fixtures & Appliances" },
  { name: "Gambling", label: "Gambling" },
  { name: "Gold", label: "Gold" },
  { name: "Grocery Stores", label: "Grocery Stores" },
  { name: "Healthcare Plans", label: "Healthcare Plans" },
  { name: "Health Information Services", label: "Health Information Services" },
  { name: "Home Improvement Retail", label: "Home Improvement Retail" },
  { name: "Household & Personal Products", label: "Household & Personal Products" },
  { name: "Industrial Distribution", label: "Industrial Distribution" },
  { name: "Industrial Metals & Minerals", label: "Industrial Metals & Minerals" },
  { name: "Information Technology Services", label: "Information Technology Services" },
  { name: "Infrastructure Operations", label: "Infrastructure Operations" },
  { name: "Insurance Brokers", label: "Insurance Brokers" },
  { name: "Insurance - Diversified", label: "Insurance - Diversified" },
  { name: "Insurance - Life", label: "Insurance - Life" },
  { name: "Insurance - Property & Casualty", label: "Insurance - Property & Casualty" },
  { name: "Insurance - Reinsurance", label: "Insurance - Reinsurance" },
  { name: "Insurance - Specialty", label: "Insurance - Specialty" },
  { name: "Integrated Freight & Logistics", label: "Integrated Freight & Logistics" },
  { name: "Internet Content & Information", label: "Internet Content & Information" },
  { name: "Internet Retail", label: "Internet Retail" },
  { name: "Leisure", label: "Leisure" },
  { name: "Lodging", label: "Lodging" },
  { name: "Lumber & Wood Production", label: "Lumber & Wood Production" },
  { name: "Luxury Goods", label: "Luxury Goods" },
  { name: "Marine Shipping", label: "Marine Shipping" },
  { name: "Medical Care Facilities", label: "Medical Care Facilities" },
  { name: "Medical Devices", label: "Medical Devices" },
  { name: "Medical Distribution", label: "Medical Distribution" },
  { name: "Medical Instruments & Supplies", label: "Medical Instruments & Supplies" },
  { name: "Metal Fabrication", label: "Metal Fabrication" },
  { name: "Mortgage Finance", label: "Mortgage Finance" },
  { name: "Oil & Gas Drilling", label: "Oil & Gas Drilling" },
  { name: "Oil & Gas E&P", label: "Oil & Gas E&P" },
  { name: "Oil & Gas Equipment & Services", label: "Oil & Gas Equipment & Services" },
  { name: "Oil & Gas Integrated", label: "Oil & Gas Integrated" },
  { name: "Oil & Gas Midstream", label: "Oil & Gas Midstream" },
  { name: "Oil & Gas Refining & Marketing", label: "Oil & Gas Refining & Marketing" },
  { name: "Other Industrial Metals & Mining", label: "Other Industrial Metals & Mining" },
  { name: "Other Precious Metals & Mining", label: "Other Precious Metals & Mining" },
  { name: "Packaged Foods", label: "Packaged Foods" },
  { name: "Packaging & Containers", label: "Packaging & Containers" },
  { name: "Paper & Paper Products", label: "Paper & Paper Products" },
  { name: "Personal Services", label: "Personal Services" },
  { name: "Pharmaceutical Retailers", label: "Pharmaceutical Retailers" },
  { name: "Pollution & Treatment Controls", label: "Pollution & Treatment Controls" },
  { name: "Publishing", label: "Publishing" },
  { name: "Railroads", label: "Railroads" },
  { name: "Real Estate - Development", label: "Real Estate - Development" },
  { name: "Real Estate - Diversified", label: "Real Estate - Diversified" },
  { name: "Real Estate Services", label: "Real Estate Services" },
  { name: "Recreational Vehicles", label: "Recreational Vehicles" },
  { name: "REIT - Diversified", label: "REIT - Diversified" },
  { name: "REIT - Healthcare Facilities", label: "REIT - Healthcare Facilities" },
  { name: "REIT - Hotel & Motel", label: "REIT - Hotel & Motel" },
  { name: "REIT - Industrial", label: "REIT - Industrial" },
  { name: "REIT - Mortgage", label: "REIT - Mortgage" },
  { name: "REIT - Office", label: "REIT - Office" },
  { name: "REIT - Residential", label: "REIT - Residential" },
  { name: "REIT - Retail", label: "REIT - Retail" },
  { name: "REIT - Specialty", label: "REIT - Specialty" },
  { name: "Rental & Leasing Services", label: "Rental & Leasing Services" },
  { name: "Residential Construction", label: "Residential Construction" },
  { name: "Resorts & Casinos", label: "Resorts & Casinos" },
  { name: "Restaurants", label: "Restaurants" },
  { name: "Savings & Cooperative Banks", label: "Savings & Cooperative Banks" },
  { name: "Scientific & Technical Instruments", label: "Scientific & Technical Instruments" },
  { name: "Security & Protection Services", label: "Security & Protection Services" },
  { name: "Semiconductor Equipment & Materials", label: "Semiconductor Equipment & Materials" },
  { name: "Semiconductors", label: "Semiconductors" },
  { name: "Shell Companies", label: "Shell Companies" },
  { name: "Shipping & Ports", label: "Shipping & Ports" },
  { name: "Silver", label: "Silver" },
  { name: "Software - Application", label: "Software - Application" },
  { name: "Software - Infrastructure", label: "Software - Infrastructure" },
  { name: "Solar", label: "Solar" },
  { name: "Specialty Business Services", label: "Specialty Business Services" },
  { name: "Specialty Chemicals", label: "Specialty Chemicals" },
  { name: "Specialty Industrial Machinery", label: "Specialty Industrial Machinery" },
  { name: "Specialty Retail", label: "Specialty Retail" },
  { name: "Staffing & Employment Services", label: "Staffing & Employment Services" },
  { name: "Steel", label: "Steel" },
  { name: "Telecom Services", label: "Telecom Services" },
  { name: "Textile Manufacturing", label: "Textile Manufacturing" },
  { name: "Thermal Coal", label: "Thermal Coal" },
  { name: "Tobacco", label: "Tobacco" },
  { name: "Tools & Accessories", label: "Tools & Accessories" },
  { name: "Travel Services", label: "Travel Services" },
  { name: "Trucking", label: "Trucking" },
  { name: "Uranium", label: "Uranium" },
  { name: "Utilities - Diversified", label: "Utilities - Diversified" },
  { name: "Utilities - Independent Power Producers", label: "Utilities - Independent Power Producers" },
  { name: "Utilities - Regulated Electric", label: "Utilities - Regulated Electric" },
  { name: "Utilities - Regulated Gas", label: "Utilities - Regulated Gas" },
  { name: "Utilities - Regulated Water", label: "Utilities - Regulated Water" },
  { name: "Utilities - Renewable", label: "Utilities - Renewable" },
  { name: "Waste Management", label: "Waste Management" }
]


export const sectorOptions = [
  { name: "Basic Materials", label: "Basic Materials" },
  { name: "Communication Services", label: "Communication Services" },
  { name: "Consumer Cyclical", label: "Consumer Cyclical" },
  { name: "Consumer Defensive", label: "Consumer Defensive" },
  { name: "Energy", label: "Energy" },
  { name: "Financial Services", label: "Financial Services" },
  { name: "Healthcare", label: "Healthcare" },
  { name: "Industrials", label: "Industrials" },
  { name: "Real Estate", label: "Real Estate" },
  { name: "Technology", label: "Technology" },
  { name: "Utilities", label: "Utilities" }
]


