import React, { useState, useEffect, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import { searchTicker } from 'src/apis/edgeProxyApi';
import { useDispatch } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { useTickerIdParams } from 'src/hooks/useTickerIdParams';
import { setTicker } from 'src/redux/ticker/tickerActions';
import useIsMountedRef from 'src/hooks/useIsMountedRef';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { SearchIcon } from 'src/theme/EdgeIcons';
import {
  Box,
  TextField,
  Typography,
  InputAdornment,
  makeStyles
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  flexWrap: {
    display: 'flex',
    justifyContent: 'flex-start',
    overflow: 'hidden',
  },
  optionLabel1: {
    flex: '0 0 auto',
    boxSizing: 'border-box',
    width: 65,
    color: theme.palette.primary.main
  },
  optionLabel2: {
    flex: '1 1 0%',
    boxSizing: 'border-box',
    color: theme.palette.text.primary,
    whiteSpace: 'nowrap',
  },
  fieldMarginDense: {
    marginTop: 6,
    marginBottom: 2
  },
  inputElement: {
    '& .MuiInputAdornment-root': {
      height: 'unset',
      marginBottom: 2
    },
    backgroundColor: theme.palette.background.paper,
    '& .MuiInput-root': {
      paddingLeft: 10,
    },
    '& .MuiAutocomplete-inputRoot': {
      paddingRight: '10px !important'
    },
    '& .MuiInputBase-input': {
      textTransform: 'uppercase'
    }
  }
}));


function TickerSearch({
  className,
  updateTickerCallback,
  clearTickerCallback,
  currentValue,
  controlled,
  disabled,
  id
}) {
  const classes = useStyles();
  const match = useRouteMatch();
  const tickerId = useTickerIdParams();
  const [open, setOpen] = useState(false);
  const [options, setOptions] = useState([]);
  const [val, setVal] = useState({ symbol: currentValue, name: '' });
  const isMounted = useIsMountedRef();
  const lastInputTimestamp = useRef(0);
  const dispatch = useDispatch();


  useEffect(() => {
    if (controlled) {
      setVal({ symbol: currentValue, name: '' });
    }
  }, [currentValue]);


  const fetchFilteredTickers = useCallback(async (queryVal) => {
    const timestamp = + new Date();
    let list = [];
    lastInputTimestamp.current = timestamp;

    if (queryVal && queryVal.length > 0) {
      try {
        // No authentication...
        list = await searchTicker(queryVal);
      } catch (err) { console.log(err); }
    }
    if (timestamp === lastInputTimestamp.current && isMounted.current) {
      setOptions(list);
    }
  }, []);


  // handles the user selecting a ticker from the dropdown, or pressing enter.
  useEffect(() => {
    if (val && val.symbol) {
      if (updateTickerCallback) {
        updateTickerCallback(val, match.path);
      } else {
        dispatch(setTicker(val, match.path));
      }
    } else if (clearTickerCallback) {
      clearTickerCallback();
    }
  }, [val]);


  useEffect(() => {
    if (!open) {
      setOptions([]);
    }
  }, [open]);


  const setTickerOnEnter = (event) => {
    setOpen(false);
    if (event.target.value) {
      if (updateTickerCallback) {
        updateTickerCallback({ symbol: event.target.value.toUpperCase() }, match.path);
      } else {
        dispatch(setTicker({ symbol: event.target.value.toUpperCase() }, match.path));
      }
    } else if (clearTickerCallback) {
      clearTickerCallback();
    }
  };


  return (
    <Box className={className}>
      <Box>
        <Autocomplete
          id={id}
          className={classes.inputElement}
          open={open}
          onOpen={() => setOpen(true)}
          onClose={() => setOpen(false)}
          options={options}
          value={val}
          clearOnEscape
          clearOnBlur
          onChange={(event, val) => setVal(val)}
          noOptionsText={'ex: AAPL'}
          getOptionLabel={(option) => option.symbol}
          disabled={disabled}
          renderOption={(option) => {
            return (
              <Box className={classes.flexWrap}>
                <Typography variant="body1" className={classes.optionLabel1}>
                  {option.symbol}
                </Typography>
                <Typography variant="body1" className={classes.optionLabel2}>
                  {option.name}
                </Typography>
              </Box>
            );
          }}
          filterOptions={(options) => options}
          onInputChange={(event, val, reason) => {
            if (reason === 'input') {
              void fetchFilteredTickers(val.toUpperCase());
            }
          }}
          renderInput={(params) => (
            <TextField
              {...params}
              margin="dense"
              placeholder={tickerId}
              className={classes.fieldMarginDense}
              fullWidth
              onKeyPress={(event) => {
                if (event.key === 'Enter') setTickerOnEnter(event);
              }}
              InputLabelProps={{
                ...params.InputLabelProps,
                disableLabel: true
              }}
              InputProps={{
                ...params.InputProps,
                disableUnderline: true,
                fullWidth: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <SearchIcon/>
                  </InputAdornment>
                ),
              }}
            />
          )}
        />
      </Box>
    </Box>
  );
}

TickerSearch.propTypes = {
  className: PropTypes.string,
  updateTickerCallback: PropTypes.func,
  clearTickerCallback: PropTypes.func,
  currentValue: PropTypes.string,
  controlled: PropTypes.bool,
  disabled: PropTypes.bool,
  id: PropTypes.string
}


TickerSearch.defaultProps = {
  currentValue: '',
  id: 'ticker-search'
}

export default TickerSearch;
