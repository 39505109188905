import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { CheckIcon, DenyIcon2 } from 'src/theme/EdgeIcons';
import ConditionalTooltip from 'src/app/components/utility/ConditionalTooltip';
import {
  IconButton,
  ClickAwayListener,
  makeStyles,
  useTheme
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {},
  button: {
    '&.--confirming .MuiSvgIcon-root': {
      color: props => props.confirmColor,
    },
    '&.--base .MuiSvgIcon-root': {
      color: props => props.baseColor,
    },
  }
}));

// TODO:  This api sucks. We can make it much better with context, but the refactor
// is too big.


/**
 * Manages the DoubleConfirmationIconButton shared state.
 * Use in parent component.
 *
 * @returns {{
 *  confirming: (id: string) => boolean,
 *  isAnyConfirming: boolean,
 *  setConfirming: (id: string) => (nextState: boolean) => void
 *  resetAllConfirming: () => void
 * }}
 **/
export function useDoubleConfirmationState() {
  const [confirming, handleConfirming] = useState({});

  return useMemo(() => ({
    confirming: id => Boolean(confirming[id]),

    isAnyConfirming: Object.values(confirming).some(Boolean),

    setConfirming: id => nextState => handleConfirming({
      ...confirming,
      [id]: nextState
    }),

    resetAllConfirming: () => handleConfirming({})

  }), [confirming]);
}


/**
 * Requires the user to click a button twice before accepting.
 * The icon and label can change between clicks.
 * Clicking away resets the confirmation state.
 */
function DoubleConfirmationButton({
  className,
  buttonClassName,
  iconButtonProps,
  disabled,
  BaseIcon,
  baseLabel,
  baseColor,
  ConfirmIcon,
  confirmLabel,
  confirmColor,
  confirming,
  setConfirming,
  onAccept
}) {
  const theme = useTheme();
  if (!baseColor) {
    baseColor = theme.palette.text.primary;
  }
  if (!confirmColor) {
    confirmColor = theme.palette.text.positive;
  }
  const classes = useStyles({ baseColor, confirmColor });


  const renderBase = () => (
    <ConditionalTooltip label={baseLabel}>
      <span>
        <IconButton
          {...iconButtonProps}
          disableRipple
          tabIndex={disabled ? undefined : 0}
          disabled={disabled}
          aria-label={baseLabel}
          className={clsx(buttonClassName, classes.button, '--base')}
          onClick={(e) => {
            e.stopPropagation();
            setConfirming(true);
          }}
        >
          <BaseIcon />
        </IconButton>
      </span>
    </ConditionalTooltip>
  );


  const renderConfirming = () => (
    <ClickAwayListener onClickAway={() => setConfirming(false)}>
      <div>
        <ConditionalTooltip label={confirmLabel}>
          <span>
            <IconButton
              {...iconButtonProps}
              tabIndex={disabled ? undefined : 0}
              disableRipple
              aria-label={confirmLabel}
              onClick={(e) => {
                e.stopPropagation();
                setConfirming(false);
                onAccept();
              }}
              className={clsx(buttonClassName, classes.button, '--confirming')}
            >
              <ConfirmIcon />
            </IconButton>
          </span>
        </ConditionalTooltip>
      </div>
    </ClickAwayListener>
  );


  return (
    <div className={clsx(classes.root, className, confirming ? 'step-confirming' : 'step-base')}>
      {confirming ? renderConfirming() : renderBase()}
    </div>
  );
}

export const DoubleConfirmationButtonPropTypes = {
  className: PropTypes.string,
  buttonClassName: PropTypes.string,
  disabled: PropTypes.bool,
  confirming: PropTypes.bool,
  BaseIcon: PropTypes.any,
  baseLabel: PropTypes.string,
  baseColor: PropTypes.string,
  ConfirmIcon: PropTypes.any,
  confirmLabel: PropTypes.string,
  confirmColor: PropTypes.string,
  setConfirming: PropTypes.func.isRequired,
  onAccept: PropTypes.func.isRequired
};

DoubleConfirmationButton.propTypes = {
  ...DoubleConfirmationButtonPropTypes
};


export const DoubleConfirmationButtonDefaultProps = {
  disabled: false,
  confirming: false,
  baseLabel: 'Delete',
  confirmLabel: 'Confirm?',
  BaseIcon: DenyIcon2,
  ConfirmIcon: CheckIcon,
};


DoubleConfirmationButton.defaultProps = {
  ...DoubleConfirmationButtonDefaultProps
};


export default DoubleConfirmationButton;
