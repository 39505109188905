/* eslint-disable no-param-reassign */

import { SET_TICKER } from 'src/redux/ticker/tickerActions';


const tickerInitialState = {
  name: '',
  symbol: ''
};

const tickerReducer = (state = tickerInitialState, action) => {
  switch (action.type) {

    case SET_TICKER: {
      return action.payload;
    }

    default: {
      return state;
    }
  }
}

export default tickerReducer;
