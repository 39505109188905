import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _noop from 'lodash/noop';
import TimePicker from 'src/app/components/pickers/components/TimePicker';
import MaskedTimeInput, {
  MaskedTimeInputDefaultProps,
  MaskedTimeInputPropTypes
} from 'src/app/components/pickers/components/MaskedTimeInput';
// import DefinedStaticDays from 'src/app/components/pickers/components/DefinedStaticDays';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import _uniqueId from 'lodash/uniqueId';
import IconAdornment from 'src/app/components/pickers/components/IconAdornment';
import Popover from '@material-ui/core/Popover';
import { MarketOpenIcon } from 'src/theme/EdgeIcons';


const useStyles = makeStyles(() => ({
  keyboardInput: {},
  popover: {
    '& > div:first-of-type': {
      backgroundColor: ({ backgroundOpacity }) => backgroundOpacity ? `rgba(0, 0, 0, ${backgroundOpacity}) !important` : 'transparent'
    }
  }
}));


/**
 * Displays:
 *  - Keyboard input
 *  - Calendar icon, which opens:
 *    - Date picker calendar
 *    - (Optionally) Static day selectors
 *
 *  All work together in concert. Valid dates are sent to parent onChange.
 *
 * @component
 */
function PopoverKeyboardTimePicker({
  inputClassName,
  popoverClassName,
  date,
  onAccept,
  marketTime,
  placeholder,
  showErrorMessage,
  errorPositionAbsolute,
  showNowButton,
  disableUnderline,
  inputVariant,
  disabled,
  backgroundOpacity,
  onKeyUp,
  allowNull,
  ...sharedProps
}) {
  const classes = useStyles({ backgroundOpacity });
  const [id] = useState(() => _uniqueId('popover_'));
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const _id = open ? id : undefined;

  const handleOpen = useCallback(event => setAnchorEl(event.currentTarget), []);

  const handleClose = useCallback(() => setAnchorEl(null), []);


  return (
    <>
      <MaskedTimeInput
        allowNull={allowNull}
        className={clsx(inputClassName, classes.keyboardInput)}
        date={date}
        marketTime={marketTime}
        placeholder={placeholder}
        onAccept={onAccept}
        showErrorMessage={showErrorMessage}
        disableUnderline={disableUnderline}
        errorPositionAbsolute={errorPositionAbsolute}
        inputVariant={inputVariant}
        acceptStrategy="onBlur"
        endAdornment={<IconAdornment onClick={handleOpen} disabled={disabled} ><MarketOpenIcon /></IconAdornment>}
        disabled={disabled}
        onKeyUp={onKeyUp}
        {...sharedProps}
      />
      <Popover
        id={_id}
        open={open}
        anchorEl={anchorEl}
        className={classes.popover}
        disabled={disabled}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          className={clsx(popoverClassName)}
          display="inline-flex"
          flexWrap="nowrap"
        >
          <TimePicker
            date={date}
            marketTime
            onChange={onAccept}
            showNowButton={showNowButton}
            {...sharedProps}
          />
        </Box>
      </Popover>
    </>
  );
}


PopoverKeyboardTimePicker.propTypes = {
  inputClassName: PropTypes.string,
  popoverClassName: PropTypes.string,
  /** date-fns Unicode date formatting */
  format: PropTypes.string.isRequired,
  /** Shown when input is empty */
  placeholder: PropTypes.string.isRequired,
  /** @see MaskedDateInput */
  inputVariant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  /** Show a shadow behind popover */
  backgroundOpacity: PropTypes.number,
  /** Partial control */
  date: PropTypes.instanceOf(Date),
  marketTime: PropTypes.bool.isRequired,
  disabledTime: PropTypes.shape({
    hours: PropTypes.func,   // () => []
    minutes: PropTypes.func, // (selectedHour) => []
    seconds: PropTypes.func  // (selectedHour, selectedMinute) => []
  }),
  /** How many numbers to jump by */
  steps: PropTypes.shape({
    hours: PropTypes.number,
    minutes: PropTypes.number,
    seconds: PropTypes.number
  }),
  /**
   * Convert the Date coming out of the picker to Market Time.
   * Assumes your min/max/disable dates are already converted to market time.
   */
  disabled: PropTypes.bool,
  /** @see MaskedTimeInput */
  showErrorMessage: PropTypes.bool,
  /** @see MaskedTimeInput */
  errorPositionAbsolute: PropTypes.bool,
  /** @see MaskedTimeInput */
  disableUnderline: PropTypes.bool,
  /** Allow exclusion of any date */
  disabledDay: PropTypes.func,
  /** Valid input has been accepted */
  onAccept: PropTypes.func.isRequired,
};


PopoverKeyboardTimePicker.defaultProps = {
  inputVariant: 'standard',
  backgroundOpacity: .3,
  marketTime: false,
  disabled: false,
  showErrorMessage: true,
  errorPositionAbsolute: false,
  disableUnderline: false,
  showNowButton: false,
  disabledTime: {},
  steps: {
    hours: 1,
    minutes: 1,
    seconds: 1
  }
};


export default React.memo(PopoverKeyboardTimePicker);
