import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import useInputStyles, { InputStyleProps } from 'src/app/slicedForm/shared/styles/useInputStyles';
import {
  makeStyles,
  Select,
  MenuItem,
  FormControl,
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {}
}))


function SimpleDropdown({
  className,
  inputClassName,
  label,
  options,
  selected,
  disabled,
  onChange,
  error,
  innerRef,
}) {
  const classes = useStyles();
  const inputClasses = useInputStyles();

  return (
    <FormControl
      className={clsx(
        className,
        classes.root,
        error && classes.formControlError
      )}
      ref={innerRef}
      variant="outlined"
      error={Boolean(error)}
    >
      <Select
        {...InputStyleProps}
        aria-label={label}
        className={clsx(inputClasses.input, inputClassName)}
        value={selected}
        disabled={disabled}
        onChange={event => onChange(event.target.value)}
        MenuProps={{ MenuListProps: { disablePadding: true } }}
        label=""
      >
        {
          Object.entries(options).map(([name, label]) => (<MenuItem key={name} value={name}>{label}</MenuItem>))
        }
      </Select>
    </FormControl>
  )
}


SimpleDropdown.propTypes = {
  className: PropTypes.string,
  label: PropTypes.string,
  /** { name: label } */
  options: PropTypes.object.isRequired,
  disabled: PropTypes.bool,
  selected: PropTypes.string,
  error: PropTypes.bool,
  onChange: PropTypes.func
}


export default React.memo(SimpleDropdown);
