import { createSelector } from 'reselect';
import _partition from 'lodash/partition';
import { STORE_KEY } from 'src/redux/layout/topListLayoutActions';
import { PROFILE_CONFIG } from 'src/redux/layout/topListLayoutSchema';
import { COMPONENT_TYPES } from 'src/app/TopListsMosaic/layout/components';

export const selectLayouts = state => state[STORE_KEY].layouts;
export const selectLayoutTabs = state => state[STORE_KEY].layoutTabs;
export const selectActiveLayoutId = state => state[STORE_KEY].activeLayout;

export const selectActiveLayout = createSelector(
  [selectLayouts, selectActiveLayoutId],
  (layouts, activeLayoutId) => ({ activeLayoutId, activeLayout: layouts?.[activeLayoutId] })
);

export const selectActiveLayoutComponents = createSelector(
  [selectActiveLayout],
  ({ activeLayout }) => activeLayout?.components
);

// Send back an object with the count of each component type. Used for permissions.
export const selectActiveLayoutComponentTypeCount = createSelector(
  [selectActiveLayout],
  ({ activeLayout }) => {
    if (!activeLayout || !activeLayout?.components) return {};
    /* eslint-disable-next-line no-unused-vars */
    return Object.entries(activeLayout.components).reduce((acc, [_, component]) => {
      return {
        ...acc,
        [component.type]: (acc[component.type] || 0) + 1
      };
    }, {});
  }
);

const emptyObj = {};

export const selectLinkData = link => state => state[STORE_KEY].links?.[link] || emptyObj;

export const selectComponent = (componentId, layoutId) => state => state[STORE_KEY].layouts[layoutId].components[componentId];

export const selectComponentLink = (componentId, layoutId) => state => {
  return componentId !== 'zero-state' ? state[STORE_KEY].layouts[layoutId].components[componentId].link : null;
};

export const selectProfileList = profileListKey => state => state[STORE_KEY][profileListKey];

export const selectIsFetchingLayouts = state => state[STORE_KEY].isFetching.layouts;

export const selectWatchlistData = state => state[STORE_KEY][PROFILE_CONFIG.WATCHLIST_ROWS.dataKey];

export const selectWatchlistProfiles = state => {
  // Sort by predefined first, then alphabetical
  return state[STORE_KEY][PROFILE_CONFIG.WATCHLIST_ROWS.listKey]
    .slice()
    .sort((a, b) => {
      return (a.predefined === b.predefined)
        ? a.profileName.localeCompare(b.profileName)
        : a.predefined ? - 1 : 1
    })
}

export const selectListActiveWatchlists = createSelector(
  [selectActiveLayoutComponents, selectWatchlistProfiles],
  (components, watchlistProfiles) => {
    const list = watchlistProfiles
      .filter(watchlist => !watchlist.predefined)
      .map(watchlist => {
        const isActive = Object.values(components)
          .filter(component => component.type === COMPONENT_TYPES.WATCHLIST)
          .some(component => {
            return component[PROFILE_CONFIG.WATCHLIST_ROWS.idKey] === watchlist.id;
          });
        return { ...watchlist, isActive };
      })
      .sort((a, b) => a.profileName.localeCompare(b.profileName));

    return _partition(list, 'isActive');
  }
);


export const selectActiveWatchlistTickers = createSelector(
  [selectListActiveWatchlists, selectWatchlistData],
  ([activeLists], data) => {
    const activeIds = activeLists.map(watchlist => watchlist.id);
    return [...new Set(
      data
        .filter(row => activeIds.includes(row.watchlist_id))
        .map(row => row.ticker)
    )]
  }
)
