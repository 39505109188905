import { format, parse } from 'date-fns';
import { parseAssumeMarketTime } from 'src/utils/datetime/date-fns.tz';
import { getSliceBounds } from 'src/app/slicedForm/utility/filterTimeSliceFunctions';


/**
 * @typedef {Object} DisabledTime
 * @property {function(number): string|false} hours
 * @property {function(number, number): string|false} minutes
 * @property {function(number, number, number): string|false} seconds
 */



/**
 * @param {Date} now
 * @returns {DisabledTime}
*/
export const extendedHoursDisabledTimes = {
  hours: (value) => {
    if (value < 4 || value > 20) return 'Invalid hour'
    return false;
  }
  // minutes: (value, selectedHour) => []
  // seconds: (value, selectedHour, selectedMinute) => []
}




/**
 * @param {string} sliceId
 * @param {Slice[]} slices
 * @returns {DisabledTime}
 */
export const makeSliceDisabledTimes = (sliceId, slices) => {
  const { min, max } = getSliceBounds(sliceId, slices);

  const [minHours, minMinutes] = [min.getHours(), min.getMinutes()];
  const [maxHours, maxMinutes] = [max.getHours(), max.getMinutes()];
  const minStamp = format(min, 'HH:mm');
  const maxStamp = format(max, 'HH:mm');

  const hoursCallback = (value) => {
    if (value < minHours) return `Must be later than ${minStamp}`
    if (value > maxHours) return `Must be earlier than ${maxStamp}`
    return false;
  }

  const minutesCallback = (value, selectedHours) => {
    if (selectedHours === minHours && value < minMinutes)
      return `Must be later than ${minStamp}`;
    if (selectedHours === maxHours && value > maxMinutes)
      return `Must be earlier than ${maxStamp}`

    return false;
  }

  return {
    hours: hoursCallback,
    minutes: minutesCallback,
  }
}












