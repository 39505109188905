import React, { useMemo, createContext, useContext } from 'react';



const defaultEntityValue = {
  id: null,
  type: null,
  tree: [],
  disabled: false
};


const AggregateEntityContext = createContext(defaultEntityValue);


export function AggregateEntityProvider({ id, type, tree, disabled, children }) {

  const value = useMemo(() => {
    return {
      id,
      type,
      tree,
      disabled,
    };
  }, [id, type, disabled, tree]);

  return (
    <AggregateEntityContext.Provider value={value}>
      {children}
    </AggregateEntityContext.Provider>
  );
}


/**
 * Information about the Element itself.
 * Default values are invalid.
 */
export function useAggregateEntityContext() {
  const context = useContext(AggregateEntityContext);
  if (context === defaultEntityValue) {
    throw new Error('useAggregateEntityContext must be used within a AggregateEntityProvider');
  }
  return context;
}
