import React, { useCallback } from 'react';
import noop from 'lodash/noop';
import { getFullRowNodeData } from 'src/app/components/grid/topListNews/columns/columnDefs';


// stopPropagation/preventDefault not working, we need to check classnames for 'prevent-row-click'

function NewsTickerGroupCellRenderer(props) {
  const {
    ticker: selectedTicker,
    handleSelectNewsTicker = noop,
    isInnerGrid = false,
  } = props.context;


  const toggleCollapse = useCallback((event) => {
    event.stopPropagation();
    event.preventDefault();
    props.node.setExpanded(!props.node.expanded);
  }, [props.node]);


  const onTickerClick = (e) => {
    if (isInnerGrid) return;
    if (selectedTicker) return;

    e.preventDefault();
    e.stopPropagation();

    return handleSelectNewsTicker(getFullRowNodeData(props.node));
  }

  let linkClassName = 'prevent-row-click ag-news-cell-btn-sm --ag-news-btn-offset';

  if (selectedTicker) {
    linkClassName += ' ag-news-cell-btn-disabled';
  }


  const renderGroupCell = () => {
    const childCount = props.node?.data?.childCount;
    return (
      <div>
        <a
          role="button"
          onClick={onTickerClick}
          className={linkClassName}
        >
          {props.node?.data?.ticker || null}
        </a>
        {Boolean(childCount) && <span className="ag-news-cell-btn prevent-row-click ett-cell-count-value" onClick={toggleCollapse}>+{props.node.data.childCount}</span>}
      </div>
    );
  };

  const renderChildCell = () => {
    return (
      <div className={`ett-cell-count-child level-${props.node?.level}`}>
        <a
          role="button"
          onClick={onTickerClick}
          className={linkClassName}
        >
          {props.node?.data?.ticker || null}
        </a>
      </div>
    );
  };


  return (
    <div className="ag-cell-ett-news-group">
      {props.node?.allChildrenCount && props.node.allChildrenCount > 0 ? renderGroupCell() : renderChildCell()}
    </div>
  );
}

export default NewsTickerGroupCellRenderer;


