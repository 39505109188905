
//

/**
 * Flattens an array of arrays, inserting a separator between each nested array.
 * @param {array[]} arr
 * @param {*} separator
 * @return {*[]}
 * @example
 * joinNestedArrays([[a,b], [c, d]], separator)
 * >> [a, b, separator, c, d]
 */
const joinNestedArrays = (arr, separator) => arr.reduce((flatArr, section) => {
  if (section.length) {
    if (flatArr.length) flatArr.push(separator);
    flatArr.push(...section);
  }
  return flatArr;
}, []);


export default joinNestedArrays;
