// suppressAutoSize doesn't actually work for autoSizeAllColumns(), which is super bizarre. Do it manually.


export const COLUMN_RESIZERS = {
  FIT_TO_DATA: 'fitToData',
  FIT_TO_HEADERS: 'fitToHeaders',
  FIT_TO_PANEL: 'fitToPanel'

}

const COLUMN_RESIZE_CALLBACKS = {
  [COLUMN_RESIZERS.FIT_TO_DATA]: (gridRef) => autosizeColumns(gridRef, true),
  [COLUMN_RESIZERS.FIT_TO_HEADERS]: (gridRef) => autosizeColumns(gridRef, false),
  [COLUMN_RESIZERS.FIT_TO_PANEL]: (gridRef) => fitColumns(gridRef)
}


export const applyResize = (gridRef, resizeKey) => {
  try {
    return COLUMN_RESIZE_CALLBACKS[resizeKey](gridRef, resizeKey);
  } catch (err) {
    console.error('Grid applyResize key invalid', resizeKey);
  }
}


const autosizeColumns = (gridRef, skipHeader) => {
  if (!gridRef?.current?.columnApi) return false;

  const columns = gridRef.current.columnApi.getColumns();
  if (!columns || !columns.length) return false;

  const keys = columns.filter(c => !c.colDef.suppressAutoSize).map(c => c.colId);

  gridRef.current.columnApi.autoSizeColumns(keys, skipHeader);

  return true;
};


const fitColumns = (gridRef, width = undefined) => {
  if (!gridRef?.current?.api) return false;

  gridRef.current.api.sizeColumnsToFit(width);

  return true;
};


export const handleKeyboardRowNavigation = params => {
  const suggestedNextCell = params.nextCellPosition;

  const KEY_UP = 'ArrowUp';
  const KEY_DOWN = 'ArrowDown';

  const noUpOrDownKeyPressed = params.key !== KEY_DOWN && params.key !== KEY_UP;
  if (noUpOrDownKeyPressed) {
    return suggestedNextCell;
  }

  params.api.forEachNode(node => {
    if (node && node?.rowIndex && node.rowIndex === suggestedNextCell.rowIndex) {
      node.setSelected(true);
    }
  });

  return suggestedNextCell;
};


export const onRowSelected = (params, callback) => {
  if (params.node.isSelected()) {
    callback(params)
  }
}
