import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _noop from 'lodash/noop';
import { 
  useShorthandNumberInput
} from 'src/utils/abbreviateNumber';
import useInputStyles, { InputStyleProps } from 'src/app/slicedForm/shared/styles/useInputStyles';
import {
  makeStyles,
  FormControl,
  TextField,
} from '@material-ui/core'

const useStyles = makeStyles(() => ({
  root: {},
}))


/**
 * Component for showing and accepting abbreviated numbers. 10K -> 10000
 * The literal state value will be un-abreviated. The display value will be abreviated.
 *
 * 1) Number comes in from props unnabreviated.
 * 2) Number is abbreviated into _inputVal
 * 2) User types. _inputVal is accepted, no matter what
 *      - They can ype both abbreviated or unabbreviated
 * 3) onBlur, the _inputVal is unabbreviated and sent back to parent. Value saved.
 * 4) useStateFromProp() is triggered, resetting the logic loop.
 *
 * This way, the number the Parent holds is always unabreviated, but the user can type either.
 * The user is always shown the abbreviated version after onBlur.
 *
 * @component
 */
function ShorthandNumberInput({
  className,
  value,
  onAccept,
  disabled,
  startAdornmentString,
  endAdornmentString,
  innerRef,
  error,
  onFocus,
}) {
  const classes = useStyles();
  const inputClasses = useInputStyles();
  const {
    inputVal,
    error: inputFormatError,
    onBlur: submitOnBlur,
    onEnter: submitOnEnter,
    onChange
  } = useShorthandNumberInput({
    value,
    decimals: 6,
    startAdornmentString,
    endAdornmentString,
    onAccept
  })
 
  return (
    <FormControl
      variant="outlined"
      ref={innerRef}
      error={Boolean(error)}
      className={clsx(
        className,
        classes.root,
        (inputFormatError || error) && inputClasses.formControlError,
        (inputFormatError || error) && 'sf-form-control-error-flash'
      )}
    >
      <TextField
        InputProps={{...InputStyleProps}}
        className={clsx(
          inputClasses.input,
          inputClasses.highlight
        )}
        disabled={disabled}
        aria-label="Number"
        value={inputVal}
        error={inputVal === ''}
        onChange={onChange}
        onFocus={onFocus}
        onKeyDown={submitOnEnter}
        onBlur={submitOnBlur}
      />
    </FormControl>
  )
}

ShorthandNumberInput.defaultProps = {
  onBlur: _noop,
  onFocus: _noop,
}


ShorthandNumberInput.propTypes = {
  className: PropTypes.string,
  value: PropTypes.number,
  onAccept: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  delimit: PropTypes.bool,
  ignoreInvalidChars: PropTypes.bool,
  startAdornmentString: PropTypes.string,
  endAdornmentString: PropTypes.string,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
}


export default ShorthandNumberInput
