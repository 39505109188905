import React from 'react';
import MosaicPanelHeader from 'src/app/TopListsMosaic/layout/MosaicPanelHeader/MosaicPanelHeader';
import MosaicPanel from 'src/app/TopListsMosaic/layout/MosaicPanel';
import MosaicPanelBody from 'src/app/TopListsMosaic/layout/MosaicPanel';
import { Typography, Box } from '@material-ui/core';


function PlaceholderComponent({ title }) {

  return (
    <MosaicPanel>
      <MosaicPanelHeader
        title="Placeholder"
        showLinkingDropdownMenu={false}

        loading={false}
      />
      <MosaicPanelBody loading={false}>
        <Box textAlign="center" mt={4}>
          <Typography variant="h3">{title}</Typography>
        </Box>
      </MosaicPanelBody>
    </MosaicPanel>
  );
}

export default PlaceholderComponent;
