import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _noop from 'lodash/noop';
import { PatternFormat } from 'react-number-format';
import { format as formatDate, isAfter, isBefore, isMatch, isValid, isWeekend, parse } from 'date-fns';
import { getCurrentTradingDay, isDateHoliday, parseAssumeMarketTime } from 'src/utils/datetime/date-fns.tz';
import { makeStyles, TextField, } from '@material-ui/core';
import {
  COLUMNS,
  COLUMN_DEFS,
  getColumnsToShow,
} from 'src/app/components/pickers/definitions/timePickerColumns';
import {
  generateMask,
  makeInputDateString,
} from 'src/app/components/pickers/definitions/maskFunctions';


const useStyles = makeStyles(theme => ({
  root: {},
  noUnderline: {
    '& > .Mui-error > input': {
      color: theme.palette.error.main
    }
  },
  errorPositionAbsolute: {
    position: 'relative',
    '& > .MuiFormHelperText-root': {
      whiteSpace: 'nowrap',
      margin: 0,
      padding: 0,
      position: 'absolute',
      bottom: 0,
      right: 0,
      transform: 'translateY(100%)',
    }
  }
}));


export const patternChar = '#';


/**
 * KeyboardInput for dates
 * @component
 */
function MaskedTimeInput({
  className,
  format,
  placeholder,
  acceptStrategy,
  variant,
  mask,
  date,
  disabled,
  marketTime,
  disabledTime,
  steps,
  allowNull,
  disableUnderline,
  showErrorMessage,
  errorPositionAbsolute,
  autoFocus,
  onAccept,
  onKeyUp,
  customValidator,
  inputRef,
  endAdornment
}) {

  const classes = useStyles();
  const [error, setError] = useState(false);
  const [inputValue, setInputValue] = useState(makeInputDateString(date, format));


  const columnsToShow = getColumnsToShow(format);


  useEffect(() => {
    setInputValue(makeInputDateString(date, format));
  }, [date]);


  const pattern = useMemo(() => generateMask(format, patternChar), [format]);


  /**
   * @param {string} formattedValue
   * @return {{newDate: Date|false, error: string|null}} - Exclusive. Either newDate or error will be set.
   */
  const parseAndValidateTime = (formattedValue) => {
    let response = { newDate: false, error: null };
    let newDate = null;

    try {
      if (!formattedValue && allowNull) {
        return { ...response, newDate };
      }
      if (!isMatch(formattedValue, format)) {
        return { ...response, error: 'Invalid format' };
      }

      newDate = marketTime
        ? parseAssumeMarketTime(formattedValue, format)
        : parse(formattedValue, format, new Date());

      if (!isValid(newDate)) {
        return { ...response, error: 'Invalid date' };
      }

      const parts = {
        'hours': newDate.getHours(),
        'minutes': newDate.getMinutes(),
        'seconds': newDate.getSeconds()
      }

      for (const key of Object.keys(parts)) {
        if (columnsToShow.includes(key) && disabledTime?.[key]) {
          const definition = COLUMN_DEFS[key];
          const disableCallbackArgs = definition.disabledCallbackArgs.map(arg => parts[arg]);
          const errorMessage = disabledTime[key](parts[key], ...disableCallbackArgs);
          if (errorMessage) {
            return { ...response, error: errorMessage }
          }
        }
      }


    } catch (err) {
      console.error(err);
      return { ...response, error: 'Invalid format' };
    }

    return { ...response, newDate };
  };


  /**
   * Update internal state no matter what.
   * Check and set errors no matter what.
   * If acceptStrategy is onChange and the inputValue is valid, update parent state.
   * @param {{value: number, formattedValue: string}} values
   * @param {Event} sourceInfo
   * @returns {undefined}
   */
  const handleChange = (values, sourceInfo) => {
    const { formattedValue } = values;
    setInputValue(formattedValue);

    const { newDate, error } = parseAndValidateTime(formattedValue);
    setError(error); // set even if null, to clear errors

    if (acceptStrategy === 'onChange' && !error) {
      onAccept(newDate);
    }
  };


  /**
   * No matter what, reset the inputValue if it is invalid.
   * If acceptStrategy is onBlur the value is valid, update the parent state.
   * Do not set errors. Not necissary.
   * @param {string} formattedValue
   * @returns {undefined}
   */
  const handleBlur = (formattedValue) => {
    const { newDate, error } = parseAndValidateTime(formattedValue);
    if (acceptStrategy === 'onBlur' && !error) {
      onAccept(newDate);
    } else if (!newDate) {
      // reset self to parent state if invalid
      setInputValue(makeInputDateString(date, format));
    }
  };


  /**
   * Sometimes we want "Enter" to close a modal.
   * Allow "Enter" to also work as onBlur(), so a user doesn't lose their work.
   * Consequence being "Enter" with a bad input will revert.
   * @param event
   * @param {string} formattedValue
   * @returns {undefined}
   */
  const handleEnter = (event, formattedValue) => {
    if (event.key === 'Enter') {
      // allow propagation. Stop before it bubbles to the popup open button, if applicable.
      handleBlur(formattedValue);
    }
  };

  const inputProps = {
    disableUnderline,
    variant,
    disabled,
    inputRef,
    ...(Boolean(endAdornment) && { endAdornment })
  };

  return (
    <PatternFormat
      className={clsx(
        className,
        classes.root,
        {
          [classes.noUnderline]: disableUnderline,
          [classes.errorPositionAbsolute]: errorPositionAbsolute
        }
      )}
      autoFocus={autoFocus}
      placeholder={placeholder}
      error={Boolean(error)}
      helperText={showErrorMessage && error}
      format={pattern}
      value={inputValue}
      customInput={TextField}
      InputProps={inputProps}
      patternChar={patternChar}
      mask={mask}
      onBlur={() => handleBlur(inputValue)}
      onKeyDown={(event) => handleEnter(event, inputValue)}
      onKeyUp={onKeyUp}
      onValueChange={handleChange}
    />
  );
}

export const MaskedTimeInputPropTypes = {
  className: PropTypes.string,
  /** date-fns Unicode date formatting */
  format: PropTypes.string.isRequired,
  /** Shown when input is empty */
  placeholder: PropTypes.string.isRequired,
  /** What type of callback should trigger the parent state to update */
  acceptStrategy: PropTypes.oneOf(['onBlur', 'onChange']).isRequired,
  /** Styling of the input */
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  /** The character to show in place of empty positions */
  mask: PropTypes.string,
  /** Partial control */
  date: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  marketTime: PropTypes.bool.isRequired,
  /** @type {DisabledTime} */
  disabledTime: PropTypes.shape({
    hours: PropTypes.func,   // () => []
    minutes: PropTypes.func, // (selectedHour) => []
    seconds: PropTypes.func  // (selectedHour, selectedMinute) => []
  }),
  /** How many numbers to jump by */
  steps: PropTypes.shape({
    hours: PropTypes.number,
    minutes: PropTypes.number,
    seconds: PropTypes.number
  }),
  /** Hide the bottom border */
  disableUnderline: PropTypes.bool,
  /** Print the error message under the input */
  showErrorMessage: PropTypes.bool,
  /** Error message is absolute, under the input */
  errorPositionAbsolute: PropTypes.bool,
  autoFocus: PropTypes.bool,
  /** Valid input has been accepted, via 'strategy' prop */
  onAccept: PropTypes.func.isRequired,
  /** Internally used for DateRange picker focus */
  onKeyUp: PropTypes.func,
  /** Internall used for DateRange picker focus */
  inputRef: PropTypes.oneOfType([PropTypes.object, PropTypes.func]),
  /** JSX to render an icon/button inside the input */
  endAdornment: PropTypes.node,
};

MaskedTimeInput.propTypes = { ...MaskedTimeInputPropTypes };

export const MaskedTimeInputDefaultProps = {
  acceptStrategy: 'onBlur',
  variant: 'standard',
  mask: '_',
  disabled: false,
  marketTime: false,
  disabledTime: {},
  steps: {
    hours: 1,
    minutes: 1,
    seconds: 1
  },
  disableUnderline: false,
  showErrorMessage: true,
  errorPositionAbsolute: false,
  autoFocus: false,
  onKeyUp: _noop
};


MaskedTimeInput.defaultProps = {
  ...MaskedTimeInputDefaultProps
};


export default React.memo(MaskedTimeInput);
