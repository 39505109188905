import { EXPR_PREFIX } from "src/redux/expressions/globalExpressionReducer";
import { decideProfileNodeType, STRUCTURAL_TYPE_RULES, STRUCTURAL_TYPES } from "./mappingDirections";
import { makeExpressionValueFormatter } from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import { AGGREGATES, AGGREGATE_SHORT_LABELS } from "../FilterForm/definitions/inputEnums";



const defaultFormatter = args => args.value;

const _floatFmt = new Intl.NumberFormat('en-US', {
  type: 'decimal',
  minimumFractionDigits: 2,
  maximumFractionDigits: 2
}).format;

const floatFormatter = args => _floatFmt(args.value);


/**
 * Given an aggregate profile, return two structs deciding 
 * 1) The render order
 * 2) The column def / formatting
 *
 * groups = [
 *  { label: 'Group 1', data: ['agg_123', 'agg_456'] },
 * ]
 *
 * defs = {
 *   'agg_123': {
 *      formatter: (value) => value,
 *      label: 'My Label',
 *      name: 'agg_123',
 *      original: 'day0_chg',
 *      conditional_agg: 'AVG'
 *   }
 * }
 *
 * Basically, this is the column defs and rows for the table. We can throw away
 * anything within an Agg def, that is only relevant to backend.
 *
 * profileToQuery still needs to be called to convert the profile
 * info a backend query, but that is a seperate step.
 *
 * @param {object[]} aggregateGroups
 * @param {object[]} gridColDefs
 * @param {object[]} expressions
 * @returns {[object[], object]} groups, defs
 **/
export function aggregateToDefinitions(aggregateGroups, colDefs, expressions = []) {
  if (!aggregateGroups || !aggregateGroups.length) {
    return [[], {}];
  }

  const aggColDefs = {};

  const recurse = (node) => {
    const type = decideProfileNodeType(node)
    const treeKey = STRUCTURAL_TYPE_RULES[type]?.treeKey;

    switch (type) {
      case STRUCTURAL_TYPES.VISUAL_GROUP: {
        return {
          label: node.label,
          data: node[treeKey].map(recurse).filter(Boolean)
        }
      }
      case STRUCTURAL_TYPES.CONDITIONAL_AGG: {
        const { name, label, conditional_agg, target } = node;
        const { column: targetColumnName, value: targetValue, type: targetType } = target;

        // CNT with condition has no target.column. Its just an integer value.
        // Use the default formatter for now, with 'none' original col

        let formatter = defaultFormatter;
        let colDef;
        let original = targetColumnName || null;

        if (targetColumnName) {
          if (targetColumnName.startsWith(EXPR_PREFIX)) {
            colDef = expressions.find(e => e.name === targetColumnName);
            if (colDef) {
              formatter = makeExpressionValueFormatter(colDef) || defaultFormatter;
            }
          } else {
            colDef = colDefs.find(c => c.name === targetColumnName);
            formatter = colDef?.valueFormatter || defaultFormatter;
          }

          if (!colDef) {
            console.warn(`aggregateToDefinitions - Could not find colDef for: ${targetColumnName} name:${name}, label:${label}, agg:${conditional_agg}`)
            return;
          }
        }

        if (conditional_agg === AGGREGATES.CNT) {
          // CNT should always render integers
          formatter = defaultFormatter;
        } else if (conditional_agg === AGGREGATES.SD_POP) {
          // STDDEV should be float
          formatter = floatFormatter;
        }

        const aggColDef = {
          formatter: (value) => formatter({ value }),
          label,
          name,
          original,
          conditional_agg
        }

        aggColDefs[name] = aggColDef;

        return name;
      }
      default: {
        throw new Error(`aggregateToDefinitions - Unknown type: ${type}, only VISUAL_GROUP and CONDITONAL_AGG needed. ${JSON.stringify(node)}`);
      }
    }


  }

  const groups = aggregateGroups.map(recurse);

  return [groups, aggColDefs];
}
