import { useState, useEffect, useCallback } from 'react';

export function useLocalStorage(key) {

  // pull the initial value from local storage if it is already set
  const [state, setState] = useState(() => {

    if (!key) {
      return undefined;
    }
    let exValue = undefined;
    try {
      exValue = localStorage.getItem(key);
    } catch (err) {
      console.log('Failed getItem localstorage', err);
    }
    if (exValue === 'undefined' || exValue === undefined) {
      return undefined;
    }
    if (exValue) {
      return JSON.parse(exValue);
    }
    return undefined;
  });


  useEffect(() => {
    try {
      localStorage.setItem(key, JSON.stringify(state));
    } catch (err) {
      console.log('Failed setItem localstorage: ', err);
    }
  }, [state]);


  const storageWatcher = useCallback((e) => {
    if (e.newValue) {
      setState((currState) => {
        if (!key) {
          return null;
        }
        const newData = typeof e.newValue === 'string' ? e.newValue : JSON.parse(e.newValue || 'null');
        return newData == state ? newData : currState;
      });
    }
  }, [state]);


  useEffect(() => {
    window.addEventListener('storage', storageWatcher);
    return () => {
      window.removeEventListener('storage', storageWatcher);
    };
  }, [state]);


  return [state, setState];
}



// Ideally this would replace the above useLocalStorage hook, 
// but I'm worried about consequences.

export function useLocalStorageWithExpiry(key) {
  const [state, setState] = useState(() => {
    if (typeof window === 'undefined' || !key) return undefined;

    try {
      const storedValue = localStorage.getItem(key);
      if (storedValue) {
        const parsedValue = JSON.parse(storedValue);
        if (parsedValue.expiry && new Date().getTime() > parsedValue.expiry) {
          localStorage.removeItem(key);
          return undefined;
        }
        return parsedValue.value;
      }
    } catch (err) {
      console.error('Failed to retrieve local storage item', err);
    }
    return undefined;
  });

  const saveState = useCallback((value, ttl = null) => {
    try {
      const expiry = ttl ? new Date().getTime() + ttl : null;
      const valueToStore = JSON.stringify({ value, expiry });
      localStorage.setItem(key, valueToStore);
      setState(value);
    } catch (err) {
      console.error('Failed to set local storage item', err);
    }
  }, [key]);

  const removeState = useCallback(() => {
    try {
      localStorage.removeItem(key);
      setState(undefined);
    } catch (err) {
      console.error('Failed to remove local storage item', err);
    }
  }, [key]);

  const storageWatcher = useCallback((e) => {
    if (e.key === key) {
      try {
        if (e.newValue) {
          const newData = JSON.parse(e.newValue);
          if (newData.expiry && new Date().getTime() > newData.expiry) {
            removeState()
          } else {
            setState(newData.value);
          }
        } else {
          setState(undefined);
        }
      } catch (err) {
        console.error('Failed to parse storage event data', err);
      }
    }
  }, [key]);

  useEffect(() => {
    window.addEventListener('storage', storageWatcher);
    return () => {
      window.removeEventListener('storage', storageWatcher);
    };
  }, [storageWatcher]);

  return [state, saveState, removeState];
}
