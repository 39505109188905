import React, { createContext, useContext, useMemo, useState, useEffect, useCallback, useReducer } from 'react';
import shortUuid from 'short-uuid';

/**
 * @typedef {Object} Notification
 * @property {string} message
 * @property {keyof SEVERITY} severity
 */


const temporaryID = () => shortUuid.generate().slice(0, 8);


const isNotificationEqual = (a, b) => {
  if (!a || !b) return false;
  return a.severity === b.severity && a.message === b.message;
}


const SEVERITY = {
  INFO: 'info',
  ERROR: 'error',
  WARNING: 'warning',
  SUCCESS: 'success',
}

const initialApiContextValue = {
  enqueueNotification: () => { },
  dequeueNotification: () => { },
}

const initialValueContextValue = {
  notification: null
}


const FormNotificationApiContext = createContext(initialApiContextValue);

const FormNotificationValueContext = createContext(initialValueContextValue);



const initialReducerState = {
  notification: null
}


const notificationReducer = (state, action) => {
  switch (action.type) {
    case 'ENQUEUE_NOTIFICATION': {
      const { notification, dedup } = action.payload;
      if (dedup && isNotificationEqual(action.payload, state.notification)) {
        return state;
      }
      return {
        notification
      }
    }
    case 'HIDE_NOTIFICATION': {
      if (state.notification.id !== action.payload) {
        return state;
      }
      return {
        notification: {
          ...state.notification,
          visible: false
        }
      }
    }
    case 'REMOVE_NOTIFICATION': {
      if (state.notification.id !== action.payload) {
        return state;
      }
      return {
        notification: null
      }
    }
    default: {
      return state;
    }
  }
}


const removeNotification = (id, dispatch) => {
  dispatch({
    type: 'HIDE_NOTIFICATION',
    payload: id
  });
  setTimeout(() => {
    dispatch({
      type: 'REMOVE_NOTIFICATION',
      payload: id
    })
  }, 1000)
}



export default function NotificationProvider({
  children,
  timeout = 5000,
  dedup = true,
}) {
  const [state, dispatch] = useReducer(notificationReducer, initialReducerState);

  const enqueueNotification = useCallback(({ title, message, severity }, _timeout = 5000) => {
    if (!message) {
      console.warn('Cannot enqueue notification without a message');
    }
    if (!severity || !Object.values(SEVERITY).includes(severity)) {
      console.warn('Cannot enqueue notification without a valid severity');
    }
    const id = temporaryID();
    dispatch({
      type: 'ENQUEUE_NOTIFICATION',
      payload: {
        notification: {
          title,
          message,
          severity,
          id,
          visible: true
        },
        dedup
      }
    });

    if (timeout) {
      setTimeout(() => {
        removeNotification(id, dispatch)
      }, _timeout || timeout);
    }

    return id;
  }, [dedup, timeout]);

  const dequeueNotification = useCallback((id) => {
    removeNotification(id, dispatch)
  }, []);


  const apiContextValue = useMemo(() => ({
    enqueueNotification,
    dequeueNotification,
  }), [enqueueNotification, dequeueNotification]);


  const notificationContextValue = useMemo(() => ({
    notification: state.notification
  }), [state.notification]);


  return (
    <FormNotificationApiContext.Provider value={apiContextValue}>
      <FormNotificationValueContext.Provider value={notificationContextValue}>
        {children}
      </FormNotificationValueContext.Provider>
    </FormNotificationApiContext.Provider>
  )
}



export function useNotificationApi() {
  const context = useContext(FormNotificationApiContext);
  return context;
}


export function useNotificationValue() {
  const context = useContext(FormNotificationValueContext);
  return context;

}
