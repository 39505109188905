// Use these variables app-wide when referencing market session key names. These correspond to dynamo and rds key names.

export const MARKET_SESSION_KEYS = {
  PREMARKET: 'pm',
  REGULAR_MARKET: 'market',
  AFTER_HOURS: 'ah',
  CLOSED: 'cl'
};
export const MARKET_SESSION_CFG = {
  [MARKET_SESSION_KEYS.PREMARKET]: [{
    from: '04:00:00',
    to: '09:29:59',
    label: 'Premarket'
  }],
  [MARKET_SESSION_KEYS.REGULAR_MARKET]: [{
    from: '09:30:00',
    to: '15:59:59',
    label: 'Market Hours'
  }],
  [MARKET_SESSION_KEYS.AFTER_HOURS]: [{
    from: '16:00:00',
    to: '19:59:59',
    label: 'After Hours'
  }],
  [MARKET_SESSION_KEYS.CLOSED]: [
    {
      from: '20:00:00',
      to: '23:59:59',
      label: 'Closed'
    },
    {
      from: '00:00:00',
      to: '03:59:59',
      label: 'Closed'
    }
  ]
};
