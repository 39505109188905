import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  makeStyles
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    width: 125
  },
}));


/**
 * Alternate version of react-date-range's DefinedRange, intended to select single dates. Pair with single DatePicker.
 * Displays a vertical list of buttons. Uses react-date-range CSS as a scaffolding.
 *
 * Less intellegent:
 *  - Does not show previews.
 *  - Does not have 'inputDays' feature.
 *  - Does not handle date logic itself, the parent must implement.
 *
 *
 * react-date-range's DefinedRange only properly works when the buttons represent real ranges. They also cannot
 * be disabled. This component is intended to be used with a single date picker, and can be disabled.
 */
function DefinedStaticDays({
  className,
  marketTime,
  date,
  definitions,
  onClick,
}) {
  const classes = useStyles();


  if (!definitions || !definitions.length) {
    return null;
  }

  return (
    <div
      className={clsx(
        className,
        'rdrDefinedRangesWrapper',
        classes.root,
      )}>
      <div className="rdrStaticRanges">
        {definitions.map(staticDay => {
          const { date: staticDate, selected, disabled } = staticDay.get(date);
          return (
            <button
              key={staticDay.name}
              type="button"
              disabled={disabled}
              className={clsx(
                'rdrStaticRange',
                { 'rdrStaticRangeSelected': selected }
              )}
              onClick={() => onClick(staticDate)}
            >
                <span className="rdrStaticRangeLabel">
                  {staticDay.label}
                </span>
            </button>
          );
        })}
      </div>
    </div>
  );
}


DefinedStaticDays.propTypes = {
  className: PropTypes.string,
  marketTime: PropTypes.bool,
  date: PropTypes.instanceOf(Date),
  definitions: PropTypes.arrayOf(PropTypes.shape({
    /** Pass in curr date, get { date, disabled, selected } for this button */
    get: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
  })),
  onClick: PropTypes.func.isRequired,
};

DefinedStaticDays.defaultProps = {
  marketTime: false,
  definitions: [],
};

export default React.memo(DefinedStaticDays);
