

/**
 * General function to map columns from one column definition set into another.
 *
 * If the column is not in the columMap, then we check for it inside targetColumnDefs.
 * If its not in either, then return undefined. That means the column is unavailable in
 * this context.
 *
 * Its recommended to create partials with the columnMap and targetColumnDefs 
 * filled in to make this easier to make this function more reusable. Do this
 * close to your data, maybe in datasource/columnDefs.js
 *
 * @param {string} colName - The column definition to map
 * @param {Object<string, string>} columnMap - The mapping of column names FROM source TO target
 * @param {ColumnDef[]} targetColumnDefs - The target column definitions. Use this if the mapping is empty. SHOULD BE COLUMN COLUMNS, not filter columns
 * @returns {string|undefined} - The target column name, or undefined if not mappable
 **/
export function mapExpressionColumn(
  colName,
  columnMap,
  targetColumnDefs
) {
  let targetName = colName;

  if (colName in columnMap) {
    targetName = columnMap[colName];
  }

  return targetColumnDefs.find(col => col.name === targetName)?.name;
}


/**
 * Returns the expression back to its original state
 **/
export const unmapExpression = (expression) => {
  const { invalid, unmap, ...rest } = expression;

  if (unmap && Object.keys(unmap).length) {
    Object.entries(unmap).forEach(([label, colObj]) => {
      if (label in rest.args) {
        rest.args = { ...rest.args, [label]: colObj }
      } else {
        console.warn(`Unmapped label not found in expression args. Did you make a mistake? ${expression.expression}<${expression.args}> unmap=${expression.unmap}`);
      }
    })
  }

  return rest;
}


export const mapExpression = (expression, mapper) => {
  const unmap = {};

  const args = Object.entries(expression.args).reduce((acc, [label, colObject]) => {
    if (!colObject?.column) {
      acc[label] = colObject;
      return acc;
    }

    const mappedColName = mapper(colObject?.column);


    if (!mappedColName) {
      expression.invalid = true;
      acc[label] = colObject;
    } else if (mappedColName === colObject.column) {
      acc[label] = colObject;
    } else {
      const colName = colObject.column;
      acc[label] = { column: mappedColName };
      unmap[label] = { column: colName }
    }
    return acc;
  }, {});

  return {
    ...expression,
    args,
    unmap
  }
}



export const realtime_to_historical = {
  "ah_chg": "day0_ah_chg",
  "ah_chg_p": "day0_ah_chg_pct",
  "ah_h": "day0_ah_high",
  "ah_l": "day0_ah_low",
  "ah_range": "day0_ah_range",
  "ah_range_pct": "day0_ah_range_pct",
  "ah_v": "day0_ah_vol",
  "c": "day0_close",
  "chg_ah_h_pc_pct": "day0_ah_high_pct",
  "chg_ah_l_pc_pct": "day0_ah_low_pct",
  "chg_h_o_pct": "day0_open_high_pct",
  "chg_h_pc_pct": "day0_close_high_pct",
  "chg_l_o_pct": "day0_open_low_pct",
  "chg_l_pc_pct": "day0_close_low_pct",
  "chg_pm_h_pc_pct": "day0_pm_high_pct",
  "chg_pm_l_pc_pct": "day0_pm_low_pct",
  "dollar_vol": "day0_dollar_vol",
  "h": "day0_high",
  "l": "day0_low",
  "o": "day0_open",
  "o_chg": "day0_ret_open",
  "o_chg_p": "day0_ret_open_pct",
  "pc": "day_m1_close",
  "pc_2_chg_p": "day0_chg2_pct",
  "pc_2_chg": "day0_chg2",
  "pc_3_chg_p": "day0_chg3_pct",
  "pc_3_chg": "day0_chg3",
  "pc_4_chg_p": "day0_chg4_pct",
  "pc_4_chg": "day0_chg4",
  "pc_5_chg_p": "day0_chg5_pct",
  "pc_5_chg": "day0_chg5",
  "pm_chg": "day0_gap",
  "pm_chg_p": "day0_gap_pct",
  "pm_h": "day0_pm_high",
  "pm_l": "day0_pm_low",
  "pm_range": "day0_pm_range",
  "pm_range_pct": "day0_pm_open_range_pct",
  "pm_v": "day0_pm_vol",
  "range": "day0_range",
  "range_pct": "day0_range_pct",
  "rvol": "day0_rvol_5",
  "session_chg": "day0_chg",
  "session_chg_p": "day0_chg_pct",
  "session_lp": "day0_close",
  "session_v": "day0_vol",
  "ssr": "day0_open_ssr",
  "trades": "day0_trades",
  "vw": "day0_vw",
}


export const historical_to_relatime = Object.entries(realtime_to_historical).reduce((acc, [k, v]) => ({ ...acc, [v]: k }), {})

const historical_ignore_columns = [
  // original
  "floatrotation",
  "shortpercentfloat",
  // new
  "ext_hours_v",
  "chg_allsession_h_pc_pct",
  "chg_allsession_l_pc_pct",
  "chg_allsession_range_pct",
  "chg_allday_h_pc_pct",
  "chg_allday_l_pc_pct",
  "chg_allday_range_pct",
  "day_m1_atr_14_pct",
  "day_m2_atr_14_pct",
  "day_m3_atr_14_pct",
  "day_21_high_pct",
  "day_21_low_pct",
  "day_63_high_pct",
  "day_63_low_pct",
  "day_126_high_pct",
  "day_126_low_pct",
  "day_252_high_pct",
  "day_252_low_pct",
  "pp_pivot_pct",
  "pp_r1_pct",
  "pp_r2_pct",
  "pp_r3_pct",
  "pp_s1_pct",
  "pp_s2_pct",
  "pp_s3_pct",
]
