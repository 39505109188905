import React, { useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  Typography,
  Dialog,
} from '@material-ui/core';
import { useDialogStyles, useListItemStyles } from './useDialogStyles';
import ManageLayoutsListItem from 'src/app/TopListsMosaic/layout/ManageLayouts/ManageLayoutsListItem';
import { generateSimpleFilenameVerson } from 'src/utils/generateProfileFilenameVersion';
import NewTemplateButton from './NewTemplateButton';
import { DEFAULT_TEMPLATE_ID } from '../constants';


function TemplateDialog({
  className,
  description,
  templates,
  selectedTemplateId,
  open,
  onClose,
  onSelect,
  onDelete,
  onCreate
}) {
  const classes = useDialogStyles();
  const newFileName = generateSimpleFilenameVerson('New Template', templates.map(({ name }) => name));
  const [listItemStates, setListItemStates] = useState({});


  const handleFormStep = (id, step) => {
    setListItemStates({
      ...listItemStates,
      [id]: step
    })
  }


  return (
    <Dialog
      disableAutoFocus
      disableEnforceFocus
      disableScrollLock
      disablePortal
      onClose={onClose}
      open={open}
      fullWidth
      transitionDuration={0}
      style={{ position: 'absolute' }}
      className={clsx(className, classes.root)}
    >
      <div className={classes.modalHeader}>
        <Typography variant="h2" className={classes.modalTitle}>Templates</Typography>
        {description && <Typography variant="body2" mt={2}>{description}</Typography>}
      </div>
      <NewTemplateButton
        newFileName={newFileName}
        onConfirm={onCreate}
        useStyles={useListItemStyles}
      />
      <div className={classes.modalBody}>
        {templates.map(template => {
          return (
            <ManageLayoutsListItem
              key={template.id}
              id={template.id}
              useStyles={useListItemStyles}
              formStep={listItemStates?.[template.id]}
              selected={false}
              title={template.name}
              onClick={(id) => {
                if (id !== selectedTemplateId) {
                  onSelect(id);
                }
              }}
              onFormStep={(step) => handleFormStep(template.id, step)}
              onDelete={template.id !== DEFAULT_TEMPLATE_ID && Boolean(onDelete) ? onDelete : null}
              disabled={template.isFetching}
              showLoading={template.isFetching}
              checkbox={false}
            />
          );
        })}
      </div>
    </Dialog>
  );
}


TemplateDialog.propTypes = {
  className: PropTypes.string,
  description: PropTypes.string,
  templates: PropTypes.array,
  selectedTemplateId: PropTypes.string,
  open: PropTypes.bool,
  onClose: PropTypes.func,
  onSelect: PropTypes.func,
  onDelete: PropTypes.func,
  onCreate: PropTypes.func
};


export default TemplateDialog;
