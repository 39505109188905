export const REQUEST__FETCH = '@data-fetch/request__fetch';
export const SUCCESS__FETCH = '@data-fetch/success__fetch';
export const FAILURE__FETCH = '@data-fetch/failure__fetch';

export const dataFetchReducer = (state, action) => {
  switch (action.type) {
    case REQUEST__FETCH: {
      return {
        ...state,
        isFetching: true,
        error: false,
      };
    }
    case SUCCESS__FETCH: {
      const a = {
        ...state,
        isFetching: false,
        error: false,
        data: action.payload
      };
      return a;
    }
    case FAILURE__FETCH: {
      return {
        ...state,
        isFetching: false,
        error: action.payload.error,
        data: action.payload.data
      };
    }
    default:
      { throw new Error(`Invalid Action ${action.type}`); }
  }
};
