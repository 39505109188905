import React, { createContext, useContext, useEffect, useMemo, useReducer } from 'react';
import { createContext as createSelectableContext, useContextSelector } from 'use-context-selector';
import withReducerLogging from 'src/utils/withReducerLogging';
import NotificationProvider from './NotificationProvider';
import { produce } from 'immer';
import reduceReducers from 'reduce-reducers';
import PropTypes from "prop-types";
import FormSettingsProvider from './FormSettingsProvider';

const defaultDispatchValue = (state) => { }

const defaultFormValues = {
  activeProfile: null,
  profiles: [],
  // expressions: [] Possible, but we don't want it as a default
}

const defaultFormActionValues = {
  onSubmit: ({ state, dispatch }) => { },
  onSave: ({ state, dispatch }) => { },
  onCancel: ({ state, dispatch }) => { },
  onCreate: ({ state, dispatch }) => { },
  onCopy: ({ state, dispatch }) => { },
  /* Not yet implemented, but planned */
  onNextPage: ({ state, dispatch }) => { },
  onPrevPage: ({ state, dispatch }) => { },
  page: 0,
}

const FormDispatchContext = createContext(defaultDispatchValue);

const FormContext = createSelectableContext(defaultFormValues);

const FormActionContext = createContext(defaultFormActionValues);


let LOG_REDUCER = false;
if (process.env.NODE_ENV !== 'production') {
  LOG_REDUCER = true;
}

/**
 * A Wrapper component to provide many kinds of Form context, that is common accross our forms.
 *
 * FormDispatchContext - dispatch function to handle updating via reducers.
 * FormContext - State of the form fields and other user input.
 * FormSettingsProvider - Settings and UI state for the form, but not user input related.
 * FormActionContext - Generalized actions, like saving, cancelling, copying, etc.
 *
 * reducers, initialState, and initFunc props MUST BE STABLE REFERENCES!
 * Memoize them, or call them from global constants.
 *
 * @component
 */
function FormProvider({
  providerName,
  initialState,
  permissions,
  settings,
  reducers,
  initFunc,
  actions,
  children,
}) {
  const createReducerArgs = useMemo(() => {
    return [
      withReducerLogging(
        reduceReducers(
          {},
          ...reducers.map(r => produce(r)),
        ),
        providerName,
        LOG_REDUCER,
        true
      ),
      initialState,
      initFunc
    ]
  }, [initialState, reducers, initFunc]);

  const [state, dispatch] = useReducer(...createReducerArgs);

  return (
    <FormDispatchContext.Provider value={dispatch}>
      <FormContext.Provider value={state}>
        <FormSettingsProvider
          settings={settings}
          permissions={permissions}
        >
          <NotificationProvider timeout={5000}>
            <FormActionContext.Provider value={actions}>
              {children}
            </FormActionContext.Provider>
          </NotificationProvider>
        </FormSettingsProvider>
      </FormContext.Provider>
    </FormDispatchContext.Provider>
  )
}


export function useFormDispatch() {
  const context = useContext(FormDispatchContext)
  if (context === defaultDispatchValue) {
    throw new Error('useFormDispatch must be used within a FormProvider')
  }
  return context;

}

export function useFormActions() {
  const context = useContext(FormActionContext);
  if (context === defaultFormActionValues) {
    throw new Error('useFormActions must be used within a FormProvider')
  }
  return context;
}

/**
 * Equivalent to redux's useSelector(), but for our Form context.
 * @example
 * const value = useFormSelector(state => state.value);
 */
export function useFormSelector(selector) {
  const context = useContextSelector(FormContext, selector)
  if (context === defaultFormValues) {
    throw new Error('useFormSelector must be used within a FormProvider')
  }
  return context;
}

/*
 * Same as useFormSelector, but allows for the default value. Basically,
 * if the parent context doesn't exist, don't error.
 **/
export function useFormSelector_Unprotected(selector) {
  return useContextSelector(FormContext, selector)
}

/**
 * Memoizes a makeSelector on the given argument.
 * Multiple arguments should be passed as a single object.
 * 
 * Must be used with a makeSelector structure, a function that returns a createSelector
 * callback. The makeSelector must have the argument handling already defined.
 * 
 * If this were not to be used, then the createSelector would be reinstantiated on every render.
 * 
 * @param {function} makeSelector
 * @param {*} argument
 * @example
 * const makeSelector = () => createSelector(
 *  [
 *    state => state.something, 
 *    (_, id) => id,  // Notice the argument is being handled
 *  ],
 *  (something, id) => something[id]
 * ) 
 * 
 * const Component = ({ id }) => {
 *  const value = useParameterizedFormSelector(makeSelector, id);
 *  // value will be memoized on id, and by createSelector 
 *  // dependancies as usual.
 * }
 * 
 */
export function useParameterizedFormSelector(makeSelector, argument) {
  const memoizedSelector = useMemo(() => {
    const selector = makeSelector();
    return state => selector(state, argument);
  }, [argument]);

  return useFormSelector(memoizedSelector);
}



FormProvider.propTypes = {
  /** The initial state of the Form values */
  initialState: PropTypes.object.isRequired,
  /** 
   * One or many reducers that will be combined via reduceReduers(), in parallel.
   * This allows you to share common actions between forms, like saving and loading profiles.
   */
  reducers: PropTypes.arrayOf(PropTypes.func).isRequired,
  /** Optional function to map the initial state before saving to the reducer */
  initFunc: PropTypes.func,
  permissions: PropTypes.object,
  settings: PropTypes.object,
  /**
   * Object of callbacks that define generalized form controls. Saving, closing, copying, etc.
   * If you do not specify a callback, the associated button will not be rendered.
   *
   * For the list of available actions:
   * @see FormProvider.defaultFormActionValues
   */
  actions: PropTypes.objectOf(PropTypes.func).isRequired,
  children: PropTypes.any.isRequired
}


const initFunc = (state) => state;


FormProvider.defaultProps = {
  providerName: 'FormProvider',
  initialState: {},
  initFunc,
  permissions: {},
  settings: {},
  actions: {}
}


export default FormProvider;
