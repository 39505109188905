import React, { useState } from "react";
import clsx from 'clsx';
import { STICKY_OPTIONS } from "../definitions/inputEnums";
import { NODE_POSITIONS, STRUCTURAL_TYPES } from 'src/app/slicedForm/mapping/mappingDirections/index';
import { DELETE_ENTITY } from "src/app/slicedForm/FilterForm/reducers/filterReducer";
import { useFormDispatch } from "src/app/slicedForm/shared/context/FormProvider";
import { useFilterEntityContext, useFormGroupContext, onAndClick } from "../context/FilterEntityContext";
import EntityIconButton from 'src/app/slicedForm/FilterForm/elements/EntityIconButton';
import EntityDoubleConfirmationIconButton from 'src/app/slicedForm/FilterForm/elements/EntityDoubleConfirmationIconButton';
import { useEntity } from 'src/app/slicedForm/FilterForm/context/useFormEntity';
import { useColumnDef } from 'src/app/slicedForm/shared/context/ColumnDefsProvider';
import { CheckIcon } from 'src/theme/EdgeIcons';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Box,
  useTheme,
  makeStyles,
} from "@material-ui/core";
import WithColumnPickerForm from "./WithColumnPickerForm";




const useStyles = makeStyles(theme => ({
  root: {},
  tempButton: {
    minWidth: 36,
  }
}))



function FilterEntityActions({ className }) {
  const classes = useStyles();
  const theme = useTheme();
  const dispatch = useFormDispatch();
  const { id, position, disabled } = useFilterEntityContext();
  const { groupId, onOrClick, groupType } = useFormGroupContext();
  const { useWatch } = useEntity(id);
  const column = useWatch('left.column');
  const colDef = useColumnDef(column);
  const [confirming, setConfirming] = useState(false);

  const sticky = colDef?.sticky === STICKY_OPTIONS.STICKY;

  const buttonTitle = groupType === STRUCTURAL_TYPES.CONDITIONAL_AGG
    ? 'Add another "OR" filter to this group'
    : 'Add another "AND" filter to this group';

  const onButtonSubmit = groupType === STRUCTURAL_TYPES.CONDITIONAL_AGG
    ? colDef => onAndClick(groupId, colDef, dispatch)
    : colDef => onOrClick(id, colDef, dispatch);

  const buttonLabel = groupType === STRUCTURAL_TYPES.CONDITIONAL_AGG
    ? 'AND'
    : 'OR'

  return (
    <Box
      className={clsx(className, classes.root)}
      display="flex"
      justifyContent="flex-end"
      alignItems="center"
    >
      <WithColumnPickerForm
        anchorPosition="right"
        onSelect={onButtonSubmit}
      >
        <EntityIconButton
          aria-label={buttonTitle}
          title={buttonTitle}
          disabled={disabled || position !== NODE_POSITIONS.LAST}
          text
        >
          {buttonLabel}
        </EntityIconButton>
      </WithColumnPickerForm>
      {!sticky ? (
        <EntityDoubleConfirmationIconButton
          confirming={confirming}
          BaseIcon={ClearIcon}
          baseLabel="Delete this filter"
          baseColor={theme.palette.primary.main}
          ConfirmIcon={CheckIcon}
          confirmLabel={'Confirm delete?'}
          confirmColor={theme.palette.text.negative}
          disabled={disabled}
          setConfirming={setConfirming}
          onAccept={() => dispatch({
            type: DELETE_ENTITY,
            payload: {
              id,
              groupId
            }
          })}
        />
      ) : (
        <EntityIconButton disabled />
      )}
    </Box>
  )
}


export default FilterEntityActions;
