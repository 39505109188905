import React, { useState, useCallback, useRef, useEffect } from 'react';
import clsx from 'clsx';
import MaskedDateInput, {
  MaskedDateInputDefaultProps,
  MaskedDateInputPropTypes
} from 'src/app/components/pickers/components/MaskedDateInput';
import ConditionalWrapper from 'src/app/components/utility/ConditionalWrapper';
import { RangeShape } from 'src/app/components/pickers/components/DateRangePicker';
import PropTypes from 'prop-types';
import { Box, Typography } from '@material-ui/core';
import { isValid } from 'date-fns';
import IconAdornment from 'src/app/components/pickers/components/IconAdornment';
import {
  ClickAwayListener,
  makeStyles
} from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {},
  formattedSeparatorCont: {
    '& p': {
      display: 'inline',
      verticalAlign: 'sub'
    }
  }
}));


/**
 * Wraps two MaskedDateInputs to create a range.
 * Some logic handles moving the cursor between them.
 *
 * We could make a single large masked input, but its less pretty.
 * @component
 */


function MaskedDateRangeInputs({
  className,
  inputClassName,
  leftInputClassName,
  rightInputClassName,
  acceptStrategy,
  range,
  onAccept,
  flexWrap,
  endAdornment,
  separator,
  leftTopLabel,
  rightTopLabel,
  disabled,
  ...rest
}) {
  const classes = useStyles();
  const [tempRange, setTempRange] = useState(range);
  const endDateInputRef = useRef(null);

  if (rest.maxDate && rest.disableFuture) throw Error('Cannot have both maxDate and disableFuture');

  useEffect(() => setTempRange(range), [range]);

  /**
   * Valid input recieved from children
   */
  const handleInputAccept = (newRange) => {
    if (isValid(newRange.startDate) && isValid(newRange.endDate)) {
      // Sort them, so they aren't out of order. Better than showing error.
      const sorted = [newRange.startDate, newRange.endDate].sort((a, b) => a.getTime() - b.getTime());
      newRange.startDate = sorted[0];
      newRange.endDate = sorted[1];
    }
    if (acceptStrategy === 'onAccept') onAccept(newRange);
    setTempRange(newRange);
  };


  /**
   * User is unfocusing component. Propagate to parent.
   * TODO: This is better for performance on History. Ideally, we'd refactor history to handle onAccept more frequently.
   */
  const handleClickAway = () => {
    onAccept(tempRange);
  };

  /**
   * Checks the carrot position in the starDate input, so we can refocus to the next input
   * once they've reached the end.
   */
  const handleKeyUpRefocus = (event) => {
    if (
      endDateInputRef.current &&
      event.target.selectionStart === rest.format.length &&
      event.target.value
    ) {
      endDateInputRef.current.focus();
      endDateInputRef.current.setSelectionRange(0, 0);
    }
  };


  const formattedSeparator = React.isValidElement(separator) ? separator : <Typography>{separator}</Typography>;

  const leftLabel = React.isValidElement(leftTopLabel) ? leftTopLabel : <Typography>{leftTopLabel}</Typography>;
  const rightLabel = React.isValidElement(rightTopLabel) ? rightTopLabel : <Typography>{rightTopLabel}</Typography>;


  return (
    <Box
      className={clsx(className, classes.root)}
      display="flex"
    >
      <ConditionalWrapper
        condition={acceptStrategy === 'onClickAway'}
        wrapper={children => (
          <ClickAwayListener
            onClickAway={handleClickAway}
            mouseEvent="onMouseDown"
            touchEvent="onTouchStart"
          >
            {children}
          </ClickAwayListener>
        )}
      >
        <Box
          display="flex"
          alignItems="center"
          flexWrap={flexWrap}
        >
          <ConditionalWrapper
            condition={Boolean(leftTopLabel)}
            wrapper={children => <div className='--with-label'>{leftLabel}{children}</div>}
          >
            <MaskedDateInput
              {...rest}
              disabled={disabled}
              className={clsx(inputClassName, leftInputClassName)}
              acceptStrategy="onBlur"
              errorPositionAbsolute
              date={tempRange.startDate}
              autoFocus={false}
              onAccept={startDate => handleInputAccept({ ...tempRange, startDate })}
              onKeyUp={handleKeyUpRefocus}
            />
          </ConditionalWrapper>

          {Boolean(formattedSeparator) && <div className={classes.formattedSeparatorCont}>{formattedSeparator}</div>}

          <ConditionalWrapper
            condition={Boolean(rightTopLabel)}
            wrapper={children => <div className='--with-label'>{rightLabel}{children}</div>}
          >
            <MaskedDateInput
              {...rest}
              disabled={disabled}
              className={clsx(inputClassName, rightInputClassName)}
              date={tempRange.endDate}
              autoFocus={false}
              errorPositionAbsolute
              acceptStrategy="onBlur"
              inputRef={endDateInputRef}
              onAccept={endDate => handleInputAccept({ ...tempRange, endDate })}
            />
          </ConditionalWrapper>
        </Box>
      </ConditionalWrapper>
      {Boolean(endAdornment) && (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          className='range-end-adornment'
        >
          {endAdornment}
        </Box>
      )}
    </Box>
  );
}


const {
  date: unusedDate,
  handleKeyUp: unusedKeyUp,
  autoFocus: unusedAutoFocus,
  ...maskedDatePropTypes
} = MaskedDateInputPropTypes;


MaskedDateRangeInputs.propTypes = {
  ...maskedDatePropTypes,
  acceptStrategy: PropTypes.oneOf(['onAccept', 'onClickAway']),
  inputClassName: PropTypes.string,
  flexWrap: PropTypes.string,
  range: RangeShape.isRequired,
  separator: PropTypes.any,
};


MaskedDateRangeInputs.defaultProps = {
  ...MaskedDateInputDefaultProps,
  acceptStrategy: 'onAccept',
  flexWrap: 'nowrap',
  separator: '->'
};


export default React.memo(MaskedDateRangeInputs);
