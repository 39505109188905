import React from 'react';

/**
 * Implementation of GitHub Primer's Slots functionality.
 * master:    https://github.com/primer/react/blob/main/src/hooks/useSlots.ts
 * permalink: https://github.com/primer/react/blob/40b29787ec4c8f709e7522147e58bd03e51b28e7/src/hooks/useSlots.ts
 */

/** @typedef {*} Props */

/**
 * 1. Component to match, example: { leadingVisual: LeadingVisual }
 * @typedef {React.ElementType<Props>} ComponentMatcher
 */

/**
 * 2. Component to match + a test function, example: { blockDescription: [Description, props => props.variant === 'block'] }
 * @typedef {[ComponentMatcher, function(Props): boolean]} ComponentAndPropsMatcher
 */

/**
 * @typedef {{string: ComponentMatcher | ComponentAndPropsMatcher}} SlotConfig
 */

/**
 * @typedef {{string: React.ReactNode | undefined}} SlotElements
 */


/**
 * Specify exactly where certain ElementTypes should be rendered within a component.
 * Extract them from React.children, and place them.
 *
 * @param {React.ReactNode} children
 * @param {SlotConfig} config
 * @returns {[SlotElements, React.ReactNode[]]} - [slots, rest]
 *
 * @example
 * import { useSlots } from './useSlots';
 *
 * function PageLayout({ children }) {
 *   const config = {
 *     header: Header,
 *     sidebar: Sidebar
 *   };
 *
 *   const [slots, rest] = useSlots(children, config);
 *
 *   return (
 *     <div className="page">
 *       <header>
 *         {slots.header}
 *       </header>
 *
 *       <main>
 *         {rest}
 *       </main>
 *
 *       <aside>
 *         {slots.sidebar}
 *       </aside>
 *     </div>
 *   );
 * }
 *
 * function App() {
 *   return (
 *     <PageLayout>
 *       <Header />
 *       <Content />
 *       <Sidebar />
 *       <Footer />
 *     </PageLayout>
 *   );
 * }
 */
function useSlots(children, config) {
  // Object mapping slot names to their elements
  const slots = mapValues(config, () => undefined);

  // Array of elements that are not slots
  const rest = [];

  const keys = Object.keys(config);
  const values = Object.values(config);

  React.Children.forEach(children, child => {
    if (!React.isValidElement(child)) {
      rest.push(child);
      return
    }

    const index = values.findIndex(value => {
      if (Array.isArray(value)) {
        const [component, testFn] = value;
        return child.type === component && testFn(child.props);
      } else {
        return child.type === value;
      }
    });

    // If the child is not a slot, add it to the `rest` array
    if (index === -1) {
      rest.push(child);
      return
    }

    const slotKey = keys[index]

    // If slot is already filled, ignore duplicates
    if (slots[slotKey]) {
      console.warn(true, `Found duplicate "${String(slotKey)}" slot. Only the first will be rendered.`)
      return
    }

    // If the child is a slot, add it to the `slots` object
    slots[slotKey] = child;
  });

  return [slots, rest]
}


export default useSlots;


/**
 * Map the values of an object, like array.map()
 */
function mapValues(obj, fn) {
  return Object.keys(obj).reduce(function (result, key) {
    result[key] = fn(obj[key]);
    return result;
  }, {});
}
