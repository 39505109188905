import { makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import React, { useMemo } from 'react';
import FormModal from 'src/app/components/FormModal';
import FiltersSection from 'src/app/slicedForm/FilterForm/elements/FiltersSection';
import FormActions, { FormCloseButton } from 'src/app/slicedForm/shared/elements/FormActions';
import FormHeader from 'src/app/slicedForm/shared/elements/FormHeader';
import ProfileNameField from 'src/app/slicedForm/shared/elements/ProfileNameField';
import ProfilesSection from 'src/app/slicedForm/shared/elements/ProfilesSection';
import AggregatesSection from '../../AggregateForm/AggregatesSection';
import { FILTER_FORM_TYPES } from '../../mapping/mappingDirections/index';
import { useFormSelector } from '../../shared/context/FormProvider';
import NotificationSnackbar from '../../shared/elements/NotificationSnackbar';
import { selectFormType } from '../reducers/filterReducer';


const useStyles = makeStyles(theme => ({
  root: {},
  panelHeaderColor: {
    backgroundColor: theme.palette.background.panelHeader
  },
  relative: {
    position: 'relative'
  },
  overflowHidden: {
    overflow: 'hidden'
  },
  scroll: {
    overflowY: 'auto',
    overflowX: 'auto'
  },
  filters: {
    minWidth: 640,
  },
  column: {
  },
  drawerCol: {
    flex: 1,
    borderRight: `1px solid ${theme.palette.background.panelBorder}`,
    height: '100%',
  },
  snackbarContainerPosition: {
    position: 'absolute',
    left: 50,
    bottom: 50,
  }
}));


function Form() {
  const classes = useStyles();
  const formType = useFormSelector(selectFormType); // profileToForm sets this

  const FormTypeComponent = useMemo(() => {
    if (formType === FILTER_FORM_TYPES.FILTER) {
      return FiltersSection;
    } else if (formType === FILTER_FORM_TYPES.AGGREGATE) {
      return AggregatesSection;
    }
    console.error('Unknown formType', formType);
  }, [formType])

  return (
    <>
      <FormModal.Actions
        className={classes.panelHeaderColor}
        borderBottom
      >
        <FormHeader
          className={classes.title}
          title={formType === FILTER_FORM_TYPES.FILTER ? 'Customize Filters' : 'Customize Ticker Stats'}
          titleWidth={260}
        >
          <ProfileNameField />
        </FormHeader>
        <FormCloseButton />
      </FormModal.Actions>

      <FormModal.Body>
        <FormModal.Drawer
          className={classes.panelHeaderColor}
          width={260}
          breakpoint={'md'}
          zIndex={3}
        >
          <FormModal.Column
            className={clsx(classes.panelHeaderColor, classes.drawerCol)}
          >
            <FormModal.Section
              title="Saved Profiles"
              expand
              scrollable
            >
              <ProfilesSection />

            </FormModal.Section>
          </FormModal.Column>
        </FormModal.Drawer>

        <FormModal.Column
          expand={true}
          className={clsx(
            classes.relative,
            classes.overflowHidden,
            // classes.scroll,
            // 'modal-horizontal-scrollbar-sm'
          )}
          borderLeft={false}
        >
          <FormModal.Section
            className={clsx(
              // classes.relative,
              classes.scroll,
              'modal-horizontal-scrollbar-sm'
            )}
          >
            <FormTypeComponent className={classes.filters} />
          </FormModal.Section>
        </FormModal.Column>

        <NotificationSnackbar />

      </FormModal.Body>
      <FormModal.Actions
        className={classes.panelHeaderColor}
        borderTop
      >
        <FormActions />
      </FormModal.Actions>
    </>
  );
}

export default Form;
