import React, { useContext } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import LayoutContext from '../LayoutContext';
import { LinkingIcon } from 'src/theme/EdgeIcons';
import { LINK_COLORS } from 'src/redux/layout/topListLayoutSchema';
import { COMPONENT_MAP } from 'src/app/TopListsMosaic/layout/components';
import SmallFilterWindow from 'src/app/components/filterContainers/SmallFilterWindow';
import {
  Typography,
  makeStyles,
  IconButton
} from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {},
  linkButton: {
    borderRadius: 0,
    padding: '5px 6px 5px 3px',
    textAlign: 'left',
    width: '100%',
    justifyContent: 'flex-start',
    alignContent: 'center',
    '& .MuiSvgIcon-root': {
      margin: 0,
    }
  },
  linkButtonLabel: {
    fontSize: 13,
    lineheight: 1,
    paddingLeft: 2,
    fontWeight: 500
  },
}));


function LinkingDropdownMenu({
  className,
  link,
  iconText,
  shouldHideIconText,
  onSelect
}) {
  const { componentType } = useContext(LayoutContext);
  const showNoLinkingOption = COMPONENT_MAP?.[componentType]?.noLinkAllowed;
  const classes = useStyles();

  return (
    <SmallFilterWindow
      className={clsx(className, classes.root)}
      Icon={LINK_COLORS?.[link]?.Icon || LinkingIcon}
      shouldRenderProps
      iconColor={LINK_COLORS?.[link]?.color || LINK_COLORS?.white?.color}
      shouldHideIconText={shouldHideIconText}
      iconText={iconText}
      noGutters
    >
      {(handleClose) => (
        <div>
          {showNoLinkingOption && (
            <div>
              <IconButton
                aria-label="Change component link color"
                disableRipple
                onClick={() => {
                  handleClose();
                  onSelect('none');
                }}
                className={classes.linkButton}
                style={{ color: LINK_COLORS.none.color }}
              >
                <LINK_COLORS.none.Icon/>
                <Typography variant="body2" color="textPrimary" className={classes.linkButtonLabel}>{LINK_COLORS.none.title}</Typography>
              </IconButton>
            </div>
          )}
          {Object.entries(LINK_COLORS).filter(([colorName]) => colorName !== 'none').map(([colorName, properties]) => (
            <div key={colorName}>
              <IconButton
                aria-label="Change component link color"
                disableRipple
                onClick={() => {
                  handleClose();
                  onSelect(colorName);
                }}
                className={classes.linkButton}
                style={{ color: properties.color }}
              >
                <properties.Icon/>
                <Typography variant="body2" color="textPrimary" className={classes.linkButtonLabel}>{properties.title}</Typography>
              </IconButton>
            </div>
          ))}
        </div>
      )}
    </SmallFilterWindow>
  );
}

LinkingDropdownMenu.propTypes = {
  className: PropTypes.string,
  iconText: PropTypes.string,
  shouldHideIconText: PropTypes.bool,
  showNoLinkOption: PropTypes.bool,
  link: PropTypes.string,
  onSelect: PropTypes.func
};


LinkingDropdownMenu.defaultProps = {
  iconText: 'Link component',
  shouldHideIconText: true,
  showNoLinkOption: false
}




export default LinkingDropdownMenu;

