import React from 'react';
import PropTypes from 'prop-types';
import ConditionalWrapper from 'src/app/components/utility/ConditionalWrapper';
import {
  Tooltip,
} from '@material-ui/core';




/**
 * Only render the tooltip label if provided
 */
function ConditionalTooltip({ label, children }) {
  return (
    <ConditionalWrapper
      condition={label}
      wrapper={tipChildren => (
        <Tooltip enterDelay={400} enterNextDelay={0} title={label} placement="top">
          {tipChildren}
        </Tooltip>
      )}
    >
      {children}
    </ConditionalWrapper>
  );

}


ConditionalTooltip.propTypes = {
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  children: PropTypes.node.isRequired,
}


export default ConditionalTooltip;
