/*
Wraps any axios instance with retry logic for handling Authentication tokens
*/

class AxiosAuthInstance {
  constructor(axios, tokenType, name) {
    this.axios = axios;
    this.tokenType = tokenType;
    this.name = name;
    this.failedRequestQueue = [];
    this.isRefreshingToken = false;
  }

  processPendingRequestQueue = (error, session) => {
    this.failedRequestQueue.forEach(prom => {
      if (error) {
        prom.reject(error);
      } else {
        prom.resolve(session[this.tokenType].getJwtToken());
      }
    });
    this.failedRequestQueue = [];
  };

  shouldIntercept = (error) => {
    try {
      return error.response.status === 401;
    } catch (e) {
      return false;
    }
  };

  setTokenData = (session) => {
    return session[this.tokenType].getJwtToken();
  };

  setAuthorizationHeader = (session) => {
    this.axios.defaults.headers.common = {
      Authorization: this.setTokenData(session),
    };
  };

  getAuthorizationHeader = () => {
    return this.axios.defaults.headers.common.Authorization
  }
}

export default AxiosAuthInstance;
