import React, { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import DatePicker from 'src/app/components/pickers/components/DatePicker';
import MaskedDateInput from 'src/app/components/pickers/components/MaskedDateInput';
import DefinedStaticDays from 'src/app/components/pickers/components/DefinedStaticDays';
import {
  Box,
  makeStyles,
} from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {},
  keyboardInput: {
    '& .MuiInputBase-input': {
      paddingLeft: 6
    }
  }
}));


/**
 * Displays:
 *  - Date picker calendar
 *  - Date picker keyboard input
 *  - (Optionally) Static day selectors
 *
 *  All work together in concert. Valid dates are sent to parent onChange.
 *
 * @component
 */
function InlineKeyboardDatePicker({
  className,
  date,
  onAccept,
  marketTime,
  placeholder,
  definedDays,
  makeStaticRanges,
  ...sharedProps
}) {
  const classes = useStyles();

  if (sharedProps.maxDate && sharedProps.disableFuture) throw Error('Cannot have both maxDate and disableFuture');

  const staticDayDefs = useMemo(() => {
    return makeStaticRanges && makeStaticRanges(sharedProps);
  }, [sharedProps.minDate, sharedProps.maxDate, sharedProps.disableFuture, sharedProps.disableHoliday, sharedProps.disabledDay]);

  return (
    <Box
      className={clsx(className, classes.root)}
      display="inline-flex"
      flexWrap="nowrap"
    >
      <DefinedStaticDays
        date={date}
        marketTime={marketTime}
        onClick={onAccept}
        definitions={staticDayDefs}
      />
      <Box
        display="inline-flex"
        flexDirection="column"
      >
        <MaskedDateInput
          className={classes.keyboardInput}
          date={date}
          marketTime={marketTime}
          placeholder={placeholder}
          onAccept={onAccept}
          acceptStrategy="onChange"
          {...sharedProps}
        />
        <DatePicker
          date={date}
          marketTime
          onAccept={onAccept}
          definedDays={definedDays}
          {...sharedProps}
        />
      </Box>
    </Box>
  );
}


InlineKeyboardDatePicker.propTypes = {
  className: PropTypes.string,
  /** date-fns Unicode date formatting */
  format: PropTypes.string.isRequired,
  /** Shown when input is empty */
  placeholder: PropTypes.string.isRequired,
  /** Partial control */
  date: PropTypes.instanceOf(Date).isRequired,
  /** How does the parent component update? */
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  /**
   * Convert the Date coming out of the picker to Market Time.
   * Assumes your min/max/disable dates are already converted to market time.
   */
  marketTime: PropTypes.bool.isRequired,
  disableHoliday: PropTypes.bool,
  disableWeekend: PropTypes.bool,
  disableFuture: PropTypes.bool,
  /** Allow exclusion of any date */
  disabledDay: PropTypes.func,
  /** Valid input has been accepted */
  onAccept: PropTypes.func.isRequired,
  /** Individual day selectors on the left of the calendar. See staticDayDefinitions.js */
  makeStaticRanges: PropTypes.func,
};


InlineKeyboardDatePicker.defaultProps = {
  marketTime: false,
  disableHoliday: false,
  disableWeekend: false,
  disableFuture: false,
};


export default React.memo(InlineKeyboardDatePicker);
