import React, { useMemo, useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _noop from 'lodash/noop';
import DatePicker from 'src/app/components/pickers/components/DatePicker';
import MaskedDateInput from 'src/app/components/pickers/components/MaskedDateInput';
import DefinedStaticDays from 'src/app/components/pickers/components/DefinedStaticDays';
import {
  Box,
  makeStyles,
} from '@material-ui/core';
import _uniqueId from 'lodash/uniqueId';
import IconAdornment from 'src/app/components/pickers/components/IconAdornment';
import Popover from '@material-ui/core/Popover';
import { Calendar6Icon } from 'src/theme/EdgeIcons';


const useStyles = makeStyles(() => ({
  keyboardInput: {},
  popover: {
    '& > div:first-of-type': {
      backgroundColor: ({ backgroundOpacity }) => backgroundOpacity ? `rgba(0, 0, 0, ${backgroundOpacity}) !important` : 'transparent'
    }
  }
}));


/**
 * Displays:
 *  - Keyboard input
 *  - Calendar icon, which opens:
 *    - Date picker calendar
 *    - (Optionally) Static day selectors
 *
 *  All work together in concert. Valid dates are sent to parent onChange.
 *
 * @component
 */
function PopoverKeyboardDatePicker({
  inputClassName,
  popoverClassName,
  date,
  onAccept,
  marketTime,
  placeholder,
  definedDays,
  makeStaticRanges,
  showErrorMessage,
  disableUnderline,
  inputVariant,
  disabled,
  backgroundOpacity,
  allowNull,
  ...sharedProps
}) {
  const classes = useStyles({ backgroundOpacity });
  const [id] = useState(() => _uniqueId('popover_'));
  const [anchorEl, setAnchorEl] = useState(null);

  const open = Boolean(anchorEl);
  const _id = open ? id : undefined;

  if (sharedProps.maxDate && sharedProps.disableFuture) throw Error('Cannot have both maxDate and disableFuture');


  const handleOpen = useCallback(event => setAnchorEl(event.currentTarget), []);

  const handleClose = useCallback(() => setAnchorEl(null), []);


  const staticDayDefs = useMemo(() => {
    return makeStaticRanges && makeStaticRanges(sharedProps);
  }, [sharedProps.minDate, sharedProps.maxDate, sharedProps.disableFuture, sharedProps.disableHoliday, sharedProps.disabledDay]);

  return (
    <>
      <MaskedDateInput
        className={clsx(inputClassName, classes.keyboardInput)}
        allowNull={allowNull}
        date={date}
        marketTime={marketTime}
        placeholder={placeholder}
        onAccept={onAccept}
        showErrorMessage={showErrorMessage}
        disableUnderline={disableUnderline}
        inputVariant={inputVariant}
        acceptStrategy="onBlur"
        endAdornment={<IconAdornment onClick={handleOpen} disabled={disabled} ><Calendar6Icon /></IconAdornment>}
        disabled={disabled}
        {...sharedProps}
      />
      <Popover
        id={_id}
        open={open}
        anchorEl={anchorEl}
        className={classes.popover}
        disabled={disabled}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
      }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        <Box
          className={clsx(popoverClassName)}
          display="inline-flex"
          flexWrap="nowrap"
        >
          <DefinedStaticDays
            date={date}
            marketTime={marketTime}
            onClick={onAccept}
            definitions={staticDayDefs}
          />
          <DatePicker
            date={date}
            marketTime
            onAccept={onAccept}
            definedDays={definedDays}
            {...sharedProps}
          />
        </Box>
      </Popover>
    </>
  );
}


PopoverKeyboardDatePicker.propTypes = {
  inputClassName: PropTypes.string,
  popoverClassName: PropTypes.string,
  /** date-fns Unicode date formatting */
  format: PropTypes.string.isRequired,
  /** Shown when input is empty */
  placeholder: PropTypes.string.isRequired,
  /** @see MaskedDateInput */
  inputVariant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  /** Show a shadow behind popover */
  backgroundOpacity: PropTypes.number,
  /** Partial control */
  date: PropTypes.instanceOf(Date),
  /** How does the parent component update? */
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  /**
   * Convert the Date coming out of the picker to Market Time.
   * Assumes your min/max/disable dates are already converted to market time.
   */
  marketTime: PropTypes.bool.isRequired,
  disableHoliday: PropTypes.bool,
  disableWeekend: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disabled: PropTypes.bool,
  /** @see MaskedDateInput */
  showErrorMessage: PropTypes.bool,
  /** @see MaskedDateInput */
  disableUnderline: PropTypes.bool,
  /** Allow exclusion of any date */
  disabledDay: PropTypes.func,
  /** Valid input has been accepted */
  onAccept: PropTypes.func.isRequired,
  /** Individual day selectors on the left of the calendar. See staticDayDefinitions.js */
  makeStaticRanges: PropTypes.func,
};


PopoverKeyboardDatePicker.defaultProps = {
  inputVariant: 'standard',
  backgroundOpacity: .3,
  marketTime: false,
  disabled: false,
  disableHoliday: false,
  disableWeekend: false,
  disableFuture: false,
  showErrorMessage: true,
  disableUnderline: false,
};


export default React.memo(PopoverKeyboardDatePicker);
