import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useRouteMatch } from 'react-router-dom';
import { removeTickerHistory, reorderTickerHistory, setTicker } from 'src/redux/ticker/tickerActions';
import TickerSearch from 'src/app/components/ticker/TickerSearch';
import TabsBarTabs from 'src/app/components/elements/TabsBarTabs';
import useTabsBarStyles from './useTabsBarStyles';
import { Menu as MenuIcon } from 'react-feather';
import {
  Box,
  Hidden,
  IconButton,
  SvgIcon
} from '@material-ui/core';

const emptyArray = [];

function GeneralTabsBar({ onMobileNavOpen, hideTickerSearch = false }) {
  const classes = useTabsBarStyles();
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const ticker = useSelector(state => state.ticker);
  const tickers = useSelector(state => state.account.user.tickerHistory || emptyArray);
  const selectedTicker = useSelector(state => state.account.user.selectedTicker);

  const handleTabClick = ticker => dispatch(setTicker(ticker, match.path));

  const handleTabRemove = ticker => dispatch(removeTickerHistory(ticker, tickers));

  const handleReorder = orderIds => {
    const orderedTickers = tickers.slice().sort((a, b) => orderIds.indexOf(a.symbol) - orderIds.indexOf(b.symbol));
    dispatch(reorderTickerHistory(orderedTickers));
  };

  const tabItems = tickers.map(item => {
    let companyName = null;
    if (item?.company?.companyName) { // TODO: this is backwards compatible support. Remove eventually.
      companyName = `- ${item.company.companyName}`;
    } else if (item?.companyName) {
      companyName = `- ${item.companyName}`;
    }

    return {
      symbol: item.symbol,
      title: `${item.symbol} ${companyName}`,
      selected: item.symbol === selectedTicker && !ticker.error
    }
  });


  return (
    <Hidden xsDown>
      <Box className={classes.root}>
        <Hidden smUp>
          <Box className={classes.buttonBox}>
            <IconButton
              className={classes.menuButton}
              onClick={onMobileNavOpen}
            >
              <SvgIcon fontSize="medium">
                <MenuIcon />
              </SvgIcon>
            </IconButton>
          </Box>
        </Hidden>
        {!hideTickerSearch && (<TickerSearch className={classes.tickerSearchFlexItem} />)}
        <TabsBarTabs
          className={classes.tabsFlexItem}
          itemIdKey="symbol"
          items={tabItems}
          onClick={handleTabClick}
          onRemove={handleTabRemove}
          onReorder={handleReorder}
        />
      </Box>
    </Hidden>
  );
}


export default GeneralTabsBar;
