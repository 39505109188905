import React, { useCallback, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _clamp from 'lodash/clamp';
import {
  Slider,
  makeStyles,
  Grid,
  Box,
} from '@material-ui/core';
import useStateFromProp from 'src/hooks/useStateFromProp';


const useStyles = makeStyles(theme => ({
  root: {},
  volumeCont: {
    display: 'flex',
    alignItems: 'center'
  },
  sliderEl: {
    '& .MuiSlider-valueLabel': {
      fontWeight: 'bold',
      fontSize: 13,
    },
    paddingTop: 15,
    paddingBottom: 12,
    '& .MuiSlider-thumb::after': {
      content: 'none'
    },
    '& .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover': {
      boxShadow: '0px 0px 0px 4px rgba(138, 133, 255, 0.16)'
    },
    '& .MuiSlider-thumb.MuiSlider-active': {
      boxShadow: '0px 0px 0px 10px rgba(138, 133, 255, 0.16)'
    },
  },

}));


const SCALE_MAP = [0, 30, 60, 120, 240, 480];

const indexToSeconds = (x) => SCALE_MAP[_clamp(x, 0, SCALE_MAP.length)];

const secondsToIndex = (x) => SCALE_MAP.indexOf(x) === -1 ? SCALE_MAP[0] : SCALE_MAP.indexOf(x);


const formatLabel = (seconds) => {
  if (seconds < 60) {
    return `${seconds}s`
  }
  const minutes = Math.floor(seconds / 60);
  const remainingSeconds = seconds % 60;

  if (remainingSeconds === 0) {
    return `${minutes}m`;
  } else {
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}m`;
  }
}


function CooldownSlider({
  className,
  disabled,
  value,
  onChange
}) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useStateFromProp(secondsToIndex(value));

  const handleChange = useCallback((_, newValue) => setInputValue(newValue), [])

  const handleChangeCommited = useCallback((_, newValue) => {
    const seconds = indexToSeconds(newValue);
    if (seconds === value) return;
    onChange(seconds);
  }, [onChange, value]);


  return (
    <div className={clsx(className, classes.sliderRoot)}>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Box pr={2} pl={1}>
            <Slider
              className={classes.sliderEl}
              disabled={disabled}
              value={inputValue}
              valueLabelDisplay="auto"
              marks
              step={1}
              min={0}
              max={5}
              scale={indexToSeconds}
              onChange={handleChange}
              onChangeCommitted={handleChangeCommited}
              valueLabelFormat={formatLabel}
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}


CooldownSlider.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.number,
  lastValue: PropTypes.number,
  onChange: PropTypes.func
};

export default CooldownSlider;
