
export const countryOptions = [
  { name: 'Afghanistan', label: 'Afghanistan' },
  { name: 'Albania', label: 'Albania' },
  { name: 'Algeria', label: 'Algeria' },
  { name: 'American Samoa', label: 'American Samoa' },
  { name: 'Andorra', label: 'Andorra' },
  { name: 'Angola', label: 'Angola' },
  { name: 'Anguilla', label: 'Anguilla' },
  { name: 'Antarctica', label: 'Antarctica' },
  { name: 'Antigua and Barbuda', label: 'Antigua and Barbuda' },
  { name: 'Argentina', label: 'Argentina' },
  { name: 'Armenia', label: 'Armenia' },
  { name: 'Aruba', label: 'Aruba' },
  { name: 'Australia', label: 'Australia' },
  { name: 'Austria', label: 'Austria' },
  { name: 'Azerbaijan', label: 'Azerbaijan' },
  { name: 'Bahamas', label: 'Bahamas' },
  { name: 'Bahrain', label: 'Bahrain' },
  { name: 'Bangladesh', label: 'Bangladesh' },
  { name: 'Barbados', label: 'Barbados' },
  { name: 'Belarus', label: 'Belarus' },
  { name: 'Belgium', label: 'Belgium' },
  { name: 'Belize', label: 'Belize' },
  { name: 'Benin', label: 'Benin' },
  { name: 'Bermuda', label: 'Bermuda' },
  { name: 'Bhutan', label: 'Bhutan' },
  { name: 'Bolivia', label: 'Bolivia' },
  { name: 'Bonaire', label: 'Bonaire' },
  { name: 'Bosnia and Herzegovina', label: 'Bosnia and Herzegovina' },
  { name: 'Botswana', label: 'Botswana' },
  { name: 'Bouvet Island', label: 'Bouvet Island' },
  { name: 'Brazil', label: 'Brazil' },
  { name: 'Brunei Darussalam', label: 'Brunei Darussalam' },
  { name: 'Bulgaria', label: 'Bulgaria' },
  { name: 'Burkina Faso', label: 'Burkina Faso' },
  { name: 'Burundi', label: 'Burundi' },
  { name: 'Cambodia', label: 'Cambodia' },
  { name: 'Cameroon', label: 'Cameroon' },
  { name: 'Canada', label: 'Canada' },
  { name: 'Cape Verde', label: 'Cape Verde' },
  { name: 'Cayman Islands', label: 'Cayman Islands' },
  { name: 'Central African Republic', label: 'Central African Republic' },
  { name: 'Chad', label: 'Chad' },
  { name: 'Chile', label: 'Chile' },
  { name: 'China', label: 'China' },
  { name: 'Christmas Island', label: 'Christmas Island' },
  { name: 'Cocos Islands', label: 'Cocos Islands' },
  { name: 'Colombia', label: 'Colombia' },
  { name: 'Comoros', label: 'Comoros' },
  { name: 'Congo', label: 'Congo' },
  { name: 'Congo Democratic Republic', label: 'Congo Democratic Republic' },
  { name: 'Cook Islands', label: 'Cook Islands' },
  { name: 'Costa Rica', label: 'Costa Rica' },
  { name: 'Country name', label: 'Country name' },
  { name: 'Croatia', label: 'Croatia' },
  { name: 'Cuba', label: 'Cuba' },
  { name: 'Curaçao', label: 'Curaçao' },
  { name: 'Cyprus', label: 'Cyprus' },
  { name: 'Czech Republic', label: 'Czech Republic' },
  { name: 'Côte d\'Ivoire', label: 'Côte d\'Ivoire' },
  { name: 'Denmark', label: 'Denmark' },
  { name: 'Djibouti', label: 'Djibouti' },
  { name: 'Dominica', label: 'Dominica' },
  { name: 'Dominican Republic', label: 'Dominican Republic' },
  { name: 'Ecuador', label: 'Ecuador' },
  { name: 'Egypt', label: 'Egypt' },
  { name: 'El Salvador', label: 'El Salvador' },
  { name: 'Equatorial Guinea', label: 'Equatorial Guinea' },
  { name: 'Eritrea', label: 'Eritrea' },
  { name: 'Estonia', label: 'Estonia' },
  { name: 'Ethiopia', label: 'Ethiopia' },
  { name: 'Falkland Islands', label: 'Falkland Islands' },
  { name: 'Faroe Islands', label: 'Faroe Islands' },
  { name: 'Fiji', label: 'Fiji' },
  { name: 'Finland', label: 'Finland' },
  { name: 'France', label: 'France' },
  { name: 'French Guiana', label: 'French Guiana' },
  { name: 'French Polynesia', label: 'French Polynesia' },
  { name: 'Gabon', label: 'Gabon' },
  { name: 'Gambia', label: 'Gambia' },
  { name: 'Georgia', label: 'Georgia' },
  { name: 'Germany', label: 'Germany' },
  { name: 'Ghana', label: 'Ghana' },
  { name: 'Gibraltar', label: 'Gibraltar' },
  { name: 'Greece', label: 'Greece' },
  { name: 'Greenland', label: 'Greenland' },
  { name: 'Grenada', label: 'Grenada' },
  { name: 'Guadeloupe', label: 'Guadeloupe' },
  { name: 'Guam', label: 'Guam' },
  { name: 'Guatemala', label: 'Guatemala' },
  { name: 'Guernsey', label: 'Guernsey' },
  { name: 'Guinea', label: 'Guinea' },
  { name: 'Guinea-Bissau', label: 'Guinea-Bissau' },
  { name: 'Guyana', label: 'Guyana' },
  { name: 'Haiti', label: 'Haiti' },
  { name: 'Heard Island and McDonald Islands', label: 'Heard Island and McDonald Islands' },
  { name: 'Vatican City', label: 'Vatican City' },
  { name: 'Honduras', label: 'Honduras' },
  { name: 'Hong Kong', label: 'Hong Kong' },
  { name: 'Hungary', label: 'Hungary' },
  { name: 'ISO 3166-2:GB', label: 'ISO 3166-2:GB' },
  { name: 'Iceland', label: 'Iceland' },
  { name: 'India', label: 'India' },
  { name: 'Indonesia', label: 'Indonesia' },
  { name: 'Iran', label: 'Iran' },
  { name: 'Iraq', label: 'Iraq' },
  { name: 'Ireland', label: 'Ireland' },
  { name: 'Isle of Man', label: 'Isle of Man' },
  { name: 'Israel', label: 'Israel' },
  { name: 'Italy', label: 'Italy' },
  { name: 'Jamaica', label: 'Jamaica' },
  { name: 'Japan', label: 'Japan' },
  { name: 'Jersey', label: 'Jersey' },
  { name: 'Jordan', label: 'Jordan' },
  { name: 'Kazakhstan', label: 'Kazakhstan' },
  { name: 'Kenya', label: 'Kenya' },
  { name: 'Kiribati', label: 'Kiribati' },
  { name: 'North Korea', label: 'North Korea' },
  { name: 'South Korea', label: 'South Korea' },
  { name: 'Kuwait', label: 'Kuwait' },
  { name: 'Kyrgyzstan', label: 'Kyrgyzstan' },
  { name: 'Lao People\'s Democratic Republic', label: 'Lao People\'s Democratic Republic' },
  { name: 'Latvia', label: 'Latvia' },
  { name: 'Lebanon', label: 'Lebanon' },
  { name: 'Lesotho', label: 'Lesotho' },
  { name: 'Liberia', label: 'Liberia' },
  { name: 'Libya', label: 'Libya' },
  { name: 'Liechtenstein', label: 'Liechtenstein' },
  { name: 'Lithuania', label: 'Lithuania' },
  { name: 'Luxembourg', label: 'Luxembourg' },
  { name: 'Macao', label: 'Macao' },
  { name: 'Macau', label: 'Macau' },
  { name: 'Macedonia', label: 'Macedonia' },
  { name: 'Madagascar', label: 'Madagascar' },
  { name: 'Malawi', label: 'Malawi' },
  { name: 'Malaysia', label: 'Malaysia' },
  { name: 'Maldives', label: 'Maldives' },
  { name: 'Mali', label: 'Mali' },
  { name: 'Malta', label: 'Malta' },
  { name: 'Marshall Islands', label: 'Marshall Islands' },
  { name: 'Martinique', label: 'Martinique' },
  { name: 'Mauritania', label: 'Mauritania' },
  { name: 'Mauritius', label: 'Mauritius' },
  { name: 'Mayotte', label: 'Mayotte' },
  { name: 'Mexico', label: 'Mexico' },
  { name: 'Micronesia', label: 'Micronesia' },
  { name: 'Moldova', label: 'Moldova' },
  { name: 'Monaco', label: 'Monaco' },
  { name: 'Mongolia', label: 'Mongolia' },
  { name: 'Montenegro', label: 'Montenegro' },
  { name: 'Montserrat', label: 'Montserrat' },
  { name: 'Morocco', label: 'Morocco' },
  { name: 'Mozambique', label: 'Mozambique' },
  { name: 'Myanmar', label: 'Myanmar' },
  { name: 'Namibia', label: 'Namibia' },
  { name: 'Nauru', label: 'Nauru' },
  { name: 'Nepal', label: 'Nepal' },
  { name: 'Netherlands', label: 'Netherlands' },
  { name: 'New Caledonia', label: 'New Caledonia' },
  { name: 'New Zealand', label: 'New Zealand' },
  { name: 'Nicaragua', label: 'Nicaragua' },
  { name: 'Niger', label: 'Niger' },
  { name: 'Nigeria', label: 'Nigeria' },
  { name: 'Niue', label: 'Niue' },
  { name: 'Norfolk Island', label: 'Norfolk Island' },
  { name: 'Northern Mariana Islands', label: 'Northern Mariana Islands' },
  { name: 'Norway', label: 'Norway' },
  { name: 'Oman', label: 'Oman' },
  { name: 'Pakistan', label: 'Pakistan' },
  { name: 'Palau', label: 'Palau' },
  { name: 'Palestine, State of', label: 'Palestine, State of' },
  { name: 'Panama', label: 'Panama' },
  { name: 'Papua New Guinea', label: 'Papua New Guinea' },
  { name: 'Paraguay', label: 'Paraguay' },
  { name: 'Peru', label: 'Peru' },
  { name: 'Philippines', label: 'Philippines' },
  { name: 'Pitcairn', label: 'Pitcairn' },
  { name: 'Poland', label: 'Poland' },
  { name: 'Portugal', label: 'Portugal' },
  { name: 'Puerto Rico', label: 'Puerto Rico' },
  { name: 'Qatar', label: 'Qatar' },
  { name: 'Romania', label: 'Romania' },
  { name: 'Russian Federation', label: 'Russian Federation' },
  { name: 'Rwanda', label: 'Rwanda' },
  { name: 'Réunion', label: 'Réunion' },
  { name: 'Saint Barthélemy', label: 'Saint Barthélemy' },
  { name: 'Saint Helena', label: 'Saint Helena' },
  { name: 'Saint Kitts and Nevis', label: 'Saint Kitts and Nevis' },
  { name: 'Saint Lucia', label: 'Saint Lucia' },
  { name: 'Saint Martin (French)', label: 'Saint Martin (French)' },
  { name: 'Saint Pierre and Miquelon', label: 'Saint Pierre and Miquelon' },
  { name: 'Saint Vincent and the Grenadines', label: 'Saint Vincent and the Grenadines' },
  { name: 'Saint Vincent And The Grenadines', label: 'Saint Vincent And The Grenadines' },
  { name: 'Samoa', label: 'Samoa' },
  { name: 'San Marino', label: 'San Marino' },
  { name: 'Sao Tome and Principe', label: 'Sao Tome and Principe' },
  { name: 'Saudi Arabia', label: 'Saudi Arabia' },
  { name: 'Senegal', label: 'Senegal' },
  { name: 'Serbia', label: 'Serbia' },
  { name: 'Seychelles', label: 'Seychelles' },
  { name: 'Sierra Leone', label: 'Sierra Leone' },
  { name: 'Singapore', label: 'Singapore' },
  { name: 'Sint Maarten (Dutch)', label: 'Sint Maarten (Dutch)' },
  { name: 'Slovakia', label: 'Slovakia' },
  { name: 'Slovenia', label: 'Slovenia' },
  { name: 'Solomon Islands', label: 'Solomon Islands' },
  { name: 'Somalia', label: 'Somalia' },
  { name: 'South Africa', label: 'South Africa' },
  { name: 'South Georgia and the South Sandwich Islands', label: 'South Georgia and the South Sandwich Islands' },
  { name: 'South Sudan', label: 'South Sudan' },
  { name: 'Spain', label: 'Spain' },
  { name: 'Sri Lanka', label: 'Sri Lanka' },
  { name: 'Sudan', label: 'Sudan' },
  { name: 'Suriname', label: 'Suriname' },
  { name: 'Svalbard and Jan Mayen', label: 'Svalbard and Jan Mayen' },
  { name: 'Swaziland', label: 'Swaziland' },
  { name: 'Sweden', label: 'Sweden' },
  { name: 'Switzerland', label: 'Switzerland' },
  { name: 'Syrian Arab Republic', label: 'Syrian Arab Republic' },
  { name: 'Taiwan', label: 'Taiwan' },
  { name: 'Tajikistan', label: 'Tajikistan' },
  { name: 'Tanzania', label: 'Tanzania' },
  { name: 'Thailand', label: 'Thailand' },
  { name: 'Timor-Leste', label: 'Timor-Leste' },
  { name: 'Togo', label: 'Togo' },
  { name: 'Tokelau', label: 'Tokelau' },
  { name: 'Tonga', label: 'Tonga' },
  { name: 'Trinidad and Tobago', label: 'Trinidad and Tobago' },
  { name: 'Tunisia', label: 'Tunisia' },
  { name: 'Turkey', label: 'Turkey' },
  { name: 'Turkmenistan', label: 'Turkmenistan' },
  { name: 'Turks and Caicos Islands', label: 'Turks and Caicos Islands' },
  { name: 'Tuvalu', label: 'Tuvalu' },
  { name: 'Uganda', label: 'Uganda' },
  { name: 'Ukraine', label: 'Ukraine' },
  { name: 'United Arab Emirates', label: 'United Arab Emirates' },
  { name: 'United Kingdom', label: 'United Kingdom' },
  { name: 'United States', label: 'United States' },
  { name: 'United States Minor Outlying Islands', label: 'United States Minor Outlying Islands' },
  { name: 'Uruguay', label: 'Uruguay' },
  { name: 'Uzbekistan', label: 'Uzbekistan' },
  { name: 'Vanuatu', label: 'Vanuatu' },
  { name: 'Venezuela', label: 'Venezuela' },
  { name: 'Viet Nam', label: 'Viet Nam' },
  { name: 'Virgin Islands, British', label: 'Virgin Islands, British' },
  { name: 'British Virgin Islands', label: 'British Virgin Islands' },
  { name: 'Virgin Islands, U.S.', label: 'Virgin Islands, U.S.' },
  { name: 'Wallis and Futuna', label: 'Wallis and Futuna' },
  { name: 'Western Sahara', label: 'Western Sahara' },
  { name: 'Yemen', label: 'Yemen' },
  { name: 'Zambia', label: 'Zambia' },
  { name: 'Zimbabwe', label: 'Zimbabwe' },
  { name: 'Åland Islands', label: 'Åland Islands' }
];

