import {
  Typography,
  InputBase,
  InputAdornment,
  alpha,
  TextField,
  lighten,
  makeStyles,
  useTheme
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import React, { useState, useCallback, useRef } from 'react';
import EntityDoubleConfirmationIconButton from '../FilterForm/elements/EntityDoubleConfirmationIconButton';
import { CheckIcon, EditIcon } from 'src/theme/EdgeIcons';
import useStateFromProp from 'src/hooks/useStateFromProp';



const useStyles = makeStyles(theme => ({
  root: {
    marginRight: 16,
    '&:hover': {
      '& $showHover': {
        display: 'flex'
      },
      '& $hideHover': {
        display: 'none'
      }
    },
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 23,
    '& h4': {
      height: '1.1876em',
      fontSize: 20,
      boxSizing: 'content-box',
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 6,
      margin: 0,
      fontWeight: 500,
      lineHeight: 1.235,
      letterSpacing: -0.05,
      borderRadius: 6,
    },
  },
  disabled: {
    opacity: 0.6
  },
  divider: {
    flex: 1,
    height: 3,
    backgroundColor: theme.palette.background.lightBorder,
    marginLeft: 23,
  },
  input: {
    // border: `none`,
    display: 'flex',
    backgroundColor: theme.palette.background.paperAlt_highlight,
    borderRadius: 4,
    '& .MuiInputAdornment-root': {
      height: '100%'
    },
    '& .MuiInputBase-input': {
      fontSize: 20,
      fontWeight: 500,
      lineHeight: 1.235,
      letterSpacing: -0.05,
      border: 'none',
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 6,
      color: theme.palette.text.primary
    },
    '&.Mui-disabled': {
      '& .MuiSvgIcon-root': {
        display: 'none',
      },
      '& .MuiInputBase-input': {
        color: alpha(theme.palette.text.primary, .6)
      }
    }
  },
  showHover: {
    display: 'none'
  },
  hideHover: {},
  deleteButton: {
    // height: '1.1876em',
    '& .MuiIconButton-root': {
      padding: '5px 6px'
    }
  }
}));



function AggregateGroupTitle({
  className,
  label,
  disabled,
  onChange,
  onDelete
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [deleteConfirming, setDeleteConfirming] = useState(false);
  const [newLabel, setNewLabel] = useStateFromProp(label);
  const inputRef = useRef(null);

  const handleChange = useCallback((event) => {
    setNewLabel(event.target.value)
  }, []);

  const handleBlur = useCallback((name) => {
    if (!name) {
      setNewLabel(label)
      return
    }
    if (name === label) return
    onChange(name);
  }, [onChange, label]);


  const handleFocus = useCallback(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  return (
    <div className={clsx(classes.root, className)} onClick={handleFocus}>
      <Typography variant="h4" className={clsx(classes.hideHover, disabled && classes.disabled)}>{newLabel}</Typography>
      <InputBase
        className={clsx(
          classes.input,
          classes.showHover
        )}
        placeholder={'Group Name'}
        disabled={disabled}
        value={newLabel}
        onChange={handleChange}
        onBlur={() => handleBlur(newLabel)}
        inputProps={{ 'aria-label': 'Edit group name' }}
        inputRef={inputRef}
        endAdornment={
          <InputAdornment position="end">
            <EditIcon style={{ fontSize: 18 }} />
          </InputAdornment>
        }
      />

      <div className={classes.divider} />

      <EntityDoubleConfirmationIconButton
        className={clsx(
          classes.showHover,
          classes.deleteButton
        )}
        confirming={deleteConfirming}
        BaseIcon={ClearIcon}
        baseLabel="Delete this group"
        baseColor={theme.palette.primary.main}
        ConfirmIcon={CheckIcon}
        confirmLabel={'Confirm delete?'}
        confirmColor={theme.palette.text.negative}
        disabled={disabled}
        setConfirming={setDeleteConfirming}
        onAccept={onDelete}
      />


    </div>
  )
}


export default AggregateGroupTitle;


