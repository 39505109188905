import React from 'react';
import clsx from 'clsx';
import { SSRSIcon } from 'src/theme/EdgeIcons';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    minWidth: 55,
    '& .ssr-icon': {
      color: theme.palette.background.ssr,
      marginLeft: 3
    }
  }
}));


function SSRIconCell({ className, iconWidth = 8, ssr, children }) {
  const classes = useStyles();

  return (
    <div className={clsx(className, classes.root)}>
      {(ssr) ? (
        <>
          <div>{children}</div>
          <div className={'ssr-icon'}><SSRSIcon width={iconWidth}/></div>
        </>
      ) : children
      }
    </div>
  );
}


export default SSRIconCell;
