
import React from "react";
import PropTypes from 'prop-types';
import Popover from 'src/app/slicedForm/FilterForm/elements/Popover';
import ColumnPickerForm, { useColumnPickerFormWidth } from './ColumnPickerForm';



/**
 * Adds a ColumnForm trigger to the first child, should be <Button> compatable.
 * Uses react.cloneElement
 *
 * If you need to customize further, just use those elements individually.
 **/
function WithColumnPickerForm({
  anchorPosition,
  maxWidth,
  columnDefs,
  onSelect,
  children
}) {
  const popoverWidth = useColumnPickerFormWidth();
  const { bindTrigger, popoverApi, popoverProps } = Popover.usePopover({
    anchorPosition: anchorPosition,
    maxWidth: maxWidth || popoverWidth
  });

  const child = React.Children.only(children);

  const EnhanceButton = React.cloneElement(child, {
    ...bindTrigger,
  })

  const handleSelect = (colDef) => {
    popoverApi.handleClose();
    onSelect(colDef);
  }

  return (
    <>
      {EnhanceButton}
      <Popover {...popoverProps}>
        <ColumnPickerForm onSelect={handleSelect} columnDefs={columnDefs} />
      </Popover>
    </>
  )
}


WithColumnPickerForm.propTypes = {
  anchorPosition: PropTypes.string,
  maxWidth: PropTypes.number,
  columnDefs: PropTypes.arrayOf(PropTypes.object),
  onSelect: PropTypes.func.isRequired,
  children: PropTypes.node.isRequired,
}


export default WithColumnPickerForm;
