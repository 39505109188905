import React from 'react';
import clsx from 'clsx';
import { MergeType } from '@material-ui/icons';
import MarketingModal from 'src/app/components/MarketingModal/elements/MarketingModal';
import MarketingModalCallout from 'src/app/components/MarketingModal/elements/MarketingModalCallout';
import { Functions as FunctionsIcon } from '@material-ui/icons';
import {
  Typography,
  List,
  ListItem,
  Box,
  ListItemText,
  ListItemIcon,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { CheckIcon } from 'src/theme/EdgeIcons';
import { permissions, PLAN_LEVEL_LABELS, usePlanLevel } from 'src/hooks/useUserPlanPermissions';
import MarketingModalErrorBoundary from './ErrorBoundaryMarketingModal';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  root: {},
}));



function MarketingModalExpressionsUpgrade(props) {
  return (
    <MarketingModalErrorBoundary {...props}>
      <ModalContent {...props} />
    </MarketingModalErrorBoundary>
  )
}



function ModalContent({
  className,
  suggestedPlanLevel,
  onClose,
}) {
  const theme = useTheme();
  const currentPlanLevel = usePlanLevel();
  const classes = useStyles();
  const planLevelLabel = PLAN_LEVEL_LABELS[suggestedPlanLevel];
  const currentPlanLevelLabel = PLAN_LEVEL_LABELS[currentPlanLevel];

  const title = (
    <Typography variant="h2">
      <span style={{ marginRight: 10 }}>🚀</span>
      Upgrade to <span style={{ color: theme.palette.text.planTag }}>{planLevelLabel}</span></Typography>
  )

  const limit = permissions?.[currentPlanLevel]?.max_expressions;
  const nextLimit = permissions?.[suggestedPlanLevel]?.max_expressions;

  return (
    <MarketingModal
      className={clsx(classes.root, className)}
      suggestedPlanLevel={planLevelLabel}
      titleContent={title}
      onClose={onClose}
    >
      <Box pt={2} pb={2}>
        <Typography variant="body1" color="textSecondary" >
          Your current <span style={{ color: theme.palette.text.planTag }}>{currentPlanLevelLabel}</span> plan is limited to {limit} {limit === 1 ? 'Expression' : 'Expressions'}.
        </Typography>
      </Box>

      <MarketingModalCallout Icon={FunctionsIcon}>
        <Typography variant="h3">Create up to {nextLimit} Expressions</Typography>
        <Typography variant="body1">Define your own metrics to be used inside Filter, Column, and Stats profiles</Typography>
      </MarketingModalCallout>

      <Box pt={2}>
        <Typography variant="body1" color="textSecondary">
          Additional {planLevelLabel} Features:
        </Typography>
      </Box>

      <Box pt={2} pb={1}>
        <List>
          <ListItem>
            <ListItemIcon className="success"><CheckIcon /></ListItemIcon>
            <ListItemText primary={`${permissions?.[suggestedPlanLevel]?.history_max_years} Years of Historical Data`} />
          </ListItem>
          <ListItem>
            <ListItemIcon className="success"><CheckIcon /></ListItemIcon>
            <ListItemText primary="Customizable Ticker Stats Profiles" />
          </ListItem>
          <ListItem>
            <ListItemIcon className="success"><CheckIcon /></ListItemIcon>
            <ListItemText primary={`Unlimited Scanner, Chart, and Watchlist Components`} />
          </ListItem>
        </List>
      </Box>

    </MarketingModal>
  )
}


MarketingModalExpressionsUpgrade.propTypes = {
  className: PropTypes.string,
  suggestedPlanLevel: PropTypes.string,
  onClose: PropTypes.func,
}


export default MarketingModalExpressionsUpgrade;
