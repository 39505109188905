import React, { useEffect, useState } from 'react';
import { useTickerIdParams } from 'src/hooks/useTickerIdParams';
import clsx from 'clsx';
import BarChart from './BarChart';
import {
  Box,
  Typography,
  makeStyles
} from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {},
  label: {
    paddingTop: 12,
    paddingBottom: 12,
  },
  chartWrap: {
    position: 'relative',
    '& .apexcharts-xaxis-label': {
      cursor: 'pointer',
      '&:hover': {
        opacity: '.7'
      }
    }
  },
  chartRefreshButton: {
    position: 'absolute',
    right: 10,
    bottom: 20
  },
  refreshButton: {
    paddingRight: 13,
    paddingTop: 15,
    cursor: 'pointer',
    '&:hover': {
      textDecoration: 'underline'
    }
  }
}));



function GapStatsBarChart({ className, timeSeriesData, operations, height, maxSize = 8 }) {
  const classes = useStyles();
  const tickerId = useTickerIdParams();
  const [mappedSeries, setMappedSeries] = useState([]);
  const [mappedTime, setMappedTime] = useState([]);
  const [excludedDates, setExcludedDates] = useState([]);


  useEffect(() => {
    setMappedSeries([]);
    setMappedTime([]);
  }, [tickerId]);


  useEffect(() => {

    if (!timeSeriesData || !timeSeriesData.length) {
      setMappedSeries([]);
      setMappedTime([]);
      return;
    }
    let dateOut = [];
    const seriesOut = operations.map(cfg => {
      const data = timeSeriesData.reduce((result, dataP) => {
        const calculatedData = cfg.operation(dataP);
        if (!isNaN(calculatedData) && !excludedDates.includes(dataP.day0_date)) {
          result.push(calculatedData);
          dateOut.push(dataP.day0_date);
        }
        return result;
      }, []);

      return {
        name: cfg.name,
        data: data.slice(0, maxSize)
      };
    });

    setMappedSeries(seriesOut);
    setMappedTime(dateOut.slice(0, seriesOut[0].data.length));

  }, [tickerId, timeSeriesData, excludedDates]);


  const onChartLabelClick = (date) => {
    setExcludedDates([...excludedDates, date]);
  };

  const renderChartRefresh = () => {
    const visibility = (excludedDates.length > 0) ? 'visible' : 'hidden';
    return (
      <Box className={classes.chartRefreshButton}>
        <a onClick={() => setExcludedDates([])} style={{ visibility }}>
          <Typography variant="body2" color="primary" className={classes.refreshButton}>Refresh Chart</Typography>
        </a>
      </Box>
    );
  };

  return (
    <Box className={clsx(classes.root, className)}>
      <Box className={classes.chartWrap}>
        <BarChart series={mappedSeries} time={mappedTime} height={height} labelClickCallback={onChartLabelClick}/>
        {renderChartRefresh()}
      </Box>
    </Box>
  );
}

export default GapStatsBarChart;

