import TagManager from 'react-gtm-module'

export class TagManagerService {

  static initialize(params = {}) {
    TagManager.initialize(params);
  }

  static push(params = {}) {
    TagManager.dataLayer({
      dataLayer: params
    })
  }
}