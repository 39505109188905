import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Typography,
  makeStyles
} from '@material-ui/core';
import clsx from "clsx";

const useStyles = makeStyles(theme => ({
  root: {
    borderTop: props => props.borderTop ? `1px solid ${theme.palette.background.panelBorder}` : 'none',
    borderBottom: props => props.borderBottom ? `1px solid ${theme.palette.background.panelBorder}` : 'none',
    '&.-expand': {
      flex: 1,
      minHeight: 0,
    },
    '&.-scrollable': {
      overflowY: 'auto',
      height: '100%',
    }
  },
  title: {
    '& h3': {
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      fontSize: theme.fontSize.title
    }
  },
  sectionWrapper: {
    flexDirection: 'column',
    display: 'flex',
    flexBasis: 'auto',
    flexGrow: 0,
    flexShrink: props => props.flexShrink,
    minHeight: 0,
    '&.--expand': {
      flexGrow: 1,
    },
    borderTop: props => props.borderTop ? `1px solid ${theme.palette.background.panelBorder}` : 'none',
    borderBottom: props => props.borderBottom ? `1px solid ${theme.palette.background.panelBorder}` : 'none',
  },
  sectionFlexCont: {
    minHeight: 0,
    display: 'flex',
    flexDirection: 'column',
    flex: 1,
  },
  contentWrapper: {
    flex: 1,
    minHeight: 0
  },
  sectionContent: {
    height: '100%',
    '&.--scrollable': {
      overflowY: 'auto',
      minHeight: 0,
    },

  },
}));

/**
 * Controls horizontal spacing of form elements
* @param {bool} expand - If true, flexGrow: 1 will be set, causing this column to fill avaialble space
* @param {bool} flexShrink - If you set this larger than 1, this Section will shrink faster than its siblings
*
* @example
* >> Make top section fill available space, while making sure both sections shrink when needed. Bottom column will shrink *more* than top.
* <div>
*   <Section expand scrollable />
*   <Section scrollable flexShrink={2}/>
* </div>
 **/
function Section({
  className,
  contentClassName,
  scrollable,
  expand,
  flexShrink,
  borderTop,
  borderBottom,
  title,
  children,
}) {
  const classes = useStyles({ borderTop, borderBottom, flexShrink });

  return (
    <div className={clsx(
      classes.sectionWrapper,
      className,
      expand && '--expand'
    )}>
      <div className={classes.sectionFlexCont}>
        {Boolean(title) && (
          <Box pt={1} pl={1} className={classes.title}>
            {React.isValidElement(title)
              ? title
              : <Typography variant="h3">{title}</Typography>
            }
          </Box>
        )}
        <div className={classes.contentWrapper}>
          <div
            className={clsx(
              classes.sectionContent,
              contentClassName,
              scrollable && ['--scrollable', 'modal-horizontal-scrollbar-sm']
            )}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  )
}



Section.propTypes = {
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  scrollable: PropTypes.bool,
  expand: PropTypes.bool,
  flexShrink: PropTypes.number,
  borderTop: PropTypes.bool,
  borderBottom: PropTypes.bool,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node,
}


Section.defaultProps = {
  flexShrink: 1
}

// export default Section;
export default Section;
