import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _uniqueId from 'lodash/uniqueId';
import useKeyboardMenuNavigation from 'src/app/components/SearchableMultiSelect/useKeyboardMenuNavigation';
import { DenyLargeIcon } from 'src/theme/EdgeIcons';
import { createFilterOptions } from '@material-ui/lab/Autocomplete';
import {
  makeStyles,
  IconButton,
  Button,
  Popover,
  TextField,
  Checkbox,
  Chip,
  Box,
  Typography,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {},
  listBox: {
    margin: 0,
    padding: [[0, 0]],
    overflowY: 'auto',
    listStyle: 'none',
    maxHeight: '34vh',
    minWidth: 200
  },
  clearButton: {
    padding: 0,
    borderRadius: 0,
    opacity: .7,
    height: '100%',
    '&:focus-visible': {
      ...theme.focus.outline
    },
    '&:hover': {
      opacity: 1
    },
    '& .MuiSvgIcon-root': {
      marginBottom: 0,
      fontSize: 22,
      color: theme.palette.text.primary,
    }
  },
  noResults: {
    padding: [[5, 0]],
    margin: 0,
    textAlign: 'center',
  },
  item: {
    fontSize: 14,
    display: 'flex',
    alignItems: 'center',
    '&[data-focus=true]': {
      backgroundColor: 'rgba(255,255,255, .1)',
    }
  },
  disabled: {
    cursor: 'not-allowed',
    '& > *': {
      pointerEvents: 'none',
    }
  },
  elipsedInput: {
    // overflowX: 'hidde',
    '& span.tagstring': {
      display: 'block',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      maxWidth: '100%',
    }
  },
  placeholder: {
    opacity: .6,
    paddingLeft: 8
  }
}));


/**
 * @typedef {Object} MultiSelectOption
 * @property {string|number} name
 * @property {string} label
 */


/**
 * Sorts the options so the selected ones appear first in the array. Non-mutable.
 *
 * @param {MultiSelectOption[]} options
 * @param {string|number[]} selected
 * @returns {MultiSelectOption[]}
 */
const sortOptionsBySelected = (options, selected) => {
  if (!selected || !selected.length || !options || !options.length) {
    return options;
  }

  const sortedOptions = [...options];

  sortedOptions.sort((a, b) => {
    const aSelected = selected.includes(a.name);
    const bSelected = selected.includes(b.name);

    if (aSelected && bSelected) {
      // If both a and b are selected, maintain their relative order
      return selected.indexOf(a.name) - selected.indexOf(b.name);
    } else if (aSelected) {
      // If only a is selected, it should come before b
      return -1;
    } else if (bSelected) {
      // If only b is selected, it should come after a
      return 1;
    } else {
      // If neither a nor b is selected, maintain their original order
      return 0;
    }
  });

  return sortedOptions;
};


/**
 * Sort options if applicable
 * @param {MultiSelectOption[]} options
 * @param {string|number[]} value
 * @param {boolean} groupSelected
 * @return {MultiSelectOption[]}
 */
const initOptions = (options, value, groupSelected) => {
  return groupSelected
    ? sortOptionsBySelected(options, value)
    : options;
};


/**
 * Use material-ui's filtering logic. Handles localization
 * @type {function(MultiSelectOption[], Object)}
 */
const filterOptions = createFilterOptions({
  stringify: (option) => option.label,
});


function SearchableMultiSelect({
  buttonClassName,
  menuClassName,
  inputClassName,
  ButtonProps,
  InputProps,
  options,
  values,
  limitTags,
  placeholder,
  noResultsText,
  groupSelected,
  disabled,
  innerRef,
  onChange,
}) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);
  const [orderedOptions, setOrderedOptions] = useState(() => initOptions(options, values, groupSelected));
  const [_id] = useState(() => _uniqueId('smi-'));
  /** The search query */
  const [inputValue, setInputValue] = useState('');

  const open = Boolean(anchorEl);
  const id = open ? _id : undefined;

  const filteredOptions = open
    ? filterOptions(orderedOptions, { inputValue })
    : [];

  const {
    resetHighlighted,
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps
  } = useKeyboardMenuNavigation(
    _id,
    filteredOptions,
    !open,
    {
      onEnter: (item) => open && handleSelectItem(item.name),
      onEscape: () => handleClose(),
      initialIndex: 0,
    }
  );

  useEffect(() => {
    resetHighlighted();
  }, [inputValue]);

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
    setOrderedOptions(initOptions(options, values, groupSelected));
  };

  const handleInputValueChange = (event) => {
    setInputValue(event.target.value);
  };

  const handleSelectItem = (name) => {
    const newValues = values.includes(name)
      ? values.filter(value => value !== name)
      : [...values, name];
    onChange(newValues);
  };


  /**
   * Display all selected options when hovered over button if some are hidden by limitTags
   * @return {string|undefined}
   */
  const getButtonTitle = () => {
    if (!values || !values.length) return;
    const string = values.map(name => options.find(option => option.name === name)?.label).filter(Boolean).join(', ');
    if (string) return string;
  };


  /**
   * Render the (Label) +N tags
   * @return {Element}
   */
  const renderTags = () => {
    const limitedValues = limitTags > -1
      ? values.slice(0, limitTags)
      : values;

    const hiddenCount = limitTags > -1
      ? values.length - limitedValues.length
      : 0;

    const taggableOptions = limitedValues
      .map(name => options.find(option => option.name === name))
      .filter(Boolean);

    return (
      <Box display="flex" alignItems="center">
        {taggableOptions.map(opt => (
          <Chip
            className={classes.chip}
            key={opt?.name}
            label={opt?.label}
          />
        ))}
        {Boolean(hiddenCount) && <span>+{hiddenCount}</span>}
      </Box>
    );
  };


  return (
    <>
      <Button
        {...ButtonProps}
        component={'a'}
        className={clsx(
          classes.button,
          buttonClassName,
          { [classes.elipsedInput]: limitTags === -1 }
        )}
        aria-describedby={id}
        type="button"
        disabled={disabled}
        onClick={handleOpen}
        title={getButtonTitle()}
        ref={innerRef}
      >
        {values.length
          ? (
            <>
              {limitTags === -1
                ? <span className="tagstring">{getButtonTitle()}</span>
                : renderTags()
              }
              <IconButton
                className={classes.clearButton}
                aria-label="Clear all"
                disableRipple
                tabIndex={disabled ? -1 : 0}
                onClick={(e) => {
                  e.stopPropagation();
                  onChange([]);
                }}
              >
                <DenyLargeIcon />
              </IconButton>
            </>
          ) : <span className={classes.placeholder}>{placeholder}</span>
        }
      </Button>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        style={{ zIndex: 9999 }}
      >
        <div
          className={menuClassName}
          {...getRootProps()}
        >
          <div className={classes.listHeader}>
            <TextField
              inputProps={{
                spellCheck: false,
                autoComplete: 'off',
                autoCapitalize: 'none'
              }}
              InputProps={InputProps}
              autoFocus
              className={clsx(inputClassName)}
              placeholder="Search..."
              value={inputValue}
              onChange={handleInputValueChange}
              {...getInputProps()}
            />
          </div>
          <ul
            className={classes.listBox}
            {...getListboxProps()}
          >
            {filteredOptions.length
              ? (

                filteredOptions.reduce((acc, curr, index) => {
                  const selected = values.includes(curr.name);
                  if (curr?.depricated && !selected) return acc;

                  return [
                    ...acc,
                    (<li
                      className={classes.item}
                      key={curr?.name}
                      role="button"
                      onClick={() => handleSelectItem(curr?.name)}
                      {...getOptionProps(index, { selected: curr })}
                    >
                      <Checkbox disableRipple checked={selected} />
                      <Typography>
                        {curr?.label}
                      </Typography>
                    </li>
                    )
                  ]
                }, [])
              )
              : <Typography className={classes.noResults}>{noResultsText}</Typography>

            }
          </ul>
        </div>
      </Popover>
    </>
  );
}


SearchableMultiSelect.defaultProps = {
  options: [],
  selected: [],
  placeholder: 'Search...',
  limitTags: -1,
  noResultsText: 'No results',
  groupSelected: true
};


SearchableMultiSelect.propTypes = {
  buttonClassName: PropTypes.string,
  menuClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  ButtonProps: PropTypes.object,
  InputProps: PropTypes.object,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string.isRequired,
    name: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    depricated: PropTypes.bool
  })).isRequired,
  values: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.string, PropTypes.number])).isRequired,
  placeholder: PropTypes.string,
  noResultsText: PropTypes.string,
  limitTags: PropTypes.number,
  groupSelected: PropTypes.bool,
  disabled: PropTypes.bool,
  onChange: PropTypes.func.isRequired
};


export default SearchableMultiSelect;
