import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles,
  Typography,
} from '@material-ui/core';
import clsx from 'clsx'

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: 0,
    minWidth: props => props.minWidth,
    maxWidth: props => props.maxWidth,
    flex: props => props.expand ? 1 : 'unset',
    borderLeft: props => props.borderLeft ? `1px solid ${theme.palette.background.panelBorder}` : 'none',
    borderRight: props => props.borderRight ? `1px solid ${theme.palette.background.panelBorder}` : 'none',
  },
  title: {
    backgroundColor: theme.palette.background.fixedTableHeader,
    '& h2': {
      fontWeight: 'bold',
      margin: 0,
      padding: 0,
      fontSize: theme.fontSize.title
    }
  }
}));


function Column({
  className,
  children,
  title,
  borderLeft,
  borderRight,
  minWidth,
  maxWidth,
  expand,
  style,
  ...rest
}) {
  const classes = useStyles({ minWidth, maxWidth, expand, borderLeft, borderRight });

  return (
    <div
      className={clsx(
        className,
        classes.root,
        'FormModal-Column'
      )}
      style={style}
      {...rest}
    >
      {Boolean(title) && (
        <Box p={1} className={classes.title}>
          {React.isValidElement(title)
            ? title
            : <Typography variant="h2">{title}</Typography>
          }
        </Box>
      )}
      {children}
    </div>
  );
}


Column.propTypes = {
  className: PropTypes.string,
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  borderLeft: PropTypes.bool,
  borderRight: PropTypes.bool,
  expand: PropTypes.bool,
  /** For react-spring use */
  style: PropTypes.object
};


Column.defaultProps = {
  style: {}
}


export default Column;

