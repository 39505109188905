import {
  format,
  utcToZonedTime,
  toDate
} from 'date-fns-tz';
import {
  parse,
  isWeekend,
  isWithinInterval,
  max,
  subDays,
  addDays,
  subYears,
  getMinutes,
  getHours,
  startOfDay,
  isValid,
  set
} from 'date-fns';
import enUS from 'date-fns/locale/en-US';
import { MARKET_SESSION_CFG, MARKET_SESSION_KEYS } from 'src/utils/datetime/definitions/marketHours';
import tradingHolidays from 'src/constants/tradingHolidays';


/**
 * HOW THIS LIBRARY WORKS
 *
 * You can never get a Date object that is both
 *  a) In a different timezone other than local
 *  b) Has the correct UNIX timestamp
 *
 *  If you convert a Date toMarketTime(), then the resulting object will format() properly, like its in Market Time.
 *  However, calling getUnixTime() on that object will give you THE WRONG UNIX TIME.
 *
 *  As a result, there's essentially two types of dates that the programmer has to keep track of.
 *
 *  1) Dates that have been converted to market time.
 *    These are needed to compare dates/times/holidays, addBusinessDays(), etc.
 *    However, if you need to pull out UNIX, you have to convert it back to local using marketTimeToCorrectUnix()
 *  2) Dates that remain in local
 *    These are for DatePickers basically, anywhere the User is inputting time-agnostic data.
 *    You can't use them to make comparisons with Market Time, but for simple formatting or querying the database, it works fine.
 *
 *  You the programmer have to make sure you're applying the right transformations. Some functions below expect a
 *  MarketTime date. Some expect local.
 *
 *  @todo I would love to make this better, but its broken me. The only idea I have is to make a Proxy wrapper for Date(),
 *    one for LocalDate() and one for MarketDate(). But that brings up a whole host of issues.
 */


export const timeZone = 'America/New_York';
export const locale = enUS;

/**************************************************************************************
  JSDOC TYPES
**************************************************************************************/

/**
 * @typedef {integer} UnixSeconds
 *
 * @typedef {integer} UnixMilliseconds
 * */

/**************************************************************************************
  GENERAL UTILITIES
**************************************************************************************/


/**
 * Take in a UTC date (unix timestamp, or new Date()) and return what it would be America/New_York.
 * Changes the times!
 * Use when we need to compare the following to Market specific times:
 *  - Past timestamp (eg. barTime)
 *  - User's current time (marketSession)
 *
 * NOTE: Localizing the same date twice will break it. You have to keep track of this
 * yourself, there's no way of checking whether a date has been localized.
 *
 * Cases:
 *  - What session was this BarTime in?
 *  - Is it currently premarket?
 *  - Is it currently a holiday / is this Chart date on a holiday?
 *
 *  Resulting date WILL HAVE WRONG UNIX STAMP
 *
 *  @example
 *  // local timezone = CST (+2)
 *  const date = toMarketTime(new Date('2023-09-22T10:00:00'));
 *  console.log(format(what, 'yyyy-MM-dd HH:mm:ss z', { timeZone, locale }));
 *  // >>> '2023-09-22 04:00:00 EDT' (The time changed, CST is +6 after EDT)
 *
 *  @param {Date} date - Date
 *  @returns {Date} - localized to America/New_York
 */
export const toMarketTime = (date) => {
  return utcToZonedTime(date, timeZone, { locale });
}


/**
 *
 * @param {UnixMilliseconds} milliseconds
 * @return {Date}
 */
export const parseUnixMilliseconds = (milliseconds) => {
  return parse(milliseconds, 'T', new Date());
}


/**
 *
 * @param {UnixSeconds} seconds
 * @return {Date}
 */
export const parseUnixSeconds = (seconds) => {
  return parse(seconds, 't', new Date());
}


/**
 * Take in a datetime, and reset it timestamp WITHOUT changing the formatted dates/times!
 *
 * This changes the underlying UNIX timestamp! You cannot rely on it.
 *
 * Use this if you only care about Date and Time stamp comparisons. Not actual Unix time.
 * @example
 * // local timezone = CST (+2)
 * const date = assumeMarketTime(new Date('2023-09-22T10:00:00'));
 * console.log(format(what, 'yyyy-MM-dd HH:mm:ss z', { timeZone, locale }));
 * // >>> '2023-09-22 10:00:00 EDT' (The time did not change)
 * @param {Date} date
 * @returns {Date} - reset to America/New_York time
 */
export const assumeMarketTime = (date) => {
  return toDate(date, { timeZone, locale })
}


/**
 * Returns the current Date, localized to America/New_York
 *
 * Resulting date WILL HAVE WRONG UNIX STAMP
 *
 * @return {Date}
 */
export const getCurrentTradingDay = () => {
  return toMarketTime(new Date());
}


/**
 * [LOCALIZATION REQUIRED]
 *
 * The underlying UNIX timestamp of a date converted to market time via
 * assumeMarketTime or toMarketTime will be wrong.
 *
 * Use this to get a vanilla Date object that has correct Unix time. Of course its timezone
 * will be wrong. Don't use it for formatting, just for pulling Unix Seconds/Milliseconds out.
 * @param {Date} date
 * @return {Date}
 */
export const marketTimeToCorrectUnix = (date) => {
  const fmt = "yyyy-MM-dd'T'HH:mm:ss.sssXXX";
  const stamp = format(date, fmt, { locale, timeZone });
  return new Date(stamp);
}


/**
 * From an agnositc stamp '2023-09-26 00:00:00', get the Unix time at that time in America/New_York
 * @param {string} dateString
 * @param {string} [fmt='yyyy-MM-dd HH:mm:ss']
 * @returns {Date}
 */
export const parseAgnosticStampToMarketUnix = (dateString, fmt = 'yyyy-MM-dd HH:mm:ss') => {
  const assumed = parseAssumeMarketTime(dateString, fmt);
  return marketTimeToCorrectUnix(assumed);
}


/**
 * Quick fix for comparing 3 dates, only caring about the Days. Ignores time.
 * Inclusive.
 * @todo Make a full version like Moment.js, with 'resolution' and 'inclusivity' params.
 * @param {Date} date
 * @param {Date} start
 * @param {Date} end
 * @returns {boolean}
 */
export const isBetweenDaysInclusive = (date, start, end) => {
  const dateSort = parseInt(format(date, 'yyyyMMdd'));
  const startSort = parseInt(format(start, 'yyyyMMdd'));
  const endSort = parseInt(format(end, 'yyyyMMdd'));
  return (dateSort >= startSort && dateSort <= endSort); // inclusive
}


/**************************************************************************************
  MIGRATION HELPERS

 These aren't that helpful, but I want to extract functions to make migration easier
**************************************************************************************/


/**
 * Returns the current date string in America/New_York time
 * @param {string} [fmt='yyyy-MM-dd'] - Foramt to return
 * @return {string}
 */
export const getCurrentMarketTimeString = (fmt = 'yyyy-MM-dd') => {
  return format(getCurrentTradingDay(), fmt, { locale, timeZone });
}

/**
 * Returns the current date string in local time, no America/New_York conversion
 * @param {string} [fmt='yyyy-MM-dd'] - Foramt to return
 * @return {string}
 */
export const getCurrentLocalTimeString = (fmt = 'yyyy-MM-dd') => {
  return format(new Date(), fmt, { locale });
}


/**
 * [USE WHEN DATESTRING HAS UTC IDENTIFIER (e.g. -400, EST, z, something...)]
 *
 * Parses the UTC string into a MarketTime object which can be formatted
 * If you don't have a UTC identifier, things might break. I don't know what happens if it doesn't.
 *
 * Resulting date WILL HAVE WRONG UNIX STAMP
 *
 * @param {string} dateString
 * @param {string} fmt
 * @return {Date}
 */
export const parseToMarketTime = (dateString, fmt) => {
  return toMarketTime(parse(dateString, fmt, new Date()));
}


/**
 * [USE WHEN DATESTRING DOES NOT HAVE UTC IDENTIFIER]
 *
 * Parses an agnostic datetime string into a MarketTime Date which can be formatted.
 * Other version of parseToMarketTime(), to be used when the timesamp has no UTC identifiers
 *
 * Resulting date WILL HAVE WRONG UNIX STAMP
 * @param {string} dateString
 * @param {string} [fmt='yyyy-MM-dd']
 * @return {Date}
 */
export const parseAssumeMarketTime = (dateString, fmt = 'yyyy-MM-dd') => {
  return assumeMarketTime(parse(dateString, fmt, new Date()));
}

/**
 * [LOCALIZATION REQUIRED]
 *
 * Format a date object into a string, localized to America/New_York
 * The date should have been previously parsed with toMarketTime (utcToZonedTime())
 * @param {Date} date
 * @param {string} [fmt='yyyy-MM-dd HH:mm:ss']
 * @return {string}
 */
export const formatMarketTime = (date, fmt = 'yyyy-MM-dd HH:mm:ss') => {
  return format(date, fmt, { locale, timeZone });
}


/**
 * [NO LOCALIZATION!]
 *
 * Format a date object into a string. Timezone remains unchanged, but with American "localee"
 * Its basically just date-fns.parse() with locale. Shouldn't matter much, but maybe
 * safer to use. Changes the formatting for long, english date strings: ("LLL")
 * @param {Date} date
 * @param {string} [fmt='yyyy-MM-dd']
 * @return {string}
 */
export const formatLocale = (date, fmt = 'yyyy-MM-dd') => {
  return format(date, fmt, { locale });
}


/**
 * [Localization optional]
 *
 * @param {Date} d
 * @returns {number} - The total minutes elapsed in current day
 */
export const minutesOfDay = (d) => {
  return getMinutes(d) + (getHours(d) * 60);
}



/**************************************************************************************
  MARKET HOURS / TOPLIST
**************************************************************************************/


/**
 * [LOCALIZATION REQUIRED]
 *
 * True if not weekend, not market closed, not holiday, and within provided session keys
 * @param {Date} date - Localized date object
 * @param {string[]} sessions - the MARKET_SESSION_KEYS keys to match
 * @returns {boolean}
 */
export const isDateDuringLiveSessions = (date, sessions) => {
  const session = getMarketSession(date);
  if (!sessions.includes(session)) {
    return false;
  }

  if (isWeekend(date)) return false;

  return !isDateHoliday(date);
};


/**
 * @param {Date} date
 * @returns {boolean}
 * @deprecated - Use isWeekend() instead
 */
export const isDateInWeekendNoTimezone = (date) => {
  console.warn('Deprication warning: "isDateInWeekendNoTimezone()" Use date-fns isWeekend() instead')
  isWeekend(date);
}


/**
 * @param {Date} date
 * @returns {boolean}
 * @deprecated - Use isWeekend(toMarketTime(date)) instead
 */
export const isDateInWeekend = (date) => {
  console.warn('Deprication warning: "isDateInWeekend()" Use date-fns isWeekend(toMarketTime(date)) instead')
  isWeekend(toMarketTime(date));
}


/**
 * @depricated - use getOldestAllowedTopListDate(maxYears) instead
 * @param {Date} date - ignored, for backwards compatability only
 * @param {integer} maxYears
 * @param {string} tz - ignored, for backwards compatability only
 * @returns {Date}
 */
export const oldestTopListDataAvailableDate = (date, maxYears, tz) => {
  console.warn('Deprecation warning: "oldestTopListDataAvailableDate()" Use getOldestAllowedTopListDate(maxYears) instead')
  return getOldestAllowedTopListDate(maxYears);
}


/**
 * Get the oldest data the user is allowed to request for Top List Historical
 * @param {integer} maxYears
 * @return {Date} - Localized
 */
export const getOldestAllowedTopListDate = (maxYears) => {
  const oldestDataAvailable = assumeMarketTime(new Date('2009-01-02'));
  const oldestDayAllowedByPlan = subYears(getCurrentTradingDay(), maxYears);
  const oldest = max([oldestDayAllowedByPlan, oldestDataAvailable])
  return startOfDay(oldest);
  // 2009-10-06
};


/**
 * [Localization optional]
 * Checks the YYYY-MM-DD of the current date within its timezone
 * @example DatePicker
 * // is the user-selected DatePicker date a holiday? (Agnostic about timestamps)
 * const isHoliday = isDateHoliday(date);
 * @example timestamps
 * // is the timestamp of this event a holiday? (Must be localized)
 * const isHoliday = isDateHoliday(toMarketTime(new Date(unix_stamp)))
 * @param {Date} date
 * @returns {boolean}
 */
export const isDateHoliday = (date) => {
  return tradingHolidays.includes(format(date, 'yyyyMMdd', { locale }));
};


/**
 * [LOCALIZATION REQUIRED]
 *
 * Take in date, and check its market session in America/New_York
 * @param {Date} date - Localized date object
 */
export const getMarketSession = (date) => {
  if (typeof date === 'number') throw Error('getMarketSession() must be passed a localized Date object, not a timestamp');
  if (format(date, 'z'))

  // TODO: make this MARKET_SESSION_KEYS.CLOSED after migration
  if (isWeekend(date)) return undefined;

  return Object.keys(MARKET_SESSION_CFG).find(key => {
    return MARKET_SESSION_CFG[key].find(({ from, to }) => {
      const interval = {
        start: parse(from, 'HH:mm:ss', date),
        end: parse(to, 'HH:mm:ss', date),
      }
      return isWithinInterval(date, interval);
    });
  });
};


/**
 * [Localization optional]
 *
 * Relative to the @param date argument, finds the most recent date that had trading occur. It could be the date argument itself.
 * Excludes weekends, holidays. Excludes the current day if the time is less than 4am.
 * TIMEZONE:
 *  - Date pickers - Don't localize, the user is inputting timezone-agnostic data. Probably use a time after 4am.
 *  - Check current time or timestamp - localize. 4am stuff must work to market time.
 *
 *  @param {date} [date=getCurrentTradingDay()] - defaults to current date
 *  @returns {date}
 */
export const getMostRecentTradingDay = (date = getCurrentTradingDay()) => {
  const today = getCurrentTradingDay();

  if (isWeekend(date) || isDateHoliday(date)) {
    return getMostRecentTradingDay(subDays(date, 1));
  }

  if (getHours(date) < 4 &&
    format(date, 'yyyy-MM-dd', { locale, timeZone }) === format(today, 'yyyy-MM-dd', { locale, timeZone })
  ) {
    return getMostRecentTradingDay(subDays(date, 1));
  }

  return date;
}


/**
 * [MUST BE LOCALIZED. In fact, I recommend never passing [date] argument. String stamp will be wrong. Unix time will be correct]
 *
 * So if today is a weekend, return 4am monday.
 * If today is a holday, return 4am next business day.
 * If today is a business day and time is before 4am, return today at 4am.
 * If today is a business day and time is after 4am, return 4am tomorrow
 * @returns {Date} - Unix timestap, gloal
 */
export const nextTradingDay4amUnix = (date = getCurrentTradingDay()) => {
  let targetDay = date;

  if (
    isWeekend(date) ||
    isDateHoliday(date) ||
    getHours(date) >= 4
  ) {
    targetDay = addBusinessDays(date, 1);
  }

  return marketTimeToCorrectUnix(set4am(targetDay));
}


/**
 * Helper method. Reset timestamp to 4am.
 * @param {Date} day
 * @retuns {Date}
 */
const set4am = (day) => {
  return set(day, {
    hours: 4,
    minutes: 0,
    seconds: 0,
    milliseconds: 0
  })
}



/**
 * [Localization optional]
 *
 * Add business days onto the passed in date. Skips weekends and holidays
 * @param {Date} date
 * @param {integer} numDays
 * @returns {Date}
 */
export const addBusinessDays = (date, numDays) => {
  if (numDays <= 0) throw Error('addBusinessDays() must be passed a positive integer');

  let daysRemaining = numDays;
  let newDate = new Date(date);
  while (daysRemaining > 0) {
    newDate = addDays(newDate, 1);
    if (isWeekend(newDate) || isDateHoliday(newDate)) {
      continue;
    }
    daysRemaining--;
  }

  return newDate;
};


/**
 * [Localization optional]
 *
 * Subtract business days onto the passed in date. Skips weekends and holidays
 * @param {Date} date
 * @param {integer} numDays
 * @returns {Date}
 */
export const subBusinessDays = (date, numDays) => {
  if (numDays <= 0) throw Error('subBusinessDays() must be passed a positive integer');

  let daysRemaining = numDays;
  let newDate = new Date(date);
  while (daysRemaining > 0) {
    newDate = subDays(newDate, 1);
    if (isWeekend(newDate) || isDateHoliday(newDate)) {
      continue;
    }
    daysRemaining--;
  }

  return newDate;
};


