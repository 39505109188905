import * as Sentry from '@sentry/react';
import { current, isDraft } from 'immer';


/**
 * Log an issue inside a reducer to Sentry
 * @param {string} transactionName 
 * @param {string|Error} error 
 * @param {Object} action 
 * @param {Object|Proxy} draft 
 */
export function logSentryError(transactionName, error, action, draft) {
  Sentry.withScope((scope) => {
    const err = error instanceof Error ? error : new Error(error);
    console.warn(err);

    scope.addAttachment({
      data: JSON.stringify({
        action,
        draft: isDraft ? current(draft) : draft
      }),
      filename: 'action.json'
    });
    scope.setTransactionName(transactionName)
    Sentry.captureException(err);
  })
}