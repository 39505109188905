import {
  Box,
  IconButton,
  makeStyles
} from "@material-ui/core";
import ClearIcon from '@material-ui/icons/Clear';
import clsx from 'clsx';
import React, { useCallback } from "react";
import PanelActionButton from 'src/app/components/panels/PanelActionButton';
import { selectIsAnyFieldInvalid } from "src/app/slicedForm/FilterForm/reducers/filterReducer";
import {
  useFormActions,
  useFormDispatch,
  useFormSelector,
} from 'src/app/slicedForm/shared/context/FormProvider';
import { useFormOverlayModal } from "../context/FormOverlayModalProvider";
import { useFormPermissions, useIsPredefined } from "../context/FormSettingsProvider";
import { useNotificationApi } from "../context/NotificationProvider";
import { flashError } from "../styles/useInputStyles";
import { PROFILE_PERMISSION_SCOPES } from "src/hooks/useUserPlanPermissions";

const useStyles = makeStyles(() => ({
  box: {
    '& .MuiButtonBase-root': {
      margin: [[0, 7]]
    }
  },
  opacity: {
    opacity: 0.5
  },
  closeButton: {
    padding: 0,
    borderRadius: 4,
    '& .MuiSvgIcon-root': {
      fontSize: 28
    }
  },
}));


/**
 * Bottom bar for FormModals. Hooks up to the actions defined in FormProvider (context)
 * @param {Object} props
 * @param {function} props.selectIsAnyInvalid - FormProvider selector to check if Form buttons should be disabled
 * TODO: This really doesn't fit with expressions. We don't even have profiles.
 */
export function FormActions({
  selectIsAnyInvalid = selectIsAnyFieldInvalid
}) {
  const classes = useStyles();
  const dispatch = useFormDispatch();
  const state = useFormSelector(state => state);
  const isAnyInvalid = useFormSelector(selectIsAnyInvalid);
  const isPredefined = useIsPredefined();
  const [customizationDisabled, disallowedProfileIds] = useFormPermissions(
    PROFILE_PERMISSION_SCOPES.disabledCustomization,
    PROFILE_PERMISSION_SCOPES.disallowedPredefinedProfiles
  );
  const { enqueueNotification } = useNotificationApi();
  const { openOverlayModal } = useFormOverlayModal();

  const {
    onSubmit,
    onSave,
    onCancel,
    onCreate,
    onCopy
  } = useFormActions();

  const isAttemptingToSaveCustomProfile = !isPredefined && customizationDisabled.value;
  const isAttemptingToSaveDisallowedPredefinedProfile = isPredefined && disallowedProfileIds.value.includes(state.activeProfile);
  const isAttemptingToSaveDisallowedProfileANY = isAttemptingToSaveDisallowedPredefinedProfile || isAttemptingToSaveCustomProfile;


  const guardErrors = useCallback((callback) => {
    if (isAnyInvalid) {
      enqueueNotification({
        message: 'Please correct the errors before saving',
        severity: 'error'
      });
      flashError();
    } else {
      callback({ state, dispatch });
    }
  }, [isAnyInvalid, state, enqueueNotification]);


  const guardCreateCopy = useCallback((callback) => {
    return () => {
      // If customization is disabled, all copy/new actions are invalid
      if (customizationDisabled.value) {
        openOverlayModal('upgrade_customization', { suggestedPlanLevel: customizationDisabled.unlocksAt });
        return;
      }

      guardErrors(callback);
    }
  }, [customizationDisabled, openOverlayModal, guardErrors]);


  const guardSaveApply = useCallback((callback) => {
    return () => {
      // If customization is disabled and we're currently on a custom profile, do not let them
      // save the profile
      if (isAttemptingToSaveCustomProfile) {
        openOverlayModal('upgrade_customization', { suggestedPlanLevel: customizationDisabled.unlocksAt });
        return;
      }

      // If the user is not allowed to select this predefined profile, do not let them save it
      if (isAttemptingToSaveDisallowedPredefinedProfile) {
        openOverlayModal('upgrade_customization', { suggestedPlanLevel: disallowedProfileIds.unlocksAt });
        return;
      }

      guardErrors(callback);
    }
  }, [isAttemptingToSaveCustomProfile, isAttemptingToSaveDisallowedPredefinedProfile, openOverlayModal, customizationDisabled, disallowedProfileIds, guardErrors]);


  return (
    <>
      <Box display="flex" alignItems="center" className={classes.box}>
        {onCreate && (
          <PanelActionButton
            color="white"
            className={clsx((isAnyInvalid || customizationDisabled.value) && classes.opacity)}
            onClick={guardCreateCopy(onCreate)}
          >
            Create New
          </PanelActionButton>
        )}
        {onCopy && (
          <PanelActionButton
            color="white"
            className={clsx(isAnyInvalid || customizationDisabled.value && classes.opacity)}
            onClick={guardCreateCopy(onCopy)}
          >
            Copy
          </PanelActionButton>
        )}
        {onSave && (
          <PanelActionButton
            color="white"
            className={clsx(
              (isAttemptingToSaveDisallowedProfileANY || isAnyInvalid) && classes.opacity
            )}
            onClick={guardSaveApply(onSave)}
          >
            Save
          </PanelActionButton>
        )}
      </Box>
      <Box display="flex" alignItems="center" className={classes.box}>
        {onCancel && (
          <PanelActionButton
            color="red"
            onClick={onCancel}
          >
            Cancel
          </PanelActionButton>
        )}
        {onSubmit && (
          <PanelActionButton
            color="primary"
            className={clsx(
              (isAttemptingToSaveDisallowedProfileANY || isAnyInvalid) && classes.opacity)
            }
            onClick={guardSaveApply(onSubmit)}
          >
            Apply
          </PanelActionButton>
        )}
      </Box>
    </>
  )
}


export function FormCloseButton() {
  const classes = useStyles();
  const { onCancel } = useFormActions();

  return (
    <IconButton
      className={classes.closeButton}
      onClick={onCancel}
      color="primary"
      disableRipple
      aria-label="Close the modal without saving"
    >
      <ClearIcon color="primary" />
    </IconButton>
  )
}

export default FormActions;
