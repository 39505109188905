import produce from 'immer';
import {
  FETCH_HISTORY_FULL,
  FETCH_PAGINATE_HISTORY_SERVERSIDE,
  PAGINATE_HISTORY_FULL,
  FETCH_HISTORY_GAP_STATS,
  FETCH_HISTORY_MARKET_STATS,
  FETCH_HISTORY_TIMESERIES,
  SET_HISTORY_ORDER,
  REQUEST__FETCH_PAGINATE_HISTORY_SERVERSIDE,
  REQUEST__FETCH_HISTORY_GAP_STATS,
  REQUEST__FETCH_HISTORY,
  REQUEST__FETCH_HISTORY_MARKET_STATS,
  REQUEST__FETCH_HISTORY_TIMESERIES
}
from 'src/redux/history/historyActions';


const initialState = {
  history: {
    main: {
      data: [],
      totalCount: 0,
      hasMore: null,
      serverPage: 0,
      frontendPage: 0,
      isFetching: true,
      isPaginating: false
    },
    overview: {
      data: [],
      totalCount: 0,
      hasMore: null,
      serverPage: 0,
      frontendPage: 0,
      isFetching: false,
      isPaginating: false,

      order: 'desc',
      orderby: 'day0_date'
    },
  },
  marketStats: {
    main: {
      data: {},
      isFetching: true
    },
    overview: {
      data: {
        stats: {},
        records: []
      },
      isFetching: false
    },
  },
  timeseries: {},
  gapStatsData: [],
  isFetchingGapStats: true,
  isFetchingMarketStats: true,
  isFetchingTimeseries: true,
};

const historyReducer = (state = initialState, action) => {

  switch (action.type) {

    case FETCH_HISTORY_FULL: {
      return produce(state, (draft) => {
        const { data: response, stateKey } = action.payload;
        draft.history[stateKey].isFetching = false;

        draft.history[stateKey].data = response?.data || initialState.history[stateKey].data;
        draft.history[stateKey].frontendPage = 0;

        draft.history[stateKey].totalCount = response?.total_count || initialState.history[stateKey].totalCount;
        draft.history[stateKey].serverPage = response?.page || initialState.history[stateKey].serverPage;
        draft.history[stateKey].hasMore = response?.has_more || initialState.history[stateKey].hasMore;
      });
    }

    case FETCH_PAGINATE_HISTORY_SERVERSIDE:
      return produce(state, (draft) => {
        const { data: response, stateKey } = action.payload;
        draft.history[stateKey].isFetching = false;
        draft.history[stateKey].isPaginating = false;

        if (response?.data) {
          draft.history[stateKey].data = [...draft.history[stateKey].data, ...response.data];
        }

        draft.history[stateKey].totalCount = response?.total_count || initialState.history[stateKey].totalCount;
        draft.history[stateKey].serverPage = response?.page || initialState.history[stateKey].serverPage;
        draft.history[stateKey].hasMore = response?.has_more || initialState.history[stateKey].hasMore;

      });

    case PAGINATE_HISTORY_FULL: {
      return produce(state, (draft) => {
        const { data: page, stateKey } = action.payload;
        draft.history[stateKey].frontendPage = page;
      });
    }

    case FETCH_HISTORY_MARKET_STATS: {
      return produce(state, (draft) => {
        const { data, stateKey } = action.payload;
        draft.marketStats[stateKey].isFetching = false;
        draft.marketStats[stateKey].data = data;
      });
    }

    case SET_HISTORY_ORDER: {
      return produce(state, (draft) => {
        const { data, stateKey } = action.payload;
        const { order, orderby } = data;
        draft.history[stateKey].order = order;
        draft.history[stateKey].orderby = orderby;
      })
    }

    case FETCH_HISTORY_TIMESERIES: {
      return produce(state, (draft) => {
        draft.isFetchingTimeseries = false;
        draft.timeseries = action.payload;
      });
    }

    case FETCH_HISTORY_GAP_STATS: {
      return produce(state, (draft) => {
        draft.isFetchingGapStats = false;
        draft.gapStatsData = action.payload || [];
      });
    }

    case REQUEST__FETCH_HISTORY: {
      return produce(state, (draft) => {
        draft.history[action.payload].isFetching = true;
      });
    }

    case REQUEST__FETCH_PAGINATE_HISTORY_SERVERSIDE: {
      return produce(state, (draft) => {
        draft.history[action.payload].isPaginating = true;
      });
    }

    case REQUEST__FETCH_HISTORY_GAP_STATS: {
      return produce(state, (draft) => {
        draft.isFetchigGapStats = true;
      });
    }
    case REQUEST__FETCH_HISTORY_TIMESERIES: {
      return produce(state, (draft) => {
        draft.isFetchingTimeseries = true;
      });
    }

    case REQUEST__FETCH_HISTORY_MARKET_STATS: {
      return produce(state, (draft) => {
        draft.marketStats[action.payload].isFetching = true;
      });
    }

    default: {
      return state;
    }
  }
};

export default historyReducer;
