export default class ChartToolbarButtonBase {
  // eslint-disable-next-line no-unused-vars
  constructor(widget, createButtonArgs) {}

  onClick(callback) {
    this.addEventListener('click', callback);
  }

  addEventListener(type, callback) {
    this.element.addEventListener(type, callback);
  }

  setAttribute(attribute, value) {
    this.element.setAttribute(attribute, value);
  }

  render() {
    throw Error('ChartToolbarButton render() Must be overriden');
  }
}
