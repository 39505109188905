
import produce from 'immer';
import _uniqBy from 'lodash/uniqBy';
import {
  REALTIME_SET_NEWS_CLICKED_TICKER,
  REALTIME_STOCKNEWS_MESSAGE,
  REALTIME_SEC_FILINGS_MESSAGE,
  REALTIME_FETCH_NEWS_HISTORY,
  REALTIME_FETCH_PRICE_DATA,
  REALTIME_BATCH_NEWS_HISTRY,
  REALTIME_SET_SNAPSHOT_TICKER,
  REALTIME_SET_SNAPSHOT_SCAN_ENABLED,
  REALTIME_SET_SNAPSHOT_SCAN_DELAY,
  REALTIME_SET_SNAPSHOT_NUM_TICKERS_TO_SCAN,
  REALTIME_SET_IS_VIEWING_LIVE_DATA,
  REALTIME_TOPLIST_SCANNER_MESSAGE,
  REALTIME_STOCKNEWS_MESSAGE_CLICKED_TICKER,
  SCANNER_HTTP_MESSAGE,
  REQUEST__SCANNER_HTTP_MESSAGE,
  NEWS_PAGE_FETCH_TICKER_NEWS,
  REALTIME_CLEAR_NEWS_HISTORY,
  REQUEST__REALTIME_FETCH_NEWS_HISTORY,
  REQUEST__NEWS_PAGE_FETCH_TICKER_NEWS,
  REALTIME_SET_TL_FEED_TYPE,
  REQUEST__REALTIME_FETCH_TL_SCANNER_HISTORY,
  REQUEST__REALTIME_FETCH_SCANNER_STATIC,
  RESOLVE__REALTIME_FETCH_NEWS_HISTORY,
  REQUEST__REALTIME_BATCH_NEWS_HISTORY,
} from 'src/redux/realtime/realtimeActions';


const initialState = {
  news: {
    news: {
      data: [],
      tickerData: [],
      isFetching: true,
      isResolved: false,
      isFetchingTicker: true,
      clickedTopListTicker: null
    },
    sec: {
      data: [],
      tickerData: [],
      isFetching: true,
      // isLoadingBackground: true,
      isResolved: false,
      isFetchingTicker: true,
    },
  },
  topListScanner: {
    selectedSession: '',
    isViewingLiveData: true,
    isFetching: false,
    httpData: []
  },
  snapshotSettings: {
    ticker: null,
    ssr: false,
    scanEnabled: false,
    numTickersToScan: 15,
    scanDelay: 15
  }
};


const accountReducer = (state = initialState, action) => {
  switch (action.type) {

    case REALTIME_STOCKNEWS_MESSAGE: {
      return produce(state, (draft) => {
        draft.news.news.data = _uniqBy(action.payload.concat(draft.news.news.data), 'uuid');
      });
    }

    case REALTIME_SEC_FILINGS_MESSAGE: {
      return produce(state, (draft) => {
        draft.news.sec.data = _uniqBy(action.payload.concat(draft.news.sec.data), 'uuid');
      });
    }

    case REALTIME_TOPLIST_SCANNER_MESSAGE: {
      const { data, key } = action.payload;
      return produce(state, (draft) => {
        draft.topListScanner[key][data.session] = data.data;
      });
    }

    case REALTIME_STOCKNEWS_MESSAGE_CLICKED_TICKER: {
      return produce(state, (draft) => {
        draft.news.news.tickerData = [...action.payload.data, ...draft.news.news.tickerData];
      });
    }

    case REALTIME_FETCH_NEWS_HISTORY: {
      const { type, data } = action.payload;
      return produce(state, (draft) => {
        draft.news[type].isFetching = false;
        draft.news[type].data = _uniqBy(data, 'uuid');
      });
    }

    case REALTIME_BATCH_NEWS_HISTRY: {
      const { type, data } = action.payload;
      return produce(state, (draft) => {
        draft.news[type].isFetching = false;
        draft.news[type].data = _uniqBy([...draft.news[type].data, ...data], 'uuid');
      });
    }

    case REALTIME_CLEAR_NEWS_HISTORY: {
      return produce(state, (draft) => {
        draft.news.news.data = [];
      });
    }

    case NEWS_PAGE_FETCH_TICKER_NEWS: {
      const { type, data } = action.payload;
      return produce(state, (draft) => {
        draft.news[type].isFetchingTicker = false;
        draft.news[type].tickerData = _uniqBy(data, 'uuid').sort((a, b) => b.dateCompare - a.dateCompare);
      });
    }


    case REALTIME_FETCH_PRICE_DATA: {
      if (action.payload && action.payload.length) {
        return produce(state, (draft) => {
          ['news', 'sec'].forEach(type => {
            draft.news[type].data.forEach(el => {
              const priceInfo = action.payload.find(({ T }) => el.ticker === T);
              if (priceInfo) {
                el.p = priceInfo.p || el.p;
                el.chg = priceInfo.chg || el.chg;
                el.chg_p = priceInfo.chg_p || el.chg_p;
                el.v = priceInfo.v || el.v;
                el.prev_c = priceInfo.prev_c || el.prev_c;
              }
            });
          });
        });
      }
      return state;
    }

    case SCANNER_HTTP_MESSAGE: {
      return produce(state, (draft) => {
        draft.topListScanner.httpData = action.payload;
        draft.topListScanner.isFetching = false;
        // draft.topListScanner.isLoadingBackground = false;
      });
    }


    case REALTIME_SET_SNAPSHOT_TICKER: {
      return produce(state, (draft) => {
        draft.snapshotSettings.ticker = action.payload.ticker;
        draft.snapshotSettings.ssr = action.payload.ssr;
      });
    }


    case REALTIME_SET_NEWS_CLICKED_TICKER: {
      return produce(state, (draft) => {
        draft.news.news.clickedTopListTicker = action.payload;
      });
    }


    case REALTIME_SET_TL_FEED_TYPE: {
      return produce(state, (draft) => {
        draft.topListScanner.selectedListType = action.payload;
      });
    }


    case REALTIME_SET_SNAPSHOT_SCAN_DELAY: {
      return produce(state, (draft) => {
        draft.snapshotSettings.scanDelay = action.payload;
      });
    }


    case REALTIME_SET_SNAPSHOT_SCAN_ENABLED: {
      return produce(state, (draft) => {
        draft.snapshotSettings.scanEnabled = action.payload;
      });
    }

    case REALTIME_SET_SNAPSHOT_NUM_TICKERS_TO_SCAN: {
      return produce(state, (draft) => {
        draft.snapshotSettings.numTickersToScan = action.payload;
      });
    }

    case REALTIME_SET_IS_VIEWING_LIVE_DATA: {
      return produce(state, (draft) => {
        draft.topListScanner.isViewingLiveData = action.payload;
      });
    }

    case REQUEST__SCANNER_HTTP_MESSAGE: {
      return produce(state, (draft) => {
        draft.topListScanner.isFetching = true;
        // draft.topListScanner.isLoadingBackground = true;
      });
    }
    // case REQUEST__SCANNER_BACKGROUND_HTTP_MESSAGE: {
    // return produce(state, (draft) => { draft.topListScanner.isLoadingBackground = true })
    // }
    case REQUEST__REALTIME_FETCH_NEWS_HISTORY: {
      return produce(state, (draft) => { draft.news[action.payload].isFetching = true; });
    }
    case REQUEST__REALTIME_BATCH_NEWS_HISTORY: {
      return produce(state, (draft) => {
        draft.news[action.payload].isFetching = true;
        draft.news[action.payload].isResolved = false;
        draft.news[action.payload].data = [];
      });
    }
    case REQUEST__NEWS_PAGE_FETCH_TICKER_NEWS: {
      return produce(state, (draft) => { draft.news[action.payload].isFetchingTicker = true; });
    }
    case REQUEST__REALTIME_FETCH_TL_SCANNER_HISTORY: {
      return produce(state, (draft) => { draft.topListScanner.isFetching = true; });
    }
    case REQUEST__REALTIME_FETCH_SCANNER_STATIC: {
      return produce(state, (draft) => { draft.topListScanner.staticData.isFetching = true; });
    }
    case RESOLVE__REALTIME_FETCH_NEWS_HISTORY: {
      return produce(state, (draft) => {
        draft.news[action.payload].isFetching = false;
        draft.news[action.payload].isResolved = true;
      });
    }

    default: {
      return state;
    }
  }
};

export default accountReducer;
