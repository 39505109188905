import React from 'react';
import clsx from 'clsx';
import {
  ButtonBase,
  alpha,
  makeStyles
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    minWidth: 64,
    boxSizing: 'border-box',
    fontFamily: theme.typography.fontFamily,
    fontWeight: 800,
    padding: '1px 13px',
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    lineHeight: 1.75,
    borderRadius: 4,
    fontSize: 14,
    borderWidth: 2,
    borderStyle: 'solid',
    color: theme.palette.primary.main,
    cursor: 'pointer',
    '&:focus-visible': {
      ...theme.focus.outline
    }
  },
  disabled: {
    cursor: 'not-allowed',
    opacity: .4
  },
  primary: {
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    '&:hover': {
      // borderColor: theme.palette.primary.main},
      backgroundColor: alpha(theme.palette.primary.main, .15),
    }
  },
  red: {
    color: theme.palette.text.negative,
    borderColor: theme.palette.text.negative,
    '&:hover': {
      // border: '3px solid inherit',
      backgroundColor: alpha(theme.palette.text.negative, .15),
    }
  },
  white: {
    color: theme.palette.text.primary,
    borderColor: theme.palette.text.primary,
    '&:hover': {
      // border: '3px solid inherit',
      backgroundColor: alpha(theme.palette.text.primary, .15),
    }
  },
  gray: {
    color: theme.palette.text.label,
    borderColor: theme.palette.text.label,
    '&:hover': {
      backgroundColor: alpha(theme.palette.text.label, .15),
    }
  }
}));


export default function PanelActionButton({ className, color = 'primary', disabled = false, onClick, children }) {
  const classes = useStyles();
  let rootClass = classes.primary;
  if (color === 'red') {
    rootClass = classes.red;
  } else if (color === 'white') {
    rootClass = classes.white;
  } else if (color === 'gray') {
    rootClass = classes.gray;
  }
  return (
    <ButtonBase
      variant="outlined"
      color="primary"
      disabled={disabled}
      disableRipple
      onClick={onClick}
      className={clsx(classes.root, rootClass, className, disabled && classes.disabled)}
    >
      {children}
    </ButtonBase>
  );
}

