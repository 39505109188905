import { useCallback } from 'react';

function useTickerExcludeContextMenu({
  forceTicker = null,
  addExcludedTicker,
}) {

  return useCallback((contextMenuParams) => {
    const ticker = contextMenuParams?.node?.data?.ticker || forceTicker;

    if (!ticker) return [];

    return [{
      name: `Exclude <strong>${ticker}</strong> from this component`,
      action: () => addExcludedTicker(ticker)
    }];
  }, [addExcludedTicker]);
}


export default useTickerExcludeContextMenu;