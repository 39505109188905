
import React from 'react';
import clsx from 'clsx';
import { MergeType } from '@material-ui/icons';
import MarketingModal from 'src/app/components/MarketingModal/elements/MarketingModal';
import MarketingModalCallout from 'src/app/components/MarketingModal/elements/MarketingModalCallout';
import { Functions as FunctionsIcon } from '@material-ui/icons';
import {
  Typography,
  List,
  ListItem,
  Box,
  ListItemText,
  ListItemIcon,
  makeStyles,
  useTheme
} from '@material-ui/core';
import { CheckIcon, HistoryIcon } from 'src/theme/EdgeIcons';
import { permissions, PLAN_LEVEL_LABELS, usePlanLevel } from 'src/hooks/useUserPlanPermissions';
import MarketingModalErrorBoundary from './ErrorBoundaryMarketingModal';
import PropTypes from 'prop-types';


const useStyles = makeStyles(theme => ({
  root: {},
}));


function MarketingModalHistoricalYearsUpgrade(props) {
  return (
    <MarketingModalErrorBoundary {...props}>
      <ModalContent {...props} />
    </MarketingModalErrorBoundary>
  )
}


function ModalContent({
  className,
  suggestedPlanLevel,
  scopeKey,
  scopeName,
  onClose,
}) {
  const theme = useTheme();
  const currentPlanLevel = usePlanLevel();
  const classes = useStyles();
  const planLevelLabel = PLAN_LEVEL_LABELS[suggestedPlanLevel];
  const currentPlanLevelLabel = PLAN_LEVEL_LABELS[currentPlanLevel];

  const title = (
    <Typography variant="h2">
      <span style={{ marginRight: 10 }}>🚀</span>
      Upgrade to <span style={{ color: theme.palette.text.planTag }}>{planLevelLabel}</span></Typography>
  )

  const limit = permissions?.[currentPlanLevel]?.[scopeKey];
  const nextLimit = permissions?.[suggestedPlanLevel]?.[scopeKey];

  return (
    <MarketingModal
      className={clsx(classes.root, className)}
      suggestedPlanLevel={planLevelLabel}
      titleContent={title}
      onClose={onClose}
    >
      <Box pt={2} pb={2}>
        <Typography variant="body1" color="textSecondary" >
          Your current <span style={{ color: theme.palette.text.planTag }}>{currentPlanLevelLabel}</span> plan is limited to {limit} {limit === 1 ? 'year' : 'years'} of {scopeName}.
        </Typography>
      </Box>

      <MarketingModalCallout Icon={HistoryIcon}>
        <Typography variant="h3">Access {nextLimit} years of Historical data</Typography>
        <Typography variant="body1">See further back into history on the History page, Historical Stats component, and Scanner component</Typography>
      </MarketingModalCallout>

      <Box pt={2}>
        <Typography variant="body1" color="textSecondary">
          Additional {planLevelLabel} Features:
        </Typography>
      </Box>

      <Box pt={2} pb={1}>
        <List>
          <ListItem>
            <ListItemIcon className="success"><CheckIcon /></ListItemIcon>
            <ListItemText primary={`Create up to ${permissions?.[suggestedPlanLevel]?.max_expressions} custom Expressions`} />
          </ListItem>
          <ListItem>
            <ListItemIcon className="success"><CheckIcon /></ListItemIcon>
            <ListItemText primary="Customizable Ticker Stats Profiles" />
          </ListItem>
          <ListItem>
            <ListItemIcon className="success"><CheckIcon /></ListItemIcon>
            <ListItemText primary="Unlimited Scanner, Chart, and Watchlist Components" />
          </ListItem>
        </List>
      </Box>

    </MarketingModal>
  )
}


MarketingModalHistoricalYearsUpgrade.propTypes = {
  className: PropTypes.string,
  suggestedPlanLevel: PropTypes.string,
  scopeKey: PropTypes.string,
  scopeName: PropTypes.string,
  onClose: PropTypes.func,
}

export default MarketingModalHistoricalYearsUpgrade;
