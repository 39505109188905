export const CHECKBOX_STATES = {
  CHECKED: 'checked',
  UNCHECKED: 'unchecked',
  INDETERMINATE: 'indeterminate'
};

export const CHILD_TYPES = {
  NESTED: 'nested',
  INDENTED: 'indented'
};
