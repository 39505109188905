import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import DoubleConfirmationButton, {
  useDoubleConfirmationState as originalUseDoubleConfirmationState,
  DoubleConfirmationButtonDefaultProps,
  DoubleConfirmationButtonPropTypes
} from 'src/app/components/elements/DoubleConfirmationButton';
import { ItemContext } from './context'
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {},
}))



function DoubleConfirmationIconButton({
  className,
  ...rest
}) {
  const classes = useStyles();
  const { disabled } = useContext(ItemContext);

  return (
    <DoubleConfirmationButton
      className={clsx(classes.root, className)}
      {...rest}
      disabled={disabled || rest.disabled}
    />
  )
}


DoubleConfirmationIconButton.displayName = 'ActionList.DoubleConfirmationIconButton'



DoubleConfirmationIconButton.propTypes = {
  ...DoubleConfirmationButtonPropTypes
};



DoubleConfirmationIconButton.defaultProps = {
  ...DoubleConfirmationButtonDefaultProps,
  baseLabel: undefined,
  baseIcon: undefined,
};


export const useDoubleConfirmationState = originalUseDoubleConfirmationState;


export default DoubleConfirmationIconButton;
