import React from 'react';
import clsx from 'clsx';
import _noop from 'lodash/noop';
import { EditIcon } from 'src/theme/EdgeIcons';
import {
  InputBase,
  InputAdornment,
  alpha,
  makeStyles,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {},
  filterInput: {
    border: `none`,
    borderRadius: 6,
    display: 'flex',
    backgroundColor: theme.palette.background.paper,
    '& .MuiInputAdornment-root': {
      height: 'unset'
    },
    '& .MuiSvgIcon-root': {
      marginLeft: 9,
      marginRight: 3
    },
    '& .MuiInputBase-input': {
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 6,
      color: theme.palette.text.primary
    },
    '&.Mui-disabled': {
      '& .MuiSvgIcon-root': {
        display: 'none',
      },
      '& .MuiInputBase-input': {
        color: alpha(theme.palette.text.primary, .6)
      }
    }
  }
}));


function FilterWindowNameTextField({
  className,
  value,
  onChange,
  onBlur,
  Icon,
  placeholder,
  ariaLabel,
  disabled
}) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.root, className)}>
      <InputBase
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        onBlur={onBlur}
        className={clsx(classes.filterInput)}
        disabled={disabled}
        inputProps={{ 'aria-label': ariaLabel }}
        endAdornment={
          <InputAdornment position="end">
            <Icon style={{ fontSize: 18 }} />
          </InputAdornment>
        }
      />
    </div>
  );
}

FilterWindowNameTextField.defaultProps = {
  Icon: EditIcon,
  onBlur: _noop,
  placeholder: '',
  ariaLabel: 'Rename',
}

export default FilterWindowNameTextField;
