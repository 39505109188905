import React, { useContext, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import clsx from 'clsx';
import { MosaicContext, MosaicWindowContext } from 'react-mosaic-component';
import LayoutContext from 'src/app/TopListsMosaic/layout/LayoutContext';
import { COMPONENT_MAP } from 'src/app/TopListsMosaic/layout/components';
import { selectComponentLink } from 'src/redux/layout/topListLayoutSelectors';
import { setComponentLinkColor } from 'src/redux/layout/topListLayoutActions';
import { useStyles as usePanelHeaderStyles } from 'src/app/components/panels/SinglePanelHeader';
import MosaicPanelTickerSearch from 'src/app/TopListsMosaic/layout/MosaicPanelHeader/MosaicPanelTickerSearch';
import PanelIconGroup from 'src/app/components/panels/PanelIconGroup';
import PanelIconButton from 'src/app/components/panels/PanelIconButton';
import LinkingDropdownMenu from 'src/app/TopListsMosaic/layout/MosaicPanelHeader/LinkingDropdownMenu';
import { DenyLargeIcon, MoveIcon } from 'src/theme/EdgeIcons';
import BugReportIcon from '@material-ui/icons/BugReport';
import {
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import ConditionalWrapper from 'src/app/components/utility/ConditionalWrapper';
import DarkTooltip from 'src/app/components/utility/DarkTooltip';

const useStyles = makeStyles(() => ({
  root: {
    paddingLeft: 0,
    position: 'relative',
  },
  dragHandleIcon: {
    paddingLeft: 9,
    paddingRight: 9,
  },
  panelHeaderLeft: {
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'center',
    backgroundColor: 'inherit',
    paddingRight: 4,
    zIndex: 1
  },
  panelIconGroupCont: {
    position: 'absolute',
    right: 4,
    height: '100%',
    display: 'flex',
    alignItems: 'center'
  },
  panelIconGroup: {
    marginRight: 0,
  },
  spacer: {
    width: 15
  },
  uninteractable: {
    pointerEvents: 'none !important',
  },
}));


const preventParentDragHandle = (event) => {
  event.preventDefault();
  event.stopPropagation();
};


const handleDebugCopy = (componentId) => {
  navigator.clipboard.writeText(componentId);
  alert(`Copied Component ID to clipboard: ${componentId}`)
}


function MosaicPanelHeader({
  className,
  children,
  showLinkingDropdownMenu,
  showClose,
  showDragHandle,
  title,
  titleSuppliment,
  titleSupplimentColor,
  align,
  loading,
  noPaddingRight,
  showClearButton,
  tickerSearchDoNotShowLastTicker,
  tickerSearchDisabled,
  tickerSearchPlaceholder,
  tickerSearchIcon,
  tickerSearchValue,
  onTickerSearchSubmit,
  onTickerSearchClear,
  debug,
}) {
  const panelHeaderClasses = usePanelHeaderStyles();
  const classes = useStyles();
  const dispatch = useDispatch();
  const mosaicContext = useContext(MosaicContext);
  const mosaicWindowContext = useContext(MosaicWindowContext);
  const { componentId, layoutId, componentType } = useContext(LayoutContext);
  const link = useSelector(selectComponentLink(componentId, layoutId));


  const [ComponentIcon, componentTitle] = useMemo(() => {
    return [
      componentType && COMPONENT_MAP?.[componentType]?.Icon ? COMPONENT_MAP[componentType].Icon : MoveIcon,
      componentType && COMPONENT_MAP?.[componentType]?.title ? COMPONENT_MAP[componentType].title : null,
    ];
  }, [componentType]);


  const handleLinkColor = (colorName) => {
    dispatch(setComponentLinkColor(componentId, layoutId, colorName));
  };


  const renderTitle = () => {
    return (
      <div className={clsx(panelHeaderClasses.panelTitleCont)}>
        <Typography variant="h3" color="textPrimary" className="panelTitle" align={align}>{title}</Typography>
        {!!(titleSuppliment) && React.isValidElement(titleSuppliment) ? (
          titleSuppliment
        ) : (
          <Typography variant="h4" className="panelTitleSuppliment" color="textSecondary" style={{ color: titleSupplimentColor }}>{titleSuppliment}</Typography>
        )}
      </div>
    );
  };


  const renderContent = () => (
    <div className={clsx(panelHeaderClasses.draggable, componentId)} data-component-id={componentId}>
      <Box className={clsx(panelHeaderClasses.root, classes.root, className, noPaddingRight && panelHeaderClasses.noPaddingRight)}>
        <div className={classes.panelHeaderLeft}>
          {(showDragHandle)
            ? (
              <ConditionalWrapper
                condition={componentTitle}
                wrapper={(children) => (
                  <DarkTooltip
                    title={(<div className={classes.tooltipContent}>{componentTitle}</div>)}
                    arrow={false}
                    enterDelay={0}
                    variant="primary"
                    placement="top"
                  >
                    {children}
                  </DarkTooltip>
                )}
              >
                <div className={classes.dragHandleIcon}>
                  <ComponentIcon />
                </div>
              </ConditionalWrapper>
            ) : (
              <div className={classes.spacer} />
            )}
          {(onTickerSearchSubmit) && (
            <div
              draggable
              onDragStart={preventParentDragHandle}
              className={clsx(loading && classes.uninteractable)}
            >
              <MosaicPanelTickerSearch
                doNotShowLastTicker={tickerSearchDoNotShowLastTicker}
                disabled={tickerSearchDisabled}
                Icon={tickerSearchIcon}
                ticker={tickerSearchValue}
                onSubmit={onTickerSearchSubmit}
                onClear={onTickerSearchClear}
                showClearButton={Boolean(onTickerSearchClear && showClearButton && tickerSearchValue)}
                nullPlaceholder={tickerSearchPlaceholder}
              />
            </div>
          )}
          {(title || titleSuppliment) && renderTitle()}
        </div>
        <div
          draggable
          onDragStart={preventParentDragHandle}
          className={clsx(
            classes.panelIconGroupCont,
            loading && classes.uninteractable
          )}
        >
          <PanelIconGroup
            className={classes.panelIconGroup}
            compact
          >
            {Boolean(debug && process.env.REACT_APP_USERS_LAMBDA_STAGE !== 'prod') && (
              <PanelIconButton onClick={() => handleDebugCopy(componentId)} text={`Copy ID: ${componentId}`} Icon={BugReportIcon} shouldHideIconText >ID</PanelIconButton>
            )}
            {children}
            {showLinkingDropdownMenu && (
              <LinkingDropdownMenu
                link={link}
                onSelect={handleLinkColor}
              />
            )}
            {(showClose) && (
              <PanelIconButton
                onClick={() => mosaicContext
                  ? mosaicContext.mosaicActions.remove(mosaicWindowContext.mosaicWindowActions.getPath())
                  : null
                }
                Icon={DenyLargeIcon}
                shouldHideIconText={true}
                text="Remove Component"
              />
            )}
          </PanelIconGroup>
        </div>
      </Box>
    </div>
  );

  // Force the drag source to disconnect if we are the root element. This will automatically reconnect it when another panel is added.
  return (mosaicWindowContext?.mosaicWindowActions?.getPath() && mosaicWindowContext?.mosaicWindowActions?.getPath().length)
    ? mosaicWindowContext.mosaicWindowActions.connectDragSource(renderContent())
    : renderContent();
}


MosaicPanelHeader.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  title: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  titleSuppliment: PropTypes.string,
  titleSupplimentColor: PropTypes.string,
  align: PropTypes.string,
  showLinkingDropdownMenu: PropTypes.bool,
  showClose: PropTypes.bool,
  showClearButton: PropTypes.bool,
  loading: PropTypes.bool,
  showDragHandle: PropTypes.bool,
  noPaddingRight: PropTypes.bool,

  tickerSearchDoNotShowLastTicker: PropTypes.bool,
  tickerSearchDisabled: PropTypes.bool,
  tickerSearchPlaceholder: PropTypes.string,
  tickerSearchIcon: PropTypes.any,
  tickerSearchValue: PropTypes.string,
  onTickerSearchSubmit: PropTypes.func,
  onTickerSearchClear: PropTypes.func,

  // tickerSearchProps: PropTypes.objectOf(PropTypes.shape(MosaicPanelTickerSearch.propTypes)),
};


MosaicPanelHeader.defaultProps = {
  titleSupplimentColor: '#fff',
  align: 'left',
  noPaddingRight: false,
  showLinkingDropdownMenu: true,
  loading: true,
  showClose: true,
  showDragHandle: true,
  showClearButton: false,
  tickerSearchDoNotShowLastTicker: false,
  tickerSearchDisabled: false,
};


export default MosaicPanelHeader;

