import React from 'react';
import PropTypes from 'prop-types'
import clsx from 'clsx';
import MosiacLoadingOverlay from './MosaicLoadingOverlay';
import {
  makeStyles
} from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {
    position: 'relative'
  }
}));


const MosaicPanelBody = React.forwardRef(({
  className,
  loading,
  children
}, ref) => {
 const classes = useStyles();

  return (
    <div className={clsx(className, classes.root)} ref={ref}>
      {children}
      <MosiacLoadingOverlay show={loading} />
    </div>
  );
});


MosaicPanelBody.propTypes = {
  clasName: PropTypes.string,
  loading: PropTypes.bool,
  children: PropTypes.any
}


MosaicPanelBody.defaultProps = {
  loading: true,
  style: {}
}


MosaicPanelBody.displayName = 'MosaicPanelBody';

export default MosaicPanelBody;

