/**
 * Optionally wrap elements with the provided wrapper class, if condition === true.
 *
 * @param {boolean} condition - Condition to check
 * @param {function} wrapper - The JSX to render the wrapper element
 * @param {JSX} children - The children, always rendered
 */
const ConditionalWrapper = ({ condition, wrapper, children }) => {
  return condition ? wrapper(children) : children
}

export default ConditionalWrapper;
