import { useEffect, useRef, useReducer } from 'react';
import { fetchKeystats } from 'src/redux/keyStats/keyStatsActions';
import {
  dataFetchReducer,
  REQUEST__FETCH,
  SUCCESS__FETCH,
  FAILURE__FETCH
} from 'src/reducers/dataFetchReducer';


function useFetchKeystats(ticker) {
  const isMounted = useRef(true);

  const [state, dispatch] = useReducer(dataFetchReducer, {
    data: {},
    isFetching: false,
    error: null,
  });

  useEffect(() => () => {
    isMounted.current = false;
  }, []);

  useEffect(() => {

    let ignore = false;
    const fetchData = async () => {
      dispatch({ type: REQUEST__FETCH });
      try {
        let result = await fetchKeystats(ticker);
        if (isMounted.current && !ignore) {
          dispatch({ type: SUCCESS__FETCH, payload: result ? result : {} });
        }
      } catch (err) {
        if (isMounted.current && !ignore) {
          dispatch({ type: FAILURE__FETCH, payload: { error: err.message, data: {} } });
        }
      }
    };

    void fetchData();

    return () => ignore = true;

  }, [ticker]);

  return [state.data, state.isFetching, state.error];
}


export default useFetchKeystats;
