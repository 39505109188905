import React from 'react';
import clsx from 'clsx';
import { FUNCTIONS, OPERATIONS, FUNCTION_LABELS } from '../arithmatic/grammar';
import {
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { T } from 'lodash/fp';


const useStyles = makeStyles(theme => ({
  root: {},
  highlight: {
    color: theme.palette.primary.main,
    fontWeight: 600
  },
  callout: {
    // display: 'inline-block',
    boxShadow: 'inset 0 0 4px rgba(0, 0, 0, 0.7)',
    backgroundColor: theme.palette.background.paperAlt_darker1,
    borderRadius: 6,
    padding: theme.spacing(1),
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  }
}));


function Callout({ children }) {
  const classes = useStyles();
  return (
    <div className={classes.callout}>
      {children}
    </div>
  )
}


function ExpressionInstructions({ className }) {
  const classes = useStyles();

  return (
    <Box p={1}>
      <Box pb={2}>
        <Typography varaint="p">Begin typing an <span className={classes.highlight}>@</span> symbol to open the Column selection menu:</Typography>
        <Callout>
          <Typography>@Price</Typography>
        </Callout>
      </Box>

      <Box pb={2}>
        <Typography varaint="p">Combine columns, numbers, operators, and functions:</Typography>
        <Callout>
          <Typography>(@Price + 10) / (@Open - 10)</Typography>
        </Callout>
      </Box>

      <Box pb={2}>
        <Typography varaint="h5">Allowed operaters and symbols:</Typography>
        <Callout>
          <Typography>{OPERATIONS.map(op => <span key={op} style={{ marginRight: 8 }}>{op}</span>)}</Typography>
        </Callout>
      </Box>

      <Box pb={1}>
        <Typography variant="h5">Allowed functions:</Typography>
        <Box >
          {Object.keys(FUNCTION_LABELS).filter(func => func in FUNCTIONS).map(func => {
            const { description, example, answer } = FUNCTION_LABELS[func];
            return (
              <Callout key={func}>
                <Typography>{example} <span style={{ opacity: .7, marginLeft: 10 }}>{`// ${answer}`}</span></Typography>
              </Callout>
            )
          })}
        </Box>
      </Box>
    </Box>
  )
}


export default ExpressionInstructions;
