import { getCompactCellParts } from 'src/app/components/grid/cellRenderers/CompactNumberCell';


const exists = (value, allowEmptyString = false) => {
  return value != null && (value !== '' || allowEmptyString);
};



/**
 * Adds a red or green flash to cells when their value changes
 * Must be a Prototype extention to access the init and refresh functions.
 * 
 * TODO: I don't think prototype is necessary anymore, but I'm not sure.
 */

function AnimateChangeCellRenderer() { }

AnimateChangeCellRenderer.prototype.init = function (params) {
  this.eGui = document.createElement('div');
  this.lastDisplayValue = params.valueFormatted ? params.valueFormatted : params.value;
  this.lastValue = params.value;
  this.refreshCount = 0;

  // eGui must be mutable. Modify this going forward.
  this.eGui = document.createElement('div');
  this.buildEGui(this.lastDisplayValue, params);
};


AnimateChangeCellRenderer.prototype.getGui = function () {
  return this.eGui;
};


AnimateChangeCellRenderer.prototype.buildEGui = function (value, params) {
  const { align = 'left', compact = true } = params;

  this.eGui.innerHTML = '';

  if (align === 'right') {
    this.eGui.classList.add('ag-right-aligned-cell');
  }

  if (compact) {
    const [prefix, middle, postfix] = getCompactCellParts(value);
    this.eGui.classList.add('compact-cell');

    if (prefix) {
      const prefixEl = document.createElement('span');
      prefixEl.classList.add('c', '--first');
      prefixEl.textContent = prefix;
      this.eGui.appendChild(prefixEl);
    }

    const middleEl = document.createTextNode(middle || middle === 0 ? middle : '');
    this.eGui.appendChild(middleEl);

    if (postfix) {
      const postfixEl = document.createElement('span');
      postfixEl.classList.add('c', '--last');
      postfixEl.textContent = postfix;
      this.eGui.appendChild(postfixEl);
    }

  } else {
    const text = document.createTextNode(value);
    this.eGui.appendChild(text);
  }
}


AnimateChangeCellRenderer.prototype.startFlashAnimationClasses = function (eParentOfValue, classToAdd) {
  eParentOfValue.classList.add(classToAdd);
  this.refreshCount++;
  const refreshCountCopy = this.refreshCount;
  window.setTimeout(() => {
    if (refreshCountCopy === this.refreshCount) {
      eParentOfValue.classList.add('ett-flash-animate')
      eParentOfValue.classList.remove(classToAdd);
      window.setTimeout(() => {
        eParentOfValue.classList.remove('ett-flash-animate')
      }, 1000)
    }
  }, 500)
}

// Only flash the cell if the displayValues are different, to avoid sub-decimal changes.
// Compare using regular value, to calculate positive/negative.
AnimateChangeCellRenderer.prototype.refresh = function (params) {
  const value = params.value;
  const displayValue = params.valueFormatted ? params.valueFormatted : params.value;

  // Easier to use context than to switch cell renderers inside colDefs.
  const { cellFlashDisabled } = params?.context;

  if (displayValue === this.lastDisplayValue) {
    return false;
  }

  const guiValue = exists(params.valueFormatted)
    ? params.valueFormatted
    : exists(params.value) ? params.value : null;

  this.buildEGui(guiValue, params);

  if (!cellFlashDisabled && typeof value === 'number' && typeof this.lastValue === 'number') {
    const delta = value - this.lastValue;
    if (delta < 0) {
      this.startFlashAnimationClasses(params.eParentOfValue, 'ett-flash-negative');
    } else if (delta > 0) {
      this.startFlashAnimationClasses(params.eParentOfValue, 'ett-flash-positive');
    }
  }

  this.lastDisplayValue = displayValue;
  this.lastValue = value;

  return true;
};



export default AnimateChangeCellRenderer;
