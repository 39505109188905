import React from 'react';
import Chart from 'react-apexcharts';
import { formatStatCellNumber } from 'src/utils/formatStatCellNumber';
import { useTheme } from '@material-ui/core';

function BarChart({ series, time, height = 375, labelClickCallback = null }) {
  const theme = useTheme();
  const colors = ['#1f87e6', '#ff5c7c', '#EDDEA4', '#F7A072'];

  const options = {
    chart: {
      background: theme.palette.background.paper,
      stacked: false,
      zoom: false,
      toolbar: {
        show: false
      },
      animations: {
        enabled: true,
        easing: 'easeinout',
        speed: 400,
        animateGradually: {
          enabled: true,
          delay: 0
        },
        dynamicAnimation: {
          enabled: true,
          speed: 350
        }
      },
      events: {
        click: (event) => {
          if (labelClickCallback !== null) {
            const el = event.target.parentNode;
            const targetClass = 'apexcharts-xaxis-label';
            if ((' ' + el.className.baseVal + ' ').replace(/[\n\t]/g, ' ').indexOf(` ${targetClass} `) > -1) {
              labelClickCallback(event.target.textContent);
            }
          }
        }
      }
    },
    colors: colors,
    dataLabels: {
      enabled: false
    },
    plotOptions: {
      bar: {
        horizontal: false,
        columnWidth: '55%',
        endingShape: 'flat'
      },
    },
    legend: {
      show: true,
      showForSingleSeries: true,
      position: 'bottom',
      horizontalAlign: 'center',
      itemMargin: {
        vertical: 7,
        horizontal: 7
      },
      labels: {
        colors: theme.palette.text.secondary
      }
    },
    stroke: {
      show: true,
      width: 2,
      colors: ['transparent']
    },
    theme: {
      mode: theme.palette.type
    },
    tooltip: {
      theme: theme.palette.type,
      shared: true,
      intersect: false,
      y: {
        formatter: (val) => (Math.round((val + Number.EPSILON) * 100) / 100) + '%'
      }
    },
    grid: {
      show: true,
      borderColor: theme.palette.chart.gridLines,
      xaxis: {
        lines: {
          show: true
        }
      }
    },
    yaxis: {
      title: {
        text: '% Spike'
      },
      labels: {
        formatter: (val) => formatStatCellNumber(val + '%')
      },
      style: {
        color: theme.palette.text.secondary,
        fontSize: '13px'
      }
    },
    fill: {
      opacity: 1
    },
    xaxis: {
      categories: time,
    },
  };


  return (
    <>
      <Chart
        type="bar"
        height={height}
        options={options}
        series={series}
      />
    </>
  );
}

export default BarChart;
