import produce from 'immer';
/* eslint-disable no-param-reassign */
import { FETCH_NEWS, REQUEST__FETCH_NEWS, FETCH_RSCORE_NEWS, CLEAR_NEWS } from 'src/redux/news/newsActions';

const initialState = {
  data: [],
  ticker: null,
  isFetching: false
}

const newsReducer = (state = initialState, action) => {

  switch (action.type) {

    case FETCH_NEWS: {
      const { ticker, data } = action.payload;
      return produce(state, draft => {
        if (!draft.data.length || draft.ticker !== ticker) {
          draft.data = data;
          draft.ticker = ticker;
        }
        draft.isFetching = false;
      })
    }

    case FETCH_RSCORE_NEWS: {
      const { ticker, data } = action.payload;
      return produce(state, draft => {
        draft.data = data;
        draft.ticker = ticker;
        draft.isFetching = false;
      })
    }

    case CLEAR_NEWS: {
      return {
        data: [],
        ticker: null,
        isFetching: false
      };
    }

    case REQUEST__FETCH_NEWS: {
      return produce(state, draft =>  {
        // draft.data = [];
        // draft.ticker = null;
        draft.isFetching = true;
      })
    }

    default: {
      return state;
    }
  }
};

export default newsReducer;
