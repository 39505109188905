import React, { useMemo } from 'react';
import {
  mapExpressionColumn,
  mapExpression,
  unmapExpression,
  realtime_to_historical
} from 'src/app/slicedForm/ExpressionForm/mapColumns';
import { useListGlobalExpressions, useMakeExpressionPayload } from 'src/redux/expressions/globalExpressionContext';
import { COLUMN_COLUMNS } from './columnDefs';

const mapHistoricalColumnToRealtime = (colName) => {
  return mapExpressionColumn(colName, realtime_to_historical, COLUMN_COLUMNS);
}


/**
 * NOTE: MAPPINGS CURRENTLY UNUSED. See early return.
 * Performs auto-mapping between historical columns and realtime columns, if needed.
 * Allows us to (sometimes) share expressions between different types of forms.
 * 
 * You MUST disptatch using the provided function, or things will break.
 * @returns {Object[], (Object[]) => (Object[])]} - [expressions, makeExpressionAction]
 *
 * useMakeExpressionPayload(expr) => { expressions: expr, context: 'realtime' }
 **/
export default function useHistoricalMappedExpressions(map = false) {
  const expressions = useListGlobalExpressions(); // Select from contextKey
  const makeExpressionPayload = useMakeExpressionPayload();

  return useMemo(() => {
    if (!map) return [expressions, makeExpressionPayload];

    const mappedExpressions = expressions.map(exp => mapExpression(exp, mapHistoricalColumnToRealtime))
    const unmapExpressions = (expressions) => makeExpressionPayload(expressions.map(unmapExpression))

    return [
      mappedExpressions,
      unmapExpressions
    ]
  }, [expressions, useMakeExpressionPayload]);
}
