

/** Order of columns to show */
export const COLUMNS = ['hours', 'minutes', 'seconds'];
/** Options per column */

export const COLUMN_DEFS = {
  hours: {
    disabledCallbackArgs: [],
    getRange: (amPm = false) => amPm ? [1, 12] : [0, 23],

  },
  minutes: {
    disabledCallbackArgs: ['hours'],
    getRange: () => [0, 59]
  },
  seconds: {
    disabledCallbackArgs: ['hours', 'minutes'],
    getRange: () => [0, 59]
  }
};

/**
 * Based on the format string, return an array of the column keys in order
 * @todo assumes largest->smallest order, but this is not always the case
 * @param {string} format
 * @returns {string[]} - column keys
 */
export const getColumnsToShow = (format) => {
  const parts = format.split(':');
  return parts.map((_, i) => COLUMNS[i]);
}

