import { createSelector } from 'reselect';
import _flow from 'lodash/fp/flow';
import _partition from 'lodash/fp/partition';
import orderBy from 'lodash/orderBy';
import _map from 'lodash/fp/map';
import _filter from 'lodash/fp/filter';
import { getActiveProfile, selectActiveProfile } from 'src/app/slicedForm/shared/reducers/profileReducer';
import { DELETE_EXPRESSION } from '../../shared/reducers/expressionReducer';

export const ADD_COLUMN = '@form-column/ADD_COLUMN';
export const DELETE_COLUMN = '@form-column/DELETE_COLUMN';
export const SET_COLUMN_ORDER = '@form-column/SET_COLUMN_ORDER';
export const REORDER_COLUMN = '@form-column/REORDER_COLUMN';

/*
Columns are contained in profiles.
TODO: should we normalize?

{
  activeProfile: 000
  profiles: [
    name: 'my_profile',
    id: 000,
    columns: [
      { column: 'vol' },
      { column: 'chg' },
    ]
  ]
}
*/


/**
 * We have a bit of a problem with stickyIndex.
 * My idea was to order them in the component, but that doesn't guarantee
 * the order will be correct by the time we render the grid. We'd need to 
 * order there too.
 * 
 * So lets order them in the reducer. Means we need colDefs unfortuntely.
 * I think theres still an issue where we can add a column somewhere else,
 * and forget to order it. I'm not sure of the answer.
 * 
 * This also means our predefined profiles have to start out with the right order. Unless
 * we order during serialization.
 * 
 * This algorithm is garbage, but I've tried so many other things
 */
const orderBySticky = (colObjects, colDefs) => {
  return _flow(
    _map(col => colDefs.find(c => c.name === col.column)),
    _filter(Boolean),
    _partition('sticky'),
    ([sticky, regular]) => {
      return [...orderBy(sticky, ['stickyIndex'], ['asc']), ...regular]
    },
    _map(def => colObjects.find(c => c.column === def.name))
  )(colObjects)
};




function columnReducer(draft, action) {
  switch (action.type) {

    /**
     * DUPLICATE ACTION. Handled in expressionReducer and filterReducer too.
     * Need to delete the given expression out of all profiles.
     **/
    case DELETE_EXPRESSION: {
      const expressionName = action.payload;
      draft.profiles.forEach(profile => {
        const idx = profile.columns.findIndex(c => c.column === expressionName)
        if (idx === -1) return;
        profile.columns.splice(idx, 1);
        console.debug('DELETED EXR from slicedForm columnReducer, ', expressionName, profile.name);
        // orderBySticky, lets hope we don't need this
      })
      break;
    }

    case ADD_COLUMN: {

      // We need to sort by sticky, so we need to know the columnDefs
      const { column, colDefs } = action.payload;
      const profile = getActiveProfile(draft);
      if (!profile) break;

      if (profile.columns.some(c => c.column === column.column)) break;
      if (!colDefs.some(c => c.name === column.column)) break;

      profile.columns.push(column);
      // would be better if this was mutable
      profile.columns = orderBySticky(profile.columns, colDefs)
      break;
    }

    case DELETE_COLUMN: {
      const { column, colDefs } = action.payload;
      const profile = getActiveProfile(draft);
      if (!profile) break;

      const idx = profile.columns.findIndex(c => c.column === column.column);
      if (idx === -1) break;

      profile.columns.splice(idx, 1);
      // would be better if this was mutable
      profile.columns = orderBySticky(profile.columns, colDefs)
      break;
    }

    case SET_COLUMN_ORDER: {
      // Set all columns in the order provided
      const profile = getActiveProfile(draft);
      if (!profile) break;

      const colDefs = action.payload;

      profile.columns.sort((a, b) => {
        const aIndex = colDefs.findIndex(d => d.name === a.column);
        const bIndex = colDefs.findIndex(d => d.name === b.column);
        return aIndex - bIndex;
      })

      break;
    }

    default: {
      break;
    }
  }
}

const emptyArray = [];

/**
 * Gets the full column arguments
 * @example >> [{ column: 'vol' }, { column: 'chg', table: 'scanner'}]
 */
export const selectColumns = createSelector(
  [selectActiveProfile],
  (profile) => profile?.columns || emptyArray
)

/**
 * Flattens the columns into a list of column names
 * @example >> ['vol', 'chg']
 */
export const selectColumnsAsList = createSelector(
  [selectActiveProfile],
  (profile) => profile?.columns?.map(({ column }) => column) || emptyArray
)



export default columnReducer;
