import Modal from './Modal';
import Column from './Column';
import Drawer from 'src/app/components/FormModal/Drawer';
import Actions from './Actions';
import Body from './Body';
import Section from './Section';


/**
 * Structural, stateless components for building forms.
 * @module FormModal
 */
const FormModal = Object.assign(Modal, {
  /** @see Actions */
  Actions,
  /** @type Column */
  Column,
  /** @type Drawer */
  Drawer,
  /** @see Body */
  Body,
  /** @see Section */
  Section
});

export default FormModal;

