import { useMemo } from 'react';
import { useColumnDefs } from 'src/app/slicedForm/shared/context/ColumnDefsProvider';

/** @typedef {import('src/app/components/grid/topListScanner/columns/columnDefs').FilterColumnDef} FilterColumnDef */


/**
 * Given a colDef, returns a list of colDefs that are comparable to it.
 * Used when comparing a column to another columnn inside a Form.
 * 
 * comparesTo: is a string[] of paths of length 2, like 'name.pm_v' or 'columnType.largeInteger'.
 * Check that the path exists on a given columnDef, and that the value matches. If so,
 * these columns are comparable.
 * 
 * @param {FilterColumnDef} inputColDef - The column asking to make a comparison
 * @return {FilterColumnDef[]} - The columns thats can be compared
*/
function useComparableColDefs(inputColDef) {
  const allColDefs = useColumnDefs();

  return useMemo(() => {
    let comparesTo = inputColDef?.comparesTo;

    if (!comparesTo) return [];

    comparesTo = Array.isArray(comparesTo) ? comparesTo : [comparesTo];
    comparesTo = comparesTo.map(c => c.split('.'));

    return allColDefs.filter(colDef => {
      if (colDef.name === inputColDef.name) {
        return false;
      }

      for (const [key, val] of comparesTo) {
        if (!key || !val) continue;

        if (!(key in colDef)) continue;

        if (!(colDef[key] === val)) continue;

        return true
      }
      return false;
    });
  }, [inputColDef, allColDefs]);

}


export default useComparableColDefs;
