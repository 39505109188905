import React from 'react';
import PropTypes from 'prop-types'
import {
  Box,
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';


const useStyles = makeStyles(theme => ({
  root: {
    borderTop: props => props.borderTop ? `1px solid ${theme.palette.background.panelBorder}` : 'none',
    borderBottom: props => props.borderBottom ? `1px solid ${theme.palette.background.panelBorder}` : 'none',
    padding: [[7, 5]]
  }
}))


function Actions({
  className,
  display,
  justifyContent,
  borderBottom,
  borderTop,
  children,
}) {
  const classes = useStyles({ borderBottom, borderTop });

  return (
    <Box
      className={clsx(
        className,
        classes.root,
        borderBottom && 'border-bottom',
        borderTop && 'border-top',
      )}
      display={display}
      justifyContent={justifyContent}
    >
      {children}
    </Box>
  )
}

Actions.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  display: PropTypes.string,
  borderTop: PropTypes.bool,
  borderBottom: PropTypes.bool,
  justifyContent: PropTypes.string,
}


Actions.defaultProps = {
  display: "flex",
  justifyContent: "space-between",
}

export default Actions;
