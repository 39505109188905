import React from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  makeStyles
} from '@material-ui/core';
import clsx from "clsx";

const useStyles = makeStyles(() => ({
  root: {
    position: 'relative',
    display: 'flex',
    overflow: 'hidden',
    height: '100%',
    flex: 1,
    padding: 0,
    '&.-showBorder': {
      '& > .FormModal-Column:not(:last-child)': {
        borderRight: '1px solid lightgray',
      }
    }
  }
}));


function Body({
  className,
  showBorders,
  children,
  innerRef
}) {
  const classes = useStyles();

  return (
    <div
      className={clsx(
        className,
        classes.root,
        showBorders && '-showBorders',
        'FormModal-Body'
      )}
      ref={innerRef}
    >
      {children}
    </div>
  )
}


Body.propTypes = {
  className: PropTypes.string,
  showBorders: PropTypes.bool,
  children: PropTypes.node.isRequired,
}


Body.defaultProps = {
  showBorders: true
}

export default Body;
