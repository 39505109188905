import {
  SchemaBuilder,
  validateColDefs
} from 'src/app/slicedForm/shared/schema/schemaBuilder';
import {
  integerToBooleanOptions,
} from 'src/app/slicedForm/FilterForm/definitions/dropdownOptions';
import {
  BASE_SYNONYMS,
} from 'src/app/slicedForm/shared/schema/synonyms';
import {
  ALLOW_NULL,
  DATA_TYPES,
  INPUTS,
  STICKY_OPTIONS,
} from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import { getColumnDefsFor, attachSynonymsToColumns } from 'src/app/components/grid/buildColumns';
import { TOP_LIST_ROLLING_DATES } from 'src/app/components/pickers/definitions/staticRangeDefinitions';
import { integerToBooleanFormatter } from 'src/app/components/grid/gridColumnFunctions'

// TODO: Rename these to match historyComponent.
// Eventually we want to share columns, better for memory.
export const GROUPS = {
  allmetrics: {
    name: 'allmetrics',
    label: 'All Metrics',
  },
  fundamentals: {
    name: 'fundamentals',
    label: 'Fundamentals'
  },
  day0Metrics: {
    name: 'day0Metrics',
    label: 'Day 1 Metrics'
  },
  day1Metrics: {
    name: 'day1Metrics',
    label: 'Day 2 Metrics'
  },
  otherDayMetrics: {
    name: 'otherDayMetrics',
    label: 'Other Day Metrics'
  },
  dailyIndicators: {
    name: 'dailyIndicators',
    label: 'Daily Indicators'
  },
  hourlyMetrics: {
    name: 'hourlyMetrics',
    label: 'Hourly Metrics'
  },
};


const SCHEMA = new SchemaBuilder({ noTimeSlice: true });


export const ALL_COLUMNS = validateColDefs([
  SCHEMA.date({
    name: 'day0_date',
    label: 'D[1] Date',
    formLabel: 'Date Range',
    group: GROUPS.fundamentals.name,
    grid: {
      width: 96,
      pinned: 'left',
      suppressSizeToFit: true,
      suppressAutoSize: true,
    },
    filter: {
      sticky: STICKY_OPTIONS.STICKY,
      stickyIndex: 0,
      label: 'Date Range'
    },
    column: {
      sticky: STICKY_OPTIONS.STICKY,
      stickyIndex: 0,
      label: 'Date'
    },
    dateConfig: {
      disableFuture: true,
      disableWeekend: false,
      disableHoliday: false,
      rollingDates: TOP_LIST_ROLLING_DATES,
      defaultRollingDate: 'rolling_30_days',
      makeInputDates: undefined,
    }
  }),
  // {
  //   name: 'ticker',
  //   label: 'Ticker',
  //   dtype: DATA_TYPES.STRING,
  //   group: GROUPS.fundamentals.name,
  //   filter: {
  //     input: INPUTS.TICKER,
  //     sticky: STICKY_OPTIONS.TOGGLEABLE,
  //     stickyIndex: 1,
  //   },
  //   column: {
  //     sticky: STICKY_OPTIONS.STICKY,
  //     stickyIndex: 1,
  //   },
  // },
  SCHEMA.price({
    name: 'day0_open',
    label: 'D[1] Open',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_high',
    label: 'D[1] High',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_low',
    label: 'D[1] Low',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_close',
    label: 'D[1] Close',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_vol',
    label: 'D[1] Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_vw',
    label: 'D[1] VW',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_range',
    label: 'D[1] Range',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_chg',
    label: 'D[1] Chg',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_chg_pct',
    label: 'D[1] Chg %',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_chg2',
    label: 'D[1] Chg2',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_chg2_pct',
    label: 'D[1] Chg2 %',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_chg3',
    label: 'D[1] Chg3',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_chg3_pct',
    label: 'D[1] Chg3 %',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_chg4',
    label: 'D[1] Chg4',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_chg4_pct',
    label: 'D[1] Chg4 %',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_chg5',
    label: 'D[1] Chg5',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_chg5_pct',
    label: 'D[1] Chg5 %',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_gap',
    label: 'D[1] Gap',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_gap_pct',
    label: 'D[1] Gap %',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_ret_open',
    label: 'D[1] Ret Open',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_ret_open_pct',
    label: 'D[1] Ret Open %',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.multiSelect({
    name: 'day0_open_ssr',
    label: 'D[1] Open SSR',
    group: GROUPS.day0Metrics.name,
    dtype: DATA_TYPES.STRING,
    options: integerToBooleanOptions,
    grid: {
      valueFormatter: integerToBooleanFormatter,
    }
  }),
  SCHEMA.multiSelect({
    name: 'day0_close_ssr',
    label: 'D[1] Close SSR',
    group: GROUPS.day0Metrics.name,
    dtype: DATA_TYPES.STRING,
    options: integerToBooleanOptions,
    grid: {
      valueFormatter: integerToBooleanFormatter
    }
  }),
  SCHEMA.percentage({
    name: 'day0_open_high_pct',
    label: 'D[1] Open High %',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_open_low_pct',
    label: 'D[1] Open Low %',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_high_low_pct',
    label: 'D[1] High Low %',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_close_high_pct',
    label: 'D[1] Close High %',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_close_low_pct',
    label: 'D[1] Close Low %',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_range_close_pct',
    label: 'D[1] Range Close %',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.largePrice({
    name: 'day0_dollar_vol',
    label: 'D[1] Dollar Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.arbitraryNumber({
    name: 'day0_consec_green',
    label: 'D[1] Consec Green',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.arbitraryNumber({
    name: 'day0_consec_red',
    label: 'D[1] Consec Red',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.arbitraryNumber({
    name: 'day0_consec_gap_up',
    label: 'D[1] Consec Gap Up',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.arbitraryNumber({
    name: 'day0_consec_gap_down',
    label: 'D[1] Consec Gap Down',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.date({
    name: 'day_m5_date',
    label: 'D[-5] Date',
    group: GROUPS.otherDayMetrics.name,
    filter: false,
  }),
  SCHEMA.price({
    name: 'day_m5_open',
    label: 'D[-5] Open',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m5_high',
    label: 'D[-5] High',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m5_low',
    label: 'D[-5] Low',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m5_close',
    label: 'D[-5] Close',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day_m5_vol',
    label: 'D[-5] Vol',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m5_vw',
    label: 'D[-5] VW',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m5_range',
    label: 'D[-5] Range',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m5_chg',
    label: 'D[-5] Chg',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m5_chg_pct',
    label: 'D[-5] Chg %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day_m5_gap',
    label: 'D[-5] Gap',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m5_gap_pct',
    label: 'D[-5] Gap %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day_m5_ret_open',
    label: 'D[-5] Ret Open',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m5_ret_open_pct',
    label: 'D[-5] Ret Open %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m5_high_low_pct',
    label: 'D[-5] High Low %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.date({
    name: 'day_m4_date',
    label: 'D[-4] Date',
    group: GROUPS.otherDayMetrics.name,
    filter: false,
  }),
  SCHEMA.price({
    name: 'day_m4_open',
    label: 'D[-4] Open',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m4_high',
    label: 'D[-4] High',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m4_low',
    label: 'D[-4] Low',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m4_close',
    label: 'D[-4] Close',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day_m4_vol',
    label: 'D[-4] Vol',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m4_vw',
    label: 'D[-4] VW',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m4_range',
    label: 'D[-4] Range',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m4_chg',
    label: 'D[-4] Chg',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m4_chg_pct',
    label: 'D[-4] Chg %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day_m4_gap',
    label: 'D[-4] Gap',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m4_gap_pct',
    label: 'D[-4] Gap %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day_m4_ret_open',
    label: 'D[-4] Ret Open',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m4_ret_open_pct',
    label: 'D[-4] Ret Open %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m4_high_low_pct',
    label: 'D[-4] High Low %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.date({
    name: 'day_m3_date',
    label: 'D[-3] Date',
    group: GROUPS.otherDayMetrics.name,
    filter: false,
  }),
  SCHEMA.price({
    name: 'day_m3_open',
    label: 'D[-3] Open',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m3_high',
    label: 'D[-3] High',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m3_low',
    label: 'D[-3] Low',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m3_close',
    label: 'D[-3] Close',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day_m3_vol',
    label: 'D[-3] Vol',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m3_vw',
    label: 'D[-3] VW',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m3_range',
    label: 'D[-3] Range',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m3_chg',
    label: 'D[-3] Chg',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m3_chg_pct',
    label: 'D[-3] Chg %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day_m3_gap',
    label: 'D[-3] Gap',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m3_gap_pct',
    label: 'D[-3] Gap %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day_m3_ret_open',
    label: 'D[-3] Ret Open',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m3_ret_open_pct',
    label: 'D[-3] Ret Open %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m3_high_low_pct',
    label: 'D[-3] High Low %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.date({
    name: 'day_m2_date',
    label: 'D[-2] Date',
    group: GROUPS.otherDayMetrics.name,
    filter: false,
  }),
  SCHEMA.price({
    name: 'day_m2_open',
    label: 'D[-2] Open',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m2_high',
    label: 'D[-2] High',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m2_low',
    label: 'D[-2] Low',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m2_close',
    label: 'D[-2] Close',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day_m2_vol',
    label: 'D[-2] Vol',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m2_vw',
    label: 'D[-2] VW',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m2_range',
    label: 'D[-2] Range',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m2_chg',
    label: 'D[-2] Chg',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m2_chg_pct',
    label: 'D[-2] Chg %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day_m2_gap',
    label: 'D[-2] Gap',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m2_gap_pct',
    label: 'D[-2] Gap %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day_m2_ret_open',
    label: 'D[-2] Ret Open',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m2_ret_open_pct',
    label: 'D[-2] Ret Open %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m2_high_low_pct',
    label: 'D[-2] High Low %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.multiSelect({
    name: 'day_m2_open_ssr',
    label: 'D[-2] Open SSR',
    group: GROUPS.otherDayMetrics.name,
    dtype: DATA_TYPES.STRING,
    options: integerToBooleanOptions,
    grid: {
      valueFormatter: integerToBooleanFormatter,
    }
  }),
  SCHEMA.date({
    name: 'day_m1_date',
    label: 'D[-1] Date',
    group: GROUPS.otherDayMetrics.name,
    filter: false,
  }),
  SCHEMA.price({
    name: 'day_m1_open',
    label: 'D[-1] Open',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m1_high',
    label: 'D[-1] High',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m1_low',
    label: 'D[-1] Low',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m1_close',
    label: 'D[-1] Close',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day_m1_vol',
    label: 'D[-1] Vol',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m1_vw',
    label: 'D[-1] VW',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m1_range',
    label: 'D[-1] Range',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m1_chg',
    label: 'D[-1] Chg',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m1_chg_pct',
    label: 'D[-1] Chg %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day_m1_gap',
    label: 'D[-1] Gap',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m1_gap_pct',
    label: 'D[-1] Gap %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day_m1_ret_open',
    label: 'D[-1] Ret Open',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m1_ret_open_pct',
    label: 'D[-1] Ret Open %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m1_high_low_pct',
    label: 'D[-1] High Low %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.largePrice({
    name: 'day_m1_dollar_vol',
    label: 'D[-1] Dollar Vol',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.multiSelect({
    name: 'day_m1_open_ssr',
    label: 'D[-1] Open SSR',
    group: GROUPS.otherDayMetrics.name,
    dtype: DATA_TYPES.STRING,
    options: integerToBooleanOptions,
    grid: {
      valueFormatter: integerToBooleanFormatter,
    }
  }),
  SCHEMA.date({
    name: 'day1_date',
    label: 'D[2] Date',
    group: GROUPS.day1Metrics.name,
    filter: false,
  }),
  SCHEMA.price({
    name: 'day1_open',
    label: 'D[2] Open',
    group: GROUPS.day1Metrics.name,
  }),
  SCHEMA.price({
    name: 'day1_high',
    label: 'D[2] High',
    group: GROUPS.day1Metrics.name,
  }),
  SCHEMA.price({
    name: 'day1_low',
    label: 'D[2] Low',
    group: GROUPS.day1Metrics.name,
  }),
  SCHEMA.price({
    name: 'day1_close',
    label: 'D[2] Close',
    group: GROUPS.day1Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day1_vol',
    label: 'D[2] Vol',
    group: GROUPS.day1Metrics.name,
  }),
  SCHEMA.price({
    name: 'day1_vw',
    label: 'D[2] VW',
    group: GROUPS.day1Metrics.name,
  }),
  SCHEMA.price({
    name: 'day1_range',
    label: 'D[2] Range',
    group: GROUPS.day1Metrics.name,
  }),
  SCHEMA.price({
    name: 'day1_chg',
    label: 'D[2] Chg',
    group: GROUPS.day1Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_chg_pct',
    label: 'D[2] Chg %',
    group: GROUPS.day1Metrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day1_gap',
    label: 'D[2] Gap',
    group: GROUPS.day1Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_gap_pct',
    label: 'D[2] Gap %',
    group: GROUPS.day1Metrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day1_ret_open',
    label: 'D[2] Ret Open',
    group: GROUPS.day1Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_ret_open_pct',
    label: 'D[2] Ret Open %',
    group: GROUPS.day1Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_high_low_pct',
    label: 'D[2] High Low %',
    group: GROUPS.day1Metrics.name,
    change: true,
  }),
  SCHEMA.largePrice({
    name: 'day1_dollar_vol',
    label: 'D[2] Dollar Vol',
    group: GROUPS.day1Metrics.name,
  }),
  SCHEMA.multiSelect({
    name: 'day1_open_ssr',
    label: 'D[2] Open SSR',
    group: GROUPS.day1Metrics.name,
    dtype: DATA_TYPES.STRING,
    options: integerToBooleanOptions,
    grid: {
      valueFormatter: integerToBooleanFormatter,
    }
  }),
  SCHEMA.date({
    name: 'day2_date',
    label: 'D[3] Date',
    group: GROUPS.otherDayMetrics.name,
    filter: false,
  }),
  SCHEMA.price({
    name: 'day2_open',
    label: 'D[3] Open',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day2_high',
    label: 'D[3] High',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day2_low',
    label: 'D[3] Low',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day2_close',
    label: 'D[3] Close',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day2_vol',
    label: 'D[3] Vol',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day2_vw',
    label: 'D[3] VW',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day2_range',
    label: 'D[3] Range',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day2_chg',
    label: 'D[3] Chg',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day2_chg_pct',
    label: 'D[3] Chg %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day2_gap',
    label: 'D[3] Gap',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day2_gap_pct',
    label: 'D[3] Gap %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day2_ret_open',
    label: 'D[3] Ret Open',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day2_ret_open_pct',
    label: 'D[3] Ret Open %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day2_high_low_pct',
    label: 'D[3] High Low %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.multiSelect({
    name: 'day2_open_ssr',
    label: 'D[3] Open SSR',
    group: GROUPS.otherDayMetrics.name,
    dtype: DATA_TYPES.STRING,
    options: integerToBooleanOptions,
    grid: {
      valueFormatter: integerToBooleanFormatter,
    }
  }),
  SCHEMA.date({
    name: 'day3_date',
    label: 'D[4] Date',
    group: GROUPS.otherDayMetrics.name,
    filter: false,
  }),
  SCHEMA.price({
    name: 'day3_open',
    label: 'D[4] Open',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day3_high',
    label: 'D[4] High',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day3_low',
    label: 'D[4] Low',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day3_close',
    label: 'D[4] Close',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day3_vol',
    label: 'D[4] Vol',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day3_vw',
    label: 'D[4] VW',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day3_range',
    label: 'D[4] Range',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day3_chg',
    label: 'D[4] Chg',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day3_chg_pct',
    label: 'D[4] Chg %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day3_gap',
    label: 'D[4] Gap',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day3_gap_pct',
    label: 'D[4] Gap %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day3_ret_open',
    label: 'D[4] Ret Open',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day3_ret_open_pct',
    label: 'D[4] Ret Open %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day3_high_low_pct',
    label: 'D[4] High Low %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.date({
    name: 'day4_date',
    label: 'D[5] Date',
    group: GROUPS.otherDayMetrics.name,
    filter: false,
  }),
  SCHEMA.price({
    name: 'day4_open',
    label: 'D[5] Open',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day4_high',
    label: 'D[5] High',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day4_low',
    label: 'D[5] Low',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day4_close',
    label: 'D[5] Close',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day4_vol',
    label: 'D[5] Vol',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day4_vw',
    label: 'D[5] VW',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day4_range',
    label: 'D[5] Range',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day4_chg',
    label: 'D[5] Chg',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day4_chg_pct',
    label: 'D[5] Chg %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day4_gap',
    label: 'D[5] Gap',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day4_gap_pct',
    label: 'D[5] Gap %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day4_ret_open',
    label: 'D[5] Ret Open',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day4_ret_open_pct',
    label: 'D[5] Ret Open %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day4_high_low_pct',
    label: 'D[5] High Low %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.date({
    name: 'day5_date',
    label: 'Day 6 Date',
    group: GROUPS.otherDayMetrics.name,
    filter: false,
  }),
  SCHEMA.price({
    name: 'day5_open',
    label: 'Day 6 Open',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day5_high',
    label: 'Day 6 High',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day5_low',
    label: 'Day 6 Low',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day5_close',
    label: 'Day 6 Close',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day5_vol',
    label: 'Day 6 Vol',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day5_vw',
    label: 'Day 6 VW',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day5_range',
    label: 'Day 6 Range',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day5_chg',
    label: 'Day 6 Chg',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day5_chg_pct',
    label: 'Day 6 Chg %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day5_gap',
    label: 'Day 6 Gap',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day5_gap_pct',
    label: 'Day 6 Gap %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day5_ret_open',
    label: 'Day 6 Ret Open',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day5_ret_open_pct',
    label: 'Day 6 Ret Open %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day5_high_low_pct',
    label: 'Day 6 High Low %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_pm_vol',
    label: 'D[1] PM Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_high',
    label: 'D[1] PM High',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_high_time',
    label: 'D[1] PM High Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_pm_high_pct',
    label: 'D[1] PM High %',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_pm_vol_to_hod',
    label: 'D[1] PM Vol To Hod',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_low',
    label: 'D[1] PM Low',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_low_time',
    label: 'D[1] PM Low Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_pm_vol_to_lod',
    label: 'D[1] PM Vol To Lod',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_low_after_high',
    label: 'D[1] PM Low After High',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_low_after_high_time',
    label: 'D[1] PM Low After High Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_low_before_high',
    label: 'D[1] PM Low Before High',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_low_before_high_time',
    label: 'D[1] PM Low Before High Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_high_after_low',
    label: 'D[1] PM High After Low',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_high_after_low_time',
    label: 'D[1] PM High After Low Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_pm_high_before_low',
    label: 'D[1] PM High Before Low',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_high_before_low_time',
    label: 'D[1] PM High Before Low Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_pm_high_m15_vol',
    label: 'D[1] PM High M15 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_pm_high_m10_vol',
    label: 'D[1] PM High M10 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_pm_high_m05_vol',
    label: 'D[1] PM High M05 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_pm_high_p15_vol',
    label: 'D[1] PM High P15 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_pm_high_p10_vol',
    label: 'D[1] PM High P10 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_pm_high_p05_vol',
    label: 'D[1] PM High P05 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_pm_low_m15_vol',
    label: 'D[1] PM Low M15 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_pm_low_m10_vol',
    label: 'D[1] PM Low M10 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_pm_low_m05_vol',
    label: 'D[1] PM Low M05 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_pm_low_p15_vol',
    label: 'D[1] PM Low P15 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_pm_low_p10_vol',
    label: 'D[1] PM Low P10 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_pm_low_p05_vol',
    label: 'D[1] PM Low P05 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_pm_start_time',
    label: 'D[1] PM Start Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_pm_open_range_pct',
    label: 'D[1] PM Open Range %',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_pm_pullback_open_j_pct',
    label: 'D[1] PM Pullback Open %',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_high_time',
    label: 'D[1] High Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_vol_to_hod',
    label: 'D[1] Vol To Hod',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_low_time',
    label: 'D[1] Low Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_vol_to_lod',
    label: 'D[1] Vol To Lod',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_low_after_high',
    label: 'D[1] Low After High',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_low_after_high_time',
    label: 'D[1] Low After High Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_low_before_high',
    label: 'D[1] Low Before High',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_low_before_high_time',
    label: 'D[1] Low Before High Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_high_after_low',
    label: 'D[1] High After Low',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_high_after_low_time',
    label: 'D[1] High After Low Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_high_before_low',
    label: 'D[1] High Before Low',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_high_before_low_time',
    label: 'D[1] High Before Low Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_high_m15_vol',
    label: 'D[1] High M15 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_high_m10_vol',
    label: 'D[1] High M10 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_high_m05_vol',
    label: 'D[1] High M05 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_high_p15_vol',
    label: 'D[1] High P15 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_high_p10_vol',
    label: 'D[1] High P10 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_high_p05_vol',
    label: 'D[1] High P05 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_low_m15_vol',
    label: 'D[1] Low M15 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_low_m10_vol',
    label: 'D[1] Low M10 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_low_m05_vol',
    label: 'D[1] Low M05 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_low_p15_vol',
    label: 'D[1] Low P15 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_low_p10_vol',
    label: 'D[1] Low P10 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_low_p05_vol',
    label: 'D[1] Low P05 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_vol',
    label: 'D[1] AH Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_high',
    label: 'D[1] AH High',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_high_time',
    label: 'D[1] AH High Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_vol_to_hod',
    label: 'D[1] AH Vol To Hod',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_low',
    label: 'D[1] AH Low',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_low_time',
    label: 'D[1] AH Low Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_vol_to_lod',
    label: 'D[1] AH Vol To Lod',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_close',
    label: 'D[1] AH Close',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_chg',
    label: 'D[1] AH Chg',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_ah_chg_pct',
    label: 'D[1] AH Chg %',
    group: GROUPS.day0Metrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_ah_low_after_high',
    label: 'D[1] AH Low After High',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_low_after_high_time',
    label: 'D[1] AH Low After High Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_low_before_high',
    label: 'D[1] AH Low Before High',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_low_before_high_time',
    label: 'D[1] AH Low Before High Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_high_after_low',
    label: 'D[1] AH High After Low',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_high_after_low_time',
    label: 'D[1] AH High After Low Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_ah_high_before_low',
    label: 'D[1] AH High Before Low',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.time({
    name: 'day0_ah_high_before_low_time',
    label: 'D[1] AH High Before Low Time',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_high_m15_vol',
    label: 'D[1] AH High M15 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_high_m10_vol',
    label: 'D[1] AH High M10 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_high_m05_vol',
    label: 'D[1] AH High M05 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_high_p15_vol',
    label: 'D[1] AH High P15 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_high_p10_vol',
    label: 'D[1] AH High P10 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_high_p05_vol',
    label: 'D[1] AH High P05 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_low_m15_vol',
    label: 'D[1] AH Low M15 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_low_m10_vol',
    label: 'D[1] AH Low M10 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_low_m05_vol',
    label: 'D[1] AH Low M05 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_low_p15_vol',
    label: 'D[1] AH Low P15 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_low_p10_vol',
    label: 'D[1] AH Low P10 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_ah_low_p05_vol',
    label: 'D[1] AH Low P05 Vol',
    group: GROUPS.day0Metrics.name,
  }),
  SCHEMA.price({
    name: 'day_m1_ah_high',
    label: 'D[-1] AH High',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.time({
    name: 'day_m1_ah_high_time',
    label: 'D[-1] AH High Time',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m1_ah_low',
    label: 'D[-1] AH Low',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.time({
    name: 'day_m1_ah_low_time',
    label: 'D[-1] AH Low Time',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m1_ah_close',
    label: 'D[-1] AH Close',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day_m1_ah_vol',
    label: 'D[-1] AH Vol',
    group: GROUPS.otherDayMetrics.name,
  }),
  SCHEMA.price({
    name: 'day_m1_ah_chg',
    label: 'D[-1] AH Chg',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day_m1_ah_chg_pct',
    label: 'D[-1] AH Chg %',
    group: GROUPS.otherDayMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day1_pm_high',
    label: 'D[2] PM High',
    group: GROUPS.day1Metrics.name,
  }),
  SCHEMA.time({
    name: 'day1_pm_high_time',
    label: 'D[2] PM High Time',
    group: GROUPS.day1Metrics.name,
  }),
  SCHEMA.percentage({
    name: 'day1_pm_high_pct',
    label: 'D[2] PM High %',
    group: GROUPS.day1Metrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day1_pm_low',
    label: 'D[2] PM Low',
    group: GROUPS.day1Metrics.name,
  }),
  SCHEMA.time({
    name: 'day1_pm_low_time',
    label: 'D[2] PM Low Time',
    group: GROUPS.day1Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day1_pm_vol',
    label: 'D[2] PM Vol',
    group: GROUPS.day1Metrics.name,
  }),
  SCHEMA.percentage({
    name: 'day1_open_high_pct',
    label: 'D[2] Open High %',
    group: GROUPS.day1Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_open_low_pct',
    label: 'D[2] Open Low %',
    group: GROUPS.day1Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_close_high_pct',
    label: 'D[2] Close High %',
    group: GROUPS.day1Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_close_low_pct',
    label: 'D[2] Close Low %',
    group: GROUPS.day1Metrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_range_close_pct',
    label: 'D[2] Range Close %',
    group: GROUPS.day1Metrics.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day1_high_time',
    label: 'D[2] High Time',
    group: GROUPS.day1Metrics.name,
  }),
  SCHEMA.time({
    name: 'day1_low_time',
    label: 'D[2] Low Time',
    group: GROUPS.day1Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day1_vol_to_hod',
    label: 'D[2] Vol To Hod',
    group: GROUPS.day1Metrics.name,
  }),
  SCHEMA.volume({
    name: 'day1_vol_to_lod',
    label: 'D[2] Vol To Lod',
    group: GROUPS.day1Metrics.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_5min_max',
    label: 'D[1] Spike 5Min Max',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_spike_5min_max_pct',
    label: 'D[1] Spike 5Min Max %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_spike_5min_max_time',
    label: 'D[1] Spike 5Min Max Time',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_spike_5min_max_vol',
    label: 'D[1] Spike 5Min Max Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_5min_max_vw',
    label: 'D[1] Spike 5Min Max VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_5min_min',
    label: 'D[1] Drop 5Min Min',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_drop_5min_min_pct',
    label: 'D[1] Drop 5Min Min %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_drop_5min_min_time',
    label: 'D[1] Drop 5Min Min Time',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_drop_5min_min_vol',
    label: 'D[1] Drop 5Min Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_5min_min_vw',
    label: 'D[1] Drop 5Min Min VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_10min_max',
    label: 'D[1] Spike 10Min Max',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_spike_10min_max_pct',
    label: 'D[1] Spike 10Min Max %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_spike_10min_max_time',
    label: 'D[1] Spike 10Min Max Time',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_spike_10min_max_vol',
    label: 'D[1] Spike 10Min Max Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_10min_max_vw',
    label: 'D[1] Spike 10Min Max VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_10min_min',
    label: 'D[1] Drop 10Min Min',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_drop_10min_min_pct',
    label: 'D[1] Drop 10Min Min %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_drop_10min_min_time',
    label: 'D[1] Drop 10Min Min Time',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_drop_10min_min_vol',
    label: 'D[1] Drop 10Min Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_10min_min_vw',
    label: 'D[1] Drop 10Min Min VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_15min_max',
    label: 'D[1] Spike 15Min Max',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_spike_15min_max_pct',
    label: 'D[1] Spike 15Min Max %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_spike_15min_max_time',
    label: 'D[1] Spike 15Min Max Time',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_spike_15min_max_vol',
    label: 'D[1] Spike 15Min Max Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_15min_max_vw',
    label: 'D[1] Spike 15Min Max VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_15min_min',
    label: 'D[1] Drop 15Min Min',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_drop_15min_min_pct',
    label: 'D[1] Drop 15Min Min %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_drop_15min_min_time',
    label: 'D[1] Drop 15Min Min Time',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_drop_15min_min_vol',
    label: 'D[1] Drop 15Min Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_15min_min_vw',
    label: 'D[1] Drop 15Min Min VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_30min_max',
    label: 'D[1] Spike 30Min Max',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_spike_30min_max_pct',
    label: 'D[1] Spike 30Min Max %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_spike_30min_max_time',
    label: 'D[1] Spike 30Min Max Time',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_spike_30min_max_vol',
    label: 'D[1] Spike 30Min Max Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_30min_max_vw',
    label: 'D[1] Spike 30Min Max VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_30min_min',
    label: 'D[1] Drop 30Min Min',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_drop_30min_min_pct',
    label: 'D[1] Drop 30Min Min %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_drop_30min_min_time',
    label: 'D[1] Drop 30Min Min Time',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_drop_30min_min_vol',
    label: 'D[1] Drop 30Min Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_30min_min_vw',
    label: 'D[1] Drop 30Min Min VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_60min_max',
    label: 'D[1] Spike 60Min Max',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_spike_60min_max_pct',
    label: 'D[1] Spike 60Min Max %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_spike_60min_max_time',
    label: 'D[1] Spike 60Min Max Time',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_spike_60min_max_vol',
    label: 'D[1] Spike 60Min Max Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_spike_60min_max_vw',
    label: 'D[1] Spike 60Min Max VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_60min_min',
    label: 'D[1] Drop 60Min Min',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_drop_60min_min_pct',
    label: 'D[1] Drop 60Min Min %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.time({
    name: 'day0_drop_60min_min_time',
    label: 'D[1] Drop 60Min Min Time',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_drop_60min_min_vol',
    label: 'D[1] Drop 60Min Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_drop_60min_min_vw',
    label: 'D[1] Drop 60Min Min VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0430_open',
    label: 'D[1] H 400 430 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0430_high',
    label: 'D[1] H 400 430 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0430_low',
    label: 'D[1] H 400 430 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0430_close',
    label: 'D[1] H 400 430 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0400_0430_vol',
    label: 'D[1] H 400 430 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0430_vw',
    label: 'D[1] H 400 430 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0430_chg',
    label: 'D[1] H 400 430 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0400_0430_chg_pct',
    label: 'D[1] H 400 430 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0500_open',
    label: 'D[1] H 400 500 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0500_high',
    label: 'D[1] H 400 500 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0500_low',
    label: 'D[1] H 400 500 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0500_close',
    label: 'D[1] H 400 500 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0400_0500_vol',
    label: 'D[1] H 400 500 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0500_vw',
    label: 'D[1] H 400 500 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0400_0500_chg',
    label: 'D[1] H 400 500 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0400_0500_chg_pct',
    label: 'D[1] H 400 500 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0430_0500_open',
    label: 'D[1] H 430 500 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0430_0500_high',
    label: 'D[1] H 430 500 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0430_0500_low',
    label: 'D[1] H 430 500 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0430_0500_close',
    label: 'D[1] H 430 500 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0430_0500_vol',
    label: 'D[1] H 430 500 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0430_0500_vw',
    label: 'D[1] H 430 500 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0430_0500_chg',
    label: 'D[1] H 430 500 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0430_0500_chg_pct',
    label: 'D[1] H 430 500 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0530_open',
    label: 'D[1] H 500 530 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0530_high',
    label: 'D[1] H 500 530 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0530_low',
    label: 'D[1] H 500 530 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0530_close',
    label: 'D[1] H 500 530 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0500_0530_vol',
    label: 'D[1] H 500 530 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0530_vw',
    label: 'D[1] H 500 530 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0530_chg',
    label: 'D[1] H 500 530 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0500_0530_chg_pct',
    label: 'D[1] H 500 530 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0600_open',
    label: 'D[1] H 500 600 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0600_high',
    label: 'D[1] H 500 600 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0600_low',
    label: 'D[1] H 500 600 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0600_close',
    label: 'D[1] H 500 600 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0500_0600_vol',
    label: 'D[1] H 500 600 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0600_vw',
    label: 'D[1] H 500 600 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0500_0600_chg',
    label: 'D[1] H 500 600 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0500_0600_chg_pct',
    label: 'D[1] H 500 600 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0530_0600_open',
    label: 'D[1] H 530 600 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0530_0600_high',
    label: 'D[1] H 530 600 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0530_0600_low',
    label: 'D[1] H 530 600 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0530_0600_close',
    label: 'D[1] H 530 600 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0530_0600_vol',
    label: 'D[1] H 530 600 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0530_0600_vw',
    label: 'D[1] H 530 600 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0530_0600_chg',
    label: 'D[1] H 530 600 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0530_0600_chg_pct',
    label: 'D[1] H 530 600 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0630_open',
    label: 'D[1] H 600 630 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0630_high',
    label: 'D[1] H 600 630 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0630_low',
    label: 'D[1] H 600 630 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0630_close',
    label: 'D[1] H 600 630 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0600_0630_vol',
    label: 'D[1] H 600 630 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0630_vw',
    label: 'D[1] H 600 630 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0630_chg',
    label: 'D[1] H 600 630 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0600_0630_chg_pct',
    label: 'D[1] H 600 630 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0700_open',
    label: 'D[1] H 600 700 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0700_high',
    label: 'D[1] H 600 700 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0700_low',
    label: 'D[1] H 600 700 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0700_close',
    label: 'D[1] H 600 700 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0600_0700_vol',
    label: 'D[1] H 600 700 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0700_vw',
    label: 'D[1] H 600 700 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0600_0700_chg',
    label: 'D[1] H 600 700 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0600_0700_chg_pct',
    label: 'D[1] H 600 700 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0630_0700_open',
    label: 'D[1] H 630 700 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0630_0700_high',
    label: 'D[1] H 630 700 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0630_0700_low',
    label: 'D[1] H 630 700 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0630_0700_close',
    label: 'D[1] H 630 700 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0630_0700_vol',
    label: 'D[1] H 630 700 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0630_0700_vw',
    label: 'D[1] H 630 700 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0630_0700_chg',
    label: 'D[1] H 630 700 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0630_0700_chg_pct',
    label: 'D[1] H 630 700 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0730_open',
    label: 'D[1] H 700 730 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0730_high',
    label: 'D[1] H 700 730 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0730_low',
    label: 'D[1] H 700 730 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0730_close',
    label: 'D[1] H 700 730 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0700_0730_vol',
    label: 'D[1] H 700 730 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0730_vw',
    label: 'D[1] H 700 730 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0730_chg',
    label: 'D[1] H 700 730 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0700_0730_chg_pct',
    label: 'D[1] H 700 730 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0800_open',
    label: 'D[1] H 700 800 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0800_high',
    label: 'D[1] H 700 800 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0800_low',
    label: 'D[1] H 700 800 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0800_close',
    label: 'D[1] H 700 800 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0700_0800_vol',
    label: 'D[1] H 700 800 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0800_vw',
    label: 'D[1] H 700 800 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0700_0800_chg',
    label: 'D[1] H 700 800 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0700_0800_chg_pct',
    label: 'D[1] H 700 800 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0730_0800_open',
    label: 'D[1] H 730 800 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0730_0800_high',
    label: 'D[1] H 730 800 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0730_0800_low',
    label: 'D[1] H 730 800 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0730_0800_close',
    label: 'D[1] H 730 800 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0730_0800_vol',
    label: 'D[1] H 730 800 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0730_0800_vw',
    label: 'D[1] H 730 800 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0730_0800_chg',
    label: 'D[1] H 730 800 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0730_0800_chg_pct',
    label: 'D[1] H 730 800 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0830_open',
    label: 'D[1] H 800 830 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0830_high',
    label: 'D[1] H 800 830 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0830_low',
    label: 'D[1] H 800 830 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0830_close',
    label: 'D[1] H 800 830 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0800_0830_vol',
    label: 'D[1] H 800 830 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0830_vw',
    label: 'D[1] H 800 830 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0830_chg',
    label: 'D[1] H 800 830 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0800_0830_chg_pct',
    label: 'D[1] H 800 830 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0900_open',
    label: 'D[1] H 800 900 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0900_high',
    label: 'D[1] H 800 900 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0900_low',
    label: 'D[1] H 800 900 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0900_close',
    label: 'D[1] H 800 900 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0800_0900_vol',
    label: 'D[1] H 800 900 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0900_vw',
    label: 'D[1] H 800 900 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0800_0900_chg',
    label: 'D[1] H 800 900 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0800_0900_chg_pct',
    label: 'D[1] H 800 900 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0830_0900_open',
    label: 'D[1] H 830 900 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0830_0900_high',
    label: 'D[1] H 830 900 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0830_0900_low',
    label: 'D[1] H 830 900 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0830_0900_close',
    label: 'D[1] H 830 900 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0830_0900_vol',
    label: 'D[1] H 830 900 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0830_0900_vw',
    label: 'D[1] H 830 900 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0830_0900_chg',
    label: 'D[1] H 830 900 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0830_0900_chg_pct',
    label: 'D[1] H 830 900 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_0930_open',
    label: 'D[1] H 900 930 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_0930_high',
    label: 'D[1] H 900 930 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_0930_low',
    label: 'D[1] H 900 930 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_0930_close',
    label: 'D[1] H 900 930 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0900_0930_vol',
    label: 'D[1] H 900 930 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_0930_vw',
    label: 'D[1] H 900 930 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_0930_chg',
    label: 'D[1] H 900 930 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0900_0930_chg_pct',
    label: 'D[1] H 900 930 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_1000_open',
    label: 'D[1] H 900 1000 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_1000_high',
    label: 'D[1] H 900 1000 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_1000_low',
    label: 'D[1] H 900 1000 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_1000_close',
    label: 'D[1] H 900 1000 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0900_1000_vol',
    label: 'D[1] H 900 1000 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_1000_vw',
    label: 'D[1] H 900 1000 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0900_1000_chg',
    label: 'D[1] H 900 1000 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0900_1000_chg_pct',
    label: 'D[1] H 900 1000 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_0930_1000_open',
    label: 'D[1] H 930 1000 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0930_1000_high',
    label: 'D[1] H 930 1000 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0930_1000_low',
    label: 'D[1] H 930 1000 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0930_1000_close',
    label: 'D[1] H 930 1000 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_0930_1000_vol',
    label: 'D[1] H 930 1000 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0930_1000_vw',
    label: 'D[1] H 930 1000 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_0930_1000_chg',
    label: 'D[1] H 930 1000 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_0930_1000_chg_pct',
    label: 'D[1] H 930 1000 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1030_open',
    label: 'D[1] H 1000 1030 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1030_high',
    label: 'D[1] H 1000 1030 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1030_low',
    label: 'D[1] H 1000 1030 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1030_close',
    label: 'D[1] H 1000 1030 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1000_1030_vol',
    label: 'D[1] H 1000 1030 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1030_vw',
    label: 'D[1] H 1000 1030 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1030_chg',
    label: 'D[1] H 1000 1030 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1000_1030_chg_pct',
    label: 'D[1] H 1000 1030 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1100_open',
    label: 'D[1] H 1000 1100 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1100_high',
    label: 'D[1] H 1000 1100 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1100_low',
    label: 'D[1] H 1000 1100 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1100_close',
    label: 'D[1] H 1000 1100 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1000_1100_vol',
    label: 'D[1] H 1000 1100 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1100_vw',
    label: 'D[1] H 1000 1100 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1000_1100_chg',
    label: 'D[1] H 1000 1100 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1000_1100_chg_pct',
    label: 'D[1] H 1000 1100 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1030_1100_open',
    label: 'D[1] H 1030 1100 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1030_1100_high',
    label: 'D[1] H 1030 1100 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1030_1100_low',
    label: 'D[1] H 1030 1100 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1030_1100_close',
    label: 'D[1] H 1030 1100 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1030_1100_vol',
    label: 'D[1] H 1030 1100 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1030_1100_vw',
    label: 'D[1] H 1030 1100 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1030_1100_chg',
    label: 'D[1] H 1030 1100 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1030_1100_chg_pct',
    label: 'D[1] H 1030 1100 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1130_open',
    label: 'D[1] H 1100 1130 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1130_high',
    label: 'D[1] H 1100 1130 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1130_low',
    label: 'D[1] H 1100 1130 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1130_close',
    label: 'D[1] H 1100 1130 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1100_1130_vol',
    label: 'D[1] H 1100 1130 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1130_vw',
    label: 'D[1] H 1100 1130 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1130_chg',
    label: 'D[1] H 1100 1130 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1100_1130_chg_pct',
    label: 'D[1] H 1100 1130 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1200_open',
    label: 'D[1] H 1100 1200 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1200_high',
    label: 'D[1] H 1100 1200 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1200_low',
    label: 'D[1] H 1100 1200 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1200_close',
    label: 'D[1] H 1100 1200 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1100_1200_vol',
    label: 'D[1] H 1100 1200 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1200_vw',
    label: 'D[1] H 1100 1200 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1100_1200_chg',
    label: 'D[1] H 1100 1200 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1100_1200_chg_pct',
    label: 'D[1] H 1100 1200 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1130_1200_open',
    label: 'D[1] H 1130 1200 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1130_1200_high',
    label: 'D[1] H 1130 1200 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1130_1200_low',
    label: 'D[1] H 1130 1200 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1130_1200_close',
    label: 'D[1] H 1130 1200 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1130_1200_vol',
    label: 'D[1] H 1130 1200 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1130_1200_vw',
    label: 'D[1] H 1130 1200 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1130_1200_chg',
    label: 'D[1] H 1130 1200 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1130_1200_chg_pct',
    label: 'D[1] H 1130 1200 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1230_open',
    label: 'D[1] H 1200 1230 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1230_high',
    label: 'D[1] H 1200 1230 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1230_low',
    label: 'D[1] H 1200 1230 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1230_close',
    label: 'D[1] H 1200 1230 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1200_1230_vol',
    label: 'D[1] H 1200 1230 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1230_vw',
    label: 'D[1] H 1200 1230 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1230_chg',
    label: 'D[1] H 1200 1230 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1200_1230_chg_pct',
    label: 'D[1] H 1200 1230 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1300_open',
    label: 'D[1] H 1200 1300 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1300_high',
    label: 'D[1] H 1200 1300 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1300_low',
    label: 'D[1] H 1200 1300 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1300_close',
    label: 'D[1] H 1200 1300 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1200_1300_vol',
    label: 'D[1] H 1200 1300 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1300_vw',
    label: 'D[1] H 1200 1300 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1200_1300_chg',
    label: 'D[1] H 1200 1300 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1200_1300_chg_pct',
    label: 'D[1] H 1200 1300 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1230_1300_open',
    label: 'D[1] H 1230 1300 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1230_1300_high',
    label: 'D[1] H 1230 1300 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1230_1300_low',
    label: 'D[1] H 1230 1300 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1230_1300_close',
    label: 'D[1] H 1230 1300 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1230_1300_vol',
    label: 'D[1] H 1230 1300 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1230_1300_vw',
    label: 'D[1] H 1230 1300 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1230_1300_chg',
    label: 'D[1] H 1230 1300 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1230_1300_chg_pct',
    label: 'D[1] H 1230 1300 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1330_open',
    label: 'D[1] H 1300 1330 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1330_high',
    label: 'D[1] H 1300 1330 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1330_low',
    label: 'D[1] H 1300 1330 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1330_close',
    label: 'D[1] H 1300 1330 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1300_1330_vol',
    label: 'D[1] H 1300 1330 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1330_vw',
    label: 'D[1] H 1300 1330 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1330_chg',
    label: 'D[1] H 1300 1330 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1300_1330_chg_pct',
    label: 'D[1] H 1300 1330 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1400_open',
    label: 'D[1] H 1300 1400 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1400_high',
    label: 'D[1] H 1300 1400 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1400_low',
    label: 'D[1] H 1300 1400 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1400_close',
    label: 'D[1] H 1300 1400 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1300_1400_vol',
    label: 'D[1] H 1300 1400 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1400_vw',
    label: 'D[1] H 1300 1400 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1300_1400_chg',
    label: 'D[1] H 1300 1400 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1300_1400_chg_pct',
    label: 'D[1] H 1300 1400 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1330_1400_open',
    label: 'D[1] H 1330 1400 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1330_1400_high',
    label: 'D[1] H 1330 1400 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1330_1400_low',
    label: 'D[1] H 1330 1400 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1330_1400_close',
    label: 'D[1] H 1330 1400 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1330_1400_vol',
    label: 'D[1] H 1330 1400 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1330_1400_vw',
    label: 'D[1] H 1330 1400 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1330_1400_chg',
    label: 'D[1] H 1330 1400 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1330_1400_chg_pct',
    label: 'D[1] H 1330 1400 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1430_open',
    label: 'D[1] H 1400 1430 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1430_high',
    label: 'D[1] H 1400 1430 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1430_low',
    label: 'D[1] H 1400 1430 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1430_close',
    label: 'D[1] H 1400 1430 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1400_1430_vol',
    label: 'D[1] H 1400 1430 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1430_vw',
    label: 'D[1] H 1400 1430 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1430_chg',
    label: 'D[1] H 1400 1430 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1400_1430_chg_pct',
    label: 'D[1] H 1400 1430 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1500_open',
    label: 'D[1] H 1400 1500 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1500_high',
    label: 'D[1] H 1400 1500 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1500_low',
    label: 'D[1] H 1400 1500 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1500_close',
    label: 'D[1] H 1400 1500 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1400_1500_vol',
    label: 'D[1] H 1400 1500 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1500_vw',
    label: 'D[1] H 1400 1500 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1400_1500_chg',
    label: 'D[1] H 1400 1500 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1400_1500_chg_pct',
    label: 'D[1] H 1400 1500 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1430_1500_open',
    label: 'D[1] H 1430 1500 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1430_1500_high',
    label: 'D[1] H 1430 1500 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1430_1500_low',
    label: 'D[1] H 1430 1500 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1430_1500_close',
    label: 'D[1] H 1430 1500 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1430_1500_vol',
    label: 'D[1] H 1430 1500 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1430_1500_vw',
    label: 'D[1] H 1430 1500 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1430_1500_chg',
    label: 'D[1] H 1430 1500 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1430_1500_chg_pct',
    label: 'D[1] H 1430 1500 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1530_open',
    label: 'D[1] H 1500 1530 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1530_high',
    label: 'D[1] H 1500 1530 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1530_low',
    label: 'D[1] H 1500 1530 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1530_close',
    label: 'D[1] H 1500 1530 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1500_1530_vol',
    label: 'D[1] H 1500 1530 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1530_vw',
    label: 'D[1] H 1500 1530 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1530_chg',
    label: 'D[1] H 1500 1530 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1500_1530_chg_pct',
    label: 'D[1] H 1500 1530 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1600_open',
    label: 'D[1] H 1500 1600 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1600_high',
    label: 'D[1] H 1500 1600 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1600_low',
    label: 'D[1] H 1500 1600 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1600_close',
    label: 'D[1] H 1500 1600 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1500_1600_vol',
    label: 'D[1] H 1500 1600 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1600_vw',
    label: 'D[1] H 1500 1600 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1500_1600_chg',
    label: 'D[1] H 1500 1600 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1500_1600_chg_pct',
    label: 'D[1] H 1500 1600 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1530_1600_open',
    label: 'D[1] H 1530 1600 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1530_1600_high',
    label: 'D[1] H 1530 1600 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1530_1600_low',
    label: 'D[1] H 1530 1600 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1530_1600_close',
    label: 'D[1] H 1530 1600 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1530_1600_vol',
    label: 'D[1] H 1530 1600 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1530_1600_vw',
    label: 'D[1] H 1530 1600 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1530_1600_chg',
    label: 'D[1] H 1530 1600 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1530_1600_chg_pct',
    label: 'D[1] H 1530 1600 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1630_open',
    label: 'D[1] H 1600 1630 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1630_high',
    label: 'D[1] H 1600 1630 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1630_low',
    label: 'D[1] H 1600 1630 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1630_close',
    label: 'D[1] H 1600 1630 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1600_1630_vol',
    label: 'D[1] H 1600 1630 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1630_vw',
    label: 'D[1] H 1600 1630 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1630_chg',
    label: 'D[1] H 1600 1630 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1600_1630_chg_pct',
    label: 'D[1] H 1600 1630 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1700_open',
    label: 'D[1] H 1600 1700 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1700_high',
    label: 'D[1] H 1600 1700 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1700_low',
    label: 'D[1] H 1600 1700 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1700_close',
    label: 'D[1] H 1600 1700 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1600_1700_vol',
    label: 'D[1] H 1600 1700 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1700_vw',
    label: 'D[1] H 1600 1700 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1600_1700_chg',
    label: 'D[1] H 1600 1700 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1600_1700_chg_pct',
    label: 'D[1] H 1600 1700 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1630_1700_open',
    label: 'D[1] H 1630 1700 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1630_1700_high',
    label: 'D[1] H 1630 1700 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1630_1700_low',
    label: 'D[1] H 1630 1700 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1630_1700_close',
    label: 'D[1] H 1630 1700 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1630_1700_vol',
    label: 'D[1] H 1630 1700 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1630_1700_vw',
    label: 'D[1] H 1630 1700 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1630_1700_chg',
    label: 'D[1] H 1630 1700 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1630_1700_chg_pct',
    label: 'D[1] H 1630 1700 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1730_open',
    label: 'D[1] H 1700 1730 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1730_high',
    label: 'D[1] H 1700 1730 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1730_low',
    label: 'D[1] H 1700 1730 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1730_close',
    label: 'D[1] H 1700 1730 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1700_1730_vol',
    label: 'D[1] H 1700 1730 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1730_vw',
    label: 'D[1] H 1700 1730 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1730_chg',
    label: 'D[1] H 1700 1730 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1700_1730_chg_pct',
    label: 'D[1] H 1700 1730 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1800_open',
    label: 'D[1] H 1700 1800 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1800_high',
    label: 'D[1] H 1700 1800 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1800_low',
    label: 'D[1] H 1700 1800 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1800_close',
    label: 'D[1] H 1700 1800 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1700_1800_vol',
    label: 'D[1] H 1700 1800 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1800_vw',
    label: 'D[1] H 1700 1800 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1700_1800_chg',
    label: 'D[1] H 1700 1800 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1700_1800_chg_pct',
    label: 'D[1] H 1700 1800 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1730_1800_open',
    label: 'D[1] H 1730 1800 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1730_1800_high',
    label: 'D[1] H 1730 1800 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1730_1800_low',
    label: 'D[1] H 1730 1800 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1730_1800_close',
    label: 'D[1] H 1730 1800 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1730_1800_vol',
    label: 'D[1] H 1730 1800 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1730_1800_vw',
    label: 'D[1] H 1730 1800 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1730_1800_chg',
    label: 'D[1] H 1730 1800 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1730_1800_chg_pct',
    label: 'D[1] H 1730 1800 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1830_open',
    label: 'D[1] H 1800 1830 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1830_high',
    label: 'D[1] H 1800 1830 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1830_low',
    label: 'D[1] H 1800 1830 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1830_close',
    label: 'D[1] H 1800 1830 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1800_1830_vol',
    label: 'D[1] H 1800 1830 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1830_vw',
    label: 'D[1] H 1800 1830 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1830_chg',
    label: 'D[1] H 1800 1830 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1800_1830_chg_pct',
    label: 'D[1] H 1800 1830 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1900_open',
    label: 'D[1] H 1800 1900 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1900_high',
    label: 'D[1] H 1800 1900 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1900_low',
    label: 'D[1] H 1800 1900 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1900_close',
    label: 'D[1] H 1800 1900 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1800_1900_vol',
    label: 'D[1] H 1800 1900 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1900_vw',
    label: 'D[1] H 1800 1900 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1800_1900_chg',
    label: 'D[1] H 1800 1900 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1800_1900_chg_pct',
    label: 'D[1] H 1800 1900 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1830_1900_open',
    label: 'D[1] H 1830 1900 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1830_1900_high',
    label: 'D[1] H 1830 1900 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1830_1900_low',
    label: 'D[1] H 1830 1900 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1830_1900_close',
    label: 'D[1] H 1830 1900 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1830_1900_vol',
    label: 'D[1] H 1830 1900 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1830_1900_vw',
    label: 'D[1] H 1830 1900 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1830_1900_chg',
    label: 'D[1] H 1830 1900 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1830_1900_chg_pct',
    label: 'D[1] H 1830 1900 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_1930_open',
    label: 'D[1] H 1900 1930 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_1930_high',
    label: 'D[1] H 1900 1930 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_1930_low',
    label: 'D[1] H 1900 1930 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_1930_close',
    label: 'D[1] H 1900 1930 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1900_1930_vol',
    label: 'D[1] H 1900 1930 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_1930_vw',
    label: 'D[1] H 1900 1930 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_1930_chg',
    label: 'D[1] H 1900 1930 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1900_1930_chg_pct',
    label: 'D[1] H 1900 1930 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_2000_open',
    label: 'D[1] H 1900 2000 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_2000_high',
    label: 'D[1] H 1900 2000 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_2000_low',
    label: 'D[1] H 1900 2000 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_2000_close',
    label: 'D[1] H 1900 2000 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1900_2000_vol',
    label: 'D[1] H 1900 2000 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_2000_vw',
    label: 'D[1] H 1900 2000 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1900_2000_chg',
    label: 'D[1] H 1900 2000 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1900_2000_chg_pct',
    label: 'D[1] H 1900 2000 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_h_1930_2000_open',
    label: 'D[1] H 1930 2000 Open',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1930_2000_high',
    label: 'D[1] H 1930 2000 High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1930_2000_low',
    label: 'D[1] H 1930 2000 Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1930_2000_close',
    label: 'D[1] H 1930 2000 Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.volume({
    name: 'day0_h_1930_2000_vol',
    label: 'D[1] H 1930 2000 Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1930_2000_vw',
    label: 'D[1] H 1930 2000 VW',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_h_1930_2000_chg',
    label: 'D[1] H 1930 2000 Chg',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day0_h_1930_2000_chg_pct',
    label: 'D[1] H 1930 2000 Chg %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_1min_high',
    label: 'D[1] 1Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_1min_high_pct',
    label: 'D[1] 1Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_1min_low',
    label: 'D[1] 1Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_1min_low_pct',
    label: 'D[1] 1Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_1min_close',
    label: 'D[1] 1Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_1min_close_pct',
    label: 'D[1] 1Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_1min_vol',
    label: 'D[1] 1Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_2min_high',
    label: 'D[1] 2Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_2min_high_pct',
    label: 'D[1] 2Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_2min_low',
    label: 'D[1] 2Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_2min_low_pct',
    label: 'D[1] 2Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_2min_close',
    label: 'D[1] 2Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_2min_close_pct',
    label: 'D[1] 2Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_2min_vol',
    label: 'D[1] 2Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_3min_high',
    label: 'D[1] 3Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_3min_high_pct',
    label: 'D[1] 3Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_3min_low',
    label: 'D[1] 3Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_3min_low_pct',
    label: 'D[1] 3Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_3min_close',
    label: 'D[1] 3Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_3min_close_pct',
    label: 'D[1] 3Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_3min_vol',
    label: 'D[1] 3Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_4min_high',
    label: 'D[1] 4Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_4min_high_pct',
    label: 'D[1] 4Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_4min_low',
    label: 'D[1] 4Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_4min_low_pct',
    label: 'D[1] 4Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_4min_close',
    label: 'D[1] 4Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_4min_close_pct',
    label: 'D[1] 4Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_4min_vol',
    label: 'D[1] 4Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_5min_high',
    label: 'D[1] 5Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_5min_high_pct',
    label: 'D[1] 5Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_5min_low',
    label: 'D[1] 5Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_5min_low_pct',
    label: 'D[1] 5Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_5min_close',
    label: 'D[1] 5Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_5min_close_pct',
    label: 'D[1] 5Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_5min_vol',
    label: 'D[1] 5Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_10min_high',
    label: 'D[1] 10Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_10min_high_pct',
    label: 'D[1] 10Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_10min_low',
    label: 'D[1] 10Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_10min_low_pct',
    label: 'D[1] 10Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_10min_close',
    label: 'D[1] 10Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_10min_close_pct',
    label: 'D[1] 10Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_10min_vol',
    label: 'D[1] 10Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_15min_high',
    label: 'D[1] 15Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_15min_high_pct',
    label: 'D[1] 15Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_15min_low',
    label: 'D[1] 15Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_15min_low_pct',
    label: 'D[1] 15Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_15min_close',
    label: 'D[1] 15Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_15min_close_pct',
    label: 'D[1] 15Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_15min_vol',
    label: 'D[1] 15Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_30min_high',
    label: 'D[1] 30Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_30min_high_pct',
    label: 'D[1] 30Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_30min_low',
    label: 'D[1] 30Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_30min_low_pct',
    label: 'D[1] 30Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_30min_close',
    label: 'D[1] 30Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_30min_close_pct',
    label: 'D[1] 30Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_30min_vol',
    label: 'D[1] 30Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_60min_high',
    label: 'D[1] 60Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_60min_high_pct',
    label: 'D[1] 60Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_60min_low',
    label: 'D[1] 60Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_60min_low_pct',
    label: 'D[1] 60Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_60min_close',
    label: 'D[1] 60Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_60min_close_pct',
    label: 'D[1] 60Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_60min_vol',
    label: 'D[1] 60Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_90min_high',
    label: 'D[1] 90Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_90min_high_pct',
    label: 'D[1] 90Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_90min_low',
    label: 'D[1] 90Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_90min_low_pct',
    label: 'D[1] 90Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_90min_close',
    label: 'D[1] 90Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_90min_close_pct',
    label: 'D[1] 90Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_90min_vol',
    label: 'D[1] 90Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_120min_high',
    label: 'D[1] 120Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_120min_high_pct',
    label: 'D[1] 120Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_120min_low',
    label: 'D[1] 120Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_120min_low_pct',
    label: 'D[1] 120Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_120min_close',
    label: 'D[1] 120Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_120min_close_pct',
    label: 'D[1] 120Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_120min_vol',
    label: 'D[1] 120Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_150min_high',
    label: 'D[1] 150Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_150min_high_pct',
    label: 'D[1] 150Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_150min_low',
    label: 'D[1] 150Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_150min_low_pct',
    label: 'D[1] 150Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_150min_close',
    label: 'D[1] 150Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_150min_close_pct',
    label: 'D[1] 150Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_150min_vol',
    label: 'D[1] 150Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_180min_high',
    label: 'D[1] 180Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_180min_high_pct',
    label: 'D[1] 180Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_180min_low',
    label: 'D[1] 180Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_180min_low_pct',
    label: 'D[1] 180Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_180min_close',
    label: 'D[1] 180Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_180min_close_pct',
    label: 'D[1] 180Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_180min_vol',
    label: 'D[1] 180Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_210min_high',
    label: 'D[1] 210Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_210min_high_pct',
    label: 'D[1] 210Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_210min_low',
    label: 'D[1] 210Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_210min_low_pct',
    label: 'D[1] 210Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_210min_close',
    label: 'D[1] 210Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_210min_close_pct',
    label: 'D[1] 210Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_210min_vol',
    label: 'D[1] 210Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_240min_high',
    label: 'D[1] 240Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_240min_high_pct',
    label: 'D[1] 240Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_240min_low',
    label: 'D[1] 240Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_240min_low_pct',
    label: 'D[1] 240Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_240min_close',
    label: 'D[1] 240Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_240min_close_pct',
    label: 'D[1] 240Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_240min_vol',
    label: 'D[1] 240Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_270min_high',
    label: 'D[1] 270Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_270min_high_pct',
    label: 'D[1] 270Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_270min_low',
    label: 'D[1] 270Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_270min_low_pct',
    label: 'D[1] 270Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_270min_close',
    label: 'D[1] 270Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_270min_close_pct',
    label: 'D[1] 270Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_270min_vol',
    label: 'D[1] 270Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_300min_high',
    label: 'D[1] 300Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_300min_high_pct',
    label: 'D[1] 300Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_300min_low',
    label: 'D[1] 300Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_300min_low_pct',
    label: 'D[1] 300Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_300min_close',
    label: 'D[1] 300Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_300min_close_pct',
    label: 'D[1] 300Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_300min_vol',
    label: 'D[1] 300Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_330min_high',
    label: 'D[1] 330Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_330min_high_pct',
    label: 'D[1] 330Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_330min_low',
    label: 'D[1] 330Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_330min_low_pct',
    label: 'D[1] 330Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_330min_close',
    label: 'D[1] 330Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_330min_close_pct',
    label: 'D[1] 330Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_330min_vol',
    label: 'D[1] 330Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.price({
    name: 'day0_360min_high',
    label: 'D[1] 360Min High',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_360min_high_pct',
    label: 'D[1] 360Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_360min_low',
    label: 'D[1] 360Min Low',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_360min_low_pct',
    label: 'D[1] 360Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_360min_close',
    label: 'D[1] 360Min Close',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day0_360min_close_pct',
    label: 'D[1] 360Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_360min_vol',
    label: 'D[1] 360Min Vol',
    group: GROUPS.hourlyMetrics.name,
  }),
  SCHEMA.percentage({
    name: 'day1_1min_high_pct',
    label: 'D[2] 1Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_1min_low_pct',
    label: 'D[2] 1Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_1min_close_pct',
    label: 'D[2] 1Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_2min_high_pct',
    label: 'D[2] 2Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_2min_low_pct',
    label: 'D[2] 2Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_2min_close_pct',
    label: 'D[2] 2Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_3min_high_pct',
    label: 'D[2] 3Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_3min_low_pct',
    label: 'D[2] 3Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_3min_close_pct',
    label: 'D[2] 3Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_4min_high_pct',
    label: 'D[2] 4Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_4min_low_pct',
    label: 'D[2] 4Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_4min_close_pct',
    label: 'D[2] 4Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_5min_high_pct',
    label: 'D[2] 5Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_5min_low_pct',
    label: 'D[2] 5Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_5min_close_pct',
    label: 'D[2] 5Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_15min_high_pct',
    label: 'D[2] 15Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_15min_low_pct',
    label: 'D[2] 15Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_15min_close_pct',
    label: 'D[2] 15Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_30min_high_pct',
    label: 'D[2] 30Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_30min_low_pct',
    label: 'D[2] 30Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_30min_close_pct',
    label: 'D[2] 30Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_60min_high_pct',
    label: 'D[2] 60Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_60min_low_pct',
    label: 'D[2] 60Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_60min_close_pct',
    label: 'D[2] 60Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_90min_high_pct',
    label: 'D[2] 90Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_90min_low_pct',
    label: 'D[2] 90Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_90min_close_pct',
    label: 'D[2] 90Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_120min_high_pct',
    label: 'D[2] 120Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_120min_low_pct',
    label: 'D[2] 120Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_120min_close_pct',
    label: 'D[2] 120Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_150min_high_pct',
    label: 'D[2] 150Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_150min_low_pct',
    label: 'D[2] 150Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_150min_close_pct',
    label: 'D[2] 150Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_180min_high_pct',
    label: 'D[2] 180Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_180min_low_pct',
    label: 'D[2] 180Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_180min_close_pct',
    label: 'D[2] 180Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_210min_high_pct',
    label: 'D[2] 210Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_210min_low_pct',
    label: 'D[2] 210Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_210min_close_pct',
    label: 'D[2] 210Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_240min_high_pct',
    label: 'D[2] 240Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_240min_low_pct',
    label: 'D[2] 240Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_240min_close_pct',
    label: 'D[2] 240Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_270min_high_pct',
    label: 'D[2] 270Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_270min_low_pct',
    label: 'D[2] 270Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_270min_close_pct',
    label: 'D[2] 270Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_300min_high_pct',
    label: 'D[2] 300Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_300min_low_pct',
    label: 'D[2] 300Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_300min_close_pct',
    label: 'D[2] 300Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_330min_high_pct',
    label: 'D[2] 330Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_330min_low_pct',
    label: 'D[2] 330Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_330min_close_pct',
    label: 'D[2] 330Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_360min_high_pct',
    label: 'D[2] 360Min High %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_360min_low_pct',
    label: 'D[2] 360Min Low %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'day1_360min_close_pct',
    label: 'D[2] 360Min Close %',
    group: GROUPS.hourlyMetrics.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_ema_9',
    label: 'D[1] EMA 9',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.percentage({
    name: 'day0_ema_9_dist_pct',
    label: 'D[1] EMA 9 Dist %',
    group: GROUPS.dailyIndicators.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_ema_21',
    label: 'D[1] EMA 21',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.percentage({
    name: 'day0_ema_21_dist_pct',
    label: 'D[1] EMA 21 Dist %',
    group: GROUPS.dailyIndicators.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_ema_50',
    label: 'D[1] EMA 50',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.percentage({
    name: 'day0_ema_50_dist_pct',
    label: 'D[1] EMA 50 Dist %',
    group: GROUPS.dailyIndicators.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_ema_72',
    label: 'D[1] EMA 72',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.percentage({
    name: 'day0_ema_72_dist_pct',
    label: 'D[1] EMA 72 Dist %',
    group: GROUPS.dailyIndicators.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_ema_89',
    label: 'D[1] EMA 89',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.percentage({
    name: 'day0_ema_89_dist_pct',
    label: 'D[1] EMA 89 Dist %',
    group: GROUPS.dailyIndicators.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_ema_100',
    label: 'D[1] EMA 100',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.percentage({
    name: 'day0_ema_100_dist_pct',
    label: 'D[1] EMA 100 Dist %',
    group: GROUPS.dailyIndicators.name,
    change: true,
  }),
  SCHEMA.price({
    name: 'day0_ema_200',
    label: 'D[1] EMA 200',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.percentage({
    name: 'day0_ema_200_dist_pct',
    label: 'D[1] EMA 200 Dist %',
    group: GROUPS.dailyIndicators.name,
    change: true,
  }),
  SCHEMA.volume({
    name: 'day0_adtv_7',
    label: 'D[1] ADTV 7',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.volume({
    name: 'day0_adtv_14',
    label: 'D[1] ADTV 14',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.volume({
    name: 'day0_adtv_30',
    label: 'D[1] ADTV 30',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.volume({
    name: 'day0_adtv_60',
    label: 'D[1] ADTV 60',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.volume({
    name: 'day0_rvol_5',
    label: 'D[1] RVOL 5',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.price({
    name: 'day0_atr_7',
    label: 'D[1] ATR 7',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.price({
    name: 'day0_atr_14',
    label: 'D[1] ATR 14',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.price({
    name: 'day0_atr_30',
    label: 'D[1] ATR 30',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.price({
    name: 'day0_atr_60',
    label: 'D[1] ATR 60',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.price({
    name: 'day_252_high',
    label: '1 Year High',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.price({
    name: 'day_252_low',
    label: '1 Year Low',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.percentage({
    name: 'day_252_range',
    label: '1 Year Range %',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.price({
    name: 'day_126_high',
    label: '6 Month High',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.price({
    name: 'day_126_low',
    label: '6 Month Low',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.percentage({
    name: 'day_126_range',
    label: '6 Month Range %',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.price({
    name: 'day_63_high',
    label: '3 Month High',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.price({
    name: 'day_63_low',
    label: '3 Month Low',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.percentage({
    name: 'day_63_range',
    label: '3 Month Range %',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.price({
    name: 'day_21_high',
    label: '30 Day High',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.price({
    name: 'day_21_low',
    label: '30 Day Low',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.percentage({
    name: 'day_21_range',
    label: '30 Day Range %',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.price({
    name: 'day_5_high',
    label: '1 Week High',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.price({
    name: 'day_5_low',
    label: '1 Week Low',
    group: GROUPS.dailyIndicators.name,
  }),
  SCHEMA.percentage({
    name: 'day_5_range',
    label: '1 Week Range %',
    group: GROUPS.dailyIndicators.name,
  }),
], GROUPS);


export const GRID_COLUMNS = getColumnDefsFor('grid', ALL_COLUMNS);
export const FILTER_COLUMNS = attachSynonymsToColumns(
  getColumnDefsFor('filter', ALL_COLUMNS),
  BASE_SYNONYMS
);
export const COLUMN_COLUMNS = attachSynonymsToColumns(
  getColumnDefsFor('column', ALL_COLUMNS),
  BASE_SYNONYMS
);


