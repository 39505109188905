import React from 'react';
import FunctionsIcon from '@material-ui/icons/Functions';
import clsx from 'clsx';
import { EXPR_PREFIX } from 'src/redux/expressions/globalExpressionReducer';

/**
 * Render an icon conditionally, based on some column property
 **/
function ColumnIconSelector({ name, floating, small, ...props }) {
  if (name?.startsWith(EXPR_PREFIX)) {
    if (floating) {
      return <FloatingInlineIcon Icon={FunctionsIcon} small={small} />
    } else {
      return <ListItemPrefixIcon Icon={FunctionsIcon} small={small} />
    }
  }
  return null;
}

export default React.memo(ColumnIconSelector);

/**
 * Allows us to place Icon inside a button without changing
 * the buttons height.
 **/
function FloatingInlineIcon({ Icon, small }) {
  return (
    <span className={clsx('floating-icon-cont', small && '--small')}>
      <Icon className="expression-icon-list-item" />
    </span>
  )
}


function ListItemPrefixIcon({ Icon }) {
  return (
    <Icon className={clsx("expression-icon-list-item")} />
  )
}


