import { current, isDraft } from 'immer';
import differ from 'deep-diff';

const repeat = (str, times) => (new Array(times + 1)).join(str);
const resolveState = state => isDraft(state) ? current(state) : state;
const pad = (num, maxLength) => repeat('0', maxLength - num.toString().length) + num;
const formatTime = time => `${pad(time.getHours(), 2)}:${pad(time.getMinutes(), 2)}:${pad(time.getSeconds(), 2)}.${pad(time.getMilliseconds(), 3)}`;


const css = {
  title: () => 'color: inherit;',
  lighter: () => 'color: gray; font-weight: lighter;',
  prevState: () => 'color: #9E9E9E; font-weight: bold;',
  action: () => 'color: #03A9F4; font-weight: bold;',
  nextState: () => 'color: #4CAF50; font-weight: bold;',
  error: () => 'color: #F20404; font-weight: bold;',
}

/**
 * Adds logs to a useReducer reducer object
 */
export default function withReducerLogging(
  reducer,
  name = 'reducer',
  enable = true,
  diff = false
) {

  if (!enable || process.env.NODE_ENV === 'production') {
    return reducer;
  }

  return (prevState, action) => {
    const titleParts = [
      ['%cuseReducer', css.lighter()],
      [`%c[${name}]`, css.title()],
      [`%c${String(action.type)}`, css.title()]
    ];


    const start = new Date();
    const nextState = reducer(prevState, action);
    const took = (+ new Date()) - start;

    titleParts.push(
      [`%c@ ${formatTime(start)}`, css.lighter()],
      [`%c(in ${took.toFixed(2)} ms)`, css.lighter()]
    );


    console.group(...reduceTitleParts(titleParts));
    console.log('%c prev state: ', css.prevState(), resolveState(prevState));
    console.log('%c action:     ', css.action(), action);
    console.log('%c next state: ', css.nextState(), resolveState(nextState));
    diff && diffLogger(prevState, nextState);
    console.groupEnd();

    return nextState;
  }
}


const reduceTitleParts = (titleParts) => {
  let str = '';
  let css = [];
  titleParts.forEach(([s, c]) => {
    str += (s + ' ');
    css.push(c);
  });
  return [str, ...css];
}


// https://github.com/flitbit/diff#differences
const dictionary = {
  E: {
    color: '#2196F3',
    text: 'CHANGED:',
  },
  N: {
    color: '#4CAF50',
    text: 'ADDED:',
  },
  D: {
    color: '#F44336',
    text: 'DELETED:',
  },
  A: {
    color: '#2196F3',
    text: 'ARRAY:',
  },
};

export function diffStyle(kind) {
  return `color: ${dictionary[kind].color}; font-weight: bold`;
}

export function render(diff) {
  const { kind, path, lhs, rhs, index, item } = diff;

  switch (kind) {
    case 'E':
      return [path.join('.'), lhs, '→', rhs];
    case 'N':
      return [path.join('.'), rhs];
    case 'D':
      return [path.join('.')];
    case 'A':
      return [`${path.join('.')}[${index}]`, item];
    default:
      return [];
  }
}

function diffLogger(prevState, newState, isCollapsed = false) {
  const diff = differ(prevState, newState);

  try {
    if (isCollapsed) {
      console.groupCollapsed('diff');
    } else {
      console.group('diff');
    }
  } catch (e) {
    console.log('diff');
  }

  if (diff) {
    diff.forEach((elem) => {
      const { kind } = elem;
      const output = render(elem);

      console.log(`%c ${dictionary[kind].text}`, diffStyle(kind), ...output);
    });
  } else {
    console.log('—— no diff ——');
  }

  try {
    console.groupEnd();
  } catch (e) {
    console.log('—— diff end —— ');
  }
}
