import {
  DATA_TYPES,
  STICKY_OPTIONS,
} from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import {
  ALL_COLUMNS as TOP_LIST_COLUMNS,
  FILTER_COLUMNS as TOP_LIST_FILTER_COLUMNS,
  GROUPS as TOP_LIST_GROUPS,
} from 'src/app/components/grid/topListScanner/columns/columnDefs';
import {
  BASE_SYNONYMS,
} from 'src/app/slicedForm/shared/schema/synonyms';
import { getColumnDefsFor, attachSynonymsToColumns } from 'src/app/components/grid/buildColumns';

const excludeTopListColumns = ['ticker', 'index', 'pr_count', 'non_pr_count', 'total_news_count'];

const flexCenterStyle = { display: 'flex', alignItems: 'center' };
const monoFontCellStyle = { fontFamily: '"Roboto Mono", "Roboto", "Helvetica", "Arial", sans-serif' };
const realtimeDataStyle = { ...flexCenterStyle, ...monoFontCellStyle };


export const GROUPS = TOP_LIST_GROUPS;

const ALL_COLUMNS = [
  {
    name: 'ticker',
    label: 'Ticker',
    dtype: DATA_TYPES.STRING,
    group: GROUPS.GENERAL.name,
    grid: {
      label: '',
      hide: true,
      cellRenderer: 'NewsTickerGroupCellRenderer',
    },
    column: {
      sticky: STICKY_OPTIONS.STICKY,
      stickyIndex: 0,
    },
    filter: null
  },
  {
    name: 'combinedHeadline',
    label: 'Headline',
    dtype: DATA_TYPES.STRING,
    group: GROUPS.GENERAL.name,
    grid: {
      cellRenderer: 'NewsHeadlineCellRenderer',
      flex: 1,
      minWidth: 190,
      autoHeight: true,
      wrapText: true,
    },
    column: {},
    filter: null,
  },
  ...TOP_LIST_COLUMNS
    .filter(x => !excludeTopListColumns.includes(x.name))
    .map(x => ({
      ...x,
      ...(x?.grid ? { grid: { ...x?.grid, width: 60, cellStyle: realtimeDataStyle, sortable: false } } : {})
      // ...(x?.grid ? { grid: { ...x?.grid, width: 60, sortable: false } } : {} )
    }))
]


export const formatSimilarity = (n) => {
  return n === 1
    ? n.toFixed(1)
    : `.${n.toFixed(2).split('.')[1]}`;
};


export const getFullRowNodeData = (rowNode) => {
  // Child articles have the same headline / datetime as their parent, but the node itself is missing that data.
  // We need these for sub-menus.
  if (!rowNode?.parent?.data) {
    return rowNode.data;
  }
  return {
    ...rowNode.parent.data,
    ...rowNode.data
  }
}


const buildRscoreDetailGridColumns = () => {

  const defaults = {
    headerClass: 'ett-header-cell',
    editable: false,
    sortable: false,
    resizable: true,
    suppressMovable: true,
    suppressAutoSize: true,
    suppressSizeToFit: true,
    suppressMenu: true,

  }
  const columns = ['ticker', 'combinedHeadline'];
  return columns.map(name => {
    const c = GRID_COLUMNS.find(x => x.name === name);
    if (c.name === 'ticker') {
      return {
        ...c,
        ...defaults,
        field: 'similarity',
        headerName: ' ',
        width: 55,
        hide: false,
        cellRenderer: undefined,
        suppressHeaderMenuButton: true,
        valueFormatter: (params) => {
          return (params.node.rowIndex === 0)
            ? params?.value
            : formatSimilarity(params?.value);
        },
      };
    }
    return {
      ...c,
      ...defaults
    }
  });
};



// FILTER COLUMNS ARE PULLED DIRECTLY FROM TOP_LIST
// THEY ARE ALSO HARD-CODED INTO news/DataSource, and of course news SlicedForms. I think that is fine.

export const FILTER_COLUMNS = TOP_LIST_FILTER_COLUMNS
export const GRID_COLUMNS = getColumnDefsFor('grid', ALL_COLUMNS);
export const COLUMN_COLUMNS = attachSynonymsToColumns(
  getColumnDefsFor('column', ALL_COLUMNS),
  BASE_SYNONYMS
);

export const RSCORE_OVERLAY_GRID_COLUMNS = buildRscoreDetailGridColumns();
