import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { Calendar } from 'react-date-range';
import {
  isAfter,
  isWeekend,
} from 'date-fns';
import {
  locale,
  isDateHoliday,
  getCurrentTradingDay
} from 'src/utils/datetime/date-fns.tz';
import {
  makeStyles,
  useTheme
} from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {
    '& .rdrDateDisplayWrapper': {
      display: 'none' // Remove if we add range pickers to this single calendar component
    }
  },
}));


/**
 * Thin wrapper for react-date-range Calendar picker component
 * @component
 */
function DatePicker({
  className,
  format,
  date,
  minDate,
  maxDate,
  marketTime,
  disableHoliday,
  disableWeekend,
  disableFuture,
  disabledDay,
  preventSnapRefocus,
  onAccept,
}) {
  const classes = useStyles();
  const theme = useTheme();

  if (maxDate && disableFuture) throw Error('Cannot have both maxDate and disableFuture');
  
  const today = marketTime ? getCurrentTradingDay() : new Date();

  const handleDisableDay = (d) => {
    // TODO: market time?
    if (disableFuture && isAfter(d, today)) return true;
    if (disableHoliday && isDateHoliday(d)) return true;
    if (disableWeekend && isWeekend(d)) return true;
    if (disabledDay && disabledDay(d)) return true;
  };


  return (
    <Calendar
      className={clsx(
        className,
        classes.root,
        { 'prevent-snap-refocus': preventSnapRefocus }
      )}
      date={date}
      color={theme.palette.primary.darker}
      onChange={onAccept}
      minDate={minDate}
      maxDate={disableFuture ? today : maxDate}
      disabledDay={handleDisableDay}
      locale={locale}
      displayMode="date"
      preventSnapRefocus={preventSnapRefocus}
      dragSelectionEnabled={false}
      showPreview={true}
      showSelectionPreview={true}
      showDateDisplay={true}
      dateDisplayFormat={format}
    />
  );
}


DatePicker.propTypes = {
  className: PropTypes.string,
  /** yyyy-MM-dd */
  format: PropTypes.string,
  /** Controlled */
  date: PropTypes.instanceOf(Date),
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  marketTime: PropTypes.bool,
  disableHoliday: PropTypes.bool,
  disableWeekend: PropTypes.bool,
  disableFuture: PropTypes.bool,
  disabledDay: PropTypes.func,
  preventSnapRefocus: PropTypes.bool,
  onAccept: PropTypes.func,
};


DatePicker.defaultProps = {
  disableHoliday: false,
  disableWeekend: false,
  disableFuture: false,
  preventSnapRefocus: false,
  definedDays: [],
};


export default React.memo(DatePicker);
