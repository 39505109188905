import BarBuffer from './BarBuffer';



/**
 * For effective caching, we need a persistent layer in between Components/DataFeeds and BarBuffers. This global singleton
 * defines that behavior.
 *
 * The BarBufferInterface() SIGLETON is responsible for:
 *  - Orchestrating multiple charts/components to connect to the same buffer
 *  - Keeping track of any given chart/component's Cursor. (bar index)
 *  - Resetting a charts cursor when chart refreshes/remounts/layout_loads
 *  - Creating buffers when needed
 *  - Deleting BarBuffers (expiring cache).
 *  - (Maybe) Holding back fetch requests when multiple are coming from different charts at the same time.
 *    - This may be better done within a buffer.
 */
class BarBufferInterface {

  constructor() {
    this.components = {};
    this.buffers = new Map();
  }


  cacheKey(ticker, resolution, historicalDate) {
    return `${ticker}#${resolution}#${historicalDate}`;
  }


  unregisterComponent(componentId) {
    if (!(componentId in this.components)) return;

    // delete the component
    const { key } = this.components[componentId];
    delete this.components[componentId];

    const otherComponentsConnectedToBuffer = Object.keys(this.components).filter(componentId => {
      return this.components[componentId].key === key;
    });

    // delete the buffer if this component was the only one using it
    if (!otherComponentsConnectedToBuffer.length) {
      this.buffers.delete(key);
    }
  }


  resetCursor(componentId) {
    if (!(componentId in this.components)) return;
    this.components[componentId].cursorBarTime = -1;
  }


  /**
   * Get bars out of the specified BarBuffer cache.
   * Bar buffer may need to fetch more itself.
   * @async
   * @param {string} componentId
   * @param {string} ticker
   * @param {string} resolution
   * @param {UnixSeconds} historicalDate
   * @param periodParams
   * @return {Promise<Object[]|boolean>}
   */
  async getBars(componentId, ticker, resolution, historicalDate = 'none', periodParams) {
    const key = this.cacheKey(ticker, resolution, isNaN(historicalDate) ? 'none' : historicalDate);

    if (!(componentId in this.components) || this.components[componentId].key !== key) {
      this.components[componentId] = {
        componentId,
        cursorBarTime: -1, // time at last bar rendered
        key
      };
    }

    if (!this.buffers.has(key)) {
      const isHistorical = historicalDate && !isNaN(historicalDate);
      this.buffers.set(key, new BarBuffer(ticker, resolution, 2000, isHistorical));
    }

    const startBarTime = this.components[componentId].cursorBarTime;
    const barChunk = await this.buffers.get(key).retrieveFromBuffer(periodParams, startBarTime);

    if (!barChunk || !barChunk.length) return false;

    this.components[componentId].cursorBarTime = barChunk[0].time;

    return barChunk;
  }


}

const barBufferInterface = new BarBufferInterface();
export default barBufferInterface;
