import React from 'react';
import clsx from 'clsx';
import ToggleButton from '@material-ui/lab/ToggleButton';
import ToggleButtonGroup from '@material-ui/lab/ToggleButtonGroup';
import {
  alpha,
  makeStyles,
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  toggleButton: {
    fontSize: 13,
    padding: '9px 11px',
    flex: 1,
    color: theme.palette.text.secondary,
    '&.Mui-selected': {
      color: '#fff',
      backgroundColor: alpha(theme.palette.primary.main, .2),
      '&:hover': {
        color: '#fff',
        backgroundColor: alpha(theme.palette.primary.main, .2),
      },
    },
    '&:hover': {
      color: '#fff',
      backgroundColor: alpha(theme.palette.primary.main, .1),
    },
    '& .MuiToggleButton-label': {
      lineHeight: 1,
      whiteSpace: 'nowrap'
    }
  },
}));


function FilterToggleButtons({
  className,
  value,
  onChange,
  ariaLabel,
  disableRipple = false,
  config,
  minButtonWidth,
  exclusive = true,
  disabled = false,
}) {
  const classes = useStyles();

  return (
    <ToggleButtonGroup
      value={value}
      exclusive={exclusive}
      onChange={onChange}
      aria-label={ariaLabel}
      className={clsx(classes.root, className)}
      disabled={disabled}
    >
      {config.map(button => {
        return (
          <ToggleButton
            value={button.value}
            className={classes.toggleButton}
            key={button.value}
            disableRipple={disableRipple}
            disableFocusRipple={disableRipple}
            disabled={button.disabled === true}
            style={minButtonWidth && { minWidth: minButtonWidth }}
          >
            {button.label}
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
}

export default FilterToggleButtons;
