import { renderTickerSSRCell } from 'src/app/components/grid/gridColumnFunctions';
import {
  ALLOW_NULL,
  DATA_TYPES,
  STICKY_OPTIONS,
} from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import {
  integerToBooleanOptions,
  exchangeOptions,
  countryIsoOptions,
  shareGroupOptions,
  industryOptions,
} from 'src/app/slicedForm/FilterForm/definitions/dropdownOptions';
import { countryOptions } from 'src/constants/countryOptions';
import {
  validateColDefs,
  SchemaBuilder,
} from 'src/app/slicedForm/shared/schema/schemaBuilder';
import {
  BASE_SYNONYMS
} from 'src/app/slicedForm/shared/schema/synonyms';
import { getColumnDefsFor, attachSynonymsToColumns } from '../../buildColumns';
import { TOP_LIST_ROLLING_DATES } from 'src/app/components/pickers/definitions/staticRangeDefinitions';
import { integerToBooleanFormatter } from 'src/app/components/grid/gridColumnFunctions'

/** Visual groupings of columns on the frontend */
export const GROUPS = {
  COMMON: {
    label: 'Commonly Used',
    name: 'COMMON'
  },
  DAY0_INTRADAY: {
    label: 'Day 1 Intraday',
    name: 'DAY0_INTRADAY',
  },
  DAY0_PREMARKET: {
    label: 'Day 1 Premarket',
    name: 'DAY0_PREMARKET',
  },
  DAY0_AFTERHOURS: {
    label: 'Day 1 Afterhours',
    name: 'DAY0_AFTERHOURS',
  },
  RANGES: {
    label: 'Ranges',
    name: 'RANGES'
  },
  INDICATORS: {
    label: 'Indicators',
    name: 'INDICATORS'
  },
  PREV_DAY: {
    label: 'Previous Days',
    name: 'PREV_DAY'
  },
  FINANCIALS: {
    label: 'Financials',
    name: 'FINANCIALS'
  },
  SHARE_STRUCTURE: {
    label: 'Share Structure',
    name: 'SHARE_STRUCTURE'
  },
  COMPANY: {
    label: 'Company',
    name: 'COMPANY'
  },
  GENERAL: {
    label: 'General',
    name: 'GENERAL'
  },
  SECRET: {
    label: 'Secret',
    name: 'SECRET',
    secret: true
  }
};


const SCHEMA = new SchemaBuilder();


// don't use if you need additional props on filter: {}
const noTimeSlice = { filter: { timeSlice: false } };


/**
 * @type {Array.<ColumnDef>}
 **/
export const ALL_COLUMNS = validateColDefs([
  {
    name: 'index',
    label: 'Index',
    dtype: DATA_TYPES.INT,
    group: GROUPS.GENERAL.name,
    grid: {
      label: '',
      width: 30,
      minWidth: 30,
      maxWidth: 30,
      pinned: 'left',
      className: 'index-cell row-drag-cell',
      valueGetter: ({ node }) => node.rowIndex + 1,
      resizable: false,
      suppressSizeToFit: true,
      suppressAutoSize: true,
    },
    filter: false,
    column: {
      sticky: STICKY_OPTIONS.TOGGLEABLE,
      stickyIndex: 0,
      noDefinition: true,
    },
  },
  {
    name: 'ticker',
    label: 'Ticker',
    dtype: DATA_TYPES.STRING,
    group: GROUPS.COMPANY.name,
    grid: {
      width: 73,
      pinned: 'left',
      className: 'ticker-cell',
      highlightOnRowHover: true,
      hasActiveIndicator: true,
      suppressSizeToFit: true,
      suppressAutoSize: true,
      resizable: true,
      cellRenderer: renderTickerSSRCell
    },
    filter: false,
    column: {
      sticky: STICKY_OPTIONS.STICKY,
      stickyIndex: 1,
      noDefinition: true,
    },
  },

  SCHEMA.price({
    name: 'session_chg',
    label: 'Chg',
    group: [GROUPS.DAY0_INTRADAY.name, GROUPS.COMMON.name],
    change: true,
  }),
  SCHEMA.percentage({
    name: 'session_chg_p',
    label: 'Chg %',
    group: [GROUPS.DAY0_INTRADAY.name, GROUPS.COMMON.name],
    change: true,
  }),
  SCHEMA.price({
    name: 'session_lp',
    label: 'Price',
    group: [GROUPS.DAY0_INTRADAY.name, GROUPS.COMMON.name],
    grid: {
      cellRenderer: 'animateChangeCellRenderer',
      cellRendererParams: {
        compact: true,
        align: 'right'
      }
    }
  }),
  SCHEMA.volume({
    name: 'session_v',
    label: 'Vol',
    group: [GROUPS.DAY0_INTRADAY.name, GROUPS.COMMON.name],
  }),
  SCHEMA.arbitraryNumber({
    name: 'rvol',
    label: 'RVOL',
    dtype: DATA_TYPES.FLOAT,
    group: [GROUPS.DAY0_INTRADAY.name, GROUPS.COMMON.name],
    grid: {
      sortable: true,
      zeroValueFormat: '-',
    },
    filter: {
      timeSlice: true,
    },
  }),
  SCHEMA.multiSelect({
    name: 'halt',
    label: 'Halt',
    dtype: DATA_TYPES.STRING,
    group: GROUPS.DAY0_INTRADAY.name,
    options: integerToBooleanOptions,
    column: false,
    grid: {
      valueFormatter: integerToBooleanFormatter
    }
  }),
  SCHEMA.largePrice({
    name: 'dollar_vol',
    label: 'Dollar Vol',
    group: [GROUPS.DAY0_INTRADAY.name, GROUPS.COMMON.name],
    filter: {
      allowNull: ALLOW_NULL.FALSE,
    }
  }),
  SCHEMA.largeInteger({
    name: 'trades',
    label: 'Trades',
    group: [GROUPS.DAY0_INTRADAY.name, GROUPS.COMMON.name],
    filter: {
      timeSlice: true,
      allowNull: ALLOW_NULL.FALSE
    }
  }),
  SCHEMA.price({
    name: 'range',
    label: 'Range',
    group: GROUPS.DAY0_INTRADAY.name,
  }),
  SCHEMA.percentage({
    name: 'range_pct',
    label: 'Pos Rng %',
    formLabel: 'Position in Range %',
    group: [GROUPS.DAY0_INTRADAY.name, GROUPS.RANGES.name],
  }),
  SCHEMA.price({
    name: 'o_chg',
    label: 'Open Chg',
    group: GROUPS.DAY0_INTRADAY.name,
    change: true
  }),
  SCHEMA.percentage({
    name: 'o_chg_p',
    label: 'Open Chg %',
    group: GROUPS.DAY0_INTRADAY.name,
    change: true
  }),
  SCHEMA.largePrice({
    name: 'marketcap',
    label: 'Market Cap',
    group: GROUPS.SHARE_STRUCTURE.name
  }),
  SCHEMA.largeInteger({
    name: 'sharesoutstanding',
    label: 'Shares Out',
    group: GROUPS.SHARE_STRUCTURE.name,
  }),
  SCHEMA.largeInteger({
    name: 'sharesfloat',
    label: 'Float',
    group: GROUPS.SHARE_STRUCTURE.name
  }),
  SCHEMA.percentage({
    name: 'percentinsiders',
    label: 'Insider Own %',
    group: GROUPS.SHARE_STRUCTURE.name,
    filter: {
      timeSlice: false,
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true,
    },
  }),
  SCHEMA.percentage({
    name: 'percentinstitutions',
    label: 'Inst. Own %',
    group: GROUPS.SHARE_STRUCTURE.name,
    filter: {
      timeSlice: false,
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true,
    },
  }),
  SCHEMA.percentage({
    name: 'shortpercentfloat',
    label: 'Short Float',
    group: GROUPS.SHARE_STRUCTURE.name,
    filter: {
      timeSlice: false,
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true,
    }
  }),
  SCHEMA.arbitraryNumber({
    name: 'floatrotation',
    label: 'Float Rotation',
    group: GROUPS.SHARE_STRUCTURE.name,
    dtype: DATA_TYPES.FLOAT,
    grid: {
      type: 'numericColumn',
      sortable: true,
    },
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    }
  }),
  SCHEMA.arbitraryNumber({
    name: 'pr_count',
    label: 'PR',
    formLabel: 'PR (# of press releases)',
    dtype: DATA_TYPES.INT,
    group: GROUPS.GENERAL.name,
    grid: {
      type: 'numericColumn',
      sortable: true,
      zeroValueFormat: ' ',
    },
    filter: {
      comparesTo: ['name.non_pr_count'],
    }
  }),
  SCHEMA.arbitraryNumber({
    name: 'non_pr_count',
    label: 'Non-PR',
    formLabel: 'Non-PR (# of non-press releases)',
    dtype: DATA_TYPES.INT,
    group: GROUPS.GENERAL.name,
    grid: {
      type: 'numericColumn',
      sortable: true,
      zeroValueFormat: ' ',
    },
    filter: {
      comparesTo: ['name.pr_count'],
    }
  }),
  SCHEMA.arbitraryNumber({
    name: 'total_news_count',
    label: 'News',
    formLabel: 'News (# of total news)',
    dtype: DATA_TYPES.INT,
    group: GROUPS.GENERAL.name,
    grid: {
      type: 'numericColumn',
      sortable: true,
      zeroValueFormat: ' ',
    }
  }),
  SCHEMA.percentage({
    name: 'lp_5_chg_p',
    label: '5 Min Chg %',
    group: GROUPS.DAY0_INTRADAY.name,
    change: true,
    filter: {
      timeSlice: false
    }
  }),
  SCHEMA.percentage({
    name: 'lp_10_chg_p',
    label: '10 Min Chg %',
    group: GROUPS.DAY0_INTRADAY.name,
    change: true,
    filter: {
      timeSlice: false
    }
  }),
  SCHEMA.percentage({
    name: 'lp_30_chg_p',
    label: '30 Min Chg %',
    group: GROUPS.DAY0_INTRADAY.name,
    change: true,
    filter: {
      timeSlice: false
    }
  }),
  SCHEMA.percentage({
    name: 'chg_h_o_pct',
    label: 'Open High %',
    formLabel: 'Open to High %',
    group: GROUPS.DAY0_INTRADAY.name,
    change: true,
  }),
  SCHEMA.percentage({
    name: 'chg_h_pc_pct',
    label: 'High %',
    group: GROUPS.DAY0_INTRADAY.name,
    change: true
  }),
  SCHEMA.percentage({
    name: 'chg_l_o_pct',
    label: 'Open Low %',
    formLabel: 'Open to Low %',
    group: GROUPS.DAY0_INTRADAY.name,
    change: true
  }),
  SCHEMA.percentage({
    name: 'chg_l_pc_pct',
    label: 'Low %',
    change: true,
    group: GROUPS.DAY0_INTRADAY.name
  }),
  SCHEMA.price({
    name: 'pm_lp',
    label: 'PM Price',
    group: GROUPS.DAY0_PREMARKET.name,
  }),
  SCHEMA.price({
    name: 'pm_chg',
    label: 'PM Gap',
    group: [GROUPS.DAY0_PREMARKET.name, GROUPS.COMMON.name],
    change: true
  }),
  SCHEMA.percentage({
    name: 'pm_chg_p',
    label: 'PM Gap %',
    group: [GROUPS.DAY0_PREMARKET.name, GROUPS.COMMON.name],
    change: true,
  }),
  SCHEMA.price({
    name: 'pm_h',
    label: 'PM High',
    group: GROUPS.DAY0_PREMARKET.name,
    change: true
  }),
  SCHEMA.price({
    name: 'pm_l',
    label: 'PM Low',
    group: GROUPS.DAY0_PREMARKET.name,
    change: true
  }),
  SCHEMA.volume({
    name: 'pm_v',
    label: 'PM Vol',
    group: [GROUPS.DAY0_PREMARKET.name, GROUPS.COMMON.name]
  }),
  SCHEMA.price({
    name: 'pm_range',
    label: 'PM Rng',
    formLabel: 'PM Range',
    group: GROUPS.DAY0_PREMARKET.name,
  }),
  SCHEMA.percentage({
    name: 'pm_range_pct',
    label: 'Pos PM Rng %',
    formLabel: 'Position in PM Range %',
    group: GROUPS.DAY0_PREMARKET.name
  }),
  SCHEMA.percentage({
    name: 'chg_pm_h_pc_pct',
    label: 'PM High %',
    group: GROUPS.DAY0_PREMARKET.name,
    change: true
  }),
  SCHEMA.percentage({
    name: 'chg_pm_l_pc_pct',
    label: 'PM Low %',
    group: GROUPS.DAY0_PREMARKET.name,
    change: true
  }),
  SCHEMA.volume({
    name: 'ext_hours_v',
    label: 'Ext Hours Vol',
    group: GROUPS.DAY0_PREMARKET.name,
  }),
  SCHEMA.price({
    name: 'ah_chg',
    label: 'AH Chg',
    group: GROUPS.DAY0_AFTERHOURS.name,
    change: true
  }),
  SCHEMA.percentage({
    name: 'ah_chg_p',
    label: 'AH Chg %',
    group: GROUPS.DAY0_AFTERHOURS.name,
    change: true
  }),
  SCHEMA.price({
    name: 'ah_h',
    label: 'AH High',
    group: GROUPS.DAY0_AFTERHOURS.name
  }),
  SCHEMA.price({
    name: 'ah_l',
    label: 'AH Low',
    group: GROUPS.DAY0_AFTERHOURS.name
  }),
  SCHEMA.volume({
    name: 'ah_v',
    label: 'AH Vol',
    group: GROUPS.DAY0_AFTERHOURS.name
  }),
  SCHEMA.price({
    name: 'ah_range',
    label: 'AH Rng',
    formLabel: 'AH Range',
    group: GROUPS.DAY0_AFTERHOURS.name
  }),
  SCHEMA.percentage({
    name: 'ah_range_pct',
    label: 'Pos AH Rng %',
    formLabel: 'Position in AH Range %',
    group: GROUPS.DAY0_AFTERHOURS.name
  }),
  SCHEMA.percentage({
    name: 'chg_ah_h_pc_pct',
    label: 'AH High %',
    group: GROUPS.DAY0_AFTERHOURS.name,
    change: true
  }),
  SCHEMA.percentage({
    name: 'chg_ah_l_pc_pct',
    label: 'AH Low %',
    group: GROUPS.DAY0_AFTERHOURS.name,
    change: true
  }),
  SCHEMA.price({
    name: 'o',
    label: 'Open',
    group: GROUPS.DAY0_INTRADAY.name,
  }),
  SCHEMA.price({
    name: 'h',
    label: 'High',
    group: GROUPS.DAY0_INTRADAY.name,
  }),
  SCHEMA.price({
    name: 'l',
    label: 'Low',
    group: GROUPS.DAY0_INTRADAY.name,
  }),
  SCHEMA.price({
    name: 'c',
    label: 'Close',
    group: GROUPS.DAY0_INTRADAY.name,
  }),
  SCHEMA.multiSelect({
    name: 'ssr',
    label: 'SSR',
    dtype: DATA_TYPES.STRING,
    group: GROUPS.DAY0_INTRADAY.name,
    options: integerToBooleanOptions,
    grid: {
      valueFormatter: integerToBooleanFormatter
    }
  }),
  SCHEMA.price({
    name: 'vw',
    label: 'VWAP',
    group: GROUPS.DAY0_INTRADAY.name,
  }),
  SCHEMA.percentage({
    name: 'chg_allsession_h_pc_pct',
    label: 'All Sess High %',
    group: [GROUPS.DAY0_INTRADAY.name, GROUPS.DAY0_PREMARKET.name, GROUPS.DAY0_AFTERHOURS.name],
    change: true
  }),
  SCHEMA.percentage({
    name: 'chg_allsession_l_pc_pct',
    label: 'All Sess Low %',
    group: [GROUPS.DAY0_INTRADAY.name, GROUPS.DAY0_PREMARKET.name, GROUPS.DAY0_AFTERHOURS.name],
    change: true
  }),
  SCHEMA.percentage({
    name: 'chg_allsession_range_pct',
    label: 'Pos Rng All Sess %',
    group: [GROUPS.DAY0_INTRADAY.name, GROUPS.DAY0_PREMARKET.name, GROUPS.DAY0_AFTERHOURS.name],
  }),
  SCHEMA.percentage({
    name: 'chg_allday_h_pc_pct',
    label: 'All Day High %',
    group: [GROUPS.DAY0_INTRADAY.name, GROUPS.DAY0_PREMARKET.name, GROUPS.DAY0_AFTERHOURS.name],
    change: true
  }),
  SCHEMA.percentage({
    name: 'chg_allday_l_pc_pct',
    label: 'All Day Low %',
    group: [GROUPS.DAY0_INTRADAY.name, GROUPS.DAY0_PREMARKET.name, GROUPS.DAY0_AFTERHOURS.name],
    change: true
  }),
  SCHEMA.percentage({
    name: 'chg_allday_range_pct',
    label: 'Pos Rng All Day %',
    group: [GROUPS.DAY0_INTRADAY.name, GROUPS.DAY0_PREMARKET.name, GROUPS.DAY0_AFTERHOURS.name],
  }),

  //******* GROUP:COMPANY *******//

  SCHEMA.multiSelect({
    name: 'country_iso',
    label: 'Country Code',
    dtype: DATA_TYPES.STRING,
    group: GROUPS.COMPANY.name,
    options: countryIsoOptions
  }),
  SCHEMA.multiSelect({
    name: 'country',
    label: 'Country',
    dtype: DATA_TYPES.STRING,
    group: GROUPS.COMPANY.name,
    options: countryOptions
  }),
  SCHEMA.multiSelect({
    name: 'share_group',
    label: 'Share Group',
    dtype: DATA_TYPES.STRING,
    group: GROUPS.COMPANY.name,
    options: shareGroupOptions,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    }
  }),
  SCHEMA.multiSelect({
    name: 'exchange',
    label: 'Exchange',
    dtype: DATA_TYPES.STRING,
    group: GROUPS.COMPANY.name,
    options: exchangeOptions,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    }
  }),
  SCHEMA.multiSelect({
    name: 'industry',
    label: 'Industry',
    dtype: DATA_TYPES.STRING,
    group: GROUPS.COMPANY.name,
    options: industryOptions,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    }
  }),
  SCHEMA.multiSelect({
    name: 'sector',
    label: 'Sector',
    dtype: DATA_TYPES.STRING,
    group: GROUPS.COMPANY.name,
    options: industryOptions,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    }
  }),
  SCHEMA.date({
    name: 'ipo_date',
    label: 'IPO Date',
    group: GROUPS.COMPANY.name,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    },
    dateConfig: {
      disableFuture: true,
      disableWeekend: false,
      disableHoliday: false,
      rollingDates: TOP_LIST_ROLLING_DATES,
      defaultRollingDate: 'rolling_30_days',
      makeInputDates: undefined,
    }
  }),

  //******* GROUP:FINANCIALS *******//

  SCHEMA.largePrice({
    name: 'cashandcashequivalents',
    label: 'Cash & Equiv',
    group: GROUPS.FINANCIALS.name,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    }
  }),
  SCHEMA.largePrice({
    name: 'cashcashequivalentsandshortterminvestments',
    label: 'Short Term Cash',
    group: GROUPS.FINANCIALS.name,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    }
  }),
  SCHEMA.largePrice({
    name: 'currentassets',
    label: 'Current Assets',
    group: GROUPS.FINANCIALS.name,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    }
  }),
  SCHEMA.largePrice({
    name: 'currentliabilities',
    label: 'Current Liabilities',
    group: GROUPS.FINANCIALS.name,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    }
  }),
  SCHEMA.arbitraryNumber({
    name: 'currentratio',
    label: 'Current Ratio',
    group: GROUPS.FINANCIALS.name,
    dtype: DATA_TYPES.FLOAT,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    },
  }),
  SCHEMA.price({
    name: 'dilutedeps',
    label: 'Diluted EPS',
    group: GROUPS.FINANCIALS.name,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    }
  }),
  SCHEMA.price({
    name: 'eps',
    label: 'EPS',
    group: GROUPS.FINANCIALS.name,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    }
  }),
  SCHEMA.percentage({
    name: 'eps_growth_q',
    label: 'EPS QoQ %',
    group: GROUPS.FINANCIALS.name,
    filter: {
      label: 'EPS QoQ Growth %',
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    },
    column: {
      label: 'EPS QoQ Growth %'
    }
  }),
  SCHEMA.largePrice({
    name: 'grossmargin',
    label: 'Gross Margin',
    group: GROUPS.FINANCIALS.name,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    },
  }),
  SCHEMA.largePrice({
    name: 'netincome',
    label: 'Net Income',
    group: GROUPS.FINANCIALS.name,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    },
  }),
  SCHEMA.largePrice({
    name: 'netmargin',
    label: 'Net Margin',
    group: GROUPS.FINANCIALS.name,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    },
  }),
  SCHEMA.arbitraryNumber({
    name: 'earnings_diff',
    label: 'Earnings Date ∆',
    dtype: DATA_TYPES.FLOAT,
    group: GROUPS.FINANCIALS.name,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    },
  }),
  SCHEMA.largePrice({
    name: 'revenue',
    label: 'Revenue',
    group: GROUPS.FINANCIALS.name,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    }
  }),
  SCHEMA.percentage({
    name: 'revenue_growth_q',
    label: 'Revenue QoQ %',
    group: GROUPS.FINANCIALS.name,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    }
  }),
  // SCHEMA.arbitraryNumber({
  //   name: 'peratio',
  //   label: 'PE Ratio',
  //   dtype: DATA_TYPES.FLOAT,
  //   group: GROUPS.FINANCIALS.name,
  //   filter: {
  //     allowNull: ALLOW_NULL.USER_CONTROLLED,
  //     allowNullDefault: true
  //   },
  // }),
  SCHEMA.percentage({
    name: 'roa',
    label: 'ROA',
    group: GROUPS.FINANCIALS.name,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    }
  }),
  SCHEMA.percentage({
    name: 'roe',
    label: 'ROE',
    group: GROUPS.FINANCIALS.name,
    filter: {
      allowNull: ALLOW_NULL.USER_CONTROLLED,
      allowNullDefault: true
    }
  }),

  //******* GROUP:PREV_DAY *******//

  ...day_minus_columns(1),

  SCHEMA.largeInteger({
    name: 'day_m1_trades',
    label: 'D[-1] Trades',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice
  }),
  SCHEMA.percentage({
    name: 'day_m1_range_close_pct',
    label: 'D[-1] Pos Rng %',
    formLabel: 'D[-1] Position in Range %',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'day_m1_close_high_pct',
    label: 'D[-1] High %',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'day_m1_close_low_pct',
    label: 'D[-1] Low %',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'day_m1_open_high_pct',
    label: 'D[-1] Open High %',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'day_m1_open_low_pct',
    label: 'D[-1] Open Low %',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.multiSelect({
    name: 'day_m1_close_ssr',
    label: 'D[-1] SSR',
    group: GROUPS.PREV_DAY.name,
    options: integerToBooleanOptions,
    ...noTimeSlice,
    grid: {
      valueFormatter: integerToBooleanFormatter
    }
  }),
  SCHEMA.arbitraryNumber({
    name: 'day_m1_rvol_5',
    label: 'D[-1] RVOL 5',
    dtype: DATA_TYPES.FLOAT,
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.largePrice({
    name: 'day_m1_dollar_vol',
    label: 'D[-1] Dollar Vol',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.arbitraryNumber({
    name: 'day_m1_consec_green',
    label: 'Consec. Green',
    dtype: DATA_TYPES.INT,
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.arbitraryNumber({
    name: 'day_m1_consec_red',
    label: 'Consec. Red',
    dtype: DATA_TYPES.INT,
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.arbitraryNumber({
    name: 'day_m1_consec_gap_up',
    label: 'Consec. Gap Up',
    dtype: DATA_TYPES.INT,
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.arbitraryNumber({
    name: 'day_m1_consec_gap_down',
    label: 'Consec. Gap Down',
    dtype: DATA_TYPES.INT,
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),

  SCHEMA.price({
    name: 'day_m1_pm_low',
    label: 'D[-1] PM Low',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_pm_high',
    label: 'D[-1] PM High',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.volume({
    name: 'day_m1_pm_vol',
    label: 'D[-1] PM Vol',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_pm_range',
    label: 'D[-1] PM Rng',
    formLabel: 'D[-1] PM Range',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'day_m1_pm_low_pct',
    label: 'D[-1] PM Low %',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'day_m1_pm_high_pct',
    label: 'D[-1] PM High %',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'day_m1_pm_open_range_pct',
    label: 'D[-1] Pos PM Rng %',
    formLabel: 'D[-1] Position in PM Range %',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_ah_high',
    label: 'D[-1] AH High',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_ah_low',
    label: 'D[-1] AH Low',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_ah_close',
    label: 'D[-1] PM close',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.volume({
    name: 'day_m1_ah_vol',
    label: 'D[-1] AH Vol',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_ah_chg',
    label: 'D[-1] AH Chg',
    change: true,
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'day_m1_ah_chg_pct',
    label: 'D[-1] AH Chg %',
    change: true,
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_ah_range',
    label: 'D[-1] AH Rng',
    formLabel: 'D[-1] AH Range',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'day_m1_ah_range_pct',
    label: 'D[-1] Pos AH Rng %',
    formLabel: 'D[-1] Position in AH Range %',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'day_m1_ah_low_pct',
    label: 'D[-1] AH Low %',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'day_m1_ah_high_pct',
    label: 'D[-1] AH High %',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),

  // UPDATES: M1_H
  SCHEMA.price({
    name: 'day_m1_h_0930_1000_high',
    label: 'D[-1] H 09:30 - 10:00 High',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_h_0930_1000_low',
    label: 'D[-1] H 09:30 - 10:00 Low',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_h_1000_1100_high',
    label: 'D[-1] H 10:00 - 11:00 High',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_h_1000_1100_low',
    label: 'D[-1] H 10:00 - 11:00 Low',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_h_1100_1200_high',
    label: 'D[-1] H 11:00 - 12:00 High',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_h_1100_1200_low',
    label: 'D[-1] H 11:00 - 12:00 Low',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_h_1200_1300_high',
    label: 'D[-1] H 12:00 - 13:00 High',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_h_1200_1300_low',
    label: 'D[-1] H 12:00 - 13:00 Low',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_h_1300_1400_high',
    label: 'D[-1] H 13:00 - 14:00 High',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_h_1300_1400_low',
    label: 'D[-1] H 13:00 - 14:00 Low',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_h_1400_1500_high',
    label: 'D[-1] H 14:00 - 15:00 High',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_h_1400_1500_low',
    label: 'D[-1] H 14:00 - 15:00 Low',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_h_1500_1600_high',
    label: 'D[-1] H 15:00 - 16:00 High',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_h_1500_1600_low',
    label: 'D[-1] H 15:00 - 16:00 Low',
    group: GROUPS.PREV_DAY.name,
    ...noTimeSlice,
  }),

  ...day_minus_columns(2),

  ...day_minus_columns(3),

  ...day_minus_columns(4),

  ...day_minus_columns(5),

  //******* GROUP:RANGES *******//

  SCHEMA.percentage({
    name: 'pc_2_chg_p',
    label: '2 Day Chg %',
    change: true,
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'pc_2_chg',
    label: '2 Day Chg',
    change: true,
    group: GROUPS.RANGES.name
  }),
  SCHEMA.percentage({
    name: 'pc_3_chg_p',
    label: '3 Day Chg %',
    change: true,
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'pc_3_chg',
    label: '3 Day Chg',
    change: true,
    group: GROUPS.RANGES.name
  }),
  SCHEMA.percentage({
    name: 'pc_4_chg_p',
    label: '4 Day Chg %',
    change: true,
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'pc_4_chg',
    label: '4 Day Chg',
    change: true,
    group: GROUPS.RANGES.name
  }),
  SCHEMA.percentage({
    name: 'pc_5_chg_p',
    label: '5 Day Chg %',
    change: true,
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'pc_5_chg',
    label: '5 Day Chg',
    change: true,
    group: GROUPS.RANGES.name
  }),
  SCHEMA.percentage({
    name: 'day_1_range_pct',
    label: 'Pos 1 Day Rng %',
    formLabel: 'Position in Range % - 1 Day',
    group: GROUPS.RANGES.name,
  }),
  SCHEMA.percentage({
    name: 'day_2_range_pct',
    label: 'Pos 2 Day Rng %',
    formLabel: 'Position in Range % - 2 Day',
    group: GROUPS.RANGES.name,
  }),
  SCHEMA.percentage({
    name: 'day_3_range_pct',
    label: 'Pos 3 Day Rng %',
    formLabel: 'Position in Range % - 3 Day',
    group: GROUPS.RANGES.name,
  }),
  SCHEMA.percentage({
    name: 'day_4_range_pct',
    label: 'Pos 4 Day Rng %',
    formLabel: 'Position in Range % - 4 Day',
    group: GROUPS.RANGES.name,
  }),
  SCHEMA.percentage({
    name: 'day_5_range_pct',
    label: 'Pos 5 Day Rng %',
    formLabel: 'Position in Range % - 5 Day',
    group: GROUPS.RANGES.name,
  }),
  SCHEMA.percentage({
    name: 'day_10_range_pct',
    label: 'Pos 10 Day Rng %',
    formLabel: 'Position in Range % - 10 Day',
    group: GROUPS.RANGES.name,
  }),
  SCHEMA.percentage({
    name: 'day_21_range_pct',
    label: 'Pos 1 Mo Day Rng %',
    formLabel: 'Position in Range % - 1 Month',
    group: GROUPS.RANGES.name,
  }),
  SCHEMA.percentage({
    name: 'day_63_range_pct',
    label: 'Pos 3 Mo Day Rng %',
    formLabel: 'Position in Range % - 3 Month',
    group: GROUPS.RANGES.name,
  }),
  SCHEMA.percentage({
    name: 'day_126_range_pct',
    label: 'Pos 6 Mo Day Rng %',
    formLabel: 'Position in Range % - 6 Month',
    group: GROUPS.RANGES.name,
  }),
  SCHEMA.percentage({
    name: 'day_252_range_pct',
    label: 'Pos 1 Yr Rng %',
    formLabel: 'Position in Range % - 1 Year',
    group: GROUPS.RANGES.name,
  }),
  SCHEMA.percentage({
    name: 'day_ytd_range_pct',
    label: 'Pos YTD Rng %',
    formLabel: 'Position in Range % - YTD',
    group: GROUPS.RANGES.name,
  }),
  SCHEMA.percentage({
    name: 'day_21_high_pct',
    label: '1 Mo High %',
    group: GROUPS.RANGES.name,
    ...noTimeSlice
  }),
  SCHEMA.percentage({
    name: 'day_63_high_pct',
    label: '3 Mo High %',
    group: GROUPS.RANGES.name,
    ...noTimeSlice
  }),
  SCHEMA.percentage({
    name: 'day_126_high_pct',
    label: '6 Mo High %',
    group: GROUPS.RANGES.name,
    ...noTimeSlice
  }),
  SCHEMA.percentage({
    name: 'day_252_high_pct',
    label: '1 Yr High %',
    group: GROUPS.RANGES.name,
    ...noTimeSlice
  }),
  SCHEMA.percentage({
    name: 'day_21_low_pct',
    label: '1 Mo Low %',
    group: GROUPS.RANGES.name,
    ...noTimeSlice
  }),
  SCHEMA.percentage({
    name: 'day_63_low_pct',
    label: '3 Mo Low %',
    group: GROUPS.RANGES.name,
    ...noTimeSlice
  }),
  SCHEMA.percentage({
    name: 'day_126_low_pct',
    label: '6 Mo Low %',
    group: GROUPS.RANGES.name,
    ...noTimeSlice
  }),
  SCHEMA.percentage({
    name: 'day_252_low_pct',
    label: '1 Yr Low %',
    group: GROUPS.RANGES.name,
    ...noTimeSlice
  }),

  // UPDATES: DAY_HIGH_$

  SCHEMA.price({
    name: 'day_2_high',
    label: '2 Day High',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_3_high',
    label: '3 Day High',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_4_high',
    label: '4 Day High',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_5_high',
    label: '5 Day High',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_10_high',
    label: '10 Day High',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_21_high',
    label: '1 Mo High',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_63_high',
    label: '3 Mo High',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_126_high',
    label: '6 Mo High',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_252_high',
    label: '1 Year High',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_ytd_high',
    label: 'YTD High',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_2_low',
    label: '2 Day Low',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_3_low',
    label: '3 Day Low',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_4_low',
    label: '4 Day Low',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_5_low',
    label: '5 Day Low',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_10_low',
    label: '10 Day Low',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_21_low',
    label: '1 Mo Low',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_63_low',
    label: '3 Mo Low',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_126_low',
    label: '6 Mo Low',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_252_low',
    label: '1 Year Low',
    group: GROUPS.RANGES.name
  }),
  SCHEMA.price({
    name: 'day_ytd_low',
    label: 'YTD Low',
    group: GROUPS.RANGES.name
  }),

  //******* GROUP:INDICATORS *******//
  SCHEMA.price({
    name: 'day_m1_atr_7',
    label: 'ATR7',
    group: GROUPS.INDICATORS.name,
  }),
  SCHEMA.price({
    name: 'day_m1_atr_14',
    label: 'ATR14',
    group: GROUPS.INDICATORS.name,
  }),
  SCHEMA.price({
    name: 'day_m1_atr_30',
    label: 'ATR30',
    group: GROUPS.INDICATORS.name,
  }),
  SCHEMA.price({
    name: 'day_m1_atr_60',
    label: 'ATR60',
    group: GROUPS.INDICATORS.name,
  }),
  SCHEMA.percentage({
    name: 'day_m1_atr_14_pct',
    label: 'ATR14 %',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice
  }),
  SCHEMA.percentage({
    name: 'day_m2_atr_14_pct',
    label: 'D[-2] ATR14 %',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice
  }),
  SCHEMA.percentage({
    name: 'day_m3_atr_14_pct',
    label: 'D[-3] ATR14 %',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice
  }),
  SCHEMA.volume({
    name: 'day_m1_adtv_7',
    label: 'ADTV7',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.volume({
    name: 'day_m1_adtv_14',
    label: 'ADTV14',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.volume({
    name: 'day_m1_adtv_30',
    label: 'ADTV30',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.volume({
    name: 'day_m1_adtv_60',
    label: 'ADTV60',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),

  SCHEMA.price({
    name: 'day_m1_pp_pivot',
    label: 'Pivot Point',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_pp_r1',
    label: 'Pivot R1',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_pp_r2',
    label: 'Pivot R2',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_pp_r3',
    label: 'Pivot R3',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_pp_s1',
    label: 'Pivot S1',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_pp_s2',
    label: 'Pivot S2',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_pp_s3',
    label: 'Pivot S3',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'pp_pivot_pct',
    label: 'Dist Pivot %',
    group: GROUPS.INDICATORS.name,
  }),
  SCHEMA.percentage({
    name: 'pp_r1_pct',
    label: 'Dist Pivot R1 %',
    group: GROUPS.INDICATORS.name,
  }),
  SCHEMA.percentage({
    name: 'pp_r2_pct',
    label: 'Dist Pivot R2 %',
    group: GROUPS.INDICATORS.name,
  }),
  SCHEMA.percentage({
    name: 'pp_r3_pct',
    label: 'Dist Pivot R3 %',
    group: GROUPS.INDICATORS.name,
  }),
  SCHEMA.percentage({
    name: 'pp_s1_pct',
    label: 'Dist Pivot S1 %',
    group: GROUPS.INDICATORS.name,
  }),
  SCHEMA.percentage({
    name: 'pp_s2_pct',
    label: 'Dist Pivot S2 %',
    group: GROUPS.INDICATORS.name,
  }),
  SCHEMA.percentage({
    name: 'pp_s3_pct',
    label: 'Dist Pivot S3 %',
    group: GROUPS.INDICATORS.name,
  }),

  // UPDATES: EMA_SMA_PP
  SCHEMA.price({
    name: 'day_m1_ema_9',
    label: 'D[-1] EMA9',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_ema_21',
    label: 'D[-1] EMA21',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_ema_50',
    label: 'D[-1] EMA50',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_ema_72',
    label: 'D[-1] EMA72',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_ema_89',
    label: 'D[-1] EMA89',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_ema_100',
    label: 'D[-1] EMA100',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_ema_200',
    label: 'D[-1] EMA200',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_sma_9',
    label: 'D[-1] SMA9',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_sma_21',
    label: 'D[-1] SMA21',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_sma_50',
    label: 'D[-1] SMA50',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_sma_72',
    label: 'D[-1] SMA72',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_sma_89',
    label: 'D[-1] SMA89',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_sma_100',
    label: 'D[-1] SMA100',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m1_sma_200',
    label: 'D[-1] SMA200',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m2_pp_pivot',
    label: 'D[-1] Pivot Point',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),
  SCHEMA.price({
    name: 'day_m3_pp_pivot',
    label: 'D[-2] Pivot Point',
    group: GROUPS.INDICATORS.name,
    ...noTimeSlice,
  }),


  SCHEMA.percentage({
    name: 'ema_9_pct',
    label: 'EMA9 Chg %',
    group: GROUPS.INDICATORS.name,
    change: true,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'ema_21_pct',
    label: 'EMA21 Chg %',
    group: GROUPS.INDICATORS.name,
    change: true,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'ema_50_pct',
    label: 'EMA50 Chg %',
    group: GROUPS.INDICATORS.name,
    change: true,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'ema_72_pct',
    label: 'EMA72 Chg %',
    group: GROUPS.INDICATORS.name,
    change: true,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'ema_89_pct',
    label: 'EMA89 Chg %',
    group: GROUPS.INDICATORS.name,
    change: true,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'ema_100_pct',
    label: 'EMA100 Chg %',
    group: GROUPS.INDICATORS.name,
    change: true,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'ema_200_pct',
    label: 'EMA200 Chg %',
    group: GROUPS.INDICATORS.name,
    change: true,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'sma_9_pct',
    label: 'SMA9 Chg %',
    group: GROUPS.INDICATORS.name,
    change: true,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'sma_21_pct',
    label: 'SMA21 Chg %',
    group: GROUPS.INDICATORS.name,
    change: true,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'sma_50_pct',
    label: 'SMA50 Chg %',
    group: GROUPS.INDICATORS.name,
    change: true,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'sma_72_pct',
    label: 'SMA72 Chg %',
    group: GROUPS.INDICATORS.name,
    change: true,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'sma_89_pct',
    label: 'SMA89 Chg %',
    group: GROUPS.INDICATORS.name,
    change: true,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'sma_100_pct',
    label: 'SMA100 Chg %',
    group: GROUPS.INDICATORS.name,
    change: true,
    ...noTimeSlice,
  }),
  SCHEMA.percentage({
    name: 'sma_200_pct',
    label: 'SMA200 Chg %',
    group: GROUPS.INDICATORS.name,
    change: true,
    ...noTimeSlice,
  }),

  ...mv_test_columns()

], GROUPS);


// Will be revealed by typing 'secret:test_columns' into metric filter search
function mv_test_columns() {
  return [
    SCHEMA.secretArbitraryNumber({
      name: 'col1',
      label: 'col1',
      group: GROUPS.SECRET.name,
    }),
    SCHEMA.secretArbitraryNumber({
      name: 'col2',
      label: 'col2',
      group: GROUPS.SECRET.name,
    }),
    SCHEMA.secretArbitraryNumber({
      name: 'col3',
      label: 'col3',
      group: GROUPS.SECRET.name,
    }),
    SCHEMA.secretArbitraryNumber({
      name: 'col4',
      label: 'col4',
      group: GROUPS.SECRET.name,
    }),
    SCHEMA.secretArbitraryNumber({
      name: 'col5',
      label: 'col5',
      group: GROUPS.SECRET.name,
    }),
    SCHEMA.secretArbitraryNumber({
      name: 'col6',
      label: 'col6',
      group: GROUPS.SECRET.name,
    }),
    SCHEMA.secretArbitraryNumber({
      name: 'col7',
      label: 'col7',
      group: GROUPS.SECRET.name,
    }),
    SCHEMA.secretArbitraryNumber({
      name: 'col8',
      label: 'col8',
      group: GROUPS.SECRET.name,
    }),
    SCHEMA.secretArbitraryNumber({
      name: 'col9',
      label: 'col9',
      group: GROUPS.SECRET.name,
    }),
    SCHEMA.secretArbitraryNumber({
      name: 'col10',
      label: 'col10',
      group: GROUPS.SECRET.name,
    }),
  ]
}


function day_minus_columns(num) {
  return [
    SCHEMA.price({
      name: `day_m${num}_open`,
      label: `D[-${num}] Open`,
      group: GROUPS.PREV_DAY.name,
      ...noTimeSlice,
    }),
    SCHEMA.price({
      name: `day_m${num}_high`,
      label: `D[-${num}] High`,
      group: GROUPS.PREV_DAY.name,
      ...noTimeSlice,
    }),
    SCHEMA.price({
      name: `day_m${num}_low`,
      label: `D[-${num}] Low`,
      group: GROUPS.PREV_DAY.name,
      ...noTimeSlice,
    }),
    SCHEMA.price({
      name: `day_m${num}_close`,
      label: `D[-${num}] Close`,
      group: GROUPS.PREV_DAY.name,
      ...noTimeSlice,
    }),
    SCHEMA.volume({
      name: `day_m${num}_vol`,
      label: `D[-${num}] Vol`,
      group: GROUPS.PREV_DAY.name,
      ...noTimeSlice,
    }),
    SCHEMA.price({
      name: `day_m${num}_vw`,
      label: `D[-${num}] VW`,
      group: GROUPS.PREV_DAY.name,
      ...noTimeSlice,
    }),
    SCHEMA.price({
      name: `day_m${num}_range`,
      label: `D[-${num}] Rng`,
      formLabel: `D[-${num}] Range`,
      group: GROUPS.PREV_DAY.name,
      ...noTimeSlice,
    }),
    SCHEMA.price({
      name: `day_m${num}_chg`,
      label: `D[-${num}] Chg`,
      change: true,
      group: GROUPS.PREV_DAY.name,
      ...noTimeSlice,
    }),
    SCHEMA.percentage({
      name: `day_m${num}_chg_pct`,
      label: `D[-${num}] Chg %`,
      change: true,
      group: GROUPS.PREV_DAY.name,
      ...noTimeSlice,
    }),
    SCHEMA.price({
      name: `day_m${num}_gap`,
      label: `D[-${num}] Gap`,
      group: GROUPS.PREV_DAY.name,
      ...noTimeSlice,
    }),
    SCHEMA.percentage({
      name: `day_m${num}_gap_pct`,
      label: `D[-${num}] Gap %`,
      group: GROUPS.PREV_DAY.name,
      ...noTimeSlice,
    }),
    SCHEMA.price({
      name: `day_m${num}_ret_open`,
      label: `D[-${num}] Open Chg`,
      change: true,
      group: GROUPS.PREV_DAY.name,
      ...noTimeSlice
    }),
    SCHEMA.price({
      name: `day_m${num}_ret_open_pct`,
      label: `D[-${num}] Open Chg %`,
      change: true,
      group: GROUPS.PREV_DAY.name,
      ...noTimeSlice
    }),
  ]
}



export const GRID_COLUMNS = getColumnDefsFor('grid', ALL_COLUMNS);
export const FILTER_COLUMNS = attachSynonymsToColumns(
  getColumnDefsFor('filter', ALL_COLUMNS),
  BASE_SYNONYMS
);
export const COLUMN_COLUMNS = attachSynonymsToColumns(
  getColumnDefsFor('column', ALL_COLUMNS),
  BASE_SYNONYMS
);


const trackerDefaults = {
  sortable: false,
  suppressMovable: true,
  suppressMenu: true,
  enableCellChangeFlash: false,
  editable: false,
  flex: 1
};

export const TRACKER_COLUMNS = [
  { ...trackerDefaults, field: 'timestamp', maxWidth: 96, minWidth: 68 },
  { ...trackerDefaults, field: 'ticker', maxWidth: 80, minWidth: 50 },
  { ...trackerDefaults, field: 'action' },
];
