import { COMPS } from 'src/app/HistoryPage/labels';
import { PREDEF_PREFIX } from 'src/redux/profileSettings/profileSettingsConfig';


// use the predef__ prefix to identify which profile pool to look in.
// Cannot be edited by user.
// Will be added to the profiles[] property when user data is fetched in actions.
// Will be removed when the profile is saved in actions.
export const predefinedHistoryTopBarProfiles = [
  {
    id: `${PREDEF_PREFIX}recent-gappers`,
    profileName: 'Recent Gappers',
    orderby: 'day0_date',
    order: 'desc',
    activeFilters: [
      { name: 'day0_date', comp: COMPS.BT, compType: 'val', selected: '30_day' },
      { name: 'day0_open', comp: COMPS.BT, compType: 'val', val: '1', val2: '15' },
      { name: 'day0_gap_pct', comp: COMPS.GE, compType: 'val', val: '20' },
      { name: 'day0_vol', comp: COMPS.GE, compType: 'val', val: '2000000' },
      { name: 'share_group', comp: COMPS.EQ, compType: 'val', selected: 'CS' }
    ]
  },
  {
    id: `${PREDEF_PREFIX}day-2-plays`,
    profileName: 'Day 2 Plays',
    orderby: 'day0_date',
    order: 'desc',
    activeFilters: [
      { name: 'day0_date', comp: COMPS.BT, compType: 'val', selected: '30_day' },
      { name: 'day0_open', comp: COMPS.BT, compType: 'val', val: '1', val2: '15' },
      { name: 'day0_vol', comp: COMPS.GE, compType: 'val', val: '1000000' },
      { name: 'day_m1_vol', comp: COMPS.GE, compType: 'val', val: '2000000' },
      { name: 'day_m1_gap_pct', comp: COMPS.GE, compType: 'val', val: '40' },
      { name: 'day_m1_chg_pct', comp: COMPS.GE, compType: 'val', val: '20' },
      { name: 'share_group', comp: COMPS.EQ, compType: 'val', selected: 'CS' }
    ]
  },
  {
    id: `${PREDEF_PREFIX}high-gappers`,
    profileName: 'High Gappers',
    orderby: 'day0_date',
    order: 'desc',
    activeFilters: [
      { name: 'day0_date', comp: COMPS.BT, compType: 'val', selected: '60_day' },
      { name: 'day0_open', comp: COMPS.BT, compType: 'val', val: '1', val2: '15' },
      { name: 'day0_gap_pct', comp: COMPS.GE, compType: 'val', val: '50' },
      { name: 'day0_vol', comp: COMPS.GE, compType: 'val', val: '2000000' },
      { name: 'share_group', comp: COMPS.EQ, compType: 'val', selected: 'CS' }
    ]
  },
  {
    id: `${PREDEF_PREFIX}intraday-movers`,
    profileName: 'Intraday Movers',
    orderby: 'day0_date',
    order: 'desc',
    activeFilters: [
      { name: 'day0_date', comp: COMPS.BT, compType: 'val', selected: '60_day' },
      { name: 'day0_open', comp: COMPS.BT, compType: 'val', val: '1', val2: '15' },
      { name: 'day0_gap_pct', comp: COMPS.LE, compType: 'val', val: '3' },
      { name: 'day0_chg_pct', comp: COMPS.GE, compType: 'val', val: '50' },
      { name: 'day0_vol', comp: COMPS.GE, compType: 'val', val: '2000000' },
      { name: 'share_group', comp: COMPS.EQ, compType: 'val', selected: 'CS' }
    ]
  },
  {
    id: `${PREDEF_PREFIX}stocks-with-halts`,
    profileName: 'Stocks with Halts',
    orderby: 'day0_date',
    order: 'desc',
    activeFilters: [
      { name: 'day0_date', comp: COMPS.BT, compType: 'val', selected: '60_day' },
      { name: 'day0_open', comp: COMPS.BT, compType: 'val', val: '1', val2: '15' },
      { name: 'day0_vol', comp: COMPS.GE, compType: 'val', val: '2000000' },
      { name: 'halts_count', comp: COMPS.GE, compType: 'val', val: '1' },
      { name: 'share_group', comp: COMPS.EQ, compType: 'val', selected: 'CS' }
    ]
  },
  {
    id: `${PREDEF_PREFIX}large-cap-movers`,
    profileName: 'Large Cap Movers',
    orderby: 'day0_date',
    order: 'desc',
    activeFilters: [
      { name: 'day0_date', comp: COMPS.BT, compType: 'val', selected: '60_day' },
      { name: 'day0_open', comp: COMPS.BT, compType: 'val', val: '25', val2: '500' },
      { name: 'day0_vol', comp: COMPS.GE, compType: 'val', val: '2000000' },
      { name: 'day0_chg', comp: COMPS.GE, compType: 'val', val: '15' },
      { name: 'share_group', comp: COMPS.EQ, compType: 'val', selected: 'CS' }
    ]
  }
];


export const defaultHistoryTopBarProfile = {
  activeProfile: `${PREDEF_PREFIX}recent-gappers`,
  exportProfile: `${PREDEF_PREFIX}recent-gappers`,
  profiles: [
    ...predefinedHistoryTopBarProfiles,
    {
      id: 'default',
      profileName: 'Default',
      orderby: 'day0_date',
      order: 'desc',
      activeFilters: [
        { name: 'day0_date', comp: COMPS.BT, compType: 'val', selected: '30_day' },
        { name: 'day0_open', comp: COMPS.BT, compType: 'val', val: 1, val2: 15 },
        { name: 'day0_gap_pct', comp: COMPS.GE, compType: 'val', val: 20 },
        { name: 'day0_vol', comp: COMPS.GE, compType: 'val', val: 2_000_000 }
      ]
    }
  ]
};


export const predefinedHistoryRecordsProfile = [
  {
    id: `${PREDEF_PREFIX}large-cap`,
    profileName: 'Large Cap',
    activeColumns: ['ticker', 'day0_date', 'day0_pm_vol', 'day0_gap_pct', 'day0_chg_pct', 'day1_ret_open_pct', 'day0_vol', 'day0_high_time'],
  },
  {
    id: `${PREDEF_PREFIX}small-cap`,
    profileName: 'Small Cap',
    activeColumns: ['ticker', 'day0_date', 'day0_pm_vol', 'day0_gap_pct', 'day0_chg_pct', 'day1_ret_open_pct', 'day0_vol', 'day0_high_time'],
  },
];

export const defaultHistoryRecordsProfile = {
  activeProfile: `${PREDEF_PREFIX}large-cap`,
  exportProfile: `${PREDEF_PREFIX}large-cap`,
  profiles: [
    ...predefinedHistoryRecordsProfile,
    {
      id: 'default',
      profileName: 'Default',
      activeColumns: ['ticker', 'day0_date', 'day0_pm_vol', 'day0_pm_high', 'day0_gap_pct', 'day0_chg_pct']
    }
  ]
};

export const defaultHistoryAllMetricsProfile = {
  activeProfile: 'default',
  profiles: [{
    id: 'default',
    profileName: 'Default',
    activeColumns: ['ticker', 'day0_date', 'day0_pm_vol', 'day0_gap_pct', 'day0_chg', 'day0_chg_pct', 'day0_ret_open_pct', 'day0_high_time', 'day0_low_time', 'day0_open_ssr']
  }]
};

export const defaultHistoryChartProfile = {
  activeProfile: 'default',
  period: 'W',
  profiles: [{
    id: 'default',
    profileName: 'Default',
    activeFilters: [
      { name: 'day0_gap_pct', type: 'line', func: 'sum' }
    ]
  }]
};
