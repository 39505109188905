import React, { useCallback, useState } from 'react';
import clsx from 'clsx';
import { useFormSelector, useFormDispatch, useFormActions } from 'src/app/slicedForm/shared/context/FormProvider';
import { selectIsAnyFieldInvalid } from 'src/app/slicedForm/FilterForm/reducers/filterReducer';
import ActionList, { useDoubleConfirmationState } from 'src/app/components/ActionList';
import ClearIcon from '@material-ui/icons/Clear';
import LockIcon from '@material-ui/icons/Lock';
import { flashError } from "../styles/useInputStyles";
import { useNotificationApi } from "../context/NotificationProvider";
import { CopyIcon, ConfirmIcon, CheckIcon } from 'src/theme/EdgeIcons';
import {
  SELECT_PROFILE,
  COPY_PROFILE,
  DELETE_PROFILE,
  generateId,
} from 'src/app/slicedForm/shared/reducers/profileReducer';
import {
  Box,
  Typography,
  Button,
  makeStyles,
  useTheme,
} from '@material-ui/core';
import { useFormOverlayModal } from '../context/FormOverlayModalProvider';
import { useFormPermissions } from '../context/FormSettingsProvider';
import PlanTag from 'src/app/components/utility/ProTag';
import { PROFILE_PERMISSION_SCOPES } from 'src/hooks/useUserPlanPermissions';
import ConditionalWrapper from 'src/app/components/utility/ConditionalWrapper';

const useStyles = makeStyles(theme => ({
  root: {
    paddingTop: theme.spacing(1)
  },
  opacity: {
    opacity: 0.6
  },
  planTagPill: {
    marginLeft: 10
  }
}));


function ProfilesSection({ className }) {
  const theme = useTheme();
  const classes = useStyles();
  const dispatch = useFormDispatch();
  const activeProfileId = useFormSelector(state => state.activeProfile);
  const profiles = useFormSelector(state => state.profiles);
  const { openOverlayModal } = useFormOverlayModal();
  const [customizationDisabled, disallowedProfileIds] = useFormPermissions(
    PROFILE_PERMISSION_SCOPES.disabledCustomization,
    PROFILE_PERMISSION_SCOPES.disallowedPredefinedProfiles
  )
  const {
    confirming,
    isAnyConfirming,
    setConfirming
  } = useDoubleConfirmationState();
  const isAnyInvalid = useFormSelector(selectIsAnyFieldInvalid);
  const { enqueueNotification } = useNotificationApi();


  const guardCopying = useCallback((callback) => {
    // Don't allow creating or copying of custom profiles if customization is disabled
    if (customizationDisabled.value) {
      openOverlayModal('upgrade_customization', { suggestedPlanLabel: customizationDisabled.unlocksAt });
      return;
    }
    callback();
  }, [customizationDisabled, openOverlayModal]);


  const guardErrors = (callback) => {
    if (isAnyInvalid) {
      enqueueNotification({
        message: 'Please correct the errors in your form before continuing',
        severity: 'error'
      });
      flashError();
    } else {
      callback();
    }
  }

  const isOpaque = useCallback((profile) => {
    if (!profile.predefined && customizationDisabled.value) return true;
    if (profile.predefined && disallowedProfileIds.value.includes(profile.id)) return true;
  }, [customizationDisabled, disallowedProfileIds]);


  return (
    <ActionList
      className={clsx(classes.root, className)}
    >
      {profiles.map(profile => {
        const isConfirming = confirming(profile?.id);
        const isProfileOpaque = isOpaque(profile);

        return (
          <ActionList.Item
            key={profile.id}
            id={profile.id}
            onSelect={() => !isAnyConfirming && guardErrors(() => dispatch({ type: SELECT_PROFILE, payload: profile.id }))}
            selected={profile.id === activeProfileId}
            role="menuitem"
            title={profile.name}
            pl={profile.predefined ? undefined : theme.spacing(2)}
          >
            {profile.predefined && (
              <ActionList.Leading margin={2}>
                <LockIcon className={clsx("lockIcon", isProfileOpaque && classes.opacity)} />
              </ActionList.Leading>
            )}

            <ConditionalWrapper
              condition={isProfileOpaque}
              wrapper={children => <span className={classes.opacity}>{children}</span>}
            >
              {profile.name}
            </ConditionalWrapper>

            {Boolean(profile?.predefined && disallowedProfileIds.value.includes(profile.id)) && (
              <PlanTag variant="pill" className={classes.planTagPill}>{disallowedProfileIds.unlocksAt}</PlanTag>
            )}
            <ActionList.Trailing>
              {!isConfirming && (
                <ActionList.IconButton
                  className={clsx(isProfileOpaque && classes.opacity)}
                  Icon={CopyIcon}
                  label={'Copy'}
                  onClick={() => {
                    guardCopying(() => guardErrors(() => {
                      dispatch({
                        type: COPY_PROFILE,
                        payload: {
                          id: profile.id,
                          name: profile.name,
                          newId: generateId()
                        }
                      });
                    }))
                  }}
                />
              )}
              {(isProfileOpaque || profile?.predefined) ? (
                <ActionList.IconButton placeholder />
              ) : (
                <ActionList.DoubleConfirmationIconButton
                  confirming={isConfirming}
                  BaseIcon={ClearIcon}
                  baseLabel={'Delete'}
                  baseColor={theme.palette.primary.main}
                  ConfirmIcon={CheckIcon}
                  confirmLabel={'Confirm delete?'}
                  confirmColor={theme.palette.text.negative}
                  setConfirming={setConfirming(profile.id)}
                  onAccept={() => dispatch({ type: DELETE_PROFILE, payload: profile.id })}
                  disabled={isOpaque(profile) || profile?.predefined} // Should never be hit
                />
              )}
            </ActionList.Trailing>
          </ActionList.Item>
        );
      })}
    </ActionList>
  );
}

export default ProfilesSection;
