import { useState, useRef } from 'react';
import _noop from 'lodash/noop';
import {
  setRef,
  useEventCallback
} from '@material-ui/core/utils';

/**
 * @enum {string}
 * @type {{start: string, end: string, reset: string}}
 * @readonly
 */
const DIF = {
  start: 'start',
  end: 'end',
  reset: 'reset',
};

/**
 * @typedef {{keyof:DIF}|number} DiffType
 */

/**
 * @enum {string}
 * @type {{next: string, prev: string}}
 * @readonly
 */
const DIR = {
  next: 'next',
  prev: 'prev',
};

const pagesize = 5;


const getOptionId = (id, index) => `${id}-option-${index}`


/**
 * @typedef {Object} KeyboardMenuNavigationConfig
 * @property {number} [initialIndex=-1] - The initial index to start at, -1 for no default focus at all
 * @property {function} onEnter
 * @property {function} onEscape
 * @property {string} listBoxRoleSelector - Override the default css [role="listbox"] selector. Some components do not accept role="" prop.
 * @property {boolean} [handleHomeEndKeys=true] - Use Home/End to skip to beginning or end of list
 * @property {boolean} [disableListWrap=false] - Disable wrapping around the list when navigating past ends
 * @property {includeInputInList} [includeInputInList=false] - Can navigate from the input to the list and vice versa
 */


/**
 * Handles keyboard navigation of an input + listbox combo.
 *  - Apply the returned props to each respective element, like {...getListboxProps()}
 *  - Style the highlighted element with '&[data-focus=true]': {}
 *
 * Does so via DOM manipulation rather than controlled state. This is faster and allows for :hover logic to work.
 *
 *
 * @param {string} id - unique id for DOM
 * @param {Object[]} items
 * @param {boolean} disabled
 * @param {KeyboardMenuNavigationConfig} config
 * @return {{
 *  resetHighlighted: function,
 *  getRootProps: function,
 *  getInputProps: function,
 *  getListboxProps: function,
 *  getOptionProps: function
 * }}
 */
export default function useKeyboardMenuNavigation(
  id,
  items,
  disabled,
  config = {}
) {
  const {
    initialIndex = -1,
    onEnter = _noop,
    onEscape = _noop,
    handleHomeEndKeys = true,
    listBoxRoleSelector = '[role="listbox"]',
    disableListWrap = false,
    includeInputInList = false,
  } = config;

  const inputRef = useRef(null);
  const listboxRef = useRef(null);
  /** Where the fake keyboard (or mouse) focus is */
  const highlightedIndexRef = useRef(initialIndex);
  /** The last item the user selected */
  const selectedIndexRef = useState(-1);


  /**
   * Take the new calculated index, and apply it to the DOM directly.
   * This is faster than re-rendering the entire list, and allows for :hover logic to work.
   * @param {number} index
   * @param {boolean} mouse
   * @returns {void}
   */
  const setHighlightedIndex = (index, mouse = false) => {
    highlightedIndexRef.current = index;

    if (index === -1) {
      inputRef.current.removeAttribute('aria-activedescendant');
    } else {
      inputRef.current.setAttribute('aria-activedescendant', getOptionId(id, index));
    }

    if (!listboxRef.current) return;

    const prev = listboxRef.current.querySelector('[data-focus]');
    if (prev) {
      prev.removeAttribute('data-focus');
    }

    const listboxNode = listboxRef.current.parentElement.querySelector(listBoxRoleSelector);

    // No results
    if (!listboxNode) return;

    // Lost focus (maybe?)
    if (index === -1) {
      // TODO: VirtualList has its own scroll API.
      // We need to attach listRef={} to <ActionList.Virtual>, not innerRef
      // the ref will not be a DOM el, but an API.
      // We need to get the dom ref by ref.__outerRef, or attach another 'innerRef' to virtuallist.
      // We need to call ref.scrollTo(scrollTop = 0/end), or ref.scrollToItem(index)
      listboxNode.scrollTop = 0;
      return;
    }

    const optionNode = listboxRef.current.querySelector(`[data-option-index="${index}"]`);

    if (!optionNode) return;

    optionNode.setAttribute('data-focus', 'true');

    if (!mouse) {
      // supposedly this has good support now?
      optionNode.scrollIntoView({ scrollMode: 'if-needed', block: 'nearest' });
    }
  };


  /**
   * Given an index, make sure it's a valid focusable element within the listbox. If not, continue on to the next element.
   * If nothing is valid, return -1;
   *
   * @param {number} index
   * @param {keyof: DIR} direction
   * @return {number} - Valid index or -1
   */
  const validOptionIndex = (index, direction) => {
    if (!listboxRef.current || index === -1) {
      return -1;
    }

    let nextFocus = index;

    // eslint-disable-next-line no-constant-condition
    while (true) {
      // out of range
      if (
        (direction === DIR.next && nextFocus === items.length) ||
        (direction === DIR.prev && nextFocus === -1)
      ) {
        return -1;
      }

      const optionNode = listboxRef.current.querySelector(`[data-option-index="${nextFocus}"]`);

      if (
        optionNode &&
        (!optionNode.hasAttribute('tabindex') ||
          optionNode.disabled ||
          optionNode.getAttribute('aria-disabled') === 'true')
      ) {
        nextFocus += direction === DIR.next ? 1 : -1;
      } else {
        return nextFocus;
      }
    }
  };


  /**
   * Calculates and applies the next highlight index from DOM elements.
   * @param {DiffType} diff
   * @param {keyof: DIR} direction
   * @returns {void} - manipulates dom directly
   */
  const changeHighlightedIndex = (diff, direction) => {
    if (disabled) {
      return;
    }

    const getNextIndex = () => {
      const maxIndex = items.length - 1;

      if (diff === DIF.reset) return initialIndex;

      if (diff === DIF.start) return 0;

      if (diff === DIF.end) return maxIndex;

      const newIndex = highlightedIndexRef.current + diff;

      // navigating before start
      if (newIndex < 0) {
        if (newIndex === maxIndex + 1 && includeInputInList) {
          // focus input
          return -1;
        }
        if ((disableListWrap && highlightedIndexRef.current !== -1) || Math.abs(diff) > 1) {
          // skip wrapping
          return 0;
        }
        // wrap
        return maxIndex;
      }

      // navigating past end
      if (newIndex > maxIndex) {
        if (newIndex === maxIndex + 1 && includeInputInList) {
          return -1;
        }

        if (disableListWrap || Math.abs(diff) > 1) {
          return maxIndex;
        }

        return 0;
      }

      return newIndex;
    };

    const nextIndex = validOptionIndex(getNextIndex(), direction);
    setHighlightedIndex(nextIndex);
    // Not sure about this line?
    selectedIndexRef.current = nextIndex;
  };


  const resetHighlighted = () => {
    changeHighlightedIndex(DIF.reset, DIR.next);
  }

  const handleKeyDown = (event) => {
    if (disabled) return;

    switch (event.key) {
      case 'Home': {
        if (handleHomeEndKeys) {
          event.preventDefault();
          changeHighlightedIndex(DIF.start, DIR.next);
        }
        break;
      }
      case 'End': {
        if (handleHomeEndKeys) {
          event.preventDefault();
          changeHighlightedIndex(DIF.end, DIR.prev);
        }
        break;
      }
      case 'PageUp': {
        event.preventDefault();
        changeHighlightedIndex(-pagesize, DIR.prev);
        // handleOpen?
        break;
      }
      case 'PageDown': {
        event.preventDefault();
        changeHighlightedIndex(pagesize, DIR.next);
        break;
      }
      case 'ArrowUp': {
        event.preventDefault();
        changeHighlightedIndex(-1, DIR.prev);
        break;
      }
      case 'ArrowDown': {
        event.preventDefault();
        changeHighlightedIndex(1, DIR.next);
        break;
      }
      case 'Enter': {
        if (onEnter && onEnter !== _noop) {
          event.preventDefault();
          if (items?.[highlightedIndexRef.current]) {
            onEnter(items?.[highlightedIndexRef.current]);
          }
        }
        break;
      }
      case 'Escape': {
        if (onEscape && onEscape !== _noop) {
          event.preventDefault();
          event.stopPropagation();
          onEscape(items?.[highlightedIndexRef.current]);
        }
        break;
      }
      default:
    }
  };


  const handleOptionMouseOver = event => {
    const index = Number(event.currentTarget.getAttribute('data-option-index'));
    setHighlightedIndex(index, true);
  };


  /**
   * Sets highlight on first render, as well as capturing ref
   */
  const handleListboxRef = useEventCallback(node => {
    setRef(listboxRef, node);

    if (node) {
      setHighlightedIndex(highlightedIndexRef.current);
    }
  });


  return {
    resetHighlighted,
    getRootProps: () => ({
      onKeyDown: handleKeyDown
    }),
    getInputProps: (refKey = 'ref') => ({
      'aria-activedescendant': '',
      [refKey]: inputRef,
    }),
    getListboxProps: (refKey = 'ref') => ({
      role: 'listbox',
      [refKey]: handleListboxRef,
      onMouseDown: event => event.preventDefault(),
    }),
    getOptionProps: (index, { disabled = false, selected = false }) => {
      return {
        onMouseOver: handleOptionMouseOver,
        role: 'option',
        tabIndex: -1,
        id: getOptionId(id, index),
        'data-option-index': index,
        'aria-disabled': disabled,
        'aria-selected': selected
      };
    },
  };
}
