import React, { useMemo, useCallback } from 'react';
import clsx from 'clsx';
import { unstable_batchedUpdates } from 'react-dom';
import { format, isValid } from 'date-fns';
import { parseAssumeMarketTime } from 'src/utils/datetime/date-fns.tz';
import { isRollingKey } from 'src/app/components/pickers/definitions/staticRangeDefinitions';
import {
  BOOLEAN_OP_LABELS,
  BOOLEAN_OPS,
  DATE_TYPES,
  DATE_TYPE_LABELS,
  ROLLING_DATE_OP_LABELS
} from 'src/app/slicedForm/FilterForm/definitions/inputEnums';
import useInputStyles, { InputStyleProps } from 'src/app/slicedForm/shared/styles/useInputStyles';
import { useEntity } from '../context/useFormEntity';
import { useColumnDef, useDefaultDateConfig } from 'src/app/slicedForm/shared/context/ColumnDefsProvider';
import { useFilterEntityContext } from 'src/app/slicedForm/FilterForm/context/FilterEntityContext';
import { Switch, Case } from 'src/app/components/utility/SwitchCase';
import SimpleDropdown from '../elements/SimpleDropdown';
import PopoverKeyboardDatePicker from 'src/app/components/pickers/PopoverKeyboardDatePicker';
import PopoverKeyboardDateRangePicker from 'src/app/components/pickers/PopoverKeyboardDateRangePicker';
import {
  Box,
  FormControl,
  makeStyles,
} from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    '& .MuiInputAdornment-root': {
      '& p': {
        fontSize: 14,
      }
    }
  },
  rolling: {
    flexDirection: 'row',
    alignItems: 'center',
    justifyContent: 'center'
  },
  flexNegativeMargin: {
    marginTop: -10
  },
  flexMargin: {
    marginTop: 10
  }
}));



const DATE_FORMAT = 'yyyy-MM-dd';


function DateCompare({
  className,
  hideValueType = true,
  hideComparison = false,
}) {
  const classes = useStyles();
  const inputClasses = useInputStyles();
  const { id, disabled } = useFilterEntityContext();

  const { useRegister, useWatch } = useEntity(id);

  const leftColumn = useWatch('left.column');
  const leftColDef = useColumnDef(leftColumn);

  const operatorInputProps = useRegister('operator', { refName: 'innerRef', valueName: 'selected' });
  const dateTypeInputProps = useRegister('dateType', { refName: 'innerRef', valueName: 'selected' });

  const rightFirstValueProps = useRegister('right[0].value');
  const rightSecondValueProps = useRegister('right[1].value');

  const defaultDateConfig = useDefaultDateConfig();

  const dateConfig = useMemo(() => {
    return {
      ...defaultDateConfig,
      ...leftColDef?.dateConfig
    }
  }, [defaultDateConfig, leftColDef?.dateConfig])



  const { range, isRolling } = useMemo(() => {

    if (dateTypeInputProps?.selected === DATE_TYPES.ROLLING) {
      return {
        range: {
          key: rightFirstValueProps?.value,
          startDate: null,
          endDate: null
        },
        isRolling: true
      }
    }

    const inputValFirst = rightFirstValueProps?.value ? parseAssumeMarketTime(rightFirstValueProps?.value, DATE_FORMAT) : null;
    const inputValSecond = rightSecondValueProps?.value ? parseAssumeMarketTime(rightSecondValueProps?.value, DATE_FORMAT) : null;
    return {
      range: {
        startDate: inputValFirst,
        endDate: inputValSecond,
        key: 'default'
      },
      isRolling: false
    }
  }, [rightFirstValueProps?.value, rightSecondValueProps?.value])


  const handleDateChange = useCallback((date) => {
    rightFirstValueProps?.onChange(
      isValid(date) ? format(date, DATE_FORMAT) : null
    )
  }, []);


  const handleDateRangeChange = ({ startDate, endDate, key }) => {
    let nextVals;

    if (isRollingKey(key)) {
      nextVals = [key, null];
    } else {
      nextVals = [
        isValid(startDate) ? format(startDate, DATE_FORMAT) : null,
        isValid(endDate) ? format(endDate, DATE_FORMAT) : null
      ]
    }

    unstable_batchedUpdates(() => {
      [rightFirstValueProps, rightSecondValueProps].forEach((props, i) => {
        props?.onChange(nextVals[i]);
      });
    })
  }

  /*
    For rolling dates, we want to show the user IN / NOT_IN as that makes semantic sense.
    But, the query is actually GTE / LT versus the start_date.

    When we switch to rolling and back, we need to make sure the operator gets set to a valid default value.
    This is done in the reducer.
  */

  return (
    <Box className={clsx(className, classes.root)}>

      <SimpleDropdown
        className={clsx(
          inputClasses.formControl,
          inputClasses.comparisonSelectControl
        )}
        options={dateTypeInputProps?.selected === DATE_TYPES.DATE ? BOOLEAN_OP_LABELS : ROLLING_DATE_OP_LABELS}
        disabled={disabled}
        label="Select comparison operator"
        {...operatorInputProps}
      />

      <Switch expr={dateConfig?.rollingDates}>
        <Case when={true}>
          <FormControl
            className={clsx(
              inputClasses.formControl,
              inputClasses.valueTypeSelectControl
            )}
          />
        </Case>
        <Case default>
          <SimpleDropdown
            className={clsx(
              inputClasses.formControl,
              inputClasses.valueTypeSelectControl
            )}
            disabled={disabled}
            options={DATE_TYPE_LABELS}
            label="Select date type"
            {...dateTypeInputProps}
            onChange={newValue => {
              dateTypeInputProps?.onChange(newValue, {
                defaultDateValue: newValue === DATE_TYPES.ROLLING ? dateConfig.defaultRollingDate : null
              });
            }}
          />
        </Case>
      </Switch>

      <Box
        display="flex"
        flexWrap="wrap"
        flex={1}
        className={classes.flexNegativeMargin}
      >
        <Switch expr={dateTypeInputProps?.selected === DATE_TYPES.ROLLING}>
          <Case when={true}>
            <SimpleDropdown
              className={clsx(
                inputClasses.flexFormControl,
                inputClasses.formControl,
                inputClasses.valueInputControl,
                classes.flexMargin
              )}
              inputClassName={inputClasses.highlight}
              disabled={disabled}
              options={dateConfig?.rollingDates.reduce((acc, date) => {
                acc[date.key] = date.label;
                return acc;
              }, {})}
              selected={range?.key}
              onChange={rightFirstValueProps?.onChange}
              error={rightFirstValueProps?.err}
              innerRef={rightFirstValueProps?.innerRef}
            />
          </Case>
          <Case default>
            <Switch expr={operatorInputProps?.selected}>
              <Case when={BOOLEAN_OPS.BTW}>
                <PopoverKeyboardDateRangePicker
                  rangeClassName={clsx(
                    inputClasses.calendarAdornment,
                    inputClasses.calendarAdornmentFakeRange,
                    inputClasses.highlight,
                    inputClasses.formControl,
                    inputClasses.singleRangeInputControl,
                    classes.flexMargin,
                    {
                      [`${inputClasses.formControlError} sf-form-control-error-flash`]: rightFirstValueProps?.error || rightSecondValueProps?.error
                    }
                  )}
                  flexWrap="wrap"
                  inputClassName={clsx(
                    inputClasses.input,
                    inputClasses.fakeSingleRangeInput,
                    inputClasses.fakeSingleRangeDateInput,
                  )}
                  leftInputClassName={clsx('--left')}
                  rightInputClassName={clsx('--right')}
                  {...InputStyleProps}
                  range={range}
                  separator={'—'}
                  format={dateConfig.format}
                  placeholder={dateConfig.placeholder}
                  disableFuture={dateConfig.disableFuture}
                  disableHoliday={dateConfig.disableHoliday}
                  disableWeekend={dateConfig.disableWeekend}
                  makeStaticRanges={dateConfig.makeRollingDates}
                  makeInputRanges={dateConfig.makeInputDates}
                  staticRangeHeaderContent={dateConfig.staticRangeHeaderContent}
                  minDate={dateConfig.minDate}
                  marketTime
                  showErrorMessage
                  disableUnderline
                  disabled={disabled}
                  acceptStrategy="onAccept"
                  onAccept={handleDateRangeChange}
                />
              </Case>
              <Case default>
                {/* <DateInput
                  format="yyyy-MM-dd"
                  value={range?.startDate}
                  placeholder="yyyy-mm-dd"
                  onBlur={handleDateChange}
                /> */}
                <PopoverKeyboardDatePicker
                  inputClassName={clsx(
                    inputClasses.input,
                    inputClasses.highlight,
                    inputClasses.formControl,
                    inputClasses.valueInputControl,
                    inputClasses.calendarAdornment,
                    classes.flexMargin,
                    {
                      [`${inputClasses.formControlError}, sf-form-control-error-flash`]: rightFirstValueProps?.error
                    }
                  )}
                  {...InputStyleProps}
                  date={range?.startDate}
                  format={dateConfig.format}
                  placeholder={dateConfig.placeholder}
                  disableFuture={dateConfig.disableFuture}
                  disableHoliday={dateConfig.disableHoliday}
                  disableWeekend={dateConfig.disableWeekend}
                  makeStaticRanges={dateConfig.makeStaticRanges}
                  minDate={dateConfig.minDate}
                  disableUnderline
                  showErrorMessage
                  disabled={disabled}
                  onAccept={handleDateChange}
                />
              </Case>
            </Switch>
          </Case>
        </Switch>
      </Box>
    </Box>
  );
}

export default DateCompare;
