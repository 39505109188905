import React from 'react';
import Skeleton from '@material-ui/lab/Skeleton';
import { useTheme, makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    width: '100%',
    height: '100%',
    position: 'relative'
  },
  cell: {
    height: '100%',
    display: 'flex',
    alignItems: 'center',
    paddingLeft: 7
  },
  skeleton: {
    width: '100%'
  },
}));


function LoadingCellSkeleton({ node, columnApi, context }) {
  const classes = useStyles();
  const theme = useTheme();

  const columns = columnApi.getAllGridColumns();
  const transformEl = document.querySelector(`.ag-grid-${context.componentId} .ag-header-container`);
  const transform = transformEl ? transformEl?.style?.transform : 'translateX(0px)';

  return (
    <div className={classes.root}>
      {columns.map(col => (
        <div
          key={col.colId}
          className={classes.cell}
          style={{
            borderRight: col.lastLeftPinned ? `1px solid #515151` : 'none',
            zIndex: col.lastLeftPinned ? 2 : 1,
            backgroundColor: node.rowIndex % 2 === 0 ? theme.palette.background.paperAlt : theme.palette.background.paper,
            width: col.actualWidth,
            position: 'absolute',
            top: 0,
            left: col.left,
            transform: col.lastLeftPinned ? 'none' : transform
          }}
        >
          <Skeleton width={Math.round(col.actualWidth * .6)} variant="text" animation="pulse"/>
        </div>
      ))}
    </div>
  );
}


export default LoadingCellSkeleton;
