import _flow from 'lodash/flow';
import he from 'he';
import {
  formatMarketTime,
  parseAssumeMarketTime
} from 'src/utils/datetime/date-fns.tz';
import {
  filterByCategories,
  filterByKeywords,
  reduceByRealtimeFilters,
  reduceBySymbolFilter
} from 'src/app/components/grid/topListNews/columns/filterStateFunctions';
import {
  FILTER_COLUMNS
} from 'src/app/components/grid/topListNews/columns/columnDefs';
import profileToQuery from 'src/app/slicedForm/mapping/profileToQuery';
import edgeDataApi from 'src/apis/edgeDataApi';
import AblyService from 'src/services/AblyService';
import NewsQuoteRequestCombiner from './NewsQuoteRequestCombiner';
import { EXPR_CTX, EXPR_PREFIX } from 'src/redux/expressions/globalExpressionReducer';
import getArithmaticParser from 'src/app/slicedForm/ExpressionForm/arithmatic/ArithmeticParser';

export const GRID_ASYNC_TRANSACTION_WAIT_MILLIS = 100;

class NewsDataSource {
  constructor(id, maxRecords = 2000) {
    this.id = id;
    this.maxRecords = maxRecords;
    this.resetCache();
    this.expressionEvaluator = getArithmaticParser().evaluate;
  }

  register() {
    NewsQuoteRequestCombiner.registerDataFeed(this.id);
  }

  unregister() {
    NewsQuoteRequestCombiner.unregisterDataFeed(this.id);
  }

  resetCache() {
    this.count = 0;
    this.childRecordCache = {};
    this.initialRequestComplete = false;
  }


  getChildTickerRecords(clickedRowData) {
    return this.childRecordCache?.[clickedRowData.news_id] || [];
  }

  async getRows(gridParams) {
    const { success, api, context } = gridParams;
    const { setIsFetching, componentId } = context;
    const requestParams = this.buildQuery(gridParams);

    if (!api || !context?.isMountedRef?.current) return;

    if (this.count >= this.maxRecords) {
      return success({ rowData: [], rowCount: this.count });
    }

    if (gridParams.request?.groupKeys && gridParams.request?.groupKeys.length) {
      const parentGroupKey = gridParams.request.groupKeys[0];
      const childRecords = this.childRecordCache?.[parentGroupKey] || [];

      return success({
        rowData: childRecords,
        rowCount: childRecords.length
      });
    }

    let records;
    try {
      const response = await edgeDataApi.post('/news/v2', {
        componentId, // The backend needs this to save column sets to dynamo, for streaming
        ...requestParams
      });
      const data = response?.data?.data || [];

      records = data
        .map((record) => this.formatRecord.call(this, record))
        .map((record) => this.putIntoGroupCache.call(this, record));

    } catch (err) {
      console.error(err);
      if (!api || !context?.isMountedRef?.current) return;
      api?.showNoRowsOverlay();
      success({ rowData: [], rowCount: 0 });
      setIsFetching(false);
      return;
    } finally {
      this.initialRequestComplete = true;
    }

    if (!api || !context?.isMountedRef?.current) return;

    this.count += records.length;

    if (!records.length) {
      if (!this.count) {
        api?.showNoRowsOverlay();
        success({ rowData: [], rowCount: 0 });
      } else {
        success({ rowData: [], rowCount: this.count });
      }
    } else {
      const lastRecord = records[records.length - 1];
      context?.setLastDate(lastRecord.datetime_id);

      if (records.length < requestParams.count || this.count > this.maxRecords) {
        success({ rowData: records, rowCount: this.count });
      } else {
        success({ rowData: records });
      }

      api?.hideOverlay();
    }

    setIsFetching(false);
    this.initialRequestComplete = true;
  }


  async getChildRscoreRecords(clickedRowData) {
    const { news_id, ticker } = clickedRowData;

    let result = [];
    try {
      const response = await edgeDataApi.get(`/news/similarity?ticker=${ticker}&news_id=${news_id}`);
      const { data = [] } = response;

      result = data.map(this.formatRecord);
    } catch (err) {
      console.error(err);
    }

    return result;
  }


  handlerId(channelName) {
    return `${channelName}-handler-${this.id}`;
  }


  openWebsocket(channelName, gridRef, isMountedRef) {
    AblyService.openConnection(channelName,
      {
        handlerId: this.handlerId(channelName),
        onMessage: (message, name) => {
          if (name === channelName) {
            // backwards compatablility
            name = 'news';
          }

          if (!gridRef?.current?.api || !isMountedRef.current) return;

          this.onMessage(message, name, gridRef);
        },
      },
    );
  }


  closeWebsocket(channelName) {
    AblyService.closeHandler(this.handlerId(channelName));
  }


  onMessage(message, name, gridRef) {
    try {
      if (name === 'news') {
        this.onMessage__news(message, gridRef);
      } else if (name === 'rscore') {
        this.onMessage__rscore(message, gridRef);
      } else {
        console.warn('Unhandled news message type:', name);
      }
    } catch (err) {
      console.error(name, err);
    }
  }

  onMessage__news(message, gridRef) {
    const context = gridRef?.current?.props?.context || {};
    const expressions = context?.expressions || [];
    const columns = context?.columnProfile?.columns || [];

    const { filters } = profileToQuery(
      { filters: context?.filterProfile?.filters },
      false,
      [],
      expressions
    );

    const combinedColumns = [...expressions, ...FILTER_COLUMNS];

    let data = message
      .filter((record) => filterByCategories(record, context?.categories))
      .filter((record) => filterByKeywords(record, context?.searchKeywords))
      .reduce((records, record) => {
        return context?.ticker
          ? reduceBySymbolFilter(records, record, [context.ticker])
          : reduceByRealtimeFilters(records, record, filters, combinedColumns);
      }, [])
      .map((record) => this.formatRecord.call(this, record))
      .map((record) => this.calculateExpressionColumns.call(this, record, columns, expressions))
      .map((record) => this.removeUnusedRealtimeColumns.call(this, record, context))
      .map((record) => this.putIntoGroupCache.call(this, record));


    if (data.length && gridRef?.current?.api) {
      gridRef?.current?.api.hideOverlay();
      gridRef?.current?.api.applyServerSideTransaction({
        route: [],
        addIndex: 0,
        add: data,
      });
      this.count += data.length;
    }
  }

  onMessage__rscore(message, gridRef) {
    const rootTransactions = [];
    const leafTransactions = [];

    message.forEach(msg => {

      // update cached tree data
      if (msg.news_id in this.childRecordCache) {
        this.childRecordCache[msg.news_id].forEach((childRecord, idx) => {
          if (childRecord.ticker === msg.ticker) {
            this.childRecordCache[msg.news_id][idx] = {
              ...this.childRecordCache[msg.news_id][idx],
              similarity: msg.similarity
            };
          }
        });
      }

      const node = gridRef?.current?.api.getRowNode(`${msg.news_id}_${msg.ticker}`);

      if (!node || node.level === 0) {
        // update grid root
        rootTransactions.push({
          ...node?.data,
          similarity: msg.similarity
        });
      } else {
        // update grid leaf
        const parentId = node.parent.key;
        if (parentId) {
          leafTransactions[parentId] = leafTransactions[parentId] || [];
          leafTransactions[parentId].push({
            ...node.data,
            similarity: msg.similarity
          });
        }
      }
    });

    if (rootTransactions.length) {
      gridRef.current?.api?.applyServerSideTransaction({
        route: [],
        update: rootTransactions,
      });
    }

    Object.keys(leafTransactions).forEach(parentId => {
      // For some reason async causes refreshCells to not work. Its okay here, but for the parent we need it to show.
      gridRef.current?.api?.applyServerSideTransactionAsync({
        route: [parentId],
        update: leafTransactions[parentId]
      });
    });

    if (rootTransactions.length || leafTransactions.length) {
      setTimeout(() => {
        gridRef.current?.api?.refreshCells({
          columns: ['combinedHeadline'],
          force: true
        });
      }, 200);
    }
  }

  /**
   * Using data from websocket, caluclate the user's selected
   * expression. [[A]] + [[B]] / 10. Substitute A and B with
   * col data.
   **/
  calculateExpressionColumns(record, columns, expressions) {
    const expressionObjects = columns
      .filter(col => col?.column.startsWith(EXPR_PREFIX))
      .map(col => expressions.find(e => e.name === col.column))
      .filter(Boolean);

    Object.keys(record?.quote || {}).forEach(ticker => {
      const quote = record.quote[ticker];
      expressionObjects.forEach(expr => {
        try {
          const value = this.expressionEvaluator(expr, quote);
          record.quote[ticker][expr.name] = value;
        } catch (err) {
          console.error(err);
        }
      })
    });

    return record;
  }

  formatRecord(record) {
    const inFormat = 'yyyy-MM-dd HH:mm:ss';
    const outFormat = 'yyyy-MM-dd - HH:mm';

    const dt = parseAssumeMarketTime(record.datetime, inFormat);
    record.dateDisplay = formatMarketTime(dt, outFormat);

    record.headline = he.decode(record.headline);
    return record;
  }

  removeUnusedRealtimeColumns(record, context) {
    let columns = context?.columnProfile?.columns || [];
    columns = columns.map(c => c?.column).filter(Boolean);

    Object.keys(record.quote).forEach(ticker => {
      record.quote[ticker] = _flow([
        Object.entries,
        arr => arr.filter(([key]) => columns.includes(key)),
        Object.fromEntries
      ])(record.quote[ticker]);
    });
    return record;
  }

  // Expand out the 'quote' field into individual columns
  // Place child records into the cache
  putIntoGroupCache(record) {
    const childRecords = [];
    let parentRecord;

    record.tickers.forEach((ticker, tickerIdx) => {
      if (tickerIdx === 0) {
        parentRecord = {
          ticker,
          ...record,
          ...record?.quote?.[ticker],
          childCount: record.tickers.length - 1
        };
        delete parentRecord.quote;
      } else {
        const childRecord = {
          news_id: record.news_id,
          ticker,
          ...record.quote?.[ticker]
        };
        childRecords.push(childRecord);
      }
    });
    if (childRecords.length) {
      this.childRecordCache[record.news_id] = childRecords;
    }

    return parentRecord;
  }


  refetchQuotes(gridRef, isMountedRef) {
    if (!gridRef?.current?.api || !isMountedRef.current) return;
    const { columnProfile } = gridRef.current.props.context;
    const columns = columnProfile?.columns || [];

    const tickers = gridRef.current.api.getRenderedNodes().reduce((tickers, node) => {
      return node?.data?.tickers ? [...tickers, ...node.data.tickers] : tickers;
    }, []);

    const _this = this;
    void NewsQuoteRequestCombiner.combineRequest(
      {
        id: _this.id,
        params: { columns, tickers },
        onResolve: async (data) => _this.applyQuoteTransactions(data, gridRef, isMountedRef)
      }
    );
  }


  applyQuoteTransactions(data, gridRef, isMountedRef) {
    if (!gridRef?.current?.api || !isMountedRef.current) return;

    // update our tree data, stored in basic cache
    Object.keys(this.childRecordCache).forEach(news_id => {
      this.childRecordCache[news_id].forEach((childRecord, idx) => {
        if (childRecord.ticker in data) {
          this.childRecordCache[news_id][idx] = {
            ...this.childRecordCache[news_id][idx],
            ...data[childRecord.ticker]
          };
        }
      });
    });

    // Update values already in the grid
    const rootUpdates = [];
    const leafUpdates = {};
    gridRef?.current?.api?.getRenderedNodes().forEach(node => {
      if (!node?.data?.ticker) {
        return;
      }
      if (node.data.ticker in data) {
        if (node.parent.allChildrenCount && node.parent.key) {
          const parentId = node.parent.key;
          leafUpdates[parentId] = leafUpdates[parentId] || [];
          leafUpdates[parentId].push({
            ...node.data,
            ...data[node.data.ticker]
          });
        } else {
          rootUpdates.push({
            ...node.data,
            ...data[node.data.ticker]
          });
        }
      }
    });

    if (rootUpdates.length) {
      gridRef.current?.api.applyServerSideTransactionAsync({
        update: rootUpdates
      });
    }

    Object.keys(leafUpdates).forEach(parentId => {
      gridRef.current?.api.applyServerSideTransactionAsync({
        route: [parentId],
        update: leafUpdates[parentId]
      });
    });
  }


  buildQuery(gridParams) {
    const { columnProfile, filterProfile, categories, lastDate, ticker, searchKeywords, expressions = [] } = gridParams.context;

    const { filters, columns } = profileToQuery(
      {
        filters: filterProfile.filters,
        columns: columnProfile.columns,
      },
      null,
      [],
      expressions
    );

    const { startRow, endRow } = gridParams.request;
    const count = endRow - startRow;


    if (ticker) {
      return {
        count,
        columns,
        categories,
        keywords: searchKeywords,
        prev: lastDate,
        ticker: ticker,
      };
    }

    return {
      count,
      columns,
      filters,
      categories,
      keywords: searchKeywords,
      prev: lastDate || null,
    };
  }

}


export default NewsDataSource;



