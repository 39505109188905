import { useState, useEffect } from 'react';

/*
 Syncs an incomming prop to component state. State will automatically update when prop changes.

 Not usually a good idea, but for the profile objects I don't commit the profile state to redux until its requested by the user.
 This works to keep an ephemeral copy of the profile in state, and make sure it updates when the user saves.
*/

export default function useStateFromProp(initialValue, onPropChanged = () => {}) {
  const [value, setValue] = useState(initialValue);

  useEffect(() => {
    setValue(initialValue);
    onPropChanged();
  }, [initialValue]);

  return [value, setValue];
}
