import React, { useRef } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { Navigation } from 'swiper/core';
import 'swiper/swiper.min.css';
import 'swiper/components/navigation/navigation.min.css';
import { makeStyles } from '@material-ui/core';
import { ArrowLeftCircle, ArrowRightCircle } from 'react-feather';


SwiperCore.use([Navigation]);


const useStyles = makeStyles((theme) => ({
  root: {
    '& .swiper-button-prev': {
      left: -1,
      display: props => props.showControls ? 'block' : 'none',
      '&:after': {
        content: 'none !important',
      },
    },
    '& .swiper-button-next': {
      display: props => props.showControls ? 'block' : 'none',
      right: -1,
      '&:after': {
        content: 'none !important',
      },
    },
    '& .swiper-arrow-icon': {
      '&.swiper-button-disabled': {
        display: 'none !important'
      },
      fontSize: '0',
      lineHeight: '0',
      position: 'absolute',
      top: 'auto',
      bottom: '-3px',
      width: '20px',
      height: '20px',
      padding: '0',
      transform: 'translate(0, -50%)',
      cursor: 'pointer',
      border: 'none',
      outline: 'none',
      color: 'transparent',
      background: 'transparent',
      '& svg': {
        width: 19,
        height: 19,
        color: theme.palette.text.secondary
      },
    }
  },
  swiperSlide: {
    width: 'auto'
  },
}));


function DragCarousel({
  className,
  showControls,
  spaceBetween,
  children,
}) {
  const classes = useStyles({ showControls });
  const navPrev = useRef();
  const navNext = useRef();

  return (
    <div className={clsx(classes.root, className)}>
      <button type="button" data-role="none" className="swiper-arrow-icon swiper-button-prev" ref={navPrev}><ArrowLeftCircle/> Next</button>
      <button type="button" data-role="none" className="swiper-arrow-icon swiper-button-next" ref={navNext}><ArrowRightCircle/> Previous</button>
      <Swiper
        slidesPerView={'auto'}
        spaceBetween={spaceBetween}
        grabCursor
        observer
        navigation={{
          prevEl: navPrev.current || undefined,
          nextEl: navNext.current || undefined,
        }}
        onInit={(swiper) => {
          swiper.params.navigation.prevEl = navPrev.current;
          swiper.params.navigation.nextEl = navNext.current;
          swiper.navigation.update();
        }}
      >
        {React.Children.map(children, child => (
          <SwiperSlide className={classes.swiperSlide} key={child.key}>
            {child}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
}


DragCarousel.propTypes = {
  className: PropTypes.string,
  showControls: PropTypes.bool,
  spaceBetween: PropTypes.number,
  children: PropTypes.arrayOf(PropTypes.node),
};


DragCarousel.defaultProps = {
  spaceBetween: 0,
  showControls: false,
};


export default DragCarousel;

