import produce from 'immer';
import _sortBy from 'lodash/sortBy';
import _uniqBy from 'lodash/uniqBy';
import { HISTORY_COLUMNS } from 'src/app/HistoryPage/columns/historyColumns';
import {
  FETCH_HISTORY_PAST_EXPORTS,
  CREATE_HISTORY_EXPORT,
  SET_CREATE_EXPORT_ERROR,
  ADD_PENDING_EXPORT,
  REMOVE_PENDING_EXPORT,
  SET_PENDING_EXPORT_ERROR,
  DELETE_HISTORY_EXPORT,

  SET_HISTORY_EXPORT_MODAL_OPEN,
  SET_HISTORY_EXPORT_MODAL_STEP,

  INITIALIZE_FORM_STATE,
  UPDATE_FORM_STATE_NAME,
  UPDATE_FORM_STATE_ORDER,
  ADD_FORM_STATE_FILTER,
  REMOVE_FORM_STATE_FILTER,
  UPDATE_FORM_STATE_FILTER,

  SET_FILTER_ERRORS,

  ADD_FORM_STATE_COLUMN,
  REMOVE_FORM_STATE_COLUMN,
  REORDER_FORM_STATE_COLUMN,

  REQUEST__FETCH_HISTORY_PAST_EXPORTS,
  REQUEST__CREATE_HISTORY_EXPORT,
  FINISH__CREATE_HISTORY_EXPORT
} from 'src/redux/exports/exportActions';
import _find from 'lodash/find';


const initialState = {
  history: {
    formState: {
      profileName: '',
      previousExportId: null
    },
    modalState: {
      step: 0,
      open: false
    },
    pastExports: [],
    pendingExports: [],
    httpErrors: {
      createExport: null
    },
    formStateErrors: {
      filters: {}
    },
    isFetching: {
      pastExports: true,
      pendingExports: true,
      createExport: false
    }
  },
};


const exportReducer = (state = initialState, action) => {
  switch (action.type) {

    case INITIALIZE_FORM_STATE: {
      return produce(state, draft => {
        const { data: newState, stateKey } = action.payload;
        draft[stateKey].formState = newState;
      });
    }

    case UPDATE_FORM_STATE_NAME: {
      return produce(state, draft => {
        const { data: newName, stateKey } = action.payload;
        draft[stateKey].formState.profileName = newName
      });
    }

    case UPDATE_FORM_STATE_ORDER: {
      return produce(state, draft => {
        const { data, stateKey } = action.payload;
        const { order, orderby } = data;
        draft[stateKey].formState.order = order
        draft[stateKey].formState.orderby = orderby
      });
    }

    case ADD_FORM_STATE_FILTER: {
      return produce(state, draft => {
        const { data: newValues, stateKey } = action.payload;
        const filterIdx = draft[stateKey].formState.activeFilters.findIndex(({ name }) => name === newValues.name)
        if (filterIdx < 0) {
          draft[stateKey].formState.activeFilters.push(newValues);
        }
      });
    }

    case REMOVE_FORM_STATE_FILTER: {
      return produce(state, draft => {
        const { data: nameToRemove, stateKey } = action.payload;
        draft[stateKey].formState.activeFilters = draft[stateKey].formState.activeFilters.filter(({ name }) => name !== nameToRemove);

        /* eslint-disable-next-line no-unused-vars */
        const { [nameToRemove]: n, ...rest } = draft[stateKey].formStateErrors.filters;
        draft[stateKey].formStateErrors.filters = rest;

        if (draft[stateKey].formState.orderby === nameToRemove) {
          draft[stateKey].formState.order = 'desc';
          draft[stateKey].formState.orderby = draft[stateKey].formState.activeFilters[0].name;
        }
      });
    }

    case UPDATE_FORM_STATE_FILTER: {
      return produce(state, draft => {
        const { data: newValues, stateKey } = action.payload;
        const filterIdx = draft[stateKey].formState.activeFilters.findIndex(({ name }) => name === newValues.name);
        if (filterIdx >= 0) {
          draft[stateKey].formState.activeFilters[filterIdx] = newValues;
        }
      });
    }

    case ADD_PENDING_EXPORT: {
      return produce(state, draft => {
        draft.history.pendingExports = _uniqBy([
          action.payload,
          ...draft.history.pendingExports
        ], 'ux')
      })
    }

    case REMOVE_PENDING_EXPORT: {
      return produce(state, draft => {
        draft.history.pendingExports = draft.history.pendingExports.filter(({ ux }) => {
          return action.payload.toString() !== ux.toString()
        });
      })
    }

    case SET_PENDING_EXPORT_ERROR: {
      return produce(state, draft => {
        const index = draft.history.pendingExports.findIndex(({ ux }) => action.payload.ux = ux);
        if (index >= -1) {
          draft.history.pendingExports[index] = {
            ...draft.history.pendingExports[index],
            ...action.payload
          }
        }
      })
    }

    case SET_FILTER_ERRORS: {
      return produce(state, draft => {
        const { data: newErrors, stateKey } = action.payload;
        draft[stateKey].formStateErrors.filters = newErrors;
      });
    }

    case ADD_FORM_STATE_COLUMN: {
      return produce(state, draft => {
        const { data: columnToAdd, stateKey } = action.payload;
        let newColumns = [...draft[stateKey].formState.activeColumns, columnToAdd]
        if (stateKey === 'history') {
          newColumns = _sortBy(newColumns, (col) => _find(HISTORY_COLUMNS, { name: col }).stickyIndex);
        }
        draft[stateKey].formState.activeColumns = newColumns;
      });
    }

    case REMOVE_FORM_STATE_COLUMN: {
      return produce(state, draft => {
        const { data: columnToRemove, stateKey } = action.payload;
        draft[stateKey].formState.activeColumns = draft[stateKey].formState.activeColumns.filter(n => n !== columnToRemove);
      });
    }

    case REORDER_FORM_STATE_COLUMN: {
      return produce(state, draft => {
        const { data: orderedColumns, stateKey } = action.payload;
        draft[stateKey].formState.activeColumns = orderedColumns;
      });
    }

    case SET_HISTORY_EXPORT_MODAL_OPEN: {
      return produce(state, draft => {
        draft.history.modalState.open = action.payload;
      })
    }

    case SET_HISTORY_EXPORT_MODAL_STEP: {
      return produce(state, draft => {
        draft.history.modalState.step = action.payload;
      })
    }

    case FETCH_HISTORY_PAST_EXPORTS: {
      return produce(state, draft => {
        draft.history.pastExports = _uniqBy([
          ...action.payload,
          ...draft.history.pastExports
        ], 'ux')
        draft.history.isFetching.pastExports = false;
      });
    }

    case DELETE_HISTORY_EXPORT: {
      return produce(state, draft => {
        const idx = draft.history.pastExports.findIndex(({ ux }) => ux === action.payload);
        if (idx !== -1) {
          draft.history.pastExports.splice(idx, 1);
        }
      })
    }

    case CREATE_HISTORY_EXPORT: {
      return produce(state, draft => {
        draft.history.pastExports.unshift(action.payload);
      });
    }

    case SET_CREATE_EXPORT_ERROR: {
      return produce(state, draft => {
        draft.history.httpErrors.createExport = action.payload;
        draft.history.isFetching.createExport = false;
      })
    }

    case REQUEST__FETCH_HISTORY_PAST_EXPORTS: {
      return produce(state, draft => {
        draft.history.isFetching.pastExports = true;
      });
    }

    case REQUEST__CREATE_HISTORY_EXPORT: {
      return produce(state, draft => {
        draft.history.httpErrors.createExport = null;
        draft.history.isFetching.createExport = true;
      });
    }

    case FINISH__CREATE_HISTORY_EXPORT: {
      return produce(state, draft => {
        draft.history.isFetching.createExport = false;
      })
    }

    default: {
      return state;
    }
  }
};

export default exportReducer;
