import { history } from 'src/App';
import { generatePath } from 'react-router';
import _filter from 'lodash/filter';
import _find from 'lodash/find';
import _findIndex from 'lodash/findIndex';
import _uniqBy from 'lodash/uniqBy';
import { updateDynamoUser } from 'src/redux/account/accountActions';
import edgeDataApi from 'src/apis/edgeDataApi';

export const SET_TICKER = '@ticker/set-ticker';
export const SET_TICKER_HISTORY = '@ticker/set-ticker-history';
export const ADD_TICKER_HISTORY = '@ticker/add-ticker-history';
export const REMOVE_TICKER_HISTORY = '@ticker/remove-ticker-history';

const MAX_NUM_TICKERS = 12;



export const setTicker = (ticker, match, sendToDynamo = true) => async (dispatch, getState) => {

  if (ticker && ticker.symbol) {

    const newTicker = await verifyTicker(ticker);

    if(match && shouldAppendTickerToRoute(match)) {
      setBrowserPath(newTicker.symbol, match);
    }

    if (!newTicker.error) {
      const userData = getState().account.user;
      const history = buildTickerHistory(newTicker, userData);

      if (sendToDynamo) {
        dispatch(updateDynamoUser({ tickerHistory: history, selectedTicker: newTicker.symbol }));
      }

      dispatch({ type: SET_TICKER_HISTORY, payload: { history: history, selectedTicker: newTicker.symbol } });
      dispatch({ type: SET_TICKER, payload: newTicker });
    }
  }
};


const shouldAppendTickerToRoute = (match) => {
  const routesWithNoTicker = ['/account', '/history', '/top-lists']
  return !routesWithNoTicker.some(route => match.startsWith(route));
}


const buildTickerHistory = (ticker, userData) => {
  let history = userData.tickerHistory;
  if(!history) {
    return [];
  }
  if (!_find(history, { symbol: ticker.symbol })) {
    const cleanedTicker = {
      symbol: ticker.symbol,
      companyName: ticker?.company?.companyName || ticker?.name
    };
    history = _uniqBy([cleanedTicker, ...history.slice(0, MAX_NUM_TICKERS - 1)], 'symbol');
  }
  return history;
};


const setBrowserPath = (symbol, match) => {
  const params = { tickerId: symbol };
  const path = generatePath(match, params);
  console.log('setBrowserPath:', match, params);
  if (!path || path !== '/') {
    history.push(path);
  } else {
    history.push(`/${symbol}`);
  }
};


// Make sure ticker exists, and also add 'company name' and symbol change information
const verifyTicker = async (ticker) => {
  let newTicker = JSON.parse(JSON.stringify(ticker));
  try {
    const response = await edgeDataApi.get(`/verify-ticker/${ticker.symbol}`)
    newTicker = { ...response.data };
    newTicker.error = false;
  } catch (err) {
    newTicker.error = true;
    console.log('Ticker Price Err: ', err);
  }
  return newTicker;
};



export const updateTickerQuoteData = (ticker) => async dispatch => {
  try {
    const tickerData = await verifyTicker(ticker);
    dispatch({ type: SET_TICKER, payload: tickerData })
  } catch (err) {
    console.log('Ticker Quote Err: ', err);
  }
}


export const removeTickerHistory = (tickerToRemove) => async (dispatch, getState) => {
  const user = getState().account.user;
  const history = user.tickerHistory;

  if(history.length === 1) {
    return;
  }

  let selectedTicker = user.selectedTicker
  if(tickerToRemove.symbol === selectedTicker) {
    const index = _findIndex(history, { symbol: tickerToRemove.symbol });
    let newIndex;
    if(index === history.length - 1) {
      newIndex = index - 1;
    } else {
      newIndex = index + 1;
    }
    selectedTicker = history[newIndex].symbol;
  }

  const newHistory = _filter(history, (o) => {
    return o.symbol !== tickerToRemove.symbol;
  });

  dispatch(updateDynamoUser({ tickerHistory: newHistory, selectedTicker }));
  dispatch({ type: SET_TICKER_HISTORY, payload: { history: newHistory, selectedTicker } });
};


export const reorderTickerHistory = (tickers) => dispatch => {
  dispatch(updateDynamoUser({ tickerHistory: tickers }));
  dispatch({ type: SET_TICKER_HISTORY, payload: { history: tickers } });
}


// set the ticker on page load
export const initializeTicker = (ticker, match) => (dispatch, getState) => {
  if (ticker) {
    dispatch(setTicker({ symbol: ticker }, match, false));
  } else {
    const user = getState().account.user;
    const tickerHistory = user.tickerHistory || [];
    const selectedTicker = user.selectedTicker;
    let tickerFromHistory = _find(tickerHistory, { symbol: selectedTicker }) || { symbol: 'AAPL', name: 'Apple, Inc.' };
    dispatch(setTicker(tickerFromHistory, match, false));
  }
};
