import _cloneDeep from 'lodash/cloneDeep';
import _isEqual from 'lodash/isEqual';
import _update from 'lodash/update';
import _get from 'lodash/get';
import produce from 'immer';
import { getLeaves } from 'react-mosaic-component';
import * as Sentry from '@sentry/react';
import { COMPONENT_TYPES } from 'src/app/TopListsMosaic/layout/components';
import { PREDEF_PREFIX } from '../profileSettings/profileSettingsConfig';
import {
  createDefaultLayoutTemplate,
  createComponentSchema,
  copyExistingLayoutSchema,
  LAYOUT_TEMPLATES,
  PROFILE_CONFIG,
} from 'src/redux/layout/topListLayoutSchema';


/**
 * @summary We had invalid logic during disconnects, causing users to push invalid layouts to the server.
 *    On load, check the layouts here, and reset to default if invalid.
 * 
 * This is rather slow. We're mapping multiple times. But the code is easier to read.
 * 
 * @see topListLayoutActions persistLayout() for details on the error.
 * @param {Object} profile
 * @returns {{updates: Object[]}} nextProfile
 */
export function validateProfile(profile) {
  const updates = [];

  validateTabsExist();       // No updates. Premature.
  validateComponentsExist(); // No updates. Premature.
  validateProfilesExist();   // This one has updates

  function validateProfilesExist() {
    Object.entries(profile?.layouts || {}).forEach(([layoutId, layout]) => {
      let p1 = ['layouts', layoutId]
      Object.entries(layout?.components || {})?.map(([componentId, component]) => {
        let p2 = [...p1, 'components', componentId]
        Object.values(PROFILE_CONFIG)
          .filter(p => !p.nonLayoutState)
          .forEach(({ listKey, idKey, defaultProfileId }) => {
            let path = [...p2, idKey];

            if (!(idKey in component)) return

            let profileId = component[idKey];

            if (profileId.startsWith('predef__')) return;

            const allProfilesOfType = profile[listKey] || [];
            const allIdsOfType = allProfilesOfType.map(p => p.id);

            if (allIdsOfType.includes(profileId)) return;

            updates.push({
              update: (profile) => _update(profile, path, () => defaultProfileId),
              param: (params = {}) => {
                params.putLayouts = params.putLayouts || [];
                params.putLayouts.push(layoutId);
                params.putLayouts = [...new Set(params.putLayouts)];
                return params;
              },
              get: (profile) => _get(profile, path),
              log: {
                attemptingFix: true,
                type: 'profileId references non-existent profile',
                path: path,
                shouldBe: defaultProfileId
              },
            });
          })
      });
    });
  }


  function validateComponentsExist() {
    Object.entries(profile?.layouts || {}).forEach(([layoutId, layout]) => {
      const definedComponentIds = Object.keys(layout?.components || {});
      const nodeComponentIds = getComponentIdsFromNode(layout?.currentNode);

      const missingComponentIds = definedComponentIds.filter(id => !nodeComponentIds.includes(id));
      const missingNodeIds = nodeComponentIds.filter(id => !definedComponentIds.includes(id));

      if (missingComponentIds.length) {
        updates.push({
          log: {
            attemptingFix: false,
            type: 'componentIds missing from currentNode',
            missingIds: missingComponentIds,
            layoutId,
            message: 'component definition exists, but are not present in Mosaic tree',
          },
        });
      }
      if (missingNodeIds.length) {
        updates.push({
          log: {
            attemptingFix: false,
            type: 'componentIds missing from component definitions',
            missingIds: missingNodeIds,
            layoutId,
            message: 'component ids exist inside Mosaic node tree, but are not present component definitions',
            recomendation: 'Remove components from Mosaic root.'
          },
        });
      }
    });
  }


  function validateTabsExist() {
    const layoutTabs = profile?.layoutTabs || [];
    const layoutIds = Object.keys(profile?.layouts || {});

    layoutTabs.forEach(tabId => {
      if (!layoutIds.includes(tabId)) {
        updates.push({
          type: 'layoutTabId missing from layouts',
          data: {
            layoutId: tabId
          },
          message: 'tab specified in layoutTabs does not exist in layouts.',
          recomendation: 'remove from layoutTabs',
          recommendedState: { layoutTabs: profile.layoutTabs.filter(id => id !== tabId) }
        });
      }
    });
  }

  return updates;
}



const getComponentIdsFromNode = (rootNode) => {
  const ids = [];

  const collect = (node) => {
    if (!node) return;

    if (typeof node === 'string') {
      ids.push(node);
    }
    else {
      collect(node.first);
      collect(node.second);
    }
  };

  collect(rootNode);

  return ids;
}




