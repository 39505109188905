import { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import _throttle from 'lodash/throttle';
import _clamp from 'lodash/clamp'
import usePickDeferredSynthVoice from './usePickDeferredSynthVoice';



/**
 * Handles triggering of audio alerts. Allows user to change the audio source without interfering
 * with currently playing audio.
 * @param {string} url - URL of the audio file.
 * @param {number} [options.volume=50] - Volume of the audio. Should be between 0 and 100.
 * @param {boolean} [options.blocking=true] - If true, only one audio can play at a time. If false, multiple can play over each other.
 * @param {number} [options.throttle=250] - Throttle milliseconds between audio triggers. Applies both to blocking and non-blocking.
 * @returns {function[]}  getIsPlaying, triggerPlaying
 **/
export function useMultiAudio(url, {
  volume = 50,
  blocking = true,
  throttle = 150,
} = {}) {
  const [triggerIndex, setTriggerIndex] = useState(0);
  const playingStack = useRef(new Map());

  // Technically not correct, as updating debounce will clear the throttle. Fine for now though.
  const triggerPlaying = useCallback(
    _throttle(
      () => setTriggerIndex((prev) => (prev + 1)),
      throttle,
      { trailing: false }
    ), [throttle])


  const getIsPlaying = useCallback(() => playingStack?.current?.size > 0, []);

  useEffect(() => {
    return () => {
      try {
        for (const audio of playingStack?.current?.values()) {
          // Theoretically helps garbage collection on unmount.
          audio.pause();
          ['canplaythrough', 'ended', 'error', 'abort'].forEach(event => audio.removeEventListener(event));
        }
      } catch (err) {
        console.warn('Error cleaning up audio stack', err)
      } finally {
        playingStack?.current?.clear();
      }
    }
  }, [])

  useEffect(() => {
    if (triggerIndex === 0) return;
    if (blocking && getIsPlaying()) return;

    if (!url) {
      return;
    }

    if (volume < 0 || volume > 100) {
      console.warn('Volume should be between 0 and 100');
    }

    if (volume === 0) {
      return;
    }

    let audio = null;

    const handleStart = () => void audio.play();
    const handleEnd = () => playingStack?.current?.delete(triggerIndex);

    try {
      audio = new Audio(url);
      audio.volume = _clamp(volume / 100, 0, 1);
      playingStack?.current?.set(triggerIndex, audio);

      audio.addEventListener('canplaythrough', handleStart, { once: true });

      audio.addEventListener('ended', handleEnd, { once: true });
      audio.addEventListener('error', handleEnd, { once: true });
      audio.addEventListener('abort', handleEnd, { once: true });
    } catch (err) {
      handleEnd();
    }
  }, [triggerIndex]);

  return useMemo(() => [getIsPlaying, triggerPlaying], [getIsPlaying, triggerPlaying]);
}



/**
 * Callback accepts an ARRAY of statements. This is because we cannot pause the audio
 * inside of a single XML statement while also reading characters individually.
 *
 * Instead, we have to send each ticker individually, and let the Synth put in breaks
 * automatically.
 *
 * Each batch accounts for a single buffer item. So if you send tts(['a', 'b']);  tts(['c', 'd']),
 * with a buffer size of 1, then only ['a', 'b'] will be spoken.
 *
 * NOTE: UPDATE 7/22/24 - NO MORE SSML!
 *  Google does not accept ssml. We're using text, and a single string for all tickers in a batch.
 *  The old batching code is useless, but not harmful.
 *  Example: 'M B O T, A A P L dot S W plus 3 more'
 *
 *
 * PROBLEMS: 
 *  - too much time between tickers
 *  - Queueing means clicking test can drop items
 *  
 *
 *
<speak>
    <s>this is <prosody rate="130%" volume="medium"><say-as interpret-as="characters">aapl</say-as></prosody></s>
    <s>this is <prosody rate="130%" volume="medium"><say-as interpret-as="characters">tsla</say-as></prosody></s>
</speak>
 **/
export function useMakeBufferLimitedTTS(limit = 2) {
  const [voiceName, voiceRate, isFetchingVoice] = usePickDeferredSynthVoice()
  const utteranceStack = useRef(new Map());
  const index = useRef(0);
  const hasCancelled = useRef(0);

  useEffect(() => {
    return () => {
      try {
        utteranceStack.current.forEach(batch => {
          batch.forEach(utterance => {
            ['end', 'error'].forEach(event => utterance.removeEventListener(event));
          })
        })
      } catch (err) {
        console.warn('Error cleaning up audio stack', err);
      } finally {
        utteranceStack.current.clear();
      }
    }
  }, [])


  useEffect(() => {
    // Attempt to do this BS cancelation thing before the first utterance, maybe we can
    // prevent the first utterance from being skipped.
    // Must wait for user interaction.

    const forceCancelVoice = () => {
      if (!voiceName) {
        return;
      }
      const utt = new SpeechSynthesisUtterance('test');
      utt.voice = window.speechSynthesis.getVoices().find(voice => voice.name === voiceName);
      utt.volume = 0;
      window.speechSynthesis.speak(utt);
      window.speechSynthesis.cancel();

      document.removeEventListener('click', forceCancelVoice);
    }

    document.addEventListener('click', forceCancelVoice);

    return () => {
      document.removeEventListener('click', forceCancelVoice);
    }
  }, [Boolean(voiceName)]);


  const getIsPlaying = useCallback(() => utteranceStack.current.size > 0, []);


  const triggerPlayingText = useCallback((textList, volume = 50, rate = 1) => {
    if (!voiceName) {
      console.warn('[triggerPlaying] No voice name set');
      return;
    } else {
      console.debug('[triggerPlaying] voice: ', voiceName);
    }


    if (volume < 0 || volume > 100) {
      console.warn('Volume should be between 0 and 100');
    }

    if (volume === 0) {
      return;
    }

    let batch = textList;
    if (!Array.isArray(textList)) {
      batch = [textList];
    }

    const idx = index.current;

    const handleEnd = (idx_, batchIdx) => (_) => {
      utteranceStack.current?.get(idx_)?.delete(batchIdx);
      if (utteranceStack.current?.get(idx_)?.size === 0) {
        utteranceStack.current.delete(idx_);
      }
    }

    if (batch.length === 0) {
      return;
    }

    if (utteranceStack.current.size >= limit) {
      return;
    }

    const voiceObj = window.speechSynthesis.getVoices().find(voice => voice.name === voiceName);

    if (!voiceObj) {
      console.warn('[triggerPlaying] Voice not found', voiceName);
      return;
    }

    batch.forEach((text, batchIdx) => {
      try {
        const utterance = new SpeechSynthesisUtterance(text);

        utterance.voice = voiceObj;
        utterance.lang = utterance.voice.lang;
        utterance.volume = _clamp(volume / 100, 0, 1);

        utterance.rate = voiceRate * rate; // Modulate the requested rate by the voice JSON's suggested rate.

        if (utteranceStack?.current && !utteranceStack.current?.has(idx)) {
          utteranceStack.current.set(idx, new Map());
        }
        utteranceStack.current.get(idx)?.set(batchIdx, utterance);

        index.current += 1;

        utterance.addEventListener('end', handleEnd(idx, batchIdx), { once: true });
        utterance.addEventListener('error', handleEnd(idx, batchIdx), { once: true });

        if (hasCancelled.current < 2) {
          // Chrome has a bug where it just refuses to work sometimes. Cancelling sometimes seems to fix it.
          // Note, this means (at minimum) the first piece of text will be skipped. Putting the cancel higher up
          // does not help. Audio has to already be triggered.
          // What about user interaction? I'm not sure how this works when the user hasn't clicked the page yet.
          window.speechSynthesis.cancel();
          hasCancelled.current += 1;
        }

        setTimeout(() => window.speechSynthesis.speak(utterance), 0);


      } catch (err) {
        console.warn('Error speaking utterence', err)
        handleEnd();
      }
    });
  }, [voiceName]);


  return useMemo(() => [getIsPlaying, triggerPlayingText], [getIsPlaying, triggerPlayingText]);
}


/**
 * @param {string[]} tickers
 * @returns {string}
 * @example
 * 'M.B.O.T., T.S.L.A. dot W.T.'
 **/
const buildBatchText__period = (tickers) => {
  const dottedTickers = tickers.map(fullTicker => {
    const [ticker, ext] = fullTicker.split('.')

    let dotted = ticker.trim().split('').map(char => `${char}.`).join('');

    if (ext && ext.length) {
      dotted += ` dot ${ext}`;
    }

    return dotted;
  });

  return dottedTickers.join(', ');
}



export function useMakeTickerTTS({
  tickerLimit = 3,
  bufferLimit = 2,
  volume = 50
}) {
  const [getIsPlaying, triggerPlaying] = useMakeBufferLimitedTTS(bufferLimit);

  const triggerPlayingTickers = useCallback(tickers => {
    if (!tickers || !tickers.length) return;

    if (volume === 0) return;

    const spokenTickers = tickers.slice(0, tickerLimit);
    const numLeft = tickers.slice(tickerLimit).length;

    // const volumeDb = convertVolumeToDecibels(volume);
    // const ssmlBatch = spokenTickers.map(ticker => buildTickerSsml(ticker, volumeDb));

    let text = buildBatchText__period(spokenTickers);

    if (numLeft) {
      // ssmlBatch.push(`<speak><prosody rate="133%" volume="${volumeDb}">and ${numLeft} more</prosody></speak>`);
      text += ` and ${numLeft} more`;
    }

    triggerPlaying(text, volume - 5, 1.15); // its louder than other sounds

  }, [triggerPlaying, tickerLimit, volume]);

  return useMemo(() => [getIsPlaying, triggerPlayingTickers], [getIsPlaying, triggerPlayingTickers]);
}


