import {
  Box, makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import React, { useCallback } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import {
  FormGroupContext, useFormGroupContext
} from 'src/app/slicedForm/FilterForm/context/FilterEntityContext';
import { useEntity } from 'src/app/slicedForm/FilterForm/context/useFormEntity';
import { STRUCTURAL_TYPES, } from 'src/app/slicedForm/mapping/mappingDirections/index';
import {
  useFormDispatch
} from 'src/app/slicedForm/shared/context/FormProvider';
import { getAggregateEntityDefaults } from 'src/app/slicedForm/shared/schema/schemaBuilder';
import EntityIconButton from '../FilterForm/elements/EntityIconButton';
import { DragHandle } from '../FilterForm/elements/FilterGroupDragProviders';
import WithColumnPickerForm from '../FilterForm/elements/WithColumnPickerForm';
import { CREATE_CONDITIONAL_AGG, CREATE_VISUAL_GROUP, DELETE_ENTITY, REORDER_LEAF, REPARENT_LEAF } from '../FilterForm/reducers/filterReducer';
import AggregateEntity from './AggregateEntity';
import AggregateGroupTitle from './AggregateGroupTitle';
import { AggregateEntityProvider } from './aggregateEntityContext';




const AND_SPACING = 3;


const useStyles = makeStyles(theme => ({
  root: {
    flex: 1
  },
  aggGroup: {
    padding: theme.spacing(1),
  },
  andGroupButton: {
    '&:focus-visible': {
      ...theme.focus.outline
    },
    borderBottomRightRadius: 4,
    // marginLeft: 22.28, // Drag and drop
    marginLeft: 5,
    '& .MuiButton-label': {
      paddingTop: 2,
      paddingLeft: 6,
      paddingRight: 6
    }
  },
  entityRow_VISUAL_GROUP_container: {
    paddingBottom: theme.spacing(AND_SPACING),
    marginRight: 12

  },
  andGroup: {
    flex: 1,
    border: `1px solid ${theme.palette.background.lightBorder}`,
    backgroundColor: theme.palette.background.paperAlt_darker1,
    borderRadius: 4,
    borderBottomRightRadius: 0,
    position: 'relative',
  },
  draggableWrapper: {
    display: 'flex',
    '& .draggable_icon_top_align': {
      display: 'block',
      paddingTop: 15
    }
  },
  draggableRowInner: {
    flex: 1
  },
  droppable: {
    paddingTop: theme.spacing(AND_SPACING),
  },
  addAggregateButton: {
    marginLeft: 22.28,
    marginBottom: theme.spacing(3)
  }
}));


function Switcher(props) {
  const { type } = props;
  const classes = useStyles();

  switch (type) {
    case STRUCTURAL_TYPES.ROOT:
      return <RootGroup {...props} />;
    case STRUCTURAL_TYPES.VISUAL_GROUP:
      return <VisualGroup {...props} />;
    case STRUCTURAL_TYPES.CONDITIONAL_AGG:
      return (
        <AggregateEntityProvider {...props}>
          <AggregateEntity className={clsx(classes.andGroup, classes.aggGroup)} />
        </AggregateEntityProvider>
      )
    default:
      console.error('Unknown type:', type);
      return null;
  }
}



function RootGroup({ type, tree, id, disabled }) {
  const classes = useStyles();
  const dispatch = useFormDispatch();
  const parentContext = useFormGroupContext();

  const handleDragEnd = useCallback((result) => {
    const { source, destination, type, reason } = result;

    if (!source || !destination) {
      return;
    }

    if (type !== STRUCTURAL_TYPES.VISUAL_GROUP) {
      console.log(`WRONG DROPPABLE TYPE: ${type}`)
      return;
    }

    if (source.droppableId === destination.droppableId) {
      dispatch({
        type: REORDER_LEAF,
        payload: {
          groupId: source.droppableId,
          sourceIndex: source.index,
          destIndex: destination.index
        }
      });
    } else {
      dispatch({
        type: REPARENT_LEAF,
        payload: {
          sourceGroupId: source.droppableId,
          destGroupId: destination.droppableId,
          sourceIndex: source.index,
          destIndex: destination.index
        }
      })
    }
  }, []);

  const handleAddGroup = useCallback(() => {
    dispatch({
      type: CREATE_VISUAL_GROUP,
      payload: {
        groupId: id,
        label: 'Group'
      }
    })
  }, [id]);

  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Box className={clsx(
        classes.root,
        classes[`group_${type}`],
        `group_${type}`
      )}>
        <FormGroupContext.Provider value={{
          ...parentContext,
          groupId: id,
          groupType: type,
        }}>
          {tree.map((node, i) => {
            return <Switcher key={node.id} {...node} disabled={disabled} />
          })}
        </FormGroupContext.Provider>

        <EntityIconButton
          className={clsx(classes.andGroup, classes.andGroupButton)}
          aria-label="Add group"
          title="Add group"
          disabled={disabled}
          onClick={handleAddGroup}
          text
        >
          + Add Group
        </EntityIconButton>
      </Box>
    </DragDropContext>
  )
}


const getListStyle = isDraggingOver => {
  return isDraggingOver ? { pointerEvents: 'none' } : {};
};


const getItemStyle = (provided, snapshot) => {
  const style = {
    userSelect: 'none',
    position: 'relative',
    ...provided.draggableProps.style,
    ...(snapshot.isDragging && { opacity: .6 })
    // cursor: snapshot.draggingOver ? "grabbing" : "not-allowed", // Not working. Can't figure out why
  };
  return style;
};


function VisualGroup({ type, tree, id, disabled }) {
  const classes = useStyles();
  const parentContext = useFormGroupContext();
  const { groupId } = parentContext;
  const dispatch = useFormDispatch();
  const { useRegister } = useEntity(id);
  const { value: label, onChange: onLabelChange } = useRegister('label');


  const handleAddAggregate = useCallback((colDef) => {
    const defaults = getAggregateEntityDefaults(colDef);
    if (!defaults) {
      console.warn(`No defaults found for column ${colDef}`);
      return;
    }

    dispatch({
      type: CREATE_CONDITIONAL_AGG,
      payload: {
        groupId: id,
        data: defaults
      }
    })
  }, [getAggregateEntityDefaults, id]);


  const handleDeleteGroup = useCallback(() => {
    dispatch({
      type: DELETE_ENTITY,
      payload: {
        id,
        groupId,
      }
    })
  }, [id, groupId])


  return (
    <Box className={clsx(
      classes[`group_${type}`],
      `group_${type}`
    )}>
      <AggregateGroupTitle
        label={label}
        disabled={disabled}
        onChange={onLabelChange}
        onDelete={handleDeleteGroup}
      />

      <FormGroupContext.Provider value={{
        ...parentContext,
        groupId: id,
        groupType: type,
      }}>
        <Droppable
          droppableId={id}
          type={type}
          isDropDisabled={disabled}
          groupId={id}
          groupType={type}
          reparent
        >
          {(provided, snapshot) => (
            <div
              ref={provided.innerRef}
              {...provided.droppableProps}
              className={clsx(classes.droppable)}
              style={getListStyle(snapshot.isDraggingOver)}
            >
              {tree.map((node, i) => (
                <Draggable
                  draggableId={`${node.type}__${node.id}`}
                  index={i}
                  isDragDisabled={disabled}
                  key={node.id}
                >
                  {(provided, snapshot) => (
                    <div
                      {...provided.draggableProps}
                      ref={provided.innerRef}
                      style={getItemStyle(provided, snapshot)}
                      className={clsx(
                        classes[`entityRow_${type}_container`],
                        classes.draggableWrapper
                      )}
                    >
                      <DragHandle {...provided.dragHandleProps} disabled={disabled} />
                      <div className={classes.draggableRowInner}>
                        <Switcher key={node.id} {...node} disabled={disabled} />
                      </div>
                    </div>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      </FormGroupContext.Provider>

      <WithColumnPickerForm
        anchorPosition="left"
        onSelect={handleAddAggregate}
      >
        <EntityIconButton
          className={clsx(
            classes.addAggregateButton,
            classes.andGroup,
            classes.aggGroup
          )}
          aria-label="Add aggregate"
          title="Add aggregate"
          disabled={disabled}
          text
        >
          + Add Aggregate
        </EntityIconButton>
      </WithColumnPickerForm>
    </Box>
  )
}


export default Switcher;
