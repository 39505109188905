import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import _noop from 'lodash/noop';
import { PatternFormat } from 'react-number-format';
import { format as formatDate, isAfter, isBefore, isMatch, isValid, isWeekend, parse } from 'date-fns';
import { getCurrentTradingDay, isDateHoliday, parseAssumeMarketTime } from 'src/utils/datetime/date-fns.tz';
import { makeStyles, TextField, } from '@material-ui/core';
import {
  generateMask,
  makeInputDateString,
} from 'src/app/components/pickers/definitions/maskFunctions';


const useStyles = makeStyles(theme => ({
  root: {
  },
  noUnderline: {
    '& > .Mui-error > input': {
      color: theme.palette.error.main
    }
  },
  errorPositionAbsolute: {
    position: 'relative',
    '& > .MuiFormHelperText-root': {
      whiteSpace: 'nowrap',
      margin: 0,
      padding: 0,
      position: 'absolute',
      bottom: 0,
      left: 0,
      transform: 'translateY(100%)',
    }
  }
}));


export const patternChar = '#';


/**
 * KeyboardInput for dates
 * @component
 */
function MaskedDateInput({
  className,
  format,
  placeholder,
  acceptStrategy,
  inputVariant,
  mask,
  date,
  minDate,
  maxDate,
  disabled,
  marketTime,
  disableHoliday,
  disableWeekend,
  errorPositionAbsolute,
  disableFuture,
  disabledDay,
  disableUnderline,
  showErrorMessage,
  allowNull,
  autoFocus,
  onAccept,
  onKeyUp,
  inputRef,
  endAdornment
}) {
  const classes = useStyles();
  const [error, setError] = useState(false);
  const [inputValue, setInputValue] = useState(makeInputDateString(date, format));

  if (maxDate && disableFuture) throw Error('Cannot have both maxDate and disableFuture');

  useEffect(() => {
    setInputValue(makeInputDateString(date, format));
  }, [date]);


  const pattern = useMemo(() => generateMask(format, patternChar), [format]);


  /**
   * @param {string} formattedValue
   * @return {{newDate: Date|false, error: string|null}} - Exclusive. Either newDate or error will be set.
   */
  const parseAndValidateDate = (formattedValue) => {
    let response = { newDate: false, error: null };
    let newDate = null;

    try {
      if (!formattedValue && allowNull) {
        return { ...response, newDate };
      }
      if (!isMatch(formattedValue, format)) {
        return { ...response, error: 'Invalid date format' };
      }

      newDate = marketTime
        ? parseAssumeMarketTime(formattedValue, format)
        : parse(formattedValue, format, new Date());

      if (!isValid(newDate)) {
        return { ...response, error: 'Invalid date' };
      }
      if (minDate && isBefore(newDate, minDate)) {
        return { ...response, error: `No data before ${formatDate(minDate, format)}` };
      }
      if (maxDate && isAfter(newDate, maxDate)) {
        return { ...response, error: `No data after ${formatDate(maxDate, format)}` };
      }
      if (disableFuture) {
        const today = marketTime ? getCurrentTradingDay() : new Date();
        if (isAfter(newDate, today)) {
          return { ...response, error: 'No data in the future' };
        }
      }
      if (disableWeekend && isWeekend(newDate)) {
        return { ...response, error: 'No data on weekends' };
      }
      if (disableHoliday && isDateHoliday(newDate)) {
        return { ...response, error: 'No data on holidays' };
      }
      if (disabledDay && disabledDay(newDate)) {
        return { ...response, error: 'No data on this day' };
      }
    } catch (err) {
      console.error(err);
      return { ...response, error: 'Invalid date format' };
    }

    return { ...response, newDate };
  };


  /**
   * Update internal state no matter what.
   * Check and set errors no matter what.
   * If acceptStrategy is onChange and the inputValue is valid, update parent state.
   * @param {{value: number, formattedValue: string}} values
   * @param {Event} sourceInfo
   * @returns {undefined}
   */
  const handleChange = (values, sourceInfo) => {
    const { formattedValue } = values;
    setInputValue(formattedValue);

    const { newDate, error } = parseAndValidateDate(formattedValue);
    setError(error); // set even if null, to clear errors

    if (acceptStrategy === 'onChange' && !error) {
      onAccept(newDate);
    }
  };


  /**
   * No matter what, reset the inputValue if it is invalid.
   * If acceptStrategy is onBlur the value is valid, update the parent state.
   * Do not set errors. Not necissary.
   * @param {string} formattedValue
   * @returns {undefined}
   */
  const handleBlur = (formattedValue) => {
    const { newDate } = parseAndValidateDate(formattedValue);
    if (acceptStrategy === 'onBlur' && !error) {
      onAccept(newDate);
    } else if (!newDate) {
      // reset self to parent state if invalid
      setInputValue(makeInputDateString(date, format));
    }
  };


  /**
   * Sometimes we want "Enter" to close a modal.
   * Allow "Enter" to also work as onBlur(), so a user doesn't lose their work.
   * Consequence being "Enter" with a bad input will revert.
   * @param event
   * @param {string} formattedValue
   * @returns {undefined}
   */
  const handleEnter = (event, formattedValue) => {
    if (event.key === 'Enter') {
      // allow propagation. Stop before it bubbles to the popup open button, if applicable.
      handleBlur(formattedValue);
    }
  };

  const inputProps = {
    disableUnderline,
    variant: inputVariant,
    disabled,
    inputRef,
    ...(Boolean(endAdornment) && { endAdornment })
  }

  return (
    <PatternFormat
      className={clsx(
        className,
        classes.root,
        {
          [classes.noUnderline]: disableUnderline,
          'range-end-adornment': Boolean(endAdornment)
        },
        errorPositionAbsolute && classes.errorPositionAbsolute
      )}
      autoFocus={autoFocus}
      placeholder={placeholder}
      error={Boolean(error)}
      helperText={showErrorMessage && error}
      format={pattern}
      value={inputValue}
      customInput={TextField}
      InputProps={inputProps}
      patternChar={patternChar}
      mask={mask}
      onBlur={() => handleBlur(inputValue)}
      onKeyDown={(event) => handleEnter(event, inputValue)}
      onKeyUp={onKeyUp}
      onValueChange={handleChange}
    />
  );
}

export const MaskedDateInputPropTypes = {
  className: PropTypes.string,
  /** date-fns Unicode date formatting */
  format: PropTypes.string.isRequired,
  /** Shown when input is empty */
  placeholder: PropTypes.string.isRequired,
  /** What type of callback should trigger the parent state to update */
  acceptStrategy: PropTypes.oneOf(['onBlur', 'onChange']).isRequired,
  /** Styling of the input */
  variant: PropTypes.oneOf(['standard', 'outlined', 'filled']),
  /** The character to show in place of empty positions */
  mask: PropTypes.string,
  /** Partial control */
  date: PropTypes.instanceOf(Date),
  /** How does the parent component update? */
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  disabled: PropTypes.bool,
  /**
   * Convert the Date coming out of the picker to Market Time.
   * Assumes your min/max/disable dates are already converted to market time.
   */
  marketTime: PropTypes.bool.isRequired,
  disableHoliday: PropTypes.bool,
  disableWeekend: PropTypes.bool,
  disableFuture: PropTypes.bool,
  /** Hide the bottom border */
  disableUnderline: PropTypes.bool,
  /** Print the error message under the input */
  showErrorMessage: PropTypes.bool,
  autoFocus: PropTypes.bool,
  /** Allow exclusion of any date */
  disabledDay: PropTypes.func,
  /** Valid input has been accepted, via 'strategy' prop */
  onAccept: PropTypes.func.isRequired,
  /** Internally used for DateRange picker focus */
  onKeyUp: PropTypes.func,
  /** Internall used for DateRange picker focus */
  inputRef: PropTypes.object,
  /** JSX to render an icon/button inside the input */
  endAdornment: PropTypes.node,
}

MaskedDateInput.propTypes = {
  ...MaskedDateInputPropTypes
};

export const MaskedDateInputDefaultProps = {
  acceptStrategy: 'onBlur',
  variant: 'standard',
  mask: '_',
  disabled: false,
  marketTime: false,
  disableHoliday: false,
  disableWeekend: false,
  disableFuture: false,
  disableUnderline: false,
  showErrorMessage: true,
  autoFocus: false,
  onKeyUp: _noop
}


MaskedDateInput.defaultProps = {
  ...MaskedDateInputDefaultProps
};


export default React.memo(MaskedDateInput);
