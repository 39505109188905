import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { history } from 'src/App';
import { useRouteMatch } from 'react-router-dom';
import { setTicker } from 'src/redux/ticker/tickerActions';
import {
  // overviewIconContent,
  financialsIconContent,
  filingsIconContent,
  insidersIconContent,
} from 'src/theme/EdgeIcons';

const PLACEHOLDER = ':tickerId';

const MENU = [
  // { path: 'overview', label: 'Overview', link: `/overview/${PLACEHOLDER}`, iconHtml: overviewIconContent },
  { path: 'financials', label: 'Financials', link: `/financials/${PLACEHOLDER}`, iconHtml: financialsIconContent },
  { path: 'SEC', label: 'Filings', link: `/SEC/${PLACEHOLDER}`, iconHtml: filingsIconContent },
  // { path: 'insiders', label: 'Insiders', link: `/insiders/${PLACEHOLDER}`, iconHtml: insidersIconContent },
];


function useRouterLinksContextMenu() {
  const dispatch = useDispatch();
  const match = useRouteMatch();
  const currentPath = match.path.split('/').filter(s => s)[0];


  return useCallback((contextMenuParams) => {
    const ticker = contextMenuParams?.node?.data?.ticker;
    if (!ticker) return [];

    return MENU
      .filter(({ path }) => path !== currentPath)
      .map(({ label, link, iconHtml }) => {
        const url = link.replace(PLACEHOLDER, ticker);
        return (
          {
            name: label,
            icon: `<span class="ett-context-menu-icon">${iconHtml}</span>`,
            action: () => {
              dispatch(setTicker({ symbol: ticker }));
              history.push(url);
            }
          }
        );
      });
  }, [currentPath]);
}

export default useRouterLinksContextMenu;
