import React, { useState, useEffect } from 'react';
import { useRouteMatch } from 'react-router-dom';
import useSettings from 'src/hooks/useSettings';
import { useDispatch, useSelector, batch } from 'react-redux';
import { PROFS } from 'src/redux/profileSettings/profileSettingsConfig';
import { useTickerIdParams } from 'src/hooks/useTickerIdParams';
import { initializeTicker } from 'src/redux/ticker/tickerActions';
import { fetchGlobalUserSettings, fetchPageProfileSettings } from 'src/redux/profileSettings/profileSettingsActions';
import { fetchHistoryPendingExports } from 'src/redux/exports/exportActions';
import NavBar from './NavBar';
import TabsBar from './TabsBar/GeneralTabsBar';
import TopBar from './TopBar';
import {
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%'
  },
  wrapper: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 0,
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.mobileTopBarHeight
    },
    [theme.breakpoints.up('sm')]: {
      paddingLeft: theme.drawerWidth
    }
  },
  container: {
    [theme.breakpoints.up('sm')]: {
      paddingLeft: 32,
      paddingRight: 32
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden'
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    // TODO: CAREFUL
    display: 'flex',
    flexDirection: 'column'
  },
  tickerError: {
    paddingTop: 20,
    paddingLeft: 30
  }
}));

// hasTabsBar - include the ticker history tabs
// redirectToTicker - if the page is /overview/, then force a ticker on to the end of the path from ticker history


function AppLayout({ children, hasTabsBar, hideTickerSearch }) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { settings, saveSettings } = useSettings();
  const match = useRouteMatch();
  const tickerId = useTickerIdParams();
  const userSub = useSelector(state => state.account.user.userSub);
  const ticker = useSelector(state => state.ticker);
  const [isMobileNavOpen, setMobileNavOpen] = useState(false);


  useEffect(() => {
    if (settings.inititalAppLayoutLoad) {
      saveSettings({ ...settings, inititalAppLayoutLoad: false });

      batch(() => {
        dispatch(initializeTicker(tickerId, match.path));
        // expressions and GLOBAL
        dispatch(fetchGlobalUserSettings());
        // pending exports should be downloaded no matter what page
        dispatch(fetchHistoryPendingExports());
      })
    }
  }, []);

  // useEffect(() => {
  //     dispatch(fetchPageProfileSettings(PROFS.page.GLOBAL));
  // }, [userSub]);

  const TabsBarWithProps = () => {
    return <TabsBar
      onMobileNavOpen={() => setMobileNavOpen(true)}
    />;
  };

  const renderContent = () => {
    if (ticker.error) {
      return (
        <>
          {TabsBarWithProps()}
          <Box className={classes.tickerError}>
            <Typography variant="h2" color="textPrimary">{`No ticker found with symbol: ${ticker.symbol}`}</Typography>
          </Box>
        </>
      );
    } else {
      return (
        <>
          {hasTabsBar && TabsBarWithProps()}
          {children}
        </>
      );
    }
  };


  return (
    <div className={classes.root}>
      <TopBar
        onMobileNavOpen={() => setMobileNavOpen(true)}
        hideTickerSearch={hideTickerSearch}
      />
      <NavBar
        onMobileClose={() => setMobileNavOpen(false)}
        openMobile={isMobileNavOpen}
      />
      <div className={classes.wrapper}>
        <div className={classes.contentContainer}>
          <div className={classes.content}>
            {renderContent()}
          </div>
        </div>
      </div>
    </div>
  );
}

AppLayout.defaultProps = {
  hasTabsBar: false,
  redirectToTicker: false
};

export default AppLayout;
