import React, { useState, useCallback, useEffect, useRef } from 'react';
import { useLocalStorageWithExpiry } from './useLocalStorage';


/**
 * @typedef {Object} JsonVoice
 * @property {string} label - Human readable name of the voice
 * @property {string} name - Name of the voice
 * @property {Array<string>} [altNames] - Alternative names for the voice, based on MacOS localization
 * @property {string} language - Language code
 * @property {string} [gender]
 * @property {Array<string>} [quality] - Voice quality
 * @property {number} rate - Speaking rate
 * @property {number} [pitch] - Speaking pitch
 * @property {boolean} [pitchControl] - Can set pitch
 * @property {Array<string>} [os] - OSes the voice is available on
 * @property {Array<string>} [browser] - Browsers the voice is available on
 **/


/**
 * Voices are deffered by the browser. Wait for ready if needed.
 **/
function useAreVoicesReady() {
  const [loaded, setLoaded] = useState(() => window?.speechSynthesis?.getVoices()?.length > 0);

  useEffect(() => {
    let current = true;

    // already loaded, no need to add to deps array because "loaded" is fresh on initial render.
    if (loaded) return;

    async function getVoices() {
      const GET_VOICES_TIMEOUT = 2000;

      if (window.speechSynthesis.getVoices()?.length > 0 && current) {
        return setLoaded(true);
      }

      let voiceschanged = new Promise(
        r => speechSynthesis.addEventListener(
          "voiceschanged", r, { once: true }));

      let timeout = new Promise(r => setTimeout(r, GET_VOICES_TIMEOUT));

      // whatever happens first, a voiceschanged event or a timeout.
      await Promise.race([voiceschanged, timeout]);

      if (window.speechSynthesis.getVoices().length > 0 && current) {
        setLoaded(true);
      }
    }

    void getVoices();

    return () => current = false;
  }, [])

  return loaded;
}


/**
 * Filter our list of reccomended voices based on whats available to the OS
 * @param {Array<JsonVoice>} voiceData - List of voices
 * @returns {Array<JsonVoice>} - List of available voices
 **/
function filterAvailableVoices(voiceData) {
  if (!voiceData) return [];

  const osVoices = window.speechSynthesis.getVoices();

  return voiceData.reduce((acc, voice) => {
    if (osVoices.some(apiVoice => apiVoice.name === voice.name)) {
      acc.push(voice);
    } else if (voice.altNames) {
      voice.altNames.forEach(altName => {
        if (osVoices.some(osVoice => osVoice.name === altName)) {
          voice.name = altName;
          acc.push(voice);
        }
      })
    }
    return acc;
  }, []);
}


function extractRegionFromLang(lang) {
  if (!lang) return null;
  const parts = lang.split('-');
  return parts.length > 1 ? parts[1] : null;
}


function groupVoicesByRegion(voices) {
  const regions = {};

  voices.forEach(voice => {
    const region = extractRegionFromLang(voice.language) || 'Other';
    if (!regions[region]) {
      regions[region] = [];
    }
    regions[region].push(voice);
  });

  return regions;
}


function sortVoicesByRegionPreference(groupedVoices) {
  const primaryRegion = ['US', 'UK', 'Other']
  // const acceptLanguages = navigator.languages;
  // const primaryRegion = acceptLanguages.map(lang => extractRegionFromLang(lang) || 'Other');

  const sortedVoices = [];

  primaryRegion.forEach(region => {
    if (groupedVoices[region]) {
      sortedVoices.push(...groupedVoices[region]);
      delete groupedVoices[region];
    }
  });

  for (const region in groupedVoices) {
    sortedVoices.push(...groupedVoices[region]);
  }

  return sortedVoices;
}


const pickVoice = (availableVoices) => {
  // Dedup first, some labels are duplicates. Pick top one.
  const labelToVoice = availableVoices.reduce((acc, voice) => {
    if (voice.label in acc) return acc;
    return { ...acc, [voice.label]: voice }
  }, {});

  // We know these voices sound good.
  const preferredVoices = [
    { name: 'Female Google voice (US)', cusRate: 1 }, // Chrome
    { name: 'Aria (US)', cusRate: 1 }, // Windows/Edge
    { name: 'Samantha (US)', cusRate: .84 }, // MacOS
  ];

  for (const voice of preferredVoices) {
    if (voice.name in labelToVoice) {
      labelToVoice[voice.name].rate = voice.cusRate;
      return labelToVoice[voice.name];
    }
  }

  return availableVoices[0];
}


/**
 * Last resort, just pick the first available OS voice
 * @returns {JsonVoice}
 **/
function getLastResortVoice() {
  const enUsCodes = ['en-us', 'en_us', 'eng-us-f000'];
  const osVoice = window.speechSynthesis.getVoices().find(voice => enUsCodes.includes(voice.lang.toLowerCase()));
  if (!osVoice) return null;

  return {
    name: osVoice.name,
    label: osVoice?.voiceURI || osVoice.name,
    language: osVoice.lang
  }
}


function getVoiceObject(voiceName) {
  const osVoice = window.speechSynthesis.getVoices().find(voice => voice.name === voiceName);
  return osVoice || null;
}

const days = (d) => d * 24 * 60 * 60 * 1000;

// dedup console logs
let init = false;

/**
 * Web Speech API is absolute misery. The voices depend on browser & OS.
 * This hook attempts to select the best voice available to the user.
 *
 * NOTE: I'm not sure how race conditions work with async import(...). 
 *  Maybe move this into a hook. Maybe save a global promise.
 *  My intuition says: Second fetch will auto-await for first, since imports 
 *  work across files anyways. Preliminary testing is showing no race condition issues.
 *
 * Steps:
 * - Wait for voices API to be available (areVoicesReady)
 * - Check localstorage for voice
 *    - If expired, delete and continue
 *    - If valid, but voice not found, delete and continue
 *    - If valid and voice exists, retrurn
 * 
 * - deferred load voiceJson data
 * - Pick a voice for the OS
 *   - If no voice available, 
 *      - pick a last resort voice
 *        - If no default available:
 *            Well, then voices should never have loaded in the fist place. Not a concern.
 *        - else:
 *          - set short Expiry (couple days?)
 *   - If available:
 *      - set voice in Localstorage
 *      - set long expiry
 *
 *  - If any of that errored:
 *    - Set a default and short expiry
 *
 *  - Finally, return either voice.name, or null.
 *
 *  CONSUMERS:
 *  simply get the voice name. If not exists, then just return early and try again later.
 *
 *
 * @param {string} [storageKey='speechSynthesisVoiceName'] - Localstorage key
 * @returns {Array} \[voice, isFetching\]
 **/
export default function usePickDeferredSynthVoice(storageKey = 'v2#speechSynthesisVoiceData') {
  const voicesReady = useAreVoicesReady()
  const [isFetching, setIsFetching] = useState(true);
  const [storedVoiceInfo, setStoredVoiceInfo, deleteStoredVoiceInfo] = useLocalStorageWithExpiry(storageKey);

  useEffect(() => {
    let current = true;

    if (!voicesReady) return;

    const storedVoiceName = storedVoiceInfo?.name;

    if (storedVoiceName) {
      if (getVoiceObject(storedVoiceName)) {
        // Stored voice valid.
        if (!init) {
          console.log('[usePickDeferredSynthVoice] Using stored voice', storedVoiceName);
        }
        setIsFetching(false);
        init = true;
        return;
      } else {
        if (!init) {
          console.log('[usePickDeferredSynthVoice] Stored voice not found, deleting:', storedVoiceName)
        }
        // Stored voice is no longer valid.
        deleteStoredVoiceInfo();
        init = true;
      }
    }

    // Load json dynamically
    import('src/assets/deferred/voices-en.json')
      .then(({ default: voices }) => {
        if (!current) return;

        const availableVoices = filterAvailableVoices(voices);
        const groupedVoices = groupVoicesByRegion(availableVoices);
        const sortedVoices = sortVoicesByRegionPreference(groupedVoices);

        if (sortedVoices.length) {
          const voice = pickVoice(sortedVoices);
          console.log('[usePickDeferredSynthVoice: fetch] Using fetched voice', voice.name);
          return setStoredVoiceInfo(
            { name: voice.name, rate: voice?.rate || 1 },
            process.env.REACT_APP_USERS_LAMBDA_STAGE === 'local' ? days(1) : days(30)
          );
        }

        const defaultVoice = getLastResortVoice();

        if (!defaultVoice) {
          console.warn('[usePickDeferredSynthVoice: fetch] No default voice available, this should not be possible.');
          return;
        }

        console.log('[usePickDeferredSynthVoice] Using last resort voice', defaultVoice.name, defaultVoice);
        setStoredVoiceInfo(
          { name: defaultVoice.name, rate: 1 },
          days(3)
        )

      }).catch(reason => {
        if (!current) return;
        console.error('[useLoadVoiceDeferred: fetch] Failed to load voice data', reason);
        const defaultVoice = getLastResortVoice();
        if (!defaultVoice) {
          console.warn('[usePickDeferredSynthVoice: fetch] No default voice available, this should not be possible.');
          return;
        }
        setStoredVoiceInfo(
          { name: defaultVoice.name, rate: 1 },
          days(1)
        );
      }).finally(() => {
        if (!current) return;
        setIsFetching(false);
      });

    return () => current = false;
  }, [voicesReady])


  return [storedVoiceInfo?.name, storedVoiceInfo?.rate, isFetching];
}
