import React from 'react';

const SPECIAL_FIRST_CHARS = ['$'];
const SPECIAL_LAST_CHARS = ['K', 'M', 'B', 'T', '%', 'x'];


export function getCompactCellParts(value) {
  let prefix = null;
  let postfix = null;
  let middle = value;

  if (!typeof value === 'string' || value === '' || value === null || value === undefined) {
    return [null, middle, null];
  }

  if (SPECIAL_FIRST_CHARS.includes(value[0])) {
    prefix = value[0];
    middle = middle.slice(1);
  }

  const lastTwoChars = value.slice(-2);

  if (lastTwoChars.length >= 2 && lastTwoChars.split('').every(char => SPECIAL_LAST_CHARS.includes(char))) {
    postfix = lastTwoChars;
    middle = middle.slice(0, -2);
  } else if (SPECIAL_LAST_CHARS.includes(value.slice(-1))) {
    postfix = value.slice(-1);
    middle = middle.slice(0, -1);
  }

  return [
    prefix,
    middle,
    postfix
  ];
}


function CompactNumberCell(props) {
  const cellValue = props.valueFormatted ? props.valueFormatted : props.value;
  const [prefix, middle, postfix] = getCompactCellParts(cellValue);

  return (
    <div className="compact-cell ag-right-aligned-cell">
      {Boolean(prefix) && <span className="c --first">{prefix}</span>}
      {middle}
      {Boolean(postfix) && <span className="c --last">{postfix}</span>}
    </div>
  )
}


export default CompactNumberCell;
