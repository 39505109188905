import PromiseHelper from 'src/utils/PromiseHelper'
import _mergeWith from 'lodash/mergeWith';
import _union from 'lodash/union';
import _isArray from 'lodash/isArray';
import edgeDataApi from 'src/apis/edgeDataApi';


/*
 Merges seperate TopListNews fetchQuote requests into a single HTTP request, to save on network costs.
*/


const NewsQuoteRequestCombiner = {
  dataSources: new Map(),
  batchingTime: 2000,  // Will wait until either all registered datasources make a request, or until this timeout limit
  currentBatch: [],


  fetchQuotes: async function() {
    if (!this.currentBatch.length) return;
    const batchedParams = this.currentBatch;
    this.currentBatch = [];

    const { columns, tickers } = mergeParams(batchedParams.map(({ params }) => params));

    if (!columns || !columns.length || !tickers || !tickers.length)  return;

    let data = null;

    try {
      const response = await edgeDataApi.post('/quotes/v2', { columns, tickers });
      data = response?.data;
    } catch (err) {
      console.error(`NewsQuoteRequestCombiner failed to fetch quotes ${err.toString()}`)
      return;
    }

    // debugger;
    if (!data || !Object.keys(data).length) return

    batchedParams.forEach(({ onResolve }) => {
      onResolve(data);
    })
  },

  combineRequest: async function (request) {
    this.currentBatch.push(request);

    if (this.currentBatch.length === this.dataSources.size) {
      await this.fetchQuotes();
    }

    await PromiseHelper.sleep(this.batchingTime);

    await this.fetchQuotes();
  },

  registerDataFeed: function (id) {
    this.dataSources.set(id, true);
  },

  unregisterDataFeed: function (id) {
    this.dataSources.delete(id);
  }
};



const mergeParams = (paramSets) => _mergeWith(
  {},
  ...paramSets,
  (objValue, srcValue) => {
  if (_isArray(objValue)) {
    return _union(objValue, srcValue);
  }
});




export default NewsQuoteRequestCombiner;
