import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import authService from 'src/services/authService';

// Verify the JWT token allows beta access. Check the user dynamo data first so we don't bother fetching the token if not required.
// CAUTION: I belive we still use this for some admin users.
export default function useVerifyBetaAccess() {
  const user = useSelector(state => state.account.user);
  const [hasAccess, setHasAccess] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    let current = true;

    if (user?.userSub) {
      (async () => {
        const { session } = await authService.getSession();
        const token = session.getIdToken().payload;
        if (current) {
          setHasAccess(token?.['custom:beta_access'] === 'true');
          setIsLoading(false);
        }
      })();
    }

    return () => current = false;
  }, [user?.userSub]);

  return [hasAccess, isLoading];
}
