import axios from 'axios';
import { stagedApis } from 'src/constants';

/*
HTTP api gateway implementation, used to make lots of requests cheaply.
Used in conjunction with the top-lists page, and other services that revolve around Ably.
Note: This is not a websocket connection itself, just an api with endpoints that help the realtime service.
*/

export default axios.create({
  baseURL: stagedApis.edgeDataApi
  // baseURL: 'https://fz3uv4s4b4.execute-api.us-east-2.amazonaws.com/data/'
});
