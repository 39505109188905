import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import Item from './Item';
import { Switch, Case } from 'src/app/components/utility/SwitchCase';
import { ListContext, DoubleConfirmationProvider } from './context';
import { useListStyles } from './styles';
import {
  List as MUIList,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  makeStyles, Typography
} from "@material-ui/core";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";


const useStyles = makeStyles(() => ({
  root: {},
  selected: {}
}))


function VirtualList({
  className,
  itemCount,
  itemHeight,
  itemData,
  itemKeyCallback,
  disabled,
  role,
  children,
  listRef,
  ...rest
}) {
  const listClasses = useListStyles();
  const classes = useStyles();

  const context = useMemo(() => ({
    virtual: true,
    draggable: false,
    disabled,
    role
  }), [role, disabled])


  return (
    <ListContext.Provider value={context}>
      <AutoSizer>
        {({ width, height }) => (
          <FixedSizeList
            className={clsx(className, classes.root, listClasses.root)}
            role={role}
            itemKey={itemKeyCallback}
            itemCount={itemCount}
            itemSize={itemHeight}
            width={width}
            height={height}
            itemData={itemData}
            ref={listRef}
            {...rest}
          >
            {children}
          </FixedSizeList>
        )}
      </AutoSizer>
    </ListContext.Provider>
  )
}


VirtualList.displayName = 'ActionList.Virtual'


VirtualList.propTypes = {
  className: PropTypes.string,
  /** The number of items to be rendered */
  itemCount: PropTypes.number.isRequired,
  disabled: PropTypes.bool,
  /** The fixed height of each individual list item */
  itemHeight: PropTypes.number.isRequired,
  /** Aria-Role. Usually listbox or menu */
  role: PropTypes.string,
  /** A callback that accepts (index) argument, to retrieve the item key. Defaults to item index */
  itemKeyCallback: PropTypes.func,
  /**
   * A callback function that accepts ({ index, style }) as arguments, and returns a Component.
   * It is best practice to memoize this function.
   * @example
   * <VirtualList>
   *   {({index, style}) => <Item style={style} item={items[index]} />}
   * </VirtualList>
   */
}


VirtualList.defaultProps = {
  virtualized: false,
  disabled: false,
  role: 'listbox',
  itemHeight: 35,
  itemKeyCallback: ({ index }) => index,
}


export default VirtualList;
