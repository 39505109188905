/* eslint-disable no-param-reassign */
import produce from 'immer';

import {
  FETCH_KEYSTATS,
  FETCH_KEYSTATS_COMBINED,
  UPDATE_KEYSTATS_QUOTE,
  UPDATE_KEYSTATS_RVOL,
  REQUEST__FETCH_KEYSTATS
} from 'src/redux/keyStats/keyStatsActions';


export const initialState = {
  data: {
    rvol: '',
    rvolCalculated: '',
  },
  quote: {
    regular: {},
    extended: {}
  },
  financials: {
    quarterly: {},
    annual: {},
    primary_sheet: 'quarterly'
  },
  isFetching: {
    keyStats: true,
  },
};


const keyStatsReducer = (state = initialState, action) => {

  switch (action.type) {

    case FETCH_KEYSTATS: {
      return produce(state, (draft) => {
        draft.isFetching.keyStats = false;
        draft.data = action.payload || [];
      });
    }

    case FETCH_KEYSTATS_COMBINED: {
      return produce(state, (draft) => {
        draft.isFetching.keyStats = false;
        if (!action.payload) {
          draft.data = initialState.data;
          draft.financials = initialState.financials;
        } else {
          draft.data = action.payload.data;
          draft.financials = action.payload.financials;
          draft.financials.primary_sheet = action.payload.primary_sheet
        }
      });
    }

    case UPDATE_KEYSTATS_QUOTE: {
      return produce(state, (draft) => {
        draft.quote.regular = {};
        draft.quote.extended = {};
        if (action?.payload?.regular) {
          draft.quote.regular = action.payload.regular;
        }
        if (action?.payload?.extended) {
          draft.quote.extended = action.payload.extended;
        }
      });
    }

    case REQUEST__FETCH_KEYSTATS: {
      return produce(state, (draft) => {
        draft.isFetching.keyStats = true;
        draft.data = initialState.data;
        draft.financials = initialState.financials;
      });
    }

    default: {
      return state;
    }
  }
};

export default keyStatsReducer;
