import {
  Box,
  Button,
  makeStyles,
} from '@material-ui/core';
import clsx from 'clsx';
import _uniqueId from 'lodash/uniqueId';
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import ActionList, { useDoubleConfirmationState } from 'src/app/components/ActionList';
import FormModal from 'src/app/components/FormModal';
import useKeyboardMenuNavigation from 'src/app/components/SearchableMultiSelect/useKeyboardMenuNavigation';
import AccountLimitTooltip, { DefaultMaxReachedTooltip } from 'src/app/components/utility/AccountLimitTooltip';
import ConditionalWrapper from 'src/app/components/utility/ConditionalWrapper';
import { ColumnCheckboxRow } from 'src/app/slicedForm/ColumnForm/elements/ColumnListItems';
import { ExpressionCheckboxRow } from 'src/app/slicedForm/ColumnForm/elements/ExpressionListItems';
import { selectColumnsAsList } from "src/app/slicedForm/ColumnForm/reducers/columnReducer";
import { useColumnDefs, useFuseOptions } from "src/app/slicedForm/shared/context/ColumnDefsProvider";
import { useFormSelector } from "src/app/slicedForm/shared/context/FormProvider";
import ColumnGroupSelector from "src/app/slicedForm/shared/elements/ColumnGroupSelector";
import MetricSearchInput from 'src/app/slicedForm/shared/elements/MetricSearchInput';
import ProfilesSection from 'src/app/slicedForm/shared/elements/ProfilesSection';
import useMetricFilter from "src/hooks/useFilterFormMetrics";
import useUserPlanPermissions from 'src/hooks/useUserPlanPermissions';
import { useIsExpressionsAvailable } from 'src/redux/expressions/globalExpressionContext';
import { useIsEditingDisabled } from '../../shared/context/FormSettingsProvider';
import useCopyOrUpgradeClickblocker from '../../shared/hooks/useCopyOrUpgradeClickblocker';
import ExpressionDetailsForm from './ExpressionDetailsForm';
import { useFormOverlayModal } from '../../shared/context/FormOverlayModalProvider';



const useStyles = makeStyles((theme) => ({
  relative: {
    position: 'relative'
  },
  drawerCol: {
    flex: 1,
    borderRight: `1px solid ${theme.palette.background.panelBorder}`,
    height: '100%',
  },
  exprButtonDisabled: {
    cursor: 'not-allowed'
  }
}));



function ColumnSelectionInner({
  leftColumnClassName,
  rightColumnClassName,
  searchInputClassName,
  showProfileControls,
  leftColumnIsDrawer,
  leftColumnWidth,
  onSelect,
  autoFocusSearch,
  ColumnRowComponent,
  ExpressionRowComponent,
}) {
  const classes = useStyles();
  const [id] = useState(() => _uniqueId('column-selection-'));
  const columnDefs = useColumnDefs();
  const [query, setQuery] = useState('');
  const [newExpressionDetailsOpen, setNewExpressionDetailsOpen] = useState(false);
  const [expressionKeyIncrement, setExpressionKeyIncrement] = useState(0); // hack to get NEW EXPRESSION form to reinitialize each open.
  const locked = useIsEditingDisabled();
  const selectedColumns = useFormSelector(selectColumnsAsList);
  const isExpressionsAvailable = useIsExpressionsAvailable();
  const { clickBlockerProps } = useCopyOrUpgradeClickblocker();
  const { openOverlayModal } = useFormOverlayModal();

  const { max_expressions, max_expressions__has_upgrade } = useUserPlanPermissions(['max_expressions', 'max_expressions__has_upgrade']);
  const currentNumExpressions = useFormSelector(state => state?.expressions || []).length;
  const expressionLimitReached = currentNumExpressions >= max_expressions && isExpressionsAvailable;


  // We want double confirmation, but only for Expressions.
  const {
    confirming: deleteExprConfirming,
    resetAllConfirming,
    isAnyConfirming: anyDeleteExprConfirming,
    setConfirming: setDeleteExprConfirming
  } = useDoubleConfirmationState();
  const fuseOptions = useFuseOptions();
  const [selectedGroup, setSelectedGroup] = useState('ALL');

  const filteredColumns = useMetricFilter({
    metrics: columnDefs,
    query,
    selectedGroup,
    fuseOptions
  });

  const {
    resetHighlighted,
    getRootProps,
    getInputProps,
    getListboxProps,
    getOptionProps
  } = useKeyboardMenuNavigation(
    id,
    filteredColumns,
    false,
    {
      onEnter: (item) => {
        const selected = selectedColumns.includes(item.name);
        onSelect(item, selected);
      },
      initialIndex: 0,
      listBoxRoleSelector: 'div'
    }
  );


  const handleNewExpressionClose = useCallback(() => setNewExpressionDetailsOpen(false), [])

  const handleNewExpressionOpen = useCallback(() => {
    setExpressionKeyIncrement(prev => prev + 1);
    setNewExpressionDetailsOpen(true);
  }, [])

  const RowSwitcher = useMemo(() => {
    const Row = React.memo(props => {
      const item = props.data.filteredColumns[props.index];
      const Component = 'expression' in item ? ExpressionRowComponent : ColumnRowComponent;
      return <Component {...props} />
    });
    Row.displayName = 'RowSwitcher';
    return Row;
  }, [ColumnRowComponent, ExpressionRowComponent]);

  const itemKey = useCallback((index) => filteredColumns[index].name, [filteredColumns]);


  const handleSearchChange = useCallback((event) => {
    setQuery(event.target.value || '')
  }, [])


  useEffect(() => {
    resetHighlighted();
  }, [query]);


  return (
    <>
      <ConditionalWrapper
        condition={leftColumnIsDrawer}
        wrapper={(children) => {
          return (
            <FormModal.Drawer
              className={leftColumnClassName}
              width={leftColumnWidth}
              breakpoint="md"
              zIndex={3}
            >
              {children}
            </FormModal.Drawer>
          )
        }}
      >
        <FormModal.Column
          className={clsx(leftColumnClassName, classes.drawerCol)}
          minWidth={leftColumnWidth}
          maxWidth={leftColumnWidth}
        >
          {showProfileControls && (
            <FormModal.Section
              title="Saved Profiles"
              expand
              scrollable
              borderBottom={true}
            >
              <ProfilesSection />
            </FormModal.Section>
          )}
          <FormModal.Section
            title="Column Groups"
            scrollable
            flexShrink={3}
          >
            <ColumnGroupSelector
              selected={selectedGroup}
              onSelect={(val) => {
                resetAllConfirming();
                setSelectedGroup(val)
              }}
              includeAllCategory
              includeExpressionsCategory={isExpressionsAvailable}
            />
          </FormModal.Section>
        </FormModal.Column>
      </ConditionalWrapper>
      <FormModal.Column
        className={clsx(rightColumnClassName, classes.relative)}
        borderLeft={false}
        borderRight
        expand
        {...getRootProps()}
      >
        <div {...clickBlockerProps} />
        <FormModal.Section
          className={searchInputClassName}
          borderBottom
        >
          <Box p={1.5}>
            <MetricSearchInput
              placeholder="Search metrics"
              value={query}
              autoFocus={autoFocusSearch}
              onChange={handleSearchChange}
              disabled={locked}
              ariaLabel="Search metrics. Use Up, Down, and Enter arrow keys for navigation."
              {...getInputProps('inputRef')}
            />
          </Box>
        </FormModal.Section>
        {Boolean(isExpressionsAvailable && selectedGroup === 'EXPRESSION') && (
          <FormModal.Section
            className={classes.newExpressionSection}
            borderBottom
          >
            <Box display="flex" style={{ padding: '5px 10px' }}>

              {/* show tooltip and disable button if there are no more upgrades */}
              <ConditionalWrapper
                condition={expressionLimitReached && !max_expressions__has_upgrade}
                wrapper={(innerChildren) => {
                  return <DefaultMaxReachedTooltip text={`You have reached the maximum number of expressions allowed (${max_expressions}).`}>{innerChildren}</DefaultMaxReachedTooltip>
                }}
              >
                <span>
                  {/* show Upgrade modal if limit reached but upgrade exists */}
                  <Button
                    variant="outlined"
                    onClick={(expressionLimitReached && max_expressions__has_upgrade)
                      ? () => openOverlayModal("upgrade_expression", { suggestedPlanLevel: max_expressions__has_upgrade })
                      : () => handleNewExpressionOpen()
                    }
                    disabled={locked || (expressionLimitReached && !max_expressions__has_upgrade)}
                  >
                    NEW EXPRESSION +
                  </Button>
                </span>

              </ConditionalWrapper>
            </Box>
            <ExpressionDetailsForm
              open={newExpressionDetailsOpen}
              handleClose={handleNewExpressionClose}
              expression={null}
              key={expressionKeyIncrement}
            />
          </FormModal.Section>
        )}
        <FormModal.Section expand>
          <ActionList.Virtual
            className={'modal-horizontal-scrollbar-sm'}
            itemHeight={35}
            itemCount={filteredColumns.length}
            itemKeyCallback={itemKey}
            disabled={locked}
            itemData={{
              filteredColumns,
              selectedColumns,
              onSelect,
              getOptionProps,
              anyDeleteExprConfirming,
              setDeleteExprConfirming,
              deleteExprConfirming
            }}
            {...getListboxProps('innerRef')}
          >
            {RowSwitcher}
          </ActionList.Virtual>
        </FormModal.Section>
      </FormModal.Column>
    </>
  )
}


ColumnSelectionInner.propTypes = {
  showProfileControls: PropTypes.bool,
  showExpressions: PropTypes.bool,
  selectedColumns: PropTypes.array,
  autoFocusSearch: PropTypes.bool,
  onSelect: PropTypes.func,
  leftColumnIsDrawer: PropTypes.bool,
  query: PropTypes.string,
  useControlledQuery: PropTypes.bool,
  ColumnRowComponent: PropTypes.elementType,
  ExpressionRowComponent: PropTypes.elementType,
}


ColumnSelectionInner.defaultProps = {
  selectedColumns: [],
  leftColumnWidth: 260,
  leftColumnIsDrawer: true,
  useControlledQuery: false,
  ColumnRowComponent: ColumnCheckboxRow,
  ExpressionRowComponent: ExpressionCheckboxRow
}


export default ColumnSelectionInner;
