import AblyService from 'src/services/AblyService';
import edgeDataApi from 'src/apis/edgeDataApi';
import { MARKET_SESSION_KEYS } from 'src/utils/datetime/definitions/marketHours';
import { getTime, set } from 'date-fns';
import {
  parseUnixMilliseconds,
  toMarketTime,
  getMarketSession,
  formatMarketTime, marketTimeToCorrectUnix
} from 'src/utils/datetime/date-fns.tz';

/**
 * Brokers the live connection between TradingView and AblyService
 *
 * Opens the connection, processes the incoming messages, and tracks some state that is needed to manage the
 * bar history and setintervals
*/

const StreamAdapter = {

  /**
   * Store the last bar loaded in the chart for each active ticker, accross all charts. The stream uses this when updating.
   * @member {Map<string, object>}
   */
  lastChartedBarCache: new Map(),
  /**
   * Store the last bar we got from ably for each chart, unmodified. Need this to aggregate volume.
   * @member {Map<string, object>}
   */
  lastStreamedBarCache: new Map(),
  /**
   * Store all the setInterval() intervals for each active stream, to be cleared when necissary.
   * @member {Map<string, number>}
   */
  setIntervalCache: new Map(),


  setLastChartedBar(key, val) {
    this.lastChartedBarCache.set(key, { ...val });
  },

  getLastChartedBar(key) {
    return this.lastChartedBarCache.get(key);
  },

  setLastStreamedBar(key, val) {
    this.lastStreamedBarCache.set(key, { ...val });
  },

  getLastStreamedBar(key) {
    return this.lastStreamedBarCache.get(key);
  },


  subscribeToStream(symbolInfo, resolution, onRealtimeCallback, subscribeUID, onResetCacheNeededCallback) {
    const channelName = `ecscharts:${symbolInfo.name}`;

    AblyService.setChannelLifecycleCallbacks(channelName, {
      onCloseChannel: this.onRealtimeChannelClose(symbolInfo.name),
      onOpenChannel: this.onRealtimeChannelOpen(symbolInfo.name)
    });

    AblyService.openConnection(channelName, {
      handlerId: subscribeUID,
      onMessage: this.processRealtimeBar(onRealtimeCallback, {
        handlerId: subscribeUID,
        resolution,
        symbolInfo,
        onResetCacheNeededCallback
      }),
    });
  },


  unsubscribeToStream(subscriberUID) {
    AblyService.closeHandler(subscriberUID);
  },


  /**
   * Don't display extended hours bars for non-intraday data
   * @param {UnixMilliseconds} barTime
   * @param {string} modif - The resolution modifier (m, D, W, M)
   * @returns {boolean}
   */
  shouldIgnoreExtendedHoursBars(barTime, modif) {
    if (modif === 'm') return false;

    const barTimeDate = toMarketTime(parseUnixMilliseconds(barTime));
    const session = getMarketSession(barTimeDate);
    return session !== MARKET_SESSION_KEYS.REGULAR_MARKET;
  },


  /**
   * @param {callback} onRealtimeCallback - Set the bars on the chart
   * @param {Object} context
   * @returns {callback} - Called automatically when an Ably message is recieved
   */
  processRealtimeBar(onRealtimeCallback, context) {
    return barData => {
      const { resolution, symbolInfo } = context;
      const [scale, modif] = this.parseResolution(resolution);

      let incomingBar = { ...barData };

      if (this.shouldIgnoreExtendedHoursBars(incomingBar.t, modif)) {
        return;
      }

      const lastBar = this.getLastChartedBar(`${symbolInfo.name}~${resolution}`);
      if (!lastBar || !Object.keys(lastBar).length) {
        // Ignore the stream if the chart hasn't loaded historical bars yet
        return;
      }

      /* If daily bars:
          pre: don't render bar at all.
          market: render normally
          after: Only volume is valid. Don't insert any new bars, just update lastBar.v
          TODO: Weekly/Monthly might not work here. Can't figure out the right logic.
      */
      if (modif !== 'm') {
        const barTimeDate = toMarketTime(parseUnixMilliseconds(incomingBar.t));
        const session = getMarketSession(barTimeDate);
        if (session === MARKET_SESSION_KEYS.PREMARKET) {
          return;
        }
        if (session === MARKET_SESSION_KEYS.AFTER_HOURS) {
          incomingBar = {
            v: incomingBar.v,
            ...lastBar
          }
        }
      }


      let bar;
      const gridBarTime = this.conformBarTimeToGrid(lastBar.time, scale, modif);

      const nextBarTime = this.getNextBarTime(gridBarTime, scale, modif);

      if (incomingBar.t >= nextBarTime) {
        bar = {
          time: incomingBar.t,
          open: incomingBar.o,
          high: incomingBar.h,
          low: incomingBar.l,
          close: incomingBar.c,
          volume: incomingBar.v
        };
      } else {
        if (modif !== 'm' || scale !== 1) {
          const lastStreamedBar = this.getLastStreamedBar(symbolInfo.name);
          incomingBar = this.correctIncommingBarForResolution(incomingBar, lastBar, lastStreamedBar);
        }
        bar = {
          ...lastBar,
          high: incomingBar.h,
          low: incomingBar.l,
          close: incomingBar.c,
          volume: incomingBar.v
        };
      }

      this.setLastChartedBar(`${symbolInfo.name}~${resolution}`, bar);
      onRealtimeCallback(bar);

      this.setLastStreamedBar(symbolInfo.name, barData);
    };
  },


  // Returns a callback that will be executed when the ably channel is opened
  // Stores the channel activity in dynamo on a setInterval
  onRealtimeChannelOpen(symbol) {
    return async () => {
      const markChannelAsActive = async () => {
        try {
          const res = await edgeDataApi.post('/stream/charts', { c: symbol });
          return res?.data;
        } catch (err) {
          console.log('ERROR markChannelAsActive:', err);
        }
      };

      const existingInterval = this.setIntervalCache.get(symbol);

      if (!existingInterval) {
        await markChannelAsActive();

        const interval = setInterval(markChannelAsActive, 1000 * 60 * 2); // Let the backend know this ticker is being viewed, every 2 minutes.
        this.setIntervalCache.set(symbol, interval);
      }
    };
  },


  // Returns a callback that will be executed when the ably channel is closed
  // Clears the open setInterval, so the ably stream can expire this channel
  onRealtimeChannelClose(symbol) {
    return () => {
      const interval = this.setIntervalCache.get(symbol);
      if (interval === undefined) return;

      this.setIntervalCache.delete(symbol);
      clearInterval(interval);
    };
  },


  /**
   * Calculate when the next bar theoretically should happen, based on resolution.
   * If we see this time, we know we need to add a new bar, rather than edit an old one.
   * Needs to conform to grid starting at 4:00AM (3M: 4:03, 4:06, ...) / (5M: 4:05, 4:10, ...)
   * @param {UnixMilliseconds} barTime
   * @param {number} scale
   * @param {string} modif
   * @throws {Error} - If the resolution is not supported
   * @returns {UnixMilliseconds} - The theoretical next bar time
   */
  getNextBarTime(barTime, scale, modif) {
    let seconds;
    if (modif === 'm') {
      seconds = 60;
    } else if (modif === 'D') {
      seconds = 60 * 60 * 24;
    } else if (modif === 'W') {
      seconds = 60 * 60 * 24 * 7;
    } else if (modif === 'M') {
      seconds = 60 * 60 * 24 * 7 * 31;
    } else {
      throw new Error(`No getNextBarTime for '${barTime}', ${scale}${modif}`);
    }

    return barTime + (seconds * scale * 1000);
  },


  /**
   * As bars come in, we need to fit them to the Chart. This means we need to round the bar time to the nearest
   * minute / hour.
   * @param {UnixMilliseconds} barTime
   * @param {integer} scale
   * @param {string} modif
   * @return {UnixMilliseconds} - The adjusted bar time
   */
  conformBarTimeToGrid(barTime, scale, modif) {
    let adjustedBarTime = barTime;
    let adjustedMinute;
    // TODO: Hourly streaming is wrong. Need an if/else for it.
    // Because day starts at 9:30, I have no idea how to normalize or what the chart wants.
    if (modif === 'm') {
      // wrong unix, convert later
      let marketBarTime = toMarketTime(parseUnixMilliseconds(barTime));
      const currMinute = formatMarketTime(marketBarTime, 'm');
      adjustedMinute = this.closestMultipleSmallerOrEqual(currMinute, scale);
      marketBarTime = set(marketBarTime, {
        minutes: adjustedMinute,
        seconds: 0,
        milliseconds: 0
      });
      const adjustedUnixBarDate = marketTimeToCorrectUnix(marketBarTime);
      adjustedBarTime = getTime(adjustedUnixBarDate);
    }
    return adjustedBarTime;
  },


  /**
   * Get closest multiple(x) of n that is smaller or equal to n. Used to conform to time grids
   * @param {number} n
   * @param {number} x
   * @returns {number}
   * @example
   * closestMultipleSmallerOrEqual(5, 3) // 3
   * closestMultipleSmallerOrEqual(10, 100) // 100
   * closestMultipleSmallerOrEqual(10, 99) // 90
   */
  closestMultipleSmallerOrEqual(n, x) {
    if (n % x === 0) {
      return n;
    }
    n -= parseInt(x / 2, 10);
    n -= (n % x);
    return n;
  },


  correctIncommingBarForResolution(bar, lastChartedBar, lastStreamedBar) {
    bar.h = Math.max(bar.h, lastChartedBar.high);
    bar.l = Math.min(bar.l, lastChartedBar.low);

    if (lastStreamedBar) {
      if (lastStreamedBar.t === bar.t) {
        bar.v = lastChartedBar.volume + (bar.v - lastStreamedBar.v);
      } else {
        bar.v += lastChartedBar.volume;
      }
    } else {
      bar.v = lastChartedBar.volume;
    }
    return bar;
  },


  parseResolution(resolution) {
    let res = '';
    if (!isNaN(resolution)) {
      res = 'm';
    } else {
      res = resolution[resolution.length - 1];
    }
    return [parseInt(resolution), res];
  }

};

window.StreamAdapter = StreamAdapter;
export default StreamAdapter;


