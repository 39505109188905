import { KEY_STATS_COLUMNS } from 'src/app/components/panels/KeyStats/KeyStatsTableDefinitions';
import { FINANCIALS_COLUMNS } from 'src/app/components/panels/KeyStats/financialsTableDefinitions';


/*
We really want to avoid huge lists of metrics duplicated everywhere, so I'm going to build this list based on the existing Overview definitions.
The structure here is different, the entirety of KeyStats and FInancials are combined into one panel.
We also want to categorize metrics within the modal to make things a bit easier.
*/

const financials = [];
Object.keys(FINANCIALS_COLUMNS).forEach(key => {
  FINANCIALS_COLUMNS[key].forEach(metric => {
    financials.push({
      ...metric,
      'allMetricsGroup': key
    })
  })
});

export const TOP_LIST_KEYSTATS_COLUMNS = {
  'General Info': KEY_STATS_COLUMNS['General Info'],
  'Financials': financials
}
