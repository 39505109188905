import React from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(() => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    flex: 1
  },
  hidden: {
    display: 'none !important'
  }
}))

function TabPanel({
  value,
  index,
  children
}) {
  const classes = useStyles();
  const hidden = value !== index;

  return (
    <div
      role="tabpanel"
      hidden={hidden}
      className={clsx(classes.root, hidden && classes.hidden)}
    >
      {children}
    </div>
  );
}

export default TabPanel;

