/*
const EXAMPLE_JSON = {
  "type": "doc",
  "content": [
    {
      "type": "paragraph",
      "content": [
        {
          "type": "text",
          "text": "(100 - "
        },
        {
          "type": "mention",
          "attrs": {
            "id": "session_lp",
            "label": "Price"
          }
        },
        {
          "type": "text",
          "text": "  ) / (10 * "
        },
        {
          "type": "mention",
          "attrs": {
            "id": "o_chg",
            "label": "Open Chg"
          }
        },
        {
          "type": "text",
          "text": " )"
        }
      ]
    }
  ]
}

const EXAMPLE_PROFILE = {
  expression: "(100 - [[A]]  ) / (10 * [[B]] )",
  args: {
    A: { column: "session_lp" },
    B: { column: "o_chg" }
  }
}
*/


/**
 * @typedef {object} TipTapSchema
 * @property {string} type
 * @property {TipTapSchema[]} [content]
 * @property {Object<string, string>} [attrs]
 * @property {string} [text]
 **/

/**
 * @typedef {object} ExpressionProfile
 * @property {string} expression
 * @property {Object<string, { column: string }>} args
 **/

const LABEL_REGEX = /\[\[([A-Z])\]\]/g;

const LABELS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';

const COLUMN_NODE_NAME = 'mention';

const makeExpressionPlaceholder = (labelChar) => `[[${labelChar}]]`;


/**
 * convert from TipTap JSON into our profile structure
 * @param {TipTapSchema} TipTapSchema
 * @returns {ExpressionProfile}
 **/
export const jsonExpressionToProfile = (schema) => {
  let expression = '';
  let args = {}; // A: { column: col_name }
  let seen = {}; // col_name: A
  let argIdx = 0;

  if (!schema || !schema.content) return { expression, args };

  schema.content.forEach((node) => {
    if (node.type !== 'paragraph') return { expression, args };
    if (!node?.content) return { expression, args };
    node.content.forEach((inode) => {
      if (inode.type === 'text') {
        expression += inode.text;
      } else if (inode.type === COLUMN_NODE_NAME) {
        let argLabel;
        if (seen[inode.attrs.id]) {
          argLabel = seen[inode.attrs.id];
        } else {
          argLabel = LABELS[argIdx];
          seen[inode.attrs.id] = argLabel;
          argIdx += 1;
        }
        expression += makeExpressionPlaceholder(argLabel);
        args[argLabel] = { column: inode.attrs.id }
      }
    });
    return;
  });

  return { expression, args }
}


/**
 * Convert from a Profile expression back to TipTap's format,
 * to insert content into the editor.
 * @param {ExpressionProfile} profile
 * @param {object[]} columnDefs
 * @returns {TipTapSchema}
 **/
export const profileExpressionToJson = (profile, columnDefs) => {
  const { expression, args } = profile;
  const content = [];

  // Split the expression into text and placeholders
  let lastIndex = 0;
  let match;

  while ((match = LABEL_REGEX.exec(expression)) !== null) {
    // Add the text before the placeholder
    if (match.index > lastIndex) {
      content.push({
        "type": "text",
        "text": expression.slice(lastIndex, match.index)
      });
    }

    // Add the placeholder (mention)
    let argKey = match[1];
    let { column } = args[argKey];
    let columnDef = columnDefs.find(col => col.name === column);
    content.push({
      "type": COLUMN_NODE_NAME,
      "attrs": {
        "id": column,
        "label": columnDef ? columnDef.label : column
      }
    });

    lastIndex = match.index + match[0].length;
  }

  // Add any remaining text after the last placeholder
  if (lastIndex < expression.length) {
    content.push({
      "type": "text",
      "text": expression.slice(lastIndex)
    });
  }

  return {
    type: "doc",
    content: [
      {
        type: "paragraph",
        content: content
      }
    ]
  };

}


/**
 * Make a human-readable version of the expression with column labels inserted.
 **/
export const profileExpressionToPreview = (profile, columnDefs) => {
  const { expression, args } = profile;
  let preview = expression;

  for (let argKey in args) {
    const { column } = args[argKey];
    const columnDef = columnDefs.find(col => col.name === column);
    const label = columnDef ? columnDef.label : column;

    preview = preview.replaceAll(makeExpressionPlaceholder(argKey), label);
  }

  return preview;
}



/**
 * In some situations, we have the data for each @Column returned
 * by the backend. We may want to compute the clause ourselves with
 * this data.
 *
 * @throws Error - If a column is missing
 * @param {ExpressionProfile} profile
 * @param {Object<str, any>} values - Indexed by column name
 * @returns {string} - Final expression with values substituted
 **/
export const insertValuesIntoExpression = (profile, values) => {
  const { expression, args } = profile;

  let finalExpression = expression;

  for (let argKey in args) {
    const { column } = args[argKey];

    if (!(column in values)) throw Error(`Column value ${column} is missing for expression ${expression}, ${JSON.stringify(args)}`);

    let value;
    if (values[column] === null) value = 'NULL';
    else if (values[column] === undefined) value = 'NULL';
    else if (typeof values[column] === 'number') value = values[column];
    else throw new Error(`Column value ${column} is not a number or nullish: ${values[column]}`)

    finalExpression = finalExpression.replaceAll(makeExpressionPlaceholder(argKey), value);
  }

  return finalExpression;
}

