import React, { useState } from 'react';
import _isEqual from 'lodash/isEqual';
import clsx from 'clsx';
import shortUuid from 'short-uuid';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useInterval } from 'src/hooks/useInterval';
import ClearIcon from '@material-ui/icons/Clear';
import {
  Box,
  Typography,
  IconButton,
  alpha,
  makeStyles,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  tabBar: {
    display: 'flex',
    backgroundColor: theme.palette.background.tab,
    position: 'relative',
    overflow: 'hidden',
    height: 38,
    '& .removeticker-placeholder': {
      minWidth: 30,
      flexBasis: 360,
      flexGrow: 0,
      flexShrink: 1,
    }
  },
  tabItem: {
    display: 'flex',
    alignItems: 'center',
    userSelect: 'none',
    border: 'none',
    textAlign: 'left',
    justifyContent: 'flex-start',
    width: '100%',
    color: '#fff',
    padding: '6px 8px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: 'none',
    overflow: 'hidden',
    height: 36,
    backgroundColor: theme.palette.background.paper,
    '& .MuiTypography-h5': {
      color: theme.palette.text.primary,
      fontSize: '13px',
      whiteSpace: 'nowrap',
      overflow: 'hidden'
    },
    '& .MuiButton-label': {
      position: 'absolute'
    },
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  },
  tabItemContainer: {
    position: 'relative',
    borderRadius: 0,
    minWidth: 30,
    flexBasis: 360,
    flexGrow: 0,
    flexShrink: 1,
    borderRight: `2px solid ${theme.palette.background.tab}`,
    borderBottom: 'none',
    '&:after': {
      content: '\'\'',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: 'calc(100% - 2px)',
      background: `linear-gradient(270deg, ${alpha(theme.palette.background.paper, 1)} 8%, ${alpha(theme.palette.background.paper, 0)} 35%)`,
      pointerEvents: 'none'
    },
    '&:hover': {
      '& $hoverFocusOverlay': {
        opacity: .18
      }
    }
  },
  active: {
    '& $tabItem': {
      height: 38,
      backgroundColor: theme.palette.background.dark,
      '& .MuiTypography-h5': {
        color: theme.palette.text.secondary
      },
    },
    '&:hover': {
      backgroundColor: theme.palette.background.dark,
      '& $hoverFocusOverlay': {
        opacity: '0 !important'
      }
    },
    '&:after': {
      content: '\'\'',
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      background: `linear-gradient(270deg, ${alpha(theme.palette.background.dark, 1)} 8%, ${alpha(theme.palette.background.dark, 0)} 35%)`,
      pointerEvents: 'none'
    }
  },
  hoverFocusOverlay: {
    content: '\'\'',
    position: 'absolute',
    bottom: 2,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    backgroundColor: theme.palette.primary.main,
    transition: 'opacity .15s',
    zIndex: 1,
    pointerEvents: 'none'
  },
  removeButton: {
    position: 'absolute',
    fontSize: '16px',
    backgroundColor: theme.palette.background.paper,
    padding: 0,
    top: 3,
    right: 3,
    color: theme.palette.primary.main,
    opacity: 1,
    zIndex: 999,
    transition: 'none',
    '&$active': {
      backgroundColor: theme.palette.background.dark,
    },
    '&:hover': {
      opacity: 1,
      backgroundColor: alpha(theme.palette.primary.main, .3),
    }
  },
  addButtonContainer: {
    borderRadius: 0,
    width: 30,
    flexGrow: 0,
    flexShrink: 0,
    borderRight: `2px solid ${theme.palette.background.tab}`,
    borderBottom: 'none',
  },
  addButton: {
    width: '100%',
    color: '#fff',
    padding: '6px 8px',
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    transition: 'none',
    overflow: 'hidden',
    height: 36,
    backgroundColor: theme.palette.background.paper,
    '&:hover': {
      backgroundColor: theme.palette.background.paper,
    },
  }
}));


const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
};

const getListStyle = isDraggingOver => {
  return isDraggingOver ? { pointerEvents: 'none' } : {};
};

const getItemStyle = (snapshot, draggableStyle) => {
  const styleDefaults = {
    userSelect: 'none',
    opacity: snapshot.isDragging ? .6 : 1,
    ...draggableStyle
  };
  if (draggableStyle?.transform) {
    const axisLockX = `${draggableStyle.transform.split(',')
      .shift()}, 0px)`;
    return {
      ...styleDefaults,
      transform: axisLockX,
    };
  }
  return styleDefaults;
};


function TabsBarTabs({
  className,
  itemIdKey,
  items,
  onClick,
  onRemove,
  onReorder
}) {
  const classes = useStyles();
  const [placeholders, setPlaceholders] = useState([]);
  const [reset, setReset] = useState(0);

  useInterval(() => {
    setPlaceholders([]);
  }, placeholders.length ? 1400 : null, reset);


  const handleRemove = (item) => {
    setPlaceholders([<div key={shortUuid.generate()} className="removeticker-placeholder">&nbsp;</div>, ...placeholders]);
    setReset(reset => reset + 1);
    onRemove(item);
  };

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }
    const orderedItems = reorder(items, result.source.index, result.destination.index);
    if (!_isEqual(items, orderedItems)) {
      onReorder(orderedItems.map(item => item[itemIdKey]));
    }
  };


  return (
    <DragDropContext onDragEnd={handleDragEnd}>
      <Droppable droppableId="ticker-history-dropable" direction="horizontal">
        {(provided, snapshot) => (
          <Box
            className={clsx(className, classes.tabBar)}
            {...provided.droppableProps}
            ref={provided.innerRef}
            style={getListStyle(snapshot.isDraggingOver)}
          >
            {items.map((item, index) => (
              <Draggable
                key={item[itemIdKey]}
                draggableId={item[itemIdKey]}
                index={index}
                isDragDisabled={placeholders.length !== 0}
              >
                {(provided, snapshot) => (
                  <Box
                    ref={provided.innerRef}
                    {...provided.draggableProps}
                    {...provided.dragHandleProps}
                    className={clsx(classes.tabItemContainer, item.selected && classes.active)}
                    style={getItemStyle(snapshot, provided.draggableProps.style)}
                  >
                    <div className={classes.hoverFocusOverlay} />
                    <a
                      className={classes.tabItem}
                      onClick={() => onClick({ ...item })}
                      role="button"
                      aria-label="Select tab"
                    >
                      <Typography variant="h5" color="textPrimary">{item.title}</Typography>
                    </a>
                    <IconButton
                      size="small"
                      aria-label="remove"
                      className={clsx(classes.removeButton, item.selected && classes.active)}
                      style={items.length === 1 ? { display: 'none' } : {}}
                      onClick={() => handleRemove({ ...item })}
                    >
                      <ClearIcon fontSize="inherit" />
                    </IconButton>
                  </Box>
                )}
              </Draggable>
            ))}
            {provided.placeholder}
            {placeholders.map(p => p)}
          </Box>
        )}
      </Droppable>
    </DragDropContext>
  );
}


export default TabsBarTabs;
