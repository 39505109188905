import PropTypes from 'prop-types';
import List from './List';
import VirtualList from './VirtualList';
import DraggableList from './DraggableList';
import Item from './Item';
import DraggableItem from './DraggableItem';
import Heading from './Heading';
import IconButton from './IconButton';
import { Leading, Trailing } from 'src/app/components/ActionList/Visuals';
import DoubleConfirmationIconButton, { useDoubleConfirmationState as originalUseDoubleConfirmationState } from './DoubleConfirmationIconButton';


/*
 * FEATURES:
 *  - Leading checkbox
 *
 *  - Trailing Icons and IconButtons, in group
 *    - Icons might have multi-step actions
 *    - Icons might hide a textbox rename (how to handle?)
 *
 *  - Drag and drop
 *
 *  - Virtualization
 *
 *  - dense / not dense
 */

/*
 * ROADMAP
 * [ ] Implement simple list with/without checkbox
 * [ ] Implement trailing icons
 * [ ] Implement virtualization
 * [ ] Implement drag and drop
 */


/**
 * Examples:
 *
 * <ActionList>
 *   {map(item => (
 *     <ActionList.Item onSelect={} item={item} checkbox>
 *       <ActionList.Leading>
 *         <Checkbox />
 *       </ActionList.Leading>
 *       Hello!
 *       <ActionList.Trailing>
 *         <ActionList.IconButton label={} icon={icon} onClick={} multiStep multiStepIcon={} />
 *         <ActionList.IconButton />
 *       </ActionList.Trailing>
 *     </ActionList.Item>
 *   )}
 * </ActionList>
 *
 * <ActionList virtualized>
 *   {map(item => (
 *     ...
 *   )}
 * </ActionList>
 *
 * <ActionList drag-drop>
 *   {map(item => (
 *     ...
 *   )}
 * </ActionList>
 *
 */



/**
 * Structural components for a generic Action List
 */
const ActionList = Object.assign(List, {
  /** @see VirtualList */
  Virtual: VirtualList,
  /** @see DraggableList */
  Draggable: DraggableList,
  /** @see Item */
  Item,
  /** @see DraggableItem */
  DraggableItem,
  /** @see Heading */
  Heading,
  /** @see Leading */
  Leading,
  /** @see Trailing */
  Trailing,
  /** @see IconButton */
  IconButton,
  /** @see DoubleConfirmationIconButton */
  DoubleConfirmationIconButton,
});

export const useDoubleConfirmationState = originalUseDoubleConfirmationState;

export default ActionList;
