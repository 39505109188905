import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchSubscriptionPlans } from 'src/redux/account/accountActions';


/**
 * Simply fetches plans if they aren't already loaded. No extra logic.
 *
 * Just use this when a user is interacting with an "Upgrade" Marketing Modal or similar.
 **/
export default function usePrefetchSubscriptionPlans() {
  const dispatch = useDispatch();
  const user = useSelector(state => state.account.user);
  const plans = useSelector(state => state.account.plans);

  useEffect(() => {
    if (user && !plans.length) {
      dispatch(fetchSubscriptionPlans());
    }
  }, [user.userSub]);
}
