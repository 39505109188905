import React, { useCallback } from 'react';
import clsx from 'clsx';
import { STRUCTURAL_TYPES, } from 'src/app/slicedForm/mapping/mappingDirections/index';
import FilterEntity from './FilterEntity';
import {
  FilterEntityProvider,
  FormGroupContext,
  getOrClickCallback,
  useFormGroupContext
} from 'src/app/slicedForm/FilterForm/context/FilterEntityContext';
import {
  FilterGroupDraggableProvider,
  FilterGroupDroppableProvider,
  DragHandle,
  DRAGGABLE_CONFIG
} from './FilterGroupDragProviders';
import {
  Box,
  makeStyles,
  alpha
} from '@material-ui/core';


const AND_SPACING = 3;


const useStyles = makeStyles(theme => ({
  root: {
    flex: 1
  },
  entityRow: {
    position: 'relative',
    '&$entityRow_OR_container:not(:last-child):after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 15,
      right: 15,
      height: 1,
      backgroundColor: alpha('#363D48', .6),
    }
  },
  entity: {
    padding: [[9, 7]],
    flex: 1
  },
  entityRow_OR: {
    display: 'flex'
  },
  entityRow_SLICE_GROUP: {},
  entityRow_OR_container: {
    position: 'relative',
    '&:not(:last-child):after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      left: 15,
      right: 15,
      height: 1,
      backgroundColor: alpha('#363D48', .6),
    }
  },
  entityRow_AND_container: {
    paddingBottom: theme.spacing(AND_SPACING),
    display: 'flex'
  },
  draggableItem: {
    '&:not(:last-child)': {
      '& $entityRow_AND': {
        flex: 1,
        '&:before': {
          // line behind AND word
          content: '""',
          zIndex: 0,
          position: 'absolute',
          left: '50%',
          bottom: `calc(-${theme.spacing(AND_SPACING)}px - 1px)`,
          transform: `translateX(-50%)`,
          width: 2,
          height: theme.spacing(AND_SPACING),
          backgroundColor: theme.palette.background.lightBorder,
        },
        '&:after': {
          // AND word
          content: '"AND"',
          fontFamily: theme.typography.fontFamily,
          fontSize: 12,
          lineHeight: .9,
          zIndex: 1,
          position: 'absolute',
          left: '50%',
          bottom: `-${theme.spacing(AND_SPACING / 2)}px`,
          transform: `translate(-50%, 63%)`,
          backgroundColor: theme.palette.background.paper,
          color: theme.palette.text.secondary,
        }
      },
    }
  },
  entityRow_AND: {
    flex: 1,
    // More styles applied from FilterSection component
    '&:not(:last-child)': {
      '&:before': {
        // line behind AND word
        content: '""',
        zIndex: 0,
        position: 'absolute',
        left: '50%',
        bottom: `calc(-${theme.spacing(AND_SPACING)}px - 1px)`,
        transform: `translateX(-50%)`,
        width: 2,
        height: theme.spacing(AND_SPACING),
        backgroundColor: theme.palette.background.lightBorder,
      },
      '&:after': {
        // AND word
        content: '"AND"',
        fontFamily: theme.typography.fontFamily,
        fontSize: 12,
        lineHeight: .9,
        zIndex: 1,
        position: 'absolute',
        left: '50%',
        bottom: `-${theme.spacing(AND_SPACING / 2)}px`,
        transform: `translate(-50%, 63%)`,
        backgroundColor: theme.palette.background.paper,
        color: theme.palette.text.secondary,
      }
    }
  },
  draggableWrapper: {
    display: 'flex',
    '& .draggable_icon_top_align': {
      display: 'block',
      paddingTop: 15
    }
  },
  draggableRowInner: {
    flex: 1
  },
}));


const GROUP_TYPES = [
  STRUCTURAL_TYPES.AND,
  STRUCTURAL_TYPES.OR,
  STRUCTURAL_TYPES.SLICE_GROUP
]


function FilterGrouping({
  type,
  tree,
  id,
  disabled,
  andGroupStyles,
}) {
  const classes = useStyles();
  const parentContext = useFormGroupContext(); // The previous parent group context

  const renderChild = useCallback(node => {
    if (GROUP_TYPES.includes(node.type)) {
      return (
        <MemoizedFilterGrouping
          type={node.type}
          tree={node.tree}
          id={node.id}
          disabled={disabled}
        />
      );
    }
    if (node.type === STRUCTURAL_TYPES.FILTER) {
      return (
        <FilterEntityProvider id={node.id} type={node.type} disabled={disabled}>
          <FilterEntity className={classes.entity} />
        </FilterEntityProvider>
      );
    }
  }, [classes.entity, disabled]);

  const isAndGroup = type === STRUCTURAL_TYPES.AND;

  return (
    <Box className={clsx(
      classes.root,
      classes[`group_${type}`],
      `group_${type}`
    )}>
      <FormGroupContext.Provider value={{
        ...parentContext,
        groupId: id,
        groupType: type,
        onOrClick: getOrClickCallback(id, type, parentContext?.groupId, parentContext?.groupType),
        draggable: DRAGGABLE_CONFIG[type].enabled
      }}>
        <FilterGroupDroppableProvider
          groupId={id || 'ROOT'}
          groupType={type}
        >
          {tree.map((node, i) => (
            <FilterGroupDraggableProvider
              className={classes.draggableItem}
              isDragDisabled={disabled}
              id={node.id}
              index={i}
              groupId={id}
              groupType={type}
              key={node.id}
            >
              <div className={clsx(
                classes[`entityRow_${type}_container`],
                DRAGGABLE_CONFIG[type].enabled && classes.draggableWrapper
              )}>
                {DRAGGABLE_CONFIG[type].enabled && <DragHandle className={DRAGGABLE_CONFIG[type]?.className} disabled={disabled} />}
                <Box
                  className={clsx(
                    classes.entityRow,
                    classes[`entityRow_${type}`],
                    isAndGroup && andGroupStyles,
                    DRAGGABLE_CONFIG[type].enabled && classes.draggableRowInner
                  )}
                >
                  {renderChild(node, i)}
                </Box>
              </div>
            </FilterGroupDraggableProvider>
          ))}
        </FilterGroupDroppableProvider>
      </FormGroupContext.Provider>
    </Box>
  );
}

const MemoizedFilterGrouping = React.memo(FilterGrouping);
export default MemoizedFilterGrouping;
