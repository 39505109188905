import React from 'react';
import clsx from  'clsx';
import PropTypes from 'prop-types';
import {
  makeStyles,
  List,
  ListItem,
  Typography,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.paperAlt,
    paddingTop: 0,
    paddingBottom: 0,
    border: `1px solid ${theme.palette.background.tab}`,
    boxShadow: '0 0 1px 0 rgb(0 0 0 / 70%), 0 3px 4px -2px rgb(0 0 0 / 50%)',
    borderTop: 'none'
  },
  listItem: {
    transition: 'none !important',
    paddingRight: 0
  },
  listItemText: {
    fontSize: 14,
    textOverflow: 'clip',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    marginRight: 0,
  },
  symbol: {
    display: 'inline-block',
    color: theme.palette.primary.main,
    fontWeight: 500,
    minWidth: 70
  },
  name: {
    color: theme.palette.text.secondary,
    fontWeight: 400
  },
}));



function MosaicPanelTickerSearchMenu({
  className,
  options,
  selected,
  onSelect
}) {
  const classes = useStyles();


  return (
    <List
      dense
      component="nav"
      aria-label="select ticker"
      className={clsx(classes.root, className)}
    >
      {options.map(({ symbol, name }, idx) => (
        <ListItem
          key={symbol}
          onClick={() => onSelect({ symbol, name })}
          button
          selected={selected === idx}
          className={classes.listItem}
        >
          <Typography className={classes.listItemText}>
            <span className={classes.symbol}>{symbol}</span>
            <span className={classes.name}>{name}</span>
          </Typography>
        </ListItem>
      ))}
    </List>
  );
}


MosaicPanelTickerSearchMenu.propTypes = {
  className: PropTypes.string,
  options: PropTypes.array,
  selected: PropTypes.number,
  onSelect: PropTypes.func
}


export default MosaicPanelTickerSearchMenu;
