import edgeDataApi from 'src/apis/edgeDataApi';
import axios from 'axios';
import fileDownload from 'js-file-download';
import AblyService from 'src/services/AblyService';
import { generateProfileFilenameVersion } from 'src/utils/generateProfileFilenameVersion';
import { HISTORY_COLUMNS } from 'src/app/HistoryPage/columns/historyColumns';
import { cleanEmptyFilters } from 'src/app/HistoryPage/labels';
import { ROLLING_DATE_OPTIONS } from 'src/app/HistoryPage/columns/historyColumnFunctions';
import { migrateHistoryPageShareGroupToShareType } from 'src/redux/profileSettings/profileSettingsActions';

export const FETCH_HISTORY_PAST_EXPORTS = '@exports/fetch-history-past-exports';
export const FETCH_HISTORY_PENDING_EXPORTS = '@exports/fetch-history-pending-exports';
export const CREATE_HISTORY_EXPORT = '@exports/create-history-export';
export const SET_CREATE_EXPORT_ERROR = '@exports/set-create-export-error';
export const ADD_PENDING_EXPORT = '@exports/add-pending-export';
export const REMOVE_PENDING_EXPORT = '@exports/remove-pending-export';
export const SET_PENDING_EXPORT_ERROR = '@exports/set-pending-export-error';
export const DELETE_HISTORY_EXPORT = '@exports/delete-history-export';
export const SET_HISTORY_EXPORT_MODAL_OPEN = '@exports/set-history-export-modal-open';
export const SET_HISTORY_EXPORT_MODAL_STEP = '@exports/set-history-export-modal-step';

export const INITIALIZE_FORM_STATE = '@exports/initialize-history-export-form-state';
export const UPDATE_FORM_STATE_NAME = '@exports/update-form-state-name';
export const UPDATE_FORM_STATE_ORDER = '@exports/update-form-state-order';
export const ADD_FORM_STATE_FILTER = '@exports/add-form-state-filter';
export const REMOVE_FORM_STATE_FILTER = '@exports/remove-form-state-filter';
export const UPDATE_FORM_STATE_FILTER = '@exports/update-form-state-filter';
export const SET_FILTER_ERRORS = '@exports/set-filter-errors';

export const ADD_FORM_STATE_COLUMN = '@exports/add-form-state-column';
export const REMOVE_FORM_STATE_COLUMN = '@exports/remove-form-state-column';
export const REORDER_FORM_STATE_COLUMN = '@exports/reorder-form-state-column';


export const REQUEST__FETCH_HISTORY_PAST_EXPORTS = '@exports/request__fetch-history-past-exports';
export const REQUEST__FETCH_HISTORY_PENDING_EXPORTS = '@exports/request__fetch-history-pending-exports';
export const REQUEST__CREATE_HISTORY_EXPORT = '@exports/request__create-history-export';
export const FINISH__CREATE_HISTORY_EXPORT = '@exports/finish__create-history-export';

/*
 Exporting history data. It has a lot in common with profileSettings,
 but because its structure in the database and on the frontend is different enough, I'm seperating it.

 Handles form state in redux (unlike the rest of the profilesettings), as well as a list of past exports to re-download, or to copy/edit settings to a new export

 When you request an export, an ably channel name will be sent back. Open that ably channel, and wait for a message containing the s3 url.
*/


//////////////////// FORM STATE ACTIONS ///////////////////////
export const openNewHistoryExportForm = (stateKey) => dispatch => {
  const state = {
    previousExportId: null,
    profileName: ''
  };

  dispatch({ type: INITIALIZE_FORM_STATE, payload: { data: state, stateKey } });
  dispatch({ type: SET_HISTORY_EXPORT_MODAL_STEP, payload: 0 });
  dispatch({ type: SET_CREATE_EXPORT_ERROR, payload: null });
  dispatch({ type: SET_FILTER_ERRORS, payload: { data: {}, stateKey: stateKey } });
  dispatch({ type: SET_HISTORY_EXPORT_MODAL_OPEN, payload: true });
};

// By default, react should rip the history page's currently viewed colums and filters, and load them into the form when opened.
// The user can alternatively choose to copy a previous export into the form.
export const initializeHistoryExportFormState = (stateKey) => dispatch => {
  const state = {
    previousExportId: null,
    profileName: ''
  };

  dispatch({ type: INITIALIZE_FORM_STATE, payload: { data: state, stateKey } });
  dispatch({ type: SET_HISTORY_EXPORT_MODAL_STEP, payload: 0 });
  dispatch({ type: SET_CREATE_EXPORT_ERROR, payload: null });
  dispatch({ type: SET_FILTER_ERRORS, payload: { data: {}, stateKey: stateKey } });
};


export const initializeHistoryExportFromPreviousExport = (stateKey, filterProfile) => (dispatch, getState) => {
  // Add version number to copied profile's name.
  const past = getState().exports?.history?.pastExports || [];
  const pending = getState().exports?.history?.pendingExports || [];
  const allProfiles = [...past, ...pending];

  const state = {
    previousExportId: filterProfile.ux,
    profileName: generateProfileFilenameVersion(filterProfile.profileName, 0, allProfiles)
  };

  dispatch({ type: INITIALIZE_FORM_STATE, payload: { data: state, stateKey } });
  dispatch({ type: SET_CREATE_EXPORT_ERROR, payload: null });
  dispatch({ type: SET_FILTER_ERRORS, payload: { data: {}, stateKey: stateKey } });
  dispatch({ type: SET_HISTORY_EXPORT_MODAL_STEP, payload: 2 });
  dispatch({ type: SET_HISTORY_EXPORT_MODAL_OPEN, payload: true });
};


/////////////////////// HTTP ACTIONS //////////////////////////


export const fetchHistoryPastExports = () => async dispatch => {
  dispatch({ type: REQUEST__FETCH_HISTORY_PAST_EXPORTS });

  let list = [];
  try {
    const res = await edgeDataApi.get('/user/history-data/downloads');
    if (res?.data) {
      list = res.data.sort((a, b) => parseInt(b.ux) - parseInt(a.ux));
      // share_type -> share_group. Unfortunately, done clientside. Eventually we should be able to remove this.
      list = migrateHistoryPageShareGroupToShareType(list);
    }
  } catch (err) {
    console.log('[fetchHistoryDownloadsList]', err);
  }
  dispatch({ type: FETCH_HISTORY_PAST_EXPORTS, payload: list });
};


export const fetchHistoryPendingExports = () => async dispatch => {
  dispatch({ type: REQUEST__FETCH_HISTORY_PENDING_EXPORTS });

  let list = [];
  try {
    const res = await edgeDataApi.get('/user/history-data/pending');
    if (res?.data) {
      list = res.data.sort((a, b) => parseInt(b.ux) - parseInt(a.ux));
      list.forEach((pending) => {
        const { channel_name, profileName, ux } = pending;
        if (!channel_name || !profileName || !ux) {
          console.warn(`Pending download does not have proper fields. Skipping. ${channel_name} ${profileName} ${ux}`);
        } else {
          dispatch(initializePendingExportAblyChannel(channel_name, profileName, ux));
          dispatch({ type: ADD_PENDING_EXPORT, payload: { ...pending, loading: true } });
        }
      });
    }
  } catch (err) {
    console.log('[fetchHistoryPendingExports]', err);
  }
  dispatch({ type: FETCH_HISTORY_PENDING_EXPORTS, payload: list });
};


export const deleteExport = (meta) => async dispatch => {
  dispatch({ type: DELETE_HISTORY_EXPORT, payload: meta['ux'] })
  try {
    const { data } = await edgeDataApi.delete(`/user/history-data/${meta['ux']}`)
  } catch (err) {
    console.error(err);
  }
}

export const createHistoryExport = (filterProfile, columnProfile, exportName) => async dispatch => {
  dispatch({ type: REQUEST__CREATE_HISTORY_EXPORT });

  const { profileName: filterProfileName, activeFilters, orderby, order } = filterProfile;
  const { profileName: columnProfileName, activeColumns } = columnProfile;

  const defaultErrorMsg = 'There was an unexpected error processing your request';
  const cleanedFilters = resolveHistoryRollingDates(activeFilters);
  const columnLabels = getHistoryColumnLabels(activeColumns);

  let data = {};
  try {
    // This is conforming to an older structure. It would make sense to just save both profiles directly, but thats only because
    // the form changed significantly. Just restructure the profiles for now, maybe I can reformat the profiles in the database later.
    const res = await edgeDataApi.post('/user/history-data/downloads', {
      activeFilters: cleanedFilters,
      activeColumns: activeColumns,
      order,
      orderby,
      profileName: exportName,
      filterProfileName,
      columnProfileName,
      columnLabels,
      ux: (+ new Date())
    });
    data = res.data;
  } catch (err) {
    console.error(err);
    return dispatch({ type: SET_CREATE_EXPORT_ERROR, payload: defaultErrorMsg });
  }

  try {
    const { success, channel_name: channelName, profileName, ux } = data;

    if (!profileName || !ux || !success) {
      console.error('No data in createExport response');
      return dispatch({ type: SET_CREATE_EXPORT_ERROR, payload: defaultErrorMsg });
    }

    dispatch({ type: ADD_PENDING_EXPORT, payload: { ...data, loading: true } });
    dispatch(initializePendingExportAblyChannel(channelName, profileName, ux));

  } catch (err) {
    console.error(err);
    return dispatch({ type: SET_CREATE_EXPORT_ERROR, payload: defaultErrorMsg });
  }

  dispatch({ type: SET_HISTORY_EXPORT_MODAL_OPEN, payload: false });
  dispatch({ type: FINISH__CREATE_HISTORY_EXPORT });
};


const initializePendingExportAblyChannel = (channelName, profileName, ux) => async dispatch => {

  const setExpiration = (expiration) => {
    console.info(channelName, ' opened');
    setTimeout(() => {
      AblyService.closeChannel(channelName);
    }, expiration);
  };

  const handleMessage = async ({ error, message, data }) => {
    try {
      if (error) {
        console.error(error);
        const error_obj = { error, message, ux, loading: false };
        dispatch({ type: SET_PENDING_EXPORT_ERROR, payload: error_obj });
      } else {
        const { url, metadata } = data;
        dispatch({ type: REMOVE_PENDING_EXPORT, payload: ux });
        dispatch({ type: CREATE_HISTORY_EXPORT, payload: metadata });
        const res = await axios.get(url, { responseType: 'blob' });
        if (res?.data) {
          fileDownload(res.data, `${profileName}.csv`);
        }
      }
    } catch (err) {
      console.error(err);
      dispatch({ type: SET_PENDING_EXPORT_ERROR, payload: { error: 'client', message: 'Something went wrong while processing message.', loading: false, ux } });
    }

    AblyService.closeChannel(channelName);
  };

  AblyService.setChannelLifecycleCallbacks(channelName, {
    onOpenChannel: () => setExpiration(2 * 60 * 1000)
  });

  AblyService.openConnection(channelName, {
    handlerId: 'dl-export-message-handler',
    onMessage: message => handleMessage(message),
    onPresence: () => { console.log(`presence: ${channelName}`) },
  });

};


// Get rid of "dateRange" filter and replace with actual date between comparisons.
const resolveHistoryRollingDates = (filters) => {
  const cleanedFilters = cleanEmptyFilters(filters);
  const query = [];

  cleanedFilters.map(filt => {
    const filterCopy = { ...filt };
    let { name, selected } = filterCopy;

    const config = HISTORY_COLUMNS.find(col => col.name === name);

    if (!config) return;

    if (config.input === 'dateRange') {
      if (selected && ROLLING_DATE_OPTIONS?.[selected]) {
        const [from, to] = ROLLING_DATE_OPTIONS[selected].getDates();
        filterCopy.val = from;
        filterCopy.val2 = to;
      }
    }
    query.push(filterCopy);
  });
  return query;
};


const getHistoryColumnLabels = (activeColumns) => {
  const columnLabelMap = {};
  activeColumns.forEach(name => {
    const config = HISTORY_COLUMNS.find(col => col.name === name);
    if (!config) return;

    columnLabelMap[name] = config.label;
  });

  return columnLabelMap;
};
