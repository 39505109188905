import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import _noop from 'lodash/noop';
import { validateTimeFormat } from 'src/app/components/pickers/definitions/maskFunctions';
import clsx from 'clsx';
import { isValid, parse, set } from 'date-fns';
import { Box, Button, makeStyles } from '@material-ui/core';
import TimePickerColumn from 'src/app/components/pickers/components/TimePickerColumn';
import {
  COLUMN_DEFS,
  COLUMNS,
  getColumnsToShow,
} from 'src/app/components/pickers/definitions/timePickerColumns';
import { getCurrentTradingDay } from 'src/utils/datetime/date-fns.tz';

const useStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    flexDirection: 'column'
  },
  timeContent: {
    display: 'flex',
    flex: 'auto',
    height: 224,
    textAlign: 'center'
  },
  column: {
    position: 'relative',
    listStyle: 'none',
    flex: '1 0 auto',
    margin: '4px 0',
    padding: 0,
    textAlign: 'start',
    overflowX: 'hidden',
    overflowY: 'hidden',
    width: 55,
    '&:hover': {
      overflowY: 'auto'
    },
    '&:not(:first-child)': {
      borderInlineStart: `1px solid ${theme.palette.background.lightBorder}`,
      // borderInlineStart: `1px solid ${theme.palette.background.panelBorder}`
    },
    '& .time-cell': {
      width: 47,
      marginInline: 4,
      padding: 0,
      margin: 0,
      paddingRight: 10,
      '&.selected .time-cell-inner': {
        backgroundColor: [theme.palette.primary.darker, '!important'],
        fontWeight: 500,
      },
      '& .time-cell-inner': {
        display: 'block',
        margin: 0,
        paddingLeft: 9,
        color: theme.palette.text.primary,
        borderRadius: theme.grid.borderRadius,
        cursor: 'pointer',
        transition: 'background-color 50ms',
        backgroundColor: 'transparent',
        '&:hover': {
          backgroundColor: 'rgba(255,255,255,.1)'
        },
      },
      '&.disabled': {
        pointerEvents: 'none !important',
        opacity: '.3',
        cursor: 'not-allowed'
      }
    },
  },
  actions: {
    borderTop: `1px solid ${theme.palette.background.lightBorder}`,
  }
}));


/**
 * @param {Date|string} date
 * @param {string} format
 * @returns {{hours: number, minutes: number, seconds: number}}
 */
const initializeTime = (date, format) => {
  validateTimeFormat(format);

  if (!date) return { hours: 0, minutes: 0, seconds: 0 };
  const parsed = (typeof date === 'string') ? parse(date, format) : date;
  return {
    hours: parsed.getHours(),
    minutes: parsed.getMinutes(),
    seconds: parsed.getSeconds()
  };
};


/**
 * Build the individual options for an interval
 * @param {string} key
 * @param {number} step
 * @returns {number[]} - steps
 */
const getOptions = (key, step) => {
  const definition = COLUMN_DEFS[key];
  const [min, max] = definition.getRange();
  const values = [];
  for (let i = min; i <= max; i += step) {
    values.push(i);
  }
  return values;
};


/**
 * Can't find a lightweight scrollable time picker. So let's build one.
 * Shows each resolution as vertical lists of numbers, scrollable.
 * @component
 *
 * @todo
 *    - Add am/pm
 *    - Steps and rounding? What if they typed 10:47, but we have 5 min steps?
 *    - Now button. What if now is not valid? Either round, or disable.
 *    - Market Time
 */
function TimePicker({
  className,
  date,
  format,
  marketTime,
  showNowButton,
  disabledTime,
  steps,
  onChange,
}) {
  const classes = useStyles();
  const inputTimes = useMemo(() => initializeTime(date, format), [date, format]);
  const columnsToShow = getColumnsToShow(format);

  const handleNow = () => {
    const time = marketTime ? getCurrentTradingDay() : new Date();
    onChange(time);
  };

  const handleChange = (key, val) => {
    let newDate = date;
    if (!isValid(newDate)) {
      newDate = marketTime ? getCurrentTradingDay() : new Date();
    }
    onChange(set(newDate, {
      hours: 0,
      minutes: 0,
      seconds: 0,
      milliseconds: 0,
      ...inputTimes,
      [key]: val
    }));
  };


  const renderColumn = (key) => {
    const definition = COLUMN_DEFS[key];

    let checkDisabled = _noop;

    if (definition?.disabledCallbackArgs && disabledTime?.[key]) {
      const disableCallbackArgs = definition.disabledCallbackArgs.map(arg => inputTimes[arg]);
      checkDisabled = (val) => disabledTime[key](val, ...disableCallbackArgs);
    }

    /** @type {number} */
    const step = steps?.[key] || 1;

    /** @type {number[]} */
    const options = getOptions(key, step);

    return (
      <TimePickerColumn
        key={key}
        name={key}
        value={inputTimes[key]}
        className={classes.column}
        options={options}
        checkDisabled={checkDisabled}
        onClick={handleChange}
      />
    );
  };


  return (
    <Box className={clsx(
      className,
      classes.root
    )}>
      <Box className={classes.timeContent}>
        {columnsToShow.map(renderColumn)}
      </Box>
      {(showNowButton) && (
        <Box
          className={classes.actions}
          p={0}
          display="flex"
          justifyContent="space-between"
        >
          <Button onClick={handleNow}>Now</Button>
        </Box>
      )}
    </Box>
  );
}


export const TimePickerPropsTypes = {
  className: PropTypes.string,
  date: PropTypes.oneOfType([PropTypes.instanceOf(Date), PropTypes.string]),
  format: PropTypes.string,
  /** @type {DisabledTime} */
  disabledTime: PropTypes.shape({
    hours: PropTypes.func,   // () => { values: [], message: '' }
    minutes: PropTypes.func, // (selectedHour) => { values: [], message: '' }
    seconds: PropTypes.func  // (selectedHour, selectedMinute) => { values: [], message: '' }
  }),
  /** How many numbers to jump by */
  steps: PropTypes.shape({
    hours: PropTypes.number,
    minutes: PropTypes.number,
    seconds: PropTypes.number
  }),
  marketTime: PropTypes.bool,
  showNowButton: PropTypes.bool,
  onChange: PropTypes.func
};


TimePicker.propTypes = { ...TimePickerPropsTypes };


export const TimePickerDefaultProps = {
  showNowButton: true,
  steps: {
    hours: 1,
    minutes: 1,
    seconds: 1
  }
};


TimePicker.defaultProps = { ...TimePickerDefaultProps };

export default React.memo(TimePicker);
