import React from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { TRACKER_ACTIONS } from 'src/app/components/grid/topListScanner/dataSource/dataSource';
import SmallFilterWindow from 'src/app/components/filterContainers/SmallFilterWindow';
import PanelIconButton from 'src/app/components/panels/PanelIconButton';
import PanelIconGroup from 'src/app/components/panels/PanelIconGroup';
import AudioForm from 'src/app/TopListsMosaic/TopListScanner/forms/AudioForm';
import {
  AlertOffIcon,
  AlertOnIcon,
  FilterIcon
} from 'src/theme/EdgeIcons';
import {
  ExpandLess as ExpandLessIcon,
  ExpandMore as ExpandMoreIcon
} from '@material-ui/icons';
import {
  ButtonBase,
  Typography,
  Checkbox,
  FormControlLabel,
  FormGroup,
  makeStyles,
  useTheme
} from '@material-ui/core';


const useStyles = makeStyles(theme => ({
  root: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    borderTop: `1px solid #414141`,
    borderBottom: `1px solid #414141`,
  },
  closedRoot: {
    position: 'relative',
  },
  openedRoot: {
    height: 24,
    justifyContent: ['center', '!important'],
    '& div': {
      position: 'static',
      top: 'unset',
      right: 'unset',
      bottom: 'unset',
      left: 'unset'
    },
  },
  text: {
    whiteSpace: 'nowrap',
    marginRight: 3,
    fontSize: 13
  },
  flexText: {
    cursor: ['default', '!important'],
    display: 'flex',
    justifyContent: 'space-between',
  },
  openIcon: {
    fontSize: 20,
  },
  drawerButton: {
    flex: 1,
    width: '100%',
    paddingTop: 1,
    paddingBottom: 1,
    fontSize: 13,
    '& svg': {
      fontSize: 18,
    },
    '&:hover': {
      backgroundColor: 'rgba(255,255,255,.1)'
    },
  },
  drawerButtonOpen: {
    borderRadius: 4,
    flexGrow: 0,
    height: '100%',
    '& p': {
      marginLeft: [7, '!important'],
    }
  },
  form: {
    paddingTop: 2,
    paddingBottom: 2,
    '& .MuiFormControlLabel-root': {
      margin: 0,
      paddingLeft: 4,
      paddingRight: 7,
      paddingTop: 2,
      paddingBottom: 2,
      '&:hover': {
        backgroundColor: 'rgba(255, 255, 255, 0.08)'
      }
    },
    '& .MuiFormControlLabel-label': {
      fontSize: 13,
      fontWeight: 500,
      paddingLeft: 3
    },
    '& .MuiSvgIcon-root': {
      fontSize: 17
    },
    '& .MuiIconButton-root': {
      padding: 3,
      borderRadius: 4,
      '& .MuiIconButton-label': {
        fontSize: 12
      }
    }
  }
}));


const blockEvent = (e) => {
  e.preventDefault();
  e.stopPropagation();
}


/**
 * In order to get this to work with the split library, we have to do some weird stuff with the opened version.
 * The opened version MUST have the current structure and styling. Change the HTML and the drag handle will break.
 * The library isn't coded to arbitrarily assign the drag handle.
 * @component
*/
function ScannerTrackerBar({
  open,
  trackerOptions,
  onTrackerOptionsChange,
  audioOptions,
  onAudioOptionsChange,
  onClick,
  onMouseDown,
}) {
  const classes = useStyles();

  return (
    <div
      onMouseDown={onMouseDown}
      className={clsx(
        classes.root,
        open ? classes.openedRoot : classes.closedRoot,
      )}
    >
      <ButtonBase
        disableRipple
        onClick={() => onClick()}
        onMouseDown={blockEvent}
        className={clsx(
          classes.drawerButton,
          open && classes.drawerButtonOpen
        )}
      >
        <Typography className={classes.text}>Activity Log</Typography>
        {(open) ? <ExpandMoreIcon className={classes.openIcon} /> : <ExpandLessIcon className={classes.openIcon} />}
      </ButtonBase>
      {open && (
        <PanelIconGroup
          compact
          positionAbsolute
          onMouseDown={blockEvent}
        >
          {Boolean(onAudioOptionsChange) && (
            <AudioForm
              {...audioOptions}
              onOptionsChange={onAudioOptionsChange}
            />
          )}
          <SmallFilterWindow
            iconText="Settings"
            Icon={FilterIcon}
            shouldHideIconText={true}
            popoverMinWidth={200}
            className={classes.settingsButton}
            noGutters
          >
            <FormGroup className={classes.form}>
              <FormControlLabel
                control={<Checkbox checked={trackerOptions[TRACKER_ACTIONS.added]} onChange={onTrackerOptionsChange} name={TRACKER_ACTIONS.added} />}
                label="Added"
              />
              <FormControlLabel
                control={<Checkbox checked={trackerOptions[TRACKER_ACTIONS.removed]} onChange={onTrackerOptionsChange} name={TRACKER_ACTIONS.removed} />}
                label="Removed"
              />
            </FormGroup>
          </SmallFilterWindow>
        </PanelIconGroup>
      )}
    </div>
  );
}


ScannerTrackerBar.propTypes = {
  open: PropTypes.bool.isRequired,
  audioEnabled: PropTypes.bool,
  options: PropTypes.object,
  onOptionsChange: PropTypes.func,
  onToggleAudio: PropTypes.func,
  onClick: PropTypes.func,
  onMouseDown: PropTypes.func,
};


ScannerTrackerBar.defaultProps = {
  open: false,
  audioEnabled: false,
  options: {},
  onOptionsChange: noop,
  onClick: noop,
  onMouseDown: noop,
};


export default ScannerTrackerBar;
