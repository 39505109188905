import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import _uniqueId from 'lodash/uniqueId';
import clsx from 'clsx';
import HelpOutlineIcon from '@material-ui/icons/HelpOutline';
import useFetchFieldDefinition from './useFetchFieldDefinition';
import { Switch, Case } from 'src/app/components/utility/SwitchCase';
import MuiPopover from '@material-ui/core/Popover';
import {
  makeStyles,
  Typography,
  Chip,
  Box
} from '@material-ui/core';



const useStyles = makeStyles(theme => ({
  panel: {},
  content: {},
  trigger: {
    display: 'flex',
    alignItems: 'center',
    padding: 3,
    borderRadius: 4,
    color: theme.palette.text.secondary,
    '& svg': {
      fontSize: 20
    }
  },
  definition: {
    fontFamily: theme.typography.fontFamily,
    color: theme.palette.text.secondary,
    fontSize: 14,
    lineHeight: 1.3,
  },
  paperBase: {
    maxWidth: props => props.maxWidth,
    maxHeight: props => props.maxHeight,
    width: '100%',
    height: '100%'
  },
  paper: {
    maxWidth: 300,
    overflow: 'visible',
    width: 300,
    height: 'unset',
    maxHeight: 'unset',
  }
}))


function HoverFieldDefinitionInner({
  className,
  colDef,
  data,
  error,
}) {
  const classes = useStyles();

  return (
    <div className={clsx(classes.panel, className)}>
      <Box p={1}>
        <Box pb={1}>
          <Typography variant="h4">
            {colDef.label}
          </Typography>
        </Box>
        <Switch expr={Boolean(error)}>
          <Case when={true}>
            <Typography>{error}</Typography>
          </Case>
          <Case default>
            <div className={classes.content}>
              {/* <Box mb={1}> */}
              {/*   <Chip label="D[-1] After Hours" className={classes.blue} /><Chip label="Premarket" className={classes.beige} /><Chip label="Market" className={classes.white} /> */}
              {/* </Box> */}
              {Boolean(data?.field_definition) && (
                <Box mb={1}>
                  <div className={classes.definition} dangerouslySetInnerHTML={{ __html: data?.field_definition }} />
                </Box>
              )}
              {Boolean(data?.example) && (
                <Box mb={1}>
                  <Typography>Example:</Typography>
                  <div className={classes.definition} dangerouslySetInnerHTML={{ __html: data?.example }} />
                </Box>
              )}
            </div>
          </Case>
        </Switch>
      </Box>
    </div>
  )
}


/**
 * Fetches and shows the long field definitions on hover.
 *
 * The popup is opened AFTER the fetch, to avoid the awkward CSS transition and size pop-in.
 *
 * The fetch is globally cached, already managed by the hook.
 **/
function HoverFieldDefinition({
  className,
  paperClassName,
  panelClassName,
  colDef,
  anchorPosition
}) {
  const classes = useStyles()
  const [_id] = useState(() => _uniqueId('popover_'))
  const [anchorEl, setAnchorEl] = useState(false);
  const { state, fetchDefinition } = useFetchFieldDefinition(colDef?.name);

  const handleHover = (e) => {
    setAnchorEl(e.currentTarget);
    fetchDefinition()
  }

  const handleUnhover = useCallback(() => {
    setAnchorEl(null)
  }, [colDef?.name])

  const open = Boolean(anchorEl && !state.isFetching && (state?.data || state?.error))

  if (colDef?.noDefinition || !colDef?.name) return null;

  return (
    <>
      <div
        className={classes.trigger}
        onMouseEnter={handleHover}
        onMouseLeave={handleUnhover}
        onFocus={handleHover}
        onBlur={handleUnhover}
        onKeyDown={(e) => e.key === 'Escape' && handleUnhover()}
        aria-describedby={_id}
        role={'tooltip'}
        tabIndex={0}
      >
        <HelpOutlineIcon />
      </div>
      <MuiPopover
        className={clsx(className, classes.root)}
        classes={{
          paper: clsx(paperClassName, classes.paperBase, classes.paper)
        }}
        open={open}
        id={_id}
        anchorEl={anchorEl}
        onClose={handleUnhover}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: anchorPosition,
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: anchorPosition,
        }}
        disableRestoreFocus
        disableAutoFocus
        disableEnforceFocus
        hideBackdrop
        style={{ pointerEvents: 'none' }}
      >
        <HoverFieldDefinitionInner
          className={panelClassName}
          colDef={colDef}
          {...state}
        />
      </MuiPopover>
    </>
  )
}



HoverFieldDefinition.propTypes = {
  className: PropTypes.string
};


HoverFieldDefinition.defaultProps = {

};


export default HoverFieldDefinition;
