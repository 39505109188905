import { useEffect, useRef } from 'react';

/* setInterval inside useEffect has some real problems with closures and unmounting. This hook does a better job. */

export function useInterval(callback, delay, reset) {
  const savedCallback = useRef();
  const savedReset = useRef();

  useEffect(() => {
    savedCallback.current = callback;
    savedReset.current = reset;
  }, [callback]);

  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null || reset !== savedReset.current) {
      let id = setInterval(tick, delay);
      return () => { clearInterval(id); }
    }
  }, [delay, reset]);
}
