import {
  makeStyles
} from '@material-ui/core';
import clsx from 'clsx';
import { useMemo } from 'react';
import { useFormOverlayModal } from '../context/FormOverlayModalProvider';
import { useFormPermissions, useIsPredefined } from '../context/FormSettingsProvider';
import { PROFILE_PERMISSION_SCOPES } from 'src/hooks/useUserPlanPermissions';


const useStyles = makeStyles(theme => ({
  clickBlockTrigger: {
    position: 'absolute',
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
    '&.--inactive': {
      display: 'none'
    }
  },
}));



/** 
 * We need to place a clickblocker over the Interactable sections of all forms.
 * The blocker should:
 *  - Prevent interaction
 *  - Open the "Upgrade" modal if customization is disabled (permissions/FormSettingsProvider)
 *  - Open the "Copy Default" modal if necissary
 * 
 * locked is the same as useIsEditingDisabled!
 *
 * @returns {{locked: boolean, clickBlockerProps: {className: string, onClick: function}}}
 **/
function useCopyOrUpgradeClickblocker() {
  const classes = useStyles();
  const isPredefined = useIsPredefined();
  const [customizationDisabled] = useFormPermissions(PROFILE_PERMISSION_SCOPES.disabledCustomization)
  const { openOverlayModal } = useFormOverlayModal();

  return useMemo(() => {
    const locked = isPredefined || customizationDisabled.value;

    let onClick = () => { };
    if (customizationDisabled.value) {
      onClick = () => openOverlayModal('upgrade_customization', { suggestedPlanLevel: customizationDisabled.unlocksAt })
    } else if (isPredefined) {
      onClick = () => openOverlayModal('copy_default');
    }

    return {
      locked,
      clickBlockerProps: {
        className: clsx(classes.clickBlockTrigger, !locked && '--inactive'),
        onClick,
      }
    }
  }, [isPredefined, customizationDisabled, openOverlayModal, classes.clickBlockTrigger]);
}

export default useCopyOrUpgradeClickblocker;
