import { generateSimpleFilenameVerson } from 'src/utils/generateProfileFilenameVersion';


export const PUT_EXPRESSION = '@form-expression/PUT_EXPRESSION';
export const CREATE_EXPRESSION = '@form-expression/CREATE_EXPRESSION';
export const DELETE_EXPRESSION = '@form-expression/DELETE_EXPRESSION';



export const expressionListToMap = expressions => {
  return expressions.reduce((acc, e) => ({ ...acc, [e.name]: e }), {});
}

/**
 * Intermediate reducer for expressions during a form session.
 * Meant to be used in the main FormProvider component. 
 *
 * Directly editing is not allowed here. Instead, we run an expressionDetailsReducer
 * first, that onSubmit gets saved to this reducer.
 *
 * This is so the "cancel" button works as expected, even when nested deeply:
 *
 * redux state
 *    SlicedForm state 
 *      ExpressionDetails state
 * 
 * Any of these can be cancelled, without propagating to the parent.
 **/
export default function expressionReducer(draft, action) {
  switch (action.type) {

    case PUT_EXPRESSION: {
      const data = action.payload;

      const idx = draft.expressions.findIndex(e => e.name === data.name);

      if (idx === -1) {
        data.label = generateSimpleFilenameVerson(data.label, draft.expressions.map(e => e.label).filter(e => e.name !== data.name));
        draft.expressions.push(data);
      } else {
        draft.expressions[idx] = data;
      }

      break;
    }

    case DELETE_EXPRESSION: {
      const name = action.payload;

      const idx = draft.expressions.findIndex(e => e.name === name);

      if (idx === -1) return;

      draft.expressions.splice(idx, 1);
      break;
    }

    default: {
      break;
    }
  }
}
