import React, { useState } from 'react';
import clsx from 'clsx';
import { NumericFormat } from 'react-number-format';
import { MoreIcon } from 'src/theme/EdgeIcons';
import { ChevronLeft as ChevronLeftIcon, SwapHoriz as SwapHorizIcon, AddCircleOutline as AddCircleOutlineIcon } from '@material-ui/icons';
import {
  ListItem,
  ListItemIcon,
  ListItemText,
  Collapse,
  ListItemSecondaryAction,
  IconButton,
  SvgIcon,
  InputBase,
  Checkbox,
  makeStyles,
  useTheme
} from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& .basic-icon-button-stripped': {
      padding: 0,
      borderRadius: 4
    },
    '& .ks-cr-spacer': {
      maxWidth: 55,
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      '&.small': {
        maxWidth: 25
      }
    },
    '& .ks-lt-icon svg': {
      fontSize: 20
    },
    '& .ks-cr-swap-icon': {
      fontSize: 20,
      position: 'absolute',
      right: 0,
      top: '50%',
      padding: 0,
      borderRadius: 4,
      transform: 'translateY(-50%)'
    },
    '& .not-unique': {

    }
  },
  alarmPanel: {
    padding: '0 20px 8px',
    borderBottom: `1px solid ${theme.palette.background.panelBorder}`
  },
  alarmPanelDisabled: {
    opacity: .5,
  },
  alarmPanelTop: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  alarmPanelBottom: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative',
    marginTop: 7,
  },
  alarmCircle: {
    borderRadius: 4,
    width: 15,
    height: 15,
  },
  filterInput: {
    border: `1px solid ${theme.palette.text.secondary}`,
    borderRadius: 3,
    maxWidth: 136,
    '& .MuiInputBase-input': {
      padding: 5,
      color: theme.palette.text.secondary
    }
  }
}));

function SingleMetricCheckboxAccordion({
  selected,
  row,
  onAddItem,
  disabled,
  alarms,
  onAlarmChange,
  onSwapAlarms,
  unique = true,
}) {
  const classes = useStyles();
  const theme = useTheme();
  const [expanded, setExpanded] = useState(false);
  const { name, label, alarmsEnabled, filterFormLabel } = row;
  const { less, greater, reversed } = alarms;

  const handleOnAlarmChange = (metricName, type, values) => {
    const { value } = values;
    onAlarmChange(metricName, type, value);
  };


  return (
    <div className={clsx(classes.root, !unique && 'not-unique')}>
      <ListItem
        title={label}
        className="top-news-filter-list-item"
        key={name}
        disableRipple
        button
        disabled={disabled}
        onClick={() => onAddItem(name, selected)}
      >
        <ListItemIcon>
          {unique
            ? <Checkbox
              edge="start"
              checked={selected}
              tabIndex={-1}
              disableRipple
              inputProps={{ 'aria-labelledby': `${label}-checkbox` }}
            />
            : <AddCircleOutlineIcon color="primary" style={{ fontSize: '19px' }} />
          }
        </ListItemIcon>
        <ListItemText id={`${label}-checkbox`}>{filterFormLabel || label}</ListItemText>
        {alarmsEnabled
          && (
            <ListItemSecondaryAction style={{ right: 31 }}>
              <IconButton
                className="basic-icon-button-stripped"
                disableRipple
                edge="end"
                aria-label="Expand more filters"
                onClick={() => setExpanded(!expanded)}
                color={(less || greater) ? "primary" : "default"}
              >
                <MoreIcon />
              </IconButton>
            </ListItemSecondaryAction>
          )}
      </ListItem>

      <Collapse in={expanded}>
        <div className={clsx(classes.alarmPanel, disabled && classes.alarmPanelDisabled)}>
          <div className={classes.alarmPanelTop}>
            <NumericFormat
              value={less || ''}
              onValueChange={(values) => handleOnAlarmChange(name, 'less', values, less, { max: greater })}
              className={classes.filterInput}
              thousandSeparator={true}
              customInput={InputBase}
              inputProps={{ max: greater, 'aria-label': 'Change smaller alarm value' }}
              disabled={disabled}
            />
            <div className="ks-cr-spacer small" />
            <NumericFormat
              value={greater || ''}
              onValueChange={(values) => handleOnAlarmChange(name, 'greater', values, greater, { min: less })}
              className={classes.filterInput}
              thousandSeparator={true}
              customInput={InputBase}
              inputProps={{ min: less, 'aria-label': 'Change larger alarm value' }}
              disabled={disabled}
            />
          </div>
          <div className={classes.alarmPanelBottom}>
            <div className="ks-cr-spacer">
              <div style={{ backgroundColor: reversed ? theme.palette.alarms.greater : theme.palette.alarms.less }} className={classes.alarmCircle} />
            </div>
            <SvgIcon className="ks-lt-icon">
              <ChevronLeftIcon />
            </SvgIcon>
            <div className="ks-cr-spacer">
              <div style={{ backgroundColor: theme.palette.alarms.between }} className={classes.alarmCircle} />
            </div>
            <SvgIcon className="ks-lt-icon">
              <ChevronLeftIcon />
            </SvgIcon>
            <div className="ks-cr-spacer">
              <div style={{ backgroundColor: reversed ? theme.palette.alarms.less : theme.palette.alarms.greater }} className={classes.alarmCircle} />
            </div>
            <IconButton
              className="ks-cr-swap-icon"
              onClick={() => onSwapAlarms(name)}
              disableRipple
              color={reversed ? "primary" : "default"}
              disabled={disabled}
            >
              <SwapHorizIcon />
            </IconButton>
          </div>
        </div>
      </Collapse>
    </div>
  );
}

export default SingleMetricCheckboxAccordion;
