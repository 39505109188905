/**
 * Check if DOM node is on the page, and in the viewport
 * @param {Element} element
 * @return {boolean}
 */
export function isElementVisible(element) {
  if (!element) {
    return false;
  }

  if (element instanceof Element) {
    // absolute/table positioning
    if (element.offsetParent) {
      return true;
    }

    // svg
    if (element.getBBox) {
      const { width, height } = element.getBBox();
      if (width || height) {
        return true;
      }
    }

    // normal element
    if (element.getBoundingClientRect) {
      const { width, height } = element.getBoundingClientRect();
      if (width || height) {
        return true;
      }
    }
  }

  return false;
}


/**
 * Holds the active animation IDs, so we can cancel them if need be
 * @type {Map<HTMLElement, number>}
 */
const scrollIds = new Map();


/**
 * Call the callback when element is ready, for animations
 * @param {HTMLLIElement} element
 * @param {function} callback
 * @returns {function} - cancelAnimationFrame callback
 */
export function waitElementReady(element, callback) {
  let id;

  const tryOrNextFrame = () => {
    if (isElementVisible(element)) {
      callback();
    } else {
      id = requestAnimationFrame(() => {
        tryOrNextFrame();
      })
    }
  }

  tryOrNextFrame();

  return () => {
    cancelAnimationFrame(id);
  }
}


/**
 *
 * @param {HTMLElement} element
 * @param {number} to
 * @param {number} duration
 * @returns {void}
 */
export function scrollTo(element, to, duration = 0) {
  if (scrollIds.has(element)) {
    cancelAnimationFrame(scrollIds.get(element));
  }

  if (duration <= 0) {
    scrollIds.set(element, requestAnimationFrame(() => {
      element.scrollTop = to;
    }));

    return;
  }

  const difference = to - element.scrollTop;
  const perTick = (difference / duration) * 10;
  scrollIds.set(element, requestAnimationFrame(() => {
    element.scrollTop += perTick;
    if (element.scrollTop !== to) {
      scrollTo(element, to, duration - 10);
    }
  }));
}
