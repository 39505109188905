import React, { useCallback, useMemo } from 'react';
import Highlighter from 'react-highlight-words';
import noop from 'lodash/noop';
import { formatSimilarity, getFullRowNodeData } from 'src/app/components/grid/topListNews/columns/columnDefs';

function NewsHeadlineCellRenderer(props) {
  const {
    searchKeywords = [],
    isInnerGrid = false,
    handleRscoreClick = noop,
  } = props.context;



  const onRscoreClick = useCallback(() => {
    if (!isInnerGrid) {
      handleRscoreClick(getFullRowNodeData(props.node));
    }
  }, []);

  const headlineValue = useMemo(() => {
    if (searchKeywords && searchKeywords.length) {
      return <Highlighter
        highlightClassName="text-highlighted"
        searchWords={searchKeywords}
        autoEscape={true}
        textToHighlight={props.node.data?.headline}
      />;
    } else {
      return props.node.data?.headline;
    }
  }, [searchKeywords, props.node.data?.headline]);


  return (
    <div className="ag-cell-news">
      {(props.node.level === 0 || props.node?.rowPinned === 'top') && (
        <>
          <div className="ag-cell-news-meta">
            <span className="ag-cell-text-seconary ag-meta-text ag-no-break ag-meta-element-sep ag-meta-date">{props.node.data?.dateDisplay}</span>
            <span className="ag-cell-text-highlight ag-meta-text ag-no-break ag-meta-element-sep">{props.node.data?.source}</span>
            {!isInnerGrid && props.node.data?.similarity && (
              <a
                title="Similarity"
                onClick={onRscoreClick}
                className="prevent-row-click ag-meta-element-sep ag-news-cell-btn-xs"
                role="button"
              >
                <span className="ag-cell-text-seconary">R: {formatSimilarity(props.node.data?.similarity)}</span>
              </a>
            )}
          </div>
          <div className="ag-cell-headline">
            <a
              href={props.node.data?.story_url}
              target="_blank"
              rel="noopener noreferrer"
              className="prevent-row-click ag-news-headline"
            >
              {headlineValue}
            </a>
          </div>
        </>
      )}
      {(props.node.level === 1 && props.node.data?.similarity) && (
        <a
          title="Similarity"
          onClick={onRscoreClick}
          className="prevent-row-click ag-meta-element-sep ag-news-cell-btn-xs"
          role="button"
        >
          <span className="ag-cell-text-seconary">R: {formatSimilarity(props.node.data.similarity)}</span>
        </a>
      )}
    </div>
  );
}


export default NewsHeadlineCellRenderer;


