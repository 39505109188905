import { useCallback } from 'react';
import useRouterLinksContextMenu from './sections/useRouterLinksContextMenu';
import joinNestedArrays from 'src/utils/joinNestedArrays';
import { DEFUALT_MENU_ITEMS } from './constants';
import useWatchlistContextMenu from './sections/useWatchlistContextMenu';
import usePreventCellChangeFlashContextMenu from './sections/usePreventCellChangeFlashContextMenu';



/**
 * @param {string} forceTicker - If no ticker exists for a given cell, use this one
 * @param {function} [setCellFlashDisabled] - Toggle component state to stop cell flashing
 */
function useNewsContextMenu({
  forceTicker = null,
  setCellFlashDisabled,
} = {}) {
  const makeRouterLinksContextMenu = useRouterLinksContextMenu();
  const makeWatchlistContextMenu = useWatchlistContextMenu({ forceTicker });
  const makePreventCellChangeFlashContextMenu = usePreventCellChangeFlashContextMenu({ setCellFlashDisabled });

  return useCallback((contextMenuParams) => {
    return joinNestedArrays([
      makeWatchlistContextMenu(contextMenuParams),
      makeRouterLinksContextMenu(contextMenuParams),
      makePreventCellChangeFlashContextMenu(contextMenuParams),
    ], DEFUALT_MENU_ITEMS.separator);
  }, [makeRouterLinksContextMenu, makeWatchlistContextMenu, makePreventCellChangeFlashContextMenu])
}


export default useNewsContextMenu;
