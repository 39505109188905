import React, { useState } from 'react';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import FilterWindowSearch from 'src/app/components/filterElements/FilterWindowSearch';
import _uniqueId from 'lodash/uniqueId';
import {
  Chip,
  makeStyles,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {},
  keywordChips: {
    display: 'flex',
    flexWrap: 'wrap',
    paddingTop: 12,
    marginLeft: theme.spacing(-.3),
    marginRight: theme.spacing(-.3)
  },
  chip: {
    margin: theme.spacing(.3),
    backgroundColor: 'rgba(0,0,0,0.19)',
    '&:focus': {
      backgroundColor: 'rgba(0,0,0,0.19)'
    }
  }
}));


function FilterKeywordSearchMultiChip({
  className,
  children,
  keywords,
  onKeywordChange,
  maxChips,
  width,
  minWordLength,
  maxWordLength
}) {
  const classes = useStyles();
  const [inputVal, setInputVal] = useState('');
  const [id] = useState(() => _uniqueId('kw-search-'));

  const handleChange = (event) => {
    setInputVal(event.target.value);
  };

  const handleKeyPress = (event) => {
    if (event.keyCode === 13) {
      handleKeywordSubmit(inputVal);
    }
  };

  const handleChipDelete = (word) => {
    onKeywordChange(keywords.filter(w => w !== word));
  };

  const handleKeywordSubmit = (keyword) => {
    if (keyword.length < minWordLength) return;
    if (keyword.length > maxWordLength) {
      keyword = keyword.substring(0, maxWordLength);
    }
    setInputVal('');
    if (keyword) {
      const chips = [...new Set([...keywords, keyword.trim()])];
      if (chips.length > maxChips) {
        chips.shift();
      }
      onKeywordChange(chips);
    }
  };

  return (
    <div className={clsx(classes.root, className)} style={{ width }}>
      <div className="input-bar-wrap">
        <FilterWindowSearch
          placeholder="Search..."
          value={inputVal}
          id={id}
          handleChange={handleChange}
          handleKeyPress={handleKeyPress}
          handleBlur={() => handleKeywordSubmit(inputVal)}
          className={'input-component-wrap'}
        />
        {children}
      </div>
      {keywords.length > 0 && (
        <div className={classes.keywordChips}>
          {keywords.map(word => <Chip clickable={false} size="small" label={word} key={word} onDelete={() => handleChipDelete(word)} className={classes.chip}/>)}
        </div>
      )}
    </div>
  );
}


FilterKeywordSearchMultiChip.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any,
  name: PropTypes.string,
  keywords: PropTypes.arrayOf(PropTypes.string),
  onKeywordChange: PropTypes.func,
  maxChips: PropTypes.number,
  width: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  minWordLength: PropTypes.number
}


FilterKeywordSearchMultiChip.defaultProps = {
  maxChips: 10,
  width: 360,
  minWordLength: 1,
  maxWordLength: 100
}


export default FilterKeywordSearchMultiChip;
