import React, { useCallback, useState, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import {
  VolumeUp,
  VolumeDown,
  VolumeOff
} from '@material-ui/icons'
import {
  Typography,
  Slider,
  makeStyles,
  Grid,
  Box,
  IconButton
} from '@material-ui/core';
import useStateFromProp from 'src/hooks/useStateFromProp';
import { DEFAULT_VOLUME } from './constants';


const useStyles = makeStyles(theme => ({
  root: {},
  volumeCont: {
    display: 'flex',
    alignItems: 'center'
  },
  volume: {
    position: 'absolute',
    top: 0,
    left: 0
  },
  volumeOff: {},
  volumeDown: {
    left: -2
  },
  volumeUp: {},
  volumeIconButton: {
    borderRight: `1px solid ${theme.palette.background.lightBorder}`,
    '& .MuiIconButton-label': {
      width: 20,
      height: 22,
      position: 'relative',
    },
    padding: '5px 6px',
    borderRadius: 4,
  },
  sliderEl: {
    paddingTop: 15,
    paddingBottom: 12,
    '& .MuiSlider-thumb::after': {
      content: 'none'
    },
    '& .MuiSlider-thumb.Mui-focusVisible, .MuiSlider-thumb:hover': {
      boxShadow: '0px 0px 0px 4px rgba(138, 133, 255, 0.16)'
    },
    '& .MuiSlider-thumb.MuiSlider-active': {
      boxShadow: '0px 0px 0px 10px rgba(138, 133, 255, 0.16)'
    },
  },
}));



function VolumeSlider({
  className,
  disabled,
  value = DEFAULT_VOLUME,
  lastValue,
  onChange
}) {
  const classes = useStyles();
  const [inputValue, setInputValue] = useStateFromProp(value);
  const [dragging, setDragging] = useState(false);

  const handleChange = useCallback((_, newValue) => setInputValue(newValue), [])

  const handleChangeCommited = useCallback((_, newValue) => {
    if (newValue === value) return;
    onChange(newValue);
  }, [onChange]);

  const icon = useMemo(() => {
    if (inputValue === 0) return <VolumeOff className={clsx(classes.volumeOff, classes.volume)} />;
    if (inputValue < 50) return <VolumeDown className={clsx(classes.volumeDown, classes.volume)} />;
    return <VolumeUp className={clsx(classes.volumeUp, classes.volume)} />;
  }, [inputValue])

  const onIconClick = useCallback(() => {
    // Toggle behavior. Remember last value.
    if (value === 0 && lastValue === 0) {
      onChange(DEFAULT_VOLUME)
    } else if (value === 0) {
      onChange(lastValue)
    } else {
      onChange(0);
    }
  }, [value, lastValue, onChange])

  const handleDragStart = useCallback((type) => {
    // Prevent the IconButton from interfering with the drag event.
    // Have to do it this way to capture End events even when dragged outside
    // of the slider element.
    return () => {
      setDragging(true);

      document.addEventListener(
        type === "mouse" ? 'mouseup' : 'touchend',
        () => setDragging(false),
        { once: true }
      )
    }
  }, []);


  return (
    <div className={clsx(className, classes.sliderRoot)}>
      <Grid container spacing={1}>
        <Grid item className={classes.volumeCont}>
          <IconButton
            className={classes.volumeIconButton}
            disabled={disabled}
            disableRipple
            onClick={onIconClick}
            style={{ pointerEvents: dragging ? 'none' : 'auto' }}
          >
            {icon}
          </IconButton>
        </Grid>
        <Grid item xs>
          <Box pr={2} pl={1}>
            <Slider
              className={classes.sliderEl}
              disabled={disabled}
              value={inputValue}
              onChange={handleChange}
              onChangeCommitted={handleChangeCommited}
              onMouseDown={handleDragStart("mouse")} // Note: better memo?
              onTouchStart={handleDragStart("touch")}
              aria-labelledby="continuous-slider"
            />
          </Box>
        </Grid>
      </Grid>
    </div>
  );
}


VolumeSlider.propTypes = {
  disabled: PropTypes.bool,
  value: PropTypes.number,
  lastValue: PropTypes.number,
  onChange: PropTypes.func
};

export default VolumeSlider;
