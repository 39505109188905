import React, { useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import clsx from 'clsx';
import useListItemStyles from 'src/app/TopListsMosaic/layout/ManageLayouts/useListItemStyles';
import { useSelector, useDispatch } from 'react-redux';
import { generateSimpleFilenameVerson } from 'src/utils/generateProfileFilenameVersion';
import { selectWatchlistProfiles } from 'src/redux/layout/topListLayoutSelectors';
import { PROFILE_CONFIG } from 'src/redux/layout/topListLayoutSchema';
import ManageLayoutsListItem, { STEPS } from 'src/app/TopListsMosaic/layout/ManageLayouts/ManageLayoutsListItem';
import NewItemButton from 'src/app/TopListsMosaic/layout/ManageLayouts/NewItemButton';
import {
  intIdExists,
  createWatchlist,
  renameWatchlist,
  deleteWatchlist,
  updateComponent
} from 'src/redux/layout/topListLayoutActions';
import { makeStyles } from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {
    width: props => props.count >= 12 ? 280 : 270
  },
  list: {
    paddingTop: 0,
    paddingBottom: 0,
    overflowY: 'auto',
    overflowX: 'hidden',
    maxHeight: 370,
    position: 'relative'
  },
}));



function ManageWatchlistsMenu({
  className,
  layoutId,
  componentId,
  watchlistProfileId,
  handleClose,
  setIsFetching,
}) {
  const watchlists = useSelector(selectWatchlistProfiles);
  const classes = useStyles({ count: watchlists.length });
  const dispatch = useDispatch();
  const [listItemStates, setListItemStates] = useState({});
  const newFileName = generateSimpleFilenameVerson('New Watchlist', watchlists.map(({ profileName }) => profileName));

  const handleFormStep = (id, step) => {
    setListItemStates({
      ...listItemStates,
      [id]: step
    })
  }

  const isOtherItemActive = (id) => {
    for (const [lid, state] of Object.entries(listItemStates)) {
      if (state !== STEPS.DISPLAYING && lid !== `${id}`) {
        return true;
      }
    }
    return false;
  }

  const handleRename = (id, newName) => dispatch(renameWatchlist(id, newName));
  const handleDelete = (id) => dispatch(deleteWatchlist(id));
  const handleCreate = (newName) => {
    dispatch(createWatchlist(componentId, layoutId, newName));
    setIsFetching(true);
    handleClose();
  }
  const handleCopy = (id, oldName) => {
    const newName = generateSimpleFilenameVerson(oldName, watchlists.map(({ profileName }) => profileName));
    dispatch(createWatchlist(componentId, layoutId, newName, id));
    setIsFetching(true);
    handleClose();
  }
  const handleSelect = (id) => {
    if (!intIdExists(id)) return;
    dispatch(updateComponent(componentId, layoutId, {
      [PROFILE_CONFIG.WATCHLIST_ROWS.idKey]: id,
    }));
    setIsFetching(true);
    handleClose();
  }

  return (
    <div className={clsx(className, classes.root)}>
      <NewItemButton
        onConfirm={handleCreate}
        newFileName={newFileName}
        buttonText="New Watchlist"
        useStyles={useListItemStyles}
      />
      <div
        aria-label="Modify Watchlists"
        className={classes.list}
      >
        {watchlists.map(wl => (
          <ManageLayoutsListItem
            key={wl.id}
            id={wl.id}
            textWidth={170}
            useStyles={useListItemStyles}
            title={wl.profileName}
            checkbox={false}
            predefined={wl.predefined}
            disabled={isOtherItemActive(wl.id)}
            formStep={listItemStates?.[wl.id]}
            selected={watchlistProfileId === wl.id}
            onFormStep={(step) => handleFormStep(wl.id, step)}
            onClick={handleSelect}
            onCopy={handleCopy}
            onEdit={!wl.predefined ? handleRename : undefined}
            onDelete={!wl.predefined ? handleDelete : undefined}
          />
        ))}
      </div>
    </div>
  );
}


ManageWatchlistsMenu.propTypes = {
  className: PropTypes.string,
  layoutId: PropTypes.string,
  componentId: PropTypes.string,
  handleClose: PropTypes.func,
  setIsFetching: PropTypes.func,
};


ManageWatchlistsMenu.defaultProps = {
  handleClose: noop,
  setIsFetching: noop
}



export default ManageWatchlistsMenu;
