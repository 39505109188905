import React from 'react';
import { useSelector } from 'react-redux';
import { Redirect } from 'react-router-dom';
import SplashScreen from 'src/components/SplashScreen';
import authService from 'src/services/authService';
import Subscription from 'src/services/Subscription';
import useVerifyBetaAccess from '../hooks/useVerifyBetaAccess';

function BillingGuard({ children }) {
  const user = useSelector(state => state.account.user);
  const [hasBetaAccess, isVerifyingBetaAccess] = useVerifyBetaAccess();

  if (!authService.getCurrentUser()) {
    return <Redirect to="/login"/>;
  }

  if (!user) {
    return <Redirect to="/login"/>;
  }

  if (isVerifyingBetaAccess) {
    return <SplashScreen/>;
  }

  if (hasBetaAccess) {
    return <Redirect to="/overview" />
  }

  if (user.subscriptionId) {
    const subscription = new Subscription(user, user.subscriptionId);
    if (subscription.hasAccess()) {
      return <Redirect to="/account/subscription"/>;
    }
  }

  return children;
}


export default BillingGuard;
