import _memoize from 'lodash/memoize';
import {
  matchLastCharRegex,
} from 'src/utils/formatters';

/**
 * Int.NumberFormat cannot both compact and use accounting notation.
 * Modify it manually.
 *
 * @example
 * const fmt = addAcountingNegative(new Intl.NumberFormat('en-US', {...}).format);
 * console.log(fmt(-1_000_000))
 * >> ($1.00)M
 **/
const addAcountingNegative = currencyFormatter => val => {
  if (!val === undefined || val === null || isNaN(val)) return '';

  const value = currencyFormatter(val);
  const compacted = value.match(matchLastCharRegex);

  if (!value.startsWith('-')) return value;
  if (!compacted) return `(${value.slice(1)})`;

  return `(${value.slice(1, -1)})${value[value.length - 1]}`;
}

const compactIntegerBase = addAcountingNegative(
  new Intl.NumberFormat('en-US', {
    notation: 'compact',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format
);

const percentage = new Intl.NumberFormat('en-US', {
  style: 'percent',
  maximumFractionDigits: 2,
  minimumFractionDigits: 2,
  currencySign: 'accounting'
}).format;


export const formatNumber = params => compactIntegerBase(params.value);

export const formatPercentage = params => percentage(params.value);

export const SHEET_KEYS = {
  BS: 'bs',
  IS: 'is',
  CF: 'cf',
}

export const ROW_TYPES = {
  section: 'section',
  subhead: 'subhead',
  spacer: 'spacer',
  sum: 'sum',
  metric: 'metric' // implied by default
}

export const PERIODS = {
  quarterly: 'quarterly',
  annual: 'annual'
}

/**
 * Search a Sheet schema, and return the Row schema for that metric
 * @param {string} metricName
 * @param {string} sheet
 * @returns {object} - The row schema
 **/
export const getRowSchemaByName = _memoize(
  (metricName, sheet) => {
    const sheetSchema = SHEETS[sheet];

    const recurse = (rows) => {
      for (const row of rows) {
        if (row.name === metricName) {
          return row;
        }
        if (row.rows) {
          const val = recurse(row.rows);
          if (val) return val;
        }
      }
      return null;
    };

    return recurse(sheetSchema.sections);
  },
  (a, b) => `${a}_${b}`
);



/**
 * Take a single sheet of our rowSchema, and get a flat list of metric schemas.
 * Used to build ChartSidebar.
 * @param {string} sheet
 * @returns {Object[]}
 **/
export const extractFlatMetrics = (sheet) => {
  const sheetSchema = SHEETS[sheet];

  const nonMetricRowTypes = [
    ROW_TYPES.spacer,
    ROW_TYPES.subhead,
    ROW_TYPES.section
  ]

  const recurse = (rows) => {
    return rows.reduce((acc, row) => {
      if (row.type === ROW_TYPES.section) {
        return [...acc, ...recurse(row.rows)];
      }
      if (nonMetricRowTypes.includes(row.type)) {
        return acc;
      }
      return [...acc, row];
    }, []);
  }

  return recurse(sheetSchema.sections);
}



let sid = 0;
let hid = 0;

const spacer = () => ({
  label: sid,
  name: `spacer-${sid++}`,
  type: ROW_TYPES.spacer,
});


const subhead = (label, indent = -1) => ({
  label,
  name: `subhead-${hid++}`,
  indent,
  type: ROW_TYPES.subhead,
});



const balanceSheet = {
  name: SHEET_KEYS.BS,
  label: 'Balance Sheet',
  sections: [
    {
      name: 's_assets',
      label: 'Assets',
      type: ROW_TYPES.section,
      rows: [

        subhead('Current Assets'),

        {
          name: 'cashandcashequivalents',
          label: 'Cash & Cash Equivalents',
        },
        {
          name: 'shortterminvestments',
          label: 'Short Term Investments',
        },
        {
          name: 'cashandshortterminvestments',
          label: 'Cash & Short Term Investments',
          type: ROW_TYPES.sum,
        },

        spacer(),

        {
          name: 'netreceivables',
          label: 'Accounts Receivable & Other',
        },
        {
          name: 'bs_inventory',
          label: 'Inventory',
        },
        {
          name: 'othercurrentassets',
          label: 'Other Current Assets',
        },
        {
          name: 'totalcurrentassets',
          label: 'Total Current Assets',
          type: ROW_TYPES.sum,
        },

        spacer(),
        subhead('Long Term Assets'),

        {
          name: 'propertyplantequipmentnet',
          label: 'Property Plant Equipment Net',
        },
        {
          name: 'goodwill',
          label: 'Goodwill',
        },
        {
          name: 'intangibleassets',
          label: 'Intangible Assets',
        },
        {
          name: 'longterminvestments',
          label: 'Long Term Investments',
        },
        {
          name: 'taxassets',
          label: 'Deferred Tax Assets'
        },
        {
          name: 'othernoncurrentassets',
          label: 'Other Long Term Assets',
        },
        {
          name: 'totalnoncurrentassets',
          label: 'Total Long-Term Assets',
          type: ROW_TYPES.sum,
        },

        spacer(),

        {
          name: 'otherassets',
          label: 'Other Assets'
        },

        {
          name: 'totalassets',
          label: 'Total Assets',
          type: ROW_TYPES.sum,
          indent: -1,
          bold: true
        },

        spacer(),
      ]
    },



    {
      name: 's_liabilities',
      label: 'Liabilities',
      type: ROW_TYPES.section,
      rows: [

        subhead('Current Liabilities'),

        {
          name: 'accountspayables',
          rowId: 'accountspayables_0',
          label: 'Accounts Payable',
        },
        {
          name: 'shorttermdebt',
          label: 'Short Term Debt',
        },
        {
          INCOMPLETE: 'MAYBE WRONG METRIC',
          name: 'taxpayables',
          label: 'Current Income Tax Payable',
        },
        {
          INCOMPLETE: 'MAYBE WRONG METRIC',
          name: 'deferredrevenue',
          label: 'Current Unearned Revenue',
        },
        {
          name: 'othercurrentliabilities',
          label: 'Other Current Liabilities',
        },
        {
          name: 'totalcurrentliabilities',
          label: 'Total Current Liabilities',
          type: ROW_TYPES.sum,
        },

        spacer(),
        subhead('Long Term Liabilities'),

        {
          name: 'longtermdebt',
          label: 'Long Term Debt',
        },
        {
          INCOMPLETE: 'MAYBE WRONG METRIC',
          label: 'Long Term Unearned Revenue',
          name: 'deferredrevenuenoncurrent',
        },
        {
          name: 'deferredtaxliabilitiesnoncurrent',
          label: 'Long Term Deferred Tax Liabilities',
        },
        {
          name: 'othernoncurrentliabilities',
          label: 'Other Long Term Liabilities',
        },
        {
          name: 'totalnoncurrentliabilities',
          label: 'Total Long Term Liabilities',
          type: ROW_TYPES.sum,
        },

        spacer(),

        {
          name: 'otherliabilities',
          label: 'Other Liabilities',
        },
        {
          name: 'totalliabilities',
          label: 'Total Liabilities',
          type: ROW_TYPES.sum,
          indent: -1,
          bold: true
        },

        spacer(),

        subhead('Stockholders Equity'),

        {
          name: 'preferredstock',
          label: 'Preferred Stock'
        },
        {
          name: 'commonstock',
          label: 'Common Stock'
        },
        {
          name: 'retainedearnings',
          label: 'Retained Earnings'
        },
        {
          name: 'accumulatedothercomprehensiveincomeloss',
          label: 'Comprehensive Income & Other',
        },
        {
          name: 'othertotalstockholdersequity',
          label: 'Other Stockholders Equity'
        },
        {
          name: 'totalequity',
          label: 'Total Equity',
          type: ROW_TYPES.sum,
          indent: -1,
          bold: true
        },

        spacer(),

        {
          name: 'minorityinterest',
          label: 'Minority Interest',
        },

        {
          name: 'totalliabilitiesandtotalequity',
          label: 'Total Liabilities & Equity',
          type: ROW_TYPES.sum,
          indent: -1,
          bold: true
        },

        spacer(),
      ]
    },


    {
      name: 's_supplimental',
      label: 'Supplementary Data',
      type: ROW_TYPES.section,
      rows: [
        {
          name: 'totaldebt',
          label: 'Total Debt',
        },
        {
          name: 'netdebt',
          label: 'Net Debt'
        }
      ]
    }
  ]
}


const incomeStatement = {
  name: SHEET_KEYS.IS,
  label: 'Income Statement',
  sections: [
    {
      name: 's_revenue',
      label: 'Revenue',
      type: ROW_TYPES.section,
      rows: [
        {
          name: 'revenue',
          label: 'Revenue',
          bold: true
        },
        {
          name: 'yoygrowthrevenue',
          label: 'YoY Growth %',
          percentage: true,
          indent: 1,
          change: true,
          valueFormatter: formatPercentage
        },
        {
          name: 'costofrevenue',
          label: 'Cost of Revenue',
        },

        spacer(),

        {
          name: 'grossprofit',
          label: 'Gross Profit',
          type: ROW_TYPES.sum,
          bold: true,
          change: true
        },
        {
          name: 'grossprofitratio',
          label: 'Gross Margin %',
          percentage: true,
          indent: 1,
          change: true,
          valueFormatter: formatPercentage
        },

        spacer(),
      ],
    },
    {
      name: 's_operating',
      label: 'Operating Income & Expenses',
      type: ROW_TYPES.section,
      rows: [

        subhead('Operating Expenses'),

        {
          name: 'researchanddevelopmentexpenses',
          label: 'R&D Expenses',
        },
        {
          name: 'sellinggeneralandadministrativeexpenses',
          label: 'Selling, General & Admin Expenses',
        },
        {
          name: 'is_depreciationandamortization',
          label: 'Depreciation & Amortization',
        },
        {
          name: 'otherexpenses',
          label: 'Other Expenses',
        },
        {
          name: 'operatingexpenses',
          label: 'Total Operating Expenses',
          type: ROW_TYPES.sum,
        },

        spacer(),

        subhead('Income & Interest/Tax Expenses'),

        {
          name: 'operatingincome',
          label: 'Operating Income (EBIT)',
          bold: true,
          type: ROW_TYPES.sum,
        },
        {
          name: 'operatingincomeratio',
          label: 'Operating Margin %',
          indent: 1,
          percentage: true,
          valueFormatter: formatPercentage
        },

        spacer(),

        {
          name: 'interestexpense',
          label: 'Interest Expense',
        },
        {
          name: 'interestincome',
          label: 'Interest Income'
        },
        {
          name: 'totalotherincomeexpensesnet',
          label: 'Other Income or Expenses'
        },

        spacer(),

        {
          name: 'incomebeforetax',
          label: 'Income Before Taxes (EBT)',
          type: ROW_TYPES.sum,
          bold: true
        },
        {
          name: 'incomebeforetaxratio',
          label: 'Income Before Tax Margin %',
          percentage: true,
          indent: 1,
          valueFormatter: formatPercentage
        },

        spacer(),

        {
          name: 'incometaxexpense',
          label: 'Income Tax Expense',
        },
        {
          name: 'is_netincome',
          label: 'Net Income',
          type: ROW_TYPES.sum,
          indent: -1,
          bold: true
        },
        {
          name: 'netincomeratio',
          label: 'Net Income Margin %',
          percentage: true,
          valueFormatter: formatPercentage
        },

        spacer()
      ]
    },

    {
      name: 's_supplimental',
      label: 'Supplementary Data',
      type: ROW_TYPES.section,
      rows: [
        {
          name: 'eps',
          label: 'EPS'
        },
        {
          name: 'epsdiluted',
          label: 'Diluted EPS'
        },
        {
          name: 'weightedaverageshsout',
          label: 'Weighted Average Shares Out.'
        },
        {
          name: 'weightedaverageshsoutdil',
          label: 'Weighted Average Shares Out. Diluted'
        },
        {
          name: 'ebitda',
          label: 'EBITDA',
        },
        {
          name: 'ebitdaratio',
          label: 'EBITDA Margin %',
          percentage: true,
          indent: 1,
          valueFormatter: formatPercentage
        }
      ]
    }
  ]
}


const cashFlow = {
  name: SHEET_KEYS.CF,
  label: 'Cash Flow',
  sections: [
    {
      name: 's_operating_activities',
      label: 'Operating Activities',
      type: ROW_TYPES.section,
      rows: [
        {
          name: 'cf_netincome',
          label: 'Net Income',
          change: true
        },
        {
          name: 'cf_depreciationandamortization',
          label: 'Depreciation & Amortization',
        },
        {
          name: 'deferredincometax',
          label: 'Deferred Income Tax'
        },
        {
          name: 'stockbasedcompensation',
          label: 'Stock Based Compensation'
        },

        spacer(),

        subhead('Changes in Working Capital'),

        {
          name: 'accountsreceivables',
          label: 'Accounts Receivables',
        },
        {
          name: 'cf_inventory',
          label: 'Inventory',
        },
        {
          name: 'accountspayables',
          rowId: 'accountspayables_1',
          label: 'Accounts Payables',
        },
        {
          name: 'otherworkingcapital',
          label: 'Other Working Capital'
        },
        {
          name: 'changeinworkingcapital',
          label: 'Total Change in Working Capital',
          type: ROW_TYPES.sum,
        },

        spacer(),

        {
          name: 'othernoncashitems',
          label: 'Other Non-Cash Items'
        },

        {
          name: 'netcashprovidedbyoperatingactivities',
          label: 'Net Cash Provided by Operating Activities',
          type: ROW_TYPES.sum,
          bold: true,
          indent: -1,
          change: true
        },

        spacer()
      ]
    },

    {
      name: 's_investing_activities',
      label: 'Investing Activities',
      type: ROW_TYPES.section,
      rows: [
        {
          name: 'capitalexpenditure',
          rowId: 'capitalexpenditure_0',
          label: 'Capital Expenditures',
        },
        {
          name: 'acquisitionsnet',
          label: 'Acquisitions',
        },
        {
          name: 'purchasesofinvestments',
          label: 'Purchases of Investments',
        },
        {
          name: 'salesmaturitiesofinvestments',
          label: 'Sales / Maturities of Investments'
        },
        {
          name: 'otherinvestingactivities',
          label: 'Other Investing Activities'
        },
        {
          name: 'netcashusedforinvestingactivities',
          label: 'Net Cash from Investing Activities',
          type: ROW_TYPES.sum,
          bold: true,
          indent: -1,
          change: true
        },

        spacer()
      ]
    },

    {
      name: 's_financing_activities',
      label: 'Financing Activities',
      type: ROW_TYPES.section,
      rows: [
        {
          name: 'debtrepayment',
          label: 'Debt Repaid',
        },
        {
          name: 'commonstockissued',
          label: 'Issuance of Common Stock',
        },
        {
          name: 'commonstockrepurchased',
          label: 'Repurchases of Common Stock',
        },
        {
          name: 'dividendspaid',
          label: 'Dividends Paid'
        },
        {
          name: 'otherfinancingactivities',
          label: 'Other Financing Activities'
        },
        {
          name: 'netcashusedprovidedbyfinancingactivities',
          label: 'Net Cash from Financing Activities',
          type: ROW_TYPES.sum,
          bold: true,
          indent: -1
        },

        spacer()
      ]
    },

    {
      name: 's_net_changes_in_cash',
      label: 'Net Changes in Cash',
      type: ROW_TYPES.section,
      rows: [
        {
          name: 'effectofforexchangesoncash',
          label: 'Effect of Forex Changes on Cash',
        },
        {
          name: 'netchangeincash',
          label: 'Net Change in Cash',
          bold: true,
          change: true
        },

        spacer()
      ]
    },

    {
      name: 's_supplimental_cash',
      label: 'Supplementary Data',
      type: ROW_TYPES.section,
      rows: [
        {
          name: 'operatingcashflow',
          label: 'Operating Cash Flow'
        },
        {
          name: 'capitalexpenditure',
          rowId: 'capitalexpenditure_1',
          label: 'Capital Expenditure',
        },
        {
          name: 'freecashflow',
          label: 'Free Cash Flow'
        },

        spacer(),

        {
          name: 'cashatbeginningofperiod',
          label: 'Cash at Beginning of Period'
        },
        {
          name: 'cashatendofperiod',
          label: 'Cash at End of Period'
        }
      ]
    }
  ]
}


export const SHEETS = {
  [SHEET_KEYS.BS]: balanceSheet,
  [SHEET_KEYS.IS]: incomeStatement,
  [SHEET_KEYS.CF]: cashFlow
}
