import React, { useCallback } from 'react';
import clsx from 'clsx';
import { INPUTS } from "src/app/slicedForm/FilterForm/definitions/inputEnums";
import { FILTER_FORM_TYPES, STRUCTURAL_TYPES } from 'src/app/slicedForm/mapping/mappingDirections/index';
import { useColumnDef } from "src/app/slicedForm/shared/context/ColumnDefsProvider";
import { useFilterEntityContext, useFormGroupContext } from '../context/FilterEntityContext';
import { useWatch } from 'src/app/slicedForm/FilterForm/context/useFormEntity'
import useEntitySlices from 'src/app/slicedForm/FilterForm/hooks/useEntitySlices';
import FilterEntityHeader from "src/app/slicedForm/FilterForm/elements/FilterEntityHeader";
import FilterEntityActions from "src/app/slicedForm/FilterForm/elements/FilterEntityActions";
import Compare from 'src/app/slicedForm/FilterForm/entityTypes/Compare';
import DateCompare from 'src/app/slicedForm/FilterForm/entityTypes/DateCompare';
import MultiSelect from 'src/app/slicedForm/FilterForm/entityTypes/MultiSelect';
import TimeCompare from 'src/app/slicedForm/FilterForm/entityTypes/TimeCompare';
import {
  Box,
  Typography,
  makeStyles,
} from '@material-ui/core';
import { useFormSelector } from '../../shared/context/FormProvider';
import { selectFormType } from '../reducers/filterReducer';
import useInputStyles from '../../shared/styles/useInputStyles';


const useStyles = makeStyles(theme => ({
  root: {},
  left: {
    flexBasis: '36%',
    width: '36%',
    flexShrink: 0
  },
  center: {
    flex: 1,
  },
  right: {
    flex: 1,
    flexGrow: 0,
  }
}))


function Placeholder({ def }) {
  return (
    <Box pl={2}>
      <Typography><strong>{def.name}:</strong> {def.input}</Typography>
    </Box>
  )
}


/**
 * A wrapper for an individual filter row in the form.
 * Decides which inputs (middle) to render
 * All entities call the same Header (left side) and Actions (right side)
 */
function FilterEntity({ className }) {
  const classes = useStyles();
  const inputClasses = useInputStyles();
  const { id, type } = useFilterEntityContext();
  const { groupId, groupType } = useFormGroupContext();
  const column = useWatch(id, 'left.column')
  const sliceMeta = useEntitySlices(id, groupId, groupType);
  const colDef = useColumnDef(column);
  const formType = useFormSelector(selectFormType);


  if (type !== STRUCTURAL_TYPES.FILTER) {
    throw new Error(`Invalid ENTITY_TYPE ${type}. FilterEntity only supports FILTER at this time`)
  }

  // if is a slice_group child, and not the first element.
  const isSliceChild = sliceMeta && sliceMeta.index !== 0;

  const chooseInput = useCallback(() => {
    if (!colDef) return <div className={classes.center} />;

    switch (colDef.input) {
      case INPUTS.COMPARE:
        return (
          <Compare
            className={classes.center}
            hideComparison={isSliceChild}
            hideValueType={isSliceChild}
          />
        )
      case INPUTS.DATE:
        return <DateCompare className={classes.center} />
      case INPUTS.TICKER:
        return <Placeholder def={colDef} className={classes.center} />
      case INPUTS.TIME:
        return (
          <TimeCompare
            className={classes.center}
            hideComparison={isSliceChild}
            hideValueType={isSliceChild}
          />
        )
      case INPUTS.DATE_RANGE:
        return <Placeholder def={colDef} className={classes.center} />
      case INPUTS.SELECT:
        return <Placeholder def={colDef} className={classes.center} />
      case INPUTS.MULTI_SELECT:
        return (
          <MultiSelect className={classes.center} hideComparison={isSliceChild} />
        )
      default:
        throw new Error(`Invalid INPUT ${colDef.input}`)
    }
  }, [colDef, isSliceChild]);


  return (
    <Box
      className={clsx(
        className,
        classes.root,
      )}
      display="flex"
      alignItems="flex-start"
    >
      <FilterEntityHeader
        className={clsx(
          classes.left,
          formType === FILTER_FORM_TYPES.AGGREGATE && inputClasses.filterEntityWithLeftPlaceholder
        )}
      />
      {chooseInput()}
      <FilterEntityActions className={classes.right} />
    </Box>
  )
}

export default FilterEntity;
