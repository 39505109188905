import { useMemo, useCallback } from 'react';
import PropTypes from 'prop-types';
import { makeFuseSearch } from 'src/utils/fuse';
import { STICKY_OPTIONS } from 'src/app/slicedForm/FilterForm/definitions/inputEnums';

/**
 * A Metric (colum/filter def) definition
 * @typedef {Object} Metric
 * @property {string} name - The unique ID/name
 * @property {string} label - The human-readable name
 * @property {string} [secret] - A secret keyword for this column. Hide column otherwise.
 * @property {string} [group] - The group/category - optional
 */


const filterGroup = (selectedGroup, def) => {
  if (!selectedGroup || selectedGroup === 'ALL') return true;
  // TODO: EXPRESSIONS. Maybe add this to the redux data. They should look like colDefs, huh?
  if (selectedGroup === 'EXPRESSION' && 'expression' in def) return true;
  if (!def.group) return false;
  if (Array.isArray(def.group)) return def.group.includes(selectedGroup);
  return def.group === selectedGroup;
}


export const metricsInGroup = (selectedGroup, metrics) => {
  return (!selectedGroup || selectedGroup === 'ALL')
    ? metrics.filter(def => def && def?.sticky !== STICKY_OPTIONS.STICKY)
    : metrics.filter(def => def && def?.sticky !== STICKY_OPTIONS.STICKY && filterGroup(selectedGroup, def));
}

/**
 * @typedef Props
 * @property {Metric[]} [metrics=[]] - The list of metrics to filter
 * @property {string} [selectedGroup] - The currently selected group
 * @property {string} [query]
 */



/**
 * Filter down the visible metrics based on a Group and Keyword filter.
 * Also removes sticky.STICKY columns, those should be hidden from the center panel.
 * @param {Props} props
 * @returns {Metric[]}
 */
function useMetricFilter(props) {
  const {
    metrics,
    selectedGroup,
    query,
    fuseOptions
  } = props;

  const search = useMemo(() => {
    const inGroup = metricsInGroup(
      selectedGroup,
      metrics
    );
    return makeFuseSearch(inGroup, fuseOptions);
  }, [metrics, selectedGroup])

  return useMemo(() => {
    if (query && query.trim().toLowerCase().startsWith('secret:')) {
      const match = query.trim().toLowerCase().split(':')?.[1] || '';
      return metrics.filter(metric => metric.secret === match);
    } else {
      return search(query).filter(metric => !metric?.secret);
    }
  }, [search, query]);
}


useMetricFilter.propTypes = {
  metrics: PropTypes.array.isRequired,
  selectedGroup: PropTypes.string,
  query: PropTypes.string,
}

useMetricFilter.defaultProps = {
  metrics: []
}


export default useMetricFilter;
