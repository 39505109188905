import React, { useMemo, useRef, useCallback } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import PickerRangeLabel from 'src/app/components/pickers/definitions/PickerRangeLabel';
import {
  DateRangePicker as OrigDateRangePicker
} from 'react-date-range';
import {
  isAfter,
  isWeekend,
} from 'date-fns';
import {
  locale,
  isDateHoliday,
  getCurrentTradingDay,
} from 'src/utils/datetime/date-fns.tz';
import {
  makeStyles,
  useTheme
} from '@material-ui/core';


const useStyles = makeStyles(() => ({
  root: {},
  dateRangePickerWrapper: {

  }
}));

const DEFAULT_RANGE_KEY = 'default';

/**
 * Thin wrapper for react-date-range DateRangePicker picker component.
 * Contains Calendar and StaicRange
 *
 * Note, multiple ranges per calendar are not supported! I've abstracted away that functionality, we
 * don't need it
 *
 * @component
 */
function DateRangePicker({
  className,
  range,
  format,
  preventSnapRefocus,
  minDate,
  maxDate,
  marketTime,
  disableHoliday,
  disableWeekend,
  disableFuture,
  disabledDay,
  staticRangeHeaderContent,
  makeStaticRanges,
  makeInputRanges,
  onAccept,
  ...props
}) {
  const classes = useStyles();
  const theme = useTheme();

  const staticRanges = useMemo(() => makeStaticRanges
    ? makeStaticRanges()
    : [],
    []);

  const inputRanges = useMemo(() => makeInputRanges
    ? makeInputRanges()
    : [],
    []);

  const staticRangeHeader = useMemo(() => {
    return React.isValidElement(staticRangeHeaderContent)
      ? <staticRangeHeaderContent />
      : <PickerRangeLabel text={staticRangeHeaderContent} />
  }, [staticRangeHeaderContent])


  const ranges = useMemo(() => [{ ...range, key: DEFAULT_RANGE_KEY }], [range]);

  if (maxDate && disableFuture) throw Error('Cannot have both maxDate and disableFuture');

  const today = marketTime ? getCurrentTradingDay() : new Date();

  const handleDisableDay = (d) => {
    // TODO: market time?
    if (disableFuture && isAfter(d, today)) return true;
    if (disableHoliday && isDateHoliday(d)) return true;
    if (disableWeekend && isWeekend(d)) return true;
    if (disabledDay && disabledDay(d)) return true;
  };

  return (
    <>
      <OrigDateRangePicker
        {...props}
        className={clsx(
          className,
          { 'prevent-snap-refocus': preventSnapRefocus }
        )}
        onChange={(newRanges) => {
          onAccept(newRanges[DEFAULT_RANGE_KEY])
        }}
        minDate={minDate}
        maxDate={disableFuture ? today : maxDate}
        ranges={ranges}
        staticRanges={staticRanges}
        inputRanges={inputRanges}
        months={2}
        locale={locale}
        disabledDay={handleDisableDay}
        preventSnapRefocus={preventSnapRefocus}
        dragSelectionEnabled={false}
        showPreview={true}
        showSelectionPreview={true}
        showDateDisplay={true}
        dateDisplayFormat={format}
        headerContent={staticRangeHeader}
        direction="horizontal"
        color={theme.palette.primary.darker}
        rangeColors={[theme.palette.primary.darker]}
      />
    </>
  );
}


export const RangeShape = PropTypes.shape({
  startDate: PropTypes.instanceOf(Date),
  endDate: PropTypes.instanceOf(Date),
});


DateRangePicker.propTypes = {
  className: PropTypes.string,
  /** Controlled. The start/end dates */
  range: RangeShape.isRequired,
  /** yyyy-MM-dd */
  format: PropTypes.string,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  preventSnapRefocus: PropTypes.bool,
  disableHoliday: PropTypes.bool,
  disableWeekend: PropTypes.bool,
  disableFuture: PropTypes.bool,
  /** see https://github.com/hypeserver/react-date-range/blob/master/src/defaultRanges.js */
  makeStaticRanges: PropTypes.func,
  /** see https://github.com/hypeserver/react-date-range/blob/master/src/defaultRanges.js */
  makeInputRanges: PropTypes.func,
  disabledDay: PropTypes.func,
  onAccept: PropTypes.func,
};


DateRangePicker.defaultProps = {
  preventSnapRefocus: false,
  disableHoliday: false,
  disableWeekend: false,
  disableFuture: false,
  definedDays: []
};


export default React.memo(DateRangePicker);
