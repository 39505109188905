import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { PREDEF_PREFIX, cloneProfile } from 'src/redux/profileSettings/profileSettingsConfig';
import clsx from 'clsx';
import { v4 as uuidv4 } from 'uuid';
import _uniqBy from 'lodash/uniqBy';
import _find from 'lodash/find';
import useStateFromProp from 'src/hooks/useStateFromProp';
import { basicFuzzySort } from 'src/utils/fuzzySort';
import { generateProfileFilenameVersion } from 'src/utils/generateProfileFilenameVersion';
import SavedProfiles from 'src/app/components/filterInnerPanels/SavedProfiles';
import SectionSelector from './SectionSelector';
import AllMetrics from 'src/app/components/filterInnerPanels/AllMetrics';
import SelectedMetrics from './SelectedMetrics';
import PanelActionButton from 'src/app/components/panels/PanelActionButton';
import FilterWindowNameTextField from 'src/app/components/filterElements/FilterWindowNameTextField';
import PanelIconButton from 'src/app/components/panels/PanelIconButton';
import { EditIcon, FilterIcon } from 'src/theme/EdgeIcons';
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  IconButton,
  Typography,
  makeStyles
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';



const useStyles = makeStyles((theme) => ({
  root: {},
  modalRoot: {
    flex: 1,
    display: 'flex',
    width: '100%',
    height: '100%',
    borderTop: `1px solid ${theme.palette.background.panelBorder}`,
    '& .ps .ps__rail-y:hover, .ps .ps--clicking': {
      background: 'transparent !important',
      opacity: '0.6 !important',
    },
    '& .ps__thumb-y': {
      width: '6px!important',
      backgroundColor: '#aaa!important',
    },
    '& .MuiListItem-root': {
      borderRadius: 5,
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: 14,
      paddingRight: 44,
      '& .MuiTypography-root': {
        fontSize: 13,
      },
      '& .MuiListItemText-root': {
        overflow: 'hidden'
      },
      '& .MuiCheckbox-root': {
        paddingTop: 1,
        paddingBottom: 1,
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }
    }
  },
  closeButton: {
    padding: 0,
    borderRadius: 4,
    '& .MuiSvgIcon-root': {
      fontSize: 28
    }
  },
  dialogRoot: {
    '& .MuiDialog-paperScrollPaper': {
      width: props => props.width,
      height: props => props.height,
      maxWidth: 'calc(100% - 64px)',
      maxHeight: 'calc(100% - 64px)',
      overflow: 'hidden'
    }
  },
  dialogActionsTop: {
    backgroundColor: theme.palette.background.panelHeader,
    alignItems: 'center',
    justifyContent: 'flex-start',
  },
  filterTextBox: {
    flex: 1,
    '& .MuiInputBase-root': {
      maxWidth: 300
    }
  },
  dialogContentRoot: {
    padding: '0 !important',
    overflow: 'hidden',
    backgroundColor: theme.palette.background.panelHeader,
    '& .ks-column-title': {
      color: theme.palette.text.secondary,
      paddingLeft: 6,
      fontSize: 16,
      fontWeight: 600
    },
    '& .left-column-title': {
      color: theme.palette.text.secondary,
      paddingLeft: 14,
      fontWeight: 600,
      fontSize: 16
    }
  },
  modalTitle: {
    fontSize: 20,
    maxWidth: 225,
    flex: 1
  },
  side: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column'
  },
  leftSide: {
    maxWidth: 240,
    paddingTop: 10,
    '& .top-news-filter-list-item': {
      paddingLeft: 27
    }
  },
  middleSide: {
    borderRight: `1px solid ${theme.palette.background.panelBorder}`,
    borderLeft: `1px solid ${theme.palette.background.panelBorder}`
  },
  rightSide: {
    backgroundColor: theme.palette.background.paper,
    maxWidth: 226,
    '& .top-news-filter-list-item:hover': {
      backgroundColor: 'transparent'
    }
  },
  savedProfiles: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    minHeight: 0,
  },
  metricGroups: {
    flex: '0 1 0%',
    display: 'flex',
    flexDirection: 'column',
    paddingTop: 7,
    color: theme.palette.text.secondary,
    borderTop: `1px solid ${theme.palette.background.panelBorder}`,
  },
  rightSideTitle: {
    padding: '7px 10px',
    backgroundColor: theme.palette.background.fixedTableHeader,
    fontWeight: 'bold'
  },
  list: {},
  selectAllList: {
    padding: 0,
    '& .MuiListItem-root': {
      '&:hover': {
        backgroundColor: 'transparent'
      },
      '& .MuiListItemText-root': {
        margin: 0,
        fontSize: 14,
        '& .MuiTypography-root': {
          fontSize: 13
        }
      }
    }
  },
  secondaryAction: {
    right: 13,
    fontSize: 0,
    pointerEvents: 'none',
    zIndex: 0
  },
  bottomActionButtons: {
    backgroundColor: theme.palette.background.panelHeader,
    borderTop: `1px solid ${theme.palette.background.panelBorder}`,
    display: 'flex',
    alignItems: 'center',
    padding: '0px 5px',
    justifyContent: 'space-between',
    '& .MuiButtonBase-root': {
      margin: 7
    }
  },
}));


const getProfileIndex = (profileSettings) => {
  return profileSettings.profiles.findIndex(prof => prof.id === profileSettings.activeProfile);
};

/* FIND:PROFILE */
// TODO: Profile. Use combination of react useReducers and context to handle this better
//     - Also, figure out a way to compose with slots. This would be magical.

function KeyStatsProfile({
  className,
  initialColumnProfile,
  COLUMN_DEFINITIONS,
  displayAllMetricsGroup,
  profileSettings,
  onSubmit,
  searchFunction,
  Icon,
  shouldHideIconText,
  extraCheckboxes,
  iconText,
  width,
  height,
  modalTitle,
  maxPerTable,
  maxPerColumn
}) {
  const classes = useStyles({ width, height });
  const [query, setQuery] = useState('');
  const [modalOpen, setModalOpen] = useState(false);

  const [profileSettingsObject, setProfileSettingsObject] = useStateFromProp(profileSettings);
  const profileSettingsMutable = JSON.parse(JSON.stringify(profileSettingsObject));
  const profIdx = profileSettingsObject.profiles.findIndex(prof => prof.id === profileSettingsObject.activeProfile);
  var activeProfile = profileSettingsMutable.profiles[profIdx]; // MUTABLE!!! Used as shorthand reference to profileSettings current
  const [newProfileName, setNewProfileName] = useStateFromProp(activeProfile.name);

  const [activeSection, setActiveSection] = useState(Object.keys(COLUMN_DEFINITIONS)[0]);
  const activeSectionIndex = activeProfile.sections.findIndex(({ sectionName }) => sectionName === activeSection);
  const maxPerColumnFinal = maxPerColumn || maxPerTable;

  const isCurrentlyViewingPredefinedProfile = profileSettingsMutable.activeProfile.includes(PREDEF_PREFIX) || activeProfile?.predefined;

  /*
  This component is different than the others, as it relies on mutation. Strategy:
  - Make a copy of the whole profile settings config object.
  - Reference it's active profile of as shorthand to use/mutate elsewhere. Changing the value of those references updates the original mutable settings object.
  - When done changing the references, set state using entire mutable object [setProfileSettingsObject(profileSettingsMutable)]

  To avoid mutation you could keep track of each individual part of the profile settings object as seperate pieces of state, but I think that would be more complex.
   */

  const onProfileSelect = (selectedProfileId) => {
    profileSettingsMutable.activeProfile = selectedProfileId;
    setNewProfileName(_find(profileSettingsMutable.profiles, { id: selectedProfileId }).name);
    setProfileSettingsObject(profileSettingsMutable);
  };

  const onProfileRemove = (selectedProfileId) => {
    if (selectedProfileId.includes(PREDEF_PREFIX)) {
      return false;
    }
    profileSettingsMutable.profiles = profileSettingsMutable.profiles.filter(({ id }) => id !== selectedProfileId);
    setProfileSettingsObject(profileSettingsMutable);
  };

  const handleProfileNameChange = (event) => {
    if (isCurrentlyViewingPredefinedProfile) {
      return false;
    }
    setNewProfileName(event.target.value);
  };

  const onSelectSession = (newSectionName) => {
    setActiveSection(newSectionName);
  };

  const handleExtraCheckboxToggle = (name, value) => {
    const pIndex = getProfileIndex(profileSettingsMutable);
    const state = profileSettingsMutable.profiles[pIndex].extra || {};
    state[name] = value;
    profileSettingsMutable.profiles[pIndex].extra = state;
    setProfileSettingsObject(profileSettingsMutable);
  }

  const handleRemoveColumn = (nameToRemove) => {
    const pIndex = getProfileIndex(profileSettingsMutable);
    const tables = profileSettingsMutable.profiles[pIndex].sections[activeSectionIndex].tables;
    for (const idx in tables) {
      profileSettingsMutable.profiles[pIndex].sections[activeSectionIndex].tables[idx] = tables[idx].filter(name => name !== nameToRemove);
    }
    setProfileSettingsObject(profileSettingsMutable);
  };


  const handleAddColumn = (name, selected) => {
    if (selected) {
      handleRemoveColumn(name);
    } else {
      const pIndex = getProfileIndex(profileSettingsMutable);
      const tables = profileSettingsMutable.profiles[pIndex].sections[activeSectionIndex].tables;
      for (const idx in tables) {
        if (tables[idx].length < maxPerColumnFinal[activeSectionIndex]) {
          profileSettingsMutable.profiles[pIndex].sections[activeSectionIndex].tables[idx].push(name);
          break;
        }
      }
      setProfileSettingsObject(profileSettingsMutable);
    }
  };


  const handleSelectedColumnsReorder = (orderedItems) => {
    const pIndex = getProfileIndex(profileSettingsMutable);
    profileSettingsMutable.profiles[pIndex].sections[activeSectionIndex].tables = orderedItems;
    setProfileSettingsObject(profileSettingsMutable);
  };


  const handleSearchChange = (event) => {
    setQuery(event.target.value);
  };

  const handleAlarmChange = (name, type, value) => {
    const pIndex = getProfileIndex(profileSettingsMutable);
    profileSettingsMutable.profiles[pIndex].alarms[name] = profileSettingsMutable.profiles[pIndex].alarms[name] || {};
    profileSettingsMutable.profiles[pIndex].alarms[name][type] = value;
    setProfileSettingsObject(profileSettingsMutable);
  };


  const handleSwapAlarms = (name) => {
    const pIndex = getProfileIndex(profileSettingsMutable);
    profileSettingsMutable.profiles[pIndex].alarms[name] = profileSettingsMutable.profiles[pIndex].alarms[name] || {};
    if (typeof name.reversed === undefined) {
      profileSettingsMutable.profiles[pIndex].alarms[name].reversed = true;
    } else {
      profileSettingsMutable.profiles[pIndex].alarms[name].reversed = !profileSettingsMutable.profiles[pIndex].alarms[name].reversed;
      setProfileSettingsObject(profileSettingsMutable);
    }
  };


  const handleCancel = () => {
    setProfileSettingsObject(profileSettings);
    setModalOpen(false);
  };


  const handleSave = () => {
    activeProfile.name = generateProfileFilenameVersion(
      newProfileName,
      profileSettingsMutable.activeProfile,
      profileSettingsMutable.profiles,
      'name'
    );
    const errorCheckedProfileSettings = preSubmitCleanUpDuplicates(profileSettingsMutable);
    setProfileSettingsObject(errorCheckedProfileSettings);
    onSubmit(errorCheckedProfileSettings);
  };


  // In case duplicates arise, or activeProfile doesn't point to an existing profile, handle the errors.
  // Should never happen, but this is for redundancy as it would be a breaking bug.
  const preSubmitCleanUpDuplicates = (settings) => {
    settings.profiles = _uniqBy(settings.profiles, 'id');
    const activeProfileExists = settings.profiles.some(({ id }) => id === settings.activeProfile);
    if (!activeProfileExists) {
      settings.activeProfile = settings.profiles[0].id;
    }
    return settings;
  };


  const createNewProfile = (fileName, profileBase) => {
    let id = uuidv4();
    let newProfName = generateProfileFilenameVersion(
      fileName,
      id,
      profileSettingsMutable.profiles,
      'name'
    );
    profileSettingsMutable.profiles.unshift({
      ...cloneProfile(profileBase),
      name: newProfName,
      id: id
    });

    profileSettingsMutable.activeProfile = id;
    setNewProfileName(newProfName);

    setProfileSettingsObject(profileSettingsMutable);
    return profileSettingsMutable;
  };


  const handleNew = () => {
    return createNewProfile('New Profile', initialColumnProfile.profiles[0]);
  };


  const handleCopy = (profileName, id) => {
    const profIdx = profileSettingsMutable.profiles.findIndex(prof => prof.id === id);
    if (profIdx >= 0) {
      return createNewProfile(profileName, profileSettingsMutable.profiles[profIdx]);
    }
  };

  const handleApply = () => {
    handleSave();
    setModalOpen(false);
  };

  const handleClickOpen = () => { setModalOpen(true); };

  const selctedMetrics = activeProfile.sections[activeSectionIndex].tables;

  const flattenedSelectedMetrics = selctedMetrics.flat();

  const filteredFlattenedAllMetrics = !query
    ? COLUMN_DEFINITIONS[activeSection]
    : searchFunction(query, COLUMN_DEFINITIONS[activeSection]);

  return (
    <>
      <Box className={clsx(classes.root, className)}>
        <PanelIconButton
          Icon={Icon}
          onClick={handleClickOpen}
          text={iconText}
          shouldHideIconText={shouldHideIconText}
        />
      </Box>

      <Dialog maxWidth="md" fullWidth open={modalOpen} onClose={handleCancel} className={classes.dialogRoot}>
        <DialogActions className={classes.dialogActionsTop}>
          {modalTitle && <Typography variant="h3" className={classes.modalTitle}>{modalTitle}</Typography>}
          <div className={classes.filterTextBox}>
            <FilterWindowNameTextField
              Icon={EditIcon}
              value={newProfileName}
              placeholder={'Untitled'}
              onChange={handleProfileNameChange}
              disabled={isCurrentlyViewingPredefinedProfile}
            />
          </div>
          <IconButton onClick={handleCancel} color="primary" disableRipple className={classes.closeButton}>
            <ClearIcon color="primary" />
          </IconButton>
        </DialogActions>
        <DialogContent className={classes.dialogContentRoot}>

          <Box className={classes.modalRoot}>

            <Box className={clsx(classes.leftSide, classes.side)}>
              <div className={classes.savedProfiles}>
                <Typography className="left-column-title">Saved Profiles</Typography>
                <SavedProfiles
                  columnProfileSettings={profileSettingsMutable}
                  onProfileSelect={onProfileSelect}
                  onProfileRemove={onProfileRemove}
                  onCopy={handleCopy}
                />
              </div>
              <div className={classes.metricGroups}>
                <Typography className="left-column-title">Table Sections</Typography>
                {Object.keys(COLUMN_DEFINITIONS).length > 1 && (
                  <SectionSelector
                    sections={Object.keys(COLUMN_DEFINITIONS)}
                    activeSection={activeSection}
                    onSectionSelect={onSelectSession}
                  />
                )}
              </div>
            </Box>

            <Box className={clsx(classes.middleSide, classes.side)}>
              <AllMetrics
                selectedItems={flattenedSelectedMetrics}
                allItems={filteredFlattenedAllMetrics}
                onAddItem={handleAddColumn}
                alarms={activeProfile.alarms}
                extraCheckboxes={extraCheckboxes}
                extraCheckboxValues={activeProfile?.extra || {}}
                onExtraCheckboxToggle={handleExtraCheckboxToggle}
                searchInputPlaceholder="Search metrics"
                searchQuery={query}
                onSearchChange={handleSearchChange}
                onAlarmChange={handleAlarmChange}
                onSwapAlarms={handleSwapAlarms}
                maximumMetricsReached={maxPerTable[activeSectionIndex] === flattenedSelectedMetrics.length}
                editLocked={isCurrentlyViewingPredefinedProfile}
                displayAllMetricsGroup={displayAllMetricsGroup}
              />
            </Box>

            <Box className={clsx(classes.rightSide, classes.side)}>
              <SelectedMetrics
                title={`Selected ${activeSection} Metrics`}
                selectedTables={selctedMetrics}
                allItems={COLUMN_DEFINITIONS[activeSection]}
                onRemoveItem={handleRemoveColumn}
                onReorder={handleSelectedColumnsReorder}
                maxPerTable={maxPerColumnFinal[activeSectionIndex]}
                editLocked={isCurrentlyViewingPredefinedProfile}
              />
            </Box>

          </Box>
        </DialogContent>

        <Box className={classes.bottomActionButtons}>
          <div>
            <PanelActionButton color="white" onClick={handleNew}>Create New</PanelActionButton>
            <PanelActionButton color="white" onClick={() => handleCopy(newProfileName, activeProfile.id)}>Copy</PanelActionButton>
            <PanelActionButton color="white" onClick={handleSave}>Save</PanelActionButton>
          </div>
          <div>
            <PanelActionButton color="red" onClick={handleCancel}>Cancel</PanelActionButton>
            <PanelActionButton color="negative" onClick={handleApply}>Apply</PanelActionButton>
          </div>
        </Box>

      </Dialog>
    </>
  );
}


KeyStatsProfile.propTypes = {
  className: PropTypes.string,
  initialColumnProfile: PropTypes.object,
  COLUMN_DEFINITIONS: PropTypes.object,
  profileSettings: PropTypes.object,
  onSubmit: PropTypes.func,
  searchFunction: PropTypes.func,
  Icon: PropTypes.func,
  iconText: PropTypes.string,
  modalTitle: PropTypes.string,
  width: PropTypes.number,
  height: PropTypes.number,
  extraCheckboxes: PropTypes.array,
  maxPerTable: PropTypes.array,
  maxPerColumn: PropTypes.number,
  shouldHideIconText: PropTypes.bool,
  displayAllMetricsGroup: PropTypes.bool,
}


KeyStatsProfile.defaultProps = {
  searchFunction: basicFuzzySort,
  Icon: FilterIcon,
  iconText: 'Filter',
  width: 900,
  height: 590,
  modalTitle: 'Customize Stats',
  maxPerTable: [7, 2],
  extraCheckboxes: undefined
}

export default KeyStatsProfile;
