import edgeDataApi from 'src/apis/edgeDataApi';
import { HISTORY_COLUMNS} from 'src/app/HistoryPage/columns/historyColumns';
import { cleanEmptyFilters, COMPS } from 'src/app/HistoryPage/labels.js';
import { ROLLING_DATE_OPTIONS } from 'src/app/HistoryPage/columns/historyColumnFunctions';

export const FETCH_HISTORY_FULL = '@history/fetch-history-full';
export const FETCH_PAGINATE_HISTORY_SERVERSIDE = '@history/fetch-paginate-history-serverside';
export const PAGINATE_HISTORY_FULL = '@history/paginate-history-full';
export const FETCH_HISTORY_TIMESERIES = '@history/fetch-history-timeseries';
export const FETCH_HISTORY_MARKET_STATS = '@history/fetch-history-market-stats';
export const FETCH_HISTORY_GAP_STATS = '@history/fetch-history-gap-stats';
export const SET_HISTORY_ORDER = '@history/set-history-order';
export const REQUEST__FETCH_HISTORY = '@history/request__fetch-history-full';
export const REQUEST__FETCH_PAGINATE_HISTORY_SERVERSIDE = '@history/request__fetch-paginate-history-serverside';
export const REQUEST__FETCH_HISTORY_MARKET_STATS = '@history/request__fetch-history-market-stats';
export const REQUEST__FETCH_HISTORY_GAP_STATS = '@history/request__fetch-history-gap-stats';
export const REQUEST__FETCH_HISTORY_TIMESERIES = '@history/request__fetch-history-timeseries';



export const buildHistoryQuery = (filters) => {
const cleanedFilters = cleanEmptyFilters(filters);
  const query = [];
  cleanedFilters.forEach(({ name, comp, compType, val, val2, selected }) => {
    const config = HISTORY_COLUMNS.find(col => col.name === name);
    if (!config) return;

    if (config.input === 'dateRange') {
      let from = val;
      let to = val2;
      if (selected && ROLLING_DATE_OPTIONS?.[selected]) {
        [from, to] = ROLLING_DATE_OPTIONS[selected].getDates();
      }
      query.push(`${name}~${COMPS.GE}~${compType}~${from}`);
      query.push(`${name}~${COMPS.LE}~${compType}~${to}`);
    } else if (comp === COMPS.BT && val && val2) {
      query.push(`${name}~${COMPS.GE}~${compType}~${val}`);
      query.push(`${name}~${COMPS.LT}~${compType}~${val2}`);
    } else if (selected) {
      query.push(`${name}~${COMPS.EQ}~${compType}~${selected}`);
    } else {
      query.push(`${name}~${comp}~${compType}~${val}`);
    }
  });

  return query;
};



export const fetchHistoryPageHistory = (filters, columns, order, orderby) => async dispatch => {
  const stateKey = 'main';

  dispatch({ type: REQUEST__FETCH_HISTORY, payload: stateKey });

  const query = buildHistoryQuery(filters);

  let out = {};
  try {
    const res = await edgeDataApi.post(`/history-stockdata-full`, {
      query,
      columns,
      order,
      orderby
    });
    out = res.data;
  } catch (err) {
    console.log('ERR: historyActions', err);
  }
  dispatch({ type: FETCH_HISTORY_FULL, payload: { data: out, stateKey } });
};



export const fetchPaginateHistoryServerside = (filters, columns, order, orderby, serverPage) => async dispatch => {
  const stateKey = 'main'

  dispatch({ type: REQUEST__FETCH_PAGINATE_HISTORY_SERVERSIDE, payload: stateKey });

  const query = buildHistoryQuery(filters);

  let out = {};
  try {
    const res = await edgeDataApi.post(`/history-stockdata-full`, {
      query,
      columns,
      order,
      orderby,
      page: serverPage
    });
    out = res.data;
  } catch (err) {
    console.log('ERR: historyActions', err);
  }
  dispatch({ type: FETCH_PAGINATE_HISTORY_SERVERSIDE, payload: { data: out, stateKey } });
};



export const fetchHistoryMarketStats = filters => async dispatch => {
  const stateKey = 'main';

  dispatch({ type: REQUEST__FETCH_HISTORY_MARKET_STATS, payload: stateKey });

  const query = buildHistoryQuery(filters);

  let out = {};
  try {
    const res = await edgeDataApi.post(`/history-stockdata-market-stats`, { query });
    out = res.data;
  } catch (err) {
    console.log('[fetchHistoryMarketStats]', err);
  }

  dispatch({ type: FETCH_HISTORY_MARKET_STATS, payload: { data: out, stateKey } });
}


export const fetchHistoryTimeseries = (aggregates, filters, period = 'W') => async dispatch => {
  dispatch({ type: REQUEST__FETCH_HISTORY_TIMESERIES });

  const query = buildHistoryQuery(filters);
  const aggregateQuery = aggregates.map(agg => ({ name: agg.name, func: agg.func }));

  let out = {};
  try {
    const res = await edgeDataApi.post(`/history-stockdata-timeseries`, {
      aggregates: aggregateQuery,
      query,
      period
    });
    if (res?.data) {
      out = res.data;
    }
  } catch (err) {
    console.log('[fetchHistoryTimeseries]', err);
  }
  dispatch({ type: FETCH_HISTORY_TIMESERIES, payload: out });
};


