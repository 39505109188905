import React, { useState, useCallback } from 'react';
import { batch } from 'react-redux';
import ActionList from 'src/app/components/ActionList';
import { DELETE_EXPRESSION } from 'src/app/slicedForm/shared/reducers/expressionReducer';
import ClearIcon from '@material-ui/icons/Clear';
import { CheckIcon, EditIcon } from 'src/theme/EdgeIcons';
import ExpressionDetailsForm from './ExpressionDetailsForm';
import ColumnIconSelector from '../../shared/elements/ColumnIconSelector';
import { useFormDispatch } from 'src/app/slicedForm/shared/context/FormProvider';
import {
  useTheme,
} from '@material-ui/core';


export function ExpressionCheckboxRow(props) {
  return <ExpressionRow {...props} checkbox />
}

export function ExpressionSimpleRow(props) {
  return <ExpressionRow {...props} />
}

function ExpressionRow({ index, style, data, checkbox }) {
  const theme = useTheme();
  const dispatch = useFormDispatch();
  const [expressionOpen, setExpressionOpen] = useState(false);

  const expressionDef = data.filteredColumns[index];
  const selected = data.selectedColumns.includes(expressionDef.name)

  const handleClose = useCallback(() => {
    setExpressionOpen(false);
  }, []);

  const handleOpen = useCallback((event) => {
    setExpressionOpen(true);
  }, []);

  const handleDelete = useCallback(() => {
    batch(() => {
      // columnReducer will handle DELETE_EXPRESSION, in adition to expressionReducer.
      dispatch({ type: DELETE_EXPRESSION, payload: expressionDef.name })
    });
  }, [expressionDef, selected, checkbox, data?.onSelect])


  return (
    <ActionList.Item
      pl={6}
      style={style}
      selected={selected}
      checkbox={checkbox}
      selectionVariant={checkbox ? 'multiple' : 'single'}
      role={checkbox ? 'menuitemcheckbox' : 'menuitem'}
      onSelect={() => { data.onSelect(expressionDef, selected) }}
      title={expressionDef.label}
      {...data.getOptionProps(index, { selected })}
    >
      <ColumnIconSelector name={expressionDef?.name} />
      {expressionDef.label}
      <ActionList.Trailing>
        {!data?.deleteExprConfirming(expressionDef.name) && (
          <>
            <ActionList.IconButton
              Icon={EditIcon}
              label={'Edit'}
              onClick={handleOpen}
            />
            <ExpressionDetailsForm
              open={expressionOpen}
              expression={expressionDef}
              handleClose={handleClose}
            />
          </>
        )}
        <ActionList.DoubleConfirmationIconButton
          confirming={data?.deleteExprConfirming(expressionDef.name)}
          BaseIcon={ClearIcon}
          baseLabel={'Delete'}
          baseColor={theme.palette.primary.main}
          ConfirmIcon={CheckIcon}
          confirmLabel={'Confirm delete?'}
          confirmColor={theme.palette.text.negative}
          setConfirming={data?.setDeleteExprConfirming(expressionDef.name)}
          onAccept={handleDelete}
        />
      </ActionList.Trailing>
    </ActionList.Item>
  )
}

