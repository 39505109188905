import PropTypes from 'prop-types';
import _isFunction from 'lodash/isFunction';


function SimpleMessageOverlay({ message }) {
  return _isFunction(message) ? message() : message
}


SimpleMessageOverlay.propTypes = {
  message: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
}


SimpleMessageOverlay.defaultProps = {
  message: 'No Rows To Show'
}


export default SimpleMessageOverlay;
