import {
  NOTIFICATION_KEY,
} from 'src/redux/notifications/notificationActions';


const initialState = {
  notifications: null
}

export default function notificationReducer(state = initialState, action) {
  if (action?.payload?.[NOTIFICATION_KEY]?.message) {
    return {
      notifications: action.payload[NOTIFICATION_KEY]
    }
  }
  return state;
}
