import React, { useState, useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import {
  deriveItemState,
  updateCheckboxStates,
  buildUiStateSchema,
  getCurrentUiPath,
  updateUiState
} from './manageState';
import clsx from 'clsx';
import CheckboxList from './CheckboxList';
import PanelActionButton from 'src/app/components/panels/PanelActionButton';
import useStateFromProp from 'src/hooks/useStateFromProp';
import {
  Box,
  makeStyles,
} from '@material-ui/core';


const useStyles = makeStyles((theme) => ({
  root: {
    flex: 1,
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
    borderTop: `1px solid ${theme.palette.background.panelBorder}`,
    '& .checkbox-list-item': {
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      paddingTop: 5,
      paddingBottom: 5,
      paddingRight: 44,
      '& .MuiTypography-root': {
        fontSize: 13,
      },
      '& .MuiListItemText-root': {
        overflow: 'hidden'
      },
      '& .MuiCheckbox-root': {
        paddingTop: 1,
        paddingBottom: 1,
        '&:hover': {
          backgroundColor: 'transparent'
        }
      }
    }
  },
  columns: {
    display: 'flex',
    height: '100%'
  },
  side: {
    flex: 1,
    borderRight: `1px solid ${theme.palette.background.panelBorder}`,
    minWidth: 200
  },
  scrollParent: {
    height: '100%',
    overflowY: 'auto'
  },
  selectable: {
    border: '2px solid white'
  },
  selected: {
    borderColor: theme.palette.primary.main
  },
  bottomActionButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0px 5px',
    borderTop: `1px solid ${theme.palette.background.panelBorder}`,
    '& .MuiButtonBase-root': {
      margin: 7
    }
  }
}));



/* FIND:PROFILE */
function NestedCheckboxFilterModal({
  className,
  items,
  disabledItemKeys,  // We could merge disabled prop into state, but it breaks the current state structure.
  initialState,
  closeModal,
  onSubmit,
  useInstanceStyles
}) {
  const baseClasses = useStyles();
  const classes = useInstanceStyles();

  const [checkboxStates, setCheckboxStates] = useStateFromProp(initialState);
  const [uiState, setUiState] = useState(buildUiStateSchema(items));


  const uiPath = useMemo(() => {
    return ['root', ...getCurrentUiPath(uiState)];
  }, [uiState]);

  // Precompute the parents checkbox state. It's a relatively slow operation.
  const derivedCheckboxStates = useMemo(() => {
    return items.filter(i => i.checkbox).reduce((obj, i) => {
      return { ...obj, [i.name]: deriveItemState(checkboxStates, items, i) }
    }, {})
  }, [checkboxStates]);


  // get precomputed cb state
  const getStateForId = useCallback(name => {
    return derivedCheckboxStates?.[name];
  }, [derivedCheckboxStates]);


  const handleClickCheckboxState = useCallback(name => {
    setCheckboxStates(updateCheckboxStates(checkboxStates, items, name));
  }, [checkboxStates]);


  const handleClickUiState = useCallback((uiPathIdx, newName) => {
    setUiState(updateUiState(uiState, uiPath, uiPathIdx, newName));
  }, [uiState, uiPath]);


  const handleCancel = useCallback(() => {
    closeModal();
  }, []);

  const handleApply = useCallback(() => {
    onSubmit(checkboxStates);
    closeModal();
  }, [checkboxStates]);


  return (
    <>
      <Box className={clsx(className, classes.root, baseClasses.root)}>
        <div className={baseClasses.columns}>
          {uiPath.map((columnKey, colIdx) => {
            let idsToRender;
            if (columnKey === 'root') {
              idsToRender = [];
            } else {
              idsToRender = items.filter(i => i?.parent === columnKey).map(i => i.name);
            }
            return (
              <div key={colIdx} className={clsx(baseClasses.side, classes.side, classes[`side-${colIdx}`])}>
                <div className={clsx(baseClasses.scrollParent, 'modal-horizontal-scrollbar-sm')}>
                  <CheckboxList
                    items={items}
                    idsToRender={idsToRender}
                    disabledItemKeys={disabledItemKeys}
                    onCheck={handleClickCheckboxState}
                    onSelect={(newName) => handleClickUiState(colIdx, newName)}
                    getStateForId={getStateForId}
                    selectedColumnItem={uiPath?.[colIdx + 1]}
                  />
                </div>
              </div>
            );
          })}
        </div>
      </Box>
      <Box className={baseClasses.bottomActionButtons}>
        <PanelActionButton color="red" onClick={handleCancel}>Cancel</PanelActionButton>
        <PanelActionButton color="negative" onClick={handleApply}>Apply</PanelActionButton>
      </Box>
    </>
  );
}


NestedCheckboxFilterModal.propTypes = {
  className: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  disabledItems: PropTypes.arrayOf(PropTypes.string),
  initialState: PropTypes.arrayOf(PropTypes.object),
  initialUiPath: PropTypes.arrayOf(PropTypes.string),
  closeModal: PropTypes.func,
  onSubmit: PropTypes.func,
  useInstanceStyles: PropTypes.func
};


NestedCheckboxFilterModal.defaultProps = {
  items: [],
  disabledItemKeys: []
}

export default NestedCheckboxFilterModal;
