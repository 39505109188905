import _cloneDeep from 'lodash/cloneDeep'
import { createSelector } from "reselect";
import { generateSimpleFilenameVerson } from 'src/utils/generateProfileFilenameVersion'
import shortUuid from 'short-uuid';
import { logSentryError } from './sentryReducerErrors';

export const SELECT_PROFILE = '@form-profile/SELECT_PROFILE';
export const ADD_PROFILE = '@form-profile/ADD_PROFILE';
export const COPY_PROFILE = '@form-profile/COPY_PROFILE';
export const RENAME_PROFILE = '@form-profile/RENAME_PROFILE';
export const DELETE_PROFILE = '@form-profile/DELETE_PROFILE';


/**
 * A Profile object
 * @typedef {Object} Profile
 * @property {string} id - The ID of the profile
 * @property {string} name - The name of the profile
 * @property {boolean} [predefined] - Optional, if the profile is predefined (uneditable)
 */

export function getActiveProfile(draft) {
  const profileId = draft.activeProfile;
  return draft.profiles.find(p => p.id === profileId);
}

/*
 * OVERALL STATE
 *
 * We're forgetting that the formReducer just controls an individual store. Actual profiles still must be stored in Redux
 * Predefined profiles have to come from redux just the same, they're valid states for the client

 *
 *
 * PREDEFINED PROFILES:
 *
 * Considerations:
 *    All solutions need to work with Redux...
 *      I don't think we're going to use this reduceReducers thing. Reducer state has to be a pure copy of what comes
 *      out of formReducer
 *
 *    How to ensure references to bad predefined profiles don't error? The profiles should be cleared before saving.
 *      - Has to happen on the redux level, not the useReducer level
 *
 * State Structure:
 *    Option 1)
 *      - profiles: [] and predefined_profiles: [] are separate keys
 *      - This makes the most sense for de/serialization, but Reducer logic is uglier (I think)
 *        a) Pass in 'predefined' on every action, change result based on that
 *        b) Lookup predefined status in reducer, and change result based on that
 *        c) Some top-level reducer that catches multiple actions, and releases or modifes as needed.
 *          - Maybe an action creator is more appropriate?
 *          - State can be decided based on (a) or (b)
 *    Option 2)
 *      - profiles and predefined are mixed into single state structure
 *      - profiles are merged and de-merged, based on is_predefined key
 *
 *
 */


function profileReducer(draft, action) {
  switch (action.type) {
    case SELECT_PROFILE: {
      draft.activeProfile = action.payload
      break;
    }
    /**
     * @param {Object} base - The unserialized (dynamo) profile to use as the base
     * @param {Object} id   - Alternatively, copy an ID from this reducer into the new profile
     * @param {string} newId - We need to know the ID ahead of time to orchestrate reducers.
     */
    case ADD_PROFILE: {
      const {
        base,
        id,
        newId,
        baseProfileName = 'New Profile'
      } = action.payload;

      if (!newId) {
        logSentryError(
          'profileReducer.ADD_PROFILE requires newId',
          new Error('profileReducer.ADD_PROFILE requires newId'),
          action,
          draft
        );
        return draft;
      }

      let newProfile;

      if (base) {
        newProfile = _cloneDeep(action.payload.base);
      } else if (id) {
        newProfile = _cloneDeep(draft.profiles.find(p => p.id === id));
      }

      if (!newProfile) {
        console.error('No profile provided to add profile action', action);
        return;
      }

      newProfile.id = newId;

      const otherNames = draft.profiles.filter(p => p.id !== newProfile.id).map(p => p.name);
      newProfile.name = generateSimpleFilenameVerson(baseProfileName, otherNames)

      delete newProfile.predefined;
      draft.profiles.push(newProfile);
      draft.activeProfile = newProfile.id;
      break;
    }
    /**
    * @param {Object} id   - The ID to copy from this reducer
    * @param {string} name - New profile name (unversioned, we apply versioning here)
    * @param {string} newId - See ADD_PROFILE
    */
    case COPY_PROFILE: {
      const { id, name, newId } = action.payload;

      if (!newId) {
        logSentryError(
          'COPY_PROFILE requires newId',
          new Error('COPY_PROFILE requires newId'),
          action,
          draft
        );
        return draft;
      }

      const oldProfile = draft.profiles.find(profile => profile.id === id);
      if (!oldProfile) break;

      const _name = name || oldProfile.name;

      const otherNames = draft.profiles.filter(p => p.id !== newId).map(p => p.name);

      const newProfile = {
        ..._cloneDeep(oldProfile),
        id: newId,
        name: generateSimpleFilenameVerson(_name, otherNames)
      }
      delete newProfile.predefined
      draft.profiles.push(newProfile);

      draft.activeProfile = newId;

      break;
    }
    case DELETE_PROFILE: {
      const idx = draft.profiles.findIndex(profile => profile.id === action.payload);
      if (idx === -1) break;

      if (draft.profiles.length === 1) break; // can't delete last profile

      draft.profiles.splice(idx, 1);

      if (draft.activeProfile === action.payload) {
        draft.activeProfile = draft.profiles[0].id;
      }
      break;
    }

    case RENAME_PROFILE: {
      const { id, newName } = action.payload;
      const idx = draft.profiles.findIndex(profile => profile.id === id);

      if (idx === -1) break;

      const otherNames = draft.profiles.filter(p => p.id !== id).map(p => p.name);
      draft.profiles[idx].name = generateSimpleFilenameVerson(newName, otherNames);

      break;
    }

    default:
      break;
  }
}


export default profileReducer;

export const generateId = () => shortUuid.generate();

const selectActiveProfileId = state => state.activeProfile;

const selectProfiles = state => state.profiles;

export const selectActiveProfile = createSelector(
  [selectActiveProfileId, selectProfiles],
  (id, profiles) => {
    // TODO: EXPRESSIONS. FormActions calls this. We don't want that. Need to generalize FormActions I guess
    if (!profiles) return null;
    return profiles.find(p => p.id === id)
  }
)


export const selectActiveProfileName = createSelector(
  [selectActiveProfile],
  (profile) => profile?.name
)

