import produce from 'immer';
import _pull from 'lodash/pull';
import _uniq from 'lodash/uniq';
import {
  SET_TAB,
  SET_PERIOD,
  ADD_CHART_METRIC,
  REMOVE_CHART_METRIC,
  CLEAR_CHART_METRICS
} from './financialsActions';
import {
  PERIODS
} from 'src/app/FinancialsPage/columnDefs/rowSchema';


/*
 * Only stores UI state.
 * Data is fetched inside a regular hook, no need for redux there.
 **/


const initialState = {
  selectedTabIndex: 0,
  selectedPeriod: PERIODS.quarterly,
  chartedMetrics: ['cashandcashequivalents', 'epsdiluted']
}


const financialsReducer = (state = initialState, action) => produce(state, draft => {
  switch (action.type) {
    case SET_TAB: {
      draft.selectedTabIndex = action.payload;
      break;
    }

    case SET_PERIOD: {
      draft.selectedPeriod = action.payload;
      break;
    }

    case ADD_CHART_METRIC: {
      draft.chartedMetrics = _uniq([action.payload, ...state.chartedMetrics]);
      break;
    }

    case REMOVE_CHART_METRIC: {
      _pull(draft.chartedMetrics, action.payload);
      break;
    }

    case CLEAR_CHART_METRICS: {
      draft.chartedMetrics = [];
      break;
    }

    default: {
      return state;
    }
  }
});


export default financialsReducer;


