import _uniqueId from 'lodash/uniqueId';
import { STRUCTURAL_TYPES } from 'src/app/slicedForm/mapping/mappingDirections/index';
import _cloneDeep from 'lodash/cloneDeep'
import { flattenObjectToPaths } from './flatpath';

/**
 * Functions to modify profile tree reducer state, when the profile is of type FILTER.
 */



/**
 * A Leaf object
 * @typedef {Object} Leaf
 * @property {string} id - The ID of the leaf/node
 * @property {{keyof: STRUCTURAL_TYPES}} type - The type of the leaf/node [FILTER/OR/AND/SLICE_GROUP]
 * @property {Leaf[]} [tree] - The children of the leaf/node. Some nodes may not have a tree.
 */



/**
 * Replace a leaf node in the given tree with a new leaf node, via callback
 * Does not work on the root AND node, as we need a reference to the parent to swap by index.
 *
 * @param {Leaf} root - The node of the tree to search. Usually profile.root
 * @param {string} targetNodeId - The ID of the node to replace
 * @param {Leaf} newLeaf - Callback function to get the new node
 * @returns {boolean} - True if the node was found and replaced, false otherwise
 */
export function replaceLeaf(
  root,
  targetNodeId,
  newLeaf,
) {
  if ('tree' in root) {
    return root.tree.some((node, idx) => {
      if (node?.id === targetNodeId) {
        root.tree[idx] = newLeaf;
        return true;
      } else if ('tree' in node) {
        return replaceLeaf(node, targetNodeId, newLeaf);
      }
      return false;
    });
  }
  return false;
}


/**
 * Add a leaf as a child of a GROUP node. (AND/OR/SLICE_GROUP)
 *
 * @param {Leaf} root - The node of the tree to search. Usually profile.root
 * @param {string} targetGroupId - The ID of the parent node that we want to add a child leaf
 * @param {Leaf} newLeaf - Callback function to get the newly created node
 * @returns {boolean} - True if the node was found and replaced, false otherwise
 */
export function addLeaf(
  root,
  targetGroupId,
  newLeaf,
) {
  if (root?.id === targetGroupId && 'tree' in root) {
    root.tree.push(newLeaf);
    return true;
  }

  if ('tree' in root) {
    return root.tree.some(node => {
      return addLeaf(node, targetGroupId, newLeaf);
    });
  }
}


/**
 * Get any Leaf by ID.
 *
 * @param {Leaf} root - The node of the tree to search. Usually profile.root
 * @param {string} targetNodeId - The ID of the leaf we want to get
 * @returns {(Leaf|undefined)} - The found leaf
 */
export function getLeaf(root, targetNodeId) {
  if (root?.id === targetNodeId) {
    return root;
  }
  if (!('tree' in root)) return;

  for (const node of root.tree) {
    const leaf = getLeaf(node, targetNodeId);
    if (leaf) return leaf;
  }
}


/**
 * Get parent of Leaf by ID.
 *
 * @param {Leaf} root - The node of the tree to search. Usually profile.root
 * @param {string} targetNodeId - The ID of the leaf we want to get
 * @returns {(Leaf|undefined)} - The found leaf
 */
export function getParentLeaf(root, targetNodeId) {
  if (!('tree' in root)) return;

  for (const node of root.tree) {
    if (node?.id === targetNodeId) {
      return root;
    }
    const parent = getParentLeaf(node, targetNodeId);
    if (parent) return parent;
  }
}


/**
 * Get all child nodes of a given node
 *
 * @param root - The parent node
 * @returns {Object[]} - The accumulator
 */
export function getAllDescendants(root) {
  const descendants = [];
  if (root.tree) {
    for (const node of root.tree) {
      descendants.push(
        node,
        ...getAllDescendants(node)
      );
      getAllDescendants(node, descendants);
    }
  }
  return descendants;
}


/**
 * Delete any Leaf by ID. Does not touch the entity.
 *
 * @param {Leaf} root - The node of the tree to search. Usually profile.root
 * @param {string} targetNodeId - The ID of the leaf we want to get
 * @returns {(Leaf|undefined)} - The deleted leaf
 */
export function deleteLeaf(root, targetNodeId) {
  if (!root.tree) return;

  for (const [idx, node] of root.tree.entries()) {
    if (node.id === targetNodeId) {
      root.tree.splice(idx, 1);
      return node;
    }
    const leaf = deleteLeaf(node, targetNodeId);
    if (leaf) return leaf;
  }
}


/**
 * Given a PROFILE node, return a FORM leaf, and a FORM entity.
 * @param {keyof STRUCTURAL_TYPES} type
 * @param {Object[]} [tree]
 * @param {Object} data
 * @return {{id: string, leaf: {id: string, type: string, tree: Object[]}, entity: {}}}
 */
export function createEntityAndLeaf({ type, tree, values = {} }) {
  const id = createEntityId();
  return {
    id,
    entity: { ...values },
    leaf: {
      id,
      type,
      ...(tree !== undefined && { tree })
    }
  };
}



// mutable
const removeEntityErrors = (entity) => {
  Object.keys(entity).forEach(path => {
    if (entity[path]?.err) {
      delete entity[path].err;
    }
  });
  return entity;
}


export function createEntityValues(nestedEntity) {
  const flat = flattenObjectToPaths(nestedEntity);
  const values = {};
  for (const path in flat) {
    values[path] = createEntityVal(flat[path]);
  }
  return values;
}


export function createEntityVal(val) {
  // return val;
  return { val, err: null }
}


export function getEntityVal(entityVal) {
  // return entityVal;
  return (entityVal && typeof entityVal === 'object')
    ? entityVal?.val
    : null
}



export function copyEntity(entityPaths, removeErrors = true) {
  const newId = createEntityId();
  const copied = _cloneDeep(entityPaths);

  return {
    id: newId,
    entity: removeErrors ? removeEntityErrors(copied) : copied,
  }
}




export function createEntityId() {
  return _uniqueId('e_');
}






/**
 * Returns the childred of a given entity, without any further 'tree' nesting.
 * Use to get SLICE_GROUP children.
 * @param {Leaf} root - The node of the tree to search. Usually profile.root
 * @param {string} targetNodeId - The ID of the leaf we want to get
 * @returns {(Leaf[] | undefined)} - The deleted leaf. Undefined if not a SLICE_GROUP.
 */
export function getSliceChildrenLeaves(root, targetNodeId) {
  const parent = getLeaf(root, targetNodeId);
  if (!parent) return undefined;
  if (parent.type !== STRUCTURAL_TYPES.SLICE_GROUP) return undefined;
  return parent.tree.map(leaf => ({ id: leaf.id, type: leaf.type }));
}
